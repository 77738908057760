import { t } from '@lingui/macro';
import { FieldText } from '@luminovo/design-system';
import { MenuItem as MuiMenuItem } from '@mui/material';
import { Column, GridApi } from 'ag-grid-community';

export function MenuItemFilter<T>({ column, api }: { column: Column<T>; api: GridApi<T> }): JSX.Element {
    const filter: { type: string; filter: string } = api.getFilterModel()[column.getColId()];
    return (
        <MuiMenuItem
            disableGutters
            disableRipple
            sx={{
                padding: '0 8px',

                backgroundColor: 'white !important',
            }}
        >
            <FieldText
                size="small"
                fullWidth
                value={filter?.filter ?? ''}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
                onChange={(text) => {
                    api.setFilterModel({
                        ...api.getFilterModel(),
                        [column.getColId()]: {
                            type: 'contains',
                            filter: text,
                        },
                    });
                }}
                type="search"
                placeholder={t`Type to filter`}
            />
        </MuiMenuItem>
    );
}
