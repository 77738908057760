import { t } from '@lingui/macro';
import { Tab, Tabs, useNavigate } from '@luminovo/design-system';
import React from 'react';
import { useLocation } from 'react-router-dom';

type TabValue = 'activity' | 'expense' | 'driver' | 'templates';

export const ManufacturingDatabaseTabs: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // needs to be 2 for `/manufacturing/templates/:templateId`
    const currentTab = location.pathname.split('/')[2] as TabValue;

    const handleTabChange = (_: unknown, newValue: TabValue) => {
        navigate(`/manufacturing/${newValue}`);
    };

    return (
        <Tabs value={currentTab} onChange={handleTabChange} size="large">
            <Tab value="activity" label={t`Activities`} />
            <Tab value="expense" label={t`Expenses`} />
            <Tab value="resource" label={t`Resources`} />
            <Tab value="driver" label={t`Drivers`} />
            <Tab value="template" label={t`Templates`} />
        </Tabs>
    );
};
