export type LeadTimeUnit = 'days' | 'weeks' | 'months';

export function convertLeadTimeUnitToDays(unit: LeadTimeUnit, value: number = 1) {
    switch (unit) {
        case 'days':
            return value;
        case 'weeks':
            return value * 7;
        case 'months':
            return value * 31;
    }
}
