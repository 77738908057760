import { isPresent, transEnum, useAssertStableProp } from '@luminovo/commons';
import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { ErrorCode, HttpError } from '@luminovo/http-client';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';
import {
    FieldValues,
    FormProvider,
    Mode,
    Path,
    SubmitHandler,
    UseFormProps,
    ValidationMode,
    useForm,
    useFormContext,
    useFormState,
} from 'react-hook-form';
import { errorCodeTranslations } from '../../modules/Error/errorCodeTranslations';
import { useDebugErrorHandler } from '../../resources/http/debugErrorHandler';
import { throwErrorUnlessProduction } from '../../utils/customConsole';
// eslint-disable-next-line import/no-unused-modules
export type ValidationErrors<T> = Partial<
    Record<ErrorCode, { fieldPath: Path<T> | `root.serverError`; message?: string }>
>;

type Props<T extends FieldValues> = Omit<UseFormProps<T>, 'mode' | 'reValidateMode'> & {
    onSubmit: SubmitHandler<T>;
    mode?: keyof ValidationMode | undefined;
    // Copy of the types here https://github.com/react-hook-form/react-hook-form/blob/62416f8d2fcf17a9b233e4eb81b4d87e9222cdd5/src/types/form.ts#L92
    reValidateMode?: Exclude<Mode, 'onTouched' | 'all'>;
    /**
     * Used to display server-side-validation errors.
     *
     * Provides a mapping of the ErrorCodes into error message that will be shown below of the defined input field.
     *
     * @see ErrorCode
     */
    validationErrors?: ValidationErrors<T>;
    formId?: string;

    /**
     * Disables the stable prop check. Disabling this could potentially result in some bugs not being caught, so use with caution!
     */
    UNSAFE_disableStablePropCheck?: boolean;
};

export function FormContainer<T extends FieldValues>({
    defaultValues,
    onSubmit,
    children,
    mode = 'onBlur',
    reValidateMode = 'onBlur',
    validationErrors,
    formId,
    UNSAFE_disableStablePropCheck = false,
}: React.PropsWithChildren<Props<T>>) {
    if (isPresent(useFormContext())) {
        throwErrorUnlessProduction(
            new Error('Error: Nesting FormContainer is not allowed since the inner FormContext cannot be accessed.'),
        );
    }
    useAssertStableProp(defaultValues, {
        errorMessage:
            'Default values should not change after the first second. This indicates an cache-invalidation bug or non-deterministic queries.',
        enabled: !UNSAFE_disableStablePropCheck,
    });

    const formReturn = useForm<T>({
        defaultValues,
        mode,
        reValidateMode,
    });

    const onError = useDebugErrorHandler();
    const wrappedOnSubmit: SubmitHandler<T> = React.useCallback(
        async (data, event) => {
            try {
                formReturn.clearErrors('root.serverError');
                await onSubmit(data, event);
            } catch (error) {
                if (error instanceof HttpError && isPresent(validationErrors)) {
                    const fieldError = validationErrors[error.code];
                    if (isPresent(fieldError)) {
                        const { fieldPath, message = transEnum(error.code, errorCodeTranslations) } = fieldError;
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        formReturn.setError(fieldPath as Path<T>, { message });
                    } else {
                        // Displays a snackbar error if the errorcode is not contained in validationErrors
                        onError(error);
                    }
                } else {
                    formReturn.reset(undefined, { keepValues: true, keepDirty: true });
                    throw error;
                }
            }
        },
        [onSubmit, onError, validationErrors, formReturn],
    );

    const handleSubmit = formReturn.handleSubmit(wrappedOnSubmit);

    return (
        <FormProvider {...formReturn}>
            <Form id={formId} onKeyDown={handleStandardFormKeydown} onSubmit={handleSubmit}>
                {children}
                <ServerErrorMessage />
            </Form>
        </FormProvider>
    );
}

function ServerErrorMessage() {
    const {
        errors: { root },
    } = useFormState();
    const serverErrorMessage = root?.hasOwnProperty('serverError') && root.serverError.message;

    if (!Boolean(serverErrorMessage)) {
        return null;
    }

    return (
        <Flexbox gap={4} paddingTop={'8px'} alignItems={'center'}>
            <ErrorOutlineIcon fontSize="small" style={{ color: colorSystem.red[7] }} />
            <Text variant="body-small" color={colorSystem.red[7]}>
                {serverErrorMessage}
            </Text>
        </Flexbox>
    );
}

const Form = styled('form')({
    display: 'flex',
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
});

const handleStandardFormKeydown: React.KeyboardEventHandler<HTMLFormElement> = (event) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const target = event.target as { type?: string };

    const shouldPreventDefault =
        event.key === 'Enter' &&
        // Allow the form to be submitted if it's the 'submit' button.
        target.type !== 'submit' &&
        // Allow new lines to be created if its a text area.
        target.type !== 'textarea';

    if (shouldPreventDefault) {
        event.preventDefault();
    }
};
