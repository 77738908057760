import { colorSystem } from '@luminovo/design-system';
import { ViewBoxCoordinateType } from '@luminovo/http-client';
import { Box } from '@mui/material';
import React from 'react';
import { SVGControlOptions, SvgImageControl } from './components/SvgImageControl';
import { SvgImageRuler } from './components/SvgImageRuler/SvgImageRuler';
import { addPaddingToViewBox } from './utils/addPaddingToViewBox';
import { SvgImageCursor, useSvgImageEventHandlers } from './utils/useSVGImageEventHandler';

export const SvgImageRenderer: React.FunctionComponent<{
    svgProps: React.SVGProps<SVGSVGElement>;
    viewBox: ViewBoxCoordinateType;
    controlOptions?: SVGControlOptions[];
    onFlipClick?: () => void;
    resetViewBoxOnUpdate?: boolean;
}> = ({ svgProps, viewBox: initialViewBox, children, controlOptions, onFlipClick, resetViewBoxOnUpdate }) => {
    const [cursor, setCursor] = React.useState<SvgImageCursor>('pointer');

    const {
        svgRef,
        linePoints,
        viewBox,
        handleReset,
        handleResetLinePoints,
        handleZoomInClick,
        handleZoomOutClick,
        handleCenterClick,
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
        handleMouseWheelScroll,
        handleMouseClick,
        handleMeasureClick,
    } = useSvgImageEventHandlers({
        initialViewBox: addPaddingToViewBox(initialViewBox),
        cursor,
        setCursor,
    });

    const handleFlipClick = () => onFlipClick?.();

    //To stop page scrolling
    React.useEffect(() => {
        function updateWheel(event: WheelEvent) {
            event.preventDefault();
        }
        if (svgRef && svgRef.current) {
            const svgRefCopy = svgRef.current;
            svgRefCopy.addEventListener('wheel', updateWheel, false);
            return () => {
                svgRefCopy.removeEventListener('wheel', updateWheel);
            };
        }
    }, [svgRef]);

    React.useEffect(() => {
        if (resetViewBoxOnUpdate) {
            handleCenterClick();
        }
        // To reset the viewBox when the viewBoxCoord changes
        // eslint-disable-next-line react-compiler/react-compiler
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialViewBox, resetViewBoxOnUpdate]);

    return (
        <Box
            style={{ width: '100%', height: '100%', position: 'relative' }}
            onMouseLeave={handleReset}
            onKeyDown={(e) => {
                if (e.key === 'Escape') {
                    setCursor('pointer');
                    handleResetLinePoints();
                }
            }}
        >
            <Box
                style={{
                    position: 'absolute',
                    zIndex: 2,
                    top: '48px',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                {controlOptions && (
                    <SvgImageControl
                        cursor={cursor}
                        controlOptions={controlOptions}
                        onZoomInClick={handleZoomInClick}
                        onZoomOutClick={handleZoomOutClick}
                        onCenterClick={handleCenterClick}
                        onFlipClick={handleFlipClick}
                        onMeasureClick={handleMeasureClick}
                    />
                )}
            </Box>

            <svg
                ref={svgRef}
                onWheel={handleMouseWheelScroll}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onMouseDown={handleMouseDown}
                onClick={handleMouseClick}
                tabIndex={0}
                viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
                style={{
                    cursor,
                    background: colorSystem.neutral.white,
                    backgroundImage: `radial-gradient(${colorSystem.neutral[4]} 1px, transparent 0)`,
                    backgroundSize: '15px 15px',
                    ...svgProps.style,
                }}
            >
                {children}
                <SvgImageRuler
                    linePoints={linePoints}
                    viewBox={viewBox}
                    svgRef={svgRef}
                    initialViewBox={addPaddingToViewBox(initialViewBox)}
                />
            </svg>
        </Box>
    );
};
