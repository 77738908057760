import { t } from '@lingui/macro';
import { isPresent, transEnum, uniqBy } from '@luminovo/commons';
import {
    Checkbox,
    colorSystem,
    FieldMultiSelectControlled,
    FieldRadioControlled,
    FieldSelectControlled,
    Flexbox,
    FormItem,
    SecondaryButton,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { SupplierPreference } from '@luminovo/http-client';
import { formatSupplierDTO, supplierPreferenceTranslations } from '@luminovo/sourcing-core';
import { Add } from '@mui/icons-material';
import { RadioGroup } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SubmitButton } from '../../../../../components/formLayouts/SubmitButton';
import { useDialogAddQuoteRequestSupplier, useQuoteRequestSuppliers } from './AddQuoteRequestSupplier';
import { QuoteRequestAssignmentFormValues } from './types';

export const FormItemSupplierSelectionStrategy: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();

    return (
        <FormItem label={`Choose supplier by`}>
            <FieldSelectControlled
                name="supplierSelectionStrategy"
                control={control}
                required={true}
                FieldProps={{
                    disableClearable: true,
                    getOptionLabel: (strategy) => {
                        switch (strategy.type) {
                            case 'SupplierPartTypeMatches':
                                return `Manually`;
                            case 'SupplierLineCard':
                                return `Supplier line card`;
                            case 'ApprovedVendorList':
                                return `Approved vendor list`;
                        }
                    },
                    options: [
                        { type: 'SupplierPartTypeMatches' },
                        { type: 'SupplierLineCard', partOptionSelectionStrategy: 'OnlyMatchingPartOptions' },
                        { type: 'ApprovedVendorList', partOptionSelectionStrategy: 'OnlyMatchingPartOptions' },
                    ],
                }}
            />
        </FormItem>
    );
};

export const FormItemSelectionStrategy: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();
    const supplierSelectionStrategyType = useWatch({ control, name: 'supplierSelectionStrategy.type' });

    if (!['SupplierLineCard', 'ApprovedVendorList'].includes(supplierSelectionStrategyType)) {
        return null;
    }

    return (
        <RadioGroup>
            <FormItem label={`What should be sent to the selected suppliers?`}>
                <Flexbox alignItems={'center'} gap={8}>
                    <FieldRadioControlled
                        control={control}
                        name="supplierSelectionStrategy.partOptionSelectionStrategy"
                        FieldProps={{ fieldValue: 'OnlyMatchingPartOptions', size: 'small' }}
                    />
                    <Text>Only part options matching line card</Text>
                </Flexbox>
                <Flexbox alignItems={'start'} gap={8}>
                    <FieldRadioControlled
                        control={control}
                        name="supplierSelectionStrategy.partOptionSelectionStrategy"
                        FieldProps={{ fieldValue: 'AllPartOptions', size: 'small' }}
                    />

                    <Text>Part options matching line card plus all approved alternatives</Text>
                </Flexbox>
            </FormItem>
        </RadioGroup>
    );
};

export const FormItemSuppliers: React.FunctionComponent<{
    groupMode: 'manufacturers' | 'offTheShelfPartOptions' | 'customPartOptions';
}> = ({ groupMode }) => {
    const context = useFormContext<QuoteRequestAssignmentFormValues>();
    const { control, setValue } = context;
    const selectedSuppliers = useWatch({ control, name: 'selectedQuoteRequestSuppliers' });
    const { data: options } = useQuoteRequestSuppliers();

    const getGroupByStatus = React.useCallback(
        (preference: SupplierPreference): 'all' | 'indeterminate' | 'none' => {
            const selectedIds = selectedSuppliers.map((s) => s.id);
            const selectableIds = selectedSuppliers.filter((s) => s.preference === preference).map((s) => s.id);

            if (selectableIds.length === 0) {
                return 'none';
            }

            if (selectableIds.every((id) => selectedIds.includes(id))) {
                return 'all';
            }

            if (selectableIds.some((id) => selectedIds.includes(id))) {
                return 'indeterminate';
            }

            return 'none';
        },
        [selectedSuppliers],
    );

    const toggleGroup = React.useCallback(
        (preference: SupplierPreference, status: 'all' | 'indeterminate' | 'none') => {
            if (status === 'all') {
                const newValue = uniqBy(
                    selectedSuppliers.filter((s) => s.preference !== preference),
                    (x) => x.id,
                );

                setValue('selectedQuoteRequestSuppliers', newValue, { shouldValidate: true });
            } else {
                const newValue = uniqBy(selectedSuppliers.concat(selectedSuppliers), (x) => x.id);
                setValue('selectedQuoteRequestSuppliers', newValue, { shouldValidate: true });
            }
        },
        [selectedSuppliers, setValue],
    );

    const { openDialog } = useDialogAddQuoteRequestSupplier();

    return (
        <FormItem
            label={t`Suppliers`}
            actions={
                <SecondaryButton size="small" onClick={() => openDialog(context)}>
                    {t`View suppliers`}
                </SecondaryButton>
            }
        >
            <FieldMultiSelectControlled
                name="selectedQuoteRequestSuppliers"
                control={control}
                FieldProps={{
                    chipBoxProps: {
                        maxHeight: '200px',
                        overflow: 'auto',
                    },
                    options: options ?? [],
                    disabled: !isPresent(options),
                    getOptionLabel: formatSupplierDTO,
                    placeholder: selectedSuppliers.length === 0 ? t`No suppliers` : undefined,
                    getOptionKey: (supplier) => supplier.id,
                    virtualized: true,
                    disableCloseOnSelect: true,
                    groupBy: (supplier): SupplierPreference => {
                        return supplier.preference;
                    },
                    renderGroupLabel: (params) => {
                        //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        const groupByKey = params.group as SupplierPreference;
                        const status = getGroupByStatus(groupByKey);

                        return (
                            <Flexbox gap={8} paddingLeft={'12px'} alignItems={'center'}>
                                <Checkbox
                                    checked={status === 'all'}
                                    indeterminate={status === 'indeterminate'}
                                    size={'small'}
                                    onClick={() => toggleGroup(groupByKey, status)}
                                    disabled={groupByKey === SupplierPreference.NotApproved}
                                />
                                <Text variant="h4">{transEnum(groupByKey, supplierPreferenceTranslations)}</Text>
                            </Flexbox>
                        );
                    },
                    renderOption: (supplier, { selected }: { selected: boolean }) => {
                        return (
                            <Flexbox key={supplier.id} gap={8} alignItems={'center'} width={'100%'} paddingLeft={'8px'}>
                                <Checkbox checked={selected} size={'small'} />
                                <Flexbox width={'100%'} justifyContent={'space-between'}>
                                    <Flexbox gap={4} alignItems={'baseline'}>
                                        <Text variant="body" color={colorSystem.neutral[8]}>
                                            {formatSupplierDTO(supplier)}
                                        </Text>
                                        <Text variant="body-small" color={colorSystem.neutral[7]}>
                                            {supplier.supplier_number}
                                        </Text>
                                    </Flexbox>
                                </Flexbox>
                            </Flexbox>
                        );
                    },
                }}
            />
        </FormItem>
    );
};

export const ButtonAddToQuoteRequest: React.FunctionComponent<{
    disabled: boolean;
    groupMode: 'manufacturers' | 'offTheShelfPartOptions' | 'customPartOptions';
}> = ({ disabled, groupMode }) => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();
    const selectedSuppliers = useWatch({ control, name: 'selectedQuoteRequestSuppliers' });
    const hasNoSuppliers = selectedSuppliers.length === 0;

    return (
        <Tooltip
            variant="white"
            title={
                disabled
                    ? {
                          manufacturers: 'Select at least one manufacturer',
                          offTheShelfPartOptions: 'Select at least one part option',
                          customPartOptions: 'Select at least one custom part option',
                      }[groupMode]
                    : ''
            }
        >
            <div>
                <SubmitButton
                    disabled={disabled || hasNoSuppliers}
                    size="medium"
                    label={'Add to quote request'}
                    startIcon={<Add />}
                />
            </div>
        </Tooltip>
    );
};
