import { Trans } from '@lingui/macro';
import { SecondaryButton, TertiaryIconButton, useBackNavigation } from '@luminovo/design-system';
import { ArrowBack } from '@mui/icons-material';
/**
 * Note: this component doesn't navigate back, it just navigates to the specified href.
 */
export function BackButton({ href, variant = 'icon' }: { href: string; variant?: 'icon' | 'button' }): JSX.Element {
    const { navigateBack } = useBackNavigation();

    if (variant === 'icon') {
        return (
            <TertiaryIconButton size="medium" onClick={() => navigateBack({ defaultPath: href })}>
                <ArrowBack fontSize="inherit" />
            </TertiaryIconButton>
        );
    } else {
        return (
            <SecondaryButton
                size="medium"
                onClick={() => navigateBack({ defaultPath: href })}
                startIcon={<ArrowBack />}
                style={{ width: 'fit-content' }}
            >
                <Trans>Back</Trans>
            </SecondaryButton>
        );
    }
}
