import { t, Trans } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import { Flexbox, PrimaryButton, Text, useNavigate } from '@luminovo/design-system';
import { CreateOrderInputDTO, http } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, PaymentIntent, Stripe } from '@stripe/stripe-js';
import { UseMutateAsyncFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PageLayout } from '../../components/PageLayout';
import { useDebugErrorHandler } from '../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../resources/http/httpQueryKey';
// eslint-disable-next-line camelcase
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { route, UrlParams } from '../../utils/routes';
interface RfqOrderProps extends UrlParams<'/rfqs/:rfqId/order/confirmation'> {}

const useMutateOrder = (rfqId: string) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();

    return useMutation({
        mutationFn: async (values: CreateOrderInputDTO) => {
            await http('POST /order-management/orders', { requestBody: values }, getToken());
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: httpQueryKey('GET /rfqs/:rfqId', { pathParams: { rfqId } }),
            });
            navigate(route('/rfqs/:rfqId/dashboard', { rfqId }));
        },
        onError: debugErrorHandler,
    });
};

type RetrievePaymentIntentFn = (
    stripe: Stripe | undefined,
    clientSecret: string | undefined,
) => Promise<PaymentIntent | undefined>;

const retrievePaymentIntent: RetrievePaymentIntentFn = async (stripe, clientSecret) => {
    if (!stripe || !clientSecret) {
        return;
    }

    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
    return paymentIntent;
};

interface UseRetrievePaymentIntentOptions {
    rfqId: string;
    clientSecret: string | undefined;
    orderSelectionOptionId: string;
    mutateAsync: UseMutateAsyncFunction<void, unknown, CreateOrderInputDTO, unknown>;
}

const useRetrievePaymentIntent = ({
    rfqId,
    clientSecret,
    orderSelectionOptionId,
    mutateAsync,
}: UseRetrievePaymentIntentOptions) => {
    const stripe = useStripe();

    return useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['paymentIntent', clientSecret],
        queryFn: async () => {
            const paymentIntent = await retrievePaymentIntent(stripe ?? undefined, clientSecret);

            if (!isPresent(paymentIntent)) {
                return t`Verifying payment.`;
            }

            switch (paymentIntent.status) {
                case 'processing':
                    await mutateAsync({
                        /* eslint-disable camelcase */
                        rfq_id: rfqId,
                        order_selection_option_id: orderSelectionOptionId,
                        payment_id: paymentIntent.id,
                        payment_processor: 'Stripe',
                        payment_collection_mode: 'ChargeImmediately',
                        /* eslint-enable camelcase */
                    });
                    return t`Your payment is processing.`;
                case 'requires_payment_method':
                case 'requires_action':
                case 'requires_confirmation':
                case 'requires_capture':
                case 'canceled':
                    return t`Your payment was not successful, please try again`;
                case 'succeeded':
                    await mutateAsync({
                        /* eslint-disable camelcase */
                        rfq_id: rfqId,
                        order_selection_option_id: orderSelectionOptionId,
                        payment_id: paymentIntent.id,
                        payment_processor: 'Stripe',
                        payment_collection_mode: 'ChargeImmediately',
                        /* eslint-enable camelcase */
                    });
                    return t`Confirming your order.`;
                default:
                    assertUnreachable(paymentIntent.status);
            }
        },
        // this is required, otherwise the page does not redirect at the end.
        enabled: !!stripe && !!clientSecret,
        retry: false,
    });
};

const OrderConfirmation = ({ pathParams, queryParams }: RfqOrderProps & { publishableKey: string }): JSX.Element => {
    const navigate = useNavigate();
    const { rfqId } = pathParams;

    const { mutateAsync, isPending } = useMutateOrder(rfqId);
    const { orderSelectionOptionId, payment_intent_client_secret: clientSecret } = queryParams;

    const handleBack = () => {
        navigate(route('/rfqs/:rfqId/dashboard', { rfqId }));
    };

    const { data: message, isLoading: isLoadingPaymentIntent } = useRetrievePaymentIntent({
        clientSecret: clientSecret ?? undefined,
        orderSelectionOptionId,
        rfqId,
        mutateAsync,
    });

    const isLoading = isLoadingPaymentIntent || isPending;

    return (
        <PageLayout layout="centered" navbar={<></>}>
            <Flexbox flexDirection={'column'} gap={'24px'} alignItems={'center'}>
                <Text variant="h1">{message}</Text>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <PrimaryButton onClick={handleBack}>
                        <Trans>Back to dashboard</Trans>
                    </PrimaryButton>
                )}
            </Flexbox>
        </PageLayout>
    );
};

export const OrderConfirmationPage = (props: RfqOrderProps): JSX.Element => {
    const { data, isLoading } = useHttpQuery('GET /organization-settings/payments/credentials', {});
    const publishableKey = data?.public_key;
    const { orderSelectionOptionId, payment_intent_client_secret: clientSecret } = props.queryParams;

    if (!orderSelectionOptionId && !clientSecret) {
        throw new Error(
            `Missing query params. orderSelectionOptionId: ${orderSelectionOptionId}, clientSecret: ${clientSecret}`,
        );
    }

    if (isLoading) {
        return (
            <PageLayout layout="centered" navbar={<></>}>
                <Flexbox flexDirection={'column'} gap={'24px'} alignItems={'center'}>
                    <Text variant="h1">
                        <Trans>Verifying payment.</Trans>
                    </Text>
                    <CircularProgress />
                </Flexbox>
            </PageLayout>
        );
    }

    if (data === undefined || typeof publishableKey !== 'string') {
        throw new Error(`Payment data should not be undefined. data: ${data}, publishableKey: ${publishableKey}`);
    }

    return (
        <PageLayout layout="centered" navbar={<></>}>
            <Elements stripe={loadStripe(publishableKey)}>
                <OrderConfirmation {...props} publishableKey={publishableKey} />
            </Elements>
        </PageLayout>
    );
};
