import { formatDecimal, formatPercentage, MonetaryValue } from '@luminovo/commons';
import { monetaryValue } from './monetaryValueMath';

/**
 * Calculates the difference between the target price and the value price
 * and formats it as a percentage or a factor.
 *
 * Example:
 * - 10% -> "10%"
 * - 2x -> "2x"
 */
export function formatTargetPriceDifference(target: MonetaryValue, value: MonetaryValue) {
    const valueScalar = monetaryValue.getScalar(value);
    const targetScalar = monetaryValue.getScalar(target);
    const diff = valueScalar / targetScalar - 1;

    if (diff < 1) {
        return formatPercentage(diff);
    }
    return `${formatDecimal(diff, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}x`;
}
