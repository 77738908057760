import { plural, t } from '@lingui/macro';
import { Column, Row, Text, columnWidth, useNavigate } from '@luminovo/design-system';
import { ActivityDTO } from '@luminovo/http-client';
import { RightAlignedStickyTableCell } from '@luminovo/manufacturing-core';
import { TableCell } from '@mui/material';
import { ErrorText } from '../../../../components/Spinners';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';
import InformationDialogBox, {
    InformationDialogBoxContent,
} from '../../../../components/dialogBox/InformationDialogBox';
import { route } from '../../../../utils/routes';
import { RfqOverviewMiniTable } from '../../../RfqOverview/components/RfqOverviewTable';
import { ActionMenuComponent } from '../../shared/manufacturingComponents';
import { generateTemplateString } from '../../shared/manufacturingUtils';
import { useCanEditManufacturingDatabase } from '../../utils/useCanEditManufacturingDatabase';
import { useDeleteActivities, useStatusChangeActivities } from '../activitiesDatabaseTableComponents';
interface ActivityMenuProps {
    activityId: string;
    status: string;
}

const ActivityMenuComponent: React.FunctionComponent<ActivityMenuProps> = ({ activityId, status }): JSX.Element => {
    const navigate = useNavigate();
    const {
        isLoading,
        isError,
        rfqsOfActivity,
        templatesOfActivity,
        deleteDialog: {
            deleteOnClick,
            isInformationDialogDeleteOpen,
            isConfirmationDialogDeleteOpen,
            setIsDeletePressed,
        },
    } = useDeleteActivities(activityId);
    const templatesOfActivityStr: string = generateTemplateString(templatesOfActivity);
    const templatesOfActivityLength = templatesOfActivity.length;
    const {
        isLoading: isLoadingStatusChange,
        isError: isErrorStatusChange,
        templatesOfActivity: templatesOfActivityStatusChange,
        statusChangeDialog: {
            setIsStatusChangePressed,
            statusChangeOnClick,
            saveStatusChange,
            isConfirmationDialogOpen,
        },
    } = useStatusChangeActivities(activityId, status);

    const generateContentString = () => {
        const templatesOfActivityStr: string = generateTemplateString(templatesOfActivityStatusChange);
        if (templatesOfActivityStatusChange.length > 0) {
            if (templatesOfActivityStatusChange.length > 1) {
                return t`Since this activity is used in templates ${templatesOfActivityStr} they will also be deactivated.`;
            } else if (templatesOfActivityStatusChange.length === 1) {
                return t`Since this activity is used in template ${templatesOfActivityStr} it will also be deactivated.`;
            }
            return '';
        }
    };
    const translatedString = generateContentString() + ' ' + t`Proceed?`;

    return (
        <RightAlignedStickyTableCell>
            <ActionMenuComponent
                isActive={status === 'Active'}
                onClickActivate={statusChangeOnClick}
                onClickEdit={function (event: React.MouseEvent<HTMLElement>): void {
                    navigate(route('/manufacturing/activity/:activityId/edit', { activityId }));
                }}
                onClickDuplicate={function (event: React.MouseEvent<HTMLElement>): void {
                    navigate(route('/manufacturing/activity/:activityId/duplicate', { activityId }));
                }}
                onClickDelete={() => setIsDeletePressed(true)}
                isLoadingDelete={isLoading}
                isLoadingStatusChange={isLoadingStatusChange}
            />
            {(isError || isErrorStatusChange) && <ErrorText />}

            <InformationDialogBox
                title={t`Deleting not possible`}
                onReject={() => setIsDeletePressed(false)}
                isDialogOpen={isInformationDialogDeleteOpen}
            >
                <InformationDialogBoxContent
                    // eslint-disable-next-line spellcheck/spell-checker
                    text={t`This activity is used by the following RfQs and cannot be deleted`}
                >
                    <RfqOverviewMiniTable data={rfqsOfActivity} />
                </InformationDialogBoxContent>
            </InformationDialogBox>

            <ConfirmationDialogBox
                text={t`Are you sure you want to delete this activity?`}
                title={t`Delete activity`}
                onConfirm={() => deleteOnClick()}
                onReject={() => setIsDeletePressed(false)}
                isDialogOpen={isConfirmationDialogDeleteOpen}
                deleteText={t`Yes, delete`}
            >
                {templatesOfActivityLength > 0 && (
                    <Text variant="body">
                        {plural(templatesOfActivityLength, {
                            one: `This activity is used in the template ${templatesOfActivityStr}. Deleting it will also delete it from the template.`,
                            other: `This activity is used in the templates ${templatesOfActivityStr}. Deleting it will also delete it from these templates.`,
                        })}
                    </Text>
                )}
            </ConfirmationDialogBox>

            <ConfirmationDialogBox
                text={t`Are you sure you want to set this activity as inactive?`}
                title={t`Deactivate activity`}
                onConfirm={() => saveStatusChange()}
                onReject={() => setIsStatusChangePressed(false)}
                isDialogOpen={isConfirmationDialogOpen}
                deleteText={t`Yes, set inactive`}
            >
                <Text variant="body">{translatedString}</Text>
            </ConfirmationDialogBox>
        </RightAlignedStickyTableCell>
    );
};

export const useActivityMenuColumnContext = (): ActivityMenuColumnContext => {
    const canEditManufacturingDatabase = useCanEditManufacturingDatabase();
    return { canEditManufacturingDatabase };
};

export interface ActivityMenuColumnContext {
    canEditManufacturingDatabase: boolean;
}

export function getActivityMenuColumn<C extends ActivityMenuColumnContext>(): Column<ActivityDTO, C> {
    return {
        label: '', // intentionally blank
        id: `activitiesActionsColumn`,
        renderHead: () => <RightAlignedStickyTableCell />,
        render: ({ data: rowData }: Row<ActivityDTO>, context) =>
            context.canEditManufacturingDatabase ? (
                <ActivityMenuComponent activityId={rowData.id} status={rowData.status} />
            ) : (
                <TableCell />
            ),
        width: columnWidth.small,
    };
}
