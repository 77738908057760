import { plural, t } from '@lingui/macro';
import {
    CenteredLayout,
    Flexbox,
    MaxWidthLayout,
    Message,
    NonIdealState,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { LinearProgress } from '@mui/material';
import { useUniversalImporter } from '../../context';
import { useRunParsers } from '../../hooks/useRunParsers';
import { TableCheckRows } from './TableCheckRows';

export function StepCheck(): JSX.Element {
    const { state } = useUniversalImporter();
    const { refetch } = useRunParsers();
    const { importerTable } = state;

    if (!importerTable) {
        return (
            <CenteredLayout>
                <NonIdealState
                    title={t`Import failed`}
                    description={t`Your file failed to import`}
                    action={{
                        children: t`Retry`,
                        onClick: () => {
                            refetch();
                        },
                    }}
                />
            </CenteredLayout>
        );
    }

    return (
        <MaxWidthLayout sx={{ position: 'relative', height: '100%' }}>
            <LinearProgress
                sx={{ position: 'absolute', left: 0, top: 0, width: '100%' }}
                variant="determinate"
                value={importerTable.getReadyForPreviewPercentage()}
            />
            <Flexbox flexDirection={'column'} gap="16px" height={'100%'}>
                <ErrorsBox />
                <TableCheckRows rows={importerTable.getRows()} />
            </Flexbox>
        </MaxWidthLayout>
    );
}

function ErrorsBox() {
    const { state } = useUniversalImporter();
    const duplicates = state.importerTable?.getUniqueColumnsWithDuplicates() ?? [];
    if (duplicates.length === 0) {
        return null;
    }

    const pluralForDuplicates = (numberOfDuplicates: number) => {
        return plural(numberOfDuplicates, {
            zero: 'duplicates',
            one: 'duplicate',
            other: 'duplicates',
        });
    };

    const groupedDuplicates = duplicates.reduce(
        (acc, duplicate) => {
            if (!acc[duplicate.columnIndex]) {
                acc[duplicate.columnIndex] = [];
            }
            acc[duplicate.columnIndex].push(duplicate);
            return acc;
        },
        {} as Record<number, typeof duplicates>,
    );

    return (
        <Message
            title={t`Duplicate values`}
            attention="high"
            size="large"
            message={
                <Flexbox flexDirection={'column'}>
                    {Object.entries(groupedDuplicates).map(([columnIndex, columnDuplicates]) => {
                        const field = state.config.fields[Number(columnIndex)];

                        return (
                            <Flexbox key={columnIndex} gap={'4px'}>
                                <Text>
                                    {t`Unique column`}
                                    {`   `}
                                    <Text variant="code">{`${field?.label}`}</Text>
                                    {` `}
                                    {t`contains duplicates for`} {` `}
                                    <Text variant="code">{`${columnDuplicates[0].text}`}</Text>
                                </Text>
                                {columnDuplicates.length > 1 && (
                                    <Tooltip
                                        title={columnDuplicates
                                            .slice(1)
                                            .map((d) => d.text)
                                            .join(', ')}
                                    >
                                        <Text>
                                            {t`(+${columnDuplicates.length - 1} more ${pluralForDuplicates(columnDuplicates.length - 1)}).`}
                                        </Text>
                                    </Tooltip>
                                )}
                            </Flexbox>
                        );
                    })}
                    <Text>{t`Remove all duplicates before proceeding.`}</Text>
                </Flexbox>
            }
            variant="red"
        />
    );
}
