import { InfoRounded } from '@mui/icons-material';
import { Tooltip, Typography, TypographyProps } from '@mui/material';
import * as React from 'react';
import { colorSystem } from '../../../theme';
import { Flexbox, FlexboxProps } from '../../Flexbox';
import { Text } from '../../Text';
interface Props extends FlexboxProps {
    label?: string | JSX.Element;
    LabelProps?: TypographyProps;
    description?: string | JSX.Element;
    variant?: 'description-as-tooltip' | 'description-inlined';
    required?: boolean;
    actions?: JSX.Element;
}

export function FormItem({
    label,
    children,
    description,
    LabelProps,
    required,
    variant = 'description-as-tooltip',
    actions,
    ...rest
}: React.PropsWithChildren<Props>): JSX.Element {
    return (
        <Flexbox flexDirection="column" gap={8} {...rest}>
            {label && variant === 'description-as-tooltip' && (
                <Flexbox alignItems="center" gap={4} justifyContent={'space-between'}>
                    <Flexbox alignItems="center" gap={4}>
                        {description && variant === 'description-as-tooltip' && (
                            <Tooltip title={description} arrow>
                                <InfoRounded
                                    style={{
                                        cursor: 'pointer',
                                        color: colorSystem.neutral[5],
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        )}
                        {/* TODO: use Text here hopefully */}
                        <Typography
                            variant="h4"
                            sx={{ color: colorSystem.neutral[8] }}
                            display="inline"
                            {...LabelProps}
                        >
                            {label}
                        </Typography>
                        {required && <span style={{ fontSize: '18px', color: colorSystem.neutral[7] }}>*</span>}
                    </Flexbox>
                    {actions && <span style={{ marginLeft: 'auto' }}>{actions}</span>}
                </Flexbox>
            )}
            {label && variant === 'description-inlined' && (
                <Flexbox flexDirection={'column'} gap={4}>
                    <Flexbox alignItems="center" gap={4}>
                        {/* TODO: use Text here hopefully */}
                        <Typography
                            variant="h4"
                            display="inline"
                            sx={{ color: colorSystem.neutral[8] }}
                            {...LabelProps}
                        >
                            {label}
                        </Typography>
                        {required && <span style={{ fontSize: '18px', color: colorSystem.neutral[7] }}>*</span>}
                        {actions && <span style={{ marginLeft: 'auto' }}>{actions}</span>}
                    </Flexbox>
                    {description && variant === 'description-inlined' && (
                        <Text
                            variant={'body-small'}
                            style={{
                                color: colorSystem.neutral[7],
                                // textWrap is a new CSS property, the type definition is not yet available
                                // See https://developer.mozilla.org/en-US/docs/Web/CSS/text-wrap
                                // @ts-ignore
                                textWrap: 'balance',
                            }}
                        >
                            {description}
                        </Text>
                    )}
                </Flexbox>
            )}

            {children}
        </Flexbox>
    );
}
