import { t } from '@lingui/macro';
import { formatDecimal, formatRelativeTime, isPresent } from '@luminovo/commons';
import {
    colorSystem,
    createColumnHelper,
    DestructivePrimaryButton,
    DestructiveSecondaryButton,
    FieldDateControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    SecondaryButton,
    Table,
    Tag,
    TanStackTable,
    Text,
    useTanStackTable,
} from '@luminovo/design-system';
import { QuoteRequestLineItemDTO } from '@luminovo/http-client';
import { formatQuantity, formatSupplierDTO } from '@luminovo/sourcing-core';
import { Delete } from '@mui/icons-material';
import { Box, Divider, Tooltip } from '@mui/material';
import React from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { FormContainer } from '../../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../../components/formLayouts/SubmitButton';
import { LayoutCard } from '../../../../../components/LayoutCard';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import { NegotiationIpnLabel } from '../../../../Negotiations/components/NegotiationIpnLabel';
import { PartLiteLabel } from '../../../../Negotiations/components/PartLiteLabel';
import { QuoteRequestStatusChip } from '../../../../Negotiations/components/QuoteRequestStatusChip';
import {
    useQuoteRequestLineItemsByQuoteRequest,
    useSuspendedQuoteRequest,
} from '../../../../Negotiations/hooks/negotiationHandlers';
import { formatPartLite } from '../../../../Negotiations/model/formatPartLite';
import { extractManufacturer } from '../QuoteRequestAssignPage/TableManufacturers';

type FormValues = {
    dueDate: string;
    notes: string;
};

type RowDate = QuoteRequestLineItemDTO;

type SharedContext = {
    enableDeleteMode: boolean;
    setEnableDeleteMode: (enable: boolean) => void;
};

const columnHelper = createColumnHelper<RowDate>();

const columns = [
    columnHelper.text('component_origin', {
        id: 'ipn',
        label: () => t`IPN`,
        size: 150,
        cell: ({ row }) => <NegotiationIpnLabel ipn={row.original.component_origin} />,
    }),
    columnHelper.text((row) => formatPartLite(row.requested_part), {
        id: 'requestedPart',
        label: () => t`Requested part`,
        size: 240,
        cell: ({ row }) => <PartLiteLabel variant={'body-small'} part={row.original.requested_part} />,
    }),
    columnHelper.text((row) => extractManufacturer(row.requested_part).name, {
        id: 'manufacturer',
        label: () => t`Manufacturer`,
        size: 100,
        initialVisibility: false,
    }),

    columnHelper.text((row) => row.description ?? '-', {
        id: 'description',
        label: () => t`Description`,
        size: 200,
    }),

    columnHelper.number((row) => row.required_quantity.quantity, {
        id: 'requiredQuantity',
        label: () => t`Qty`,
        size: 100,
        cell: ({ row }) => formatQuantity(row.original.required_quantity, { showPiecesUnit: false }),
    }),
    columnHelper.number((row) => row.potential_quantity.quantity, {
        id: 'potentialQuantity',
        size: 100,
        label: () => t`Potential Qty`,
        cell: ({ row }) => formatQuantity(row.original.potential_quantity ?? 0, { showPiecesUnit: false }),
    }),
];

function TableQuoteRequestLineItems({ data }: { data: QuoteRequestLineItemDTO[] | undefined }) {
    const [enableDeleteMode, setEnableDeleteMode] = React.useState(false);

    const { table } = useTanStackTable({
        data,
        columns,
        enableColumnOrdering: true,
        enableColumnHiding: true,
        enableExcelExport: true,
        enableSelection: {
            enabled: enableDeleteMode,
            getRowId: (row) => String(row.id),
        },
        sharedContext: { enableDeleteMode, setEnableDeleteMode },
    });

    return <TanStackTable table={table} ActionButton={ActionButton} />;
}

function ActionButton({
    table,
    sharedContext,
}: {
    table: Table<QuoteRequestLineItemDTO>;
    sharedContext: SharedContext;
}) {
    const { enableDeleteMode, setEnableDeleteMode } = sharedContext;
    const { mutateAsync, isPending: isLoading } = useHttpMutation('POST /quote-request/line-item/delete', {
        snackbarMessage: 'Quote request line items deleted',
        onSuccess: () => {
            table.resetRowSelection();
            setEnableDeleteMode(false);
        },
    });

    if (enableDeleteMode) {
        const disabled = !(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected());
        return (
            <Flexbox gap={4}>
                <SecondaryButton
                    size="medium"
                    onClick={() => {
                        table.resetRowSelection();
                        setEnableDeleteMode(false);
                    }}
                >
                    Cancel
                </SecondaryButton>
                <Tooltip title={disabled ? 'Select rows to delete' : ''}>
                    <span>
                        <DestructivePrimaryButton
                            size="medium"
                            disabled={!(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected())}
                            startIcon={<Delete />}
                            isLoading={isLoading}
                            onClick={() => {
                                const ids = table.getSelectedRowModel().rows.map((row) => row.original.id);
                                mutateAsync({ requestBody: { ids } });
                            }}
                        >
                            Delete
                        </DestructivePrimaryButton>
                    </span>
                </Tooltip>
            </Flexbox>
        );
    }

    return (
        <DestructiveSecondaryButton size="medium" startIcon={<Delete />} onClick={() => setEnableDeleteMode(true)}>
            Delete line items
        </DestructiveSecondaryButton>
    );
}

function FormItemDueDate() {
    const { control } = useFormContext<FormValues>();
    const { errors } = useFormState<FormValues>();
    const dueDate = useWatch({ control, name: 'dueDate' });

    return (
        <FormItem label={t`Due date`}>
            <FieldDateControlled
                control={control}
                name="dueDate"
                inFuture
                minDate={new Date().toISOString()}
                FieldProps={{
                    placeholder: 'Select due date...',
                }}
            />
            <Text>{!isPresent(errors.dueDate) && Boolean(dueDate) && formatRelativeTime(dueDate)}</Text>
        </FormItem>
    );
}

function FormItemNotes() {
    const { control } = useFormContext<FormValues>();
    return (
        <FormItem label={t`Notes`}>
            <FieldTextControlled
                control={control}
                name="notes"
                FieldProps={{
                    placeholder: 'Add notes...',
                    multiline: true,
                    minRows: 2,
                }}
            />
        </FormItem>
    );
}

export function QuoteRequestDraftView({ quoteRequestId }: { quoteRequestId: string }) {
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);

    const defaultValues: FormValues = {
        dueDate: quoteRequest.due_date ?? '',
        notes: quoteRequest.notes ?? '',
    };

    const { data: quoteRequestLineItems } = useQuoteRequestLineItemsByQuoteRequest(quoteRequest.id);

    const { mutateAsync } = useHttpMutation('PATCH /quote-request/:id', {
        snackbarMessage: 'Quote request updated',
    });

    const onSubmit = (values: FormValues) => {
        mutateAsync({
            pathParams: { id: quoteRequest.id },
            requestBody: {
                // eslint-disable-next-line camelcase
                due_date: Boolean(values.dueDate) ? values.dueDate : null,
                notes: Boolean(values.notes) ? values.notes : null,
            },
        });
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Box display="grid" gridTemplateColumns="300px 5fr" gap={'16px'} height={'100%'}>
                <Flexbox flexDirection="column" gap="16px">
                    <LayoutCard>
                        <Flexbox justifyContent="space-between" gap={8}>
                            <Text variant="h3" showEllipsis>
                                {formatSupplierDTO(quoteRequest.supplier)}
                            </Text>
                            <Tag attention="low" color={'neutral'} label={`#${quoteRequest.number}`} />
                        </Flexbox>
                        <Flexbox flexDirection="column" rowGap={1}>
                            <Flexbox justifyContent="space-between" gap={8}>
                                <Text
                                    variant="body-small"
                                    color={colorSystem.neutral[8]}
                                >{t`Line items to be sent`}</Text>
                                <QuoteRequestStatusChip status={quoteRequest.status} />
                            </Flexbox>
                            <Text variant="h4">{formatDecimal(quoteRequestLineItems?.length)}</Text>
                        </Flexbox>
                        <Divider />
                        <FormItemDueDate />
                        <FormItemNotes />
                        <SubmitButton size="small" label={`Update quote request`} />
                    </LayoutCard>
                </Flexbox>

                <LayoutCard style={{ height: '80vh' }}>
                    <TableQuoteRequestLineItems data={quoteRequestLineItems} />
                </LayoutCard>
            </Box>
        </FormContainer>
    );
}
