export function DragAndDropUploadImage({ text }: { text: string }): JSX.Element {
    return (
        <svg width="596" height="104" viewBox="0 0 596 104" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="595" height="103" rx="1.5" fill="#F7F8FA" />
            <text
                x="50%"
                y="80.2"
                fill="#858CA0"
                style={{ whiteSpace: 'pre' }}
                fontFamily="INTER"
                fontSize="12"
                fontWeight="600"
                letterSpacing="0px"
                textAnchor="middle"
            >
                <tspan>{text}</tspan>
            </text>
            <path
                d="M307.8 33.3866C306.893 28.7866 302.853 25.3333 298 25.3333C294.147 25.3333 290.8 27.52 289.133 30.72C285.12 31.1466 282 34.5466 282 38.6666C282 43.08 285.587 46.6666 290 46.6666H307.333C311.013 46.6666 314 43.68 314 40C314 36.48 311.267 33.6266 307.8 33.3866ZM307.333 44H290C287.053 44 284.667 41.6133 284.667 38.6666C284.667 35.9333 286.707 33.6533 289.413 33.3733L290.84 33.2266L291.507 31.96C292.773 29.52 295.253 28 298 28C301.493 28 304.507 30.48 305.187 33.9066L305.587 35.9066L307.627 36.0533C309.707 36.1866 311.333 37.9333 311.333 40C311.333 42.2 309.533 44 307.333 44V44ZM292.667 37.3333H296.067V41.3333H299.933V37.3333H303.333L298 32L292.667 37.3333Z"
                fill="#B6BBC9"
            />
            <rect x="0.5" y="0.5" width="595" height="103" rx="1.5" stroke="#DDE1E9" strokeDasharray="4 2" />
        </svg>
    );
}
