import { useSourcingScenariosOfRfq } from '@/resources/sourcingScenario/sourcingScenarioHandlers';
import { t } from '@lingui/macro';
import { compareByStringKey, isPresent, transEnum, uniq } from '@luminovo/commons';
import {
    createColumnHelper,
    Flexbox,
    PrimaryButton,
    SupportedFilterFn,
    Tab,
    Table,
    Tabs,
    TagGroup,
    TanStackTable,
    useNavigate,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    NegotiationLineItem,
    NegotiationLineItemStatus,
    PartLite,
    PartLiteTypes,
    QuoteRequestLineItemAggregatedStatusesDTO,
    SourcingScenarioDTO,
} from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { Box } from '@mui/material';
import React from 'react';
import { LayoutCard } from '../../../../components/LayoutCard';
import { PageLayoutCollapsibleSidebar } from '../../../../components/PageLayoutCollapsibleSidebar';
import { route, UrlParams } from '../../../../utils/routes';
import { ChartNegotiationLineItemStatus } from '../../../Negotiations/components/CardSummary';
import { NegotiationIpnsLabel } from '../../../Negotiations/components/NegotiationIpnsLabel';
import { NegotiationPartsLabel } from '../../../Negotiations/components/NegotiationPartsLabel';
import {
    useNegotiationAggregatedStatuses,
    useNegotiationLineItems,
} from '../../../Negotiations/hooks/negotiationHandlers';
import { negotiationLineItemStatusTranslations } from '../../../Negotiations/i18n';
import { formatPartLite } from '../../../Negotiations/model/formatPartLite';
import {
    getNegotiationLineItemFlattenedParts,
    getNegotiationLineItemIpns,
} from '../../../Negotiations/model/getNegotiationLineItemParts';
import {
    calculateNegotiationLineItemQuoteRequestSummary,
    NegotiationLineItemQuoteRequestSummaryChip,
} from '../../../Negotiations/model/getNegotiationLineItemStatus';
import { NavigationSidebarSourcing } from '../NavigationSidebarSourcing';

export type NegotiationLineItemRowType = NegotiationLineItem & {
    aggregatedStatuses: QuoteRequestLineItemAggregatedStatusesDTO | undefined;
    sourcingScenarios: SourcingScenarioDTO[];
};

type SharedContext = {
    rfqId: string;
    negotiationId: string;
};

const columnHelper = createColumnHelper<NegotiationLineItemRowType, SharedContext>();

const columns = [
    columnHelper.array((row) => row.sourcingScenarios, {
        id: 'sourcingScenarios',
        label: () => t`Sourcing scenarios`,
        size: 130,
        getOptionLabel: (opt) => opt.name,
        getOptionKey: (opt) => opt.id,
        cell: (item) => (
            <TagGroup color="neutral" options={uniq(item.getValue())} getOptionLabel={(opt) => opt.name} limit={1} />
        ),
    }),

    columnHelper.text(
        (row) =>
            getNegotiationLineItemFlattenedParts(row)
                .map((part: PartLite) => formatPartLite(part))
                .join(', '),
        {
            id: 'requestedParts',
            label: () => t`Requested parts`,
            size: 200,
            renderType: 'text',
            enableOnRowClick: false,
            cell: ({ row }) => (
                <NegotiationPartsLabel parts={getNegotiationLineItemFlattenedParts(row.original)} hideIpns />
            ),
        },
    ),
    columnHelper.array((row) => getNegotiationLineItemIpns(row).map(formatPartLite), {
        id: 'ipn',
        label: () => t`IPN`,
        size: 150,
        getOptionKey: (ipn) => String(ipn),
        getOptionLabel: (ipn) => String(ipn),
        initialVisibility: false,
        renderType: 'text',
        enableOnRowClick: false,
        cell: (item) => <NegotiationIpnsLabel ipns={item.getValue()} />,
    }),

    columnHelper.number((row) => row.quantity.quantity, {
        id: 'quantity',
        size: 100,
        label: () => t`Quantity`,
        cell: ({ row }) => formatQuantity(row.original.quantity, { showPiecesUnit: false }),
    }),

    columnHelper.array((row) => row.demands.map((d) => d.assembly).filter(isPresent), {
        id: 'assembly',
        label: () => t`Assembly`,
        size: 150,
        getOptionLabel: (opt) => opt.name,
        getOptionKey: (opt) => opt.id,
        cell: (item) => (
            <TagGroup color="neutral" options={uniq(item.getValue())} getOptionLabel={(opt) => opt.name} limit={2} />
        ),
    }),

    columnHelper.array((row) => row.demands.map((d) => d.ship_to_site).filter(isPresent), {
        id: 'shipTo',
        label: () => t`Ship to`,
        size: 150,
        getOptionLabel: (opt) => opt.name,
        getOptionKey: (opt) => opt.id,
        initialVisibility: false,
        cell: (item) => (
            <TagGroup color="neutral" options={uniq(item.getValue())} getOptionLabel={(opt) => opt.name} limit={1} />
        ),
    }),

    columnHelper.enum(
        (row) =>
            calculateNegotiationLineItemQuoteRequestSummary(
                row.aggregatedStatuses?.statuses_by_negotiation_line_item.find(
                    (statuses) => statuses.negotiation_line_item_id === row.id,
                ),
            ).status,
        {
            id: 'quoteRequests',
            getOptionLabel: (opt) => transEnum(opt, negotiationLineItemStatusTranslations),
            label: () => t`Quote requests`,
            initialPinning: 'right',
            size: 150,
            cell: (item) => (
                <NegotiationLineItemQuoteRequestSummaryChip
                    negotiationId={item.row.original.negotiation_id}
                    negotiationLineItem={item.row.original}
                    quoteRequestSummary={calculateNegotiationLineItemQuoteRequestSummary(
                        item.row.original.aggregatedStatuses?.statuses_by_negotiation_line_item.find(
                            (statuses) => statuses.negotiation_line_item_id === item.row.original.id,
                        ),
                    )}
                />
            ),
            quickFilters: [
                {
                    label: () => `No quote requests`,
                    value: {
                        filterFn: SupportedFilterFn.equalsAny,
                        value: [NegotiationLineItemStatus.NoQuoteRequests],
                    },
                    showCount: true,
                },
            ],
        },
    ),
];

function useTableNegotiationLineItems({ negotiationId, rfqId }: { negotiationId: number; rfqId: string }) {
    const { data: lineItems } = useNegotiationLineItems(negotiationId);
    const { data: sourcingScenarios } = useSourcingScenariosOfRfq(rfqId);
    const { data: aggregatedStatuses } = useNegotiationAggregatedStatuses(negotiationId);

    const data = React.useMemo(() => {
        return lineItems
            ?.map((lineItem) => ({
                ...lineItem,
                sourcingScenarios:
                    sourcingScenarios?.filter((scenario) =>
                        scenario.assembly_demands.some((demand) => lineItem.demands.some((d) => d.id === demand.id)),
                    ) ?? [],
                aggregatedStatuses,
            }))
            .sort(compareByStringKey((row) => row.sourcingScenarios.map((scenario) => scenario.name).join(', ')));
    }, [lineItems, sourcingScenarios, aggregatedStatuses]);

    return useTanStackTable({
        data,
        columns,
        enableSelection: {
            enabled: true,
            getRowId: (row) => String(row.id),
        },
        enableRowSelection: (row) => {
            const parts = getNegotiationLineItemFlattenedParts(row.original);
            return !parts.some(
                (part) => part.kind === PartLiteTypes.Custom || part.kind === PartLiteTypes.CustomComponent,
            );
        },
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enableExcelExport: true,
        sharedContext: {
            rfqId,
            negotiationId,
        },
    });
}

function ActionButtons({
    table,
    sharedContext,
}: {
    table: Table<NegotiationLineItemRowType>;
    sharedContext: SharedContext;
}) {
    // TODO: This is a performance issue - use getIsSomeRowSelected and than to the maping on click
    const negotiationLineItems: Array<NegotiationLineItemRowType> = table
        .getSelectedRowModel()
        .rows.map((row) => row.original);

    return (
        <PrimaryButton
            size="medium"
            disabled={negotiationLineItems.length === 0}
            href={route(
                '/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/assign',
                {
                    rfqId: sharedContext.rfqId,
                    negotiationId: sharedContext.negotiationId,
                },
                {
                    lineItems: negotiationLineItems.map((item) => item.id).join(','),
                },
            )}
        >
            Add to quote request
        </PrimaryButton>
    );
}

export default function SourcingNegotiationLineItemsPage({
    pathParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items'>) {
    const { rfqId } = pathParams;
    const negotiationId = Number(pathParams.negotiationId);
    const navigate = useNavigate();

    const { table } = useTableNegotiationLineItems({ negotiationId, rfqId });

    return (
        <PageLayoutCollapsibleSidebar sidebar={<NavigationSidebarSourcing rfqId={rfqId} />} layoutVariant="fullWidth">
            <Flexbox gap="20px" flexDirection="column" height="100%">
                <Tabs sx={{ minHeight: 32 }} size="large" value={'lineItems'}>
                    <Tab
                        value={'lineItems'}
                        label={t`Line items`}
                        onClick={() =>
                            navigate(
                                route('/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items', {
                                    rfqId,
                                    negotiationId,
                                }),
                            )
                        }
                    />
                    <Tab
                        value={'quoteRequests'}
                        label={t`Quote requests`}
                        onClick={() =>
                            navigate(
                                route('/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests', {
                                    rfqId,
                                    negotiationId,
                                }),
                            )
                        }
                    />
                </Tabs>

                <LayoutCard title={t`Line items`} style={{ height: '100%', minHeight: 800 }}>
                    <ChartNegotiationLineItemStatus negotiationId={negotiationId} />
                    <Box style={{ height: 'calc(100% - 48px)' }}>
                        <TanStackTable table={table} ActionButton={ActionButtons} />
                    </Box>
                </LayoutCard>
            </Flexbox>
        </PageLayoutCollapsibleSidebar>
    );
}
