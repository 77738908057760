import { getLocale } from '../i18n';
import { isPresent } from '../typingUtils';

const enLongDateFormatter = new Intl.DateTimeFormat('en-GB', { month: 'short', year: 'numeric', day: 'numeric' });
const deLongDateFormatter = new Intl.DateTimeFormat('de', { month: 'short', year: 'numeric', day: 'numeric' });
const esLongDateFormatter = new Intl.DateTimeFormat('es', { month: 'short', year: 'numeric', day: 'numeric' });
const frLongDateFormatter = new Intl.DateTimeFormat('fr', { month: 'short', year: 'numeric', day: 'numeric' });
function getLongDateFormatter(): Intl.DateTimeFormat {
    switch (getLocale()) {
        case 'en':
            return enLongDateFormatter;
        case 'de':
            return deLongDateFormatter;
        case 'es':
            return esLongDateFormatter;
        case 'fr':
            return frLongDateFormatter;
    }
}

/**
 * Formats a date string to a localized long date format. The date will always be the
 * date specified (irrespective of the timezone).
 * This function when taking in 31-12-2024 will always return 31 Dec 2024.
 * Without this, 31-12-2024, when viewed in America/Los_Angeles timezone will return  30 Dec 2024
 *
 * @param date - A string representing a date in YYYY-MM-DD format.
 * @returns A localized string representation of the date (not including time) in long format.
 */
export const formatLocalDate = (date: string | null | undefined): string => {
    if (!date) {
        return '-';
    }

    const dateWithTimezone = new Date(date);
    const offsetMinutes = dateWithTimezone.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    // We need the minutes part too to handle cases like Mumbai time which is -330
    const offsetMinutesPart = Math.abs(offsetMinutes) % 60;
    const offsetSign = '-';
    const offset = `${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutesPart.toString().padStart(2, '0')}`;
    const dateString = `${date}T00:00:00${offset}`;

    return getLongDateFormatter().format(new Date(dateString));
};

export const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;
/**
 * Examples:
 * - en: 28 May 2021
 * - de: 28. Mai 2021
 */
export const formatToLongDate = (date: string | Date | undefined | null, options?: { ifAbsent?: string }): string => {
    if (!isPresent(date)) {
        return options?.ifAbsent ?? '-';
    }

    if (typeof date === 'string' && dateOnlyRegex.test(date)) {
        return formatLocalDate(date);
    }

    const d = date instanceof Date ? date : new Date(date);
    if (isNaN(d.getTime())) {
        return options?.ifAbsent ?? '-';
    }

    return getLongDateFormatter().format(d);
};
