import { Flexbox } from '@luminovo/design-system';
import { Popover } from '@mui/material';
import React from 'react';

export interface PopupStepperProps {
    steps: PopupStep<unknown>[];
    stepIndex: number;
    setStepIndex: (index: number) => void;
}

type PopupStepProps<T> = {
    onNext: (state?: T) => void;
    onBack: () => void;
    state: T;
    setState: (state: T) => void;
};

export type PopupStep<T> = {
    canContinue: (state: T) => boolean;
    shouldSkip?: (state: T) => boolean;
    Component: React.ComponentType<PopupStepProps<T>>;
};

/**
 * The PopupStepper component is a generic stepper that can be used to guide the user through a series of steps.
 *
 * The steps are defined by the `steps` prop.
 *
 * To access the PopupStepper, use the {@link usePopupStepper} hook.
 *
 * @see usePopupStepper
 */
function PopupStepper<T>({
    steps,
    onSubmit,
    closePopup,
    anchorEl,
    defaultState,
}: {
    steps: PopupStep<T>[];
    onSubmit: (state: T) => void;
    closePopup: () => void;
    anchorEl: HTMLElement | null;
    defaultState: T;
}): JSX.Element {
    const [stepIndex, setStepIndex] = React.useState(0);
    const [state, setState] = React.useState(defaultState);

    const currentStep = steps[stepIndex];
    const onNext = (newState: T = state) => {
        const canContinue = currentStep.canContinue(newState);
        if (!canContinue) {
            // Do not proceed if the current step is not valid
            return;
        }
        setState(newState);

        const nextNonSkippedStepIndex = steps.findIndex(
            (step, index) => index > stepIndex && (!step.shouldSkip || !step.shouldSkip(newState)),
        );

        if (nextNonSkippedStepIndex === -1) {
            closePopup();
            onSubmit(newState);
        } else if (stepIndex < steps.length - 1) {
            setStepIndex(nextNonSkippedStepIndex);
        } else {
            throw new Error('Illegal state: stepIndex is out of bounds.');
        }
    };

    const onBack = () => {
        if (stepIndex > 0) {
            setStepIndex(stepIndex - 1);
        }
        if (stepIndex === 0) {
            closePopup();
        }
    };

    const Component = currentStep.Component;

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={closePopup}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            elevation={2}
            slotProps={{
                paper: {
                    sx: {
                        borderRadius: '8px',
                    },
                },
            }}
        >
            <Flexbox sx={{ position: 'relative', flexDirection: 'column', borderRadius: 8 }}>
                <Component onNext={onNext} onBack={onBack} state={state} setState={setState} />
            </Flexbox>
        </Popover>
    );
}

/**
 * The usePopup hook is a hook that can be used to create a popup with a stepper.
 */
export function usePopupStepper<T>({
    steps,
    onSubmit,
    defaultState,
}: {
    steps: PopupStep<T>[];
    onSubmit: (state: T) => void;
    defaultState: T;
}): {
    anchorEl: HTMLElement | null;
    openPopup: (event: React.MouseEvent<HTMLElement>) => void;
    closePopup: () => void;
    popup: JSX.Element | null;
} {
    const [isPopupOpen, setPopupOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const openPopup = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setPopupOpen(true);
    };

    const closePopup = () => {
        setAnchorEl(null);
        setPopupOpen(false);
    };
    const popup = isPopupOpen ? (
        <PopupStepper
            defaultState={defaultState}
            steps={steps}
            onSubmit={onSubmit}
            anchorEl={anchorEl}
            closePopup={closePopup}
        />
    ) : null;

    return {
        anchorEl,
        openPopup,
        closePopup,
        popup,
    };
}
