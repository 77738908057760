import { t } from '@lingui/macro';
import { Flexbox, Message, useNavigate } from '@luminovo/design-system';
import { CustomerPortalRequirement, PcbIssue, PcbState } from '@luminovo/http-client';
import { LinearProgress } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { httpQueryKey } from '../../../../../resources/http/httpQueryKey';
import { route } from '../../../../../utils/routes';
import { StepContainer } from './StepContainer';
export const PcbStep = ({
    pcbState,
    rfqId,
    assemblyId,
    isRfqEditable,
    requirement,
}: {
    pcbState: PcbState;
    rfqId: string;
    assemblyId: string;
    isRfqEditable: boolean;
    requirement: CustomerPortalRequirement;
}): JSX.Element => {
    const status = pcbState.status.type;

    useInvalidateQueryIfPcbAnalysisIsInProgress(rfqId, pcbState);

    return (
        <StepContainer
            status={status}
            label={t`PCB`}
            type="pcb"
            rfqId={rfqId}
            assemblyId={assemblyId}
            isRfqEditable={isRfqEditable}
            requirement={requirement}
        >
            {pcbState.status.type === 'InProgress' && (
                <Flexbox flexDirection="column" gap="8px" width="100%">
                    {pcbState.status.issues
                        .sort((a, b) => a.type.localeCompare(b.type))
                        .map((issue) => (
                            <PcbIssueLine {...getIssueLineProps(issue, rfqId, assemblyId)} key={issue.type} />
                        ))}
                </Flexbox>
            )}
        </StepContainer>
    );
};

function getShouldRefetch(pcbState: PcbState): boolean {
    if (pcbState.status.type !== 'InProgress') {
        return false;
    }

    const issues = pcbState.status.issues;

    const isSingleIssueNotApproved =
        issues.length === 1 && issues.find((issue) => issue.type === 'NotApproved') !== undefined;

    return !isSingleIssueNotApproved;
}

function useInvalidateQueryIfPcbAnalysisIsInProgress(rfqId: string, pcbState: PcbState) {
    const queryClient = useQueryClient();
    const shouldRefetch = getShouldRefetch(pcbState);

    useEffect(() => {
        const id = setInterval(() => {
            if (shouldRefetch) {
                queryClient.invalidateQueries({
                    queryKey: [
                        httpQueryKey('POST /rfqs/:rfqId/customer-portal', { pathParams: { rfqId } }),
                        httpQueryKey('GET /rfqs/:rfqId', { pathParams: { rfqId } }),
                        httpQueryKey('GET /rfqs'),
                    ],
                });
            }
        }, 5000);

        return () => clearInterval(id);
    }, [shouldRefetch, queryClient, rfqId]);
}

interface PcbIssueLineProps {
    message: string;
    level: 'Warning' | 'Error';
    link?: string;
    child?: JSX.Element;
}

const EmptyIcon = (): JSX.Element => <></>;

const PcbIssueLine = ({ message, level, link }: PcbIssueLineProps): JSX.Element => {
    const navigate = useNavigate();

    return (
        <Message
            variant={level === 'Error' ? 'red' : 'yellow'}
            title={message}
            size="small"
            attention="low"
            action={link ? { label: t`Go to PCB`, onClick: () => navigate(link) } : undefined}
            onClose={undefined}
            overrides={{ MessageIcon: EmptyIcon }}
        />
    );
};

function getIssueLineProps(issue: PcbIssue, rfqId: string, assemblyId: string): PcbIssueLineProps {
    const specificationLink = route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification', { rfqId, assemblyId });
    const filesLink = route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files', { rfqId, assemblyId });

    switch (issue.type) {
        case 'AnalysisError':
            return {
                level: 'Error',
                message: t`Error during PCB analysis`,
                link: specificationLink,
            };
        case 'IsAnalyzing':
            return {
                level: 'Warning',
                message: t`Analysis in progress`,
                child: (
                    <LinearProgress
                        variant="determinate"
                        value={issue.progress}
                        style={{ flexGrow: 1, marginBottom: '4px' }}
                    />
                ),
            };
        case 'NoFiles':
            return {
                level: 'Error',
                message: t`No file upload`,
                link: filesLink,
            };
        case 'NoOffer':
            return {
                level: 'Error',
                message: t`No offer`,
            };
        case 'NotApproved':
            return {
                level: 'Warning',
                message: t`PCB data not fully saved`,
                link: specificationLink,
            };
    }
}
