import { colorSystem, Flexbox } from '@luminovo/design-system';
import { DriverDTO, DriverStatusDetailsDTO } from '@luminovo/http-client';
import { selectDriverIdDto, selectDriverName, selectDriverNotes, selectDriverType } from '@luminovo/manufacturing-core';
import { Grid, Typography } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { Skeleton } from '../../../components/Spinners';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { DriverInformation, EditActivityConfiguration } from '../ActivitiesTable/editActivityConfigurations';
import { DriverCountInformation } from '../ActivitiesTable/getDriverCountInformation';
import { EditExpenseConfiguration } from '../ExpensesTable/editExpensesConfigurations';
import { DriverStatusIcon } from './DriverStatusCellIcon';
import { DriverCell } from './manufacturingComponents';

interface DriverDetailsRowProps {
    driverDetails: DriverDTO;
    driverStatus: DriverStatusDetailsDTO | undefined;
    countInformation: DriverCountInformation;
    activityConfigurationId?: string;
    expenseConfigurationId?: string;
}

const EditConfiguration = ({
    driverInformation,
    expenseConfigurationId,
    activityConfigurationId,
    manufacturingScenarioId,
    assemblyIdOfSelectedManufacturingScenario,
}: {
    driverInformation: DriverInformation;
    expenseConfigurationId: string | undefined;
    activityConfigurationId: string | undefined;
    manufacturingScenarioId: string;
    assemblyIdOfSelectedManufacturingScenario: string;
}) => {
    if (activityConfigurationId) {
        return (
            <EditActivityConfiguration
                driverInformation={driverInformation}
                activityConfigurationInformation={{ activityConfigurationId }}
                selectedManufacturingScenarioId={manufacturingScenarioId}
                assemblyIdOfSelectedManufacturingScenario={assemblyIdOfSelectedManufacturingScenario}
            />
        );
    }

    if (expenseConfigurationId) {
        return (
            <EditExpenseConfiguration
                driverInformation={driverInformation}
                expenseConfigurationId={expenseConfigurationId}
                selectedManufacturingScenarioId={manufacturingScenarioId}
                assemblyIdOfSelectedManufacturingScenario={assemblyIdOfSelectedManufacturingScenario}
            />
        );
    }

    return <></>;
};

const displayCount = (driverCount: number | undefined, driver: DriverDTO): string => {
    if (driverCount !== undefined) {
        return driverCount.toString();
    } else if (selectDriverType(driver) === 'User+Manual') {
        return '-';
    } else {
        return '';
    }
};

export const DriverDetailsRow = ({
    driverDetails,
    driverStatus,
    countInformation,
    activityConfigurationId,
    expenseConfigurationId,
}: DriverDetailsRowProps): JSX.Element => {
    const { manufacturingAssemblyDetailsId, manufacturingScenarioId, rfqId } = useParams<{
        rfqId: string;
        manufacturingAssemblyDetailsId: string;
        manufacturingScenarioId: string;
    }>();

    const { data, isLoading } = useHttpQuery('GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId', {
        pathParams: { manufacturingAssemblyDetailsId },
    });
    const driverInformation: DriverInformation = {
        automaticDriverCount: countInformation.automaticDriverCount,
        driverCount: countInformation.driverCount,
        driverId: selectDriverIdDto(driverDetails),
        driverName: selectDriverName(driverDetails),
        driverNotes: selectDriverNotes(driverDetails),
    };

    return (
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item xs={8}>
                <DriverCell driverId={selectDriverIdDto(driverDetails)} />
            </Grid>

            <Grid item xs={4}>
                <Flexbox gap={'20px'} alignItems={'center'} justifyContent={'end'}>
                    <Typography variant={'body1'} style={{ color: colorSystem.neutral[9] }}>
                        {displayCount(countInformation.driverCount, driverDetails)}
                    </Typography>
                    {isLoading || data === undefined ? (
                        <Skeleton />
                    ) : (
                        <>
                            <EditConfiguration
                                driverInformation={driverInformation}
                                expenseConfigurationId={expenseConfigurationId}
                                activityConfigurationId={activityConfigurationId}
                                manufacturingScenarioId={manufacturingScenarioId}
                                assemblyIdOfSelectedManufacturingScenario={data.data.assembly_id}
                            />
                            <DriverStatusIcon
                                driverStatus={driverStatus}
                                rfqId={rfqId}
                                manufacturingAssemblyDetails={data.data}
                                iconType="status"
                            />
                        </>
                    )}
                </Flexbox>
            </Grid>
        </Grid>
    );
};
