/* eslint-disable camelcase */
import { CustomPartOfferInputDTO, CustomPartOfferUpdateDTO } from '@luminovo/http-client';
import { nullToUndefined, undefinedToNull } from '../../../utils/typingUtils';
import { CustomPartOfferFormValues } from './CustomPartOfferForm';

export function convertToCustomPartOfferUpdateDTO(values: CustomPartOfferFormValues): CustomPartOfferUpdateDTO {
    return {
        linked_part: values.linkedPart,
        supplier_and_stock_location: values.supplierAndStockLocation.id,
        unit_of_measurement: values.quantityUnit,
        currency: values.currency,
        price_points: values.pricePoints,
        one_time_costs: values.oneTimeCosts,
        valid_until: undefinedToNull(values.validUntilDate),
        notes: undefinedToNull(values.notes),
        price_type: values.priceType,
        sourcing_batch_size: values.sourcingBatchSize,
        specification: values.specification
            ? values.specification.data.panel.type === 'PanelDetails'
                ? {
                      // Panel Details
                      type: values.specification.type,
                      data: values.specification.data.panel,
                  }
                : {
                      // Existing Panel
                      type: values.specification.type,
                      data: {
                          type: values.specification.data.panel.type,
                          data: {
                              ...values.specification.data.panel.data,
                              id: values.specification.data.panel.data.pcb,
                          },
                      },
                  }
            : undefined,
    };
}

export function convertToCustomPartOfferInputDTO(values: CustomPartOfferFormValues): CustomPartOfferInputDTO {
    return {
        linked_part: values.linkedPart,
        supplier_and_stock_location: values.supplierAndStockLocation.id,
        unit_of_measurement: values.quantityUnit,
        currency: values.currency,
        price_points: values.pricePoints,
        one_time_costs: values.oneTimeCosts,
        valid_until: values.validUntilDate,
        notes: nullToUndefined(values.notes),
        price_type: values.priceType,
        offer_number: undefined,
        sourcing_scenario_id: undefined,
        sourcing_batch_size: values.sourcingBatchSize,
    };
}
