import { Box, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { colorSystem, INTER } from '../../theme';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

interface CollapsedBreadcrumbs {
    type: 'collapsed-breadcrumb';
    title: string;
    breadcrumbs: BreadcrumbItem[];
}

export interface BreadcrumbItem {
    type?: 'regular-breadcrumb';
    href?: string;
    title: JSX.Element | string;
}

interface ToolbarProps extends React.PropsWithChildren<{}> {
    breadcrumbs: Array<BreadcrumbItem>;
    left?: ReactNode;
    center?: ReactNode;
    style?: React.CSSProperties;
    hideLastBreadcrumb?: boolean;
}

const centerStyle: React.CSSProperties = { placeItems: 'center' };

export function Toolbar({
    breadcrumbs,
    center,
    children: right,
    left,
    style,
    hideLastBreadcrumb = false,
}: ToolbarProps): JSX.Element {
    const collapsedBreadcrumbs = collapseBreadcrumbs(breadcrumbs, hideLastBreadcrumb);
    return (
        <Container style={style}>
            <BreadcrumbsContainer>
                {collapsedBreadcrumbs.map((breadcrumb, i) => {
                    return (
                        <React.Fragment key={i}>
                            {i > 0 && (
                                <Text variant="h5" color={colorSystem.neutral[6]}>
                                    {'/'}
                                </Text>
                            )}
                            <Breadcrumb
                                key={i}
                                breadcrumb={breadcrumb}
                                isCurrent={i === collapsedBreadcrumbs.length - 1}
                            />
                        </React.Fragment>
                    );
                })}
                {left}
            </BreadcrumbsContainer>
            <Box display="grid" style={centerStyle}>
                {center}
            </Box>
            <ActionsContainer>
                <React.Suspense fallback={<></>}>{right}</React.Suspense>
            </ActionsContainer>
        </Container>
    );
}

const Container = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr fit-content(32%) 1fr',
    padding: '8px 20px',
    borderBottom: `1px solid ${colorSystem.neutral[2]}`,
    alignItems: 'center',
    background: colorSystem.neutral.white,
    boxSizing: 'border-box',
    minHeight: 49,
});

const BreadcrumbsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: 2,
});

const ActionsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    justifyContent: 'flex-end',
});

function Breadcrumb({
    breadcrumb,
    isCurrent,
}: {
    breadcrumb: BreadcrumbItem | CollapsedBreadcrumbs;
    isCurrent: boolean;
}) {
    if (isCurrent) {
        return <StyledCurrentBreadcrumb variant={'h4'}>{breadcrumb.title}</StyledCurrentBreadcrumb>;
    }
    if (breadcrumb.type === 'collapsed-breadcrumb') {
        return (
            <Tooltip
                variant="white"
                arrow
                title={
                    <BreadcrumbsContainer>
                        {breadcrumb.breadcrumbs.map((breadcrumb, i) => {
                            return (
                                <React.Fragment key={i}>
                                    {i > 0 && (
                                        <Text variant="h5" color={colorSystem.neutral[6]}>
                                            {'/'}
                                        </Text>
                                    )}

                                    <StyledBreadcrumbSmall to={breadcrumb.href ?? '#'}>
                                        <Text color={'inherit'}>{breadcrumb.title}</Text>
                                    </StyledBreadcrumbSmall>
                                </React.Fragment>
                            );
                        })}
                    </BreadcrumbsContainer>
                }
            >
                <StyledBreadcrumbLink to="#">
                    <Text variant="h5" color={colorSystem.neutral[6]}>
                        {breadcrumb.title}
                    </Text>
                </StyledBreadcrumbLink>
            </Tooltip>
        );
    }

    return <StyledBreadcrumbLink to={breadcrumb.href ?? '#'}>{breadcrumb.title}</StyledBreadcrumbLink>;
}

const StyledCurrentBreadcrumb = styled(Text)({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 400,
    padding: '4px 8px',
    '&:hover': {
        color: colorSystem.neutral[9],
        backgroundColor: colorSystem.neutral[1],
    },
    borderRadius: 4,
});

const StyledBreadcrumbLink = styled(Link)({
    textDecoration: 'none',
    color: colorSystem.neutral[6],
    '&:hover': {
        color: colorSystem.neutral[9],
        backgroundColor: colorSystem.neutral[1],
    },
    borderRadius: 4,
    padding: '4px 8px',
    fontFamily: INTER,
    minWidth: 0,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
});

const StyledBreadcrumbSmall = styled(Link)({
    textDecoration: 'none',
    color: colorSystem.neutral[8],
    borderRadius: 4,
    padding: '4px 8px',
    '&:hover': {
        color: colorSystem.neutral[9],
        backgroundColor: colorSystem.neutral[1],
    },
    fontFamily: INTER,
    minWidth: 0,
    fontSize: 12,
    lineHeight: '20px',
});

function collapseBreadcrumbs(
    breadcrumbs: BreadcrumbItem[],
    hideLastBreadcrumb: boolean,
): Array<BreadcrumbItem | CollapsedBreadcrumbs> {
    const breadcrumbsToShow = hideLastBreadcrumb ? breadcrumbs.slice(0, -1) : breadcrumbs;

    if (breadcrumbsToShow.length <= 3) {
        return breadcrumbsToShow;
    }
    const first = breadcrumbsToShow[0];
    const lastTwo = breadcrumbsToShow.slice(-2);
    return [
        first,
        {
            type: 'collapsed-breadcrumb',
            title: '...',
            breadcrumbs: breadcrumbsToShow.slice(1, -2),
        },
        ...lastTwo,
    ];
}
