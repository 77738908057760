import { Trans } from '@lingui/macro';
import { BackButton, CheckableChip, colorSystem, Flexbox, Link, PrimaryButton, Text } from '@luminovo/design-system';
import { ChevronRight, RocketLaunch } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { StepHelpDialog } from './components/StepHelpDialog';
import { HelpDialog } from './HelpDialog';
import { Step, StepperProps } from './types';

export function Stepper({
    title = '',
    stepIndex,
    steps,
    onStepIndexChange = () => {},
    onSubmit,
    hrefBack,
    tools,
    ...props
}: StepperProps): JSX.Element {
    const step = steps[stepIndex];
    const [showHelpDialog, setShowHelpDialog] = React.useState(false);
    useArrowKeyNavigation(onStepIndexChange, stepIndex, steps.length);
    const { isPending, mutateAsync } = useMutation({
        mutationFn: onSubmit,
    });

    const isLastStep = stepIndex === steps.length - 1;
    const isNextStepDisabled = !isLastStep && !steps[stepIndex + 1].enabled;

    const nextButton = (
        <PrimaryButton
            sx={{ justifySelf: 'flex-end' }}
            disabled={isNextStepDisabled}
            disableFocusRipple
            autoFocus={!isNextStepDisabled}
            size="medium"
            onClick={() => onStepIndexChange('next')}
        >
            <Trans>Next</Trans>
        </PrimaryButton>
    );

    const submitButton = (
        <PrimaryButton
            isLoading={isPending}
            sx={{ justifySelf: 'flex-end' }}
            startIcon={<RocketLaunch />}
            disabled={!step.isDone}
            size="medium"
            onClick={() => mutateAsync()}
        >
            <Trans>Import</Trans>
        </PrimaryButton>
    );

    return (
        <Flexbox
            position={'relative'}
            flexDirection={'column'}
            height={'100vh'}
            width={'100vw'}
            boxSizing={'border-box'}
            bgcolor={colorSystem.neutral.white}
            {...props}
        >
            <Box
                display={'grid'}
                gridTemplateColumns={'1fr auto 1fr'}
                padding={'8px'}
                gap={'8px'}
                alignItems={'center'}
                justifyItems={'end'}
                borderBottom={`1px solid ${colorSystem.neutral[2]}`}
            >
                <Box sx={{ alignItems: 'center', justifySelf: 'flex-start', gap: 1, display: 'flex' }}>
                    <BackButton href={hrefBack ?? '/'} />
                    <Text variant="h3">{title}</Text>
                </Box>
                <StepperNavigation onStepIndexChange={onStepIndexChange} stepIndex={stepIndex} steps={steps} />

                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
                    {step.helpButton && (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <Link
                                attention="high"
                                variant="body-small"
                                onClick={() => {
                                    setShowHelpDialog(true);
                                }}
                            >
                                {step.helpButton.buttonText}
                            </Link>
                        </span>
                    )}
                    {tools}
                    {!isLastStep ? nextButton : submitButton}
                </div>
            </Box>

            <Flexbox flexGrow={1} maxHeight={'100vh'} overflow={'auto'}>
                <StepView step={step} steps={steps} onStepIndexChange={onStepIndexChange} stepIndex={stepIndex} />
            </Flexbox>
            <HelpDialog
                title={step.helpButton?.title ?? ''}
                body={step.helpButton?.body ?? <></>}
                width={'md'}
                showDialog={showHelpDialog}
                onClose={() => {
                    setShowHelpDialog(false);
                }}
            />
        </Flexbox>
    );
}

export function useStepperState(steps: number): {
    stepIndex: number;
    setStepIndex: StepperProps['onStepIndexChange'];
} {
    const [stepIndex, setStepIndex] = React.useState(0);
    return {
        stepIndex,
        setStepIndex: (step: number | 'next' | 'prev') => {
            let numStep = 0;
            if (typeof step === 'number') {
                numStep = step;
            }
            if (step === 'next') {
                numStep = stepIndex + 1;
            }
            if (step === 'prev') {
                numStep = stepIndex - 1;
            }

            if (numStep <= 0) {
                setStepIndex(0);
                return;
            }
            if (numStep >= steps) {
                setStepIndex(steps - 1);
                return;
            }
            setStepIndex(numStep);
        },
    };
}

function useArrowKeyNavigation(onStepIndexChange: (stepIndex: number) => void, stepIndex: number, stepsLength: number) {
    React.useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (!event.metaKey) {
                return;
            }
            if (event.key === 'Enter') {
                event.preventDefault();
                onStepIndexChange(stepIndex + 1);
            } else if (event.key === 'Backspace') {
                event.preventDefault();
                onStepIndexChange(stepIndex - 1);
            }
        }

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onStepIndexChange, stepIndex, stepsLength]);
}

function StepView({ step, onStepIndexChange }: StepperProps & { step: Step }) {
    const dialog = step.helpDialog ? <StepHelpDialog key={step.label} {...step.helpDialog} /> : null;

    if (typeof step.content === 'function') {
        const Component = step.content;
        return (
            <>
                {dialog}
                <Component onStepIndexChange={onStepIndexChange} />
            </>
        );
    }
    return (
        <>
            {dialog}
            {step.content}
        </>
    );
}

function StepperNavigation({ stepIndex, steps, onStepIndexChange = () => {} }: StepperProps) {
    return (
        <Flexbox
            sx={{
                background: colorSystem.neutral.white,
                borderRadius: '64px',
                alignItems: 'center',
            }}
            gap={4}
            alignItems={'center'}
        >
            {steps.map((step, i) => (
                <React.Fragment key={i}>
                    {i > 0 && <ChevronRight color="disabled" />}
                    <StepperButton
                        step={step}
                        number={i + 1}
                        onClick={() => onStepIndexChange(i)}
                        isSelected={stepIndex === i}
                    />
                </React.Fragment>
            ))}
        </Flexbox>
    );
}

function StepperButton({
    isSelected,
    number,
    onClick,
    step,
}: {
    isSelected: boolean;
    number: number;
    onClick: () => void;
    step: Step;
}) {
    return (
        <CheckableChip
            onClick={() => onClick()}
            label={step.label ?? ''}
            selected={isSelected}
            count={number}
            done={step.isDone}
            disabled={!step.enabled}
        />
    );
}
