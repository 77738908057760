/* eslint-disable camelcase */
import { assertPresent, isPresent, throwErrorUnlessProduction } from '@luminovo/commons';
import { PCBV2BoardPropertiesValuesDTO } from '@luminovo/http-client';
import {
    ExtractedAttributeDTO,
    ExtractedAttributeValueDTO,
    ExtractionResultsDTO,
    MergedResultDTO,
} from '@luminovo/http-client/src/resources/pcb/pcbExtractionResultBackendTypes';
import { MergedAttributes, PcbAttribute } from '@luminovo/pdf-extractor';
import { BoundingBox } from '@luminovo/pdf-extractor/src/framework/boundingBox';
import { FileWithExtractedPcbSpec } from './types';

type PcbAttributeKeys = PcbAttribute extends { attr: infer K } ? K : never;

const convertToExtractedAttributeValue = (
    propertyValue: PCBV2BoardPropertiesValuesDTO,
): ExtractedAttributeValueDTO | undefined => {
    // TODO: Change this to store the object in the database with the other attributes
    const property =
        typeof propertyValue === 'object' && 'value' in propertyValue ? propertyValue.value : propertyValue;

    if (Array.isArray(property)) {
        return {
            // eslint-disable-next-line spellcheck/spell-checker
            type: 'StrArray',
            data: property,
        };
    }
    if (typeof property === 'string') {
        return {
            // eslint-disable-next-line spellcheck/spell-checker
            type: 'Str',
            data: property,
        };
    }
    if (typeof property === 'number') {
        if (property % 1 !== 0) {
            return {
                type: 'Float',
                data: property,
            };
        } else {
            return {
                type: 'Int',
                data: property,
            };
        }
    }
    if (typeof property === 'boolean') {
        return {
            // eslint-disable-next-line spellcheck/spell-checker
            type: 'Bool',
            data: property,
        };
    }

    throwErrorUnlessProduction(new Error(`Unknown property type: ${typeof property}`));
    return undefined;
};

// Convert from the pdf-extractor format to the backend format
export const convertExtractedValuesToRequestBody = (extractedValues: FileWithExtractedPcbSpec): MergedResultDTO[] => {
    return Object.entries(extractedValues.extracted).map(([_key, value]) => {
        const extractedRegion = value.regions[0];
        return {
            id: extractedRegion.id,
            page_number: extractedRegion.pageNumber,
            location_on_page: {
                x: extractedRegion.box.x.toString(),
                y: extractedRegion.box.y.toString(),
                width: extractedRegion.box.width.toString(),
                height: extractedRegion.box.height.toString(),
            },
            attributes: extractedRegion.attributes
                .map((attribute): ExtractedAttributeDTO => {
                    return {
                        name: attribute.attr,
                        // @ts-ignore
                        value: attribute.value ? convertToExtractedAttributeValue(attribute.value) : undefined,
                        confidence: attribute.confidence?.toString(),
                    };
                })
                .filter(({ value }) => isPresent(value)),
        };
    });
};

// Convert from the backend format to the pdf-extractor format
export const convertResponseBodyToExtractedValues = (response: ExtractionResultsDTO): FileWithExtractedPcbSpec => {
    let result: MergedAttributes<PcbAttribute> = {};

    for (const item of response.merged_results) {
        for (const attr of item.attributes) {
            // Some known keys are not in the PcbAttribute type, so we need to skip them
            if (['yes', 'no'].includes(attr.name)) {
                continue;
            }

            const name = attr.name as keyof MergedAttributes<PcbAttribute>;
            let tempResultName = result[name];

            // If it doesn't exist, create it
            if (tempResultName === undefined) {
                tempResultName = {
                    regions: [],
                    value: attr.value.data,
                };
            }

            // if it does exist, add the region
            assertPresent(tempResultName).regions.push({
                id: item.id,
                content: '',
                pageNumber: item.page_number,
                box: new BoundingBox(
                    parseFloat(item.location_on_page.x),
                    parseFloat(item.location_on_page.y),
                    parseFloat(item.location_on_page.width),
                    parseFloat(item.location_on_page.height),
                ),
                //@ts-ignore
                attributes: item.attributes.map((a) => ({
                    attr: a.name as PcbAttributeKeys,
                    value: a.value.data,
                    confidence: a.confidence ? parseFloat(a.confidence) : undefined,
                })),
            });

            result = {
                ...result,
                [name]: tempResultName,
            };
        }
    }

    return {
        extracted: result,
        file: response.file,
    };
};
