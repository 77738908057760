/* eslint-disable camelcase */
import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import {
    ApprovalStatusRuntype,
    ComplianceStatus,
    ComplianceStatusRuntype,
    EmissionDataRuntype,
    MonetaryValueBackendRuntype,
    QualificationRuntype,
    QuantityUnitDTORuntype,
    RohsVersionRuntype,
} from '../backendTypes';
import { ManufacturerDTORuntype } from '../manufacturer';
import { PackagingRuntype } from '../offer/Packaging';
import { RegionsEnumRuntype } from '../supplierAndStockLocation';

export enum StandardPartTypes {
    Generic = 'Generic',
    OffTheShelf = 'OffTheShelf',
    Ipn = 'Ipn',
}

export type StandardPartDTO = r.Static<typeof StandardPartDTORuntype>;
export const StandardPartDTORuntype = r.Record({
    type: runtypeFromEnum(StandardPartTypes),
    data: r.String, // The part ID
});

export enum PartIdTypes {
    Generic = 'Generic',
    OffTheShelf = 'OffTheShelf',
    Ipn = 'Ipn',
    Custom = 'Custom',
    CustomComponent = 'CustomComponent',
    RawSpecification = 'RawSpecification',
}

export type PartDTO = r.Static<typeof PartDTORuntype>;
export const PartDTORuntype = r.Record({
    type: runtypeFromEnum(PartIdTypes),
    data: r.String, // The part ID
});

export type CandidateCpn = r.Static<typeof CandidateCpnRuntype>;
export const CandidateCpnRuntype = r.Record({
    value: r.String,
    revision: r.String.nullable(),
});

export function formatCandidateCpn(cpn: CandidateCpn): string {
    if (cpn.revision !== null) {
        return `${cpn.value}`;
    }
    return `${cpn.value}: ${cpn.revision}`;
}

export function isCandidateCpn(cpn?: CandidateCpn | String | undefined | null): cpn is CandidateCpn {
    return !!cpn && typeof cpn !== 'string' && 'value' in cpn && 'revision' in cpn;
}

export type ColumnOrigin = r.Static<typeof ColumnOriginRuntype>;
export const ColumnOriginRuntype = r.Record({
    column: r.String,
    candidate: r.Union(r.String, CandidateCpnRuntype),
});

export enum CustomPartTypeEnum {
    PCB = 'PCB',
    Mechanical = 'Mechanical',
    Plastic = 'Plastic',
    Cable = 'Cable',
    Packaging = 'Packaging',
    Label = 'Label',
    Other = 'Other',
}

export const CustomPartTypeEnumRuntype = runtypeFromEnum(CustomPartTypeEnum);

export type PartCategoryDTO = r.Static<typeof PartCategoryDTORuntype>;
export const PartCategoryDTORuntype = r.Record({
    id: r.Number,
    name: r.String,
    depth: r.Number,
    children: r.Array(r.Number),
});

export type CustomPartType = r.Static<typeof CustomPartTypeRuntype>;
export const CustomPartTypeRuntype = r.Union(
    r.Record({
        name: r.Literal(CustomPartTypeEnum.PCB),
        content: r.String.nullable(), // this is the pcbId
    }),
    r.Record({
        name: r.Literal(CustomPartTypeEnum.Mechanical),
    }),
    r.Record({
        name: r.Literal(CustomPartTypeEnum.Plastic),
    }),
    r.Record({
        name: r.Literal(CustomPartTypeEnum.Cable),
    }),
    r.Record({
        name: r.Literal(CustomPartTypeEnum.Packaging),
    }),
    r.Record({
        name: r.Literal(CustomPartTypeEnum.Label),
    }),
    r.Record({
        name: r.Literal(CustomPartTypeEnum.Other),
    }),
);

export interface CustomPartUpdateInput extends r.Static<typeof CustomPartUpdateInputRuntype> {}
export const CustomPartUpdateInputRuntype = r.Record({
    description: r.String.nullable(),
    type: CustomPartTypeRuntype.nullable(),
    reach_compliant: ComplianceStatusRuntype.nullable(),
    rohs_compliant: ComplianceStatusRuntype.nullable(),
});

export type CustomPartInput = r.Static<typeof CustomPartInputRuntype>;
export const CustomPartInputRuntype = r.Record({
    description: r.String.nullable(),
    type: CustomPartTypeRuntype,
    reach_compliant: ComplianceStatusRuntype,
    rohs_compliant: ComplianceStatusRuntype,
});

export interface CustomFullPart extends r.Static<typeof CustomFullPartRuntype> {}
export const CustomFullPartRuntype = r.Record({
    id: r.String,
    description: r.Null.Or(r.String),
    type: CustomPartTypeRuntype,
    reach_compliant: ComplianceStatusRuntype,
    rohs_compliant: ComplianceStatusRuntype,
});

export interface FileUrl extends r.Static<typeof FileUrlRuntype> {}
export const FileUrlRuntype = r.Record({
    url: r.String,
});

export enum LifecycleEnum {
    PreRelease = 'PreRelease',
    Active = 'Active',
    NotRecommendedForNewDesigns = 'NotRecommendedForNewDesigns',
    Aftermarket = 'Aftermarket',
    EndOfLife = 'EndOfLife',
    Obsolete = 'Obsolete',
    Acquired = 'Acquired',
    Unknown = 'Unknown',
}

export type PackageTag = r.Static<typeof PackageTagRuntype>;
export const PackageTagRuntype = r.Union(
    r.Literal('bom-importer-extractable'),
    r.Literal('generic-part-creatable'),
    r.Literal('user-selectable'),
    r.Literal('migration'),
    r.Literal('UserInput'),
    r.Literal('not-description-extractable'),
);

export enum PackageMountingEnum {
    SMT = 'SMT',
    THT = 'THT',
    PressFit = 'Press Fit',
    Other = 'Other',
}

export interface PackageFamily extends r.Static<typeof PackageFamilyRuntype> {}
export const PackageFamilyRuntype = r.Record({
    name: r.String,
    mounting: runtypeFromEnum(PackageMountingEnum),
});

export interface PackageDTO extends r.Static<typeof PackageDTORuntype> {}
export const PackageDTORuntype = r.Record({
    id: r.String,
    name: r.String.nullable(),
    aliases: r.Array(r.String),
    number_of_pins: r.Number.nullable().optional(),
    mounting: runtypeFromEnum(PackageMountingEnum).optional().nullable(),
    tags: r.Array(PackageTagRuntype),
});

// create and edit ots
export interface PackageUserInput extends r.Static<typeof PackageUserInputRuntype> {}
export const PackageUserInputRuntype = r.Record({
    name: r.String.nullable().optional(),
    number_of_pins: r.Number.nullable().optional(),
    mounting: runtypeFromEnum(PackageMountingEnum).nullable().optional(),
});

// Beware that origin for parts is different from origin for offers!
export enum OTSPartOriginEnum {
    Manual = 'Manual',
    Import = 'Import',
    Aggregate = 'Aggregate',
    DescriptionExtraction = 'DescriptionExtraction',
    // ----
    ArrowAPI = 'ArrowAPI',
    AvnetAPI = 'AvnetAPI',
    AvnetUsaAPI = 'AvnetUsaAPI',
    BuerklinAPI = 'BuerklinAPI',
    ChipAssistAPI = 'ChipAssistAPI',
    DigikeyAPI = 'DigikeyAPI',
    Element14API = 'Element14API',
    EveAPI = 'EveAPI',
    FarnellAPI = 'FarnellAPI',
    FutureAPI = 'FutureAPI',
    GudecoAPI = 'GudecoAPI',
    HeilindAPI = 'HeilindAPI',
    IHSImport = 'IHSImport',
    MasterAPI = 'MasterAPI',
    MouserAPI = 'MouserAPI',
    MyArrowAPI = 'MyArrowAPI',
    LcscAPI = 'LcscAPI',
    NewarkAPI = 'NewarkAPI',
    OctopartAPI = 'OctopartAPI',
    OnlineComponentsAPI = 'OnlineComponentsAPI',
    RsComponentsAPI = 'RsComponentsAPI',
    RochesterAPI = 'RochesterAPI',
    QuestComponentsAPI = 'QuestComponentsAPI',
    RutronikAPI = 'RutronikAPI',
    SchukatAPI = 'SchukatAPI',
    SluiceBoxAPI = 'SluiceBoxAPI',
    SosAPI = 'SosAPI',
    SourcengineAPI = 'SourcengineAPI',
    TiAPI = 'TiAPI',
    TtiAPI = 'TtiAPI',
    TmeAPI = 'TmeAPI',
    TrustedPartsAPI = 'TrustedPartsAPI',
    VenkelAPI = 'VenkelAPI',
    WeltronAPI = 'WeltronAPI',
    WuerthEisosAPI = 'WuerthEisosAPI',
}

const OTSPartOriginEnumRuntype = runtypeFromEnum(OTSPartOriginEnum);

const OctopartOriginDataRuntype = r.Record({
    part_id: r.String,
    manufacturer: r.Record({
        manufacturer_id: r.String,
        manufacturer_name: r.String,
    }),
});

const IHSOriginDataRuntype = r.Record({
    part_number: r.String,
    manufacturer: r.Record({
        short_manufacturer_name: r.String,
        manufacturer_name: r.String,
    }),
});

const SiliconExpertIdRuntype = r.Record({
    se_id: r.String,
});

const DescriptionExtractionDataRuntype = r.Record({
    original_vote: r.String,
    extraction_version: r.Number,
    origin: r.Record({
        type: OTSPartOriginEnumRuntype,
    }),
});

const PartOriginRuntype = r.Record({
    type: OTSPartOriginEnumRuntype,
    data: r.Optional(
        r.Union(
            OctopartOriginDataRuntype,
            SiliconExpertIdRuntype,
            IHSOriginDataRuntype,
            DescriptionExtractionDataRuntype,
            r.Null,
        ),
    ),
});

export enum DielectricTypes {
    Ceramic = 'Ceramic',
}

export enum EccnGovernance {
    EAR = 'EAR',
    ITAR = 'ITAR',
}

export enum ConflictMineralStatus {
    Unknown = 'Unknown',
    Compliant = 'Compliant',
}

export interface Dielectric extends r.Static<typeof DielectricRuntype> {}
export const DielectricRuntype = r.Record({
    type: runtypeFromEnum(DielectricTypes).nullable(),
    dielectric: r.String.nullable(),
});

export interface TechnicalProperties extends r.Static<typeof TechnicalPropertiesRuntype> {}
const TechnicalPropertiesRuntype = r.Record({
    capacitance: r.String.nullable(), // Strings because these values are decimal
    resistance: r.String.nullable(),
    power_rating: r.String.nullable(),
    tolerance: r.String.nullable(),
    temperature_coefficient: r.String.nullable(),
    voltage_rating: r.String.nullable(),
    dielectric: DielectricRuntype.nullable(),
});

const UsageDataRuntype = r.Record({
    tenant: r.String,
    part_is_used: r.Boolean,
    ipns: r.Array(r.String),
    inventory_stock: r.Number,
    has_ambiguous_stock: r.Boolean.nullable(),
    used_assemblies: r.Array(r.String),
    used_assembly_count: r.Number,
    last_modified: r.String,
});

export type PartEmissionData = r.Static<typeof PartEmissionDataRuntype>;
// If the user doesn't have the part emissions enabled for a part
// (because they never clicked on a button in the UI) then the status for the part will
// be `NotEnabled`. You can think of this status as a gate / screen that hides the
// "actual" part emission data for this part (because emission data on a part level is
// generally public in the DB, but we don't want to leak the actual status to tenants
// who haven't paid for it).
//
// Then the "actual" part emission status can be:
// * `Available` (then we have the CO2e data obviously)
// * `NotFetched` no tenant ever asked for the CO2e data for this part,
//      so we haven't fetched this info from the SluiceBox API yet
// * `NotAvailable` some tenant has requested the CO2e data for this part in the past,
//      but for whatever reason the SluiceBox API says that it cannot give us data for
//      this part
// * `Requested` tenant has asked for the CO2e data for this part, but it hasn't arrived
//      from SluiceBox API yet
const PartEmissionDataRuntype = r.Union(
    r.Record({
        type: r.Literal('Available'),
        data: r.Record({
            product_phase_gwp_in_kg_co2e: r.String,
        }),
    }),
    r.Record({
        type: r.Literal('NotAvailable'),
    }),
    r.Record({
        type: r.Literal('NotEnabled'),
    }),
    r.Record({
        type: r.Literal('NotFetched'),
    }),
    r.Record({
        type: r.Literal('Requested'),
        data: r.Record({
            requested_at: r.String,
        }),
    }),
);

export interface OffTheShelfPartVote extends r.Static<typeof OffTheShelfPartVoteRuntype> {}
export const OffTheShelfPartVoteRuntype = r.Record({
    origin: PartOriginRuntype,
    reach_compliant: ComplianceStatusRuntype.nullable(),
    rohs_compliant: ComplianceStatusRuntype.nullable(),
    aecq_compliant: ComplianceStatusRuntype.nullable(),
    lifecycle: runtypeFromEnum(LifecycleEnum).nullable(),
    lifecycle_yteol: r.Number.nullable(),
});

export enum PreferenceStatusEnum {
    Preferred = 'Preferred',
    Blocked = 'Blocked',
}

export const PreferenceStatusEnumRuntype = runtypeFromEnum(PreferenceStatusEnum);

export interface OtsFullPart extends r.Static<typeof OtsFullPartRuntype> {}
export const OtsFullPartRuntype = r.Record({
    id: r.String,
    manufacturer: ManufacturerDTORuntype,
    mpn: r.String,
    mpn_aliases: r.Array(r.String),

    ipns: r.Array(r.String),

    origin: PartOriginRuntype,

    part_category: r
        .Record({
            part_category: r.Record({
                id: r.Number,
                name: r.String,
            }),
            path: r.Array(r.Number),
        })
        .optional()
        .nullable(),
    type: r.String.nullable().optional(),
    description: r.String.nullable(),

    reach_compliant: ComplianceStatusRuntype,
    reach_candidate_list_date: r.String.nullable(),
    rohs_compliant: ComplianceStatusRuntype,
    rohs_version: RohsVersionRuntype.nullable(),
    aecq_compliant: ComplianceStatusRuntype,
    china_rohs_compliant: ComplianceStatusRuntype,
    prop65_compliant: ComplianceStatusRuntype,
    hts_code: r.String.nullable(),
    taric: r.String.nullable(),
    qualifications: r.Array(QualificationRuntype),
    svhc_cas_numbers: r.Array(r.String),
    country_of_origin: r.Array(RegionsEnumRuntype),
    eccn_numbers: r.Array(r.String),
    eccn_governance: runtypeFromEnum(EccnGovernance).nullable(),
    conflict_mineral_status: runtypeFromEnum(ConflictMineralStatus),

    emissions_data: PartEmissionDataRuntype,

    lifecycle_status: runtypeFromEnum(LifecycleEnum),
    lifecycle_yteol: r.Number.nullable(),
    lifecycle_yteol_range: r.String.nullable(),
    last_buy_date: r.String.nullable(),
    last_delivery_date: r.String.nullable(),

    technical_properties: TechnicalPropertiesRuntype.nullable(),
    package: PackageDTORuntype.Or(r.Null),
    spns: r.Array(r.String),

    manufacturer_product_url: r.String.nullable(),
    datasheet_url: r.String.nullable(),
    image_url: r.String.nullable(),
    preference_status: PreferenceStatusEnumRuntype.nullable(),
    usage_data: UsageDataRuntype.nullable(),
});

const GenericResistorWithFullPackageRuntype = r.Record({
    resistance: r.String,
    power_rating: r.String.nullable(),
    tolerance: r.String.nullable(),
    temperature_coefficient: r.String.nullable(),
    voltage_rating: r.String.nullable(),
    package: PackageUserInputRuntype.nullable(),
});

const GenericCapacitorWithFullPackageRuntype = r.Record({
    capacitance: r.String,
    dielectric: DielectricRuntype.nullable(),
    tolerance: r.String.nullable(),
    voltage_rating: r.String.nullable(),
    package: PackageUserInputRuntype.nullable(),
});

const GenericPartWithFullPackageRuntype = r.Record({
    Resistor: GenericResistorWithFullPackageRuntype.optional(),
    Capacitor: GenericCapacitorWithFullPackageRuntype.optional(),
});

export const GenericPartWithIdAndFullPackageRuntype = r.Record({
    id: r.String,
    content: GenericPartWithFullPackageRuntype,
});

export const OffTheShelfPartWithFullPackageRuntype = r.Record({
    id: r.String,
    manufacturer: r.String,
    mpn: r.String,
    mpn_aliases: r.Array(r.String),

    part_type: r.String.nullable(),
    part_category: r.Array(r.Number),
    description: r.String.nullable(),

    raw_part_category: r.Array(r.String).nullable(),

    additional_text_for_technical_parameter_extraction: r.String.nullable().optional(),
    origin_id: r.String.nullable().optional(),

    reach_compliant: ComplianceStatusRuntype,
    rohs_compliant: ComplianceStatusRuntype,

    reach_candidate_list_date: r.String.nullable(),
    rohs_version: RohsVersionRuntype.nullable(),
    china_rohs_compliant: ComplianceStatusRuntype,
    prop65_compliant: ComplianceStatusRuntype,
    hts_code: r.String.nullable(),
    taric: r.String.nullable(),
    qualifications: r.Array(QualificationRuntype),
    svhc_cas_numbers: r.Array(r.String),
    country_of_origin: r.Array(RegionsEnumRuntype),
    eccn_numbers: r.Array(r.String),
    eccn_governance: runtypeFromEnum(EccnGovernance).nullable(),
    conflict_mineral_status: runtypeFromEnum(ConflictMineralStatus),

    emission_data: PartEmissionDataRuntype,

    lifecycle_yteol: r.Number.nullable(),
    lifecycle_yteol_range: r.String.nullable(),
    last_buy_date: r.String.nullable(),
    last_delivery_date: r.String.nullable(),

    technical_properties: TechnicalPropertiesRuntype.nullable(),
    package: PackageUserInputRuntype.nullable(),

    manufacturer_product_url: r.String.nullable(),
    datasheet_url: r.String.nullable(),
    image_url: r.String.nullable(),
});

export enum PartAlternativeSimilarityEnum {
    FormFitFunction = 'FormFitFunction',
    Recommended = 'Recommended',
    Functional = 'Functional',
}

export const PartAlternativeSimilarityEnumRuntype = runtypeFromEnum(PartAlternativeSimilarityEnum);

export interface PartAlternative extends r.Static<typeof PartAlternativeRuntype> {}
export const PartAlternativeRuntype = r.Record({
    off_the_shelf_part: OtsFullPartRuntype,
    similarity: PartAlternativeSimilarityEnumRuntype,
});

export interface OtsFormPostValues extends r.Static<typeof OtsFormPostValuesRuntype> {}
export const OtsFormPostValuesRuntype = r.Record({
    mpn: r.String,
    part_category: r.Number.Or(r.Undefined),
    manufacturer: r.String,
    reach_compliant: ComplianceStatusRuntype,
    rohs_compliant: ComplianceStatusRuntype,
    aecq_compliant: ComplianceStatusRuntype,
    last_buy_date: r.String.Or(r.Undefined),
    lifecycle_status: runtypeFromEnum(LifecycleEnum),
    description: r.String.Or(r.Undefined),
    datasheet_url: r.String.Or(r.Undefined),
    image_url: r.String.Or(r.Undefined),
    manufacturer_product_url: r.String.Or(r.Undefined),
    mpn_aliases: r.Array(r.String),
    package: PackageUserInputRuntype,
    qualifications: r.Array(QualificationRuntype),
});

export interface OtsFormPatchValues extends r.Static<typeof OtsFormPatchValuesRuntype> {}
export const OtsFormPatchValuesRuntype = r.Record({
    part_category: r.Number.optional(),
    reach_compliant: ComplianceStatusRuntype.optional(),
    rohs_compliant: ComplianceStatusRuntype.optional(),
    aecq_compliant: ComplianceStatusRuntype.optional(),
    last_buy_date: r.Union(r.String, r.Null, r.Undefined).optional(),
    lifecycle_status: runtypeFromEnum(LifecycleEnum).optional(),
    description: r.Union(r.String, r.Null, r.Undefined).optional(),
    datasheet_url: r.Union(r.String, r.Null, r.Undefined).optional(),
    image_url: r.Union(r.String, r.Null, r.Undefined).optional(),
    manufacturer_product_url: r.Union(r.String, r.Null, r.Undefined).optional(),
    package: PackageUserInputRuntype.optional(),
    qualifications: r.Array(QualificationRuntype).optional(),
});

export interface PartTypesDTO {
    items: string[];
}

export enum DielectricCeramicEnum {
    X8R = 'X8R',
    C0G = 'C0G / NP0',
    X7R = 'X7R',
    X5R = 'X5R',
    X8L = 'X8L',
    Y5V = 'Y5V',
    X6S = 'X6S',
    Z5U = 'Z5U',
    X7S = 'X7S',
    //NP0 = 'NP0', // Search Octopart and DB by C0G instead, as they are synonyms but C0G is a lot more common
    Y5U = 'Y5U',
    C0H = 'C0H',
    Y5R = 'Y5R',
    Y5P = 'Y5P',
    Y5T = 'Y5T',
    U2J = 'U2J',
    Z5V = 'Z5V',
    Z5P = 'Z5P',
    K4000 = 'K4000',
    X6T = 'X6T',
    X7T = 'X7T',
    Z5F = 'Z5F',
}

export enum GenericPartTypes {
    Resistor = 'Resistor',
    Capacitor = 'Capacitor',
}

export type GenericCapacitor = r.Static<typeof GenericCapacitorRuntype>;
// eslint-disable-next-line import/no-unused-modules
export const GenericCapacitorRuntype = r.Record({
    type: r.Literal(GenericPartTypes.Capacitor),
    technical_parameters: r.Record({
        capacitance: r.String.nullable(), // Strings because these values are decimal
        package_id: r.String.nullable(),
        dielectric: DielectricRuntype.nullable(),
        tolerance: r.String.nullable(),
        voltage_rating: r.String.nullable(),
    }),
});

export type GenericResistor = r.Static<typeof GenericResistorRuntype>;
// eslint-disable-next-line import/no-unused-modules
export const GenericResistorRuntype = r.Record({
    type: r.Literal(GenericPartTypes.Resistor),
    technical_parameters: r.Record({
        resistance: r.String.nullable(), // Strings because these values are decimal
        package_id: r.String.nullable(),
        power_rating: r.String.nullable(),
        tolerance: r.String.nullable(),
        temperature_coefficient: r.String.nullable(),
        voltage_rating: r.String.nullable(),
    }),
});
export type GenericPart = r.Static<typeof TechnicalParametersRuntype>;
export const TechnicalParametersRuntype = r.Union(GenericCapacitorRuntype, GenericResistorRuntype);
export interface GenericFullPart extends r.Static<typeof GenericFullPartRuntype> {}

export const GenericFullPartRuntype = r.Record({
    id: r.String,
    content: TechnicalParametersRuntype,
    matches: r.Array(OtsFullPartRuntype),
    reach_compliant: runtypeFromEnum(ComplianceStatus),
    rohs_compliant: runtypeFromEnum(ComplianceStatus),
    aecq_compliant: runtypeFromEnum(ComplianceStatus),
    lifecycle_status: runtypeFromEnum(LifecycleEnum),
    lifecycle_yteol: r.Number.nullable(),
    is_manufacturer_preferred: r.Boolean,
    emission_data: EmissionDataRuntype.nullable(),
});

/**
 * An IncompleteGenericFullPart is essentially a GenericPart with values missing. Once the missing values
 * are added the user can convert it into a GenericPart
 *
 * Use `isIncompleteGenericFullPart` to switch on IncompleteGenericFullPart
 */
export type IncompleteGenericFullPart = r.Static<typeof IncompleteGenericFullPartRuntype>;

export const IncompleteGenericFullPartRuntype = r.Union(GenericCapacitorRuntype, GenericResistorRuntype);

export type ConflictingTechnicalParameters = r.Static<typeof ConflictingTechnicalParametersRuntype>;
export const ConflictingTechnicalParametersRuntype = r.Record({
    resistances: r.Array(r.String),
    tolerances: r.Array(r.String),
    power_ratings: r.Array(r.String),
    voltage_ratings: r.Array(r.String),
    temperature_coefficients: r.Array(r.String),
    packages: r.Array(r.String),
    capacitances: r.Array(r.String),
    dielectrics: r.Array(r.String),
});

export function isConflictingTechnicalParameters(
    v?: ConflictingTechnicalParameters | string | null | undefined | string[],
): v is ConflictingTechnicalParameters {
    return (
        !!v &&
        typeof v !== 'string' &&
        'resistances' in v &&
        'tolerances' in v &&
        'power_ratings' in v &&
        'capacitances' in v &&
        'packages' in v
    );
}

export enum PartOptionExtractionReasonWithoutContextEnum {
    MatchingMpn = 'MatchingMpn',
    NoManufacturerGiven = 'NoManufacturerGiven',
    MatchingIpn = 'MatchingIpn',
    MatchingCpn = 'MatchingCpn',
    MatchingSpn = 'MatchingSpn',
    // The MPN matches and but is already linked to an IPN part option
    MatchingMpnLinkedToIpn = 'MatchingMpnLinkedToIpn',
    // The matched parts were replaced by IPNs with the same linked parts
    MatchedPartsReplacedByIpn = 'MatchedPartsReplacedByIpn',
    // There is more than one IPN result for a candidate IPN
    ManyMatchingIpn = 'ManyMatchingIpn',
    // There is more than one IPN result for a candidate SPN
    ManyMatchingSpn = 'ManyMatchingSpn',
    // The IPN has status removed
    IpnRemoved = 'IpnRemoved',
    // There are multiple CPNs with different revisions matching but no revision is specified in the BOM
    MatchingCpnButRevisionUnclear = 'MatchingCpnButRevisionUnclear',
    // The IPN matches but there is a CPN in the BOM that points to a different IPN
    MatchingIpnButDifferentCpn = 'MatchingIpnButDifferentCpn',
    // The CPN matches but there is an IPN in the BOM that is different to the one of the CPN
    MatchingCpnButDifferentIpn = 'MatchingCpnButDifferentIpn',
    // The SPN matches but there is a CPN in the BOM that points to a different IPN
    MatchingSpnButDifferentCpn = 'MatchingSpnButDifferentCpn',
    // The SPN matches but there is another IPN in the BOM that is different to the one of the SPN
    MatchingSpnButDifferentIpn = 'MatchingSpnButDifferentIpn',
    // There is an explicit manufacturer-free indication
    ExplicitlyManufacturerFree = 'ExplicitlyManufacturerFree',
    MatchingTechSpecs = 'MatchingTechSpecs',
    ApprovalStatusChangedInPreviousImport = 'ApprovalStatusChangedInPreviousImport',
    AssemblyImport = 'AssemblyImport',
}

export enum PartOptionExtractionReasonEnum {
    // There is a matching manufacturer found via an alternative name
    MatchingManufacturerAlternativeName = 'MatchingManufacturerAlternativeName',
    // There is a matching manufacturer
    MatchingManufacturer = 'MatchingManufacturer',
    // The extracted generic part matches the technical parameters but some are conflicting
    MatchingTechSpecsWithConflicts = 'MatchingTechSpecsWithConflicts',
    // The part option was added with the quote import
    QuoteImport = 'QuoteImport',
}

export type PartOptionExtractionReason = r.Static<typeof PartOptionExtractionReasonRuntype>;
export const PartOptionExtractionReasonRuntype = r.Union(
    r.Record({
        name: runtypeFromEnum(PartOptionExtractionReasonWithoutContextEnum),
    }),
    r.Record({
        name: r.Literal(PartOptionExtractionReasonEnum.MatchingTechSpecsWithConflicts),
        context: ConflictingTechnicalParametersRuntype,
    }),
    r.Record({
        name: r.Literal(PartOptionExtractionReasonEnum.MatchingManufacturer),
        context: r.String,
    }),
    r.Record({
        name: r.Literal(PartOptionExtractionReasonEnum.MatchingManufacturerAlternativeName),
        context: r.String,
    }),
    r.Record({
        name: r.Literal(PartOptionExtractionReasonEnum.QuoteImport),
        context: r.String.optional().nullable(),
    }),
);

export enum OriginSetMethod {
    Automatic = 'Automatic',
    Manual = 'Manual',
    Previous = 'Previous',
}

export enum ColumnName {
    MPN = 'MPN',
    SupplierPartNumber = 'SupplierPartNumber',
    IPN = 'IPN',
    CPN = 'CPN',
    TechSpecs = 'TechSpecs',
    Previous = 'Previous',
}

export function formatColumnName(column: ColumnName): string {
    switch (column) {
        case ColumnName.CPN:
            return 'CPN';
        case ColumnName.IPN:
            return 'IPN';
        case ColumnName.MPN:
            return 'MPN';
        case ColumnName.SupplierPartNumber:
            return 'SPN';
        case ColumnName.TechSpecs:
            return 'Specs';
        case ColumnName.Previous:
            return 'Prev';
    }
}

export interface PartOptionOrigin extends r.Static<typeof PartOptionOriginRuntype> {}
export const PartOptionOriginRuntype = r.Record({
    set_method: runtypeFromEnum(OriginSetMethod).nullable(),
    column: runtypeFromEnum(ColumnName).nullable().optional(),
    candidate: r.Union(r.String, CandidateCpnRuntype).nullable().optional(),
    reasons: r.Array(PartOptionExtractionReasonRuntype),
});

export enum PartSuggestionReasonEnum {
    PartialMpn = 'PartialMpn',
    ManufacturerMismatch = 'ManufacturerMismatch',
    PartOnlySuggestedBecauseManufacturerIsBlocked = 'PartOnlySuggestedBecauseManufacturerIsBlocked',
    CandidateMpnMatchedButNoManufacturerGiven = 'CandidateMpnMatchedButNoManufacturerGiven',
    GenericPartOnlySuggestedBecauseNonGenericPartOptionExists = 'GenericPartOnlySuggestedBecauseNonGenericPartOptionExists',
    GenericPartOnlySuggestedBecauseManufacturerGiven = 'GenericPartOnlySuggestedBecauseManufacturerGiven',
    GenericPartOnlySuggestedBecauseIpnGiven = 'GenericPartOnlySuggestedBecauseIpnGiven',
    GenericPartOnlySuggestedBecauseCpnGiven = 'GenericPartOnlySuggestedBecauseCpnGiven',
    GenericPartOnlySuggestedBecauseMpnGiven = 'GenericPartOnlySuggestedBecauseMpnGiven',
    GenericPartMissingRequiredParameter = 'GenericPartMissingRequiredParameter',
    GenericPartOnlySuggestedBecauseExplicitlyNotManufacturerFree = 'GenericPartOnlySuggestedBecauseExplicitlyNotManufacturerFree',
    IpnSuggestedBasedOnMpnCandidate = 'IpnSuggestedBasedOnMpnCandidate',
    IpnSuggestedBasedOnLinkedOrSuggestedParts = 'IpnSuggestedBasedOnLinkedOrSuggestedParts',
    GenericPartTechnicalParametersMatchThisIpn = 'GenericPartTechnicalParametersMatchThisIpn',
    IpnSuggestedBecauseOfBetterTechnicalParameters = 'IpnSuggestedBecauseOfBetterTechnicalParameters',
    // Part options were moved to suggestions because the previous import with the same data was changed to a custom part specification
    PreviousImportCustomPartSpecification = 'PreviousImportCustomPartSpecification',
    PreviousImportRemovedPartOption = 'PreviousImportRemovedPartOption',
    PreviousImportManuallyAddedPartOption = 'PreviousImportManuallyAddedPartOption',
    PreviousImportAutomaticallyAddedPartOption = 'PreviousImportAutomaticallyAddedPartOption',
    PreviousImportPreviouslyAddedPartOption = 'PreviousImportPreviouslyAddedPartOption',
    // This reason is now deprecated and no longer comes from the BomImporter.
    // In the bom importer we now approve both Mpn and generic part. as seen in this ticket: https://www.notion.so/luminovo/Building-Backlog-BOM-f9b31a8848bc4a05904107919350323f?p=c3dee61dd98842e0a20f150f337a2c17&pm=s
    // We are only keeping this for backward compatibility with boms imported prior to this change.
    Deprecated_PartOnlySuggestedBecauseExplicitlyManufacturerFreeAndGenericPart = 'PartOnlySuggestedBecauseExplicitlyManufacturerFreeAndGenericPart',
    ConflictingPartSpecificationTypes = 'ConflictingPartSpecificationTypes',
}

export type PartSuggestionReason = r.Static<typeof PartSuggestionReasonRuntype>;
export const PartSuggestionReasonRuntype = r.Union(
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.PartialMpn),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseNonGenericPartOptionExists),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.ManufacturerMismatch),
        context: r.String.optional().nullable(),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.PartOnlySuggestedBecauseManufacturerIsBlocked),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseManufacturerGiven),
        context: r.String.optional().nullable(),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseIpnGiven),
        context: r.String.optional().nullable(),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseCpnGiven),
        context: r.String.optional().nullable(),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseMpnGiven),
        context: r.String.optional().nullable(),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.GenericPartMissingRequiredParameter),
        context: r.String,
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.CandidateMpnMatchedButNoManufacturerGiven),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseExplicitlyNotManufacturerFree),
    }),
    r.Record({
        name: r.Literal(
            PartSuggestionReasonEnum.Deprecated_PartOnlySuggestedBecauseExplicitlyManufacturerFreeAndGenericPart,
        ),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.IpnSuggestedBasedOnMpnCandidate),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.IpnSuggestedBasedOnLinkedOrSuggestedParts),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.GenericPartTechnicalParametersMatchThisIpn),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.IpnSuggestedBecauseOfBetterTechnicalParameters),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.PreviousImportCustomPartSpecification),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.PreviousImportRemovedPartOption),
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.PreviousImportManuallyAddedPartOption),
        context: ApprovalStatusRuntype,
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.PreviousImportAutomaticallyAddedPartOption),
        context: ApprovalStatusRuntype,
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.PreviousImportPreviouslyAddedPartOption),
        context: ApprovalStatusRuntype,
    }),
    r.Record({
        name: r.Literal(PartSuggestionReasonEnum.ConflictingPartSpecificationTypes),
        context: r.Array(r.String),
    }),
);

export type PartSuggestionOrigin = r.Static<typeof PartSuggestionOriginRuntype>;
export const PartSuggestionOriginRuntype = r.Union(
    r.Record({
        column: r.Literal(ColumnName.MPN),
        candidate: r.String,
        reasons: r.Array(PartSuggestionReasonRuntype),
    }),
    r.Record({
        column: r.Literal(ColumnName.SupplierPartNumber),
        candidate: r.String,
        reasons: r.Array(PartSuggestionReasonRuntype),
    }),
    r.Record({
        column: r.Literal(ColumnName.IPN),
        candidate: r.String,
        reasons: r.Array(PartSuggestionReasonRuntype),
    }),
    r.Record({
        column: r.Literal(ColumnName.CPN),
        candidate: CandidateCpnRuntype,
        reasons: r.Array(PartSuggestionReasonRuntype),
    }),
    r.Record({
        column: r.Literal(ColumnName.TechSpecs),
        reasons: r.Array(PartSuggestionReasonRuntype),
    }),
    r.Record({
        column: r.Literal(ColumnName.Previous),
        candidate: r.Union(r.String, CandidateCpnRuntype).nullable(),
        reasons: r.Array(PartSuggestionReasonRuntype),
    }),
);

export type PartOptionDTO = r.Static<typeof PartOptionDTORuntype>;
export const PartOptionDTORuntype = r.Record({
    part: StandardPartDTORuntype,
    approval_status: ApprovalStatusRuntype,
    origin: PartOptionOriginRuntype.nullable(),
});

export enum CustomOptionTypes {
    CustomPart = 'CustomPart',
    CustomComponent = 'CustomComponent',
}
export const CustomOptionTypesRuntype = runtypeFromEnum(CustomOptionTypes);

export type CustomOptionVariantDTO = r.Static<typeof CustomOptionVariantDTORuntype>;
export const CustomOptionVariantDTORuntype = r.Record({
    type: runtypeFromEnum(CustomOptionTypes),
    data: r.String, // custom part id or custom component id
});

export type CustomOptionDTO = r.Static<typeof CustomOptionDTORuntype>;
export const CustomOptionDTORuntype = r.Record({
    part: CustomOptionVariantDTORuntype,
    approval_status: ApprovalStatusRuntype,
    origin: PartOptionOriginRuntype.nullable(),
});

export interface PartSearchResponseDTO {
    items: OtsFullPart[];
    offset: number;
}

export type SMTMountingTypesDTO = r.Static<typeof SMTMountingTypesDTORuntype>;
const SMTMountingTypesDTORuntype = r.Record({
    imperial: r.Array(r.String),
    metric: r.Array(r.String),
});

export type PartMountingTypesDTO = r.Static<typeof PartMountingTypesDTORuntype>;
export const PartMountingTypesDTORuntype = r.Record({
    smt: SMTMountingTypesDTORuntype,
    tht: r.Array(r.String),
});

const ExternalParamsOctoPartRuntype = r.Record({
    offset: r.Number,
});

export type PartSearchInput = r.Static<typeof PartSearchInputRuntype>;
const PartSearchInputRuntype = r.Record({
    search_candidate: r.String,
    search_request_number: r.Number.nullable(),
    external_params: ExternalParamsOctoPartRuntype.nullable(),
    already_fetched_parts: r.Number.nullable(),
    search_id: r.String.optional(),
    rfq_context: r.Union(r.Literal('WithinRfQ'), r.Literal('OutsideRfQ')),
    rfq_id: r.String.optional(),
});

export type BackendPartOfferSummary = r.Static<typeof BackendPartOfferSummaryDTO>;
export const BackendPartOfferSummaryDTO = r.Record({
    part_id: r.String,
    supplier_stock: QuantityUnitDTORuntype.nullable(),
    best_price_in_stock: MonetaryValueBackendRuntype.nullable(),
    median_price_in_stock: MonetaryValueBackendRuntype.nullable(),
    best_price: MonetaryValueBackendRuntype.nullable(),
    stock_last_update: r.String.nullable(),
});

export const MpnMatchesRuntype = r.Dictionary(r.Array(OtsFullPartRuntype));

export const IpnInventoryRuntype = r.Record({
    ipn: r.String,
    last_updated: r.String,
    unit_of_measurement: QuantityUnitDTORuntype,
    unit_price: MonetaryValueBackendRuntype,
    available_stock: r.Number,
    total_stock: r.Number.nullable(),
    packaging: PackagingRuntype.nullable(),
    standard_lead_time: r.Number.nullable(),
});
export type IpnInventory = r.Static<typeof IpnInventoryRuntype>;

export type OffTheShelfPartSearchFilterRequest = r.Static<typeof OffTheShelfPartSearchFilterRequestRuntype>;
export const OffTheShelfPartSearchFilterRequestRuntype = r.Union(
    r.Record({
        field: r.Literal('capacitance'),
        operator: r.Literal('inNumberRange'),
        parameter: r.Array(r.Union(r.Number, r.Null)),
    }),
    r.Record({
        field: r.Literal('resistance'),
        operator: r.Literal('inNumberRange'),
        parameter: r.Array(r.Union(r.Number, r.Null)),
    }),
    r.Record({
        field: r.Literal('power-rating'),
        operator: r.Literal('inNumberRange'),
        parameter: r.Array(r.Union(r.Number, r.Null)),
    }),
    r.Record({
        field: r.Literal('tolerance'),
        operator: r.Literal('inNumberRange'),
        parameter: r.Array(r.Union(r.Number, r.Null)),
    }),
    r.Record({
        field: r.Literal('temperature-coefficient'),
        operator: r.Literal('inNumberRange'),
        parameter: r.Array(r.Union(r.Number, r.Null)),
    }),
    r.Record({
        field: r.Literal('voltage-rating'),
        operator: r.Literal('inNumberRange'),
        parameter: r.Array(r.Union(r.Number, r.Null)),
    }),
    r.Record({
        field: r.Literal('dielectric-dielectric'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(runtypeFromEnum(DielectricCeramicEnum)),
    }),
    r.Record({
        field: r.Literal('manufacturer'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(r.String),
    }),
    r.Record({
        field: r.Literal('package'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(r.String),
    }),
    r.Record({
        field: r.Literal('country-of-origin'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(RegionsEnumRuntype),
    }),
    r.Record({
        field: r.Literal('rohs-compliant'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(ComplianceStatusRuntype),
    }),
    r.Record({
        field: r.Literal('reach-compliant'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(ComplianceStatusRuntype),
    }),
    r.Record({
        field: r.Literal('lifecycle'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(runtypeFromEnum(LifecycleEnum)),
    }),
    r.Record({
        field: r.Literal('last-buy-date'),
        operator: r.Literal('inNumberRange'),
        parameter: r.Array(r.Union(r.Number, r.Null)), // TODO - should be date
    }),
    r.Record({
        field: r.Literal('mpn'),
        operator: r.Literal('includesString'),
        parameter: r.Array(r.String),
    }),
    r.Record({
        field: r.Literal('ipn'),
        operator: r.Literal('includesString'),
        parameter: r.Array(r.String),
    }),
    r.Record({
        field: r.Literal('spn'),
        operator: r.Literal('includesString'),
        parameter: r.Array(r.String),
    }),
    r.Record({
        field: r.Literal('inventory-stock'),
        operator: r.Literal('inNumberRange'),
        parameter: r.Array(r.Union(r.Number, r.Null)),
    }),
    r.Record({
        field: r.Literal('ambiguous-stock'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(r.Boolean),
    }),
    r.Record({
        field: r.Literal('used-assemblies'),
        operator: r.Literal('arrIncludesSome'),
        parameter: r.Array(r.String),
    }),
    r.Record({
        field: r.Literal('part-is-used'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(r.Boolean),
    }),
    r.Record({
        field: r.Literal('description'),
        operator: r.Literal('includesString'),
        parameter: r.Array(r.String),
    }),
    r.Record({
        field: r.Literal('part-category'),
        operator: r.Literal('equalsAny'),
        parameter: r.Array(r.Number),
    }),
);

export type OffTheShelfPartSearchSortRequest = r.Static<typeof OffTheShelfPartSearchSortRequestRuntype>;
export const OffTheShelfPartSearchSortRequestRuntype = r.Union(
    r.Record({
        field: r.Literal('inventory-stock'),
        direction: r.Union(r.Literal('asc'), r.Literal('desc')),
    }),
    r.Record({
        field: r.Literal('number-of-used-assemblies'),
        direction: r.Union(r.Literal('asc'), r.Literal('desc')),
    }),
    r.Record({
        field: r.Literal('lifecycle'),
        direction: r.Union(r.Literal('asc'), r.Literal('desc')),
    }),
    r.Record({
        field: r.Literal('rohs-compliance'),
        direction: r.Union(r.Literal('asc'), r.Literal('desc')),
    }),
    r.Record({
        field: r.Literal('reach-compliance'),
        direction: r.Union(r.Literal('asc'), r.Literal('desc')),
    }),
);

export type OffTheShelfPartSearchAggregationDTO = r.Static<typeof OffTheShelfPartSearchAggregationDTORuntype>;
export const OffTheShelfPartSearchAggregationDTORuntype = r.Union(
    r.Record({
        field: r.Literal('manufacturers'),
        options: r.Array(
            r.Record({
                value: r.String,
                count: r.Number,
            }),
        ),
    }),
    r.Record({
        field: r.Literal('part_category'),
        options: r.Array(
            r.Record({
                value: r.String,
                count: r.Number,
            }),
        ),
    }),
    r.Record({
        field: r.Literal('reach_compliant'),
        options: r.Array(
            r.Record({
                value: r.String,
                count: r.Number,
            }),
        ),
    }),
    r.Record({
        field: r.Literal('rohs_compliant'),
        options: r.Array(
            r.Record({
                value: r.String,
                count: r.Number,
            }),
        ),
    }),
    r.Record({
        field: r.Literal('packages'),
        options: r.Array(
            r.Record({
                value: r.String,
                count: r.Number,
            }),
        ),
    }),
    r.Record({
        field: r.Literal('lifecycle'),
        options: r.Array(
            r.Record({
                value: r.String,
                count: r.Number,
            }),
        ),
    }),
);

export interface OffTheShelfPartSearchResponse extends r.Static<typeof OffTheShelfPartSearchResponseRuntype> {}
export const OffTheShelfPartSearchResponseRuntype = r.Record({
    search_id: r.String,
    total_count: r.Number,
    page_params: r.Unknown.Or(r.Undefined),
    aggregations: r.Array(OffTheShelfPartSearchAggregationDTORuntype),
    page: r.Array(OtsFullPartRuntype),
    highlights: r.Dictionary(r.Array(r.String), r.String).optional(),
});

export interface StockInformationDTO extends r.Static<typeof StockInformationDTORuntype> {}
const StockInformationDTORuntype = r.Record({
    inventory: r.Record({
        quantity: QuantityUnitDTORuntype.nullable(),
        last_updated: r.String.nullable(),
    }),
    external: r.Record({
        quantity: QuantityUnitDTORuntype.nullable(),
        last_updated: r.String.nullable(),
    }),
});

export interface EmissionDataRequestDTO extends r.Static<typeof EmissionDataRequestDTORuntype> {}

export const EmissionDataRequestDTORuntype = r.Record({
    ots_part_ids: r.Array(r.String),
    generic_part_ids: r.Array(r.String),
});

export interface PartAvailability extends r.Static<typeof PartAvailabilityRuntype> {}
export const PartAvailabilityRuntype = r.Record({
    part: StandardPartDTORuntype,
    stock: StockInformationDTORuntype.nullable(),
});

export const RFQContextualizedBulkIdsRuntype = r.Union(
    r.Record({
        ids: r.Array(r.String),
        rfq_context: r.Literal('WithinRfQ'),
        rfq_id: r.String,
    }),
    r.Record({
        ids: r.Array(r.String),
        rfq_context: r.Literal('OutsideRfQ'),
    }),
);

export type LoadingOffersProgressDTO = r.Static<typeof LoadingOffersProgressDTORuntype>;
export const LoadingOffersProgressDTORuntype = r.Record({
    total: r.Number,
    parts: r.Array(
        r.Record({
            id: r.String,
            count: r.Number,
        }),
    ),
    custom_parts: r.Dictionary(r.Record({ count: r.Number, id: r.String }), r.String),
    apis: r.Array(
        r.Record({
            name: r.String,
            count: r.Number,
        }),
    ),
});

export interface SupportedPartsDTO extends r.Static<typeof SupportedPartsDTORuntype> {}
export const SupportedPartsDTORuntype = r.Record({
    supplier: r.String,
    part_ids: r.Array(PartDTORuntype),
});

export enum PartLiteTypes {
    OffTheShelf = 'OffTheShelf',
    Generic = 'Generic',
    Ipn = 'Ipn',
    RawSpecification = 'RawSpecification',
    Custom = 'Custom',
    CustomComponent = 'CustomComponent',
    Unknown = 'Unknown',
}

export const OtsPartLiteRuntype = r.Record({
    kind: r.Literal(PartLiteTypes.OffTheShelf),
    id: r.String,
    manufacturer: r.Record({
        id: r.String,
        name: r.String,
    }),
    mpn: r.String,
});

export const GenericPartLiteRuntype = r.Record({
    kind: r.Literal(PartLiteTypes.Generic),
    id: r.String,
    content: r.Record({
        type: runtypeFromEnum(GenericPartTypes),
    }),
    matches: r.Array(r.Union(OtsPartLiteRuntype)),
});

export const RawSpecificationLiteRuntype = r.Record({
    kind: r.Literal(PartLiteTypes.RawSpecification),
    id: r.String,
    manufacturer: r.Record({
        id: r.String.nullable(),
        name: r.String.nullable(),
    }),
    mpn: r.String.nullable(),
    description: r.String.nullable(),
});

export const OtsComponentLiteRuntype = r.Record({
    kind: r.Literal(PartLiteTypes.Ipn),
    id: r.String,
    matches: r.Array(r.Union(OtsPartLiteRuntype, GenericPartLiteRuntype, RawSpecificationLiteRuntype)),
});

export const CustomPartLiteRuntype = r.Record({
    kind: r.Literal(PartLiteTypes.Custom),
    id: r.String,
    description: r.Null.Or(r.String),
    type: CustomPartTypeRuntype,
});

export const CustomComponentLiteRuntype = r.Record({
    kind: r.Literal(PartLiteTypes.CustomComponent),
    id: r.String,
    matches: r.Array(CustomPartLiteRuntype),
});

/**
 * This part currently only exists in the frontend, it's used to represent an unmatched part -
 * a part that is not known in the catalog. The ID is just a placeholder, to make sure all parts
 * have an ID.
 */
export const UnknownPartLiteRuntype = r.Record({
    kind: r.Literal(PartLiteTypes.Unknown),
    id: r.String,
    mpn: r.String,
    manufacturer: r.Record({
        name: r.String,
    }),
});

export type PartLite = r.Static<typeof PartLiteRuntype>;
export const PartLiteRuntype = r.Union(
    OtsPartLiteRuntype,
    GenericPartLiteRuntype,
    OtsComponentLiteRuntype,
    RawSpecificationLiteRuntype,
    CustomPartLiteRuntype,
    CustomComponentLiteRuntype,
    UnknownPartLiteRuntype,
);
