import { t } from '@lingui/macro';
import { Currency, MonetaryValue } from '@luminovo/commons';
import { Check, Edit } from '@mui/icons-material';
import { Box, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { colorSystem } from '../../theme';
import { SecondaryButton, SecondaryIconButton } from '../buttons';
import { Tooltip } from '../Tooltip';
import { BaseField, BaseFieldProps, FieldConverter } from './BaseField';
import { FieldSelect } from './FieldSelect';

export interface FieldMonetaryValueProps extends Omit<BaseFieldProps<MonetaryValue | null>, 'converter'> {
    defaultCurrency: Currency;
    enableCurrencyPicker?: boolean;
}

export function FieldMonetaryValue({
    enableCurrencyPicker = false,
    defaultCurrency,
    ...props
}: FieldMonetaryValueProps): JSX.Element {
    const currency = props.value?.currency ?? defaultCurrency;
    const [isCurrencyPickerOpen, setIsCurrencyPickerOpen] = useState(false);

    if (isCurrencyPickerOpen && enableCurrencyPicker) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: colorSystem.blue[2],
                }}
            >
                <FieldSelect
                    fullWidth
                    options={Object.values(Currency)}
                    onChange={(newCurrency) => {
                        props.onChange({
                            amount: props.value?.amount ?? '0',
                            currency: newCurrency ?? defaultCurrency,
                        });
                    }}
                    value={currency}
                />
                <SecondaryIconButton onClick={() => setIsCurrencyPickerOpen(false)}>
                    <Check fontSize="inherit" />
                </SecondaryIconButton>
            </Box>
        );
    }

    return (
        <BaseField
            fullWidth
            slotProps={{
                input: {
                    endAdornment: enableCurrencyPicker ? (
                        <Tooltip title={t`Change currency`}>
                            <SecondaryButton
                                size="small"
                                onClick={() => setIsCurrencyPickerOpen(true)}
                                endIcon={<Edit fontSize="inherit" />}
                                sx={{ marginRight: 1 }}
                            >
                                {currency}
                            </SecondaryButton>
                        </Tooltip>
                    ) : (
                        <InputAdornment position="end">{currency}</InputAdornment>
                    ),
                },
            }}
            converter={fieldConverter(currency)}
            {...props}
        />
    );
}

export const fieldConverter = (currency: Currency): FieldConverter<MonetaryValue | null> => ({
    parseValue: (text: string) => {
        const value = text.trim().replace(',', '.').replace(/[.]*$/, '');
        if (value === '') {
            return { ok: true, value: null };
        }
        const parsed = Number(value);
        if (isNaN(parsed)) {
            return { ok: false, value: null };
        }
        return { ok: true, value: { amount: String(parsed), currency } };
    },
    formatValue: (value) => {
        return value ? String(value.amount) : '';
    },
});
