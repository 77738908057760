import { t } from '@lingui/macro';
import { ParametricSearchInput } from '@luminovo/design-system';
import { ExtractResponseBody, ManufacturerDTO, PackageDTO } from '@luminovo/http-client';
import { getPackageOptions } from '@luminovo/sourcing-core';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import {
    ComponentsSearchAutocompleteState,
    UseComponentsSearchStateResult,
    useCustomersById,
} from './ErpParametricSearchFunctions';

function groupManufacturersById(res: { data: ManufacturerDTO[] }) {
    return res.data.reduce((manufacturersById: Record<string, ManufacturerDTO>, manufacturer) => {
        manufacturersById[manufacturer.id] = manufacturer;
        return manufacturersById;
    }, {});
}

function groupPackagesById(data: { items: PackageDTO[] }) {
    const packages = getPackageOptions(data.items);
    return packages.reduce((packagesById: Record<string, PackageDTO>, pack) => {
        packagesById[pack.id] = pack;
        return packagesById;
    }, {});
}

export const ErpParametricSearchInput = ({
    searchState,
    searchResultsPage,
}: {
    searchState: UseComponentsSearchStateResult;
    searchResultsPage?: ExtractResponseBody<'POST /components/search'>;
}): JSX.Element => {
    const { data: manufacturersById = {} } = useHttpQuery(
        'GET /manufacturers',
        { queryParams: { high_quality: false } },
        {
            select: groupManufacturersById,
            staleTime: Infinity,
        },
    );

    const customersById = useCustomersById(searchState);

    const { data: packagesById = {} } = useHttpQuery(
        'GET /parts/packages',
        { queryParams: {} },
        {
            select: groupPackagesById,
            staleTime: Infinity,
        },
    );

    const autocompleteState: ComponentsSearchAutocompleteState = {
        manufacturersById,
        packagesById,
        customersById,
        aggregations: searchResultsPage?.aggregations,
    };

    return <ParametricSearchInput {...searchState} autocompleteState={autocompleteState} placeholder={t`Search`} />;
};
