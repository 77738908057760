import { assertUnreachable, isPresent } from '@luminovo/commons';
import { DateRange, FilterConfig, FilterValue, NumberRange, SupportedFilterFn } from '../type';

export function createFilterValueUpdater<TValue>(
    newValue: unknown | ((oldValue: FilterValue<TValue> | undefined) => unknown),
    defaultFilterFn: FilterConfig['defaultFilterFn'],
): (oldValue: FilterValue<TValue> | undefined) => FilterValue<unknown> | undefined {
    return (unsafeOldValue: FilterValue<TValue> | undefined) => {
        const oldValue = isFilterValue(unsafeOldValue) ? unsafeOldValue : undefined;
        const resolvedNewValue = typeof newValue === 'function' ? newValue(oldValue) : newValue;

        if (!isPresent(resolvedNewValue)) {
            return undefined;
        }

        if (!isPresent(oldValue)) {
            return {
                filterFn: defaultFilterFn,
                value: getInitialValue(defaultFilterFn, resolvedNewValue),
            };
        }

        const filterFn = oldValue.filterFn;
        switch (filterFn) {
            case SupportedFilterFn.includesString:
                return { filterFn, value: resolvedNewValue as string };
            case SupportedFilterFn.inNumberRange:
                return { filterFn, value: resolvedNewValue as NumberRange };
            case SupportedFilterFn.inMonetaryValueRange:
                return { filterFn, value: resolvedNewValue as NumberRange };
            case SupportedFilterFn.inDateRange:
                return { filterFn, value: resolvedNewValue as DateRange };
            case SupportedFilterFn.equalsAny:
                return { filterFn, value: resolvedNewValue as unknown[] };
            case SupportedFilterFn.equalsNone:
                return { filterFn, value: resolvedNewValue as unknown[] };
            case SupportedFilterFn.arrIncludesSome:
                return { filterFn, value: resolvedNewValue as unknown[] };
            case SupportedFilterFn.arrIncludesAll:
                return { filterFn, value: resolvedNewValue as unknown[] };
            case SupportedFilterFn.arrExcludesSome:
                return { filterFn, value: resolvedNewValue as unknown[] };
            default:
                assertUnreachable(filterFn);
        }
    };
}

function getInitialValue(filterFn: SupportedFilterFn, newValue: unknown): any {
    switch (filterFn) {
        case SupportedFilterFn.includesString:
            return newValue as string;
        case SupportedFilterFn.inNumberRange:
        case SupportedFilterFn.inMonetaryValueRange:
            return newValue as NumberRange;
        case SupportedFilterFn.inDateRange:
            return newValue as DateRange;
        case SupportedFilterFn.equalsAny:
        case SupportedFilterFn.equalsNone:
            return newValue;
        case SupportedFilterFn.arrIncludesSome:
        case SupportedFilterFn.arrIncludesAll:
        case SupportedFilterFn.arrExcludesSome:
            return newValue;
        default:
            assertUnreachable(filterFn);
    }
}

export function isFilterValue<TValue>(value: unknown): value is FilterValue<TValue> {
    if (typeof value !== 'object' || value === null) {
        return false;
    }

    const filterValue = value as Partial<FilterValue<TValue>>;

    if (!('filterFn' in filterValue) || !('value' in filterValue)) {
        return false;
    }

    const filterFn = filterValue.filterFn;
    if (!isPresent(filterFn)) {
        return false;
    }

    switch (filterFn) {
        case SupportedFilterFn.includesString:
            return typeof filterValue.value === 'string';
        case SupportedFilterFn.inNumberRange:
        case SupportedFilterFn.inMonetaryValueRange:
            return (
                Array.isArray(filterValue.value) &&
                filterValue.value.length === 2 &&
                (filterValue.value[0] === null || typeof filterValue.value[0] === 'number') &&
                (filterValue.value[1] === null || typeof filterValue.value[1] === 'number')
            );
        case SupportedFilterFn.inDateRange:
            return (
                Array.isArray(filterValue.value) &&
                filterValue.value.length === 2 &&
                (filterValue.value[0] === null || typeof filterValue.value[0] === 'string') &&
                (filterValue.value[1] === null || typeof filterValue.value[1] === 'string')
            );
        case SupportedFilterFn.equalsAny:
        case SupportedFilterFn.equalsNone:
            return Array.isArray(filterValue.value);
        case SupportedFilterFn.arrIncludesSome:
        case SupportedFilterFn.arrIncludesAll:
        case SupportedFilterFn.arrExcludesSome:
            return Array.isArray(filterValue.value);
        default:
            assertUnreachable(filterFn);
    }
}
