import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { ExcelLinesRuntype, QuantityUnitRuntype, RowRuntype } from '../backendTypes';
import { ErrorCodeRuntype } from '../errorCodes';
import { PartSuggestionOriginRuntype, TechnicalParametersRuntype } from '../part';
import { PartSpecificationRuntype } from '../part/fullPartBackendTypes';
import { BomImporterIssueRuntype } from './bomImporterIssuesEnum';

/* eslint-disable camelcase */

export type HeaderMetadata = r.Static<typeof HeaderMetadataRuntype>;
const HeaderMetadataRuntype = r.Record({
    header_row_number: r.Number,
    raw_header_row: RowRuntype,
});

export type SingleOriginalExcelRow = r.Static<typeof SingleOriginalExcelRowRuntype>;
const SingleOriginalExcelRowRuntype = r.Record({
    line_number: r.Number,
    raw_original_line: RowRuntype,
    issues: r.Array(BomImporterIssueRuntype),
});

const PartSuggestionRuntype = r.Union(
    r.Record({
        part: r.Record({
            type: r.Union(r.Literal('OffTheShelf'), r.Literal('Generic'), r.Literal('Ipn')),
            data: r.String,
        }),
        origin: PartSuggestionOriginRuntype.nullable().optional(),
    }),
    r.Record({
        part: r.Record({
            type: r.Literal('IncompleteGeneric'),
            data: TechnicalParametersRuntype,
        }),
        origin: PartSuggestionOriginRuntype.nullable().optional(),
    }),
);

const BomItem = r.Record({
    designators: r.Array(r.String),
    quantity_per_designator: r.Number.nullable(),
    dnp: r.Boolean,
    unit: QuantityUnitRuntype,
    notes: r.String.optional(),
    part_specification: PartSpecificationRuntype.nullable(),
    part_suggestions: r.Array(PartSuggestionRuntype),
    is_consigned: r.Boolean,
});

export interface BomImporterLine extends r.Static<typeof BomImporterLineRuntype> {}

const BomImporterLineRuntype = r.Record({
    excel_origins: r.Array(SingleOriginalExcelRowRuntype),
    bom_item: BomItem.nullable(),
    issues: r.Array(BomImporterIssueRuntype),
});

// We do not want to display some of the column types we get from the bom importer,
// because they would only add more confusion to the user
export type DisplayableColumnIds = Exclude<keyof ColumnMap, 'enumeration' | 'generic_part_type'>;

export type ColumnMap = r.Static<typeof ColumnMapRuntype>;
const ColumnMapRuntype = r.Record({
    designators: r.Array(r.Number),
    quantity: r.Array(r.Number),
    unit: r.Array(r.Number),
    dnp: r.Array(r.Number),
    manufacturer_free: r.Array(r.Number),
    generic_part_type: r.Array(r.Number),
    manufacturer: r.Array(r.Number),
    mpn: r.Array(r.Number),
    supplier_part_number: r.Array(r.Number),
    cpn: r.Array(r.Number),
    technical_parameters: r.Array(r.Number),
    notes: r.Array(r.Number),
    enumeration: r.Array(r.Number),
    ipn: r.Array(r.Number),
    consigned: r.Array(r.Number),
    cpn_revision: r.Array(r.Number),
    export_pass_through: r.Array(r.Number),
    level: r.Array(r.Number),
});

// input for step 1 is just excel file and how we did it for /converter endpoint

export type BomScreeningSheet = r.Static<typeof BomScreeningSheetRunType>;
export const BomScreeningSheetRunType = r.Record({
    global_issues_response: r.Array(BomImporterIssueRuntype),
    // The thing you want to edit
    column_map: ColumnMapRuntype,
    // We currently represent excel lines just as dumped json string but that should
    // potentially change in this story, I'm leaving it as was it for now though
    excel_lines: r.Array(SingleOriginalExcelRowRuntype),
    header_metadata: r.Null.Or(HeaderMetadataRuntype),
    sheet_name: r.String,
    reused_column_map: r.Boolean,
});

export type ScreenerHandlerOutput = r.Static<typeof ScreenerHandlerOutputRuntype>;
export const ScreenerHandlerOutputRuntype = r.Record({
    file_name: r.String,
    sheets: r.Array(BomScreeningSheetRunType),
});

export type LineScreenerErrorOutput = r.Static<typeof LineScreenerErrorOutputRuntype>;
export const LineScreenerErrorOutputRuntype = r.Record({
    file_name: r.String,
    error_code: ErrorCodeRuntype,
});

export function isScreenerHandlerOutput(
    bom: LineScreenerErrorOutput | ScreenerHandlerOutput,
): bom is ScreenerHandlerOutput {
    return 'sheets' in bom;
}

export function isLineScreenerErrorOutput(
    bom: LineScreenerErrorOutput | ScreenerHandlerOutput,
): bom is LineScreenerErrorOutput {
    return 'error_code' in bom;
}

// The output we get for step 1, accepts excel file returns information for first screen
export type BomScreeningOutput = r.Static<typeof BomScreeningOutputRuntype>;
export const BomScreeningOutputRuntype = r.Union(ScreenerHandlerOutputRuntype, LineScreenerErrorOutputRuntype);

export enum PreviousLinesSearchVariant {
    Inactive = 'Inactive',
    AllColumns = 'AllColumns',
    SelectedColumns = 'SelectedColumns',
}

export type PreviousLinesSearchColumns = r.Static<typeof PreviousLinesSearchColumnsRuntype>;
const PreviousLinesSearchColumnsRuntype = r.Record({
    variant: runtypeFromEnum(PreviousLinesSearchVariant),
    columns: r.Array(r.String),
});

// Input for step 2
export type BomLineBuildingInput = r.Static<typeof BomLineBuildingInputRuntype>;
const BomLineBuildingInputRuntype = r.Record({
    column_map: ColumnMapRuntype,
    relevant_excel_lines: r.Array(SingleOriginalExcelRowRuntype),
    file_name: r.String,
    bom_header_metadata: r.Null.Or(HeaderMetadataRuntype),
    sheet_name: r.String,
    rfq_id: r.String,
    importing_assembly: r.String,
    previous_lines_search_columns: PreviousLinesSearchColumnsRuntype,
});

export type BomLineBuildingOutput = r.Static<typeof BomLineBuildingOutputRuntype>;

const ParentOrAncestorData = r.Record({
    id: r.String,
    quantity: r.Number.optional(),
});

const BomImporterAssemblyParentOrAncestor = r.Record({
    ancestor_type: r.Literal('Ancestor').Or(r.Literal('Parent')),
    data: ParentOrAncestorData,
});

const BomImporterAssemblyAncestorMapping = r.Array(r.Array(BomImporterAssemblyParentOrAncestor));

const CpnRevision = r.Record({
    value: r.String,
    revision: r.String.nullable(),
});

export const BomImportedAssemblyIssueRuntype = r.Union(
    r.Record({
        issue_type: r.Literal('AutomaticallyGenerated'),
    }),
    r.Record({
        issue_type: r.Literal('ConflictingIpnIdentifier'),
        data: r.Array(r.String),
    }),
    r.Record({
        issue_type: r.Literal('ConflictingCpnIdentifier'),
        data: r.Array(CpnRevision),
    }),
);

export type BomImportedAssemblyIssue = r.Static<typeof BomImportedAssemblyIssueRuntype>;

const BomImporterAssemblyParentRuntype = r.Record({
    id: r.String,
    quantity: r.Number,
});

const BomImporterAssemblyInput = r.Record({
    id: r.String,
    designator: r.String,
    issues: r.Array(BomImportedAssemblyIssueRuntype),
    parents: r.Array(BomImporterAssemblyParentRuntype),
    ancestor_mapping: BomImporterAssemblyAncestorMapping,
    excel_origins: ExcelLinesRuntype.nullable(),
});

export const BomLineBuildingOutputRuntype = r.Record({
    lines_in_assembly: r.Dictionary(r.Array(BomImporterLineRuntype), r.String),
    assemblies: r.Array(BomImporterAssemblyInput),
});

export interface BomLineBuildingErrorOutput extends r.Static<typeof BomLineBuildingErrorOutputRuntype> {}

const AssemblyRecognitionErrorOutputRuntype = r.Record({
    error_type: r.Union(r.Literal('UnreachableAssemblyState'), r.Literal('UnrecognizedTopLevelAssembly')),
    context: r.String.nullable(),
});

export type AssemblyRecognitionError = r.Static<typeof AssemblyRecognitionErrorOutputRuntype>;
export const BomLineBuildingErrorOutputRuntype = r.Record({
    file_name: r.String,
    data: r.Union(BomScreeningSheetRunType, AssemblyRecognitionErrorOutputRuntype),
});

const BomImporterBackendHeaderMetadataRuntype = r.Record({
    header_line_number: r.Number,
    raw_header_line: RowRuntype,
});

export type BomImporterBackendInput = r.Static<typeof BomImporterBackendInputRuntype>;
export const BomImporterBackendInputRuntype = r.Record({
    header_metadata: r.Optional(BomImporterBackendHeaderMetadataRuntype.nullable()),
    column_map: ColumnMapRuntype,
    importing_assembly: r.String,
    assemblies: r.Array(BomImporterAssemblyInput),
    lines_per_assembly: r.Dictionary(r.Array(BomImporterLineRuntype), r.String),
    accept_previous_bom_lines: r.Boolean,
    previous_lines_search_variant: runtypeFromEnum(PreviousLinesSearchVariant),
});

export const BomImporterErrorResponseRuntype = r.Record({
    detail: r.String,
    title: r.String,
    status: r.Number,
    code: ErrorCodeRuntype,
});

export type BomFileDTO = r.Static<typeof BomFileDTORuntype>;
export const BomFileDTORuntype = r.Record({
    id: r.String,
    raw_header: RowRuntype,
    // this assembly field will be deprecated soon
    assembly: r.String.optional(),
    // TODO: @Tev will remove this optional tag on the assembly_ids field in a separate MR, once the backend changes have been merged
    assembly_ids: r.Array(r.String).optional(),
    column_map: ColumnMapRuntype.nullable(),
});

export enum BomProgressStage {
    FileReading = 'FileReading',
    Screening = 'Screening',
    CrossChecking = 'CrossChecking',
    LineBuilding = 'LineBuilding',
    Completed = 'Completed',
}

const BomProgressStageRuntype = runtypeFromEnum(BomProgressStage);

export type TaskAcceptedResponse = r.Static<typeof TaskAcceptedResponseRuntype>;
export const TaskAcceptedResponseRuntype = r.Record({
    task_endpoint: r.String,
});

export type TechnicalParams = r.Static<typeof TechnicalParamsRuntype>;
export const TechnicalParamsRuntype = r.Dictionary(r.Unknown, r.String);

export type BomProgressDTO = r.Static<typeof BomProgressRuntype>;
export const BomProgressRuntype = r.Union(
    r.Record({
        status: r.Literal('PROGRESS'),
        progress: r.Number,
        task_id: r.String,
        data: r.Record({ stage: r.Optional(BomProgressStageRuntype) }).nullable(),
    }),
    r.Record({
        status: r.Literal('SUCCESS'),
        progress: r.Number,
        task_id: r.String,
        data: r.Record({
            data: r.Union(BomLineBuildingOutputRuntype, BomScreeningOutputRuntype),
        }),
    }),
    r.Record({
        status: r.Literal('FAILED'),
        task_id: r.String,
        data: r
            .Record({
                data: BomLineBuildingErrorOutputRuntype,
            })
            .nullable(),
    }),
);
