/* eslint-disable camelcase */
import { plural, t, Trans } from '@lingui/macro';
import { usePermissions } from '@luminovo/auth';
import { assertUnreachable } from '@luminovo/commons';
import {
    Action,
    colorSystem,
    DataTable,
    DefaultStyledTable,
    Flexbox,
    PersistentTableState,
    SecondaryButton,
    TableState,
    Text,
    Tooltip,
    useDataTableState,
} from '@luminovo/design-system';
import { AssemblyIndustry, FullPart, GenericPartTypes, PartSuggestionFull, RfqContext } from '@luminovo/http-client';
import { styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { hasPartsWithCpns } from '../../../../../components/partColumns/hasPartsWithCpns';
import { useGenericPartDetailsDrawer } from '../../../../../components/partSpecificationCards/GenericPart/GenericPartCardDetails';
import { useIpnDetailsDrawer } from '../../../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { useOtsPartDetailsDrawer } from '../../../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { BomItem } from '../../../../../resources/designItem/bomItemFrontendTypes';
import {
    otsComponentLinkingDialog,
    useOtsComponentLinkingDialog,
} from '../../LinkPartsDialog/useOtsComponentLinkingDialog';
import { OtsPartSpecificationType } from '../../SpecificationTypeForms/types';
import { TableContainer } from '../TableContainer';
import {
    blockedManufacturersDialog,
    useAddPartWithBlockedManufacturerDialog,
} from '../useAddPartWithBlockedManufacturerDialog';
import { getPartialMatchesColumns, useGenericPartEditDrawer } from './columns';
import { PartialMatchesContext } from './partialMatchesTypes';

const idExtractor = (rowData: PartSuggestionFull): string => {
    if (rowData.type === 'IncompleteGeneric') {
        const { type } = rowData.part;
        if (type === GenericPartTypes.Capacitor) {
            const { package_id, tolerance, voltage_rating, capacitance, dielectric } =
                rowData.part.technical_parameters;
            return `incomplete-${package_id ?? ''}-${tolerance ?? ''}-${voltage_rating ?? ''}-${capacitance ?? ''}-${dielectric ?? ''}`;
        }
        if (type === GenericPartTypes.Resistor) {
            const { package_id, tolerance, voltage_rating, resistance, power_rating, temperature_coefficient } =
                rowData.part.technical_parameters;
            return `incomplete-${package_id ?? ''}-${tolerance ?? ''}-${voltage_rating ?? ''}-${resistance ?? ''}-${power_rating ?? ''}-${temperature_coefficient ?? ''}`;
        }
        assertUnreachable(type);
    }
    return rowData.part.id;
};

export const PartialMatchesTable = ({
    partialMatches,
    handleAddPartOption,
    handleAddMultiplePartOptions,
    rfqId,
    bomItem,
    assemblyId,
    assemblyIndustry,
    hasDesignatorQuantityMismatch,
    isSubmitting,
}: {
    partialMatches: PartSuggestionFull[];
    handleAddPartOption: OtsPartSpecificationType['handleAddPart'];
    handleAddMultiplePartOptions: OtsPartSpecificationType['handleAddMultiplePartOptions'];
    rfqId: string;
    bomItem: BomItem;
    assemblyId: string;
    assemblyIndustry?: AssemblyIndustry;
    hasDesignatorQuantityMismatch: boolean;
    isSubmitting: boolean;
}): JSX.Element => {
    const location = useLocation();
    const rfqContext: RfqContext = useMemo(() => ({ type: 'WithinRfQ', rfq_id: rfqId }), [rfqId]);
    const { openDialog: openOtsCompLinkingDialog } = useOtsComponentLinkingDialog();
    const { openDrawer: openOtsDrawer } = useOtsPartDetailsDrawer();
    const { openDrawer: openIpnDrawer } = useIpnDetailsDrawer();
    const { openDrawer: openGenericPartDrawer } = useGenericPartDetailsDrawer();
    const { openDrawer: openEditGenericPartDrawer } = useGenericPartEditDrawer({
        rfqContext,
        onGenericPartCreation: handleAddPartOption,
    });

    const sharedContext: PartialMatchesContext = useMemo(() => {
        return {
            rfqContext,
            bomItem,
            rfqId,
            assemblyId,
            handleAddPartOption,
            handleAddMultiplePartOptions,
            assemblyIndustry,
            openOtsCompLinkingDialog,
            hasDesignatorQuantityMismatch,
            isSubmitting,
        };
    }, [
        rfqContext,
        bomItem,
        rfqId,
        assemblyId,
        handleAddPartOption,
        handleAddMultiplePartOptions,
        assemblyIndustry,
        openOtsCompLinkingDialog,
        hasDesignatorQuantityMismatch,
        isSubmitting,
    ]);

    const hasPartOptionsWithCpns = useMemo(
        () =>
            hasPartsWithCpns({
                parts: partialMatches.map((partialMatch) => partialMatch.part),
            }),
        [partialMatches],
    );

    const columns = useMemo(() => getPartialMatchesColumns({ hasPartOptionsWithCpns }), [hasPartOptionsWithCpns]);
    const tableState: TableState<PartSuggestionFull, PartialMatchesContext> = useDataTableState({
        columns,
        items: partialMatches,
        paginationOptions: {
            showPagination: false,
        },
        persistenceId: `partial-matches.${location.pathname}`,
        sharedContext,
        selectionOptions: { idExtractor },
    });

    const handleRowClick = useCallback(
        (suggestion: PartSuggestionFull) => {
            if (suggestion.type === 'OffTheShelf') {
                return openOtsDrawer({ part: suggestion.part, rfqContext });
            }
            if (suggestion.type === 'Component') {
                return openIpnDrawer({ ipnId: suggestion.part.id, rfqContext });
            }
            if (suggestion.type === 'Generic') {
                return openGenericPartDrawer({
                    genericPart: suggestion.part.content,
                    mpnMatches: suggestion.part.matches,
                    isEditEnable: true,
                    onAddAlsoRemove: (part) => handleAddPartOption(part, 'partialMatches'),
                    rfqContext,
                });
            }
            if (suggestion.type === 'IncompleteGeneric') {
                return openEditGenericPartDrawer({ part: suggestion.part });
            }
        },
        [
            handleAddPartOption,
            openEditGenericPartDrawer,
            openGenericPartDrawer,
            openIpnDrawer,
            openOtsDrawer,
            rfqContext,
        ],
    );

    if (partialMatches.length === 0) {
        return (
            <Text variant="h5" color={colorSystem.neutral[6]}>
                <Trans>No partial part matches found</Trans>
            </Text>
        );
    }

    return (
        <>
            <AddMultiplePartOptionsButton
                handleAddMultiplePartOptions={handleAddMultiplePartOptions}
                dispatch={tableState.dispatch}
                state={tableState.state}
                partialMatches={partialMatches}
                bomItem={bomItem}
                rfqContext={rfqContext}
                handleAddPartOption={handleAddPartOption}
            />
            <DataTable
                tableState={tableState}
                overrides={{ Container: StyledTableContainer, Table: StyledTable }}
                onItemClick={handleRowClick}
                stickyHeader={false}
                size="medium"
            />
        </>
    );
};

// The table & its container are rotated by 180 degrees so that the scrollbar is moved to the top of the table.
const rotate180degrees = 'rotateX(180deg)';

const StyledTableContainer = styled(TableContainer)({
    overflow: 'invisible',
    transform: rotate180degrees,
});

const StyledTable = styled(DefaultStyledTable)({
    transform: rotate180degrees,
});

const AddMultiplePartOptionsButton = ({
    handleAddMultiplePartOptions,
    dispatch,
    state,
    partialMatches,
    bomItem,
    rfqContext,
    handleAddPartOption,
}: {
    handleAddMultiplePartOptions: OtsPartSpecificationType['handleAddMultiplePartOptions'];
    dispatch: React.Dispatch<Action>;
    state: PersistentTableState;
    partialMatches: PartSuggestionFull[];
    rfqContext: RfqContext;
    bomItem: BomItem;
    handleAddPartOption: OtsPartSpecificationType['handleAddPart'];
}) => {
    const partOptions = bomItem.parts;
    const selectedPartIds = state.selectedIds;
    const { permissions } = usePermissions();
    const { openDialog: openBlockedManuDialog } = useAddPartWithBlockedManufacturerDialog();
    const { openDialog: openOtsCompLinkingDialog } = useOtsComponentLinkingDialog();
    const { openDrawer: openIncompleteGenericPartDialog } = useGenericPartEditDrawer({
        rfqContext,
        onGenericPartCreation: handleAddPartOption,
    });

    const handleClick = useCallback(
        ({ selectedPartIds }: { selectedPartIds: string[] }) => {
            const partsToAdd = partialMatches.reduce((acc: FullPart[], part) => {
                if (part.type !== 'IncompleteGeneric' && selectedPartIds.includes(part.part.id)) {
                    return [...acc, part.part];
                }
                return acc;
            }, []);

            const checkAndHandleComponentLinking = ({ newParts }: { newParts: FullPart[] }) => {
                const otsCompLinkingData = otsComponentLinkingDialog({
                    partOptions,
                    partsToAddAsPartOption: newParts,
                    permissions,
                });
                if (otsCompLinkingData.shouldOpenComponentLinkingDialog) {
                    const { otsComponent, partsToLink } = otsCompLinkingData;
                    return openOtsCompLinkingDialog({
                        otsComponent,
                        partsToLink,
                        onConfirm: () => {
                            handleAddMultiplePartOptions(newParts, 'partialMatches');
                            dispatch({ type: 'clear-selected-items' });
                        },
                        onCancel: () => {
                            handleAddMultiplePartOptions(newParts, 'partialMatches');
                            dispatch({ type: 'clear-selected-items' });
                        },
                    });
                }
                handleAddMultiplePartOptions(newParts, 'partialMatches');
                dispatch({ type: 'clear-selected-items' });
            };

            const checkAndHandleIncompleteGenericParts = ({ partsToAdd }: { partsToAdd: FullPart[] }) => {
                const incompleteGenericParts =
                    selectedPartIds.filter((id) => id.includes('incomplete')).length > 0
                        ? partialMatches.filter((part) => part.type === 'IncompleteGeneric')
                        : [];

                if (incompleteGenericParts.length > 0) {
                    return openIncompleteGenericPartDialog({
                        part: incompleteGenericParts[0].part,
                        onIncompletePartCreated: (newPart) => {
                            checkAndHandleComponentLinking({ newParts: [...partsToAdd, newPart] });
                        },
                    });
                }
                checkAndHandleComponentLinking({ newParts: partsToAdd });
            };

            const checkAndHandleBlockedManufacturers = () => {
                const blockedManufacturersData = blockedManufacturersDialog({
                    fullParts: partsToAdd,
                });
                if (blockedManufacturersData.shouldOpenBlockedManufacturersDialog) {
                    const { partsWithBlockedManufacturers } = blockedManufacturersData;
                    return openBlockedManuDialog({
                        partsWithBlockedManufacturers,
                        onConfirm: () => checkAndHandleIncompleteGenericParts({ partsToAdd }),
                    });
                }
                checkAndHandleIncompleteGenericParts({ partsToAdd });
            };

            checkAndHandleBlockedManufacturers();
        },
        [
            handleAddMultiplePartOptions,
            dispatch,
            partialMatches,
            openIncompleteGenericPartDialog,
            partOptions,
            permissions,
            openOtsCompLinkingDialog,
            openBlockedManuDialog,
        ],
    );

    return (
        <Flexbox justifyContent="flex-end" marginBottom="8px" position="absolute" top="71px" right="20px">
            <Tooltip title={t`Select the parts you want to add first`}>
                <span>
                    <SecondaryButton
                        size="medium"
                        onClick={() => handleClick({ selectedPartIds })}
                        disabled={selectedPartIds.length === 0}
                    >
                        {selectedPartIds.length > 0 ? (
                            <>
                                <Trans>{`${plural(selectedPartIds.length, {
                                    one: `Add ${selectedPartIds.length} selected part`,
                                    other: `Add ${selectedPartIds.length} selected parts`,
                                })}`}</Trans>
                            </>
                        ) : (
                            <Trans>Add parts</Trans>
                        )}
                    </SecondaryButton>
                </span>
            </Tooltip>
        </Flexbox>
    );
};
