import { Trans } from '@lingui/macro';
import { BaseButtonProps, SecondaryButton } from '@luminovo/design-system';
import { useFormContext } from 'react-hook-form';

type CancelButtonProps = Omit<BaseButtonProps, 'type' | 'disabled' | 'href'> & {
    disabled?: boolean;
    onClick?: () => void;
};

export function CancelButton({ disabled = false, onClick, ...props }: CancelButtonProps) {
    const { formState, reset } = useFormContext();

    return (
        <SecondaryButton
            disabled={formState.isSubmitting || disabled}
            type={'reset'}
            onClick={() => {
                onClick ? onClick() : reset();
            }}
            style={{ minWidth: 80 }}
            {...props}
        >
            <Trans>Cancel</Trans>
        </SecondaryButton>
    );
}
