import { Trans } from '@lingui/macro';
import { Permission, usePermissions } from '@luminovo/auth';
import { id, isPresent } from '@luminovo/commons';
import {
    DataTable,
    Flexbox,
    SecondaryButton,
    Text,
    colorSystem,
    extractHighlights,
    useDataTableState,
} from '@luminovo/design-system';
import { AssemblyIndustry, OtsFullPart, RfqContext } from '@luminovo/http-client';
import { Box, CircularProgress } from '@mui/material';
import { InfiniteData } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useFormState } from 'react-hook-form';
import { ComparePartsMessage } from '../../../../components/CompareItemsDialog';
import { OtsPartSearchComparisonDialog } from '../../../../components/CompareItemsDialog/OtsPartSearchComparisonDialog/OtsPartSearchComparisonDialog';
import { isEmptyQuery } from '../../../../components/SearchContainer';
import { useOtsPartDetailsDrawer } from '../../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { getMpnTerms } from '../../../PartLibrary/IPNLinking/AddNewParts/OtsPartSearch';
import { useOtsPartAddDialog } from '../../../PartLibrary/PartDetailsPage/components/OtsPartDialogs/OtsPartAddDialog';
import { PartSearchInput, usePartSearchState } from '../../../PartLibrary/PartSearchPage/PartSearchInput';
import { SearchResultsPage, useSearchParts } from '../../../PartLibrary/PartSearchPage/useSearchParts';
import { getApprovedIpnOrOtsParts } from '../AddParts/PartAlternatives/PartAlternativesTable';
import { TableContainer } from '../AddParts/TableContainer';
import { useHasDesignatorQuantityMismatch } from '../AddParts/hasDesignatorOrQtyMismatch';
import { OtsPartSpecificationType } from '../SpecificationTypeForms/types';
import {
    columnActions,
    columnAvailability,
    columnCompliance,
    columnDatasheet,
    columnDescription,
    columnLifecycle,
    columnManufacturer,
    columnMpn,
    columnPackage,
    columnSelection,
} from './columns';
import { OtsPartSearchTableContext, OtsPartSearchTableData } from './otsPartSearchTypes';

interface OtsPartSearchProps {
    rfqId: string;
    bomItem: BomItem;
    assemblyId: string;
    otsPartSpecificationContext: OtsPartSpecificationType;
    onRemovePart: (partId: string) => void;
    rfqContext: RfqContext;
    existingPartIds: string[] | undefined;
    assemblyIndustry?: AssemblyIndustry;
}

export const OtsPartSearch = ({
    rfqId,
    bomItem,
    assemblyId,
    otsPartSpecificationContext,
    existingPartIds,
    onRemovePart,
    rfqContext,
    assemblyIndustry,
}: OtsPartSearchProps): JSX.Element => {
    const { handleAddPart } = otsPartSpecificationContext;
    const searchState = usePartSearchState({});
    const { permissions } = usePermissions();
    const { openDialog: openOtsPartAddDialog } = useOtsPartAddDialog();

    const {
        data: searchResults,
        isFetching,
        fetchNextPage,
        hasNextPage,
    } = useSearchParts({ searchState, rfqContext: { rfq_context: 'WithinRfQ', rfq_id: rfqId } });

    const currentPage = searchResults && searchResults.pages[searchResults.pages.length - 1];
    const searchEntered = !isEmptyQuery(searchState);

    return (
        <>
            <Flexbox gap="8px" flexDirection="column">
                <PartSearchInput searchResultsPage={currentPage} searchState={searchState} />
            </Flexbox>
            {searchEntered && isFetching && !searchResults && (
                <div style={{ padding: '4px' }}>
                    <CircularProgress size={20} />
                </div>
            )}
            {searchEntered && searchResults && (
                <>
                    <PartResultsContainer
                        assemblyId={assemblyId}
                        rfqId={rfqId}
                        bomItem={bomItem}
                        searchResults={searchResults}
                        otsPartSpecificationContext={otsPartSpecificationContext}
                        handleRemovePart={onRemovePart}
                        existingPartIds={existingPartIds}
                        rfqContext={rfqContext}
                        permissions={permissions}
                        assemblyIndustry={assemblyIndustry}
                    />
                    <Box display="grid" gap="8px" style={{ placeItems: 'center' }}>
                        {hasNextPage ? (
                            <SecondaryButton
                                disabled={isFetching}
                                onClick={() => fetchNextPage()}
                                startIcon={isFetching && <CircularProgress size={20} />}
                                size="medium"
                            >
                                <Trans>Load more</Trans>
                            </SecondaryButton>
                        ) : (
                            <NoMoreResultsContainer
                                searchResults={searchResults}
                                openOtsPartAddDialog={openOtsPartAddDialog}
                                handleAddPart={handleAddPart}
                            />
                        )}
                    </Box>
                </>
            )}
        </>
    );
};

const NoMoreResultsContainer = ({
    searchResults,
    openOtsPartAddDialog,
    handleAddPart,
}: {
    searchResults: InfiniteData<SearchResultsPage>;
    openOtsPartAddDialog: ({
        initialMpn,
        onSuccess,
    }: {
        initialMpn: string;
        onSuccess: (newPart: OtsFullPart) => void;
    }) => void;
    handleAddPart: OtsPartSpecificationType['handleAddPart'];
}) => {
    const parts = searchResults?.pages.flatMap((res) => res?.page ?? []) ?? [];

    if (parts.length === 0) {
        return null;
    }
    return (
        <>
            <Text variant="h5" color={colorSystem.neutral[7]}>
                <Trans> No more results for your search </Trans>
            </Text>
            <span>
                <SecondaryButton
                    onClick={() => {
                        openOtsPartAddDialog({
                            initialMpn: '',
                            onSuccess: (newPart) => handleAddPart(newPart, 'otsPartForm'),
                        });
                    }}
                    size="medium"
                    disableTouchRipple
                >
                    <Trans>Add off-the-shelf part manually</Trans>
                </SecondaryButton>
            </span>
        </>
    );
};

const PartResultsContainer = ({
    rfqId,
    bomItem,
    searchResults,
    otsPartSpecificationContext,
    handleRemovePart,
    rfqContext,
    assemblyId,
    existingPartIds,
    permissions,
    assemblyIndustry,
}: {
    rfqId: string;
    bomItem: BomItem;
    searchResults: InfiniteData<SearchResultsPage>;
    otsPartSpecificationContext: OtsPartSpecificationType;
    handleRemovePart: (partId: string) => void;
    existingPartIds: string[] | undefined;
    rfqContext: RfqContext;
    assemblyId: string;
    permissions: Permission[];
    assemblyIndustry?: AssemblyIndustry;
}) => {
    const parts: OtsFullPart[] = searchResults?.pages.flatMap((res) => res.page) ?? [];

    const mpnTerms = getMpnTerms(searchResults);

    const highlights = Object.assign({}, ...searchResults.pages.map((res) => res.highlights ?? {}));

    return (
        <OtsPartSearchTable
            assemblyId={assemblyId}
            rfqId={rfqId}
            bomItem={bomItem}
            parts={parts}
            mpnTerms={mpnTerms}
            highlights={highlights}
            otsPartSpecificationContext={otsPartSpecificationContext}
            handleRemovePart={handleRemovePart}
            rfqContext={rfqContext}
            existingPartIds={existingPartIds}
            permissions={permissions}
            assemblyIndustry={assemblyIndustry}
        />
    );
};

const otsPartSearchDataIdExtractor = (data: OtsPartSearchTableData): string => {
    return data.part.id;
};

const selectionOptions = {
    idExtractor: otsPartSearchDataIdExtractor,
};

const otsPartSearchColumns = [
    columnMpn,
    columnManufacturer,
    columnDescription,
    columnPackage,
    columnDatasheet,
    columnAvailability,
    columnLifecycle,
    columnCompliance,
    columnActions,
];

const OtsPartSearchTable = ({
    rfqId,
    bomItem,
    parts,
    assemblyId,
    mpnTerms,
    highlights,
    otsPartSpecificationContext,
    handleRemovePart,
    rfqContext,
    existingPartIds,
    permissions,
    assemblyIndustry,
}: {
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    parts: OtsFullPart[];
    mpnTerms: RegExp[];
    highlights: Record<string, string[]>;
    otsPartSpecificationContext: OtsPartSpecificationType;
    handleRemovePart: (partId: string) => void;
    rfqContext: RfqContext;
    existingPartIds: string[] | undefined;
    permissions: Permission[];
    assemblyIndustry?: AssemblyIndustry;
}) => {
    const { handleAddPart, handleAddMultiplePartOptions } = otsPartSpecificationContext;
    const [isPartSelectionActive, setPartSelectionActive] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { isSubmitting } = useFormState();
    const { openDrawer: openOtsDrawer } = useOtsPartDetailsDrawer();
    const hasDesignatorQuantityMismatch = useHasDesignatorQuantityMismatch();

    const approvedIpnOrOtsParts = getApprovedIpnOrOtsParts(bomItem);
    const partToCompareTo = existingPartIds ? parts.filter((p) => !existingPartIds.includes(p.id)) : parts;

    const isCompareEnabled = approvedIpnOrOtsParts.length > 0 && partToCompareTo.length > 0;

    const sharedContext: OtsPartSearchTableContext = {
        rfqId,
        bomItem,
        assemblyId,
        handleAddPart,
        handleAddMultiplePartOptions,
        handleRemovePart,
        rfqContext,
        existingPartIds,
        isSubmitting,
        permissions,
        hasDesignatorQuantityMismatch,
        assemblyIndustry,
    };

    const tableItems = parts.map((part: OtsFullPart) => {
        return {
            part,
            highlights: extractHighlights(highlights[part.id] ?? []),
            mpnTerms,
        };
    });

    const tableState = useDataTableState({
        columns: isPartSelectionActive ? [columnSelection, ...otsPartSearchColumns] : otsPartSearchColumns,
        items: tableItems,
        persistenceId: `ots-part-search-table`,
        paginationOptions: {
            showPagination: false,
            defaultRowsPerPage: 5000,
            rowsPerPageOptions: [5000],
        },
        sharedContext,
        selectionOptions,
    });

    const handleRowClick = useCallback(
        (part: OtsPartSearchTableData) => {
            return openOtsDrawer({ part: part.part, rfqContext });
        },
        [openOtsDrawer, rfqContext],
    );

    const selectedOtsParts = tableState.state.selectedIds
        .map((id) => tableItems.find((item) => otsPartSearchDataIdExtractor(item) === id)?.part)
        .filter(isPresent);

    return (
        <>
            {isPartSelectionActive ? (
                <span style={{ position: 'absolute', top: '60px', left: '20px', width: 'calc(100% - 40px)' }}>
                    <ComparePartsMessage
                        isCompareEnabled={isCompareEnabled}
                        countOfSelectedParts={selectedOtsParts.length}
                        setPartSelectionActive={setPartSelectionActive}
                        clearSelection={() => tableState.dispatch({ type: 'clear-selected-items' })}
                        setIsDialogOpen={setIsDialogOpen}
                        bomItem={bomItem}
                    />
                </span>
            ) : (
                <Flexbox justifyContent="flex-end" marginBottom="8px" position="absolute" top="71px" right="20px">
                    <SecondaryButton
                        size="medium"
                        onClick={() => {
                            if (parts.length > 0 && parts.length < 3) {
                                return setIsDialogOpen(true);
                            }
                            return setPartSelectionActive(true);
                        }}
                        disabled={!isCompareEnabled}
                        id={id('design/button_compare_parts')}
                    >
                        <Trans>Compare</Trans>
                    </SecondaryButton>
                </Flexbox>
            )}
            <DataTable
                tableState={tableState}
                size="large"
                stickyHeader={false}
                onItemClick={handleRowClick}
                overrides={{ Container: TableContainer }}
            />
            {approvedIpnOrOtsParts.length > 0 && (
                <OtsPartSearchComparisonDialog
                    key={partToCompareTo.map((p) => p.id).join()}
                    assemblyId={assemblyId}
                    isOpen={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    partOptions={approvedIpnOrOtsParts}
                    searchResults={parts.length > 0 && partToCompareTo.length < 3 ? partToCompareTo : selectedOtsParts}
                    rfqId={rfqId}
                    bomItem={bomItem}
                    handleAddPartOption={handleAddPart}
                    tableState={tableState}
                    assemblyIndustry={assemblyIndustry}
                />
            )}
        </>
    );
};
