import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import { CenteredLayout, Dropzone } from '@luminovo/design-system';
import { UploadedFileType, http } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { Prompt } from 'react-router-dom';
import ConfirmationDialogBox from '../../../components/dialogBox/ConfirmationDialogBox';
import {
    useAssemblyResources,
    useDeleteAdditionalFile,
    useDeleteCadFile,
} from '../../../resources/assembly/assemblyHandler';
import { useHttpFileUpload } from '../../../resources/http/useHttpFileUpload';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { getFileNameFromResourcesString } from '../../../utils/stringFunctions';
interface FileSectionProps {
    assemblyId: string;
    rfqId: string;
    isEditable: boolean;
    tab: 'Cad' | 'Manufacturing';
}

export const FilesSection = ({ assemblyId, rfqId, isEditable, tab }: FileSectionProps): JSX.Element => {
    const { cadFile, additionalFiles } = useAssemblyResources(assemblyId);
    const { data: guidanceText } = useHttpQuery(
        /* eslint-disable-next-line spellcheck/spell-checker */
        'GET /assemblies/guidance-text',
        {},
    );

    if (cadFile === undefined || additionalFiles === undefined || !isPresent(guidanceText)) {
        return (
            <CenteredLayout height={'100%'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    if (tab === 'Cad') {
        return (
            <CadFile
                assemblyId={assemblyId}
                rfqId={rfqId}
                content={{ file: cadFile, guidanceText: guidanceText.cad }}
                isEditable={isEditable}
            />
        );
    }

    if (tab === 'Manufacturing') {
        return (
            <AdditionalFiles
                assemblyId={assemblyId}
                rfqId={rfqId}
                content={{ files: additionalFiles, guidanceText: guidanceText.other }}
                isEditable={isEditable}
            />
        );
    }
    assertUnreachable(tab);
};

const CadFile = ({
    assemblyId,
    rfqId,
    content,
    isEditable,
}: {
    assemblyId: string;
    rfqId: string;
    content: { file: string | null; guidanceText: string };
    isEditable: boolean;
}) => {
    const { file, guidanceText } = content;
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState<
        { isOpen: false } | { isOpen: true; onConfirm: () => void }
    >({ isOpen: false });
    const { mutateAsync: uploadCadFile, isPending: isUploading } = useHttpFileUpload(
        'GET /assemblies/:assemblyId/upload/cad',
        (res) => res.data.url,
        {
            snackbarMessage: t`Files uploaded successfully`,
            onSuccess: async (response, variables) => {
                const files = Array.isArray(variables.files) ? variables.files : [variables.files];
                await Promise.all(
                    files.map((file) =>
                        http(
                            'POST /assemblies/:id/history/file-uploaded',
                            {
                                pathParams: { id: assemblyId },
                                // eslint-disable-next-line camelcase
                                requestBody: { file_type: UploadedFileType.Cad, file_name: file.name },
                            },
                            getToken(),
                        ),
                    ),
                );
            },
        },
    );
    const { mutateAsync: deleteCadFile, isPending: isDeleting } = useDeleteCadFile(assemblyId, rfqId);

    return (
        <>
            <Prompt
                when={isUploading || isDeleting}
                message={t`Files are still loading. Are you sure you want to leave?`}
            />

            <Dropzone
                title={t`CAD`}
                subHeader={guidanceText}
                onDropAccepted={(files) => uploadCadFile({ pathParams: { assemblyId }, files })}
                multiple={false}
                disabled={!isEditable || isPresent(file)}
                isLoading={isUploading}
                accept={null}
                persistentFiles={
                    file
                        ? [
                              {
                                  name: getFileNameFromResourcesString(file),
                                  onClick: () => window.open(file, '_blank'),
                                  onDelete: () => {
                                      setIsConfirmationDialogOpen({
                                          isOpen: true,
                                          onConfirm: () => deleteCadFile(getFileNameFromResourcesString(file)),
                                      });
                                  },
                                  disabled: isDeleting || !isEditable,
                              },
                          ]
                        : []
                }
            />
            <ConfirmationDialogBox
                title={t`Delete file`}
                text={t`This file will be permanently removed. Are you sure you want to proceed?`}
                isDialogOpen={isConfirmationDialogOpen.isOpen}
                onReject={() => setIsConfirmationDialogOpen({ isOpen: false })}
                onConfirm={isConfirmationDialogOpen.isOpen ? isConfirmationDialogOpen.onConfirm : () => {}}
                deleteText={t`Yes, delete`}
            />
        </>
    );
};

const AdditionalFiles = ({
    assemblyId,
    rfqId,
    content,
    isEditable,
}: {
    assemblyId: string;
    rfqId: string;
    content: { files: string[]; guidanceText: string };
    isEditable: boolean;
}) => {
    const { files, guidanceText } = content;
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState<
        { isOpen: false } | { isOpen: true; onConfirm: () => void }
    >({ isOpen: false });
    const { mutateAsync: mutateAsyncUpload, isPending: isUploading } = useHttpFileUpload(
        'GET /assemblies/:assemblyId/upload/other',
        (res) => res.data.url,
        {
            snackbarMessage: null,
            onSuccess: async (response, variables) => {
                const files = Array.isArray(variables.files) ? variables.files : [variables.files];
                await Promise.all(
                    files.map((file) =>
                        http(
                            'POST /assemblies/:id/history/file-uploaded',
                            {
                                pathParams: { id: assemblyId },
                                // eslint-disable-next-line camelcase
                                requestBody: { file_type: UploadedFileType.Other, file_name: file.name },
                            },
                            getToken(),
                        ),
                    ),
                );
            },
        },
    );
    const { mutateAsync: mutateAsyncDelete, isPending: isDeleting } = useDeleteAdditionalFile(assemblyId, rfqId);

    return (
        <>
            <Prompt
                when={isUploading || isDeleting}
                message={t`Files are still loading. Are you sure you want to leave?`}
            />
            <Dropzone
                title={t`Manufacturing files`}
                subHeader={guidanceText}
                onDropAccepted={(files) => mutateAsyncUpload({ pathParams: { assemblyId }, files })}
                multiple={true}
                disabled={!isEditable}
                isLoading={isUploading}
                accept={null}
                persistentFiles={files.map((file) => ({
                    name: getFileNameFromResourcesString(file),
                    onClick: () => window.open(file, '_blank'),
                    onDelete: () => {
                        setIsConfirmationDialogOpen({
                            isOpen: true,
                            onConfirm: () => mutateAsyncDelete(getFileNameFromResourcesString(file)),
                        });
                    },
                    disabled: isDeleting || !isEditable,
                }))}
            />
            <ConfirmationDialogBox
                title={t`Delete file`}
                text={t`This file will be permanently removed. Are you sure you want to proceed?`}
                isDialogOpen={isConfirmationDialogOpen.isOpen}
                onReject={() => setIsConfirmationDialogOpen({ isOpen: false })}
                onConfirm={isConfirmationDialogOpen.isOpen ? isConfirmationDialogOpen.onConfirm : () => {}}
                deleteText={t`Yes, delete`}
            />
        </>
    );
};
