import { t } from '@lingui/macro';
import { formatToIso8601Date, isPresent } from '@luminovo/commons';
import { InputAdornment } from '@mui/material';
import { Column } from '@tanstack/react-table';
import { colorSystem } from '../../../theme';
import { Flexbox } from '../../Flexbox';
import { SearchInput } from '../../SearchField';
import { fieldConverterDate } from '../../forms/FieldDate/FieldDate';
import { createDateValidator } from '../../forms/FieldDate/FieldDateControlled';
import { DateRange, FilterValue, getFilterConfig } from '../type';
import { createFilterValueUpdater, isFilterValue } from './createFilterValueUpdater';

function parseDateValue(value: string | undefined): string | null {
    if (!isPresent(value) || value === '' || (typeof value === 'string' && value.trim() === '')) {
        return null;
    }

    const parsedValue = formatToIso8601Date(value, { ifAbsent: '' });
    return Boolean(parsedValue) ? parsedValue : null;
}

export function FilterInDateRange<TData>({
    column,
    onClose,
}: {
    column: Column<TData, unknown>;
    onClose: () => void;
}): JSX.Element | null {
    const filterConfig = getFilterConfig(column, 'date');

    if (!isPresent(filterConfig)) {
        return null;
    }

    const handleMinChange = (value: string | undefined) => {
        const dateValue = parseDateValue(value);
        column.setFilterValue(
            createFilterValueUpdater((old: FilterValue<DateRange> | undefined) => {
                const max = old?.value[1] ?? null;
                if (!isPresent(dateValue) && !isPresent(max)) {
                    return undefined;
                }

                return [dateValue, max];
            }, filterConfig.defaultFilterFn),
        );
    };

    const handleMaxChange = (value: string | undefined) => {
        const dateValue = parseDateValue(value);
        column.setFilterValue(
            createFilterValueUpdater((old: FilterValue<DateRange> | undefined) => {
                const min = old?.value[0] ?? null;
                if (!isPresent(dateValue) && !isPresent(min)) {
                    return undefined;
                }
                return [min, dateValue];
            }, filterConfig.defaultFilterFn),
        );
    };
    const filterValue = column.getFilterValue();
    const valueMin =
        isFilterValue<DateRange>(filterValue) && typeof filterValue.value[0] === 'string'
            ? String(filterValue.value[0])
            : undefined;
    const valueMax =
        isFilterValue<DateRange>(filterValue) && typeof filterValue.value[1] === 'string'
            ? String(filterValue.value[1])
            : undefined;

    return (
        <Flexbox gap={4}>
            <SearchInput
                value={valueMin}
                onChange={handleMinChange}
                debounceWait={50}
                onKeyEnter={onClose}
                onClear={() => handleMinChange(undefined)}
                style={{ backgroundColor: colorSystem.neutral.white, width: '168px' }}
                formatter={{
                    converter: fieldConverterDate,
                    validation: createDateValidator({ required: false }),
                }}
                InputProps={{
                    type: 'date',
                    startAdornment: <InputAdornment position="start">{t`Min`}</InputAdornment>,
                }}
            />
            <SearchInput
                value={valueMax}
                onChange={handleMaxChange}
                debounceWait={50}
                onKeyEnter={onClose}
                onClear={() => handleMaxChange(undefined)}
                style={{ backgroundColor: colorSystem.neutral.white, width: '168px' }}
                formatter={{
                    converter: fieldConverterDate,
                    validation: createDateValidator({ required: false }),
                }}
                InputProps={{
                    type: 'date',
                    startAdornment: <InputAdornment position="start">{t`Max`}</InputAdornment>,
                }}
            />
        </Flexbox>
    );
}
