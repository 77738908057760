import { CircularProgress, IconButton, IconButtonProps, styled } from '@mui/material';
import React from 'react';
import { XOR } from '../DataTable/types';

export type BaseIconButtonProps = Omit<IconButtonProps, 'size'> &
    XOR<Pick<IconButtonProps, 'onClick'>, { href: string }> & {
        size?: 'small' | 'medium' | 'large';
        isLoading?: boolean;
    };

export const BaseIconButton = React.forwardRef<HTMLButtonElement, BaseIconButtonProps>(
    ({ size = 'large', isLoading = false, ...props }, ref) => {
        switch (size) {
            case 'small':
                return (
                    <SmallButton
                        {...props}
                        children={isLoading ? <CircularProgress size={'auto'} /> : props.children}
                        disabled={isLoading || props.disabled}
                        ref={ref}
                    />
                );
            case 'medium':
                return (
                    <MediumButton
                        {...props}
                        children={isLoading ? <CircularProgress size={'auto'} /> : props.children}
                        disabled={isLoading || props.disabled}
                        ref={ref}
                    />
                );
            case 'large':
                return (
                    <LargeButton
                        {...props}
                        children={isLoading ? <CircularProgress size={'auto'} /> : props.children}
                        disabled={isLoading || props.disabled}
                        ref={ref}
                    />
                );
        }
    },
);

const SmallButton = styled(IconButton)({
    width: '24px',
    height: '24px',
    fontSize: '16px',
    '& * > svg': {
        width: '16px',
        height: '16px',
    },
});

const MediumButton = styled(IconButton)({
    height: '32px',
    width: '32px',
    fontSize: '16px',
    '& * > svg': {
        height: '16px',
        width: '16px',
    },
});

const LargeButton = styled(IconButton)({
    fontSize: '18px',
    lineHeight: '20px',
    width: '40px',
    height: '40px',
    '& * > svg': {
        width: '20px',
        height: '20px',
    },
});
