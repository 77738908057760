import {
    MenuItem as MuiMenuItem,
    MenuItemProps as MuiMenuItemProps,
    SvgIcon as MuiSvgIcon,
    styled,
} from '@mui/material';
import React, { forwardRef } from 'react';
import { useNavigate } from '../../hooks/useNavigate';
import { bodySmall, colorSystem } from '../../theme';
export type MenuItemVariant = 'primary' | 'destructive';

export type MenuItemProps = MuiMenuItemProps & {
    label: string | JSX.Element;
    startIcon?: JSX.Element;
    variant?: MenuItemVariant;
    href?: string;
    overrides?: {
        InnerItem: React.ComponentType<{ label: string | JSX.Element; startIcon?: JSX.Element }>;
    };
};

const DefaultInnerItem = ({ label, startIcon }: { label: string | JSX.Element; startIcon?: JSX.Element }) => (
    <>
        {startIcon && <SvgIcon fill="currentColor">{startIcon}</SvgIcon>}
        <span style={{ ...bodySmall, width: '100%' }}>{label}</span>
    </>
);

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
    ({ label, startIcon, href, variant = 'primary', overrides = {}, ...props }, ref) => {
        const { InnerItem = DefaultInnerItem } = overrides;

        const navigate = useNavigate();
        const onClick = React.useCallback(() => {
            if (href) {
                navigate(href);
            }
        }, [href, navigate]);

        return (
            <DefaultMenuItem
                ref={ref}
                {...props}
                disableRipple={true}
                variant={variant}
                onClick={href ? onClick : props.onClick}
            >
                <InnerItem label={label} startIcon={startIcon} />
            </DefaultMenuItem>
        );
    },
);

const SvgIcon = styled(MuiSvgIcon)({
    height: '16px',
    width: '16px',
    marginRight: '8px',
    color: colorSystem.neutral[6],
});

const DefaultMenuItem = styled(MuiMenuItem)(({ variant }: { variant?: MenuItemVariant }) => ({
    padding: '8px',
    margin: '4px',
    '&:hover': {
        background: colorSystem.neutral[1],
        borderRadius: '4px',
        '& * > svg': {
            color: variant === 'primary' ? colorSystem.neutral[6] : colorSystem.red[7],
        },
        '& span': {
            color: variant === 'primary' ? colorSystem.neutral[9] : colorSystem.red[7],
        },
    },
}));
