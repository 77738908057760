import { MonetaryValue } from '@luminovo/commons';
import { QuantityUnitDTO } from '@luminovo/http-client';
import { monetaryValue } from './monetaryValueMath';

export function getQuoteRequestLineItemPrice({
    unitPrice,
    pricePer,
    requiredQuantity,
    bid,
    selected,
}: {
    unitPrice: MonetaryValue | undefined;
    pricePer: number | undefined;
    requiredQuantity: QuantityUnitDTO | undefined;
    bid: boolean;
    selected: boolean;
}) {
    if (!bid || !selected) {
        return monetaryValue.zero;
    }
    if (!unitPrice) {
        return monetaryValue.zero;
    }
    const pricePerOrDefault = pricePer || 1;
    const quantityOrDefault = requiredQuantity?.quantity || 1;

    return monetaryValue.scale(unitPrice, quantityOrDefault / pricePerOrDefault);
}
