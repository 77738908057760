import { t } from '@lingui/macro';
import { compareByStringKey, isPresent } from '@luminovo/commons';
import { TanStackTable, createColumnHelper, useNavigate, useTanStackTable } from '@luminovo/design-system';
import {
    ManufacturerDTO,
    ManufacturerLineCardDTO,
    ManufacturerWithPartCountDTO,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import { SupplierChip, formatSupplierDTO } from '@luminovo/sourcing-core';
import React from 'react';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useManufacturers } from '../../resources/manufacturer/manufacturerHandler';
import {
    useGlobalApprovedSupplierAndStockLocations,
    useGlobalPreferredSupplierAndStockLocations,
    useSupplierAndStockLocations,
} from '../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { route } from '../../utils/routes';
export type ManufacturerLineCardTableItem = {
    type: 'subRow';
    manufacturer: ManufacturerWithPartCountDTO;
    supplierAndStockLocation: SupplierAndStockLocationDTO;
    isPreferred: boolean;
    isApproved: boolean;
};

// eslint-disable-next-line max-params
export function convertToManufacturerLineCardTableItems(
    manufacturers: ManufacturerWithPartCountDTO[],
    manufacturerLineCard: ManufacturerLineCardDTO,
    supplierAndStockLocations: SupplierAndStockLocationDTO[],
    preferredSuppliersAndStockLocationsDTO: SupplierAndStockLocationDTO[],
    approvedSuppliersAndStockLocationsDTO: SupplierAndStockLocationDTO[],
): ManufacturerLineCardTableItem[] {
    return manufacturerLineCard.supported_by
        .flatMap((id) => {
            const manufacturer = manufacturers.find((m) => m.id === manufacturerLineCard.manufacturer_id);
            if (!isPresent(manufacturer)) {
                return [];
            }

            const supplierAndStockLocation = supplierAndStockLocations.find(
                (supplier_and_stock_location) => supplier_and_stock_location.supplier.id === id,
            );
            if (!isPresent(supplierAndStockLocation)) {
                return [];
            }

            const isPreferred = preferredSuppliersAndStockLocationsDTO.some((sasl) => sasl.supplier.id === id);
            const isApproved = approvedSuppliersAndStockLocationsDTO.some((sasl) => sasl.supplier.id === id);

            return {
                type: 'subRow' as const,
                manufacturer,
                supplierAndStockLocation,
                isPreferred,
                isApproved,
            };
        })
        .sort(compareByStringKey((s) => formatSupplierDTO(s.supplierAndStockLocation.supplier)));
}

const columnHelper = createColumnHelper<ManufacturerLineCardTableItem>();

const columns = [
    columnHelper.text((row) => formatSupplierDTO(row.supplierAndStockLocation.supplier), {
        id: 'supplierAndStockLocation',
        size: 180,
        label: () => t`Supplier`,
        cell: ({ row }) => (
            <SupplierChip
                isPreferred={row.original.isPreferred}
                isApproved={row.original.isApproved}
                supplier={row.original.supplierAndStockLocation.supplier}
            />
        ),
    }),
];

export const ManufacturerLineCardTab: React.FunctionComponent<{
    manufacturer: ManufacturerDTO;
}> = ({ manufacturer }) => {
    const navigate = useNavigate();
    const { data: manufacturers } = useManufacturers();
    const { data: suppliers } = useSupplierAndStockLocations();
    const { data: preferredSuppliersAndStockLocationsDTO } = useGlobalPreferredSupplierAndStockLocations();
    const { data: approvedSuppliersAndStockLocationsDTO } = useGlobalApprovedSupplierAndStockLocations();

    const { data: manufacturerLineCard } = useHttpQuery('GET /manufacturers/:id/line-card', {
        pathParams: { id: manufacturer.id },
    });

    const data = React.useMemo(() => {
        if (
            !manufacturers ||
            !suppliers ||
            !manufacturerLineCard ||
            !preferredSuppliersAndStockLocationsDTO ||
            !approvedSuppliersAndStockLocationsDTO
        ) {
            return undefined;
        }
        return convertToManufacturerLineCardTableItems(
            manufacturers,
            manufacturerLineCard,
            suppliers,
            preferredSuppliersAndStockLocationsDTO,
            approvedSuppliersAndStockLocationsDTO,
        );
    }, [
        manufacturers,
        suppliers,
        manufacturerLineCard,
        preferredSuppliersAndStockLocationsDTO,
        approvedSuppliersAndStockLocationsDTO,
    ]);

    const { table } = useTanStackTable({
        columns,
        data,
        enableExcelExport: true,
        onRowClick: (row) => {
            navigate(
                route(
                    '/supplier/:supplierAndStockLocationId',
                    {
                        supplierAndStockLocationId: row.original.supplierAndStockLocation.id,
                    },
                    { tab: 'lineCard' },
                ),
            );
        },
    });

    return <TanStackTable table={table} size={'small'} />;
};
