import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { PackageDTORuntype } from '../part';
import {
    BomFileDTORuntype,
    BomImporterBackendInputRuntype,
    BomProgressRuntype,
    TechnicalParamsRuntype,
} from './bomImporterBackendTypes';

export const bomImporterEndpoints = {
    'GET /bom-files/:id': endpoint({
        description: "returns a BOM file given it's ID",
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: BomFileDTORuntype }),
    }),

    'GET /bom-files/example': endpoint({
        description: 'returns a URL to download a BOM example - used in the guidance drawer for the BOM structure',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: r.Record({ url: r.String }) }),
    }),

    'GET /bom-files/examples': endpoint({
        description:
            'returns the example urls for Single level and Multi level BOMs - used in the guidance drawer for the BOM structure',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Record({
                single_level_bom: r.String,
                multi_level_bom: r.String,
            }),
        }),
    }),

    'GET /bom-importer/screener/tasks/:taskId': endpoint({
        description: 'returns the status of a BOM import task',
        pathParams: r.Record({ taskId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: BomProgressRuntype }),
    }),
    'GET /bom-importer/line-builder/tasks/:taskId': endpoint({
        description: 'returns the status of a BOM import task',
        pathParams: r.Record({ taskId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: BomProgressRuntype }),
    }),

    'POST /design-items/bom-importer': endpoint({
        description: 'posts BOM data to the backend',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: BomImporterBackendInputRuntype,
        responseBody: r.Record({ data: r.Record({ bom_file_id: r.String, design_items: r.Array(r.String) }) }),
        invalidates: [
            'POST /parts/off-the-shelf/bulk',
            'POST /design-items/bulk',
            'POST /parts/generic/bulk',
            'POST /ipns/bulk',
            'GET /assemblies/:assemblyId/descendants',
            'GET /assemblies/:assemblyId/descendants-summary',
        ],
        handleResponse: async (response) => {
            const responseData = await response.json();
            if (response.ok) {
                return responseData;
            }
            throw responseData;
        },
    }),

    'GET /bom-files/:bomFileId/upload': endpoint({
        description: 'returns a URL to upload a BOM file',
        pathParams: r.Record({ bomFileId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Record({
                url: r.String,
            }),
        }),
        invalidates: ['GET /bom-files/:id'],
    }),

    'POST /bom-importer/extract-possible-parameters': endpoint({
        description: 'extracts technical parameters from a BOM file',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            items: r.Array(r.String),
        }),
        responseBody: r.Record({
            data: r.Dictionary(r.Unknown, r.String),
        }),
    }),

    'POST /bom-importer/extract-technical-parameters': endpoint({
        description: 'extracts technical parameters from a BOM file',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            items: r.Array(r.String),
            packages: r.Array(PackageDTORuntype),
            require_generic_part_type_extracted: r.Boolean,
        }),
        responseBody: r.Record({
            data: r.Dictionary(TechnicalParamsRuntype.nullable(), r.String),
        }),
    }),
};
