import { isOtsFullPart, LifecycleEnum } from '@luminovo/http-client';
import { LifecycleChip } from '@luminovo/sourcing-core';
import { OtsPartLifecycleStatusOriginsTooltip } from '../../../../../../components/PartLifecycleView';
import { ApprovedPartOptionItem } from '../bomOverviewFrontendTypes';
import { EmptyValue } from '../columns/EmptyValue';
import { MultiplePartOriginsTooltipWithChip } from './MultiplePartOriginsTooltipWithChip';

export const BomItemLifecycleStatusOriginTooltipWithChip: React.FunctionComponent<{
    bomItemLifecycleStatus: LifecycleEnum | undefined;
    approvedPartOptions: ApprovedPartOptionItem[];
}> = ({ bomItemLifecycleStatus, approvedPartOptions }): JSX.Element => {
    if (!bomItemLifecycleStatus) {
        return <EmptyValue />;
    }

    const isSingleOtsPart = approvedPartOptions.length === 1 && isOtsFullPart(approvedPartOptions[0].fullPart);

    if (isSingleOtsPart) {
        const part = approvedPartOptions[0].fullPart;
        return (
            <OtsPartLifecycleStatusOriginsTooltip partId={part.id} lifecycleStatus={bomItemLifecycleStatus}>
                <LifecycleChip lifecycle={bomItemLifecycleStatus} showShortName />
            </OtsPartLifecycleStatusOriginsTooltip>
        );
    }

    return (
        <MultiplePartOriginsTooltipWithChip name="Lifecycle">
            <LifecycleChip lifecycle={bomItemLifecycleStatus} showShortName />
        </MultiplePartOriginsTooltipWithChip>
    );
};
