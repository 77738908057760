/* eslint-disable camelcase */
/* eslint-disable spellcheck/spell-checker */
import { isPresent, uniqBy } from '@luminovo/commons';
import {
    AddPartEvents,
    ApprovalStatus,
    CandidateCpn,
    ColumnName,
    FullPart,
    OriginSetMethod,
    PartAlternativeSimilarityEnum,
    PartOptionDTO,
    PartSpecificationTypes,
    PartSuggestionFull,
    PartSuggestionOrigin,
    PartSuggestionReasonEnum,
    StandardPartTypes,
    isGenericFullPart,
    isOtsComponentFull,
} from '@luminovo/http-client';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useAssembly } from '../../../resources/assembly/assemblyHandler';
import { BomItem } from '../../../resources/designItem/bomItemFrontendTypes';
import { getCandidateCpns } from '../../../resources/designItem/getCandidateCpns';
import { getCandidateMpns } from '../../../resources/designItem/getCandidateMpns';
import { convertToPartOption } from '../../../resources/part/convertToPartOption';
import { SectionOfScreen } from '../../../resources/part/partFrontendTypes';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { AddParts } from './AddParts/AddParts';
import { useSuggestions } from './AddParts/useSuggestions';
import { BomItemFormState } from './BomItemFormState';
import { PartOptions } from './PartOptions/PartOptionsTable';
import { MainContainer } from './SpecificationTypeForms/MainContainer';
import { SeparateContainer } from './SpecificationTypeForms/SeparateContainer';

function isIpnPartStateRemoved(part: FullPart): boolean {
    if (isOtsComponentFull(part)) {
        return part.state === 'Removed';
    }
    return false;
}

function isPreviousSuggestionOrigin(suggestionOrigin?: PartSuggestionOrigin): boolean {
    if (suggestionOrigin) {
        return suggestionOrigin.column === ColumnName.Previous;
    }
    return false;
}

function firstApprovalStatusFromSuggestionOriginOrDefault(suggestionOrigin?: PartSuggestionOrigin): ApprovalStatus {
    if (suggestionOrigin) {
        for (let reason of suggestionOrigin.reasons) {
            if (
                reason.name === PartSuggestionReasonEnum.PreviousImportManuallyAddedPartOption ||
                reason.name === PartSuggestionReasonEnum.PreviousImportPreviouslyAddedPartOption ||
                reason.name === PartSuggestionReasonEnum.PreviousImportAutomaticallyAddedPartOption
            ) {
                return reason.context;
            }
        }
    }
    return ApprovalStatus.Approved;
}

function getApprovalAndOriginSetMethod(
    part: FullPart,
    suggestionOrigin?: PartSuggestionOrigin,
): { approvalStatus: ApprovalStatus; setMethod: OriginSetMethod } {
    const approvalStatus = firstApprovalStatusFromSuggestionOriginOrDefault(suggestionOrigin);
    const isIPNStatusRemoved = isIpnPartStateRemoved(part);
    const isOfPreviousOrigin = isPreviousSuggestionOrigin(suggestionOrigin);
    return {
        approvalStatus: isIPNStatusRemoved ? ApprovalStatus.Rejected : approvalStatus,
        setMethod: isOfPreviousOrigin ? OriginSetMethod.Previous : OriginSetMethod.Manual,
    };
}

const useBomItemCandidateMpns = (bomItem: BomItem, suggestions: PartSuggestionFull[]) => {
    return React.useMemo(() => {
        return getCandidateMpns(bomItem, suggestions);
    }, [bomItem, suggestions]);
};

const useBOMItemCandidates = (bomItem: BomItem, suggestions: PartSuggestionFull[]) => {
    const candidateMpns = useBomItemCandidateMpns(bomItem, suggestions);
    return React.useMemo(() => {
        const candidateCpns: CandidateCpn[] = getCandidateCpns(bomItem, suggestions);
        return {
            candidateMpns,
            candidateCpns,
        };
    }, [bomItem, candidateMpns, suggestions]);
};

export const OtsPartSpecificationForm = React.memo(function OtsPartSpecificationForm({
    assemblyId,
    viewContext,
    bomItem,
    isEditable,
    submitForm,
}: {
    assemblyId: string;
    viewContext: ViewContext;
    bomItem: BomItem;
    isEditable: boolean;
    submitForm: () => void;
}) {
    const { control, setValue } = useFormContext<BomItemFormState>();
    const { field } = useController({
        name: 'otsPart.data.part_options',
        control,
        defaultValue: [],
    });

    const { data: assembly } = useAssembly(assemblyId);

    const partOptions: PartOptionDTO[] = field.value;

    const fullPartsWithApprovalStatus = bomItem.parts;

    const aggregationKey = bomItem.individualDesignItems[0]?.aggregation_key;
    const { suggestions, isLoading: isSuggestionsLoading } = useSuggestions(assembly?.id, aggregationKey);
    const candidates = useBOMItemCandidates(bomItem, suggestions);

    const countOfExistingGenericPartOptions = partOptions.filter(
        (option) => option.part.type === StandardPartTypes.Generic,
    ).length;

    const setPartOptions = React.useCallback(
        (fn: (existingPats: PartOptionDTO[]) => PartOptionDTO[]) => {
            setValue(
                'otsPart.data.part_options',
                uniqBy(fn(partOptions), (p) => p.part.data /* the part ID */),
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
            submitForm();
        },
        [partOptions, submitForm, setValue],
    );

    const handleAddMultiplePartOptions = React.useCallback(
        (
            newParts: FullPart[],
            sectionOfScreen: SectionOfScreen,
            alternativeSimilarity?: PartAlternativeSimilarityEnum,
            suggestionOrigin?: PartSuggestionOrigin,
        ) => {
            const newPartOptions = newParts
                .map((newPart) => {
                    const { approvalStatus, setMethod } = getApprovalAndOriginSetMethod(newPart, suggestionOrigin);
                    return convertToPartOption(newPart, { approvalStatus, setMethod });
                })
                .filter(isPresent);

            const hasNewGenericPart = newParts.some(
                (part) => isGenericFullPart(part) && countOfExistingGenericPartOptions === 0,
            );

            const newEventProperties: AddPartEvents = extractEventProperties({
                assemblyId,
                designItemIds: bomItem.id,
                sectionOfScreen,
                alternativeSimilarity,
            });

            if (hasNewGenericPart) {
                setValue('otsPart.data.is_manufacturer_free', true);
            }
            setValue('addPartEvents', [newEventProperties], {
                shouldDirty: true,
            });
            setPartOptions((parts) => {
                return [...parts, ...newPartOptions];
            });
        },
        [assemblyId, bomItem.id, countOfExistingGenericPartOptions, setPartOptions, setValue],
    );

    const handleAddPartOption = React.useCallback(
        (
            newPart: FullPart,
            sectionOfScreen: SectionOfScreen,
            alternativeSimilarity?: PartAlternativeSimilarityEnum,
            suggestionOrigin?: PartSuggestionOrigin,
        ) => {
            handleAddMultiplePartOptions([newPart], sectionOfScreen, alternativeSimilarity, suggestionOrigin);
        },
        [handleAddMultiplePartOptions],
    );

    const handleRemoveMultiplePartOptions = React.useCallback(
        (partIds: string[]) => {
            const partOptionsToDelete = partOptions.filter((part) => partIds.includes(part.part.data));
            if (partOptionsToDelete.length === 0) {
                return;
            }
            const countOfGenericPartsToDelete = partOptionsToDelete.filter(
                (part) => part.part.type === StandardPartTypes.Generic,
            ).length;

            if (countOfGenericPartsToDelete === countOfExistingGenericPartOptions) {
                setValue('otsPart.data.is_manufacturer_free', false);
            }

            setPartOptions((parts) => {
                return parts.filter((part) => !partIds.includes(part.part.data));
            });
        },
        [countOfExistingGenericPartOptions, partOptions, setPartOptions, setValue],
    );

    const handleRemovePartOption = React.useCallback(
        (partId: string) => {
            const partOption = partOptions.find((partOption) => partOption.part.data === partId);
            if (!partOption) {
                return;
            }
            const isTheLastGenericPartToBeRemovedFromOptions = countOfExistingGenericPartOptions === 1;
            if (partOption.part.type === StandardPartTypes.Generic && isTheLastGenericPartToBeRemovedFromOptions) {
                setValue('otsPart.data.is_manufacturer_free', false);
            }
            setPartOptions((parts) => {
                return parts.filter((p) => p.part.data !== partId);
            });
        },
        [countOfExistingGenericPartOptions, partOptions, setPartOptions, setValue],
    );

    const suggestionsRef = React.useRef<HTMLDivElement>(null);
    const isVisible = useAreSuggestionsVisible(suggestionsRef);

    const rfqId = viewContext.rfqId;

    if (!isEditable) {
        return (
            <PartOptions
                assemblyId={assemblyId}
                viewContext={viewContext}
                bomItem={bomItem}
                isEditable={isEditable}
                partOptions={partOptions}
                fullPartsWithApprovalStatus={fullPartsWithApprovalStatus}
                candidates={candidates}
                handleRemovePartOption={handleRemovePartOption}
                assemblyIndustry={assembly?.industry}
                setPartOptions={setPartOptions}
                handleRemoveMultiplePartOptions={handleRemoveMultiplePartOptions}
            />
        );
    }

    return (
        <>
            <MainContainer>
                <PartOptions
                    assemblyId={assemblyId}
                    viewContext={viewContext}
                    bomItem={bomItem}
                    isEditable={isEditable}
                    partOptions={partOptions}
                    fullPartsWithApprovalStatus={fullPartsWithApprovalStatus}
                    candidates={candidates}
                    handleRemovePartOption={handleRemovePartOption}
                    assemblyIndustry={assembly?.industry}
                    setPartOptions={setPartOptions}
                    handleRemoveMultiplePartOptions={handleRemoveMultiplePartOptions}
                />
            </MainContainer>
            <SeparateContainer ref={suggestionsRef}>
                {isVisible && rfqId && (
                    <AddParts
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                        bomItem={bomItem}
                        partOptions={partOptions}
                        isEditable={isEditable}
                        otsPartSpecificationContext={{
                            partSpecificationType: PartSpecificationTypes.OffTheShelf,
                            handleAddPart: handleAddPartOption,
                            handleAddMultiplePartOptions,
                        }}
                        handleRemovePartOption={handleRemovePartOption}
                        suggestions={suggestions}
                        isSuggestionsLoading={isSuggestionsLoading}
                        assemblyIndustry={assembly?.industry}
                    />
                )}
            </SeparateContainer>
        </>
    );
});

function useAreSuggestionsVisible(ref: React.RefObject<HTMLElement>) {
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
        // if the element is already visible, we don't need to toggle its visibility again when its no longer visible.
        if (isVisible) return;
        const observer = new IntersectionObserver(([entry]) => setIsVisible(entry.isIntersecting));
        if (ref.current) observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [isVisible, ref]);

    return isVisible;
}

function extractEventProperties({
    assemblyId,
    designItemIds,
    sectionOfScreen,
    alternativeSimilarity,
}: {
    assemblyId: string;
    designItemIds: string[];
    sectionOfScreen: SectionOfScreen;
    alternativeSimilarity?: PartAlternativeSimilarityEnum;
}): AddPartEvents {
    if (sectionOfScreen === 'alternativeParts' && alternativeSimilarity !== undefined) {
        return {
            assembly_id: assemblyId,
            design_item_ids: designItemIds,
            alternative_origin: alternativeSimilarity,
        };
    }
    return {
        assembly_id: assemblyId,
        design_item_ids: designItemIds,
        screen_section: sectionOfScreen,
    };
}
