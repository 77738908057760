import { isPresent } from '@luminovo/commons';
import {
    PCBFileTypes,
    PCBGraphicCopperJson,
    PCBGraphicDrillHole,
    PCBGraphicDrillHoleTool,
    PCBGraphicLayer,
    PCBGraphicPath,
    PCBGraphicPathDef,
    PCBGraphicPosition,
    PCBGraphics,
    PCBLayerTypes,
} from '@luminovo/http-client';
import React from 'react';
import { ColorPreference, DEFAULT_STYLE, PcbSide, getLayerColor } from '../../PCBColorSchemas';

const DRILL_COLOR_SCHEMA: Record<PCBGraphicDrillHoleTool['drillType'], { color: string; opacity: number }> = {
    PLATED: {
        color: '#aaa',
        opacity: 1,
    },
    NON_PLATED: {
        color: '#777',
        opacity: 1,
    },
};

const getDrillColor = (drillType: PCBGraphicDrillHoleTool['drillType']): { color: string; opacity: number } =>
    DRILL_COLOR_SCHEMA[drillType] ? DRILL_COLOR_SCHEMA[drillType] : DRILL_COLOR_SCHEMA.NON_PLATED;

const getPaths = (
    copperJson: PCBGraphicCopperJson,
    isInverted: boolean = false,
): Array<React.SVGProps<SVGPathElement>> => {
    const defPaths = new Map<string, string>();

    copperJson.defs?.forEach((def: PCBGraphicPathDef) => {
        if (def) {
            //Can improve. Check stackrate `createNormalLayer`
            defPaths.set(def.id, def.path);
        }
    });

    return copperJson.paths
        .flatMap((path: Array<PCBGraphicPath>) => path)
        .filter((path: PCBGraphicPath) => path.path || path.use)
        .map((path: PCBGraphicPath, index: number) => {
            if (path.path) {
                let transform = undefined;
                if (path.orientation && path.orientation.degrees > 0) {
                    transform = `rotate(${path.orientation.degrees} 0 0`;
                }
                return <path key={path.trace} d={path.path} fill={isInverted ? 'black' : ''} transform={transform} />;
            } else if (path.use) {
                const translate = `translate(${path.use.location.x} ${path.use.location.y})`;
                let transform = '';
                if (path.orientation && path.orientation.degrees > 0) {
                    transform = `${translate} rotate(${path.orientation.degrees} 0 0) `;
                } else {
                    transform = translate;
                }

                return (
                    <path
                        key={`${path.trace}-${path.use.reference}-${index}`}
                        d={defPaths.get(path.use.reference)}
                        fill={isInverted ? 'black' : ''}
                        transform={transform}
                    />
                );
            }

            return undefined;
        })
        .filter(isPresent);
};

export const createSvgComponents = (pcbSide: PcbSide, svgData: PCBGraphics, colorPreference: ColorPreference) => {
    const defs: Array<React.SVGProps<SVGGElement>> = [];

    let viewCutOffIndex =
        svgData.layers.findIndex(
            (l: PCBGraphicLayer) =>
                !l.file &&
                l.definition &&
                (l.definition.layerType === 'core' ||
                    l.definition.layerType === 'dielectric' ||
                    // eslint-disable-next-line spellcheck/spell-checker
                    l.definition.layerType === 'flexcore' ||
                    l.definition.layerType === 'prepreg'),
        ) + 1;

    viewCutOffIndex = pcbSide === 'front' ? viewCutOffIndex : svgData.layers.length - viewCutOffIndex;

    const selectedLayers: Array<PCBGraphicLayer> =
        pcbSide === 'front' ? svgData.layers.slice(0, viewCutOffIndex) : svgData.layers.slice(viewCutOffIndex);

    let svgItems: Array<React.SVGProps<SVGGElement>> = selectedLayers
        .map((layer: PCBGraphicLayer) => {
            if (layer.file !== undefined && layer.graphic?.copper_json?.paths !== undefined) {
                let paths: Array<React.SVGProps<SVGPathElement>> = [];
                const fileType: PCBFileTypes = layer.file.fileType.fileType;

                const layerColor = getLayerColor(fileType, colorPreference);

                if (fileType === PCBFileTypes.SOLDERMASK_BOTTOM || fileType === PCBFileTypes.SOLDERMASK_TOP) {
                    const maskId = `SVGMask-${fileType}`;

                    paths.push(
                        <path key={layer.file.id} d={svgData.outline.graphic.paths[0][0].path} fill={'white'} />,
                    );
                    paths = paths.concat(getPaths(layer.graphic.copper_json, true));

                    defs.push(
                        <mask key={layer.file.id} fill="black" id={maskId}>
                            {paths}
                        </mask>,
                    );

                    return (
                        <g
                            className={fileType}
                            key={layer.file.id}
                            fill={layerColor ? layerColor.color : DEFAULT_STYLE.color}
                            opacity={layerColor ? layerColor.opacity : DEFAULT_STYLE.opacity}
                            mask={`url("#${maskId}")`}
                        >
                            <path d={svgData.outline.graphic.paths[0][0].path} />
                        </g>
                    );
                }
                paths = getPaths(layer.graphic.copper_json);
                return (
                    <g
                        className={fileType}
                        key={layer.file.id}
                        fill={layerColor ? layerColor.color : DEFAULT_STYLE.color}
                        opacity={layerColor ? layerColor.opacity : DEFAULT_STYLE.opacity}
                    >
                        {paths}
                    </g>
                );
            } else if (layer.definition) {
                const layerType = layer.definition.layerType;
                const layerColor = getLayerColor(layerType, colorPreference);

                // dont draw soldermask here. if it wasn't drawn in the previous step, assume there isn't one
                if (layerType !== PCBLayerTypes.SOLDERMASK) {
                    return (
                        <g
                            className={layerType}
                            key={layer.definition.id}
                            fill={layerColor ? layerColor.color : DEFAULT_STYLE.color}
                            opacity={layerColor ? layerColor.opacity : DEFAULT_STYLE.opacity}
                        >
                            <path d={svgData.outline.graphic.paths[0][0].path} />
                        </g>
                    );
                } else {
                    return <g key={layer.definition.id} />;
                }
            }

            return undefined;
        })
        .filter(isPresent);

    const drills = svgData.drillHoles.flatMap((drillGroup: PCBGraphicDrillHole, index) => {
        return drillGroup.tools.map((tool: PCBGraphicDrillHoleTool) => {
            const radius = tool.diameter / 2;
            const listOfCircles = tool.drills.map((drill: PCBGraphicPosition) => {
                const x = drill.x;
                const y = drill.y;
                return <circle key={`${x}-${y}`} cx={x} cy={y} r={radius} />;
            });

            const className = `index:${index} ${tool.drillType} ${tool.name} ${tool.diameter.toFixed(3).toString()}`;
            const drillColor = getDrillColor(tool.drillType);

            return (
                <g key={className} className={className} fill={drillColor.color} opacity={drillColor.opacity}>
                    {listOfCircles}
                </g>
            );
        });
    });

    svgItems = pcbSide === 'front' ? svgItems.reverse().concat(drills) : svgItems.concat(drills);
    svgItems.push(<defs key={'def'}>{defs}</defs>);

    return svgItems;
};
