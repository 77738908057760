import { cases } from '../combinators/cases';
import { either } from '../combinators/either';
import { boolean } from '../parsers/boolean';
import { Parser } from '../parsers/Parser';

export const dnp = (): Parser<boolean> => {
    return either(
        boolean({ parseEmptyAsFalse: true }),
        cases({
            matches: [
                {
                    when: ['dnp', 'do not place', "don't place", 'do not assemble', 'not to be assembled'],
                    then: true,
                },
            ],
        }),
    );
};
