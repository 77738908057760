import { usePersistedState } from '@luminovo/design-system';
import { DemandDTO } from '@luminovo/http-client';
import * as r from 'runtypes';
import { useButtonGroups } from '../../../../Demand/DemandSummary';
import { useFilterDemandDialog } from '../../../../Demand/FilterDemandDialog';
import { DemandType } from '../../../../Demand/types';

const StoredDemandIdsHashmapSchema = r.Union(
    r.Record({
        type: r.Literal('all'),
    }),
    r.Record({
        type: r.Literal('selected'),
        selectedDemandIds: r.Array(r.String),
    }),
);

export type StoredDemandIdsHashmap = r.Static<typeof StoredDemandIdsHashmapSchema>;

const usePersistedDemandIdsHashmap = (
    firRenderDefaultValue: StoredDemandIdsHashmap,
): [StoredDemandIdsHashmap, (newState: StoredDemandIdsHashmap) => void] => {
    const localStorageKey = `demand-ids-ipn-v3`;
    const valueInLocalStorage = localStorage.getItem(localStorageKey);

    const [demandIdsState, setDemandIdsState] = usePersistedState<string>(
        localStorageKey,
        valueInLocalStorage ?? JSON.stringify(firRenderDefaultValue),
        localStorage,
    );
    const demandIds: StoredDemandIdsHashmap = StoredDemandIdsHashmapSchema.check(JSON.parse(demandIdsState));

    const setDemandIds = (newState: StoredDemandIdsHashmap) => {
        setDemandIdsState(JSON.stringify(newState));
    };
    return [demandIds, setDemandIds];
};

export const useDemandTabsInner = ({
    demands,
    initialDemandType,
}: {
    demands: DemandDTO[];
    initialDemandType: DemandType;
}) => {
    const buttonGroups = useButtonGroups({ initialDemandType });
    const allDemandIds = demands.map((demand) => demand.id);
    const [demandIdsHashmap, setDemandIdsHashmap] = usePersistedDemandIdsHashmap({ type: 'all' });
    const demandFilterState = demandIdsHashmap;

    if (!demandFilterState) {
        setDemandIdsHashmap({ type: 'all' });
    }
    const selectedDemandIds: string[] =
        demandFilterState.type === 'selected' ? demandFilterState.selectedDemandIds : allDemandIds;

    const setDialog = useFilterDemandDialog({
        submit: (demandIds: string[]) => {
            if (demandIds.length === allDemandIds.length) {
                setDemandIdsHashmap({ type: 'all' });
            } else {
                setDemandIdsHashmap({ type: 'selected', selectedDemandIds: demandIds });
            }
        },
        initialDemandIds: selectedDemandIds,
        demands,
        removeFilter: () => {
            setDemandIdsHashmap({ type: 'all' });
        },
    });

    return {
        buttonGroups,
        setDialog,
        selectedDemandIds,
    };
};
