import { http } from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useToken } from '../../../../resources/hooks';
import { invalidateAllQueriesForEndpoint, invalidateQuery } from '../../../../resources/http/useHttpQuery';
import { throwErrorUnlessProduction } from '../../../../utils/customConsole';

export const useDeleteManufacturingScenario = (manufacturingScenarioId: string, onSuccess?: () => void) => {
    const { manufacturingAssemblyDetailsId } = useParams<{
        rfqId: string;
        manufacturingAssemblyDetailsId: string;
    }>();
    const { token } = useToken();
    const queryClient = useQueryClient();

    const { mutateAsync: mutateAsyncDeleteManufacturingScenario } = useMutation({
        mutationFn: ({ shouldInvalidateTableCache }: { shouldInvalidateTableCache: boolean }) =>
            http(
                'DELETE /manufacturing-scenarios/:manufacturingScenarioId',
                {
                    pathParams: { manufacturingScenarioId },
                },
                token,
            ),
        onSuccess: async (_, values) => {
            if (values.shouldInvalidateTableCache) {
                //table cache should only be invalidate if the user is on the manufacturing assembly details page.
                await invalidateQuery(
                    'GET /manufacturing-scenarios',
                    /* eslint-disable-next-line camelcase */
                    { queryParams: { manufacturing_assembly_details_id: manufacturingAssemblyDetailsId } },
                    queryClient,
                );
                await invalidateQuery(
                    'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId/overview',
                    { pathParams: { manufacturingAssemblyDetailsId: manufacturingAssemblyDetailsId } },
                    queryClient,
                );
                // i didn't want to pass the assembly_id all the way down. hence invalidating all keys
                await invalidateAllQueriesForEndpoint('GET /calculations/scenario-combinations', queryClient);
            }

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error) => throwErrorUnlessProduction(error),
    });
    return {
        mutateAsyncDeleteManufacturingScenario,
    };
};
