import { useCurrentUserDetailsContext } from '@/components/contexts/CurrentUserDetailsContext';
import { t } from '@lingui/macro';
import { CollapsibleSidebar } from '@luminovo/design-system';
import { ForwardToInboxRounded, SpaceDashboardRounded } from '@mui/icons-material';
import { route } from '../../../utils/routes';

export function NavigationSidebarSourcing({ rfqId }: { rfqId: string }) {
    const { isClosedBetaTester } = useCurrentUserDetailsContext();
    return (
        <CollapsibleSidebar
            id="sourcing"
            title={t`Sourcing`}
            intialOpen={false}
            contents={[
                {
                    type: 'link',
                    href: route('/rfqs/:rfqId/sourcing', { rfqId }),
                    Icon: SpaceDashboardRounded,
                    label: t`Overview`,
                },

                {
                    type: 'link',
                    href: route('/rfqs/:rfqId/sourcing/negotiations', { rfqId }),
                    Icon: ForwardToInboxRounded,
                    label: t`Negotiations`,
                    hidden: !isClosedBetaTester,
                    isSelected: (pathname) =>
                        pathname.startsWith(route('/rfqs/:rfqId/sourcing/negotiations', { rfqId })),
                },
            ]}
        />
    );
}
