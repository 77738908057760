import * as r from 'runtypes';
import { MonetaryValueBackendRuntype } from '../backendTypes';
import { endpoint } from '../http/endpoint';
import { NegotiationsStandardPartOfferBulkInputDTORuntype } from '../offer';
import { PartDTORuntype } from '../part';
import {
    AwardScenarioDTORuntype,
    DemandDTORuntype,
    NegotiationDTORuntype,
    NegotiationLineItemRuntype,
    PartOptionRequestSummaryDTORuntype,
    QuoteRequestDTORuntype,
    QuoteRequestLineItemAggregatedStatusesRuntype,
    QuoteRequestLineItemDTORuntype,
    SupplierSelectionStrategyRuntype,
} from './negotiationBackendTypes';

export const negotiationEndpoints = {
    'GET /negotiation': endpoint({
        description: 'Retrieves all negotiations',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(NegotiationDTORuntype),
        }),
    }),

    'GET /negotiation/:id': endpoint({
        description: 'Retrieves a negotiation',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: NegotiationDTORuntype,
    }),

    'PATCH /negotiation/:id': endpoint({
        description: 'Updates a negotiation',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            name: r.String,
        }),
        responseBody: r.Record({
            id: r.Number,
            name: r.String,
        }),
        invalidates: ['GET /negotiation'],
    }),

    'GET /negotiation/:id/line-items': endpoint({
        description: 'Retrieves line items for a negotiation',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Array(NegotiationLineItemRuntype),
    }),

    'POST /award-scenario/find': endpoint({
        description: 'Retrieves all award scenarios for a negotiation',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            negotiation_id: r.Number,
        }),
        responseBody: r.Record({
            items: r.Array(AwardScenarioDTORuntype),
        }),
    }),

    'GET /demand': endpoint({
        description: 'Retrieves all demands',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Array(DemandDTORuntype),
    }),

    'GET /negotiation/:id/demands': endpoint({
        description: 'Retrieves demands associated with a negotiation',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Array(DemandDTORuntype),
    }),

    'GET /negotiation/:id/aggregated-statuses': endpoint({
        description: 'Retrieves aggregated statuses for quote request line items',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: QuoteRequestLineItemAggregatedStatusesRuntype,
    }),

    'POST /negotiation/line-items/part-options-with-quote-request-line-items': endpoint({
        description: 'Finds part options with quote request line items',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            negotiation_line_item_ids: r.Array(r.Number),
        }),
        responseBody: r.Array(PartOptionRequestSummaryDTORuntype),
    }),

    'GET /quote-request/:id/additional-files': endpoint({
        description: 'Gets additional files for a quote request',
        pathParams: r.Record({
            id: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Array(r.Record({ name: r.String, url: r.String })),
    }),

    'GET /quote-request/:id/additional-files/upload-link': endpoint({
        description: 'Gets an upload link for additional files for a quote request',
        pathParams: r.Record({
            id: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.String,
        invalidates: ['GET /quote-request/:id/additional-files'],
    }),

    'DELETE /quote-request/:id/offers': endpoint({
        description: 'Deletes offers offers for a quote request',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),

    'DELETE /quote-request/line-item/delete-offer': endpoint({
        description: 'Delete offers for a set of quote request line items',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            ids: r.Array(r.Number),
        }),
        responseBody: r.Unknown,
    }),

    'DELETE /quote-request/:id/additional-files': endpoint({
        description: 'Deletes additional files for a quote request',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            file_name: r.String,
        }),
        responseBody: r.Unknown,
        invalidates: ['GET /quote-request/:id/additional-files'],
    }),

    'GET /quote-request/:id': endpoint({
        description: 'Get a quote request',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: QuoteRequestDTORuntype,
    }),

    'POST /quote-request/find': endpoint({
        description: 'Finds quote requests',
        requestBody: r.Record({
            // either quote_request_ids or negotiation_id or negotiation_line_item_ids must be provided
            // if neither is provided, throws an error
            quote_request_ids: r.Array(r.String).optional(),
            negotiation_id: r.Number.optional(),
            negotiation_line_item_ids: r.Array(r.Number).optional(),
            quote_request_numbers: r.Array(r.Number).optional(),
        }),
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        responseBody: r.Record({
            items: r.Array(QuoteRequestDTORuntype),
        }),
    }),

    'GET /quote-request/:id/line-items': endpoint({
        description: 'Finds quote request line items for a quote request',
        pathParams: r.Record({
            id: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(QuoteRequestLineItemDTORuntype),
        }),
    }),
    'POST /quote-request/line-items/find': endpoint({
        description: 'Finds quote request line items by negotiation line item ids',
        requestBody: r.Record({
            negotiation_line_item_ids: r.Array(r.Number),
        }),
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        responseBody: r.Record({
            items: r.Array(QuoteRequestLineItemDTORuntype),
        }),
    }),

    'POST /quote-request/draft': endpoint({
        description: 'Creates a quote request draft',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            supplier_ids: r.Array(r.String),
            items: r.Array(
                r.Record({
                    // negotiation part options
                    part_option_id: r.Number,
                    part: PartDTORuntype,
                }),
            ),
            supplier_selection_strategy: SupplierSelectionStrategyRuntype,
        }),
        responseBody: r.Record({
            quote_requests: r.Array(
                r.Record({
                    id: r.String,
                    quote_request_line_items: r.Number,
                }),
            ),
        }),
        invalidates: [
            'POST /quote-request/line-items/find',
            'GET /quote-request/:id/line-items',
            'POST /quote-request/find',
            'GET /quote-request/:id',
            'GET /negotiation/:id/aggregated-statuses',
            'POST /negotiation/line-items/part-options-with-quote-request-line-items',
        ],
    }),

    // TODO(negotiations): move to quote-request-line-item endpoints
    'GET /quote-request/:id/download-excel': endpoint({
        description: 'Downloads a quote request as an Excel file',
        pathParams: r.Record({
            id: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),

    // TODO(negotiations): move to quote-request-line-item endpoints
    'POST /quote-request/:id/add-offers/off-the-shelf': endpoint({
        description: 'Adds off-the-shelf offers to a quote request',
        pathParams: r.Record({
            id: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            supplier_and_stock_location: r.String,
            inputs: r.Array(NegotiationsStandardPartOfferBulkInputDTORuntype),
            event_metadata: r.Record({
                type: r.Literal('Excel'),
            }),
        }),
        responseBody: r.Unknown,
        invalidates: [
            'POST /quote-request/line-items/find',
            'POST /quote-request/find',
            'GET /quote-request/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    'POST /quote-request/:id/add-offers/custom': endpoint({
        description: 'Adds custom offers to a quote request',
        pathParams: r.Record({
            id: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            supplier_and_stock_location: r.String,
            inputs: r.Array(NegotiationsStandardPartOfferBulkInputDTORuntype),
            event_metadata: r.Record({
                type: r.Literal('Excel'),
            }),
        }),
        responseBody: r.Unknown,
        invalidates: [
            'POST /quote-request/line-items/find',
            'POST /quote-request/find',
            'GET /quote-request/:id',
            'GET /quote-request/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    // TODO(negotiations): move to award-scenario endpoints
    'POST /award-scenario': endpoint({
        description: 'Creates an award scenario',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        responseBody: r.Record({
            award_scenario_id: r.Number.optional(),
        }),
        requestBody: r.Record({
            name: r.String,
            negotiation_id: r.Number,
            clone_from: r.Number.optional(),
        }),
        invalidates: [
            'POST /award-scenario/find',
            'GET /quote-request/:id/line-items',
            'POST /award-scenario/refresh-automatic',
            'POST /quote-request/line-items/find',
            'GET /negotiation/:id/line-items',
        ],
    }),

    'POST /award-scenario/:id/award-from-quote-requests': endpoint({
        description: 'Upserts an award scenario with best offers from a set of quote requests',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            items: r.Array(
                r.Record({
                    negotiation_line_item_id: r.Number,
                    quote_request_ids: r.Array(r.String),
                }),
            ),
        }),
        responseBody: r.Record({
            award_scenario_id: r.Number.nullable(),
        }),
        invalidates: [
            'POST /award-scenario/find',
            'GET /quote-request/:id/line-items',
            'POST /award-scenario/refresh-automatic',
            'POST /quote-request/line-items/find',
            'GET /negotiation/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    'POST /award-scenario/:id/copy-awarded-offers': endpoint({
        description: 'Upserts an award scenario with an array of awarded offers',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            items: r.Array(
                r.Record({
                    negotiation_line_item_id: r.Number,
                    awarded_offer_id: r.Number,
                }),
            ),
        }),
        responseBody: r.Record({
            award_scenario_id: r.Number.nullable(),
        }),
        invalidates: [
            'POST /award-scenario/find',
            'GET /quote-request/:id/line-items',
            'POST /award-scenario/refresh-automatic',
            'POST /quote-request/line-items/find',
            'GET /negotiation/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    // TODO(negotiations): Remove this endpoint
    'POST /award-scenario/:id/awarded-offer/upsert-from-supplier': endpoint({
        description: 'Upserts an awarded offer from a supplier',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            supplier_ids: r.Array(r.String),
            negotiation_line_item_ids: r.Array(r.Number),
            name: r.String,
        }),
        responseBody: r.Record({
            award_scenario_id: r.Number.optional(),
        }),
        invalidates: [
            'POST /award-scenario/find',
            'GET /quote-request/:id/line-items',
            'POST /award-scenario/refresh-automatic',
            'POST /quote-request/line-items/find',
            'GET /negotiation/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    // TODO(negotiations): move to quote-request endpoints
    'POST /award-scenario/:id/awarded-offer/upsert': endpoint({
        description: 'Upserts an awarded offer on a negotiation line item',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            name: r.String,
            negotiation_line_item_id: r.Number,
            offer_id: r.Record({
                kind: r.Union(r.Literal('OffTheShelf'), r.Literal('Custom')),
                id: r.String,
            }),
        }),
        responseBody: r.Record({
            award_scenario_id: r.Number,
        }),
        invalidates: [
            'POST /award-scenario/find',
            'GET /quote-request/:id/line-items',
            'POST /award-scenario/refresh-automatic',
            'POST /quote-request/line-items/find',
            'GET /negotiation/:id/line-items',
        ],
    }),

    // TODO(negotiations): move to quote-request endpoints
    'POST /quote-request/send-many': endpoint({
        description: 'Sends multiple quote requests',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            include_pcb_specification: r.Boolean,
            include_shipping_panel_specification: r.Boolean,
            include_mail_attachments: r.Boolean,
            mail_data: r.Array(
                r.Record({
                    quote_request_id: r.String,
                    recipient_emails: r.Array(r.Record({ name: r.String.optional(), email: r.String })),
                    cc_emails: r.Array(r.Record({ name: r.String.nullable(), email: r.String })),
                    email: r.Record({ subject: r.String, body: r.String }),
                }),
            ),
        }),
        responseBody: r.Unknown,
        invalidates: [
            'POST /quote-request/line-items/find',
            'POST /quote-request/find',
            'GET /quote-request/:id',
            'GET /quote-request/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    // TODO(negotiations): move to quote-request-line-item endpoints
    'PATCH /quote-request/:id': endpoint({
        description: 'Updates a quote request',
        pathParams: r.Record({
            id: r.String,
        }),
        responseBody: r.Unknown,
        queryParams: r.Undefined,
        requestBody: r.Record({
            due_date: r.String.nullable().optional(),
            discarded: r.Boolean.optional(),
            show_customer: r.Boolean.optional(),
            show_target_price: r.Boolean.optional(),
            notes: r.String.nullable().optional(),
        }),
        invalidates: [
            'POST /quote-request/line-items/find',
            'POST /quote-request/find',
            'GET /quote-request/:id',
            'GET /quote-request/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    // TODO(negotiations): move to quote-request endpoints
    'POST /quote-request/line-item/update-many': endpoint({
        description: 'Updates multiple quote request line items',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            updates: r.Array(
                r.Record({
                    id: r.Number,
                    target_price: MonetaryValueBackendRuntype,
                }),
            ),
        }),
        responseBody: r.Unknown,
        invalidates: [
            'POST /quote-request/line-items/find',
            'GET /quote-request/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    'POST /negotiation': endpoint({
        description: 'Creates a negotiation',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            name: r.String,
            demand_groups: r.Array(r.Array(r.String)),
            rfq_id: r.String.optional(),
            filter_by_categories: r.Array(
                r.Union(
                    r.Record({ type: r.Literal('OtsCategory'), id: r.Number }),
                    r.Record({ type: r.Literal('CustomPartType'), data: r.String }),
                ),
            ),
            filter_manufacturers: r.Array(r.String),
            filter_not_quoted_since: r.String.nullable(),
            group_by_customer: r.Boolean,
            group_by_site_groups: r.Array(r.Array(r.String)),
            group_by_supplier_groups: r.Array(r.Array(r.String)),
            group_by_sourcing_scenario: r.Boolean,
        }),
        responseBody: r.Record({
            id: r.Number,
        }),
        invalidates: ['GET /negotiation', 'GET /demand'],
    }),

    'DELETE /negotiation/:id': endpoint({
        description: 'Deletes a negotiation',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),

    // TODO(negotiations): move to award-scenario endpoints
    'POST /award-scenario/refresh-automatic': endpoint({
        description: 'Refreshes automatic award scenarios',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            negotiation_id: r.Number,
        }),
        responseBody: r.Unknown,
        invalidates: [
            'GET /negotiation/:id/line-items',
            'POST /award-scenario/find',
            'GET /quote-request/:id/line-items',
            'POST /quote-request/line-items/find',
        ],
    }),

    'DELETE /award-scenario/:id': endpoint({
        description: 'Deletes an award scenario',
        pathParams: r.Record({
            id: r.Number,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),

    'DELETE /quote-request/:id': endpoint({
        description: 'Deletes a quote request',
        pathParams: r.Record({
            id: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),

    'DELETE /quote-request/delete-many': endpoint({
        description: 'Deletes mulitple quote requests',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            quote_request_ids: r.Array(r.String),
        }),
        responseBody: r.Unknown,
    }),

    'POST /demand/delete-bulk': endpoint({
        description: 'Deletes multiple demands',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            ids: r.Array(r.String),
        }),
        responseBody: r.Unknown,
        invalidates: ['GET /demand'],
    }),

    'POST /quote-request/line-item/delete': endpoint({
        description: 'Deletes a quote request line item',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            ids: r.Array(r.Number),
        }),
        responseBody: r.Unknown,
        invalidates: [
            'POST /negotiation/line-items/part-options-with-quote-request-line-items',
            'GET /quote-request/:id/line-items',
        ],
    }),
} satisfies Record<string, Endpoint>;

type Endpoint = ReturnType<typeof endpoint>;
