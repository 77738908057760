/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type MasterElectronicsPriceTypeDTO = r.Static<typeof MasterElectronicsPriceTypeDTORuntype>;
export const MasterElectronicsPriceTypeDTORuntype = r.Union(r.Literal('ListPrice'), r.Literal('ContractPrice'));

export interface MasterElectronicsCredentialsDTO extends r.Static<typeof MasterElectronicsCredentialsDTORuntype> {}
export const MasterElectronicsCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
        price_type: MasterElectronicsPriceTypeDTORuntype,
    }),
);

export interface MasterElectronicsCredentialsInputDTO
    extends r.Static<typeof MasterElectronicsCredentialsInputDTORuntype> {}
export const MasterElectronicsCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
    price_type: MasterElectronicsPriceTypeDTORuntype.optional(),
});

export interface MasterElectronicsResponseBodyDTO extends r.Static<typeof MasterElectronicsResponseBodyDTORuntype> {}
export const MasterElectronicsResponseBodyDTORuntype = r.Record({
    data: MasterElectronicsCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
