import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout, useNavigate } from '@luminovo/design-system';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useSourcingScenario } from '../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { UrlParams } from '../../../utils/routes';
import { SourcingScenarioForm } from './SourcingScenarioForm';
import {
    SourcingScenarioFormValues,
    convertExtractedValuesToRequestBody,
    convertToSourcingScenarioFormValue,
} from './converters';

export function EditSourcingScenarioForm({
    pathParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit'>): JSX.Element {
    const { sourcingScenarioId } = pathParams;
    const navigate = useNavigate();
    const { data: sourcingScenario } = useSourcingScenario(sourcingScenarioId);

    const { mutateAsync } = useHttpMutation('PATCH /sourcing-scenarios/:sourcingScenarioId', {
        snackbarMessage: t`Sourcing scenario updated`,
        onSuccess: () => navigate(-1),
    });

    const submitHanlder = async (formValues: SourcingScenarioFormValues) => {
        await mutateAsync({
            pathParams: { sourcingScenarioId },
            requestBody: convertExtractedValuesToRequestBody(formValues),
        });
    };

    if (!isPresent(sourcingScenario)) {
        return (
            <CenteredLayout>
                <SpinnerWithBackdrop noBackdrop={true} />
            </CenteredLayout>
        );
    }

    const defaultValues = convertToSourcingScenarioFormValue(sourcingScenario);

    return <SourcingScenarioForm onSubmit={submitHanlder} defaultValues={defaultValues} />;
}
