import { Badge, colorSystem, Flexbox, SecondaryIconButton, StickyLayout, Text } from '@luminovo/design-system';
import { KeyboardTabRounded } from '@mui/icons-material';
import { BoxProps, Collapse } from '@mui/material';
import React from 'react';
import { PageLayout } from '../../../components/PageLayout';

type BomImporterScreeningLayoutProps = {
    sidebar?: JSX.Element;
    sidebarTitle?: string;
    navbar?: JSX.Element;
    badgeCount?: number;
} & BoxProps;

export function BomImporterScreeningLayout({
    children,
    sidebar,
    navbar,
    sidebarTitle,
    badgeCount,
}: BomImporterScreeningLayoutProps) {
    const [isOpen, setOpen] = React.useState(true);

    const sidebarCollapsedWidth = '308px';

    return (
        <PageLayout
            layout={'fragment'}
            navbar={navbar}
            flexDirection={'row'}
            removeHubspotPaddingFix
            bgcolor={colorSystem.neutral[1]}
        >
            {sidebar && (
                <StickyLayout
                    style={{
                        borderRight: `1px solid ${colorSystem.neutral[2]}`,
                        backgroundColor: 'white',
                        zIndex: 3,
                        height: '100%',
                        padding: '16px',
                        transition: 'all 0.2s ease-in',
                        boxSizing: 'border-box',
                    }}
                >
                    <Flexbox flexDirection="column" gap="16px">
                        <Flexbox justifyContent="space-between" alignItems="center">
                            {sidebarTitle && (
                                <Collapse in={isOpen} orientation="horizontal">
                                    <Text variant="h4">{sidebarTitle}</Text>
                                </Collapse>
                            )}
                            <Badge badgeContent={badgeCount} color="red" style={{ alignSelf: 'flex-start' }}>
                                <SecondaryIconButton
                                    size="small"
                                    onClick={() => setOpen((isOpen) => !isOpen)}
                                    disableRipple
                                >
                                    <KeyboardTabRounded
                                        style={{ transform: isOpen ? 'rotate(180deg)' : undefined }}
                                        fontSize="inherit"
                                    />
                                </SecondaryIconButton>
                            </Badge>
                        </Flexbox>
                        <Collapse in={isOpen} orientation="horizontal">
                            <Flexbox flexDirection="column" width={sidebarCollapsedWidth} gap="16px">
                                {sidebar}
                            </Flexbox>
                        </Collapse>
                    </Flexbox>
                </StickyLayout>
            )}

            {children}
        </PageLayout>
    );
}
