import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, Message, Text, useNavigate } from '@luminovo/design-system';
import LaunchIcon from '@mui/icons-material/Launch';

export const ErrorInFormulaBox = ({ route }: { route: string }): JSX.Element => {
    const navigate = useNavigate();

    return (
        <Message
            attention="high"
            variant="red"
            overrides={{
                ActionButton: () => (
                    <Flexbox
                        alignItems={'center'}
                        gap={'4px'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(route)}
                    >
                        <LaunchIcon style={{ color: colorSystem.neutral[7], fontSize: '12px' }} />
                        <Text variant="h5" style={{ color: colorSystem.neutral[7] }}>
                            <Trans>Resolve</Trans>
                        </Text>
                    </Flexbox>
                ),
            }}
            size="small"
            title={t`Error in formula`}
        />
    );
};
