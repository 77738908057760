import { t } from '@lingui/macro';
import { MenuButton, MenuItem, useNavigate } from '@luminovo/design-system';
import { DeleteRounded, ModeEditOutlineRounded, MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import React from 'react';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';
import { useIsCalculationModuleActive } from '../../../../featureFlags';
import { usePageParams } from '../../../../resources/hooks';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { route } from '../../../../utils/routes';
import { AssembliesUsedInCalculationForTheSourcingScenario } from './AssembliesUsedInCalculationForTheSourcingScenario';

const SourcingScenarioActionMenu: React.FunctionComponent<{
    sourcingScenarioId: string;
    minimal?: boolean;
}> = ({ sourcingScenarioId, minimal }): JSX.Element => {
    const navigate = useNavigate();
    const { rfqId } = usePageParams<'/rfqs/:rfqId'>();
    const { dialog, setDialogOpen } = useConfirmDeletionDialog({ rfqId, sourcingScenarioId });

    const handleEdit = React.useCallback(() => {
        navigate(
            route(`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit`, {
                rfqId,
                sourcingScenarioId,
            }),
        );
    }, [navigate, rfqId, sourcingScenarioId]);

    const handleDelete = React.useCallback(() => {
        setDialogOpen(true);
    }, [setDialogOpen]);

    return (
        <>
            <MenuButton
                size="medium"
                label={minimal ? '' : t`Sourcing scenario`}
                appearance={minimal ? 'tertiary' : 'secondary'}
                style={{ minWidth: 0, padding: minimal ? 0 : undefined }}
                icon={<MoreHorizIcon />}
            >
                <MenuItem onClick={handleEdit} label={t`Edit`} startIcon={<ModeEditOutlineRounded />} />
                <MenuItem
                    onClick={handleDelete}
                    label={t`Delete`}
                    variant={'destructive'}
                    startIcon={<DeleteRounded />}
                />
            </MenuButton>
            {dialog}
        </>
    );
};

function useConfirmDeletionDialog({ rfqId, sourcingScenarioId }: { rfqId: string; sourcingScenarioId: string }) {
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    const { mutateAsync } = useHttpMutation('DELETE /sourcing-scenarios/:sourcingScenarioId', {
        enableInvalidateAllQueries: false,
        snackbarMessage: t`Sourcing scenario deleted`,
        onSuccess: () => navigate(route('/rfqs/:rfqId/sourcing', { rfqId })),
    });
    const { isCalculationModuleActive } = useIsCalculationModuleActive();

    const onConfirm = async () => {
        await mutateAsync({ pathParams: { sourcingScenarioId } });
    };

    const handleReject = React.useCallback(() => {
        setDialogOpen(false);
    }, []);

    return {
        setDialogOpen,
        dialog: (
            <ConfirmationDialogBox
                onConfirm={onConfirm}
                onReject={handleReject}
                text=""
                title={t`Are you sure you would like to delete this sourcing scenario?`}
                isDialogOpen={isDialogOpen}
            >
                {isCalculationModuleActive && (
                    <AssembliesUsedInCalculationForTheSourcingScenario sourcingScenarioId={sourcingScenarioId} />
                )}
            </ConfirmationDialogBox>
        ),
    };
}

export default SourcingScenarioActionMenu;
