import { t } from '@lingui/macro';
import { LinkStatus } from '../types';

export function formatLinkStatus(status: LinkStatus | null | undefined): string {
    switch (status) {
        case 'insert':
            return t`New`;
        case 'update':
            return t`Update`;
        case 'skipped':
        case null:
        case undefined:
            return t`Excluded`;
    }
}
