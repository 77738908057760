/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import {
    Chip,
    colorSystem,
    Flexbox,
    Message,
    SecondaryIconButton,
    StatusChip,
    Text,
    useNavigate,
} from '@luminovo/design-system';
import { AssemblyMonitoring } from '@luminovo/http-client';
import { Close, PersonRounded } from '@mui/icons-material';
import { route } from '../../../../utils/routes';
import { ViewContext } from '../ModuleTableData';
import { RenderInterests, Title } from './AssemblyMonitoringUtils';
import useMonitoringDrawer from './useMonitoringDrawer';
export const AssemblyMonitoringViewOnly = ({
    onClose,
    viewContext,
    assemblyMonitoring,
}: {
    onClose: () => void;
    viewContext: ViewContext;
    assemblyMonitoring: AssemblyMonitoring;
}) => {
    const { is_active: isActive } = assemblyMonitoring;
    const navigate = useNavigate();
    const { openDrawer } = useMonitoringDrawer();

    const onClick = () => {
        const topAssemblyRoute =
            viewContext.type === 'AssemblyOverview'
                ? route(
                      '/assemblies/:assemblyId/dashboard',
                      { assemblyId: assemblyMonitoring.top_level_assembly },
                      { isMonitoringOpen: 'true', rfqId: viewContext.rfqId, tab: undefined },
                  )
                : route('/rfqs/:rfqId/bom/assembly/:assemblyId', {
                      rfqId: viewContext.rfqId,
                      assemblyId: assemblyMonitoring.top_level_assembly,
                  });
        navigate(topAssemblyRoute);

        openDrawer({ assemblyMonitoring, assemblyId: assemblyMonitoring.top_level_assembly });
    };
    return (
        <>
            <Flexbox
                flexDirection="column"
                padding="32px"
                gap={8}
                style={{ background: colorSystem.neutral[0], borderBottom: `1px solid ${colorSystem.neutral[2]}` }}
            >
                <Flexbox justifyContent="space-between" marginBottom="16px">
                    <SecondaryIconButton size="medium" onClick={() => onClose()}>
                        <Close fontSize="inherit" />
                    </SecondaryIconButton>
                    <StatusChip color={isActive ? 'green' : 'neutral'} label={isActive ? t`Active` : t`Inactive`} />
                </Flexbox>
                <Flexbox gap={8}>
                    <Text variant="h3">
                        <Trans>Assembly monitoring</Trans>
                    </Text>
                </Flexbox>
                <Text>
                    <Trans>Set up automatic in-app and email alerts for this assembly and all its subassemblies.</Trans>
                </Text>
            </Flexbox>
            <Flexbox flexDirection="column" padding="32px" gap="24px">
                <Message
                    attention="low"
                    size="large"
                    variant="blue"
                    title={t`Edit monitoring`}
                    message={t`Assembly monitoring settings can only be edited in the top level assembly.`}
                    action={{
                        label: t`Go to top level assembly`,
                        onClick,
                    }}
                />
                <Flexbox flexDirection="column" gap="12px">
                    <Title label={t`Notify me of changes in`} />
                    <RenderInterests interests={assemblyMonitoring.interests} />
                </Flexbox>
                <Flexbox flexDirection="column" gap="12px">
                    <Title label={t`Who should be notified?`} />
                    <Flexbox gap={8}>
                        {assemblyMonitoring.users.map((user) => {
                            return (
                                <Chip
                                    key={user.id}
                                    color="neutral"
                                    label={`${user.first_name} ${user.last_name}`}
                                    startIcon={<PersonRounded fontSize="inherit" />}
                                />
                            );
                        })}
                        {assemblyMonitoring.users.length === 0 && (
                            <Text>
                                <Trans>No users selected</Trans>
                            </Text>
                        )}
                    </Flexbox>
                </Flexbox>
                <Flexbox flexDirection="column" gap="12px">
                    <Title label={t`Frequency`} />
                    <span>
                        <Chip color="neutral" label={t`${assemblyMonitoring.frequency}`} />
                    </span>
                </Flexbox>
            </Flexbox>
        </>
    );
};
