import { compareByStringKey } from '@luminovo/commons';
import { useSuspenseHttpQuery } from '../http/useHttpQuery';

export function useManufacturers({ highQuality }: { highQuality: boolean } = { highQuality: false }) {
    return useSuspenseHttpQuery(
        'GET /manufacturers',
        { queryParams: { high_quality: highQuality } },
        {
            select: (res) => Array.from(res.data).sort(compareByStringKey((x) => x.name)),
            staleTime: Infinity,
        },
    );
}
