import { Trans, t } from '@lingui/macro';
import { assertUnreachable, isPresent, isProductionEnvironment } from '@luminovo/commons';
import {
    CenteredLayout,
    DestructivePrimaryButton,
    DestructiveSecondaryButton,
    Flexbox,
    Message,
    NonIdealState,
    PrimaryButton,
    RequestViaMailIcon,
    SecondaryButton,
    SecondaryIconButton,
    StatusChip,
    Text,
    Toolbar,
    colorSystem,
    useNavigate,
} from '@luminovo/design-system';
import { QuoteTrackingDTO, QuoteTrackingState } from '@luminovo/http-client';
import { formatSupplierAndStockLocationDTO } from '@luminovo/sourcing-core';
import { ArrowBack, Delete, Publish } from '@mui/icons-material';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import React from 'react';
import { ViewCommentsButton, useCommunicationsDrawer } from '../../../../components/CommentsDrawer';
import { PageLayout } from '../../../../components/PageLayout';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useQuoteTracking } from '../../../../resources/quoteTracking/quoteTrackingHandler';
import {
    useSourcingFullBulk,
    useSourcingScenariosOfRfq,
} from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { UrlParams, route } from '../../../../utils/routes';
import { PdfQuoteImportButton } from '../PdfOfferExtractorDialog';
import { useRequestConfigurationDialog } from '../QuoteExporter/RequestConfigurationDialog';
import { QuoteTrackingList } from './QuoteTrackingList';
import { QuoteTrackingMetricSettings, QuoteTrackingTotalMetricCard } from './QuoteTrackingMetricCards';
import { QuoteTrackingSummary } from './QuoteTrackingSummary';
import { QuoteTrackingTabsController } from './tabs/QuoteTrackingTabsController';
const BackButton: React.FunctionComponent<{ rfqId: string }> = ({ rfqId }) => {
    const navigate = useNavigate();
    return (
        <SecondaryIconButton size="medium" onClick={() => navigate(route(`/rfqs/:rfqId/sourcing`, { rfqId }))}>
            <ArrowBack fontSize="inherit" />
        </SecondaryIconButton>
    );
};

const ToolbarQuiteTracking: React.FunctionComponent<{
    rfqId: string;
}> = ({ rfqId }) => {
    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: t`Sourcing`,
                    href: route(`/rfqs/:rfqId/sourcing`, { rfqId }),
                },
                { title: t`Quoting` },
            ]}
        >
            <RequestQuoteButton rfqId={rfqId} />
            <ImportQuoteButton rfqId={rfqId} />
        </Toolbar>
    );
};

const RequestQuoteButton: React.FunctionComponent<{ rfqId: string }> = ({ rfqId }) => {
    const { openDialog } = useRequestConfigurationDialog({ rfqId });
    return (
        <SecondaryButton size="medium" onClick={() => openDialog()} startIcon={<RequestViaMailIcon />}>
            <Trans>Request quote</Trans>
        </SecondaryButton>
    );
};

const ImportQuoteButton: React.FunctionComponent<{ rfqId: string }> = ({ rfqId }) => {
    return (
        <PrimaryButton
            size="medium"
            startIcon={<Publish />}
            href={route('/rfqs/:rfqId/sourcing/quote-importer', { rfqId })}
        >
            <Trans>Import Excel</Trans>
        </PrimaryButton>
    );
};

const QuoteTrackingHeader: React.FunctionComponent<{
    quoteTracking: QuoteTrackingDTO;
    rfqId: string;
}> = ({ quoteTracking, rfqId }) => {
    return (
        <Flexbox gap={8} alignItems={'center'}>
            <Text variant="h1">{formatSupplierAndStockLocationDTO(quoteTracking.supplier_and_stock_location)}</Text>
            {quoteTracking.state === 'Received' && <StatusChip color="green" label={t`Received`} />}
            {quoteTracking.state === 'Pending' && (
                <StatusChip color="yellow" label={t({ id: 'quotetracking.status.pending', message: 'Pending' })} />
            )}
            {quoteTracking.state === 'Overdue' && <StatusChip color="red" label={t`Overdue`} />}
            {quoteTracking.state === 'Discarded' && <StatusChip color="neutral" label={t`Discarded`} />}
            {quoteTracking.state === 'FailedToSend' && <StatusChip color="red" label={t`Failed to send`} />}
            <Flexbox flex={1} />
            <Flexbox gap={'8px'}>
                <OpenSupplierPortalButton quoteTracking={quoteTracking} />
                <ChangeStatusToDiscardedButton quoteTracking={quoteTracking} />
                <DeleteQuoteTrackingOffersButton quoteTracking={quoteTracking} />
                <PdfQuoteImportButton quoteTracking={quoteTracking} />
                <QuoteTrackingCommentsButton quoteTrackingId={quoteTracking.id} rfqId={rfqId} />
            </Flexbox>
        </Flexbox>
    );
};

const OpenSupplierPortalButton: React.FunctionComponent<{
    quoteTracking: QuoteTrackingDTO;
}> = ({ quoteTracking }) => {
    const navigate = useNavigate();
    if (isProductionEnvironment()) {
        return null;
    }

    return (
        <Tooltip title={'(Development Environment Only)'}>
            <DestructivePrimaryButton
                size="small"
                onClick={async () => {
                    navigate(
                        route('/quote-portal/quote-tracking/:quoteTrackingId', {
                            quoteTrackingId: quoteTracking.id,
                        }),
                    );
                }}
                style={{ whiteSpace: 'nowrap', width: 'auto' }}
            >
                Go to supplier portal
            </DestructivePrimaryButton>
        </Tooltip>
    );
};

const ChangeStatusToDiscardedButton: React.FunctionComponent<{
    quoteTracking: QuoteTrackingDTO;
}> = ({ quoteTracking }) => {
    const { mutateAsync, isPending: isLoading } = useHttpMutation('PATCH /quote-tracking/:quoteTrackingId', {
        snackbarMessage: t`Status changed`,
    });

    if (quoteTracking.state === QuoteTrackingState.Received) {
        return null;
    }

    function getDerivedState() {
        if (
            quoteTracking.state === QuoteTrackingState.Pending ||
            quoteTracking.state === QuoteTrackingState.Overdue ||
            quoteTracking.state === QuoteTrackingState.Received ||
            quoteTracking.state === QuoteTrackingState.FailedToSend
        ) {
            return {
                nextState: QuoteTrackingState.Discarded,
                label: t`Change status to discarded`,
            };
        }
        if (quoteTracking.state === QuoteTrackingState.Discarded) {
            return {
                nextState: QuoteTrackingState.Pending,
                label: t`Change status to pending`,
            };
        }
        assertUnreachable(quoteTracking.state);
    }
    const { label, nextState } = getDerivedState();
    return (
        <SecondaryButton
            size="small"
            disabled={isLoading}
            onClick={async () => {
                await mutateAsync({
                    pathParams: { quoteTrackingId: quoteTracking.id },
                    requestBody: { state: nextState },
                });
            }}
            style={{ whiteSpace: 'nowrap', width: 'auto' }}
        >
            {label}
        </SecondaryButton>
    );
};

const DeleteQuoteTrackingOffersButton: React.FunctionComponent<{
    quoteTracking: QuoteTrackingDTO;
}> = ({ quoteTracking }) => {
    const [isConfirmDeletionDialogOpen, setIsConfirmDeletionDialogOpen] = React.useState(false);
    const { mutateAsync, isPending: isLoading } = useHttpMutation('DELETE /quote-tracking/:quoteTrackingId/offers', {
        snackbarMessage: t`Deleted all offers associated with this quote request`,
    });

    if (quoteTracking.custom_part_offers.length === 0 && quoteTracking.standard_part_offers.length === 0) {
        return null;
    }

    return (
        <>
            <DestructiveSecondaryButton
                startIcon={<Delete />}
                size="small"
                disabled={isLoading}
                onClick={() => setIsConfirmDeletionDialogOpen(true)}
                style={{ whiteSpace: 'nowrap' }}
            >
                <Trans>Delete offers</Trans>
            </DestructiveSecondaryButton>
            <ConfirmationDialogBox
                text={t`If you continue you delete all offers associated with this quote request and set the status of the quote request back to pending. Are you sure you want to delete the offers?`}
                closeIconIsShown={true}
                onConfirm={async () => {
                    await mutateAsync({ pathParams: { quoteTrackingId: quoteTracking.id } });
                }}
                title={t`Delete offers from quote request`}
                isDialogOpen={isConfirmDeletionDialogOpen}
                onReject={() => setIsConfirmDeletionDialogOpen(false)}
            />
        </>
    );
};

function QuoteTrackingCommentsButton({ quoteTrackingId, rfqId }: { quoteTrackingId: string; rfqId: string }) {
    const { openDrawer } = useCommunicationsDrawer({
        threads: [
            {
                commentType: 'QuoteTracking',
                category: 'Internal',
                typeIds: quoteTrackingId,
                quoteTrackingId,
                rfqId,
            },
            {
                commentType: 'QuoteTracking',
                category: 'Public',
                typeIds: quoteTrackingId,
                quoteTrackingId,
                rfqId,
            },
        ],
    });

    return (
        <ViewCommentsButton
            size={'small'}
            eventEntity={{ type: 'QuoteTracking', data: quoteTrackingId }}
            onClick={() => openDrawer()}
        />
    );
}

const QuoteTrackingContent: React.FunctionComponent<{
    rfqId: string;
    quoteTrackingId: string | undefined;
    metricSettings: QuoteTrackingMetricSettings;
}> = ({ rfqId, quoteTrackingId, metricSettings }) => {
    const { data: quoteTracking, isLoading } = useQuoteTracking({ quoteTrackingId });

    if (isLoading) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    if (!isPresent(quoteTracking)) {
        return (
            <CenteredLayout>
                <NonIdealState
                    Icon={RequestViaMailIcon}
                    title={t`You did not send any quote requests for this RfQ.`}
                    description={''}
                    overrides={{
                        ActionButton: () => (
                            <Flexbox alignItems={'center'} gap={'8px'}>
                                <RequestQuoteButton rfqId={rfqId} />
                                <ImportQuoteButton rfqId={rfqId} />
                            </Flexbox>
                        ),
                    }}
                />
            </CenteredLayout>
        );
    }

    return (
        <Flexbox flexDirection={'column'} bgcolor={colorSystem.neutral[1]} padding={'24px'} gap={'12px'}>
            <QuoteTrackingHeader quoteTracking={quoteTracking} rfqId={rfqId} />
            {quoteTracking.state === QuoteTrackingState.FailedToSend && (
                <Message
                    attention="high"
                    variant="red"
                    size="small"
                    title={t`Sending failed`}
                    message={t`The quote request could not be sent due to an issue with our email provider. Sorry for the inconvenience, please try again.`}
                    overrides={{ ActionButton: () => <ChangeStatusToDiscardedButton quoteTracking={quoteTracking} /> }}
                />
            )}
            <Box display="grid" gridTemplateColumns="340px auto" gap={'12px'}>
                <QuoteTrackingSummary quoteTracking={quoteTracking} metricSettings={metricSettings} />
                <QuoteTrackingTabsController quoteTracking={quoteTracking} metricSettings={metricSettings} />
            </Box>
        </Flexbox>
    );
};

export const QuoteTrackingPage = (urlParams: UrlParams<'/rfqs/:rfqId/sourcing/quote-tracking'>): JSX.Element => {
    const {
        pathParams: { rfqId },
        queryParams: { quoteTrackingId },
    } = urlParams;

    const { data: sourcingScenarios } = useSourcingScenariosOfRfq(rfqId);
    const { data: sourcingFulls } = useSourcingFullBulk(sourcingScenarios?.map((s) => s.id));

    const [metricSettings, setMetricSettings] = React.useState<QuoteTrackingMetricSettings>({ sourcingFulls });

    React.useEffect(() => {
        if (!isPresent(metricSettings.sourcingFulls) && isPresent(sourcingFulls)) {
            setMetricSettings({ sourcingFulls });
        }
    }, [metricSettings, sourcingFulls]);

    return (
        <PageLayout
            layout={'fragment'}
            removeHubspotPaddingFix
            header={<ToolbarQuiteTracking rfqId={rfqId} />}
            bgcolor={colorSystem.neutral[1]}
        >
            <Box display="grid" gridTemplateColumns="auto 1fr" style={{ height: '100%' }}>
                <Flexbox
                    flexDirection={'column'}
                    gap={'12px'}
                    width={'340px'}
                    bgcolor={colorSystem.neutral.white}
                    padding={'24px'}
                    position={'sticky'}
                    height={'calc(100% - 48px)'}
                    top={0}
                >
                    <Flexbox gap={'8px'} alignItems={'center'}>
                        <BackButton rfqId={rfqId} />
                        <Text variant="h2">{t`Quotes`}</Text>
                        <Flexbox flex={1} />
                    </Flexbox>

                    <QuoteTrackingTotalMetricCard
                        rfqId={rfqId}
                        metricSettings={metricSettings}
                        onChange={(value) => setMetricSettings(value)}
                        numberOfSourcingScenarios={sourcingScenarios?.length ?? 0}
                    />

                    <QuoteTrackingList rfqId={rfqId} selectedQuoteTrackingId={quoteTrackingId} />
                </Flexbox>

                <QuoteTrackingContent rfqId={rfqId} quoteTrackingId={quoteTrackingId} metricSettings={metricSettings} />
            </Box>
        </PageLayout>
    );
};
