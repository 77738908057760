import { t } from '@lingui/macro';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import { Flexbox, NonIdealState, colorSystem, useNavigate } from '@luminovo/design-system';
import { CheckedBulkPnpItemsResponseDTO } from '@luminovo/http-client';
import { ErrorOutline, Launch } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import React from 'react';
import { PageLayout } from '../../components/PageLayout';
import { ErrorFallback } from '../../components/errorHandlers/ErrorBoundary';
import { useSuspenseHttpQuery } from '../../resources/http/useHttpQuery';
import { useRfQ } from '../../resources/rfq/rfqHandler';
import { useIsRfqEditable } from '../../utils/rfqUtils';
import { UrlParams, route } from '../../utils/routes';
import { ViewContext } from '../Bom/components/ModuleTableData';
import { ColumnMappingNotSubmittedState } from './ColumnMappingNotSubmitted';
import { PnpViewer } from './PnPViewer';
import { PnpUploadDropzone } from './PnpUpload/PnpUploadDropzone';
import { ToolbarPnp } from './Toolbar/ToolbarPnp';
const NoBomItemsFound = ({
    assemblyId,
    rfqId,
    currentParentAssemblyId,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    currentParentAssemblyId: string | null | undefined;
    viewContext: ViewContext;
}): JSX.Element => {
    const navigate = useNavigate();
    const routeToBom =
        viewContext.type === 'WithinRfQ'
            ? route(
                  '/rfqs/:rfqId/bom/assembly/:assemblyId',
                  { rfqId, assemblyId },
                  { currentParentAssemblyId, tab: null, monitoring: null },
              )
            : route(
                  '/assemblies/:assemblyId/dashboard',
                  { assemblyId },
                  { tab: null, rfqId, isMonitoringOpen: undefined },
              );
    return (
        <NonIdealState
            title={t`No items in BOM`}
            description={t`Please add items to the BOM before uploading a pick and place file.`}
            Icon={ErrorOutline}
            withBorder={true}
            action={{
                children: t`Go to BOM`,
                startIcon: <Launch />,
                onClick: () => {
                    navigate(routeToBom);
                },
            }}
        />
    );
};

const renderItem = ({
    data,
    assemblyId,
    allBomDesignators,
    rfqId,
    currentParentAssemblyId,
    isRfqEditable,
    viewContext,
}: {
    data: CheckedBulkPnpItemsResponseDTO;
    assemblyId: string;
    allBomDesignators: string[];
    rfqId: string;
    currentParentAssemblyId: string | null | undefined;
    isRfqEditable: boolean;
    viewContext: ViewContext;
}) => {
    if (allBomDesignators.length === 0) {
        return (
            <NoBomItemsFound
                viewContext={viewContext}
                currentParentAssemblyId={currentParentAssemblyId}
                assemblyId={assemblyId}
                rfqId={rfqId}
            />
        );
    }
    const type = data.type;

    switch (type) {
        case 'PnpNotUploaded':
            return <PnpUploadDropzone assemblyId={assemblyId} isRfqEditable={isRfqEditable} />;
        case 'PnpUploaded':
            return (
                <PnpViewer
                    columnMapping={data.data}
                    assemblyId={assemblyId}
                    allBomDesignators={allBomDesignators}
                    isRfqEditable={isRfqEditable}
                />
            );
        case 'ColumnMappingNotSubmitted':
            return <ColumnMappingNotSubmittedState assemblyId={assemblyId} isRfqEditable={isRfqEditable} />;
        default:
            assertUnreachable(type);
    }
};

const PnpImporterPageInner = ({
    assemblyId,
    rfqId,
    currentParentAssemblyId,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    currentParentAssemblyId: string | null | undefined;
    viewContext: ViewContext;
}) => {
    const { data } = useSuspenseHttpQuery(
        'GET /pnp/bulk',
        {
            queryParams: { assembly: assemblyId },
        },
        {},
    );

    const { data: descendantsData } = useSuspenseHttpQuery(
        'GET /assemblies/:assemblyId/descendants',
        {
            pathParams: {
                assemblyId,
            },
        },
        {},
    );

    const { data: rfq } = useRfQ(rfqId);
    /* eslint-disable-next-line camelcase */
    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);

    if (!data || !descendantsData) {
        return <CircularProgress />;
    }

    const allBomDesignators: string[] = descendantsData.data.design_items
        .map((descendant) => descendant.designator)
        .filter(isPresent);
    return renderItem({
        data,
        assemblyId,
        allBomDesignators,
        rfqId,
        currentParentAssemblyId,
        isRfqEditable,
        viewContext,
    });
};

export const PnPImporter = ({
    rfqId,
    assemblyId,
    currentParentAssemblyId,
    viewContext,
}: {
    rfqId: string;
    assemblyId: string;
    currentParentAssemblyId?: string | null | undefined;
    viewContext: ViewContext;
}) => {
    return (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <PnpImporterPageInner
                rfqId={rfqId}
                assemblyId={assemblyId}
                viewContext={viewContext}
                currentParentAssemblyId={currentParentAssemblyId}
            />
        </Sentry.ErrorBoundary>
    );
};

export const PnpImporterPage = ({
    pathParams,
    queryParams,
}: UrlParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/pnp-importer'>): JSX.Element => {
    const { rfqId, assemblyId } = pathParams;
    const { currentParentAssemblyId } = queryParams;
    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);
    return (
        <PageLayout
            layout="default"
            header={
                <ToolbarPnp assemblyId={assemblyId} rfqId={rfqId} currentParentAssemblyId={currentParentAssemblyId} />
            }
            style={{ backgroundColor: colorSystem.neutral[1] }}
        >
            <Flexbox
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                marginTop={'32px'}
            >
                <PnPImporter
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                    viewContext={viewContext}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            </Flexbox>
        </PageLayout>
    );
};
