import { CancelButton } from '@/components/formLayouts/CancelButton';
import { LayoutCard } from '@/components/LayoutCard';
import { PageLayoutCollapsibleSidebar } from '@/components/PageLayoutCollapsibleSidebar';
import { ButtonSubmitQuoteRequest } from '@/modules/Negotiations/components/ButtonSubmitQuoteRequest';
import { IndicatorImportStatuses } from '@/modules/Negotiations/components/IndicatorImportStatuses';
import { TableQuoteRequestLineItemsSupplierForm } from '@/modules/Negotiations/components/TableQuoteRequestLineItemsSupplierForm';
import { ExcelQuoteImportDropZone } from '@/modules/Negotiations/NegotiationsQuoteImporter/ExcelQuoteImportDropZone';
import { StandardPartQuoteImportResult } from '@/modules/Negotiations/NegotiationsQuoteImporter/types';
import { FormContainerQuoteRequest } from '@/modules/Negotiations/pages/supplier-portal/SupplierPortalQuoteRequestPage/components/FormContainerQuoteRequest';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout, Flexbox, Toolbar, useBackNavigation } from '@luminovo/design-system';
import { QuoteRequestDTO } from '@luminovo/http-client';
import React from 'react';

import { route, UrlParams } from '@/utils/routes';
import { NavigationSidebarSourcing } from '../NavigationSidebarSourcing';

function ToolbarInner({ fileName, quoteRequest }: { fileName: string; quoteRequest: QuoteRequestDTO }) {
    const { navigateBack } = useBackNavigation();
    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: fileName,
                },
            ]}
        >
            <CancelButton
                size={'medium'}
                onClick={() => navigateBack({ defaultPath: route('/parts/quote-requests') })}
            />
            <ButtonSubmitQuoteRequest quoteRequestId={quoteRequest.id} />
        </Toolbar>
    );
}

export const SourcingQuoteRequestsImporterPage: React.FunctionComponent<
    UrlParams<'/rfqs/:rfqId/sourcing/negotiations/quote-requests/importer'>
> = (urlParams) => {
    const { rfqId } = urlParams.pathParams;
    const [importResult, setImportResult] = React.useState<StandardPartQuoteImportResult | undefined>(undefined);

    if (!isPresent(importResult)) {
        return (
            <PageLayoutCollapsibleSidebar
                sidebar={<NavigationSidebarSourcing rfqId={rfqId} />}
                layoutVariant="fullWidth"
            >
                <CenteredLayout>
                    <ExcelQuoteImportDropZone onSuccess={setImportResult} isLoading={false} />
                </CenteredLayout>
            </PageLayoutCollapsibleSidebar>
        );
    }

    const quoteRequestId = importResult.quoteRequest.id;

    return (
        <FormContainerQuoteRequest quoteRequestId={quoteRequestId} importResults={importResult}>
            <PageLayoutCollapsibleSidebar
                sidebar={<NavigationSidebarSourcing rfqId={rfqId} />}
                layoutVariant="fullWidth"
                header={<ToolbarInner fileName={importResult.file.name} quoteRequest={importResult.quoteRequest} />}
            >
                <Flexbox flexDirection="column" gap={'12px'} height={'100%'}>
                    <IndicatorImportStatuses />
                    <LayoutCard style={{ height: '100%' }}>
                        <TableQuoteRequestLineItemsSupplierForm quoteRequestId={quoteRequestId} />
                    </LayoutCard>
                </Flexbox>
            </PageLayoutCollapsibleSidebar>
        </FormContainerQuoteRequest>
    );
};
