import React from 'react';
import { useHistory } from 'react-router-dom';

type NavigateOptions = {
    replace?: boolean;
};

type To = string | -1 | { pathname: string; search: string };

type NavigateFunction = (to: To, options?: NavigateOptions) => void;

/**
 * TEMPORARY: This is a temporary hook to replace the useHistory hook from react-router-dom.
 * This will be removed once we migrate to the new router.
 *
 * Please do not use useHistory in new components anymore, this hook should be used instead.
 * Also, there is a useNavigateBack hook that can be used to navigate back.
 */
export function useNavigate(): NavigateFunction {
    const history = useHistory();

    return React.useCallback(
        (to: To, options?: NavigateOptions) => {
            if (typeof to === 'number') {
                if (to === -1) {
                    history.goBack();
                } else {
                    history.go(to);
                }
            } else if (options?.replace) {
                history.replace(to);
            } else {
                history.push(to);
            }
        },
        [history],
    );
}
