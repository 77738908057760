import { t } from '@lingui/macro';
import { MenuButton, MenuItem, Tooltip, useNavigate } from '@luminovo/design-system';
import { AddRounded, FileUploadRounded, PersonAddRounded } from '@mui/icons-material';
import { useSupplier } from '../../../resources/supplier/supplierHandler';
import { route } from '../../../utils/routes';
import { useDialogAddSupplierContact } from './SupplierContactDialogs/AddSupplierContactDialog';
export function ButtonAddSupplierContacts({
    supplierId,
    showLabel = false,
    appearance = 'secondary',
}: {
    supplierId: string;
    showLabel?: boolean;
    appearance?: 'primary' | 'secondary';
}): JSX.Element {
    const navigate = useNavigate();
    const { data: supplier } = useSupplier(supplierId);
    const { openDialog } = useDialogAddSupplierContact(supplierId);

    const supplierName = supplier?.name ?? t`Supplier`;
    return (
        <Tooltip title={showLabel ? '' : t`Add contacts for ${supplierName}`} placement="right">
            <span>
                <MenuButton
                    size="medium"
                    icon={<PersonAddRounded />}
                    appearance={appearance}
                    label={showLabel ? t`Add contacts` : undefined}
                >
                    <MenuItem
                        startIcon={<FileUploadRounded />}
                        label={<>{t`From .csv file`}</>}
                        onClick={() =>
                            navigate(
                                route('/supplier/:supplierId/supplier-contacts-importer', {
                                    supplierId,
                                }),
                            )
                        }
                    />
                    <MenuItem startIcon={<AddRounded />} label={t`Manually`} onClick={() => openDialog()} />
                </MenuButton>
            </span>
        </Tooltip>
    );
}
