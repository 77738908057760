import { t } from '@lingui/macro';
import { colorSystem } from '@luminovo/design-system';
import { BannerCard } from './BannerCard';
import { DownloadQuotationButton } from './DownloadQuotationButton';

export const BannerCardOrderInProduction = ({ rfqId }: { rfqId: string }): JSX.Element => {
    return (
        <BannerCard
            background="linear-gradient(83.52deg, #6FCEFF -1.88%, #6F7FFF 120.44%)"
            title={t`Your order is now in production!`}
            description={t`You will receive a notification once the order is shipped.`}
            icon="🏭"
            callToAction={
                <DownloadQuotationButton
                    rfqId={rfqId}
                    color={colorSystem.neutral.white}
                    background="rgba(255, 255, 255, .3)"
                    hideIfNoQuotation
                />
            }
        />
    );
};
