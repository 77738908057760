import { assertUnreachable } from '@luminovo/commons';
import { CustomerPortalState, OrganizationDTO, RfqDTO, RfqStatus } from '@luminovo/http-client';
import { helpHero } from '../../../../../utils/analytics';
import { useIsRfqEditable } from '../../../../../utils/rfqUtils';
import { isEveryCustomerPortalStepDone } from '../../util/DashboardSteps';
import { BannerCardNoOrderOrQuotation } from './BannerCardNoOrderOrQuotation';
import { BannerCardOrderConfirmed } from './BannerCardOrderConfirmed';
import { BannerCardOrderInProcurement } from './BannerCardOrderInProcurement';
import { BannerCardOrderInProduction } from './BannerCardOrderInProduction';
import { BannerCardOrderPlaced } from './BannerCardOrderPlaced';
import { BannerCardOrderShipped } from './BannerCardOrderShipped';
import { BannerCardPaymentFailed } from './BannerCardPaymentFailed';
import { BannerCardQuotationAvailable } from './BannerCardQuotationAvailable';
import { BannerCardQuotationInProgress } from './BannerCardQuotationInProgress';
import { BannerCardRequestInDraft } from './BannerCardRequestInDraft';

export function CustomerDashboardBanner({
    rfq,
    assemblyId,
    org,
    customerPortalState,
}: {
    rfq: RfqDTO;
    assemblyId: string;
    org: OrganizationDTO;
    customerPortalState: CustomerPortalState;
}) {
    const { isRfqEditable } = useIsRfqEditable(rfq.status, rfq.is_archived, rfq.workflow_type);

    const everyStepDone = isEveryCustomerPortalStepDone(customerPortalState);

    const rfqStatus = rfq.status;
    const rfqId = rfq.id;

    // Ensures that the user can restart the HelpHero tour
    const hasRequestQuotationButtonEnabled = rfqStatus === RfqStatus.RequestInDraft && everyStepDone;
    helpHero?.update({ hasRequestQuotationButtonEnabled });

    if (rfqStatus === RfqStatus.OrderConfirmed) {
        return <BannerCardOrderConfirmed orgName={org.name} rfqId={rfqId} />;
    }

    if (rfqStatus === RfqStatus.OrderInProduction) {
        return <BannerCardOrderInProduction rfqId={rfqId} />;
    }

    if (rfqStatus === RfqStatus.OrderShipped) {
        return (
            <BannerCardOrderShipped
                orgName={org.name}
                shippingTrackingLink={rfq.shipping_tracking_link ?? undefined}
                rfqId={rfqId}
            />
        );
    }

    if (rfqStatus === RfqStatus.QuotationAvailable) {
        return <BannerCardQuotationAvailable rfqId={rfqId} orderWorkflow={rfq.workflow_type} />;
    }

    if (rfqStatus === RfqStatus.RequestInDraft) {
        return (
            <BannerCardRequestInDraft
                rfqId={rfqId}
                assemblyId={assemblyId}
                customerPortalState={customerPortalState}
                isRfqEditable={isRfqEditable}
            />
        );
    }

    if (rfqStatus === RfqStatus.QuotationInProgress) {
        return <BannerCardQuotationInProgress rfqId={rfqId} />;
    }

    if (rfqStatus === RfqStatus.NoOrder || rfqStatus === RfqStatus.NoQuotation) {
        return <BannerCardNoOrderOrQuotation />;
    }

    if (rfqStatus === RfqStatus.OrderPlaced) {
        return <BannerCardOrderPlaced rfqId={rfqId} />;
    }

    if (rfqStatus === RfqStatus.OrderInProcurement) {
        return <BannerCardOrderInProcurement rfqId={rfqId} />;
    }

    if (rfqStatus === RfqStatus.PaymentFailed) {
        return <BannerCardPaymentFailed rfqId={rfqId} />;
    }

    assertUnreachable(rfqStatus);
}
