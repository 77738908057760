import { LayoutCard } from '../../../../components/LayoutCard';
import { useDescendants } from '../../../../resources/assembly/assemblyHandler';
import { ViewContext } from '../../../Bom/components/ModuleTableData';
import { SubassembliesTable } from './SubassembliesTable';

export const SubassembliesContainer = ({
    viewContext,
    assemblyId,
}: {
    viewContext: ViewContext;
    assemblyId?: string;
}) => {
    const { data: descendants } = useDescendants(assemblyId);

    if (!descendants || descendants.data.assemblies.length === 0) {
        return null;
    }

    return (
        <LayoutCard style={{ height: 'clamp(400px, 40vh, 540px)' }}>
            <SubassembliesTable subassemblies={descendants.data.assemblies} viewContext={viewContext} />
        </LayoutCard>
    );
};
