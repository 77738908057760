import { Trans } from '@lingui/macro';
import { Flexbox, Text, Tooltip, TooltipProps } from '@luminovo/design-system';
import React from 'react';

export const MultiplePartOriginsTooltipWithChip: React.FunctionComponent<{
    name: 'RoHS' | 'REACH' | 'AECQ' | 'Lifecycle';
    children: TooltipProps['children'];
}> = ({ name, children }): JSX.Element => {
    return (
        <Tooltip
            arrow
            title={
                <Flexbox flexDirection="column" gap="12px" width="200px" padding="4px">
                    <Text variant="h5">
                        <Trans>{name} origins</Trans>
                    </Text>
                    <Text variant="body-small">
                        <Trans>
                            This BOM item has multiple {name} origins due to multiple part options. Click to see the
                            details.
                        </Trans>
                    </Text>
                </Flexbox>
            }
            variant="white"
        >
            <span>{children}</span>
        </Tooltip>
    );
};
