import { t } from '@lingui/macro';
import { CenteredLayout, Flexbox, useNavigate } from '@luminovo/design-system';
import { ManufacturingScenarioTemplateDTO, SourcingScenarioDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormContainer, ValidationErrors } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useAssemblyAggregateSourcingQuantity } from '../../../../resources/assembly/assemblyHandler';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useSourcingScenariosOfRfq } from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { route } from '../../../../utils/routes';
import { TemplateSelectionControlled } from './TemplateSelection';
import { ManufacturingScenarioNameControlled, SourcingScenarioControlled } from './formItems';
import { BatchSizeNotAMultipleOfOrderSize, useBatchSizeEvaluation } from './formItems/BatchSizeNotMultipleOfOrderSize';
import { BatchSizesMultiSelectControlledAdd } from './formItems/BatchSizesMultiSelectControlled';
import { AddManufacturingScenarioFormState } from './types';
const validationErrors: ValidationErrors<AddManufacturingScenarioFormState> = {
    'manufacturing_scenario.name_already_exists': {
        fieldPath: 'manufacturingScenarioName',
    },
};

export const AddManufacturingScenarioForm = ({
    rfqId,
    assemblyId,
    onSuccess,
}: {
    rfqId: string;
    assemblyId: string;
    onSuccess: () => void;
}): JSX.Element => {
    const { data: sourcingScenarios } = useSourcingScenariosOfRfq(rfqId);
    const { data: manufacturingScenarioTemplates } = useHttpQuery('GET /manufacturing-scenario-templates', {
        queryParams: {},
    });
    const { assemblyQuantities } = useAssemblyAggregateSourcingQuantity({
        rfqId,
        assemblyId,
        enabled: true,
    });
    const navigate = useNavigate();
    const { mutateAsync } = useHttpMutation('POST /manufacturing-scenarios', {
        snackbarMessage: t`Manufacturing scenario successfully created`,
        onSuccess: (result) => {
            onSuccess();
            navigate(
                route('/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId', {
                    rfqId: rfqId,
                    manufacturingAssemblyDetailsId: result.data.manufacturing_assembly_details,
                }),
            );
        },
    });

    const defaultValues: AddManufacturingScenarioFormState = {
        manufacturingScenarioName: '',
        batchSizes: [],
        sourcingScenarioId: '',
        manufacturingScenarioTemplateIds: [],
        notes: '',
    };

    const onSubmit = async (form: AddManufacturingScenarioFormState) => {
        /* eslint-disable camelcase */
        await mutateAsync({
            requestBody: {
                name: form.manufacturingScenarioName,
                batch_sizes: form.batchSizes,
                assembly: assemblyId,
                sourcing_scenario:
                    form.sourcingScenarioId && form.sourcingScenarioId.length > 0 ? form.sourcingScenarioId : null,
            },
            queryParams: {
                from_templates: form.manufacturingScenarioTemplateIds,
            },
            /* eslint-enable camelcase */
        });
    };

    if (!sourcingScenarios || !assemblyQuantities || !manufacturingScenarioTemplates?.data) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} validationErrors={validationErrors}>
            <Flexbox flexDirection="column" height="100%" gap="24px">
                <Flexbox>
                    <AddManufacturingScenarioFormInner
                        sourcingScenarios={sourcingScenarios}
                        manufacturingScenarioTemplates={manufacturingScenarioTemplates.data.filter(
                            (t) => t.status === 'Active',
                        )}
                        assemblyQuantities={assemblyQuantities}
                    />
                </Flexbox>
                <Flexbox flexDirection="row" justifyContent="end">
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
};

const AddManufacturingScenarioFormInner = ({
    sourcingScenarios,
    manufacturingScenarioTemplates,
    assemblyQuantities,
}: {
    sourcingScenarios: SourcingScenarioDTO[];
    manufacturingScenarioTemplates: ManufacturingScenarioTemplateDTO[];
    assemblyQuantities: Record<string, number>;
}): JSX.Element => {
    const { control } = useFormContext<AddManufacturingScenarioFormState>();
    const width = '350px';

    const batchSizeEvaluation = useBatchSizeEvaluation(sourcingScenarios, assemblyQuantities);

    return (
        <Flexbox flexDirection="column" width="100%" gap="24px">
            <Flexbox flexDirection="row" gap="32px">
                <ManufacturingScenarioNameControlled control={control} name="manufacturingScenarioName" width={width} />

                <BatchSizesMultiSelectControlledAdd control={control} width={width} />

                <SourcingScenarioControlled
                    assemblyQuantities={assemblyQuantities}
                    sourcingScenarios={sourcingScenarios}
                    width={width}
                />

                {batchSizeEvaluation.type === 'error' && (
                    <BatchSizeNotAMultipleOfOrderSize error={batchSizeEvaluation} />
                )}
            </Flexbox>

            <TemplateSelectionControlled
                control={control}
                manufacturingScenarioTemplates={manufacturingScenarioTemplates}
            />
        </Flexbox>
    );
};
