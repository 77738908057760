/* eslint-disable camelcase */
import { colorSystem, Flexbox, StickyLayout, usePersistedState } from '@luminovo/design-system';
import { DesignItemOriginTypes } from '@luminovo/http-client';
import { UseFormReturn } from 'react-hook-form';
import { QueryParams } from '../../../utils/routes';
import { BomItemTableData, ViewContext } from '../../Bom/components/ModuleTableData';
import { OriginalExcel } from '../../DesignItemDetails/components/OriginalExcel';
import { BOM_DETAILS_BORDER_BOTTOM, BomItemDetails } from './BomItemFormHeader/BomItemDetails';
import { BomItemFormState } from './BomItemFormState';

interface BomItemDetailsAndOriginalExcelProps {
    queryParams: QueryParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>;
    assemblyId: string;
    viewContext: ViewContext;
    bomItem: BomItemTableData;
    formReturn: UseFormReturn<BomItemFormState>;
    submitForm: () => void;
    isEditable: boolean;
    siblingDesignators: string[];
    customerId?: string;
}

export const BomItemDetailsAndOriginalExcel = ({
    queryParams,
    assemblyId,
    viewContext,
    bomItem,
    formReturn,
    submitForm,
    isEditable,
    siblingDesignators,
    customerId,
}: BomItemDetailsAndOriginalExcelProps): JSX.Element => {
    const [isBomDetailsPinned, setIsBomDetailsPinned] = usePersistedState<'true' | 'false'>(
        'isBomDetailsPinned',
        'false',
        localStorage,
    );
    return (
        <>
            <Flexbox
                flexDirection="column"
                style={{
                    position: isBomDetailsPinned === 'true' ? 'sticky' : 'unset',
                    top: isBomDetailsPinned === 'true' ? '0px' : 'unset',
                    zIndex: isBomDetailsPinned === 'true' ? 1 : 'unset',
                    background: colorSystem.neutral[1],
                    marginBottom: -BOM_DETAILS_BORDER_BOTTOM,
                }}
            >
                <BomItemDetails
                    queryParams={queryParams}
                    assemblyId={assemblyId}
                    bomItem={bomItem}
                    submitForm={submitForm}
                    isRfqEditable={isEditable}
                    siblingDesignators={siblingDesignators}
                    customerId={customerId}
                    viewContext={viewContext}
                    isBomDetailsPinned={isBomDetailsPinned}
                    setIsBomDetailsPinned={setIsBomDetailsPinned}
                />
            </Flexbox>
            <StickyLayout
                top={isBomDetailsPinned === 'true' ? '148px' : 0}
                zIndex={1}
                style={{
                    background: colorSystem.neutral[1],
                }}
            >
                {bomItem.individualDesignItems.some(
                    (c): boolean => c.origin.type === DesignItemOriginTypes.ExcelFile,
                ) && <OriginalExcel bomItem={bomItem} formReturn={formReturn} />}
            </StickyLayout>
        </>
    );
};
