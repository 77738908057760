import { Currency, isPresent } from '@luminovo/commons';
import {
    ItemClass,
    OtsComponentFull,
    OtsFullPart,
    Packaging,
    PriceType,
    QuantityUnit,
    QuoteRequestDTO,
    StandardPartDTO,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import { Control, FieldErrors } from 'react-hook-form';
import { ParsedObjectsResult } from 'read-excel-file/types';

type OffTheShelfLinkedPart = {
    type: 'OffTheShelf';
    part: OtsFullPart;
};

type IpnLinkedPart = {
    type: 'Ipn';
    part: OtsComponentFull;
};

export type AlternativeLinkedPart = {
    type: 'AlternativePart';
    part: OtsFullPart | null;
    matches: OtsFullPart[];
    requestedOtsPart: OtsFullPart | undefined;
};

type NonMatchingLinkedPart = {
    type: 'NonMatching';
    part: null;
};

export type StandardPartQuoteLineTableData = {
    type: 'StandardPartLine';
    index: number;
    quoteRequestLineItemId: string | undefined;
    requestedPart: StandardPartDTO | undefined;
    linkedPart: OffTheShelfLinkedPart | IpnLinkedPart | AlternativeLinkedPart | NonMatchingLinkedPart;
    row: StandardPartQuoteLineRow['row'];
    warnings: StandardPartQuoteLineRow['warnings'];
    excelRowNumber: number;
};

export type QuoteLineTableData = {
    status: Array<'good' | 'errors' | 'warnings' | 'missingLeadTimeUnit' | 'missingUnit'>;
} & StandardPartQuoteLineTableData;

export type QuoteImporterFormState = {
    state: 'idle' | 'loading' | 'ready';
    file: File;
    quoteRequest: QuoteRequestDTO;
    supplierAndStockLocation: SupplierAndStockLocationDTO;
    offerNumber: string | undefined;
    priceType: PriceType;
    selectedIndices: number[];
    lines: StandardPartQuoteLineTableData[];
};

export type SharedContext = {
    control: Control<QuoteImporterFormState, any>;
    errors: FieldErrors<QuoteImporterFormState>;
};

export type StandardPartQuoteImportResult = {
    file: File;
    quoteRequestNumber: number; // TODO: Remove this
    quoteRequest: QuoteRequestDTO;
    supplierId: string | undefined;
    supplierName: string | undefined;
    offerNumber: string | undefined;
    quoteLineRow: StandardPartQuoteLineRow[];
};

export function hasMissingUnitForLine(line: StandardPartQuoteLineTableData): boolean {
    return !isPresent(line.row.unit);
}

export function hasMissingLeadTimeUnitForLine(line: StandardPartQuoteLineTableData): boolean {
    return !isPresent(line.row.stdFactoryLeadTimeUnit);
}

export function hasErrorsForLine(
    line: StandardPartQuoteLineTableData,
    errors: FieldErrors<QuoteImporterFormState>,
): boolean {
    const index = line.index;
    if (!isPresent(line.linkedPart.part)) {
        return true;
    }

    if (hasMissingUnitForLine(line)) {
        return true;
    }
    if (hasMissingLeadTimeUnitForLine(line)) {
        return true;
    }

    if (isPresent(errors.lines) && isPresent(errors.lines[index])) {
        return true;
    }

    if (!isPresent(line.row.currency)) {
        return true;
    }

    if (!isPresent(line.row.unitPriceQuantity)) {
        return true;
    }

    // When the line is a no-bid line, the unit price is not required
    if (line.row.bid && (!isPresent(line.row.unitPrice) || line.row.unitPrice <= 0)) {
        return true;
    }

    if (!isPresent(line.row.unitPriceQuantity) || line.row.unitPriceQuantity <= 0) {
        return true;
    }

    if (isPresent(line.row.stock) && line.row.stock < 0) {
        return true;
    }

    if (isPresent(line.row.minPackagingQuantity) && line.row.minPackagingQuantity <= 0) {
        return true;
    }

    if (isPresent(line.row.minOrderQuantity) && line.row.minOrderQuantity <= 0) {
        return true;
    }

    if (isPresent(line.row.stdFactoryLeadTime) && line.row.stdFactoryLeadTime <= 0) {
        return true;
    }

    return false;
}

export function hasWarningsForLine(line: StandardPartQuoteLineTableData): boolean {
    return line.warnings.some((warning) => {
        const column = warning.column as StandardPartQuoteHeader;
        const propKey = HeaderToPropsMap[column];
        return !isPresent(line.row[propKey]);
    });
}

export function extractQuoteLineStatus(
    line: StandardPartQuoteLineTableData,
    errors: FieldErrors<QuoteImporterFormState>,
): Array<'good' | 'errors' | 'warnings' | 'missingLeadTimeUnit' | 'missingUnit'> {
    const status: Array<'good' | 'errors' | 'warnings' | 'missingLeadTimeUnit' | 'missingUnit'> = [];

    if (hasErrorsForLine(line, errors)) {
        status.push('errors');
    }

    if (hasWarningsForLine(line)) {
        status.push('warnings');
    }

    if (hasMissingUnitForLine(line)) {
        status.push('missingUnit');
    }

    if (hasMissingLeadTimeUnitForLine(line)) {
        status.push('missingLeadTimeUnit');
    }

    if (status.length === 0) {
        status.push('good');
    }

    return status;
}

export type StandardPartQuoteLineRow = {
    type: 'StandardPartLine';
    excelRowNumber: number;
    row: StandardPartQuoteLines;
    warnings: ParsedObjectsResult<StandardPartQuoteLines>['errors'];
};

export enum StandardPartQuoteHeader {
    LumiQuoteId = 'LumiQuote-ID',
    Ipn = 'IPN',
    Description = 'Description',
    Manufacturer = 'Manufacturer',
    ManufacturerPartNumber = 'Manufacturer Part Number (MPN)',
    ShipTo = 'Ship to',
    RequiredQuantity = 'Required quantity',
    PotentialQuantity = 'Potential quantity',
    Bid = 'Bid*',
    Unit = 'Unit',
    OfferedMpn = 'Offered MPN*',
    OfferedManufacturer = 'Offered manufacturer*',
    UnitPrice = 'Unit price*',
    UnitPriceQuantity = 'Unit Price quantity*',
    Currency = 'Currency*',
    SupplierPartNumber = 'Supplier part number (SKU)',
    Packaging = 'Packaging',
    MinOrderQuantity = 'Minimum Order Quantity (MOQ)',
    MinPackagingQuantity = 'Minimum Packaging Quantity (MPQ)',
    StdFactoryLeadTime = 'Std. factory lead time',
    StdFactoryLeadTimeUnit = 'Std. factory lead time unit',
    CancellationWindow = 'Cancellation window',
    CancellationTimeUnit = 'Cancellation time unit',
    ItemClass = 'Item class (standard/non-standard)',
    Ncnr = 'NCNR',
    Stock = 'Stock',
    ValidUntil = 'Price valid until (YYYY.MM.DD)',
    ValidFrom = 'Price valid from (YYYY.MM.DD)',
    Customer = 'Customer',
    NegotiatedByCustomer = 'Negotiated by customer',
    AdditionalNotes = 'Notes (if offered part differs: provide reason for alternative, e.g. MPN correction, different packaging, alternative)',
}

export type StandardPartQuoteLines = Partial<{
    lumiQuoteId: string;
    ipn: string;
    description: string;
    requestedManufacturer: string;
    requestedMpn: string;
    shipTo: string;
    requiredQuantity: number;
    potentialQuantity: number;
    bid: boolean;
    unit: QuantityUnit;
    offeredMpn: string;
    offeredManufacturer: string;
    unitPrice: number;
    unitPriceQuantity: number;
    currency: Currency;
    supplierPartNumber: string | null;
    packaging: Packaging | null;
    minOrderQuantity: number;
    minPackagingQuantity: number;
    stdFactoryLeadTime: number;
    stdFactoryLeadTimeUnit: 'days' | 'weeks';
    cancellationWindow: number;
    cancellationTimeUnit: 'days' | 'weeks';
    itemClass: ItemClass | null;
    ncnr: boolean;
    stock: number;
    validUntil: string;
    validFrom: string;
    customer?: string | null;
    negotiatedByCustomer?: boolean | null;
    additionalNotes: string;
    partCategory: string;
}>;

export const HeaderToPropsMap: { [key in StandardPartQuoteHeader]: keyof StandardPartQuoteLines } = {
    [StandardPartQuoteHeader.LumiQuoteId]: 'lumiQuoteId',
    [StandardPartQuoteHeader.Ipn]: 'ipn',
    [StandardPartQuoteHeader.Description]: 'description',
    [StandardPartQuoteHeader.Manufacturer]: 'requestedManufacturer',
    [StandardPartQuoteHeader.ManufacturerPartNumber]: 'requestedMpn',
    [StandardPartQuoteHeader.ShipTo]: 'shipTo',
    [StandardPartQuoteHeader.RequiredQuantity]: 'requiredQuantity',
    [StandardPartQuoteHeader.PotentialQuantity]: 'potentialQuantity',
    [StandardPartQuoteHeader.Bid]: 'bid',
    [StandardPartQuoteHeader.Unit]: 'unit',
    [StandardPartQuoteHeader.OfferedMpn]: 'offeredMpn',
    [StandardPartQuoteHeader.OfferedManufacturer]: 'offeredManufacturer',
    [StandardPartQuoteHeader.UnitPrice]: 'unitPrice',
    [StandardPartQuoteHeader.UnitPriceQuantity]: 'unitPriceQuantity',
    [StandardPartQuoteHeader.Currency]: 'currency',
    [StandardPartQuoteHeader.SupplierPartNumber]: 'supplierPartNumber',
    [StandardPartQuoteHeader.Packaging]: 'packaging',
    [StandardPartQuoteHeader.MinOrderQuantity]: 'minOrderQuantity',
    [StandardPartQuoteHeader.MinPackagingQuantity]: 'minPackagingQuantity',
    [StandardPartQuoteHeader.StdFactoryLeadTime]: 'stdFactoryLeadTime',
    [StandardPartQuoteHeader.StdFactoryLeadTimeUnit]: 'stdFactoryLeadTimeUnit',
    [StandardPartQuoteHeader.CancellationWindow]: 'cancellationWindow',
    [StandardPartQuoteHeader.CancellationTimeUnit]: 'cancellationTimeUnit',
    [StandardPartQuoteHeader.ItemClass]: 'itemClass',
    [StandardPartQuoteHeader.Ncnr]: 'ncnr',
    [StandardPartQuoteHeader.Stock]: 'stock',
    [StandardPartQuoteHeader.ValidUntil]: 'validUntil',
    [StandardPartQuoteHeader.ValidFrom]: 'validFrom',
    [StandardPartQuoteHeader.Customer]: 'customer',
    [StandardPartQuoteHeader.NegotiatedByCustomer]: 'negotiatedByCustomer',
    [StandardPartQuoteHeader.AdditionalNotes]: 'additionalNotes',
};
