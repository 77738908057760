import { t, Trans } from '@lingui/macro';
import { useHasPermission } from '@luminovo/auth';
import { assertUnreachable, Currency, formatRelativeTime, id, isPresent } from '@luminovo/commons';
import {
    colorSystem,
    DestructiveTertiaryIconButton,
    Dropzone,
    FieldDateControlled,
    FieldNumericControlled,
    FieldSelectControlled,
    FieldSelectCreatableControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    FormSection,
    Message,
    SecondaryButton,
    TertiaryButton,
    Text,
    useNavigate,
} from '@luminovo/design-system';
import {
    CustomLinkedPartDTO,
    CustomPartTypeEnum,
    CustomPricePointInputDTO,
    OneTimeCostInputDTO,
    Option,
    PcbPanelSpecificationDTO,
    PriceType,
    QuantityUnit,
    QuantityUnitDTO,
    RegionsEnum,
    RfqContext,
    SupplierAndStockLocationDTO,
    SupplierPartType,
    SupplierPreference,
    SupplierType,
} from '@luminovo/http-client';
import {
    formatSupplierAndStockLocationDTO,
    priceTypeTranslations,
    QuantityUnitRecordPlural,
    QuantityUnitRecordSingular,
} from '@luminovo/sourcing-core';
import { Add, Close } from '@mui/icons-material';
import { Box, Divider, InputAdornment, styled, Typography } from '@mui/material';
import * as React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { transEnum } from '../../../components/localization/TransEnum';
import { PartCard } from '../../../components/partSpecificationCards/PartCard';
import { LoadingText } from '../../../components/Spinners';
import { inputCurrenciesPublicTranslations } from '../../../resources/currencyInputTypes';
import { AttachmentFile } from '../../../resources/http/useHttpFileUpload';
import { useCustomComponent, useCustomPart } from '../../../resources/part/partHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { useDialogAddSupplierAndStockLocation } from '../../SupplierManagement/components/SupplierDialogs/AddSupplierDialog';
export type CustomPartOfferFormValues = {
    linkedPart: CustomLinkedPartDTO;
    supplierAndStockLocation: SupplierAndStockLocationDTO;
    quantityUnit: QuantityUnitDTO;
    currency: Currency;
    pricePoints: CustomPricePointInputDTO[];
    oneTimeCosts: OneTimeCostInputDTO[];
    validUntilDate?: string;
    notes?: Option<string>;
    priceType: PriceType;
    sourcingBatchSize?: number | null;
    files: AttachmentFile[];
    specification?: PcbPanelSpecificationDTO | null;
};

function FormItemPriceType() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    return (
        <FormItem label={t`Type`} required>
            <FieldSelectControlled
                control={control}
                name="priceType"
                required
                FieldProps={{
                    options: [
                        PriceType.ListPrice,
                        PriceType.ContractPrice,
                        PriceType.QuotePrice,
                        PriceType.CustomerNegotiatedPrice,
                    ],
                    getOptionLabel: (option) => transEnum(option, priceTypeTranslations),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

export function CustomPartOfferForm({
    onSubmit,
    defaultValues,
    rfqContext,
    showUnitOfMeasurementWarning = false,
}: {
    onSubmit: (form: CustomPartOfferFormValues) => Promise<void>;
    defaultValues: CustomPartOfferFormValues;
    rfqContext: RfqContext;
    showUnitOfMeasurementWarning?: boolean;
}) {
    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} UNSAFE_disableStablePropCheck>
            <Flexbox gap={32} flexDirection="column">
                <FormSection title={t`Offer information`}>
                    <FormItemLinkedPart rfqContext={rfqContext} />
                    <FormItemSupplier />
                    <FormItemPriceType />
                    <FormItemUnitOfMeasurement showWarning={showUnitOfMeasurementWarning} />
                    <FormItemSourcingBatchSize />
                </FormSection>
                <Divider />
                <FormSection title={t`Pricing information`}>
                    <FormItemCurrency />
                    <FormItemPricePoints />
                    <FormItemOneTimeCosts />
                </FormSection>
                <Divider />
                <FormSection title={t`Offer validity`}>
                    <FormItemValidUntilDate />
                </FormSection>
                <Divider />
                <FormSection title={t`Additional information`}>
                    <FormItemNotes />
                    <FormItemFiles />
                </FormSection>
                <Divider />
                <Flexbox flexDirection="row" gap={8} justifyContent="flex-end">
                    <CancelButton />
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}

function CancelButton() {
    const navigate = useNavigate();
    const handleClose = React.useCallback(() => {
        navigate(-1);
    }, [navigate]);
    return (
        <SecondaryButton onClick={handleClose}>
            <Trans>Cancel</Trans>
        </SecondaryButton>
    );
}

function FormItemLinkedPart({ rfqContext }: { rfqContext: RfqContext }) {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const linkedPart = useWatch({ control, name: 'linkedPart' });

    if (linkedPart.type === 'CustomPart') {
        return <FormItemCustomPart partId={linkedPart.id} rfqContext={rfqContext} />;
    }

    if (linkedPart.type === 'CustomComponent') {
        return <FormItemCustomComponent ipnId={linkedPart.id} rfqContext={rfqContext} />;
    }

    assertUnreachable(linkedPart);
}

function FormItemCustomPart({ partId, rfqContext }: { partId: string; rfqContext: RfqContext }) {
    const { data: part } = useCustomPart(partId);

    return (
        <FormItem label={t`Part`}>
            <PartCard part={part} rfqContext={rfqContext} collapsed={true} />
        </FormItem>
    );
}

function FormItemCustomComponent({ ipnId, rfqContext }: { ipnId: string; rfqContext: RfqContext }) {
    const { data: customComponent } = useCustomComponent(ipnId, rfqContext);

    return (
        <FormItem label={t`Custom component`}>
            <PartCard part={customComponent} rfqContext={rfqContext} collapsed={true} />
        </FormItem>
    );
}

function FormItemSupplier() {
    const { control, setValue } = useFormContext<CustomPartOfferFormValues>();

    const hasCreateSupplierPermission = useHasPermission(['create:supplier']);
    const { data: supplierAndStockLocations = [], isLoading } = useNonExcludedSupplierAndStockLocations();
    const { openDialog } = useDialogAddSupplierAndStockLocation({
        onSuccessCallback: (s) => {
            setValue('supplierAndStockLocation', s, { shouldValidate: true });
        },
        disableSupplierPreferenceField: true,
    });

    if (isLoading) {
        return <LoadingText />;
    }

    return (
        <FormItem label={t`Supplier`} required>
            <FieldSelectCreatableControlled
                control={control}
                name="supplierAndStockLocation"
                required
                FieldProps={{
                    id: id('sourcing/input_supplier'),
                    options: supplierAndStockLocations,
                    getOptionKey: (option) => option.id,
                    getOptionLabel: (option) => formatSupplierAndStockLocationDTO(option),
                    renderOption: (option) => {
                        return (
                            <Flexbox flexDirection={'column'} gap={4} maxWidth={'100%'}>
                                <Text variant={'body'}>{formatSupplierAndStockLocationDTO(option)}</Text>
                                <Text variant={'body-small'} color={colorSystem.neutral[7]}>
                                    {option.supplier_number}
                                </Text>
                            </Flexbox>
                        );
                    },
                    isOptionEqualToValue: (option, value) => option.id === value.id,
                    action: {
                        label: t`Add new supplier`,
                        disabled: !hasCreateSupplierPermission,
                        onClick: (newValue) => {
                            openDialog({
                                name: newValue,
                                stockLocation: RegionsEnum.Unknown,
                                supplierNumber: '',
                                supplierPreference: SupplierPreference.NotApproved,
                                supplierType: SupplierType.Manufacturer,
                                supplierPartType: SupplierPartType.Custom,
                            });
                        },
                    },
                }}
            />
        </FormItem>
    );
}

function FormItemUnitOfMeasurement({ showWarning }: { showWarning: boolean }) {
    const [editMode, setEditMode] = React.useState(false);
    const { control } = useFormContext<CustomPartOfferFormValues>();

    return (
        <FormItem
            label={t`Unit of measurement`}
            required
            description={
                <Trans>
                    All other quantities are multiplied by the unit of measurement to obtain the total quantity. For
                    example, if the unit of measurement is “10 meters” and you have “2” in stock, then we calculate this
                    as 2 * 10 meters = 20 meters in stock
                </Trans>
            }
            actions={
                showWarning ? (
                    <TertiaryButton size="small" onClick={() => setEditMode((edit) => !edit)}>
                        <Trans>Edit unit of measurement</Trans>
                    </TertiaryButton>
                ) : undefined
            }
        >
            {editMode && (
                <Message
                    size={'small'}
                    variant={'yellow'}
                    attention={'low'}
                    message={t`Modifying the unit of measurement will affect both quantity and stock`}
                />
            )}
            <Box display={'grid'} gridTemplateColumns="1fr 120px" columnGap={8}>
                <FieldNumericControlled
                    control={control}
                    name="quantityUnit.quantity"
                    required={true}
                    min={1}
                    FieldProps={{
                        disabled: showWarning ? !editMode : false,
                    }}
                />
                <FieldSelectControlled
                    control={control}
                    name="quantityUnit.unit"
                    FieldProps={{
                        disableClearable: true,
                        options: Object.values(QuantityUnit).filter((unit) => unit !== QuantityUnit.Boards),
                        getOptionLabel: (option) => transEnum(option, QuantityUnitRecordSingular),
                        disabled: showWarning ? !editMode : false,
                    }}
                />
            </Box>
        </FormItem>
    );
}

function FormItemCurrency() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    return (
        <FormItem label={t`Currency`} required>
            <FieldSelectControlled
                control={control}
                name="currency"
                required
                FieldProps={{
                    options: Object.values(Currency),
                    getOptionLabel: (option) => transEnum(option, inputCurrenciesPublicTranslations),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

function FormItemPricePoints() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const currency = useWatch({ control, name: 'currency' });
    const unitOfMeasurement = useWatch({ control, name: 'quantityUnit.quantity' });
    const unit = useWatch({ control, name: 'quantityUnit.unit' });
    const disabled = !isPresent(unitOfMeasurement) || isNaN(unitOfMeasurement);

    const linkedPart = useWatch({ control, name: 'linkedPart' });
    const { data: part } = useCustomPart(linkedPart.id);
    const isPcbCustomPart = part?.type.name === CustomPartTypeEnum.PCB;

    const { fields, append, remove } = useFieldArray({ control, name: 'pricePoints' });

    return (
        <FormItem label={t`Price points`}>
            <Box
                display={'grid'}
                alignItems="center"
                gridTemplateColumns={'1fr 1fr 1fr auto'}
                rowGap={'16px'}
                columnGap={'16px'}
            >
                <Typography color="textSecondary">
                    <Trans>Quantity</Trans>*
                </Typography>
                <Typography color="textSecondary">
                    {isPcbCustomPart ? <Trans>Panel price</Trans> : <Trans>Unit price</Trans>}*
                </Typography>
                <Typography color="textSecondary">
                    <Trans>Lead time (days)</Trans>
                </Typography>

                <span />
                {fields.map((item, index) => {
                    return (
                        <React.Fragment key={`${index}-${JSON.stringify(item)}`}>
                            <FieldNumericControlled
                                control={control}
                                name={`pricePoints.${index}.quantity`}
                                exclusiveMin={0}
                                required
                                FieldProps={{
                                    placeholder: t`Quantity`,
                                    InputProps: {
                                        endAdornment:
                                            disabled || unitOfMeasurement === 1 ? undefined : (
                                                <InputAdornment position="end">
                                                    {`× ${unitOfMeasurement} ${transEnum(
                                                        unit,
                                                        QuantityUnitRecordPlural,
                                                    )}`}
                                                </InputAdornment>
                                            ),
                                    },
                                }}
                            />
                            <FieldNumericControlled
                                control={control}
                                name={`pricePoints.${index}.amount`}
                                exclusiveMin={0}
                                required
                                FieldProps={{
                                    InputProps: {
                                        endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                                    },
                                }}
                            />
                            <FieldNumericControlled
                                control={control}
                                name={`pricePoints.${index}.lead_time_days`}
                                isInteger={true}
                                min={1}
                                FieldProps={{
                                    placeholder: t`Full days`,
                                }}
                            />
                            <DestructiveTertiaryIconButton
                                onClick={() => remove(index)}
                                size="medium"
                                disabled={fields.length < 2}
                            >
                                <Close fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        </React.Fragment>
                    );
                })}
                <SecondaryButton
                    startIcon={<Add />}
                    onClick={() => {
                        append({
                            quantity: fields.map((x) => x.quantity).reduce((a, b) => Math.max(a, b), 1),
                            amount: '0',
                            lead_time_days: undefined,
                        });
                    }}
                    size="medium"
                >
                    <Trans>Add price point</Trans>
                </SecondaryButton>
            </Box>
        </FormItem>
    );
}

function FormItemOneTimeCosts() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const currency = useWatch({ control, name: 'currency' });

    const { fields, append, remove } = useFieldArray({ control, name: 'oneTimeCosts' });

    return (
        <FormItem label={t`One-time costs`}>
            <Box
                display={'grid'}
                alignItems="center"
                gridTemplateColumns={'1fr 1fr 1fr auto'}
                rowGap={'16px'}
                columnGap={'16px'}
            >
                <Typography color="textSecondary">
                    <Trans>Cost</Trans>*
                </Typography>
                <Typography color="textSecondary">
                    <Trans>Description</Trans>
                </Typography>
                <span />
                <span />
                {fields.map((item, index) => {
                    return (
                        <React.Fragment key={`${index}-${JSON.stringify(item)}`}>
                            <FieldNumericControlled
                                control={control}
                                name={`oneTimeCosts.${index}.amount`}
                                exclusiveMin={0}
                                required
                                FieldProps={{
                                    InputProps: {
                                        endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                                    },
                                }}
                            />
                            <FieldTextControlled control={control} name={`oneTimeCosts.${index}.description`} />
                            <span />
                            <DestructiveTertiaryIconButton onClick={() => remove(index)} size="medium">
                                <Close fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        </React.Fragment>
                    );
                })}
                <SecondaryButton
                    startIcon={<Add />}
                    onClick={() => {
                        append({
                            amount: String(1),
                            description: undefined,
                        });
                    }}
                    size="medium"
                >
                    <Trans>Add one-time cost</Trans>
                </SecondaryButton>
            </Box>
        </FormItem>
    );
}

function FormItemSourcingBatchSize() {
    const { control } = useFormContext<CustomPartOfferFormValues>();

    return (
        <FormItem label={t`Sourcing batch size`}>
            <FieldNumericControlled
                control={control}
                name={`sourcingBatchSize`}
                exclusiveMin={0}
                isInteger={true}
                FieldProps={{
                    placeholder: t`Sourcing batch size`,
                }}
            />
        </FormItem>
    );
}

function FormItemValidUntilDate() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const validUntilDate = useWatch({ control, name: 'validUntilDate' });

    return (
        <FormItem label={t`Valid until`}>
            <FieldDateControlled control={control} name={'validUntilDate'} />
            {validUntilDate && <Text variant="body">{formatRelativeTime(validUntilDate)}.</Text>}
        </FormItem>
    );
}

function FormItemNotes() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    return (
        <FormItem label={t`Notes`}>
            <FieldTextControlled control={control} name="notes" FieldProps={{ multiline: true, minRows: 2 }} />
        </FormItem>
    );
}

const FormItemFiles = () => {
    const { control, setValue } = useFormContext<CustomPartOfferFormValues>();
    const files = useWatch({ control, name: 'files' });

    const handleChangeDropZone = (newFiles: File[]) => {
        const filesWithUrl = newFiles.map(
            (file): AttachmentFile => ({
                file: file,
                url: URL.createObjectURL(file),
                name: file.name,
                state: 'PendingUpload',
            }),
        );
        setValue('files', [...files, ...filesWithUrl]);
    };

    const handleDelete = async (removedFile: AttachmentFile) => {
        setValue(
            'files',
            files.map((file) => {
                if (file.url === removedFile.url) {
                    if (file.file) {
                        file.state = 'RemovedLocal';
                    } else {
                        file.state = 'PendingRemoval';
                    }
                }
                return file;
            }),
        );
    };

    const renderFiles = files.filter((file) => file.state === 'StoredInAzure' || file.state === 'PendingUpload');
    return (
        <FormItem label={t`Files`}>
            <Dropzone
                accept={null}
                title={''}
                subHeader={t`You can upload additional files to your offer`}
                onDropAccepted={handleChangeDropZone}
                multiple={true}
                overrides={{ Container }}
                persistentFiles={renderFiles.map((file) => ({
                    name: file.name,
                    onClick: () => window.open(file.url, '_blank'),
                    onDelete: () => handleDelete(file),
                }))}
            />
        </FormItem>
    );
};

const Container = styled(Flexbox)({
    flexDirection: 'column',
});
