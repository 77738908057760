/* eslint-disable camelcase */
import { AnalyticsBrowser } from '@segment/analytics-next';
import { ConsentType } from '../ConsentType';
import { Tracker } from '../Tracker';

let tracker: AnalyticsBrowser | undefined = undefined;

export const segmentTracker: Tracker = {
    requiredConsent: ConsentType.analytics,

    /**
     * Don't pollute analytics with internal user events
     */
    enabledForInternalUsers: false,

    enabledForDevEnvironment: false,

    identify(
        userId: string,
        {
            email,
            language,
            is_from_customer,
            customer_name,
            host,
            tenant,
            has_access_to_customer_portal,
            has_access_to_source_to_contract,
            is_closed_beta_tester,
            has_full_manufacturing_access,
            has_customers,
            client_type,
        },
    ) {
        tracker?.identify(
            userId,
            {
                email,
                language,
                is_from_customer,
                customer_name,
                host,
                tenant,
                has_access_to_customer_portal,
                has_access_to_source_to_contract,
                is_closed_beta_tester,
                has_full_manufacturing_access,
                has_customers,
                client_type,
            },
            { groupId: tenant },
        );
    },

    initialize() {
        if (window.SEGMENT_WRITE_KEY) {
            tracker = AnalyticsBrowser.load({ writeKey: window.SEGMENT_WRITE_KEY });
        }
    },

    group: (
        orgId: string,
        {
            name,
            has_access_to_customer_portal,
            is_closed_beta_tester,
            has_access_to_source_to_contract,
            has_customers,
            client_type,
        },
    ) => {
        tracker?.group(orgId, {
            name,
            has_access_to_customer_portal,
            has_access_to_source_to_contract,
            is_closed_beta_tester,
            has_customers,
            client_type,
        });
        // note that we should also pass the userId to the context of the group call
        // could be done in the following way with @segment/analytics-next
        //      tracker?.group(orgId, {}, {userId: userId})
        // the Segment docs are a little cryptic but the current assumption is that if
        // we do an identify call before the group call (which we always do) then the userId is
        // anyways correctly set in the group call by @segment/analytics-next and we don't need
        // to manually set it.
    },

    track(event: string, args) {
        tracker?.track(event, args);
    },
    page(eventName, args) {
        tracker?.page(eventName, args);
    },
};
