import { cases } from '../combinators/cases';
import { Parser } from './Parser';

export function boolean(
    { parseEmptyAsFalse = false }: { parseEmptyAsFalse?: boolean } = { parseEmptyAsFalse: false },
): Parser<boolean> {
    const empty = parseEmptyAsFalse ? [''] : [];

    return cases({
        matches: [
            {
                when: [
                    'true',
                    'yes',
                    'ja',
                    'si',
                    'oui',
                    'sì',
                    'da',
                    'tak',
                    'igen',
                    'evet',
                    'да',
                    '是',
                    '是的',
                    'ha',
                    'y',
                ],
                then: true,
            },
            {
                when: ['false', 'no', 'nein', 'non', 'nie', 'hayır', 'нет', '否', '不是', 'nem', 'n', ...empty],
                then: false,
            },
        ],
    });
}
