import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { ManufacturerLineCardDTORuntype, ManufacturerWithPartCountDTORuntype } from './manufacturerBackendTypes';

export const manufacturerEndpoints = {
    'GET /manufacturers': endpoint({
        description: 'Fetches all manufacturers',
        pathParams: r.Undefined,
        queryParams: r.Record({ high_quality: r.Boolean }),
        requestBody: r.Undefined,
        responseBody: r.Record({ data: r.Array(ManufacturerWithPartCountDTORuntype) }),
    }),

    'GET /manufacturers/line-card': endpoint({
        description: 'Fetches all linecards for every manufacturer',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: r.Array(ManufacturerLineCardDTORuntype) }),
    }),

    'GET /manufacturers/:id/line-card': endpoint({
        description: 'Fetches linecards for that manufacturer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: ManufacturerLineCardDTORuntype,
    }),
};
