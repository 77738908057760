import React from 'react';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';

export const DeletePcbFileModal = ({
    fileNames,
    pcbId,
    isConfirmationDialogOpen,
    setIsConfirmationDialogOpen,
    onDeleteConfirmation,
    title,
    text,
}: {
    fileNames: string[];
    pcbId: string;
    isConfirmationDialogOpen: boolean;
    setIsConfirmationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onDeleteConfirmation?: () => void;
    title: string;
    text: string;
}) => {
    const { mutateAsync } = useHttpMutation('DELETE /pcb/:pcbId/files', {
        snackbarMessage: null,
        onSuccess: () => {
            onDeleteConfirmation?.();
        },
    });

    const handleDeleteFile = async () => {
        await mutateAsync({
            pathParams: { pcbId },
            requestBody: { files: fileNames },
        });
    };

    return (
        <ConfirmationDialogBox
            text={text}
            title={title}
            closeIconIsShown={true}
            onConfirm={handleDeleteFile}
            isDialogOpen={isConfirmationDialogOpen}
            onReject={() => setIsConfirmationDialogOpen(false)}
        />
    );
};
