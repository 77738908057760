import { t } from '@lingui/macro';
import { Chip } from '@luminovo/design-system';
import { SupplierLineCardItemSourceDTO } from '@luminovo/http-client';

export const LineCardSourceChip = ({ source }: { source: SupplierLineCardItemSourceDTO }): JSX.Element => {
    return (
        <Chip
            color={source.type === 'System' ? 'green' : 'blue'}
            label={source.type === 'System' ? t`System` : t`Manual`}
        />
    );
};
