import { Trans } from '@lingui/macro';
import { compareByDate } from '@luminovo/commons';
import {
    colorSystem,
    Dialog,
    DialogActions,
    DialogContent,
    Flexbox,
    PrimaryButton,
    TertiaryButton,
    Text,
} from '@luminovo/design-system';
import { DemandDTO } from '@luminovo/http-client';
import { useState } from 'react';
import { useDialogContext } from '../../components/contexts/ModalContext';
import { analytics } from '../../utils/analytics';
import { TableDemandsFilterDialog } from './TableDemandFilterDialog';

const DemandFilterDialog = ({
    closeDialog,
    submit,
    initialDemandIds,
    demands,
    removeFilter,
}: {
    closeDialog: () => void;
    submit: (demandIds: string[]) => void;
    removeFilter: () => void;
    initialDemandIds: string[];
    demands: DemandDTO[];
}) => {
    const [selectedDemandIds, setSelectedDemandIds] = useState<string[]>(initialDemandIds);
    const filteredDemands = demands;
    const isInitialState =
        initialDemandIds.length === selectedDemandIds.length &&
        initialDemandIds.every((id) => selectedDemandIds.includes(id));

    return (
        <Dialog
            open
            onClose={closeDialog}
            title={
                <Flexbox gap={4} alignItems="center">
                    <Trans>Filter demand</Trans>
                </Flexbox>
            }
            maxWidth="xl"
        >
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <Text color={colorSystem.neutral[8]}>
                    <Trans>
                        Fine-tune which demand you would like to be displayed. Please note that these filters will only
                        be applied to your account.
                    </Trans>
                </Text>

                <Flexbox flexDirection="column" gap={4}>
                    <Text variant="h4" color={colorSystem.neutral[8]}>
                        <Trans>Demands</Trans>
                    </Text>
                    <Text color={colorSystem.neutral[8]}>
                        <Trans>Which of the following demands should be taken into account?</Trans>
                    </Text>
                    <div style={{ height: '400px', width: '100%', marginTop: '8px' }}>
                        <TableDemandsFilterDialog
                            demands={filteredDemands.sort(compareByDate((demand) => demand.created_at))}
                            onSelectionChange={(demandIds) => {
                                setSelectedDemandIds(demandIds);
                            }}
                            showSelection={true}
                            selectedDemandIds={selectedDemandIds}
                            showImportButton={false}
                        />
                    </div>
                </Flexbox>
            </DialogContent>
            <DialogActions>
                <TertiaryButton
                    onClick={() => {
                        removeFilter();
                        closeDialog();
                    }}
                >
                    <Trans>Reset to all</Trans>
                </TertiaryButton>
                <PrimaryButton
                    onClick={() => {
                        analytics.track('save_filter_demands', {
                            /* eslint-disable camelcase */
                            demand_ids: selectedDemandIds,
                            /* eslint-enable camelcase */
                        });
                        submit(selectedDemandIds);
                        closeDialog();
                    }}
                    disabled={isInitialState}
                >
                    <Trans>Save filter changes</Trans>
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};

export const useFilterDemandDialog = ({
    submit,
    initialDemandIds,
    demands,
    removeFilter,
}: {
    submit: (demandIds: string[]) => void;
    removeFilter: () => void;
    initialDemandIds: string[];
    demands: DemandDTO[];
}) => {
    const { setDialog, closeDialog } = useDialogContext();

    return () =>
        setDialog(
            <DemandFilterDialog
                closeDialog={closeDialog}
                submit={submit}
                initialDemandIds={initialDemandIds}
                demands={demands}
                removeFilter={removeFilter}
            />,
        );
};
