import { Plural, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, PrimaryButton, Tag, Text, useNavigate } from '@luminovo/design-system';
import { AssemblyTypeEnum } from '@luminovo/http-client';
import React from 'react';
import { Skeleton } from '../../../../components/Spinners';
import { transEnum } from '../../../../components/localization/TransEnum';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { assemblyTypePublicTranslations } from '../../../../resources/rfq/i18n';
import { route } from '../../../../utils/routes';
import { DividerWithMargins } from '../assemblyOverviewCardComponents';
import { AssemblyOverviewBlankCard } from './AssemblyOverviewBlankCard';
import { AssemblyPath } from './components/AssemblyPath';
import { STYLED_BOX_GRID_WIDTH, StyledBoxGrid } from './components/StyledBoxGrid';

export interface AssemblyOverviewCardProps {
    designator: string;
    assemblyType: AssemblyTypeEnum;
    parentAssemblies: string[];
    rfqId: string;
    manufacturingAssemblyDetailsId: string;
    assemblyId: string;
}

export const AssemblyOverviewCard: React.FunctionComponent<AssemblyOverviewCardProps> = ({
    designator,
    assemblyType,
    parentAssemblies,
    rfqId,
    manufacturingAssemblyDetailsId,
    assemblyId,
}: AssemblyOverviewCardProps): JSX.Element => {
    const navigate = useNavigate();

    const { data, isLoading } = useHttpQuery('GET /manufacturing-scenarios', {
        /* eslint-disable-next-line camelcase */
        queryParams: { manufacturing_assembly_details_id: manufacturingAssemblyDetailsId },
    });
    const numberOfManufacturingScenarios: number | undefined = data?.data.length;

    const handleClick = React.useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            navigate(
                route('/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId', {
                    rfqId,
                    manufacturingAssemblyDetailsId,
                }),
            );
        },
        [manufacturingAssemblyDetailsId, navigate, rfqId],
    );

    if (isLoading) {
        return <Skeleton width={STYLED_BOX_GRID_WIDTH} />;
    }
    if (numberOfManufacturingScenarios === undefined || numberOfManufacturingScenarios === 0) {
        return (
            <AssemblyOverviewBlankCard
                rfqId={rfqId}
                designator={designator}
                assemblyId={assemblyId}
                assemblyType={assemblyType}
                parentAssemblies={parentAssemblies}
                manufacturingAssemblyDetailsId={manufacturingAssemblyDetailsId}
            />
        );
    }

    return (
        <StyledBoxGrid>
            <Flexbox flexDirection="column">
                <Flexbox
                    alignItems={'center'}
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                >
                    <Text variant="h2" style={{ maxWidth: '300px' }} showEllipsis>
                        {designator}
                    </Text>
                    <Tag
                        color={'neutral'}
                        label={transEnum(assemblyType, assemblyTypePublicTranslations)}
                        style={{ marginLeft: '8px' }}
                    />
                </Flexbox>
                <AssemblyPath assemblyId={assemblyId} />
            </Flexbox>
            <DividerWithMargins />
            <Flexbox flexDirection={'column'}>
                <Text variant="body" style={{ color: colorSystem.neutral[6], maxWidth: '300px' }} showEllipsis>
                    <Plural
                        value={numberOfManufacturingScenarios}
                        one={`# manufacturing scenario.`}
                        other={`# manufacturing scenarios.`}
                    />
                </Text>
            </Flexbox>
            <Flexbox>
                <PrimaryButton style={{ marginLeft: 'auto' }} onClick={handleClick} size="medium">
                    <Trans>View dashboard</Trans>
                </PrimaryButton>
            </Flexbox>
        </StyledBoxGrid>
    );
};
