import { t, Trans } from '@lingui/macro';
import { Protected } from '@luminovo/auth';
import { colorSystem, Flexbox, Tab, Tabs, Tag, TertiaryButton, Text } from '@luminovo/design-system';
import { PreferenceStatusEnum, RfqContext, UserType } from '@luminovo/http-client';
import { TimeStamp } from '@luminovo/sourcing-core';
import { Launch } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useCurrentUserDetailsContext } from '../../../../components/contexts/CurrentUserDetailsContext';
import { Skeleton } from '../../../../components/Spinners';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useIpn } from '../../../../resources/part/partHandler';
import { route } from '../../../../utils/routes';
import { nthValueInArray } from '../../../../utils/typingUtils';
import { LinkedPartsTab } from './LinkedPartsTab';
import { SupplyChainIpnSummary } from './SupplyChainSummaryBoxes';
import { UsedInTableDataMini } from './UsedInTab/UsedInTableData';

const OpenIpnPageButton: React.FunctionComponent<{
    ipnId: string;
}> = ({ ipnId }) => {
    return (
        // TODO(supplier-portal): hide for suppliers

        <TertiaryButton
            size="small"
            startIcon={<Launch />}
            onClick={() =>
                window.open(
                    route('/parts/components/ipn', {}, { ipnId, q: undefined }),
                    '_blank',
                    'noopener noreferrer',
                )
            }
        >
            <Trans>View details</Trans>
        </TertiaryButton>
    );
};

const OpenIpnOffersPageButton: React.FunctionComponent<{
    ipnId: string;
}> = ({ ipnId }) => {
    return (
        <Protected requiredPermissions={['edit:ipn']}>
            <TertiaryButton
                size="small"
                startIcon={<Launch />}
                onClick={() =>
                    window.open(
                        route('/parts/components/ipn/offers', {}, { ipnId, q: undefined }),
                        '_blank',
                        'noopener noreferrer',
                    )
                }
            >
                <Trans>View offers</Trans>
            </TertiaryButton>
        </Protected>
    );
};

const Description = ({ text }: { text: string }): JSX.Element => {
    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <Text variant="h5" color={colorSystem.neutral[7]}>
                <Trans>Description</Trans>
            </Text>
            <Text
                variant="body-small"
                style={{
                    overflowY: 'auto',
                    height: '48px',
                }}
            >
                {text}
            </Text>
        </Flexbox>
    );
};

const CustomerTabs = ({
    activeTab,
    setActiveTab,
}: {
    activeTab: 'linked-parts' | 'used-in' | 'offers';
    setActiveTab: (val: 'linked-parts' | 'used-in' | 'offers') => void;
}) => {
    return (
        <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)} size="large">
            <Tab value="linked-parts" label={t`Linked parts`} />
        </Tabs>
    );
};

const EMSTabs = ({
    ipnId,
    activeTab,
    setActiveTab,
}: {
    ipnId: string;
    activeTab: 'linked-parts' | 'offers' | 'used-in';
    setActiveTab: (val: 'linked-parts' | 'offers' | 'used-in') => void;
}) => {
    const { data: items = [] } = useHttpQuery(
        'POST /ipns/assemblies/bulk',
        {
            requestBody: { ids: [ipnId], rfq_context: 'OutsideRfQ' },
        },
        { select: (res) => res.data[ipnId] || [] },
    );

    const assembliesCount = items.length;

    return (
        <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)} size="large">
            <Tab value="linked-parts" label={t`Linked parts`} />
            <Tab value="used-in" count={assembliesCount} label={t`Used in`} />;
            <Tab value="offers" label={t`Offers`} />
        </Tabs>
    );
};

export const IpnDetailsPanel = ({
    ipnId,
    rfqContext,
    BackButton,
    showOpenIpnPageButton,
}: {
    ipnId: string;
    rfqContext: RfqContext;
    BackButton: React.ComponentType;
    showOpenIpnPageButton: boolean;
}): JSX.Element => {
    const { data: ipn, isLoading } = useIpn(ipnId, rfqContext, { refetchOnWindowFocus: true });
    const currentUser = useCurrentUserDetailsContext();
    const [activeTab, setActiveTab] = useState<'linked-parts' | 'offers' | 'used-in'>('linked-parts');

    if (isLoading || !ipn) {
        return <Skeleton width={200} />;
    }

    const description = nthValueInArray(ipn.part_specifications, 0)?.description ?? '-';

    return (
        <Flexbox flexDirection="column" gap={24} style={{ backgroundColor: 'inherit' }}>
            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                <BackButton />
                <Protected requiredPermissions={['edit:ipn']}>
                    <Flexbox gap={12}>{showOpenIpnPageButton && <OpenIpnPageButton ipnId={ipnId} />}</Flexbox>
                </Protected>
            </Flexbox>

            <Flexbox flexDirection="column" justifyContent="center" gap={12}>
                <Flexbox gap={2} flexDirection="column">
                    <Flexbox gap={4} alignItems="center" justifyContent="space-between">
                        <Flexbox gap={4} alignItems="center">
                            <Text variant="h2" color={colorSystem.neutral[8]}>
                                {ipn.id}
                            </Text>
                        </Flexbox>
                        <Flexbox gap={12} alignItems="flex-end">
                            <TimeStamp title={t`Last import`} time={ipn.last_imported_at} />
                            <TimeStamp title={t`Last change`} time={ipn.updated_at} />
                        </Flexbox>
                    </Flexbox>
                    {ipn.component_specification?.function_specification?.type && (
                        <Flexbox gap="4px">
                            <Tag
                                color="neutral"
                                label={ipn.component_specification.function_specification.type}
                                attention="low"
                            />
                            {ipn.manufacturer_preference_status && (
                                <Tag
                                    label={ipn.manufacturer_preference_status?.toString()}
                                    attention="low"
                                    color={
                                        ipn.manufacturer_preference_status === PreferenceStatusEnum.Preferred
                                            ? 'violet'
                                            : 'red'
                                    }
                                />
                            )}
                        </Flexbox>
                    )}
                </Flexbox>
                <Box sx={{ marginBottom: '-16px' }}>
                    <Description text={description} />
                </Box>

                <SupplyChainIpnSummary ipn={ipn} />
            </Flexbox>

            {currentUser.userType === UserType.Customer && (
                <CustomerTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            )}

            {currentUser.userType === UserType.Internal && (
                <EMSTabs ipnId={ipnId} activeTab={activeTab} setActiveTab={setActiveTab} />
            )}

            {activeTab === 'linked-parts' && <LinkedPartsTab ipnId={ipnId} rfqContext={rfqContext} />}

            {activeTab === 'offers' && (
                <Box sx={{ maxWidth: '200px' }}>
                    <OpenIpnOffersPageButton ipnId={ipnId} />
                </Box>
            )}
            {activeTab === 'used-in' && <UsedInTableDataMini ipnId={ipnId} />}
        </Flexbox>
    );
};
