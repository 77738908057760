/* eslint-disable camelcase */
import { useDialogContext } from '@/components/contexts/ModalContext';
import { CancelButton } from '@/components/formLayouts/CancelButton';
import { FormContainer } from '@/components/formLayouts/FormContainer';
import { SubmitButton } from '@/components/formLayouts/SubmitButton';
import { t } from '@lingui/macro';
import { Dialog, DialogActions, DialogContent, DialogTitle, Flexbox } from '@luminovo/design-system';
import { Box } from '@mui/material';
import {
    DemandFormState,
    FormDeliveryEndDate,
    FormDeliveryStartDate,
    FormItemAssemblies,
    FormItemContext,
    FormItemQuantity,
} from './DemandFormItems';

export function useEditDemandDialog() {
    const { setDialog, closeDialog } = useDialogContext();
    return {
        openDialog: ({
            defaultValues,
            onSubmit,
        }: {
            defaultValues: DemandFormState;
            onSubmit: (values: DemandFormState) => void;
        }) =>
            setDialog(
                <Dialog open={true} maxWidth={'md'} onClose={() => closeDialog()}>
                    <FormContainer
                        defaultValues={defaultValues}
                        onSubmit={(values) => {
                            onSubmit(values);
                            closeDialog();
                        }}
                    >
                        <DialogTitle title={`Edit demand`} handleClose={() => closeDialog()} />
                        <DialogContent style={{ paddingBottom: '24px' }}>
                            <Flexbox flexDirection="column" rowGap={3}>
                                <FormItemContext />
                                <FormItemAssemblies />
                                <FormItemQuantity />
                                <Box
                                    display={'grid'}
                                    alignItems="center"
                                    gridTemplateColumns={'1fr 1fr'}
                                    rowGap={'16px'}
                                    columnGap={'16px'}
                                >
                                    <FormDeliveryStartDate />
                                    <FormDeliveryEndDate />
                                </Box>
                            </Flexbox>
                        </DialogContent>
                        <DialogActions>
                            <CancelButton onClick={() => closeDialog()} />
                            <SubmitButton label={t`Save`} />
                        </DialogActions>
                    </FormContainer>
                </Dialog>,
            ),
    };
}
