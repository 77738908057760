import { t, Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import {
    createColumnHelper,
    PrimaryButton,
    TanStackTable,
    useNavigate,
    useTanStackTable,
} from '@luminovo/design-system';
import { DemandResponseDTO, DemandsSummaryResponseDTO } from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { Upload } from '@mui/icons-material';
import { useMemo } from 'react';
import { NoDemandsMessage } from '../../../../../components/NoDemandsMessage';
import { route } from '../../../../../utils/routes';
import { DemandType } from '../../../../Demand/types';
import { SupplierOrRecipientCell } from './SupplierOrRecipientCell';
const ImportDemandsButton = () => {
    const navigate = useNavigate();
    return (
        <PrimaryButton
            onClick={() => {
                navigate(route('/parts/demand/demand-importer'));
            }}
            size="medium"
            startIcon={<Upload />}
        >
            <Trans>Import demand</Trans>
        </PrimaryButton>
    );
};

function convertToDemand(data: DemandResponseDTO, demandType: DemandType): Demand {
    let quantity;
    switch (demandType) {
        case 'net':
            quantity = data.net_quantity;
            break;
        case 'gross':
            quantity = data.gross_quantity;
            break;
        default:
            assertUnreachable(demandType);
    }
    return {
        startDate: data.delivery_start_date,
        endDate: data.delivery_end_date,
        supplier: data.supplier.name,
        shipToSite: data.ship_to_site.name,
        quantity: formatQuantity(quantity),
        origin: 'Calculated',
    };
}

interface Demand {
    startDate: string;
    endDate: string;
    supplier: string;
    shipToSite: string;
    quantity: string;
    origin: string;
}

const columnHelper = createColumnHelper<Demand>();

const columns = [
    columnHelper.date('startDate', {
        label: () => t`Start date`,
        size: 200,
        cell: (info) => info.getValue(),
    }),
    columnHelper.date('endDate', {
        label: () => t`End date`,
        size: 200,
        cell: (info) => info.getValue(),
    }),
    columnHelper.enum('supplier', {
        label: () => t`Supplier (site)`,
        getOptionLabel: (option) => option,
        size: 200,
        cell: (info) => <SupplierOrRecipientCell title={info.getValue()} />,
    }),
    columnHelper.enum('shipToSite', {
        label: () => t`Ship to (site)`,
        getOptionLabel: (option) => option,
        size: 200,
        cell: (info) => <SupplierOrRecipientCell title={info.getValue()} />,
    }),
    columnHelper.number('quantity', {
        label: () => t`Quantity`,
        size: 200,
        cell: (info) => info.getValue(),
    }),
];

export const DemandTable = ({
    demandData,
    demandType,
}: {
    demandData: DemandsSummaryResponseDTO | undefined;
    demandType: DemandType;
}): JSX.Element => {
    const data = useMemo(
        () => demandData?.demands.map((data) => convertToDemand(data, demandType)),
        [demandData, demandType],
    );

    const { table } = useTanStackTable({
        data,
        columns,
        enableColumnHiding: true,
        enableColumnOrdering: true,
    });

    return (
        <TanStackTable table={table} ActionButton={ImportDemandsButton} EmptyPlaceholder={() => <NoDemandsMessage />} />
    );
};
