import { isEqual } from '@luminovo/commons';
import { CheckableChip } from '@luminovo/design-system';
import { useMemo } from 'react';
import { formatColumnValue, formatValue } from '../columns/getColumnValue';
import { AgGridFilter, DataGridRow, QuickFilterProps } from '../types';
import { useColumnFilter, useDataGridContext } from './DataGridContextProvider';

/**
 * A quick filter for a column.
 *
 * @param param.columnId - The column to filter.
 * @param param.label - The label to display.
 * @param param.value - The value to filter by.
 * @returns
 */
export function QuickFilter<TRow extends DataGridRow, TValue>({
    column,
    label,
    value,
}: QuickFilterProps<TRow, TValue>): JSX.Element {
    const { api, rowData } = useDataGridContext<DataGridRow>();
    const currentFilter = useColumnFilter<DataGridRow>(column.getColId());

    const filterValue = value;
    const filterText = formatValue(filterValue, column);
    const count = useMemo(() => {
        if (!column) {
            return 0;
        }
        return rowData.filter((row) => formatColumnValue(row, column) === filterText).length;
    }, [rowData, column, filterText]);

    if (!column) {
        // can't find column, so we can't filter
        return <></>;
    }

    const filter: AgGridFilter = { filterType: 'text', type: 'equals', filter: filterText };
    const isActive = Boolean(currentFilter) && isEqual(currentFilter, filter);

    return (
        <>
            <CheckableChip
                label={`${count} ${label()}`}
                selected={isActive}
                onClick={() => {
                    if (!api) {
                        return;
                    }
                    if (isActive) {
                        api.setFilterModel({
                            ...api.getFilterModel(),
                            [column.getColId()]: null,
                        });
                        return;
                    } else {
                        api.setFilterModel({
                            ...api.getFilterModel(),
                            [column.getColId()]: filter,
                        });
                    }
                }}
            />
        </>
    );
}
