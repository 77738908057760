import { t } from '@lingui/macro';
import { uniq } from '@luminovo/commons';
import { Chip, Flexbox, Tag, Text, colorSystem } from '@luminovo/design-system';
import { ColumnMap, ColumnName, ExcelLineType, PartOptionDTO, RowRecord } from '@luminovo/http-client';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';
import React from 'react';
import { UseFormReturn, useController } from 'react-hook-form';
import { getTooltipForAcronym } from '../../../../utils/getTooltip';
import { HighlightableString } from '../../../../utils/highlighting/highlightableString';
import { findBestHighlightMatch } from '../../../../utils/highlighting/mpnHighlighting';
import { getValueOf, notUndefined } from '../../../../utils/typingUtils';
import { generateColumnsFromColumnMap, parseColumns } from '../../../BomImporter/parseColumns';
import { BomItemFormState } from '../../../DesignItem/components/BomItemFormState';
import { getSelectedOptions as getTagsByColumnId } from '../AutocompleteColumnTags/reducer';
import { Column, ColumnTagsSheetsState } from '../AutocompleteColumnTags/types';
import { parseRawOriginalLine } from '../parseRawOriginalLine';

interface EmbeddedTableProps {
    headerRowJson: RowRecord | undefined;
    excelRows: ExcelLineType;
    allCandidatesForHighlights?: string[];
    highlightedColumnNamesPartSuggestions: ColumnName[];
    tagsState: ColumnTagsSheetsState;
    showLineNumberColumn?: boolean;
    columnMap: ColumnMap | null;
    formReturn: UseFormReturn<BomItemFormState>;
    isColumnMap: boolean;
}

const ColumnNameCell = styled(TableCell)({
    verticalAlign: 'top',
    height: '100%',
    position: 'sticky',
    backgroundColor: colorSystem.neutral[0],
    // The top is needed for sticky scrolling
    top: 0,
});

const StyledCell = styled(TableCell)({
    border: 'none',
});

const EmbeddedTableCell = React.memo(function EmbeddedTableCell({
    content,
    highlightedColumns,
    index,
}: {
    content: HighlightableString;
    highlightedColumns: number[];
    index: number;
}) {
    const [isCopiedVisible, setIsCopiedVisible] = React.useState<{ x: number; y: number } | undefined>(undefined);

    const handleClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const selection = window.getSelection();
        const text =
            selection && selection.toString().length > 0
                ? selection.toString()
                : typeof content === 'string'
                  ? content
                  : content.map((t) => t.fragment).join('');

        await navigator.clipboard
            .writeText(text.trim())
            .then(() => {
                setIsCopiedVisible({ x: e.pageX + 24, y: e.pageY });
            })
            .catch(() => {
                /* can be ignored */
            });
    };

    return (
        <StyledCell onClick={handleClick}>
            {content.map(({ fragment, isHighlighted }, i) => (
                <Typography
                    key={i}
                    display="inline"
                    style={
                        highlightedColumns.includes(index) && isHighlighted
                            ? {
                                  color: colorSystem.primary[6],
                                  cursor: 'copy',
                              }
                            : { cursor: 'copy' }
                    }
                    variant={'subtitle1'}
                >
                    {fragment}
                </Typography>
            ))}
            <FadeOut
                style={{
                    position: 'fixed',
                    left: isCopiedVisible?.x,
                    top: isCopiedVisible?.y,
                    zIndex: 1000,
                    userSelect: 'none',
                }}
                isVisible={!!isCopiedVisible}
                onHide={() => setIsCopiedVisible(undefined)}
            >
                <Tag color="green" label={t`Copied to clipboard`} />
            </FadeOut>
        </StyledCell>
    );
});

const EmbeddedTableTagCell = React.memo(function EmbeddedTableTagCell({
    tagsState,
    column,
}: {
    tagsState: ColumnTagsSheetsState;
    column: Column;
}) {
    const ref = React.useRef<HTMLElement>(null);

    const tags = getTagsByColumnId(tagsState, column.id);

    if (tags.length === 0) {
        return <TableCell key={column.id} />;
    }
    return (
        <TableCell ref={ref} key={column.id} style={{ verticalAlign: 'top' }}>
            <Flexbox gap={4} flexDirection={'row'} flexWrap="wrap" justifyContent={'flex-start'}>
                {tags.map((columnTag) => {
                    return (
                        <Tooltip
                            title={getTooltipForChip({ label: columnTag.label })}
                            placement="top"
                            key={columnTag.id}
                        >
                            <Box maxWidth="130px">
                                <Chip color={'neutral'} label={columnTag.label} />
                            </Box>
                        </Tooltip>
                    );
                })}
            </Flexbox>
        </TableCell>
    );
});

function getTooltipForChip({ label }: { label: string }) {
    const tooltipForAcronym = getTooltipForAcronym(label);
    if (!!tooltipForAcronym) {
        return tooltipForAcronym;
    }
    return label.length > 19 ? label : '';
}

const isHeaderRowEmpty = (headerRowJson: RowRecord | undefined) => {
    if (!headerRowJson) return true;
    return Object.keys(headerRowJson).length === 0;
};

// if header row is empty, don't display the column names
const defaultEmptyHeaderColumns: Column[] = [];
const EmbeddedTableHeaderRow = React.memo(function EmbeddedTableHeaderRow({
    columns,
    tagsState,
    headerRowJson,
    columnMap,
    isColumnMap,
}: {
    columns: Column[];
    tagsState: ColumnTagsSheetsState;
    headerRowJson: RowRecord | undefined;
    columnMap: ColumnMap | null;
    isColumnMap: boolean;
}) {
    const isHeaderEmpty = isHeaderRowEmpty(headerRowJson);

    const columnsToDisplay = React.useMemo(() => {
        return isHeaderEmpty && columnMap ? generateColumnsFromColumnMap(columnMap) : columns;
    }, [columnMap, columns, isHeaderEmpty]);

    // if header row is empty, don't display the column names
    const columnNameCells = isHeaderEmpty ? defaultEmptyHeaderColumns : columns;

    return (
        <TableHead>
            {isColumnMap && (
                <TableRow>
                    {columnsToDisplay.map((column) => {
                        return <EmbeddedTableTagCell key={column.id} column={column} tagsState={tagsState} />;
                    })}
                </TableRow>
            )}
            <TableRow>
                {columnNameCells.map((column) => {
                    return (
                        <ColumnNameCell key={column.id}>
                            <Text
                                key={column.id}
                                variant="body-small"
                                style={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 5,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {column.label}
                            </Text>
                        </ColumnNameCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
});

const BomTableRow = React.memo(function BomTableRow({
    cellContents,
    lineNumber,
    highlightedColumns,
}: {
    lineNumber?: number;
    cellContents: HighlightableString[];
    highlightedColumns: number[];
}) {
    return (
        <TableRow>
            {lineNumber !== undefined && (
                <StyledCell>
                    <Typography>{lineNumber}</Typography>
                </StyledCell>
            )}
            {cellContents.map((content, index: number) => {
                return (
                    <EmbeddedTableCell
                        highlightedColumns={highlightedColumns}
                        index={index}
                        key={index}
                        content={content}
                    />
                );
            })}
        </TableRow>
    );
});

function FadeOut({
    isVisible,
    onHide,
    children,
    style,
}: React.PropsWithChildren<{
    isVisible: boolean;
    onHide: () => void;
    style?: React.CSSProperties;
}>) {
    React.useEffect(() => {
        let timeoutId: undefined | ReturnType<typeof setTimeout> = undefined;
        if (isVisible) {
            timeoutId = setTimeout(() => {
                onHide();
            }, 700);
        }
        return () => timeoutId && clearTimeout(timeoutId);
    }, [isVisible, onHide]);

    if (!isVisible) {
        return null;
    }
    return <span style={style}>{children}</span>;
}

const StyledTableContainer = styled(TableContainer)({
    overflow: 'visible',
});

const border = `1px solid ${colorSystem.neutral[2]}`;
const cellWidth = 128;
const smallCellWidth = 32;

const StyledTable = styled(Table)({
    width: '100%',
    background: colorSystem.neutral[0],

    '& td': {
        borderRight: border,
        width: cellWidth,
        maxWidth: cellWidth,
        minWidth: cellWidth,
        overflowWrap: 'break-word',
        verticalAlign: 'top',
        padding: '4px',
    },
    '& tr': {
        borderBottom: border,
    },
    '& th': {
        borderBottom: border,
        borderRight: border,
        width: cellWidth,
        maxWidth: cellWidth,
        minWidth: cellWidth,
        overflowWrap: 'break-word',

        padding: '4px',
    },
    '& td:first-of-type, th:first-of-type ': {
        width: smallCellWidth,
        maxWidth: smallCellWidth,
        minWidth: smallCellWidth,
        textAlign: 'center',
    },
    '& tr:nth-of-type(1) th:nth-of-type(1)': {
        borderBottom: 'none',
    },
    '& td:last-child, th:last-child': {
        borderBottom: 'none',
    },
    borderSpacing: 0,
    borderCollapse: 'separate',
    margin: 0,
});

function highlightCandidatesInExcelRow({
    cellContents,
    allCandidates,
}: {
    cellContents: string[];
    allCandidates: string[];
}): HighlightableString[] {
    return cellContents.map((content: string): HighlightableString => {
        return findBestHighlightMatch(content, allCandidates);
    });
}

export const EmbeddedTable = React.memo(function EmbeddedTable({
    headerRowJson,
    excelRows,
    allCandidatesForHighlights,
    highlightedColumnNamesPartSuggestions,
    tagsState,
    showLineNumberColumn = false,
    columnMap,
    formReturn,
    isColumnMap,
}: EmbeddedTableProps) {
    const highlightedExcelRows = React.useMemo(() => {
        return excelRows.map((row) => {
            const parsedRow: string[] | undefined = parseRawOriginalLine(row.raw_original_line);
            const lineNumber = showLineNumberColumn ? row.line_number : undefined;
            if (parsedRow === undefined) {
                return {
                    lineNumber,
                    cellContents: undefined,
                };
            }
            return {
                lineNumber,
                cellContents: highlightCandidatesInExcelRow({
                    cellContents: parsedRow,
                    allCandidates: allCandidatesForHighlights ?? [],
                }),
            };
        });
    }, [allCandidatesForHighlights, excelRows, showLineNumberColumn]);

    const { field } = useController({
        // eslint-disable-next-line spellcheck/spell-checker
        name: 'otsPart.data.part_options',
        control: formReturn.control,
        defaultValue: [],
    });
    const partOptions: PartOptionDTO[] = field.value;

    const highlightedColumnNamesPartOptions = React.useMemo(() => {
        return partOptions.map((option: PartOptionDTO) => option?.origin?.column || undefined).filter(notUndefined);
    }, [partOptions]);

    const highlightedColumns = React.useMemo(() => {
        const highlightedColumnNames = uniq(
            highlightedColumnNamesPartSuggestions.concat(highlightedColumnNamesPartOptions),
        );
        let highlightedColumnIndices: number[] = [];
        for (const columnName of highlightedColumnNames) {
            if (columnMap !== null) {
                highlightedColumnIndices = highlightedColumnIndices.concat(
                    getValueOf(columnMap, columnName.toLowerCase()) || [],
                );
            }
        }
        return highlightedColumnIndices;
    }, [columnMap, highlightedColumnNamesPartOptions, highlightedColumnNamesPartSuggestions]);

    const columns = React.useMemo(() => {
        return [{ id: 'lineNumberColumn', label: '' }].concat(
            parseColumns({
                rawHeaderRow: headerRowJson,
                excelRows,
                columnMap,
            }),
        );
    }, [columnMap, excelRows, headerRowJson]);

    return (
        <StyledTableContainer>
            <StyledTable size="small">
                <EmbeddedTableHeaderRow
                    columns={columns}
                    tagsState={tagsState}
                    headerRowJson={headerRowJson}
                    columnMap={columnMap}
                    isColumnMap={isColumnMap}
                />
                <TableBody>
                    {highlightedExcelRows.map(
                        (row, key) =>
                            row.cellContents && (
                                <BomTableRow
                                    key={key}
                                    highlightedColumns={highlightedColumns}
                                    lineNumber={row.lineNumber}
                                    cellContents={row.cellContents}
                                />
                            ),
                    )}
                </TableBody>
            </StyledTable>
        </StyledTableContainer>
    );
});
