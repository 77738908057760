import * as rt from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { PCBFileTypesRuntimeType, PcbServerErrorTypeRuntype } from './pcbBackendTypes';

const PCBGraphicPositionRuntype = rt.Record({
    x: rt.Number,
    y: rt.Number,
});

export type PCBGraphicPosition = rt.Static<typeof PCBGraphicPositionRuntype>;

const PCBGraphicDimensionRuntype = rt.Record({
    min: PCBGraphicPositionRuntype,
    max: PCBGraphicPositionRuntype,
});

export type PCBGraphicDimension = rt.Static<typeof PCBGraphicDimensionRuntype>;

const PCBGraphicFormatRuntype = rt.Record({
    unit: rt.String,
    resolution: rt.Number,
    dimension: PCBGraphicDimensionRuntype.optional(),
    complexity: rt.Number.optional(),
    scaling: rt.Number,
    gerberscale: rt.Number.optional(),
});

export type PCBGraphicFormat = rt.Static<typeof PCBGraphicFormatRuntype>;

const PCBGraphicFileTypeRuntype = rt.Record({
    service: rt.String,
    category: rt.String,
    fileType: PCBFileTypesRuntimeType,
    productionFile: rt.Boolean,
    mimeType: rt.String,
    index: rt.Number.optional(),
});

const PCBGraphicMetaInfoRuntype = rt.Record({
    trace_width: rt.Number.optional(),
    copper_clearance: rt.Number.optional(),
});

export type PCBGraphicMetaInfo = rt.Static<typeof PCBGraphicMetaInfoRuntype>;

const PCBGraphicFileRuntype = rt.Record({
    id: rt.String,
    name: rt.String,
    format: PCBGraphicFormatRuntype,
    fileType: PCBGraphicFileTypeRuntype,
    metaInfo: PCBGraphicMetaInfoRuntype,
    inverted: rt.Boolean,
});

export type PCBGraphicFile = rt.Static<typeof PCBGraphicFileRuntype>;

const PCBGraphicPathDefUseRuntype = rt.Record({
    reference: rt.String,
    location: PCBGraphicPositionRuntype,
});

export type PCBGraphicPathDefUse = rt.Static<typeof PCBGraphicPathDefUseRuntype>;

const PCBGraphicPathDefRuntype = rt.Record({
    id: rt.String,
    path: rt.String,
});

export type PCBGraphicPathDef = rt.Static<typeof PCBGraphicPathDefRuntype>;

const PCBGraphicPathRuntype = rt.Record({
    use: PCBGraphicPathDefUseRuntype.optional(),
    elementIds: rt.Array(rt.String).optional(),
    trace: rt.String.optional(),
    path: rt.String.optional(),
    orientation: rt
        .Record({
            degrees: rt.Number,
            mirrored: rt.Boolean,
        })
        .optional(),
});

export type PCBGraphicPath = rt.Static<typeof PCBGraphicPathRuntype>;

const PCBGraphicCopperJsonRuntype = rt.Record({
    viewbox: PCBGraphicDimensionRuntype,
    format: PCBGraphicFormatRuntype,
    count: rt.Number,
    paths: rt.Array(rt.Array(PCBGraphicPathRuntype)),
    defs: rt.Array(PCBGraphicPathDefRuntype),
});

export type PCBGraphicCopperJson = rt.Static<typeof PCBGraphicCopperJsonRuntype>;

const PCBGraphicLayerDefinitionMetaRuntype = rt.Record({
    side: rt.String,
    polarity: rt.String,
    displayname: rt.String,
    cucount: rt.Number.optional(),
});

export type PCBGraphicLayerDefinitionMeta = rt.Static<typeof PCBGraphicLayerDefinitionMetaRuntype>;

const PCBGraphicLayerDefinitionMaterialMetaRuntype = rt.Record({
    description: rt.String.optional(),
    supplier: rt.String,
    cuthickness: rt.Number.optional(),
    type: rt.String,
    supplierdescription: rt.String,
});

export type PCBGraphicLayerDefinitionMaterialMeta = rt.Static<typeof PCBGraphicLayerDefinitionMaterialMetaRuntype>;

const PCBGraphicLayerDefinitionMaterialRuntype = rt.Record({
    meta: PCBGraphicLayerDefinitionMaterialMetaRuntype,
    materialType: rt.String,
});

export type PCBGraphicLayerDefinitionMaterial = rt.Static<typeof PCBGraphicLayerDefinitionMaterialRuntype>;

export enum PCBLayerTypes {
    SOLDERMASK = 'soldermask',
    FOIL = 'foil',
    PREPREG = 'prepreg',
    CORE = 'core',
    FLEXCORE = 'flexcore',
    DIELECTRIC = 'dielectric',
}

const PCBLayerTypesRuntype = runtypeFromEnum(PCBLayerTypes);

const PCBGraphicLayerDefinitionRuntype = rt.Record({
    id: rt.String,
    layerType: PCBLayerTypesRuntype,
    meta: PCBGraphicLayerDefinitionMetaRuntype,
    material: PCBGraphicLayerDefinitionMaterialRuntype,
});

export type PCBGraphicLayerDefinition = rt.Static<typeof PCBGraphicLayerDefinitionRuntype>;

const PCBGraphicLayerRuntype = rt.Record({
    definition: PCBGraphicLayerDefinitionRuntype.optional(),
    file: PCBGraphicFileRuntype.optional(),
    graphic: rt
        .Record({
            copper_json: PCBGraphicCopperJsonRuntype,
        })
        .optional(),
});

export type PCBGraphicLayer = rt.Static<typeof PCBGraphicLayerRuntype>;

const PCBGraphicDrillHoleToolRuntype = rt.Record({
    name: rt.String,
    drillType: rt.Union(rt.Literal('PLATED'), rt.Literal('NON_PLATED')),
    diameter: rt.Number,
    drills: rt.Array(PCBGraphicPositionRuntype),
});

export type PCBGraphicDrillHoleTool = rt.Static<typeof PCBGraphicDrillHoleToolRuntype>;

const PCBGraphicDrillHoleRuntype = rt.Record({
    tools: rt.Array(PCBGraphicDrillHoleToolRuntype),
    scaling: rt.Number,
    fileUnit: rt.String.optional(),
});

export type PCBGraphicDrillHole = rt.Static<typeof PCBGraphicDrillHoleRuntype>;

const PCBGraphicOutlineRuntype = rt.Record({
    id: rt.String,
    file: rt.String,
    userChoice: rt.Boolean,
    metaInfo: PCBGraphicMetaInfoRuntype,
    graphic: PCBGraphicCopperJsonRuntype,
    score: rt.Number,
});

export type PCBGraphicOutline = rt.Static<typeof PCBGraphicOutlineRuntype>;

export const PCBGraphicsRuntype = rt.Record({
    layers: rt.Array(PCBGraphicLayerRuntype),
    outline: PCBGraphicOutlineRuntype,
    drillHoles: rt.Array(PCBGraphicDrillHoleRuntype),
});

export enum PCBGraphicType {
    LAYER = 'layer',
    OUTLINE = 'outline',
    DRILLS = 'drills',
}

export const WrapperPCBGraphicLayerRuntype = rt.Record({
    type: rt.Literal(PCBGraphicType.LAYER),
    data: PCBGraphicLayerRuntype,
    graphic: rt
        .Record({
            copper_json: PCBGraphicCopperJsonRuntype,
        })
        .optional(),
});

export const WrapperPCBGraphicOutlineRuntype = rt.Record({
    type: rt.Literal(PCBGraphicType.OUTLINE),
    data: PCBGraphicOutlineRuntype,
});

export const WrapperPCBGraphicDrillHoleRuntype = rt.Record({
    type: rt.Literal(PCBGraphicType.DRILLS),
    data: rt.Array(PCBGraphicDrillHoleRuntype),
});

export const WrapperPCBGraphicRuntype = rt.Union(
    WrapperPCBGraphicLayerRuntype,
    WrapperPCBGraphicOutlineRuntype,
    WrapperPCBGraphicDrillHoleRuntype,
);

export const PCBGraphicsArrayRuntype = rt.Array(WrapperPCBGraphicRuntype);

export type PCBGraphics = rt.Static<typeof PCBGraphicsRuntype>;
export type PCBGraphicsArray = rt.Static<typeof PCBGraphicsArrayRuntype>;

const PCBGraphicsSuccessRuntype = rt.Record({
    status: rt.Literal('ok'),
    data: PCBGraphicsArrayRuntype,
});

const PCBGraphicsErrorRuntype = rt.Record({
    status: rt.Literal('error'),
    error: PcbServerErrorTypeRuntype,
});

export const PCBGraphicsResponseRuntype = rt.Union(PCBGraphicsSuccessRuntype, PCBGraphicsErrorRuntype);

export type PCBGraphicsResponse = rt.Static<typeof PCBGraphicsResponseRuntype>;
