import { t } from '@lingui/macro';
import { MenuItem } from '@luminovo/design-system';
import { Column, GridApi } from 'ag-grid-community';

export function MenuItemHideColumn<T>({
    column,
    api,
    onClose,
}: {
    column: Column<T>;
    api: GridApi<T>;
    onClose: () => void;
}): JSX.Element {
    if (column.getColDef().lockVisible) {
        return <></>;
    }
    return (
        <MenuItem
            onClick={() => {
                api.setColumnsVisible([column], false);
                onClose();
            }}
            label={t`Hide column`}
        />
    );
}
