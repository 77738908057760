import { GridApi, SortDirection } from 'ag-grid-community';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AgGridFilter, DataGridRow } from '../types';

interface DataGridContextValue<T> {
    api: GridApi<T> | undefined;
    setApi: (ref: GridApi<T> | undefined) => void;

    quickFilter: string;
    setQuickFilter: (filter: string) => void;

    rowData: T[];
    setRowData: (rowData: T[]) => void;
}

const DataGridContext = createContext<DataGridContextValue<any> | undefined>(undefined);

export function useDataGridContext<T = DataGridRow>(): DataGridContextValue<T> {
    const context = useContext(DataGridContext);
    if (!context) {
        throw new Error('useDataGridContext must be used within a DataGridContextProvider');
    }
    return context;
}

export function useColumnFilter<T = DataGridRow>(columnId: string): AgGridFilter | undefined {
    const { api } = useDataGridContext<T>();
    const column = api?.getColumn(columnId) ?? undefined;
    const initialFilter = column ? (api?.getColumnFilterModel<AgGridFilter>(column) ?? undefined) : undefined;
    const [filter, setFilter] = useState<AgGridFilter | undefined>(initialFilter);

    useEffect(() => {
        api?.addEventListener('filterChanged', () => {
            if (!column) {
                return;
            }
            const newFilter = api?.getColumnFilterModel<AgGridFilter>(column) ?? undefined;
            setFilter(newFilter);
        });
    }, [api, column]);

    return filter;
}

export function useColumnSort<T = DataGridRow>(columnId: string): SortDirection | undefined {
    const { api } = useDataGridContext<T>();
    const column = api?.getColumn(columnId) ?? undefined;
    const initialSort = column?.getSort() ?? undefined;
    const [sort, setSort] = useState<SortDirection | undefined>(initialSort);

    useEffect(() => {
        api?.addEventListener('sortChanged', () => {
            if (!column) {
                return;
            }
            const newSort = column.getSort();
            setSort(newSort);
        });
    }, [api, column]);

    return sort;
}

export function DataGridContextProvider<T>({
    children,
    initialData,
}: React.PropsWithChildren<{ initialData: T[] }>): JSX.Element {
    const [api, setApi] = useState<GridApi<T> | undefined>(undefined);
    const [quickFilter, setQuickFilter] = useState<string>('');
    const [rowData, setRowData] = useState<T[]>(initialData);
    const memoizedValue = useMemo(
        () => ({ api, setApi, quickFilter, setQuickFilter, rowData, setRowData }),
        [api, setApi, quickFilter, setQuickFilter, rowData, setRowData],
    );
    return <DataGridContext.Provider value={memoizedValue}>{children}</DataGridContext.Provider>;
}

const noop = () => {};
export function DataGridReadonlyContextProvider<T>({
    children,
    rowData,
}: React.PropsWithChildren<{ rowData: T[] }>): JSX.Element {
    const [api, setApi] = useState<GridApi<T> | undefined>(undefined);
    const [quickFilter, setQuickFilter] = useState<string>('');
    const memoizedValue = useMemo(
        () => ({ api, setApi, quickFilter, setQuickFilter, rowData, setRowData: noop }),
        [api, setApi, quickFilter, setQuickFilter, rowData],
    );
    return <DataGridContext.Provider value={memoizedValue}>{children}</DataGridContext.Provider>;
}
