import { t, Trans } from '@lingui/macro';
import { formatInteger, isPresent } from '@luminovo/commons';
import { ButtonGroup, ButtonGroupItem, colorSystem, Flexbox, Message, Text } from '@luminovo/design-system';
import { DemandSummaryDTO, ParticipantDemandSummaryDTO } from '@luminovo/http-client';
import { FilterDemandsButton } from '@luminovo/manufacturing-core';
import { Box, Skeleton } from '@mui/material';
import { useState } from 'react';
import { DemandSummaryTableDynamic } from './DemandSummaryTable';
import { DemandTimeframeType, DemandType, ParticipantRole } from './types';

export type ButtonGroupsReturnType = {
    participantRole: ParticipantRole;
    handleParticipantRoleClick: ((newValue: ParticipantRole) => void) | undefined;
    demandTimeframeType: DemandTimeframeType;
    handleDemandTimeframeTypeClick: ((newValue: DemandTimeframeType) => void) | undefined;
    demandType: DemandType;
    handleDemandTypeClick: ((newValue: DemandType) => void) | undefined;
};

export const useButtonGroups = ({ initialDemandType }: { initialDemandType: DemandType }): ButtonGroupsReturnType => {
    const [participantRole, setParticipantRole] = useState<ParticipantRole>('recipient');
    const handleParticipantRoleClick = (newValue: ParticipantRole) => {
        setParticipantRole(newValue);
    };

    const [demandTimeframeType, setDemandTimeframeType] = useState<DemandTimeframeType>('monthly');
    const handleDemandTimeframeTypeClick = (newValue: DemandTimeframeType) => {
        setDemandTimeframeType(newValue);
    };

    const [demandType, setDemandType] = useState<DemandType>(initialDemandType);
    const handleDemandTypeClick = (newValue: DemandType) => {
        setDemandType(newValue);
    };

    return {
        participantRole,
        handleParticipantRoleClick,
        demandTimeframeType,
        handleDemandTimeframeTypeClick,
        demandType,
        handleDemandTypeClick,
    };
};

export const ButtonGroups = ({
    buttonGroupsReturnType,
}: {
    buttonGroupsReturnType: ButtonGroupsReturnType;
}): JSX.Element => {
    const {
        participantRole,
        handleParticipantRoleClick,
        demandTimeframeType,
        handleDemandTimeframeTypeClick,
        demandType,
        handleDemandTypeClick,
    } = buttonGroupsReturnType;

    return (
        <Flexbox justifyContent={'space-between'}>
            <Flexbox gap={'20px'}>
                {isPresent(handleParticipantRoleClick) && (
                    <ButtonGroup size="small">
                        <ButtonGroupItem
                            key={'recipient'}
                            selected={participantRole === 'recipient'}
                            onClick={() => handleParticipantRoleClick('recipient')}
                        >
                            <Trans>Recipient</Trans>
                        </ButtonGroupItem>
                        <ButtonGroupItem
                            key={'supplier'}
                            selected={participantRole === 'supplier'}
                            onClick={() => handleParticipantRoleClick('supplier')}
                        >
                            <Trans>Supplier</Trans>
                        </ButtonGroupItem>
                    </ButtonGroup>
                )}
                {isPresent(handleDemandTypeClick) && (
                    <ButtonGroup size="small">
                        <ButtonGroupItem
                            key={'net'}
                            selected={demandType === 'net'}
                            onClick={() => handleDemandTypeClick('net')}
                        >
                            <Trans>Net</Trans>
                        </ButtonGroupItem>
                        <ButtonGroupItem
                            key={'gross'}
                            selected={demandType === 'gross'}
                            onClick={() => handleDemandTypeClick('gross')}
                        >
                            <Trans>Gross</Trans>
                        </ButtonGroupItem>
                    </ButtonGroup>
                )}
                {isPresent(handleDemandTimeframeTypeClick) && (
                    <ButtonGroup size="small">
                        <ButtonGroupItem
                            key={'yearly'}
                            selected={demandTimeframeType === 'yearly'}
                            onClick={() => handleDemandTimeframeTypeClick('yearly')}
                        >
                            <Trans>Yearly</Trans>
                        </ButtonGroupItem>
                        <ButtonGroupItem
                            key={'monthly'}
                            selected={demandTimeframeType === 'monthly'}
                            onClick={() => handleDemandTimeframeTypeClick('monthly')}
                        >
                            <Trans>Monthly</Trans>
                        </ButtonGroupItem>
                    </ButtonGroup>
                )}
            </Flexbox>
        </Flexbox>
    );
};

const ParticipantsHorizontalList = ({
    participantDemands,
    demandType,
}: {
    participantDemands: ParticipantDemandSummaryDTO[];
    demandType: DemandType;
}) => {
    return (
        <Flexbox gap={'40px'}>
            {participantDemands.map((participant) => {
                const participantName = participant.participant_name;
                return (
                    <Flexbox key={participantName} flexDirection={'column'}>
                        <Flexbox alignItems={'center'} gap={4}>
                            <Text variant="body-small" color={colorSystem.neutral[8]}>
                                {participantName}
                            </Text>
                        </Flexbox>
                        <Text variant="h4" color={colorSystem.neutral[9]}>
                            {formatInteger(
                                demandType === 'gross'
                                    ? participant.total_gross_demand.quantity
                                    : participant.total_net_demand.quantity,
                            )}
                        </Text>
                    </Flexbox>
                );
            })}
        </Flexbox>
    );
};

export const DemandSummary = ({
    buttonGroups,
    demandSummary,
    onClickFilterDemandButton,
    isFiltered,
}: {
    buttonGroups: ButtonGroupsReturnType;
    demandSummary: DemandSummaryDTO | undefined;
    onClickFilterDemandButton: () => void;
    isFiltered: boolean;
}) => {
    const participantsExist = isPresent(demandSummary) && demandSummary.ship_to_site_demands.length > 0;
    return (
        <Flexbox
            sx={{
                backgroundColor: colorSystem.neutral.white,
                paddingY: '24px',
                paddingX: '20px',
            }}
            flexDirection={'column'}
            gap={'16px'}
        >
            <Flexbox width={'100%'} justifyContent={'space-between'}>
                <Text color={colorSystem.neutral[8]} variant="h3">
                    <Trans>Summary</Trans>
                </Text>
                <FilterDemandsButton isFiltered={isFiltered} onClick={onClickFilterDemandButton} />
            </Flexbox>
            <Box marginBottom={1}>
                <Message
                    message={t`We currently only show gross demand for assemblies.`}
                    variant="blue"
                    attention="low"
                    size="small"
                />
            </Box>
            <ButtonGroups buttonGroupsReturnType={buttonGroups} />
            {demandSummary ? (
                <>
                    <Flexbox flexDirection={'column'}>
                        <Text color={colorSystem.neutral[7]} variant="h5">
                            <Trans>Total demand</Trans>
                        </Text>
                        <Text color={colorSystem.neutral[9]} variant="h1">
                            {formatInteger(
                                buttonGroups.demandType === 'gross'
                                    ? demandSummary.total_gross_demand.quantity
                                    : demandSummary.total_net_demand.quantity,
                            )}
                        </Text>
                    </Flexbox>
                    <ParticipantsHorizontalList
                        participantDemands={
                            buttonGroups.participantRole === 'recipient'
                                ? demandSummary.ship_to_site_demands
                                : demandSummary.supplier_demands
                        }
                        demandType={buttonGroups.demandType}
                    />
                    {participantsExist && (
                        <Box sx={{ height: '200px', marginTop: '28px' }}>
                            <DemandSummaryTableDynamic
                                demandSummary={demandSummary}
                                demandType={buttonGroups.demandType}
                                participantRole={buttonGroups.participantRole}
                                timeframeType={buttonGroups.demandTimeframeType}
                            />
                        </Box>
                    )}
                </>
            ) : (
                <Flexbox flexDirection={'column'} gap={12}>
                    <Skeleton variant="rectangular" height={50} width={200} />
                    <Skeleton variant="rectangular" height={100} />
                </Flexbox>
            )}
        </Flexbox>
    );
};
