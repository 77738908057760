import { t } from '@lingui/macro';
import { colorSystem } from '@luminovo/design-system';
import { OrderConfirmedIcon } from '../../../components/OrderConfirmedIcon';
import { BannerCard } from './BannerCard';
import { DownloadQuotationButton } from './DownloadQuotationButton';
import { GREEN_GRADIENT } from './gradients';

export const BannerCardOrderConfirmed = ({
    orgName,
    rfqId,
}: {
    orgName: string | undefined;
    rfqId: string;
}): JSX.Element => {
    const emsName = orgName || 'EMS';

    return (
        <BannerCard
            background={GREEN_GRADIENT}
            title={t`Your order has been confirmed.`}
            description={emsName + ' ' + t`has confirmed your purchase.`}
            icon={<OrderConfirmedIcon />}
            callToAction={
                <DownloadQuotationButton
                    rfqId={rfqId}
                    color={colorSystem.neutral.white}
                    background="rgba(255, 255, 255, .3)"
                    hideIfNoQuotation
                />
            }
        />
    );
};
