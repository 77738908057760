import { AgGridReact, AgGridReactProps } from 'ag-grid-react';

import { colorSystem, NonIdealState } from '@luminovo/design-system';
import * as icons from '@mui/icons-material';
import { CellClickedEvent } from 'ag-grid-community';
import { useDataGridContext } from './components/DataGridContextProvider';
import { HeaderComponent } from './components/HeaderComponent';
import './registerModules';
import { themeEditableGrid } from './theme';

export interface DataGridProps<T> extends Omit<AgGridReactProps<T>, 'onRowClicked'> {
    editable?: boolean;
    /**
     * We override the default behavior of the row click to allow for the row to be clickable
     * while still allowing some columns to override this behaviour.
     */
    onRowClicked?: (event: CellClickedEvent<T>) => void;
}

export function DataGrid<T>({
    columnDefs = [],
    editable = true,
    theme = themeEditableGrid,
    onRowClicked,
    ...props
}: DataGridProps<T>): JSX.Element {
    const { setApi, rowData, setRowData, quickFilter } = useDataGridContext<T>();

    return (
        <AgGridReact<T>
            // default to no visible row selection
            rowSelection={undefined}
            quickFilterText={quickFilter}
            suppressScrollWhenPopupsAreOpen
            suppressScrollOnNewData
            onGridReady={(event) => {
                setApi(event.api);
            }}
            noRowsOverlayComponent={() => (
                <>
                    <NonIdealState title="No rows" description="No rows" />
                </>
            )}
            icons={{
                menu: icons.AcUnit,
            }}
            overlayNoRowsTemplate="no rows???"
            pinnedBottomRowData={[]}
            defaultColDef={{
                onCellClicked: (event) => {
                    onRowClicked?.(event);
                },
                editable,
                cellEditorPopup: false,
                filter: true,
                headerComponent: HeaderComponent,
                cellStyle: ({ value }) => ({
                    color:
                        value === null || value === undefined || value === '-'
                            ? colorSystem.neutral[6]
                            : colorSystem.neutral[8],
                }),
            }}
            onCellValueChanged={(event) => {
                if (event.rowIndex === -1 || event.rowIndex === null || event.data === undefined) {
                    return;
                }
                const clonedRowData = [...rowData];
                clonedRowData[event.rowIndex] = event.data;
                setRowData(clonedRowData);
            }}
            theme={theme}
            columnDefs={columnDefs}
            animateRows={true}
            singleClickEdit={true}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
            rowData={rowData}
            {...props}
        />
    );
}
