import { t } from '@lingui/macro';
import { Column, columnWidth, DataTable, Row, useDataTableState, useNavigate } from '@luminovo/design-system';
import { CalculationTemplateResponseDTO } from '@luminovo/http-client';
import { ManufacturingDatabaseTableCell, TextRow } from '@luminovo/manufacturing-core';
import { CircularProgress, TableCell } from '@mui/material';
import React from 'react';
import { useIsAdminUser } from '../../../../../components/contexts/CurrentUserDetailsContext';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { CIRCULAR_PROGRESS_BUTTON_SIZE } from '../../../../../themes';
import { route } from '../../../../../utils/routes';
import CalculationTemplateMenuComponent from './CalculationTemplateMenuComponent';
const CalculationTemplatesTable = (): JSX.Element => {
    const { data: calculationTemplatesData, isLoading } = useHttpQuery('GET /calculations/templates', {});
    const isAdmin = useIsAdminUser();

    const columns: Column<CalculationTemplateResponseDTO>[] = [
        {
            label: t`Template name`,
            id: 'templateNameColumn',
            render: ({ data: rowData }: Row<CalculationTemplateResponseDTO>) => (
                <ManufacturingDatabaseTableCell>
                    <TextRow text={rowData.name} />
                </ManufacturingDatabaseTableCell>
            ),
        },
        {
            label: '',
            id: 'calculationTemplateActionMenuColumn',
            render: ({ data: rowData }: Row<CalculationTemplateResponseDTO>): JSX.Element => {
                return isAdmin ? <CalculationTemplateMenuComponent templateId={rowData.id} /> : <TableCell />;
            },
            width: columnWidth.small,
        },
    ];

    const tableState = useDataTableState({
        columns: columns,
        items: calculationTemplatesData?.data ?? [],
        persistenceId: 'calculationTemplatesTable',
        paginationOptions: {
            showPagination: true,
            persistPagination: true,
        },
    });

    const navigate = useNavigate();
    const handleItemClick = React.useCallback(
        (calculationTemplate: CalculationTemplateResponseDTO) => {
            navigate(route('/calculation/templates/:templateId/edit', { templateId: calculationTemplate.id }));
        },
        [navigate],
    );
    return isLoading ? (
        <CircularProgress size={CIRCULAR_PROGRESS_BUTTON_SIZE} color="primary" />
    ) : (
        <DataTable
            size={'medium'}
            onItemClick={isAdmin ? handleItemClick : undefined}
            tableState={tableState}
            key={'calculationTemplatesTable'}
        />
    );
};

export default CalculationTemplatesTable;
