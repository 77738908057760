/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type OnlineComponentsPriceTypeDTO = r.Static<typeof OnlineComponentsPriceTypeDTORuntype>;
export const OnlineComponentsPriceTypeDTORuntype = r.Union(r.Literal('ListPrice'), r.Literal('ContractPrice'));

export interface OnlineComponentsCredentialsDTO extends r.Static<typeof OnlineComponentsCredentialsDTORuntype> {}
export const OnlineComponentsCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
        price_type: OnlineComponentsPriceTypeDTORuntype,
    }),
);

export interface OnlineComponentsCredentialsInputDTO
    extends r.Static<typeof OnlineComponentsCredentialsInputDTORuntype> {}
export const OnlineComponentsCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
    price_type: OnlineComponentsPriceTypeDTORuntype.optional(),
});

export interface OnlineComponentsResponseBodyDTO extends r.Static<typeof OnlineComponentsResponseBodyDTORuntype> {}
export const OnlineComponentsResponseBodyDTORuntype = r.Record({
    data: OnlineComponentsCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
