import { useSuspenseHttpQuery } from '@/resources/http/useHttpQuery';
import { t } from '@lingui/macro';
import { Currency, isEqual, isPresent } from '@luminovo/commons';
import { Dropzone } from '@luminovo/design-system';
import { QuantityUnit } from '@luminovo/http-client';
import { parse } from '@luminovo/parsers';
import { useMutation } from '@tanstack/react-query';
import { ParsedObjectsResult, Row, Schema } from 'read-excel-file';
import { DomainError, useDebugErrorHandler } from '../../../resources/http/debugErrorHandler';
import {
    HeaderToPropsMap,
    StandardPartQuoteHeader,
    StandardPartQuoteImportResult,
    StandardPartQuoteLines,
} from './types';

const HEADER_HEIGHT = 2;

const standardPartSchema: Schema = {
    [StandardPartQuoteHeader.LumiQuoteId]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.LumiQuoteId],
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.Ipn]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.Ipn],
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.Description]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.Description],
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.Manufacturer]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.Manufacturer],
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.ManufacturerPartNumber]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.ManufacturerPartNumber],
        type: String,
        required: false,
    },
    // Missing column: Ship to
    [StandardPartQuoteHeader.RequiredQuantity]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.RequiredQuantity],
        required: false,
        type: convertPositiveNumber,
    },
    // Missing column: Potential quantity
    // Missing column: Unit
    [StandardPartQuoteHeader.Unit]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.Unit],
        required: false,
        type: (value) => {
            switch (value) {
                case 'Kg':
                    return QuantityUnit.Kg;
                case 'Meters':
                    return QuantityUnit.Meters;
                case 'Liters':
                    return QuantityUnit.Liters;
                case 'Pieces':
                    return QuantityUnit.Pieces;
                default:
                    throw new Error('Unit not supported');
            }
        },
    },
    [StandardPartQuoteHeader.Bid]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.Bid],
        type: (value) => {
            const result = parse.boolean().parse(value.toString());
            if (result.ok) {
                return result.value;
            }
            return false;
        },
        required: false,
    },
    [StandardPartQuoteHeader.OfferedMpn]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.OfferedMpn],
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.OfferedManufacturer]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.OfferedManufacturer],
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.UnitPrice]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.UnitPrice],
        required: false,
        type: convertPositiveNumber,
    },
    [StandardPartQuoteHeader.UnitPriceQuantity]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.UnitPriceQuantity],
        required: false,
        type: (value) => {
            if (typeof value === 'number') {
                return convertPositiveNumber(value);
            }

            if (typeof value === 'string') {
                const match = value.match(/\d+/);
                if (match) {
                    return convertPositiveNumber(match[0]);
                }
            }

            throw new Error('Unit price quantity not supported');
        },
    },
    [StandardPartQuoteHeader.Currency]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.Currency],
        required: false,
        type: (value) => {
            return Object.values(Currency).find((currency) => currency.toString() === value.toString()) ?? null;
        },
    },

    [StandardPartQuoteHeader.SupplierPartNumber]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.SupplierPartNumber],
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.Packaging]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.Packaging],
        required: false,
        type: (value) => {
            const result = parse.packaging().parse(value.toString());
            if (result.ok) {
                return result.value;
            }
            return null;
        },
    },
    [StandardPartQuoteHeader.MinOrderQuantity]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.MinOrderQuantity],
        required: false,
        type: convertPositiveNumber,
    },
    [StandardPartQuoteHeader.MinPackagingQuantity]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.MinPackagingQuantity],
        required: false,
        type: convertPositiveNumber,
    },
    [StandardPartQuoteHeader.StdFactoryLeadTime]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.StdFactoryLeadTime],
        required: false,
        type: convertPositiveNumber,
    },
    [StandardPartQuoteHeader.StdFactoryLeadTimeUnit]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.StdFactoryLeadTimeUnit],
        type: String,
        required: false,
        oneOf: ['days', 'weeks'],
    },
    [StandardPartQuoteHeader.CancellationWindow]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.CancellationWindow],
        required: false,
        type: convertPositiveNumber,
    },
    [StandardPartQuoteHeader.CancellationTimeUnit]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.CancellationTimeUnit],
        required: false,
        type: String,
        oneOf: ['days', 'weeks'],
    },
    [StandardPartQuoteHeader.ItemClass]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.ItemClass],
        required: false,
        type: (value) => {
            const result = parse.itemClass().parse(value.toString());
            if (result.ok) {
                return result.value;
            }
            return null;
        },
    },
    [StandardPartQuoteHeader.Ncnr]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.Ncnr],
        required: false,
        type: (value) => {
            const result = parse.ncnr().parse(value.toString());
            if (result.ok) {
                return result.value;
            }
            return false;
        },
    },
    [StandardPartQuoteHeader.Stock]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.Stock],
        required: false,
        type: convertNumber,
    },
    [StandardPartQuoteHeader.ValidFrom]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.ValidFrom],
        type: String,
        required: false,
        parse: (cell) => {
            return convertToDateISOString(cell);
        },
    },
    [StandardPartQuoteHeader.ValidUntil]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.ValidUntil],
        type: String,
        required: false,
        parse: (cell) => {
            return convertToDateISOString(cell);
        },
    },
    [StandardPartQuoteHeader.Customer]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.Customer],
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.NegotiatedByCustomer]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.NegotiatedByCustomer],
        type: (value) => {
            const result = parse.boolean().parse(value.toString());
            if (result.ok) {
                return result.value;
            }
            return false;
        },
        required: false,
    },

    [StandardPartQuoteHeader.AdditionalNotes]: {
        prop: HeaderToPropsMap[StandardPartQuoteHeader.AdditionalNotes],
        type: String,
        required: false,
    },
};

function convertToDateISOString(cell: unknown) {
    if (cell instanceof Date) {
        return cell.toISOString().split('T')[0];
    }

    if (typeof cell === 'string') {
        const newDate = new Date(cell);
        if (!isNaN(newDate.getTime())) {
            return newDate.toISOString().split('T')[0];
        }
    }
    return undefined;
}

function convertToString(cell: unknown) {
    if (typeof cell === 'string') {
        return cell;
    }

    if (typeof cell === 'number') {
        return cell.toString();
    }

    if (cell instanceof Date) {
        return cell.toISOString();
    }

    return undefined;
}

function convertNumber(cell: unknown): number {
    if (typeof cell === 'string') {
        const parsed = Number(cell);
        if (!isNaN(parsed) && parsed >= 0) {
            return parsed;
        }
    } else if (typeof cell === 'number' && cell >= 0) {
        return cell;
    }

    throw new Error('Value is negative');
}

function convertPositiveNumber(cell: unknown): number {
    if (typeof cell === 'string') {
        const parsed = Number(cell);
        if (!isNaN(parsed) && parsed > 0) {
            return parsed;
        }
    } else if (typeof cell === 'number' && cell > 0) {
        return cell;
    }

    throw new Error('Value is not positive');
}

const detectSchema = (
    rows: Row[],
): { schema: 'standardPartQuote'; headerStart: number } | { schema: 'unknown'; headerStart: null } => {
    for (const [index, row] of rows.entries()) {
        const cellValues = row.map((cell) => convertToString(cell));

        if (cellValues.includes(StandardPartQuoteHeader.ManufacturerPartNumber)) {
            return { schema: 'standardPartQuote', headerStart: index };
        }
    }

    return { schema: 'unknown', headerStart: null };
};

function findStringBefore(searchString: string, rows: Row[]) {
    const flatRows = rows.flat();
    const position = flatRows.findIndex((cell) => isEqual(cell, searchString));

    if (position === -1 || position === 0) {
        return undefined;
    }

    return convertToString(flatRows[position - 1]);
}

function findStringAfter(searchString: string, rows: Row[]) {
    const flatRows = rows.flat();
    const position = flatRows.findIndex((cell) => isEqual(cell, searchString));

    if (position === -1 || flatRows.length <= position + 1) {
        return undefined;
    }

    return convertToString(flatRows[position + 1]);
}

export function useMutationUpload({ onSuccess }: { onSuccess: (data: StandardPartQuoteImportResult) => void }) {
    const onError = useDebugErrorHandler();
    const { data: quoteRequests } = useSuspenseHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {},
        },
        {
            select: (data) => data.items,
        },
    );

    return useMutation({
        mutationFn: async (files: File[]): Promise<StandardPartQuoteImportResult> => {
            const { default: readXlsxFile } = await import('read-excel-file');
            const { default: convertToJson } = await import('read-excel-file/schema');

            const rows = await readXlsxFile(files[0]);
            const { schema, headerStart } = detectSchema(rows);

            if (schema === 'unknown') {
                throw new DomainError({
                    code: 'excel_quote_importer.could_not_detect_schema',
                    message:
                        'The Excel file format was not recognized. Please use the standard quote template provided by Luminovo.',
                    context: { rows },
                });
            }

            const quoteRequestNumber = convertPositiveNumber(rows[0][0]);
            const quoteRequest = quoteRequests.find((qr) => qr.number === quoteRequestNumber);

            if (!isPresent(quoteRequest)) {
                throw new DomainError({
                    code: 'excel_quote_importer.could_not_find_quote_request',
                    message: `Quote request #${quoteRequestNumber ?? 'unknown'} was not found. Please verify the quote request number exists for this tenant.`,
                    context: { quoteRequestNumber },
                });
            }

            const sharedOfferInfo = {
                file: files[0],
                quoteRequestNumber,
                quoteRequest,
                supplierId: findStringBefore('Supplier', rows.slice(0, headerStart)),
                // We don't know the supplier and stock location id at this point
                // as it's not in the file. It should be in this file, but it's not.
                // For now we will fetch it in the frontend.
                supplierAndStockLocationId: undefined,
                supplierName: findStringAfter('Supplier:', rows.slice(0, headerStart)),
                offerNumber: findStringAfter('Offer number:', rows.slice(0, headerStart)),
            };

            const quoteLines = convertToJson(
                rows.slice(headerStart),
                standardPartSchema,
            ) as unknown as ParsedObjectsResult<StandardPartQuoteLines>;
            const quoteLineRow = quoteLines.rows.map((row, index) => ({
                type: 'StandardPartLine' as const,
                excelRowNumber: index + headerStart + HEADER_HEIGHT,
                row,
                warnings: quoteLines.errors.filter((error) => error.row === index + HEADER_HEIGHT),
            }));

            const result: StandardPartQuoteImportResult = { ...sharedOfferInfo, quoteLineRow };
            return result;
        },
        onError,
        onSuccess,
    });
}

export function ExcelQuoteImportDropZone({
    onSuccess,
    isLoading: isLoadingOuter,
}: {
    onSuccess: (data: StandardPartQuoteImportResult) => void;
    isLoading: boolean;
}) {
    const { mutateAsync, isPending } = useMutationUpload({
        onSuccess: (value) => {
            onSuccess(value);
        },
    });

    return (
        <Dropzone
            title={t`Excel quote importer`}
            onDropAccepted={(files) => mutateAsync(files)}
            multiple={false}
            isLoading={isPending || isLoadingOuter}
            accept={{
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            }}
        />
    );
}
