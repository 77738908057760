import { compareByStringKey, isPresent } from '@luminovo/commons';
import { chainComparators, compareByNumber } from '@luminovo/design-system';
import { DemandDTO, FullSourcingDTO, SourcingScenarioDTO } from '@luminovo/http-client';
import React from 'react';
import { useBulkQuery, useBulkSingleQuery } from '../batching/useBulkQuery';
import { useHttpQuery } from '../http/useHttpQuery';
import { useRfQ } from '../rfq/rfqHandler';
import { getTotalQuantity } from './getTotalQuantity';

export function useSourcingScenario(sourcingScenarioId: string | undefined) {
    return useBulkSingleQuery('POST /sourcing-scenarios/bulk', sourcingScenarioId, {
        idExtractor: (item: SourcingScenarioDTO) => item.id,
        httpOptions: (ids) => {
            return { requestBody: { ids } };
        },
        select: (res) => res.items,
    });
}

export function useSourcingScenarios(sourcingScenarioIds: string[] | undefined) {
    return useBulkQuery(
        'POST /sourcing-scenarios/bulk',
        sourcingScenarioIds,
        {
            idExtractor: (item: SourcingScenarioDTO) => item.id,
            httpOptions: (ids) => {
                return { requestBody: { ids } };
            },
            select: (res) => res.items,
        },
        {
            sortBy: chainComparators(
                compareByNumber((x) => -getTotalQuantity(x)),
                compareByStringKey((item) => item.name),
            ),
        },
    );
}

export function useSourcingFull(sourcingScenarioId: string | undefined) {
    return useBulkSingleQuery('POST /sourcing-scenarios/sourcing-full/bulk', sourcingScenarioId, {
        idExtractor: (item: FullSourcingDTO) => item.sourcing_scenario_id,
        httpOptions: (ids) => {
            return { requestBody: { ids } };
        },
        select: (res) => res.items,
    });
}

export function useSourcingFullBulk(sourcingScenarioIds: string[] | undefined) {
    return useBulkQuery(
        'POST /sourcing-scenarios/sourcing-full/bulk',
        sourcingScenarioIds,
        {
            idExtractor: (item: FullSourcingDTO) => item.sourcing_scenario_id,
            httpOptions: (ids) => {
                return { requestBody: { ids } };
            },
            select: (res) => res.items,
        },
        {
            sortBy: compareByStringKey((s) => s.sourcing_scenario_id),
        },
    );
}

export function useSourcingScenariosOfRfq(rfqId?: string) {
    const { data: rfq } = useRfQ(rfqId ?? '', Boolean(rfqId));
    const sourcingScenarioIds = rfq?.sourcing_scenarios ?? [];
    return useSourcingScenarios(sourcingScenarioIds);
}

export function useDemandsOfRfq(rfqId: string) {
    return useHttpQuery(
        'GET /rfqs/:rfqId/demands',
        {
            pathParams: { rfqId },
        },
        { select: (data) => data.items },
    );
}

export type DemandWithSourcingScenarios = DemandDTO & {
    sourcingScenarios: SourcingScenarioDTO[];
};

export function useDemandsWithSourcingScenarios(rfqId: string) {
    const { data: demands } = useDemandsOfRfq(rfqId);
    const { data: rfq } = useRfQ(rfqId, Boolean(rfqId));
    const { data: sourcingScenarios } = useSourcingScenarios(rfq?.sourcing_scenarios);

    return React.useMemo(() => {
        if (!isPresent(demands) || !isPresent(sourcingScenarios)) {
            return { data: undefined };
        }

        const data = demands.map((demand: DemandDTO) => {
            const linkedScenarios = sourcingScenarios.filter((scenario) =>
                scenario.assembly_demands.some((assemblyDemand: DemandDTO) => assemblyDemand.id === demand.id),
            );

            return {
                ...demand,
                sourcingScenarios: linkedScenarios,
            } as DemandWithSourcingScenarios;
        });

        return { data };
    }, [demands, sourcingScenarios]);
}
