import { QuantityUnitDTO, YearlyDemandDTO } from '@luminovo/http-client';

export function generateYearlyDemands(existingDemands: YearlyDemandDTO[]): {
    year: number;
    gross_quantity: QuantityUnitDTO;
    net_quantity: QuantityUnitDTO;
}[] {
    if (existingDemands.length === 0) {
        return [];
    }
    // if two demands have the same month and year, we should error
    const duplicateDemands = existingDemands.filter(
        (demand, index, self) => self.findIndex((t) => t.year === demand.year) !== index,
    );
    if (duplicateDemands.length > 0) {
        throw new Error('Duplicate demands found');
    }

    const lastDemand = Math.max(...existingDemands.map((d) => d.year), new Date().getFullYear());
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    // Start from current year and go until end date year
    for (let year = currentYear; year <= lastDemand; year++) {
        years.push(year);
    }

    const quantityUnits = [...new Set(existingDemands.map((d) => d.gross_quantity.unit))];
    if (quantityUnits.length > 1) {
        throw new Error('All demands must have the same quantity unit');
    }
    const quantityUnit = quantityUnits[0];

    return years.map((year) => {
        const existingDemand = existingDemands.find((d) => d.year === year);
        if (existingDemand) {
            return existingDemand;
        }
        const defaultQuantityUnit: QuantityUnitDTO = { unit: quantityUnit, quantity: 0 };

        return {
            /*eslint-disable camelcase*/
            year,
            gross_quantity: defaultQuantityUnit,
            net_quantity: defaultQuantityUnit,
            /*eslint-enable camelcase*/
        };
    });
}
