/* eslint-disable camelcase */
import { useDialogContext } from '@/components/contexts/ModalContext';
import { CancelButton } from '@/components/formLayouts/CancelButton';
import { FormContainer } from '@/components/formLayouts/FormContainer';
import { SubmitButton } from '@/components/formLayouts/SubmitButton';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import {
    DemandWithSourcingScenarios,
    useDemandsWithSourcingScenarios,
    useSourcingScenariosOfRfq,
} from '@/resources/sourcingScenario/sourcingScenarioHandlers';
import { route } from '@/utils/routes';
import { t } from '@lingui/macro';
import { formatToLongDate } from '@luminovo/commons';
import {
    createColumnHelper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    Link,
    TagGroup,
    TanStackTable,
    useNavigate,
    useTanStackTable,
} from '@luminovo/design-system';
import { SourcingScenarioDTO } from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { Launch } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

type FormState = {
    name: string;
    rfqId: string;
    scope:
        | {
              kind: 'sourcingScenarios';
              sourcingScenarios: SourcingScenarioDTO[];
          }
        | {
              kind: 'demands';
              demands: DemandWithSourcingScenarios[];
          };
};

export function useCreateQuoteRoundDialog({ rfqId }: { rfqId: string }) {
    const { setDialog, closeDialog } = useDialogContext();
    const navigate = useNavigate();

    const defaultValues: FormState = {
        name: '',
        rfqId,
        scope: {
            kind: 'sourcingScenarios',
            sourcingScenarios: [],
        },
    };

    const { mutateAsync } = useHttpMutation('POST /negotiation', {
        snackbarMessage: t`Successfully created negotiation`,
        onSuccess: (data) => {
            navigate(
                route(`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items`, {
                    rfqId,
                    negotiationId: data.id,
                }),
            );
        },
    });

    const onSubmit = (form: FormState) => {
        const generateDemandGroups = () => {
            switch (form.scope.kind) {
                case 'demands':
                    return [form.scope.demands.map((demand) => demand.id)];
                case 'sourcingScenarios':
                    return form.scope.sourcingScenarios.map((sourcingScenario) =>
                        sourcingScenario.assembly_demands.map((demand) => demand.id),
                    );
            }
        };

        const requestBody = {
            name: form.name,
            demand_groups: generateDemandGroups(),
            rfq_id: rfqId,
            filter_by_categories: [],
            filter_manufacturers: [],
            filter_not_quoted_since: null,
            group_by_customer: false,
            group_by_site_groups: [],
            group_by_supplier_groups: [],
            group_by_sourcing_scenario: false,
        };
        mutateAsync({ requestBody });
    };

    return {
        openDialog: () =>
            setDialog(
                <Dialog open={true} maxWidth={'md'} onClose={() => closeDialog()}>
                    <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
                        <DialogTitle title={`Start negotiation`} handleClose={() => closeDialog()} />
                        <DialogContent>
                            <Flexbox flexDirection="column" gap={12}>
                                <FormItemName />
                                <FormItemStrategy />
                                <ControllerTable />
                            </Flexbox>
                        </DialogContent>
                        <DialogActions>
                            <CancelButton onClick={() => closeDialog()} />
                            <SubmitButton label={t`Create`} />
                        </DialogActions>
                    </FormContainer>
                </Dialog>,
            ),
    };
}

function FormItemName() {
    const { control } = useFormContext<FormState>();
    return (
        <FormItem variant="description-inlined" required label={t`Name`}>
            <FieldTextControlled
                control={control}
                name="name"
                required
                FieldProps={{ placeholder: t`e.g. Negotiation #1` }}
            />
        </FormItem>
    );
}

function FormItemStrategy() {
    const { control } = useFormContext<FormState>();
    return (
        <FormItem label={t`Scope`}>
            <FieldSelectControlled
                control={control}
                name="scope"
                FieldProps={{
                    size: 'small',
                    disableClearable: true,
                    options: [
                        { kind: 'sourcingScenarios', sourcingScenarios: [] },
                        { kind: 'demands', demands: [] },
                    ],
                    getOptionLabel: (opt) => {
                        switch (opt.kind) {
                            case 'sourcingScenarios':
                                return t`Sourcing scenarios`;
                            case 'demands':
                                return t`Demands`;
                        }
                    },
                }}
            />
        </FormItem>
    );
}

function ControllerTable() {
    const { control } = useFormContext<FormState>();
    const scope = useWatch({ control, name: 'scope' });

    if (scope.kind === 'demands') {
        return <FormItemDemands />;
    }

    if (scope.kind === 'sourcingScenarios') {
        return <FormItemSourcingScenarios />;
    }

    return null;
}

function FormItemSourcingScenarios() {
    const { control, setValue } = useFormContext<FormState>();
    const rfqId: string = useWatch({ control, name: 'rfqId' });
    const { data } = useSourcingScenariosOfRfq(rfqId);

    const handleRowSelectionChange = React.useCallback(
        (value: Record<string, boolean>) => {
            const selectedSourcingScenarios = data?.filter((row) => value[String(row.id)]) ?? [];
            setValue('scope.sourcingScenarios', selectedSourcingScenarios, { shouldValidate: true });
        },
        [data, setValue],
    );

    const columns = React.useMemo(() => {
        const columnHelper = createColumnHelper<SourcingScenarioDTO>();
        return [
            columnHelper.text((row) => row.name, {
                id: 'name',
                size: 180,
                label: () => t`Name`,
                cell: (item) => item.getValue() ?? '-',
            }),
            columnHelper.array('assembly_demands', {
                size: 300,
                label: () => t`Demands`,
                getOptionLabel: (value) =>
                    `${value.assembly?.name ?? '-'} X ${formatQuantity(value.quantity, { showPiecesUnit: false })}`,
                cell: (item) => (
                    <TagGroup
                        color="neutral"
                        options={item.getValue()}
                        getOptionLabel={(opt) =>
                            `${opt.assembly?.name ?? '-'} x ${formatQuantity(opt.quantity, { showPiecesUnit: false })}`
                        }
                        limit={3}
                    />
                ),
            }),
        ];
    }, []);

    const { table } = useTanStackTable({
        data,
        columns,
        enableSelection: {
            enabled: true,
            getRowId: (row) => row.id,
            onRowSelectionChange: handleRowSelectionChange,
        },
    });

    return (
        <Box sx={{ height: '400px' }}>
            <TanStackTable table={table} size="small" enableMenuBar={false} />
        </Box>
    );
}

function FormItemDemands() {
    const { control, setValue } = useFormContext<FormState>();
    const rfqId: string = useWatch({ control, name: 'rfqId' });
    const { data } = useDemandsWithSourcingScenarios(rfqId);

    const handleRowSelectionChange = React.useCallback(
        (value: Record<string, boolean>) => {
            const selectedDemands = data?.filter((row) => value[String(row.id)]) ?? [];
            setValue('scope.demands', selectedDemands, { shouldValidate: true });
        },
        [data, setValue],
    );

    const columns = React.useMemo(() => {
        const columnHelper = createColumnHelper<DemandWithSourcingScenarios>();
        return [
            columnHelper.text((row) => row.assembly?.name ?? '-', {
                id: 'assembly',
                size: 180,
                label: () => t`Assembly`,
                cell: ({ row }) => {
                    const { assembly } = row.original;

                    if (!assembly) {
                        return '-';
                    }

                    return (
                        <Link
                            to={route('/assemblies/:assemblyId/dashboard', { assemblyId: assembly.id })}
                            attention="high"
                            startIcon={<Launch fontSize="small" />}
                        >
                            {assembly?.name}
                        </Link>
                    );
                },
            }),

            columnHelper.number((row) => row.quantity.quantity, {
                id: 'quantity',
                size: 100,
                label: () => t`Quantity`,
                cell: ({ row }) => formatQuantity(row.original.quantity, { showPiecesUnit: false }),
            }),

            columnHelper.array('sourcingScenarios', {
                size: 100,
                label: () => t`Sourcing scenarios`,
                getOptionLabel: (value) => value.name,
                cell: (item) => (
                    <TagGroup color="neutral" options={item.getValue()} getOptionLabel={(opt) => opt.name} limit={1} />
                ),
            }),

            columnHelper.date('delivery_start_date', {
                size: 100,
                label: () => t`Delivery start date`,
                cell: ({ row }) => formatToLongDate(row.original.delivery_start_date, { ifAbsent: '-' }),
            }),

            columnHelper.date('delivery_end_date', {
                size: 100,
                label: () => t`Delivery end date`,
                cell: ({ row }) => formatToLongDate(row.original.delivery_end_date, { ifAbsent: '-' }),
            }),

            columnHelper.enum('context', {
                size: 160,
                getOptionLabel: (value) => value ?? '-',
                label: () => t`Context`,
                renderType: 'text',
                initialVisibility: false,
                cell: (item) => item.getValue() ?? '-',
            }),
        ];
    }, []);

    const { table } = useTanStackTable({
        data,
        columns,
        enableSelection: {
            enabled: true,
            getRowId: (row) => row.id,
            onRowSelectionChange: handleRowSelectionChange,
        },
    });

    return (
        <Box sx={{ height: '400px' }}>
            <TanStackTable table={table} size="small" enableMenuBar={false} />
        </Box>
    );
}
