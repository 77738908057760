import { plural, t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { useNavigate } from '@luminovo/design-system';
import {
    ManufacturingEntityStatusSelection,
    ManufacturingScenarioTemplateDTO,
    RfqListItemDTO,
    http,
} from '@luminovo/http-client';
import { RightAlignedStickyTableCell } from '@luminovo/manufacturing-core';
import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React from 'react';
import { ErrorText } from '../../../../components/Spinners';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';
import InformationDialogBox, {
    InformationDialogBoxContent,
} from '../../../../components/dialogBox/InformationDialogBox';
import { useDebugErrorHandler } from '../../../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../../../resources/http/httpQueryKey';
import { useManufacturingScenarioTemplatesOfExpenses } from '../../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesHandler';
import { useParsedRfqsOfExpense } from '../../../../resources/rfq/parsedRfqsHandler';
import { route } from '../../../../utils/routes';
import { RfqOverviewMiniTable } from '../../../RfqOverview/components/RfqOverviewTable';
import { ActionMenuComponent } from '../../shared/manufacturingComponents';
import { filterActiveTemplates, generateTemplateString } from '../../shared/manufacturingUtils';
import { DeleteDialog } from '../../shared/sharedManufacturingTypes';
interface UseDeleteExpenses {
    isLoading: boolean;
    isError: boolean;
    rfqsOfExpense: RfqListItemDTO[];
    templatesOfExpense: ManufacturingScenarioTemplateDTO[];
    deleteDialog: DeleteDialog;
}

const useDeleteExpenses = (expenseId: string): UseDeleteExpenses => {
    const token = getToken();
    const { enqueueSnackbar } = useSnackbar();
    const debugErrorHandler = useDebugErrorHandler();

    const [isDeletePressed, setIsDeletePressed] = React.useState<boolean>(false);
    const {
        data: rfqsData,
        isLoading: isLoadingRfqs,
        isError: isErrorRfqs,
    } = useParsedRfqsOfExpense(expenseId, isDeletePressed);
    const rfqsOfExpense = rfqsData?.data ?? [];
    const {
        data,
        isLoading: isLoadingTemplate,
        isError: isErrorTemplate,
    } = useManufacturingScenarioTemplatesOfExpenses([expenseId], isDeletePressed);
    const templatesOfExpense = data?.data !== undefined ? filterActiveTemplates(data.data) : [];
    const isAnythingLoading = isLoadingRfqs || isLoadingTemplate;
    const isAnyError = isErrorRfqs || isErrorTemplate;

    const isInformationDialogDeleteOpen = isDeletePressed && !isLoadingRfqs && !isErrorRfqs && rfqsOfExpense.length > 0;

    const isConfirmationDialogDeleteOpen =
        isDeletePressed && !isAnythingLoading && !isAnyError && rfqsOfExpense.length === 0;
    const queryClient = useQueryClient();

    const { mutateAsync } = useMutation({
        mutationFn: () => http('DELETE /expenses/:expenseId', { pathParams: { expenseId } }, token),
        onError: debugErrorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: httpQueryKey('DELETE /expenses/:expenseId') });
            enqueueSnackbar(t`Expense deleted`, { variant: 'info' });
        },
    });

    return {
        isLoading: isAnythingLoading,
        isError: isAnyError,
        rfqsOfExpense,
        templatesOfExpense,
        deleteDialog: {
            deleteOnClick: () => mutateAsync(),
            isInformationDialogDeleteOpen,
            isConfirmationDialogDeleteOpen,
            setIsDeletePressed,
        },
    };
};

const useStatusChangeExpenses = (expenseId: string, status: string) => {
    const [isStatusChangePressed, setIsStatusChangePressed] = React.useState<boolean>(false);
    const { data, isLoading, isError } = useManufacturingScenarioTemplatesOfExpenses(
        [expenseId],
        isStatusChangePressed,
    );
    const templatesOfExpense = data?.data !== undefined ? filterActiveTemplates(data.data) : [];

    const isConfirmationDialogOpen = isStatusChangePressed && !isLoading && !isError && templatesOfExpense.length > 0;
    const { enqueueSnackbar } = useSnackbar();
    const token = getToken();
    const debugErrorHandler = useDebugErrorHandler();
    const queryClient = useQueryClient();

    const { mutateAsync: mutateAsyncPatch } = useMutation({
        mutationFn: (info: { token: string; status: ManufacturingEntityStatusSelection }) => {
            return http(
                'PATCH /expenses/:expenseId',
                { requestBody: { status: info.status }, pathParams: { expenseId } },
                token,
            );
        },
        onError: debugErrorHandler,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /expenses') });
            enqueueSnackbar(t`Expense updated`, { variant: 'info' });
            setIsStatusChangePressed(false);
        },
    });

    const saveStatusChange = () => {
        mutateAsyncPatch({
            token,
            status: status === 'Active' ? 'Inactive' : 'Active',
        });
    };

    const statusChangeOnClick = (event: React.MouseEvent<HTMLElement>): void => {
        if (status === 'Inactive') {
            saveStatusChange();
        } else {
            setIsStatusChangePressed(true);
        }
    };

    if (isStatusChangePressed && !isLoading && !isError && templatesOfExpense.length === 0) {
        setIsStatusChangePressed(false);
        saveStatusChange();
    }

    return {
        isLoading,
        isError,
        templatesOfExpense,
        statusChangeDialog: {
            setIsStatusChangePressed,
            statusChangeOnClick,
            saveStatusChange,
            isConfirmationDialogOpen,
        },
    };
};

interface ExpenseMenuProps {
    expenseId: string;
    status: string;
}

export const ExpenseMenuComponent: React.FunctionComponent<ExpenseMenuProps> = ({ expenseId, status }): JSX.Element => {
    const navigate = useNavigate();
    const {
        isLoading,
        isError,
        rfqsOfExpense,
        templatesOfExpense,
        deleteDialog: {
            deleteOnClick,
            isInformationDialogDeleteOpen,
            isConfirmationDialogDeleteOpen,
            setIsDeletePressed,
        },
    } = useDeleteExpenses(expenseId);
    const templatesOfExpenseStr: string = generateTemplateString(templatesOfExpense);
    const templatesOfExpenseLength = templatesOfExpense.length;
    const {
        isLoading: isLoadingStatusChange,
        isError: isErrorStatusChange,
        templatesOfExpense: templatesOfExpenseStatusChange,
        statusChangeDialog: {
            setIsStatusChangePressed,
            statusChangeOnClick,
            saveStatusChange,
            isConfirmationDialogOpen,
        },
    } = useStatusChangeExpenses(expenseId, status);

    const generateContentString = () => {
        const templatesOfExpenseStr: string = generateTemplateString(templatesOfExpenseStatusChange);
        if (templatesOfExpenseStatusChange.length > 0) {
            if (templatesOfExpenseStatusChange.length > 1) {
                return t`Since this expense is used in templates ${templatesOfExpenseStr} they will also be deactivated.`;
            } else if (templatesOfExpenseStatusChange.length === 1) {
                return t`Since this expense is used in template ${templatesOfExpenseStr} it will also be deactivated.`;
            }
            return '';
        }
    };
    const translatedString = generateContentString() + ' ' + t`Proceed?`;

    return (
        <RightAlignedStickyTableCell>
            <ActionMenuComponent
                isActive={status === 'Active'}
                onClickActivate={statusChangeOnClick}
                onClickEdit={function (event: React.MouseEvent<HTMLElement>): void {
                    navigate(route('/manufacturing/expense/:expenseId/edit', { expenseId }));
                }}
                onClickDuplicate={function (event: React.MouseEvent<HTMLElement>): void {
                    navigate(route('/manufacturing/expense/:expenseId/duplicate', { expenseId }));
                }}
                onClickDelete={() => setIsDeletePressed(true)}
                isLoadingDelete={isLoading}
                isLoadingStatusChange={isLoadingStatusChange}
            />
            {(isError || isErrorStatusChange) && <ErrorText />}

            <InformationDialogBox
                title={t`Deleting not possible`}
                onReject={() => setIsDeletePressed(false)}
                isDialogOpen={isInformationDialogDeleteOpen}
            >
                <InformationDialogBoxContent
                    // eslint-disable-next-line spellcheck/spell-checker
                    text={t`This expense is used by the following RfQs and cannot be deleted`}
                >
                    <RfqOverviewMiniTable data={rfqsOfExpense} />
                </InformationDialogBoxContent>
            </InformationDialogBox>

            <ConfirmationDialogBox
                text={t`Are you sure you want to delete this expense?`}
                title={t`Delete expense`}
                onConfirm={() => deleteOnClick()}
                onReject={() => setIsDeletePressed(false)}
                isDialogOpen={isConfirmationDialogDeleteOpen}
                deleteText={t`Yes, delete`}
            >
                {templatesOfExpenseLength > 0 && (
                    <Typography variant={'body1'}>
                        {plural(templatesOfExpenseLength, {
                            one: `This expense is used in the template ${templatesOfExpenseStr}. Deleting it will also delete it from the template.`,
                            other: `This expense is used in the templates ${templatesOfExpenseStr}. Deleting it will also delete it from these templates.`,
                        })}
                    </Typography>
                )}
            </ConfirmationDialogBox>

            <ConfirmationDialogBox
                text={t`Are you sure you want to set this expense as inactive?`}
                title={t`Deactivate expense`}
                onConfirm={() => saveStatusChange()}
                onReject={() => setIsStatusChangePressed(false)}
                isDialogOpen={isConfirmationDialogOpen}
                deleteText={t`Yes, set inactive`}
            >
                <Typography variant={'body1'}>{translatedString}</Typography>
            </ConfirmationDialogBox>
        </RightAlignedStickyTableCell>
    );
};
