import { t } from '@lingui/macro';
import {
    Flexbox,
    Highlight,
    PrimaryIconButton,
    TertiaryIconButton,
    Text,
    TextField,
    colorSystem,
} from '@luminovo/design-system';
import { Check, Close } from '@mui/icons-material';
import { InputAdornment, MenuItem, styled } from '@mui/material';
import levenshtein from 'js-levenshtein';
import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import { ImporterCell, PopupState } from '../../../../types';

export function CellInput({
    cell,
    state,
    setState,
    onNext,
}: {
    cell: ImporterCell;
    state: PopupState;
    setState: (state: PopupState) => void;
    onNext: (state: PopupState) => void;
}): JSX.Element {
    const alternatives = cell?.status.alternatives ?? [];

    const [searchText, setSearchText] = React.useState('');
    const filteredAlternatives = alternatives
        .filter((alt) => {
            return (
                alt.label.toLowerCase().includes(searchText.toLowerCase()) ||
                (alt.description ?? '').toLowerCase().includes(searchText.toLowerCase())
            );
        })
        .sort(
            (a, b) =>
                levenshtein(a.label.toLowerCase(), cell.text.toLowerCase()) -
                levenshtein(b.label.toLowerCase(), cell.text.toLowerCase()),
        );
    const maxLength = 50;
    const error =
        state.text.length === 0
            ? t`Required`
            : state.text.length > maxLength
              ? t`Must not be more than ${maxLength} characters`
              : undefined;

    const textInputIndex = searchText.length === 0 ? 0 : 1;

    if (alternatives.length === 0) {
        return (
            <Flexbox flexDirection={'column'} sx={{ padding: 1, gap: 1 }}>
                <Flexbox gap={4} alignItems={'center'}>
                    <TextField
                        autoFocus
                        size="small"
                        placeholder={t`Type to edit`}
                        value={state.text}
                        onFocus={(e) => {
                            e.target.select();
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onNext({ ...state, text: state.text.trim() });
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <TertiaryIconButton size="small" onClick={() => setState({ ...state, text: '' })}>
                                        <Close fontSize="inherit" />
                                    </TertiaryIconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={(event) => {
                            setState({ ...state, text: event.target.value });
                        }}
                    />
                    <PrimaryIconButton
                        disabled={Boolean(error)}
                        onClick={() => {
                            onNext({ ...state, text: state.text.trim() });
                        }}
                        size="medium"
                    >
                        <Check fontSize="inherit" />
                    </PrimaryIconButton>
                </Flexbox>
                {error && (
                    <Text color={colorSystem.red[6]} variant="body-small">
                        {error}
                    </Text>
                )}
            </Flexbox>
        );
    }

    return (
        <Flexbox sx={{ flexDirection: 'column', gap: 1 }}>
            <Flexbox
                sx={{
                    width: '100%',
                    borderBottom: `1px solid ${colorSystem.neutral[2]}`,
                    padding: '8px',
                    boxSizing: 'border-box',
                    gap: 1,
                }}
            >
                <TextField
                    autoFocus
                    placeholder={t`Type to filter`}
                    fullWidth
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Flexbox>
            <Virtuoso
                style={{
                    height: 32 * Math.min(alternatives.length, 5) + 8,
                    width: 400,
                    overflowX: 'hidden',
                    boxSizing: 'border-box',
                }}
                overscan={200}
                totalCount={filteredAlternatives.length + textInputIndex}
                itemContent={(index) => {
                    const alt =
                        index === filteredAlternatives.length
                            ? {
                                  label: searchText,
                                  description: t`Manual`,
                                  id: searchText,
                                  existing: false,
                              }
                            : filteredAlternatives[index];
                    return (
                        <MenuItem
                            onClick={() => {
                                onNext({ ...state, value: alt, text: alt.label });
                            }}
                        >
                            <Flexbox alignItems={'center'} width={'100%'} gap={8}>
                                <Highlight matcher={[searchText]} label={`${alt.label}`} />
                                <span style={{ flexGrow: 1 }} />
                                <Highlight
                                    overrides={{ Container: HighlightContainer }}
                                    matcher={[searchText]}
                                    label={alt.description ?? ''}
                                />
                            </Flexbox>
                        </MenuItem>
                    );
                }}
            />
        </Flexbox>
    );
}
const HighlightContainer = styled('span')({
    fontSize: 10,
    // break words
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
