import { isPresent, isProductionEnvironment } from '@luminovo/commons';
import { CurrentUserDetailsDTO, UserType } from '@luminovo/http-client';
import * as React from 'react';
import { Permission } from '../../permissions/permissions';
import { useProtected } from '../../permissions/useProtected';
import { useSuspenseHttpQuery } from '../../resources/http/useHttpQuery';
import { loadLocale } from '../../utils/internationalisation';

type FeatureFlags = {
    isClosedBetaTester: boolean;
    hasAccessToCustomerPortal: boolean;
    hasAccessToSourceToContract: boolean;
};

type UserDetails = CurrentUserDetailsDTO & { userType: UserType } & FeatureFlags;
/**
 * Returns the details of the currently logged-in user, including contact information, organization details, and customer information.
 */
export function useCurrentUserDetailsContext(): UserDetails {
    const { data: details } = useSuspenseHttpQuery(
        'GET /my/details',
        {},
        {
            staleTime: Infinity,
            select: (response) => response.data,
        },
    );

    React.useEffect(() => {
        loadLocale(details.user.user_language);
    }, [details]);

    return React.useMemo(() => {
        if (isPresent(details.supplier)) {
            return {
                ...details,
                userType: UserType.Supplier,
                isClosedBetaTester: details.organization.is_closed_beta_tester || !isProductionEnvironment(),
                hasAccessToCustomerPortal: details.organization.has_access_to_customer_portal,
                hasAccessToSourceToContract: false,
            };
        }

        if (isPresent(details.customer)) {
            return {
                ...details,
                userType: UserType.Customer,
                isClosedBetaTester: details.organization.is_closed_beta_tester || !isProductionEnvironment(),
                hasAccessToCustomerPortal: details.organization.has_access_to_customer_portal,
                hasAccessToSourceToContract: false,
            };
        }

        return {
            ...details,
            userType: UserType.Internal,
            isClosedBetaTester: details.organization.is_closed_beta_tester || !isProductionEnvironment(),
            hasAccessToCustomerPortal: details.organization.has_access_to_customer_portal,
            hasAccessToSourceToContract: details.organization.has_access_to_source_to_contract,
        };
    }, [details]);
}

/**
 * Returns true if the logged-in user is a customer.
 *
 * @deprecated use `useCurrentUserDetailsContext` instead
 */
export function useIsCustomer(): boolean {
    const userTypes = useUserType();
    return userTypes === UserType.Customer;
}

export function useUserType(): UserType {
    const { userType } = useCurrentUserDetailsContext();
    return userType;
}

export const ADMIN_PERMISSIONS: Permission[] = ['edit:organization_settings', 'edit:user:organization'];

/**
 * Helper function to check if the logged-in user is an admin
 */
export function useIsAdminUser(): boolean {
    return useProtected({
        requiredPermissions: ADMIN_PERMISSIONS,
        fallback: false,
        value: true,
    });
}
