import { Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { ButtonGroup, ButtonGroupItem, Flexbox } from '@luminovo/design-system';
import { AssemblyIndustry, PartAlternative, PartSuggestionFull } from '@luminovo/http-client';
import React, { useState } from 'react';
import { useFormState } from 'react-hook-form';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { OtsPartSpecificationType } from '../SpecificationTypeForms/types';
import { PartAlternativesTable } from './PartAlternatives/PartAlternativesTable';
import { PartialMatchesTable } from './PartialMatches/PartialMatchesTable';
import { useHasDesignatorQuantityMismatch } from './hasDesignatorOrQtyMismatch';

type SuggestionType = 'partialMatches' | 'partAlternatives';

type PartSuggestionProps = {
    partialMatches: PartSuggestionFull[];
    isSuggestionsLoading: boolean;
    partAlternatives: PartAlternative[];
    otsPartSpecificationContext: OtsPartSpecificationType;
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    assemblyIndustry?: AssemblyIndustry;
};

export const PartSuggestions = React.memo(function PartSuggestions({
    partialMatches,
    isSuggestionsLoading,
    partAlternatives,
    bomItem,
    otsPartSpecificationContext,
    rfqId,
    assemblyId,
    assemblyIndustry,
}: PartSuggestionProps) {
    const { handleAddPart: handleAddPartOption, handleAddMultiplePartOptions } = otsPartSpecificationContext;

    const [suggestionsToShow, setSuggestionsToShow] = useState<SuggestionType>(
        partialMatches.length === 0 && partAlternatives.length !== 0 && !isSuggestionsLoading
            ? 'partAlternatives'
            : 'partialMatches',
    );

    const { isSubmitting } = useFormState();
    const hasDesignatorQuantityMismatch = useHasDesignatorQuantityMismatch();

    return (
        <>
            {bomItem.showPartAlternatives && (
                <Flexbox justifyContent="space-between">
                    <ButtonGroup size="large">
                        <ButtonGroupItem
                            selected={suggestionsToShow === 'partialMatches'}
                            onClick={() => setSuggestionsToShow('partialMatches')}
                            count={partialMatches.length}
                            id={id('design/button_partial_matches')}
                        >
                            <Trans>Partial matches</Trans>
                        </ButtonGroupItem>
                        <ButtonGroupItem
                            selected={suggestionsToShow === 'partAlternatives'}
                            onClick={() => setSuggestionsToShow('partAlternatives')}
                            count={partAlternatives.length}
                            id={id('design/button_alternative_parts')}
                        >
                            <Trans>Part alternatives</Trans>
                        </ButtonGroupItem>
                    </ButtonGroup>
                </Flexbox>
            )}
            {suggestionsToShow === 'partialMatches' && (
                <PartialMatchesTable
                    assemblyId={assemblyId}
                    partialMatches={partialMatches}
                    handleAddPartOption={handleAddPartOption}
                    handleAddMultiplePartOptions={handleAddMultiplePartOptions}
                    rfqId={rfqId}
                    bomItem={bomItem}
                    assemblyIndustry={assemblyIndustry}
                    hasDesignatorQuantityMismatch={hasDesignatorQuantityMismatch}
                    isSubmitting={isSubmitting}
                />
            )}
            {suggestionsToShow === 'partAlternatives' && bomItem.showPartAlternatives && (
                <PartAlternativesTable
                    partAlternatives={partAlternatives}
                    assemblyId={assemblyId}
                    handleAddPartOption={handleAddPartOption}
                    handleAddMultiplePartOptions={handleAddMultiplePartOptions}
                    rfqId={rfqId}
                    bomItem={bomItem}
                    assemblyIndustry={assemblyIndustry}
                    hasDesignatorQuantityMismatch={hasDesignatorQuantityMismatch}
                    isSubmitting={isSubmitting}
                />
            )}
        </>
    );
});
