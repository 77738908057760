import { t } from '@lingui/macro';
import { OtsFullPart, PartLite, PartLiteTypes } from '@luminovo/http-client';

/**
 * A simple class that indexes OTS parts by MPN and manufacturer name.
 */
export class MpnIndex {
    // An index by MPN and then manufacturer name
    private index: Record<string, Record<string, PartLite[]>>;

    constructor(parts: OtsFullPart[]) {
        const indexEntries = parts.flatMap((part) => {
            return [part.manufacturer.name, ...part.manufacturer.alternative_names].map((manufacturer) => {
                return {
                    mpn: sanitizeMpn(part.mpn),
                    manufacturer: sanitizeManufacturer(manufacturer),
                    part,
                };
            });
        });

        this.index = {};

        for (const entry of indexEntries) {
            if (!this.index[entry.mpn]) {
                this.index[entry.mpn] = {};
            }
            if (!this.index[entry.mpn][entry.manufacturer]) {
                this.index[entry.mpn][entry.manufacturer] = [];
            }
            this.index[entry.mpn][entry.manufacturer].push({
                ...entry.part,
                kind: PartLiteTypes.OffTheShelf,
            });
        }
    }

    find({
        mpn = '',
        manufacturer = '',
    }: {
        mpn?: string;
        manufacturer?: string;
    }): { part: PartLite; warning?: string } | undefined {
        const mpnLower = sanitizeMpn(mpn);
        const manufacturerLower = sanitizeManufacturer(manufacturer);

        const mpnMatches = this.index[mpnLower] ?? {};
        const manufacturerMatches = mpnMatches[manufacturerLower] ?? [];

        if (manufacturerMatches.length > 0) {
            return { part: manufacturerMatches[0] };
        }

        const allMpnMatches = Object.values(mpnMatches).flatMap((m) => m);
        const firstMpnMatch = allMpnMatches[0];
        if (firstMpnMatch) {
            return {
                part: firstMpnMatch,
                warning: t`No match for manufacturer "${manufacturer}", but we found an MPN match. Please confirm if this part is correct.`,
            };
        }

        return {
            part: {
                kind: PartLiteTypes.Unknown,
                id: `${mpnLower}-${manufacturerLower}`,
                mpn: mpn,
                manufacturer: { name: manufacturer },
            },
            warning: t`Unable to find part in catalog.`,
        };
    }
}

function sanitizeMpn(mpn: string): string {
    return mpn.replaceAll(/\s/g, '').toLowerCase();
}

function sanitizeManufacturer(manufacturer: string): string {
    return manufacturer.toLowerCase();
}
