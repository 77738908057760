import { assertUnreachable } from '@luminovo/commons';
import {
    CustomPartOfferPcbPanelSpecification,
    ExistingPanelContentDTO,
    PCBV2,
    PanelDetailsPostDTO,
} from '@luminovo/http-client';
import { extractPcbWidthAndHeight } from './extractPcbWidthAndHeight';
import { isValidPanelDetails } from './isValidPanelDetails';

export const getPanelDimension = (
    offerPanelSpecification: CustomPartOfferPcbPanelSpecification,
    pcb: PCBV2,
): {
    x: number | null;
    y: number | null;
} => {
    const type = offerPanelSpecification.type;
    switch (type) {
        case 'PanelDetails':
            return getPanelDimensionFromPanelDetails(offerPanelSpecification.data, pcb);
        case 'Existing':
            return getPanelDimensionFromExistingPanel(offerPanelSpecification.data);
        default:
            assertUnreachable(type);
    }
};

export const getPanelDimensionFromPanelDetails = (
    panelDetails: PanelDetailsPostDTO,
    pcb: PCBV2,
): {
    x: number | null;
    y: number | null;
} => {
    const { isValid, severity } = isValidPanelDetails(panelDetails);
    let { width: boardWidth = 0, height: boardHeight = 0 } = extractPcbWidthAndHeight(pcb);
    if (isValid === false && severity === 'error') {
        return {
            x: null,
            y: null,
        };
    }

    if (panelDetails.pcb_is_rotated) {
        const tmpHeight = boardHeight;
        boardHeight = boardWidth;
        boardWidth = tmpHeight;
    }

    const paddingLeft = parseFloat(panelDetails.padding.left_in_mm);
    const paddingRight = parseFloat(panelDetails.padding.right_in_mm);
    const paddingTop = parseFloat(panelDetails.padding.top_in_mm);
    const paddingBottom = parseFloat(panelDetails.padding.bottom_in_mm);
    const horizontalSpacing = parseFloat(panelDetails.horizontal_spacing_in_mm);
    const verticalSpacing = parseFloat(panelDetails.vertical_spacing_in_mm);
    const rowCount = panelDetails.row_count;
    const columnCount = panelDetails.column_count;
    const paddingXAxis = paddingLeft + paddingRight;
    const paddingYAxis = paddingTop + paddingBottom;
    const netWidth = columnCount * (boardWidth + horizontalSpacing) - horizontalSpacing;
    const netHeight = rowCount * (boardHeight + verticalSpacing) - verticalSpacing;

    return {
        x: netWidth + paddingXAxis,
        y: netHeight + paddingYAxis,
    };
};

const getPanelDimensionFromExistingPanel = (
    existingPanel: ExistingPanelContentDTO,
): {
    x: number | null;
    y: number | null;
} => {
    return {
        x: parseFloat(existingPanel.panel_width),
        y: parseFloat(existingPanel.panel_height),
    };
};

export function getNumberOfPcbsPerPanel(offerPanelSpecification: CustomPartOfferPcbPanelSpecification): number {
    const type = offerPanelSpecification.type;
    switch (type) {
        case 'PanelDetails':
            const panelDetails = offerPanelSpecification.data;
            return panelDetails.row_count * panelDetails.column_count;
        case 'Existing':
            const existingPanel = offerPanelSpecification.data;
            return existingPanel.number_of_pcbs;
        default:
            assertUnreachable(type);
    }
}
