import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, FormItem, TertiaryButton, Text } from '@luminovo/design-system';
import { Add, InfoOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Divider, Tooltip } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    CheckboxFormControlController,
    TextFieldController,
} from '../../../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { CalculationTemplateFormInput } from '../utils/types';
import { CostsFieldArray } from './CostFieldArray';
import { defaultValuePercentage, nameRule } from './defaults';
import { ProfitAndDiscountFields } from './LockToggle';
import { SingleCost } from './SingleCost';

const SectionHeader = ({ text, tooltipText }: { text: string; tooltipText?: string | undefined }): JSX.Element => {
    return (
        <Flexbox padding={'10px'} bgcolor={colorSystem.neutral[1]} marginTop={'44px'} gap={'4px'}>
            {tooltipText && (
                <Tooltip title={tooltipText} arrow>
                    <InfoOutlined fontSize={'small'} style={{ color: colorSystem.neutral[8] }} />
                </Tooltip>
            )}
            <Text variant="h3" color={colorSystem.neutral[8]}>
                {text}
            </Text>
        </Flexbox>
    );
};

export const GeneralSection = ({ templateId }: { templateId: string | undefined }): JSX.Element => {
    const { control } = useFormContext<CalculationTemplateFormInput>();
    const { data, isLoading } = useHttpQuery('GET /calculations/templates', {});
    const allNames = data?.data.filter((template) => template.id !== templateId).map((template) => template.name) ?? [];

    if (isLoading) {
        return <CircularProgress />;
    }
    return (
        <Box>
            <SectionHeader text={t`General`} />
            <Box padding={'12px'}>
                <FormItem label={t`Template name*`}>
                    <TextFieldController
                        control={control}
                        name={'name'}
                        TextFieldProps={{ style: { width: 180 } }}
                        rules={{
                            ...nameRule(),
                            validate: (value) => {
                                if (allNames.includes((value ?? '').toString())) {
                                    return t`A template with this name already exists`;
                                }
                                return;
                            },
                        }}
                    />
                </FormItem>
                <Flexbox marginTop="12px" flexDirection={'column'}>
                    <CheckboxFormControlController
                        control={control}
                        name={`isSeparateProjectCostSelected`}
                        label={
                            <Text>
                                <Trans>Separate project cost from manufacturing cost</Trans>
                            </Text>
                        }
                    />
                    <CheckboxFormControlController
                        control={control}
                        name={'includeOneTimeCostInProjectCost'}
                        label={
                            <Text>
                                <Trans>Separate one-time costs from material costs</Trans>
                            </Text>
                        }
                    />
                    <CheckboxFormControlController
                        control={control}
                        name={'includeExcessMaterialInMaterialCost'}
                        label={
                            <Text>
                                <Trans>Include excess material in material costs</Trans>
                            </Text>
                        }
                    />
                </Flexbox>
            </Box>
        </Box>
    );
};

const AutomaticallyCalculatedSection = ({ costName }: { costName: string }): JSX.Element => {
    return (
        <Flexbox padding={'28px'} paddingLeft={'14px'}>
            <Text variant="h4" color={colorSystem.neutral[5]} style={{ width: 330 }}>
                {costName}
            </Text>
            <Text variant="body" color={colorSystem.neutral[5]}>
                <Trans>Automatically calculated</Trans>
            </Text>
        </Flexbox>
    );
};

const CustomCost = ({ field }: { field: ProfitAndDiscountFields }): JSX.Element => {
    const formReturn = useFormContext<CalculationTemplateFormInput>();
    const initialValue = formReturn.watch(`${field}.value`);
    const [showField, setShowField] = React.useState(initialValue !== undefined);

    const removeField = () => {
        formReturn.setValue(`${field}.value`, { unitCost: defaultValuePercentage });
        formReturn.unregister(`${field}`);
        setShowField(false);
    };
    return (
        <Box padding={'14px'} paddingLeft={'14px'} paddingRight={'12px'}>
            {showField ? (
                <Flexbox alignItems={'center'}>
                    <SingleCost field={field} removeField={removeField} />
                </Flexbox>
            ) : (
                <TertiaryButton
                    startIcon={<Add />}
                    size="medium"
                    onClick={() => {
                        setShowField(true);
                        formReturn.setValue(
                            `${field}.value`,
                            {
                                unitCost: defaultValuePercentage,
                            },
                            { shouldValidate: true },
                        );
                        formReturn.setValue(`${field}.isLocked`, false, { shouldValidate: true });
                    }}
                >
                    {field.includes('profit') ? <Trans>Add profit</Trans> : <Trans>Add discount</Trans>}
                </TertiaryButton>
            )}
        </Box>
    );
};

const CalculatedFields = ({ path }: { path: 'manufacturingCost' | 'materialCost' }): JSX.Element => {
    const formReturn = useFormContext<CalculationTemplateFormInput>();
    const fieldArrayValues = formReturn.getValues(`${path}.extraCosts`);

    return (
        <>
            <AutomaticallyCalculatedSection costName={t`Cost`} />
            <Divider />
            <CostsFieldArray fieldName={`${path}.extraCosts`} />
            {fieldArrayValues.length > 0 && (
                <>
                    <Divider /> <AutomaticallyCalculatedSection costName={t`Total cost`} />
                </>
            )}
            <Divider />
            <CustomCost field={`${path}.profit`} />
            <Divider />
            <CustomCost field={`${path}.discount`} />
            <Divider />
            <AutomaticallyCalculatedSection costName={t`Price`} />
            <Divider />
        </>
    );
};

export const MaterialCosts = (): JSX.Element => {
    return (
        <Box marginTop="-20px">
            <SectionHeader
                tooltipText={t`Here you can add overhead costs, profit and discount to your material costs.`}
                text={t`Material cost`}
            />
            <CalculatedFields path={'materialCost'} />
        </Box>
    );
};

export const ManufacturingCosts = (): JSX.Element => {
    return (
        <Box>
            <SectionHeader
                tooltipText={t`Here you can add overhead costs, profit and discount to your manufacturing costs.`}
                text={t`Manufacturing cost`}
            />
            <CalculatedFields path={'manufacturingCost'} />
        </Box>
    );
};

export const OtherCosts = (): JSX.Element => {
    return (
        <Box>
            <SectionHeader
                tooltipText={t`Here you can add further overhead costs such as "Sales overhead". These are added to the sum of material and manufacturing prices.`}
                text={t`Other costs`}
            />
            <CostsFieldArray fieldName={'additionalCosts.otherCosts'} />
        </Box>
    );
};

export const AdditionalProfitsAndDiscounts = (): JSX.Element => {
    return (
        <Box>
            <SectionHeader
                tooltipText={t`Here you can add a profit to the total price and set a discount.`}
                text={t`Additional profit/discount`}
            />
            <CustomCost field={'additionalCosts.profit'} />
            <Divider />
            <CustomCost field={'additionalCosts.discount'} />
        </Box>
    );
};

export const PostProfitCosts = (): JSX.Element => {
    return (
        <Box>
            <SectionHeader
                tooltipText={t`Here you can add further costs such as "Logistic costs". These are added to the sum of all other prices and are not affected by any profit.`}
                text={t`Post-profit costs`}
            />
            <CostsFieldArray fieldName={'additionalCosts.postProfitCosts'} />
        </Box>
    );
};

export const ProjectCosts = (): JSX.Element => {
    const { watch } = useFormContext<CalculationTemplateFormInput>();
    const isSeparateProjectCostSelected = watch(`isSeparateProjectCostSelected`);
    return isSeparateProjectCostSelected ? (
        <Box>
            <SectionHeader
                tooltipText={t`Here you can add profit to your project-level expenses (NREs).`}
                text={t`Project cost`}
            />
            <AutomaticallyCalculatedSection costName={t`Cost`} />
            <Divider />

            <CustomCost field={'projectCost.profit'} />
            <Divider />
            <AutomaticallyCalculatedSection costName={t`Price`} />
            <Divider />
        </Box>
    ) : (
        <></>
    );
};
