import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { assertSuccessfulResponse } from '../http/http';
import {
    QuotePriceBulkRequestBodyDTORuntype,
    QuotePriceRequestBodyDTORuntype,
    SendQuotePriceRequestBodyDTORuntype,
} from './exportBackendTypes';
import { getNormalizedFileName } from './exportFileName';

export const exportEndpoints = {
    'GET /export/costed-bom-xlsx': endpoint({
        description: 'Returns an excel file with all of the costed BOM',
        pathParams: r.Undefined,
        queryParams: r.Record({
            rfq: r.String,
            template_id: r.String.optional(),
        }),
        requestBody: r.Undefined,
        invalidates: ['GET /rfqs/snapshots', 'GET /rfqs/:rfqId/snapshot'],
        responseBody: r.Record({ fileName: r.String, blob: r.InstanceOf<Blob>(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponse(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),

    'POST /export/quote-price-xlsx': endpoint({
        description: 'Returns an quote-price templates',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: QuotePriceRequestBodyDTORuntype,
        responseBody: r.Record({ fileName: r.String, blob: r.InstanceOf<Blob>(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponse(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),

    'POST /export/quote-price-xlsx-bulk': endpoint({
        description: 'Returns a zip file containing supplier specific quote-price templates',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: QuotePriceBulkRequestBodyDTORuntype,
        responseBody: r.Record({ fileName: r.String, blob: r.InstanceOf<Blob>(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponse(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),

    'GET /export/bom-xlsx': endpoint({
        description: 'Returns an excel file with all of the BOM items in a given assembly',
        pathParams: r.Undefined,
        queryParams: r.Record({ assemblyId: r.String, templateId: r.String.optional() }),
        requestBody: r.Undefined,
        responseBody: r.Record({ fileName: r.String, blob: r.InstanceOf<Blob>(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponse(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),
    'GET /export/calculation-cost-xlsx': endpoint({
        description: 'Returns an excel file with all of the calculation cost items in a given assembly',
        pathParams: r.Undefined,
        queryParams: r.Record({ calculation_assembly_details_id: r.String }),
        requestBody: r.Undefined,
        responseBody: r.Record({ fileName: r.String, blob: r.InstanceOf<Blob>(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponse(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),

    'GET /export/manufacturing-xlsx': endpoint({
        description: 'Returns an excel file with all of the manufacturing items in a given assembly',
        pathParams: r.Undefined,
        queryParams: r.Record({ manufacturing_assembly_details_id: r.String }),
        requestBody: r.Undefined,
        responseBody: r.Record({ fileName: r.String, blob: r.InstanceOf<Blob>(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponse(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),

    'GET /rfqs/:rfqId/snapshot/:snapshotId/costed-bom-xlsx': endpoint({
        description: 'Download a costed BOM as an Excel file',
        pathParams: r.Record({
            rfqId: r.String,
            snapshotId: r.String,
        }),
        queryParams: r.Record({
            template_id: r.String.optional(),
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({ fileName: r.String, blob: r.InstanceOf<Blob>(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponse(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),

    'POST /export/send-quote-price-mail': endpoint({
        description: 'Sends multiple emails with upplier specific quote-price-xlsx attached',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: SendQuotePriceRequestBodyDTORuntype,
        responseBody: r.Unknown,
        invalidates: [
            'GET /rfqs/:rfqId/quote-tracking',
            'GET /quote-tracking/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
        ],
    }),
};
