import { isPresent } from '@luminovo/commons';
import { ColumnFiltersState } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useBomOverviewTable } from '../../../Bom/components/BomHealthDashboard/BomOverviewTable/BomOverview';
import { ModuleTableData, ViewContext } from '../../../Bom/components/ModuleTableData';

// TODO - this function is unsafe because it doesn't validate the filters
function parseInitialHealthDashboardFilters(search: string): ColumnFiltersState {
    const queryParams = new URLSearchParams(search);
    const paramValue = queryParams.get('dashboardFilters');
    if (!paramValue) {
        return [];
    }
    try {
        const searchBlocks: ColumnFiltersState = JSON.parse(paramValue);
        if (!Array.isArray(searchBlocks)) {
            return [];
        }
        return searchBlocks;
    } catch (e) {
        return [];
    }
}

export const useDashboardFilters = ({
    modules = [],
    urlSearch,
    assemblyId,
    viewContext,
}: {
    modules?: ModuleTableData[];
    urlSearch: string;
    assemblyId: string;
    viewContext: ViewContext;
}) => {
    const columnFilters = useMemo(() => parseInitialHealthDashboardFilters(urlSearch), [urlSearch]);

    const { table } = useBomOverviewTable({
        items: modules,
        isBomMainPage: false,
        assemblyId,
        viewContext,
        columnFilters,
    });

    return useMemo(() => {
        return table
            .getFilteredRowModel()
            .rows.map((row) => {
                if (row.original.type === 'row') {
                    return row.original.moduleTableData;
                }
                return null;
            })
            .filter(isPresent);
    }, [table]);
};
