/* eslint-disable camelcase */
import { DemandType } from '@/modules/Demand/types';
import { useTopLevelAssemblies } from '@/resources/assembly/assemblyHandler';
import { t } from '@lingui/macro';
import { assertPresent, isPresent, transEnum } from '@luminovo/commons';
import {
    FieldDateControlled,
    FieldNumericControlled,
    FieldSelectControlled,
    FieldTextControlled,
    FormItem,
} from '@luminovo/design-system';
import {
    AssemblyResponseDTO,
    CustomerDTO,
    DemandPostDTO,
    QuantityUnit,
    QuantityUnitDTO,
    SiteDTO,
} from '@luminovo/http-client';
import { QuantityUnitRecordPlural } from '@luminovo/sourcing-core';
import { InputAdornment } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export type DemandFormState = {
    rfqId: string;
    context: string | null;
    assembly: AssemblyResponseDTO | null;
    type: DemandType;
    quantity: QuantityUnitDTO;
    ship_to_site: SiteDTO | null;
    delivery_start_date: string | null;
    delivery_end_date: string | null;
    supplier_site: SiteDTO | null;
    production_start_date: string | null;
    end_customer: CustomerDTO | null;
};

export function convertDemandFormStateToRequestBody(values: DemandFormState): DemandPostDTO {
    return {
        rfq_id: values.rfqId,
        context: values.context,
        assembly_id: assertPresent(values.assembly).id,
        type: values.type,
        quantity: values.quantity,
        delivery_start_date: values.delivery_start_date,
        delivery_end_date: values.delivery_end_date,
        ship_to_site_id: values.ship_to_site?.id ?? null,
        ship_to_site_number: values.ship_to_site?.site_number ?? null,
        supplier_site_id: values.supplier_site?.id ?? null,
        supplier_site_number: values.supplier_site?.site_number ?? null,
        production_start_date: values.production_start_date ?? null,
        end_customer_number: values.end_customer?.customer_number ?? null,
        end_customer_id: values.end_customer?.id ?? null,
    };
}

export function createDemandFormStateDefaultValues(rfqId: string, values: Partial<DemandFormState>): DemandFormState {
    return {
        rfqId,
        context: null,
        assembly: null,
        // Most scenarios are gross, so we default to that
        type: 'gross',
        quantity: {
            quantity: 0,
            unit: QuantityUnit.Pieces,
        },
        delivery_start_date: null,
        delivery_end_date: null,
        ship_to_site: null,
        supplier_site: null,
        production_start_date: null,
        end_customer: null,
        ...values,
    };
}

export function FormItemContext() {
    const { control } = useFormContext<DemandFormState>();
    return (
        <FormItem
            label={t`Name`}
            variant="description-inlined"
            description={t`Enter a descriptive name to help identify this demand later.`}
        >
            <FieldTextControlled control={control} name="context" />
        </FormItem>
    );
}

export function FormItemQuantity() {
    const { control } = useFormContext<DemandFormState>();
    const quantityUnit = useWatch({ control, name: 'quantity.unit' });
    return (
        <FormItem label={t`Quantity`} required>
            <FieldNumericControlled
                control={control}
                name="quantity.quantity"
                required={true}
                min={1}
                FieldProps={{
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position="end">
                                {transEnum(quantityUnit, QuantityUnitRecordPlural)}
                            </InputAdornment>
                        ),
                    },
                }}
            />
        </FormItem>
    );
}

export function FormItemAssemblies() {
    const { control } = useFormContext<DemandFormState>();
    const rfqId = useWatch({ control, name: 'rfqId' });
    const { data: assemblies = [], isLoading } = useTopLevelAssemblies(rfqId);
    return (
        <FormItem required label={t`Assembly`}>
            <FieldSelectControlled
                control={control}
                required
                name="assembly"
                FieldProps={{
                    placeholder: t`Select assembly`,
                    options: assemblies,
                    disabled: isLoading,
                    disableClearable: true,
                    getOptionKey: (assembly: AssemblyResponseDTO) => assembly.id,
                    getOptionLabel: (assembly: AssemblyResponseDTO) => assembly.designator,
                }}
            />
        </FormItem>
    );
}

export function FormDeliveryStartDate() {
    const { control, trigger } = useFormContext<DemandFormState>();
    const deliveryEndDate = useWatch({ control, name: 'delivery_end_date' });

    // TODO: Remove the useEffect and use a afterChange callback instead
    // To trigger the validation of the delivery end date when the delivery start date changes
    React.useEffect(() => {
        trigger('delivery_start_date');
    }, [deliveryEndDate, trigger]);

    return (
        // If the delivery end date is present, the delivery start date is required
        <FormItem required={isPresent(deliveryEndDate)} label={t`Delivery start date`}>
            <FieldDateControlled required={isPresent(deliveryEndDate)} control={control} name="delivery_start_date" />
        </FormItem>
    );
}

export function FormDeliveryEndDate() {
    const { control, trigger } = useFormContext<DemandFormState>();
    const deliveryStartDate = useWatch({ control, name: 'delivery_start_date' });

    // To trigger the validation of the delivery end date when the delivery start date changes
    React.useEffect(() => {
        trigger('delivery_end_date');
    }, [deliveryStartDate, trigger]);

    return (
        // If the delivery start date is present, the delivery end date is required
        <FormItem required={isPresent(deliveryStartDate)} label={t`Delivery end date`}>
            <FieldDateControlled required={isPresent(deliveryStartDate)} control={control} name="delivery_end_date" />
        </FormItem>
    );
}
