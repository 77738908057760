import { Checkbox } from '@luminovo/design-system';
import { IHeaderParams } from 'ag-grid-community';
import { useDataGridContext } from '../components/DataGridContextProvider';

export function HeaderComponentBoolean<T>({ column }: IHeaderParams): JSX.Element {
    const field = column.getColDef().field;
    if (!field) {
        throw new Error('field is required for boolean columns');
    }

    const { rowData, setRowData } = useDataGridContext<T>();

    const allChecked = rowData.every((row) => getColumnValue(row, field));
    const someChecked = rowData.some((row) => getColumnValue(row, field));

    return (
        <Checkbox
            onClick={(event) => {
                event.stopPropagation();
                setRowData(rowData.map((row) => ({ ...row, [field]: !allChecked })));
            }}
            indeterminate={someChecked !== allChecked}
            checked={allChecked}
        />
    );
}

function getColumnValue<T>(row: T, field: string): boolean {
    // @ts-expect-error
    return Boolean(row[field]);
}
