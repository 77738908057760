import { assertUnreachable } from '@luminovo/commons';
import { TanStackTableProps } from '@luminovo/design-system';
import * as React from 'react';
import { AssemblyCentricSourcingScenarioTable } from './AssemblyCentricSourcingScenarioTable';
import { SourcingCentricSourcingScenarioTable } from './SourcingCentricSourcingScenarioTable';
import { AssemblyCentricTableType, SourcingScenarioTableData, SourcingScenarioTableSharedContext } from './utils/types';

export const GroupedSourcingScenarioTable: React.FunctionComponent<{
    rfqId: string;
    sourcingScenarioIds: string[];
    columnsKey: string;
    ActionButton?: TanStackTableProps<
        SourcingScenarioTableData | AssemblyCentricTableType,
        SourcingScenarioTableSharedContext
    >['ActionButton'];
    preferredView?: 'scenario' | 'assembly';
}> = ({ sourcingScenarioIds, columnsKey, ActionButton, rfqId, preferredView = 'scenario' }) => {
    if (preferredView === 'assembly') {
        return (
            <AssemblyCentricSourcingScenarioTable
                rfqId={rfqId}
                sourcingScenarioIds={sourcingScenarioIds}
                columnsKey={columnsKey}
                ActionButton={
                    isSourcingScenariosTableWithSubRowsTypeActionButton(ActionButton) ? ActionButton : undefined
                }
            />
        );
    }
    if (preferredView === 'scenario') {
        return (
            <SourcingCentricSourcingScenarioTable
                rfqId={rfqId}
                sourcingScenarioIds={sourcingScenarioIds}
                columnsKey={columnsKey}
                ActionButton={isSourcingScenarioTableDataActionButton(ActionButton) ? ActionButton : undefined}
            />
        );
    }
    assertUnreachable(preferredView);
};

function isSourcingScenarioTableDataActionButton(
    component: any,
): component is TanStackTableProps<SourcingScenarioTableData, SourcingScenarioTableSharedContext>['ActionButton'] {
    return component !== undefined;
}
function isSourcingScenariosTableWithSubRowsTypeActionButton(
    component: any,
): component is TanStackTableProps<AssemblyCentricTableType, SourcingScenarioTableSharedContext>['ActionButton'] {
    return component !== undefined;
}
