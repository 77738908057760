import { formatDecimal, formatPercentage, isEqual } from '@luminovo/commons';
import { MenuItem, Text } from '@luminovo/design-system';
import { Box, Divider } from '@mui/material';
import { Column } from 'ag-grid-community';
import { formatColumnValue } from '../columns/getColumnValue';
import { useColumnFilter, useDataGridContext } from './DataGridContextProvider';

export function MenuItemCounts({ column }: { column: Column }): JSX.Element {
    const { rowData, api } = useDataGridContext();
    const getValueCounts = (): Array<{ value: string; count: number }> => {
        const counts = new Map<string, number>();

        if (!rowData) return [];

        rowData.forEach((row) => {
            const value = formatColumnValue(row, column) ?? '-';
            counts.set(value, (counts.get(value) ?? 0) + 1);
        });

        const sorted = Array.from(counts.entries());
        sorted.sort((a, b) => b[1] - a[1]);
        return sorted.map(([value, count]) => ({ value, count }));
    };
    const currentFilter = useColumnFilter(column.getColId());

    const valueCounts = getValueCounts();

    if (valueCounts.length === 0) {
        return <></>;
    }

    const totalCount = valueCounts.reduce((sum, { count }) => sum + count, 0);

    return (
        <>
            {valueCounts.slice(0, 10).map(({ value, count }) => {
                const filter = { filterType: 'text', type: 'equals', filter: value };
                const isActive = Boolean(currentFilter) && isEqual(currentFilter, filter);

                return (
                    <MenuItem
                        key={value}
                        selected={isActive}
                        onClick={() => {
                            if (!api) {
                                return;
                            }
                            if (isActive) {
                                api.setFilterModel({
                                    ...api.getFilterModel(),
                                    [column.getColId()]: null,
                                });
                                return;
                            } else {
                                api.setFilterModel({
                                    ...api.getFilterModel(),
                                    [column.getColId()]: filter,
                                });
                            }
                        }}
                        label={
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    flex: 1,
                                    width: '100%',
                                    '&:hover .menu-item-count-text::after': {
                                        content: `"${formatPercentage(count / totalCount)}"`,
                                    },
                                }}
                            >
                                <Text variant="body-small">{value}</Text>
                                <Box
                                    className="menu-item-count-text"
                                    sx={{
                                        textAlign: 'right',
                                        flex: 1,
                                        '&::after': {
                                            content: `"${formatDecimal(count)}"`,
                                        },
                                        minWidth: 60,
                                    }}
                                />
                            </Box>
                        }
                    />
                );
            })}
            <Divider />
        </>
    );
}
