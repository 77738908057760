import { useCurrentUserDetailsContext } from '../components/contexts/CurrentUserDetailsContext';
import { getTenantId } from '../permissions/tenants';

/**
 * @deprecated use `useCurrentUserDetailsContext` instead
 */
export const useHasAccessToCustomerPortal = (): { hasAccessToCustomerPortal: boolean } => {
    const { organization } = useCurrentUserDetailsContext();
    return {
        hasAccessToCustomerPortal: organization.has_access_to_customer_portal,
    };
};

export const useHasCustomers = (): { hasCustomers: boolean } => {
    const { organization } = useCurrentUserDetailsContext();
    return {
        hasCustomers: organization.has_customers,
    };
};

export const useIsLuminovoTenant = (): boolean => {
    const tenantId = getTenantId();
    return tenantId === 'luminovo';
};
