/* eslint-disable camelcase */
import { useSuspenseHttpQuery } from '@/resources/http/useHttpQuery';
import { t } from '@lingui/macro';
import { groupBy, isPresent, LanguageEnum } from '@luminovo/commons';
import { useNavigate } from '@luminovo/design-system';
import {
    ExtractResponseBody,
    SupplierAndStockLocationDTO,
    SupplierContactDTO,
    SupplierContactDTORuntype,
    SupplierTag,
} from '@luminovo/http-client';
import { UniversalImporter } from '@luminovo/universal-importer';
import { ImportStatus } from '@luminovo/universal-importer/src/types';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { useSupplierContacts } from '../../resources/supplierContact/supplierContactHandler';
import { route, UrlParams } from '../../utils/routes';

function generateImportStatusKey(value: { supplier: string; email: string }) {
    return `${value.supplier};${value.email}`;
}

function transformToImportStatusRecord(
    /* eslint-disable-next-line */
    response: ExtractResponseBody<'PATCH /supplier-contacts/upsert'>,
): Record<string, ImportStatus> {
    return response.items.reduce(
        (acc, item) => {
            const isSuccessful = item.status < 300;
            const key = generateImportStatusKey(item.contact);
            if (isSuccessful && SupplierContactDTORuntype.guard(item.contact)) {
                acc[key] = { success: true as const };
            } else {
                acc[key] = {
                    success: false as const,
                    message: item.description,
                };
            }
            return acc;
        },
        {} as Record<string, ImportStatus>,
    );
}

function isSupplierContactEqual(
    supplierContact: SupplierContactDTO,
    record: {
        supplier_number: string;
        first_name: string;
        last_name: string;
        position: string;
        language: LanguageEnum;
        is_main_contact: boolean;
    },
): boolean {
    return (
        supplierContact.first_name === record.first_name &&
        supplierContact.last_name === record.last_name &&
        supplierContact.position === record.position &&
        supplierContact.user_language === record.language &&
        supplierContact.is_main_contact === record.is_main_contact
    );
}

export function BulkSupplierContactsImporter(params: UrlParams<'/supplier/supplier-contacts-importer/bulk'>) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { data: allContacts = [] } = useSupplierContacts();

    /* eslint-disable-next-line spellcheck/spell-checker */
    const { mutateAsync: bulkUpsertSupplierContact } = useHttpMutation('PATCH /supplier-contacts/upsert', {
        snackbarMessage: null,
    });

    const { data: supplierAndStockLocations } = useSuspenseHttpQuery(
        'GET /suppliers-and-stock-locations',
        {},
        { select: (res) => res.data },
    );

    const supplierIdToContacts = React.useMemo(() => {
        return groupBy(allContacts, (contact) => contact.supplier);
    }, [allContacts]);

    const supplierNumberToSupplier = React.useMemo(() => {
        return groupBy(supplierAndStockLocations, (sasl) => sasl.supplier_number);
    }, [supplierAndStockLocations]);

    const suppliers = supplierAndStockLocations.filter((sasl) => isPresent(sasl.supplier_number)).map(toParsedValue);

    return (
        <UniversalImporter
            batchSize={100}
            title={t`Import supplier contacts`}
            onImportDone={() => {
                navigate(route('/supplier'));
                enqueueSnackbar(t`Contacts imported successfully`, { variant: 'success' });
            }}
            transformRecordAction={(record) => {
                const suppliers = supplierNumberToSupplier[record.data.supplier_number];

                // If we don't find the supplier
                if (!isPresent(suppliers)) {
                    return 'insert';
                }
                // This should only ever be one because there's already a unique constraint on the backend
                const supplierId = suppliers[0].supplier.id;
                const supplierContacts = supplierIdToContacts[supplierId];

                // If we don't find any supplier contacts
                if (!isPresent(supplierContacts)) {
                    return 'insert';
                }
                const supplierContact = supplierContacts.find((contact) => contact.email === record.data.email);

                if (isPresent(supplierContact)) {
                    // If nothing change then skip
                    if (isSupplierContactEqual(supplierContact, record.data)) {
                        return 'skipped';
                    }
                    return 'update';
                }
                return 'insert';
            }}
            onImportBatch={async (batch) => {
                const items = batch.map((record) => {
                    return {
                        email: record.data.email,
                        first_name: record.data.first_name,
                        last_name: record.data.last_name,
                        position: record.data.position,
                        user_language: record.data.language,
                        is_main_contact: record.data.is_main_contact,
                        // This should be safe, because of the `errorOnUnknown`
                        supplier: supplierNumberToSupplier[record.data.supplier_number][0].supplier.id,
                    };
                });

                const response = await bulkUpsertSupplierContact({
                    requestBody: {
                        items,
                    },
                });
                const importStatuses = transformToImportStatusRecord(response);

                // Handles edge case, where we lose the duplicates because the response doesn't return
                // duplicates, also ensures that the correct status would be shown for the correct row
                return batch.map((item) => {
                    const supplierId = supplierNumberToSupplier[item.data.supplier_number][0].id;

                    const key = generateImportStatusKey({ supplier: supplierId, email: item.data.email });
                    // Incase we can't find the import status
                    return importStatuses[key] ?? { success: true };
                });
            }}
            config={{
                fields: [
                    {
                        id: 'supplier_number' as const,
                        label: t`Supplier number`,
                        description: t`The unique identifier of the supplier`,
                        required: true,
                        columnIndices: [],
                        parser: {
                            type: 'supplier.number',
                            options: {
                                suppliers,
                                errorOnUnknown: true,
                                warnOnSystemSupplier: false,
                                trim: true,
                            },
                        },
                    },
                    {
                        id: 'first_name' as const,
                        label: t`First name`,
                        description: t`The first name of the contact.`,
                        parser: { type: 'string', options: { trim: true } },
                        columnIndices: [],
                        required: true,
                    },
                    {
                        id: 'last_name' as const,
                        label: t`Last name`,
                        description: t`The last name of the contact.`,
                        parser: { type: 'string', options: { trim: true } },
                        columnIndices: [],
                        required: true,
                    },
                    {
                        id: 'email' as const,
                        label: t`Email`,
                        description: t`The email address of the contact.`,
                        parser: { type: 'email', options: { records: allContacts } },
                        columnIndices: [],
                        required: true,
                    },
                    {
                        id: 'position' as const,
                        label: t`Position`,
                        description: t`The position in the company e.g. Sales Manager.`,
                        parser: { type: 'string', options: { trim: true } },
                        columnIndices: [],
                        required: false,
                    },
                    {
                        id: 'is_main_contact' as const,
                        label: t`Is default contact`,
                        parser: {
                            type: 'boolean',
                            options: {},
                        },
                        defaultValue: { id: false, label: t`No` },
                        columnIndices: [],
                        required: false,
                        description: t`The default contact selected when sending emails.`,
                    },
                    {
                        id: 'language' as const,
                        label: t`Language`,
                        description: t`Communications to this contact will be in this language.`,
                        parser: {
                            type: 'language',
                            options: {},
                        },
                        defaultValue: { id: 'en', label: 'English' },
                        columnIndices: [],
                        required: false,
                    },
                ],
                skipRows: [],
            }}
        />
    );
}

function toParsedValue(supplier: SupplierAndStockLocationDTO) {
    return {
        id: supplier.supplier_number ?? '',
        label: String(supplier.supplier_number),
        description: supplier.supplier.name,
        existing: false,
        isSystem: supplier.tags.some((s) => s.tag === SupplierTag.System),
    };
}
