/* eslint-disable react-compiler/react-compiler */
import { t } from '@lingui/macro';
import { colorSystem, DestructiveSecondaryIconButton, Flexbox, Tag } from '@luminovo/design-system';
import { PCBFileTypes } from '@luminovo/http-client';
import { Close } from '@mui/icons-material';
import { Box, ClickAwayListener, Menu, MenuItem, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { transEnum } from '../../../../components/localization/TransEnum';
import { DeletePcbFileModal } from '../../StackUpTab/components/DeleteStackUpFileModal';
import { pcbFileTypesTranslations } from './utils/i18n';
import { getFileStateType, PCBFileWithId, StateFileType } from './utils/layerAssignmentsUtils';

const specialLayerSelections: Partial<Record<StateFileType, Array<PCBFileTypes>>> = {
    [StateFileType.Copper]: [
        PCBFileTypes.COPPER_TOP,
        PCBFileTypes.PLANE_MID,
        PCBFileTypes.COPPER_MID,
        PCBFileTypes.COPPER_BOTTOM,
    ],
    [StateFileType.MechanicalFiles]: [
        PCBFileTypes.OUTLINE,
        PCBFileTypes.DRILL,
        PCBFileTypes.MECHANICAL,
        PCBFileTypes.ADHESIVE_TOP, //Since Adhesive & Peelable has no effect right now we will mark them as only top
        PCBFileTypes.PEELABLE_TOP,
    ],
};

export const FileColors: Record<string, { color: keyof typeof colorSystem }> = {
    [StateFileType.SilkscreenTop]: { color: 'red' },
    [StateFileType.SilkscreenBottom]: { color: 'red' },
    [StateFileType.SoldermaskBottom]: { color: 'green' },
    [StateFileType.SoldermaskTop]: { color: 'green' },
    [StateFileType.Copper]: { color: 'yellow' },
    [StateFileType.PasteTop]: { color: 'neutral' },
    [StateFileType.PasteBottom]: { color: 'neutral' },
    [StateFileType.MechanicalFiles]: { color: 'primary' },
    [StateFileType.MiscellaneousFiles]: { color: 'teal' },
    [PCBFileTypes.OUTLINE]: { color: 'violet' },
    [PCBFileTypes.DRILL]: { color: 'primary' },
    [StateFileType.StackupFiles]: { color: 'neutral' },
};

const FileDetailsWrapper = styled(Paper)({
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '12px 16px',
    gap: '8px',
    width: '100%',
    minWidth: '305px',
    borderRadius: '6px',
    border: `solid transparent`,
    '&:hover': {
        border: `solid ${colorSystem.primary[5]}`,
    },
});

export const LayerAssignmentFileCard: React.FunctionComponent<{
    pcbId: string;
    disabled?: boolean;
    isActive?: boolean;
    isClicked?: boolean;
    file: PCBFileWithId;
    openDropDown?: boolean;
    style?: React.CSSProperties;
    fileName?: string;
    onDropDownChange?: (fileType: PCBFileTypes, currentFile: PCBFileWithId) => void;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}> = ({
    pcbId,
    disabled,
    openDropDown = false,
    isActive = false,
    isClicked = false,
    file,
    style,
    fileName,
    onDropDownChange,
    onClick,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const ref = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (openDropDown) {
            setOpen(true);
        }
    }, [openDropDown]);

    const layer = getFileStateType(file);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickAway = () => {
        handleClose();
    };

    const onFileTypeChange = (fileType: PCBFileTypes) => {
        onDropDownChange && onDropDownChange(fileType, file);
    };

    const layerStyle =
        file.fileType.fileType === PCBFileTypes.OUTLINE ? FileColors[PCBFileTypes.OUTLINE] : FileColors[layer];

    return (
        <>
            {isActive && (
                <FileDetailsWrapper
                    style={{
                        display: 'var(--is-dragging-view, flex)',
                        height: '44px',
                        backgroundColor: colorSystem.neutral[2],
                        borderColor: colorSystem.neutral[5],
                        borderStyle: 'dotted',
                        ...style,
                    }}
                    elevation={0}
                />
            )}

            <ClickAwayListener onClickAway={handleClickAway}>
                <FileDetailsWrapper
                    style={{
                        cursor: disabled ? 'pointer' : 'grab',
                        display: 'var(--is-dragging-hide, flex)',
                        border: disabled
                            ? `solid ${colorSystem.neutral[2]}`
                            : isClicked
                              ? `solid ${colorSystem.primary[5]}`
                              : `solid transparent`,
                        boxShadow: disabled ? 'none' : '0px 2px 4px 0px rgba(82, 129, 183, 0.32)',
                        ...style,
                    }}
                    onClick={(event) => {
                        open === false && onClick?.(event);
                    }}
                    elevation={disabled ? 0 : 3}
                >
                    <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                        <Flexbox flexDirection={'column'} gap={'4px'}>
                            <Typography
                                title={file.name}
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                                component="span"
                                variant="h5"
                            >
                                {fileName !== undefined ? fileName : file.name}
                            </Typography>
                            <Box
                                ref={ref}
                                maxWidth={'fit-content'}
                                onClick={(event) => {
                                    !disabled && setOpen(true);
                                    event.stopPropagation();
                                }}
                            >
                                <Tag
                                    label={transEnum(file.fileType.fileType, pcbFileTypesTranslations)}
                                    color={layerStyle.color}
                                    style={{
                                        padding: '2px 8px',
                                        ...(layerStyle.color === 'red'
                                            ? {
                                                  backgroundColor: colorSystem.neutral.white,
                                                  border: `solid 1px ${colorSystem.neutral[5]}`,
                                                  color: colorSystem.neutral[7],
                                              }
                                            : {}),
                                    }}
                                />
                            </Box>
                        </Flexbox>
                        {disabled === false && <DeleteFileButton file={file} pcbId={pcbId} />}
                    </Flexbox>

                    {/* Menu */}
                    {specialLayerSelections[layer] && (
                        <Menu
                            anchorEl={ref.current} // TODO - fix useRef
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            open={open}
                            onClose={handleClose}
                        >
                            {specialLayerSelections[layer]?.map((l) => (
                                <MenuItem
                                    onClick={() => {
                                        onFileTypeChange(l);
                                        handleClose();
                                    }}
                                    key={l}
                                >
                                    {transEnum(l, pcbFileTypesTranslations)}
                                </MenuItem>
                            ))}
                        </Menu>
                    )}
                </FileDetailsWrapper>
            </ClickAwayListener>
        </>
    );
};

const DeleteFileButton = ({ file, pcbId }: { file: PCBFileWithId; pcbId: string }) => {
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState(false);
    return (
        <>
            <DeletePcbFileModal
                fileNames={[file.name]}
                pcbId={pcbId}
                isConfirmationDialogOpen={isConfirmationDialogOpen}
                setIsConfirmationDialogOpen={setIsConfirmationDialogOpen}
                onDeleteConfirmation={() => {}}
                title={t`Delete ${file.name}?`}
                text={t`Are you sure you want to delete the file?`}
            />
            <DestructiveSecondaryIconButton
                size="small"
                onClick={(event) => {
                    event.stopPropagation();
                    setIsConfirmationDialogOpen(true);
                }}
            >
                <Close fontSize="inherit" />
            </DestructiveSecondaryIconButton>
        </>
    );
};
