/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { assertPresent, isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import {
    Currency,
    CustomOptionOfferDTO,
    http,
    NegotiationsStandardPartOfferBulkInputDTO,
    PartLite,
    PriceType,
    QuoteRequestDTO,
    QuoteRequestLineItemDTO,
    StandardPartOfferDTO,
    SupplierDTO,
} from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormContainer } from '../../../../../../components/formLayouts/FormContainer';
import { useHttpMutation } from '../../../../../../resources/mutation/useHttpMutation';
import { convertRfqContext, useOtsPartByMpn } from '../../../../../../resources/part/partHandler';
import { useSupplier } from '../../../../../../resources/supplier/supplierHandler';
import { useSupplierAndStockLocations } from '../../../../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { useSuspendedQuoteRequest } from '../../../../hooks/negotiationHandlers';
import { convertLeadTimeUnitToDays } from '../../../../model/LeadTimeUnit';
import { MpnIndex } from '../../../../model/MpnIndex';
import { StandardPartQuoteImportResult } from '../../../../NegotiationsQuoteImporter/types';
import { convertPartLiteToStandardPartDTO, convertQuoteRequestLineItemsToRowItems, isImportMatch } from '../model';
import { FormValuesQuoteRequest, QUOTE_REQUEST_FORM_ID } from '../types';

export function FormContainerQuoteRequest({
    children,
    importResults,
    quoteRequestId, // TODO can be replaced with the DTO
    onSuccess,
}: {
    children: React.ReactNode;
    importResults?: StandardPartQuoteImportResult;
    quoteRequestId: string;
    onSuccess?: () => void;
}) {
    const onSubmit = useSubmitForm(quoteRequestId, onSuccess);
    const defaultValues = useDefaultValues(quoteRequestId, importResults);

    if (!defaultValues) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return (
        <FormContainer
            UNSAFE_disableStablePropCheck
            formId={QUOTE_REQUEST_FORM_ID}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
        >
            {children}
        </FormContainer>
    );
}

function useDefaultValues(
    quoteRequestId: string,
    importResults?: StandardPartQuoteImportResult,
): FormValuesQuoteRequest | undefined {
    const mpns =
        importResults?.quoteLineRow.flatMap((row) => (row.row?.requestedMpn ? [row.row.requestedMpn] : [])) ?? [];
    const { data: partsResponse } = useOtsPartByMpn({ mpns, rfqContext: { type: 'OutsideRfQ' } });

    const {
        data: { quoteRequest, supplier, quoteRequestLineItems, standardPartOffers, customPartOffers, allParts },
    } = useSuspenseQuery({
        queryKey: ['fetch-supplier-portal', quoteRequestId],
        queryFn: async (): Promise<{
            quoteRequest: QuoteRequestDTO;
            supplier: SupplierDTO;
            quoteRequestLineItems: QuoteRequestLineItemDTO[];
            standardPartOffers: StandardPartOfferDTO[];
            customPartOffers: CustomOptionOfferDTO[];
            allParts: PartLite[];
        }> => {
            const token = getToken();
            const quoteRequestLineItemsResponse: Promise<QuoteRequestLineItemDTO[]> = http(
                'GET /quote-request/:id/line-items',
                { pathParams: { id: quoteRequestId } },
                token,
            ).then((res) => res.items);
            const quoteRequestResponse = http('GET /quote-request/:id', { pathParams: { id: quoteRequestId } }, token);

            const lineItems: QuoteRequestLineItemDTO[] = await quoteRequestLineItemsResponse;

            const standardPartOfferId: string[] = (lineItems ?? [])
                .map((lineItem) => lineItem.received_offer?.offer_id)
                .filter(isPresent)
                .flatMap((offer) => (offer?.kind === 'OffTheShelf' ? [offer.id] : []));

            const customPartOfferIds: string[] = (lineItems ?? [])
                .map((lineItem) => lineItem.received_offer?.offer_id)
                .filter(isPresent)
                .flatMap((offer) => (offer?.kind === 'Custom' ? [offer.id] : []));

            const standardPartOffersResponse: Promise<StandardPartOfferDTO[]> = http(
                'POST /offers/off-the-shelf/bulk',
                {
                    requestBody: {
                        ids: standardPartOfferId,
                        ...convertRfqContext({ type: 'OutsideRfQ' }),
                    },
                },
                token,
            ).then((res) => res.items);

            const customPartOffersResponse: Promise<CustomOptionOfferDTO[]> = http(
                'POST /offers/custom-part/bulk',
                {
                    requestBody: {
                        ids: customPartOfferIds,
                    },
                },
                token,
            ).then((res) => res.items);

            const quoteRequest: QuoteRequestDTO = await quoteRequestResponse;
            const standardPartOffers: StandardPartOfferDTO[] = await standardPartOffersResponse;
            const customPartOffers: CustomOptionOfferDTO[] = await customPartOffersResponse;

            // Parts can come from the requested, or the offer.
            const requestedParts: PartLite[] = lineItems.map((r) => r.requested_part).filter(isPresent);
            const offeredParts: PartLite[] = lineItems.map((r) => r.received_offer?.part).filter(isPresent);
            const allParts: PartLite[] = [...requestedParts, ...offeredParts];

            return {
                quoteRequest,
                supplier: quoteRequest.supplier,
                quoteRequestLineItems: lineItems,
                standardPartOffers,
                customPartOffers,
                allParts,
            };
        },
    });

    const rows = React.useMemo(() => {
        const parts = partsResponse?.flatMap((part) => part.matches) ?? [];
        const mpnIndex = new MpnIndex(parts);
        return convertQuoteRequestLineItemsToRowItems({
            quoteRequestLineItems: quoteRequestLineItems ?? [],
            getPartOrThrow: (id): PartLite => {
                return assertPresent([...allParts].find((part) => part.id === id));
            },
            getOffer: (id) => standardPartOffers.find((offer) => offer.id === id),
            getCustomPartOffer: (id) => customPartOffers.find((offer) => offer.id === id),
            getImporterRow: (lineItemId) =>
                importResults?.quoteLineRow
                    // Filter out rows that have no unit price, as they are not importable
                    ?.filter((row) => row.row.unitPrice)
                    .find((row) => isImportMatch(lineItemId, row.row?.lumiQuoteId)),
            mpnIndex,
        });
    }, [quoteRequestLineItems, allParts, standardPartOffers, customPartOffers, importResults, partsResponse]);

    if (!quoteRequest || !partsResponse) {
        return undefined;
    }

    return {
        rows,
        offerNumber: '',
        additionalFiles: [],
        defaultCurrency: supplier.default_currency ?? Currency.EUR,
    };
}

function useSubmitForm(quoteRequestId: string, onSuccess?: () => void): SubmitHandler<FormValuesQuoteRequest> {
    const { mutateAsync } = useHttpMutation('POST /quote-request/:id/add-offers/off-the-shelf', {
        snackbarMessage: t`Offers imported successfully`,
        onSuccess,
    });
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);
    const { data: supplierAndStockLocation } = useSupplierAndStockLocations();

    const supplierId = quoteRequest?.supplier.id;
    const supplierAndStockLocationId = supplierAndStockLocation?.find((s) => s.supplier.id === supplierId)?.id;

    const { data: supplier } = useSupplier(supplierId);
    const defaultCurrency = supplier?.default_currency ?? Currency.EUR;

    return async (formState: FormValuesQuoteRequest) => {
        if (!supplierAndStockLocationId) {
            throw new Error('Supplier and stock location not found');
        }
        await mutateAsync({
            pathParams: { id: String(quoteRequestId) },
            requestBody: {
                event_metadata: { type: 'Excel' },
                inputs: formState.rows
                    .filter((row) => row.selected)
                    .filter((row) =>
                        // currently even no-bid offers require a linked part
                        // until that requirement is lifted from the backend, we need to filter out rows that don't have a linked part
                        Boolean(
                            convertPartLiteToStandardPartDTO(row.offeredPart?.part, row.component_origin ?? undefined),
                        ),
                    )
                    .map((row): NegotiationsStandardPartOfferBulkInputDTO => {
                        const pricePer = row.pricePer;
                        const unitPrice = Number(row.unitPrice?.amount) / pricePer;

                        const cancellationWindow = row.cancellationWindow;
                        const cancellationWindowInDays = isPresent(cancellationWindow)
                            ? convertLeadTimeUnitToDays(row.cancellationWindowUnit ?? 'weeks', cancellationWindow)
                            : null;

                        const stdFactoryLeadTime = row.stdFactoryLeadTime;
                        const stdFactoryLeadTimeInDays = isPresent(stdFactoryLeadTime)
                            ? convertLeadTimeUnitToDays(row.stdFactoryLeadTimeUnit ?? 'weeks', stdFactoryLeadTime)
                            : null;

                        const linkedPart = convertPartLiteToStandardPartDTO(
                            row.offeredPart?.part ?? undefined,
                            row.component_origin ?? undefined,
                        );

                        return {
                            quote_request_line_item_id: row.lineItemId,
                            linked_part: assertPresent(linkedPart),
                            supplier_part_number: row.supplierPartNumber ?? null,
                            price_type: PriceType.QuotePrice,
                            bid: row.bid,
                            packaging: row.packaging ?? null,
                            attachment: null,
                            ncnr: row.ncnr ?? false,
                            notes: row.notes ?? '',
                            currency: row.unitPrice?.currency ?? defaultCurrency,
                            availability_input: {
                                on_order: [],
                                stock: row.stock ?? null,
                                factory_lead_time_days: stdFactoryLeadTimeInDays,
                                factory_quantity: null,
                            },
                            price_break_inputs: row.bid
                                ? [
                                      {
                                          moq: row.moq ?? 1,
                                          mpq: row.mpq ?? 1,
                                          unit_price: unitPrice,
                                      },
                                  ]
                                : [],
                            valid_from: row.validFrom ?? null,
                            valid_until: row.validUntil ?? null,
                            item_class: row.itemClass ?? null,
                            cancellation_window_in_days: cancellationWindowInDays,
                            offer_number: formState.offerNumber,
                            supplier_and_stock_location: supplierAndStockLocationId,
                            unit_of_measurement: { quantity: 1, unit: 'Pieces' },
                        };
                    }),
                supplier_and_stock_location: supplierAndStockLocationId,
            },
        });
    };
}
