/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { formatDecimal, formatMonetaryValue, isPresent, transEnum } from '@luminovo/commons';
import {
    ChangeIcon,
    colorSystem,
    createColumnHelper,
    DestructivePrimaryButton,
    DestructiveSecondaryButton,
    Flexbox,
    SecondaryButton,
    SupportedFilterFn,
    Table,
    Tag,
    TanStackTable,
    Text,
    Tooltip,
    useTanStackTable,
    WarningCircleIcon,
} from '@luminovo/design-system';
import {
    PartLiteTypes,
    QuoteRequestDTO,
    QuoteRequestLineItemDTO,
    QuoteRequestLineItemStatus,
    StandardPartOfferDTO,
} from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { Delete } from '@mui/icons-material';
import React from 'react';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useOTSOffers } from '../../../resources/offer/offerHandler';
import { useOfferDrawer } from '../../SolutionManager/components/OfferDrawer';
import { quoteRequestLineItemStatusTranslations } from '../i18n';
import { formatPartLite } from '../model/formatPartLite';
import { formatQuoteRequestLineItemWarning } from '../model/formatQuoteRequestLineItemWarnings';
import {
    doesUnitPriceHaveWarning,
    getRowWarnings,
    getUnitPriceToTargetPriceScale,
    hasMoqWarning,
    hasPartChanged,
    QuoteRequestLineItemWarning,
} from '../model/getQuoteRequestLineItemWarnings';
import { NegotiationIpnLabel } from './NegotiationIpnLabel';
import { PartLiteLabel } from './PartLiteLabel';
import { QuoteRequestLineItemStatusChip } from './QuoteRequestLineItemStatusChip';
import { WarningComponent } from './TableWarningWrapper';

type RowData = QuoteRequestLineItemDTO & {
    offer: StandardPartOfferDTO | undefined;
};

type SharedContext = {
    enableDeleteMode: boolean;
    setEnableDeleteMode: (enable: boolean) => void;
    offerLength: number;
};

const columnHelper = createColumnHelper<RowData>();

const ipnColumn = columnHelper.text('component_origin', {
    id: 'ipn',
    label: () => t`IPN`,
    size: 150,
    cell: ({ row }) => {
        return <NegotiationIpnLabel ipn={row.original.component_origin} />;
    },
});

const warningColumn = columnHelper.array(getRowWarnings, {
    id: 'warning',
    getOptionLabel: formatQuoteRequestLineItemWarning,
    quickFilters: [
        {
            label: () => t`With differing offered par`,
            value: {
                filterFn: SupportedFilterFn.arrIncludesSome,
                value: [QuoteRequestLineItemWarning.PartChanged],
            },
            showCount: true,
        },
        {
            label: () => t`With large MOQ`,
            value: {
                filterFn: SupportedFilterFn.arrIncludesSome,
                value: [QuoteRequestLineItemWarning.LargeMOQ],
            },
            showCount: true,
        },
    ],
    initialVisibility: false,
    enableHiding: false,
    label: () => t`Warning`,
    size: 100,
});

const requestedPartColumn = columnHelper.text((row) => formatPartLite(row.requested_part), {
    id: 'requestedPart',
    label: () => t`Requested part`,
    size: 240,
    cell: ({ row }) => {
        if (row.original.requested_part?.kind === PartLiteTypes.Ipn) {
            return <Text variant="inherit">No linked part</Text>;
        }
        return <PartLiteLabel variant={'body-small'} part={row.original.requested_part} />;
    },
});

const descriptionColumn = columnHelper.text((row) => row.description ?? '-', {
    id: 'description',
    label: () => t`Description`,
    size: 200,
});

const requiredQuantityColumn = columnHelper.number((row) => row.required_quantity.quantity, {
    id: 'requiredQuantity',
    label: () => t`Qty`,
    size: 100,
    cell: ({ row }) => formatQuantity(row.original.required_quantity, { showPiecesUnit: false }),
});
const potentialQuantityColumn = columnHelper.number((row) => row.potential_quantity.quantity, {
    id: 'potentialQuantity',
    size: 100,
    label: () => t`Potential Qty`,
    cell: ({ row }) => formatQuantity(row.original.potential_quantity, { showPiecesUnit: false }),
});
const recipientsColumn = columnHelper.text((row) => row.recipients ?? '-', {
    id: 'recipients',
    label: () => t`Ship to`,
    size: 100,
});

const targetPriceColumn = columnHelper.monetaryValue('target_price', {
    id: 'targetPrice',
    label: () => t`Target price`,
    size: 100,
    cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '-' }),
});
const offeredPartColumn = columnHelper.text((row) => formatPartLite(row.received_offer?.part), {
    id: 'offeredPart',
    label: () => t`Offered part`,
    size: 200,
    cell: ({ row }) => (
        <WarningComponent
            tooltipText="The offered part is different from the requested part"
            warning={hasPartChanged(row.original)}
            startIcon={<ChangeIcon fill={colorSystem.yellow[7]} />}
        >
            <PartLiteLabel
                variant={'body-small'}
                part={row.original.received_offer?.part}
                color={hasPartChanged(row.original) ? 'inherit' : undefined}
            />
        </WarningComponent>
    ),
});
const unitPriceColumn = columnHelper.monetaryValue((row) => row.received_offer?.unit_price, {
    id: 'unitPrice',
    label: () => t`Unit price`,
    size: 100,
    renderType: 'generic',
    cell: ({ row, getValue }) => {
        const unitPriceWarning = doesUnitPriceHaveWarning(row.original);
        const unitPriceScale = getUnitPriceToTargetPriceScale(row.original);
        return (
            <WarningComponent
                startIcon={<WarningCircleIcon />}
                warning={unitPriceWarning}
                tooltipText={`Unit price is ${unitPriceScale}x of the target price`}
            >
                {formatMonetaryValue(getValue(), 'unit-price', { ifAbsent: '-' })}
            </WarningComponent>
        );
    },
});
const moqColumn = columnHelper.number((row) => row.received_offer?.moq, {
    id: 'moq',
    label: () => t`MOQ`,
    size: 100,
    cell: ({ row, getValue }) => {
        // warn if the MOQ is 50% or more of the required quantity
        const moq = getValue();
        if (!isPresent(moq)) {
            return <>-</>;
        }
        const requiredQuantity = row.original.required_quantity.quantity;
        const showWarning = hasMoqWarning(row.original);
        if (showWarning) {
            return (
                <Tooltip
                    title={`Large MOQ: ${formatDecimal(moq / requiredQuantity, { maximumFractionDigits: 1 })}x the requested quantity`}
                >
                    <span>
                        <Tag attention="low" color="yellow" label={formatDecimal(getValue(), { ifAbsent: '-' })} />
                    </span>
                </Tooltip>
            );
        }
        return <>{formatDecimal(getValue(), { ifAbsent: '-' })}</>;
    },
});
const mpqColumn = columnHelper.number((row) => row.received_offer?.mpq, {
    id: 'mpq',
    label: () => t`MPQ`,
    size: 100,
    cell: ({ getValue }) => formatDecimal(getValue(), { ifAbsent: '-' }),
});

const stockColumn = columnHelper.number((row) => row.offer?.available_prices.stock, {
    id: 'stock',
    label: () => t`Stock`,
    size: 100,
    cell: ({ getValue }) => formatDecimal(getValue(), { ifAbsent: '-' }),
});

const packagingColumn = columnHelper.text((row) => row.offer?.packaging, {
    id: 'packaging',
    label: () => t`Packaging`,
    size: 100,
    cell: ({ getValue }) => getValue() ?? '-',
});

const ncnrColumn = columnHelper.text((row) => row.offer?.ncnr, {
    id: 'ncnr',
    label: () => t`NCNR`,
    size: 100,
    cell: ({ getValue }) => getValue() ?? '-',
});

const notesColumn = columnHelper.text((row) => row.offer?.notes, {
    id: 'notes',
    label: () => t`Notes`,
    size: 100,
    cell: ({ getValue }) => getValue() ?? '-',
});

const statusColumn = columnHelper.enum((row) => row.status, {
    id: 'status',
    label: () => t`Status`,
    size: 100,
    getOptionLabel: (opt) => transEnum(opt, quoteRequestLineItemStatusTranslations),
    initialPinning: 'right',
    cell: (item) => <QuoteRequestLineItemStatusChip quoteRequestLineItemStatus={item.getValue()} />,
    quickFilters: [
        {
            label: () => 'Received',
            value: {
                filterFn: SupportedFilterFn.equalsAny,
                value: [QuoteRequestLineItemStatus.Received],
            },
            showCount: true,
        },
    ],
});

const customerNameColumn = columnHelper.text((row) => row.customers, {
    id: 'customerName',
    label: () => t`Customer name`,
    size: 100,
    cell: ({ getValue }) => getValue() ?? '-',
});

export function TableQuoteRequestLineItems({
    quoteRequest,
    lineItems,
}: {
    quoteRequest: QuoteRequestDTO;
    lineItems: QuoteRequestLineItemDTO[] | undefined;
}) {
    const showCustomerName = quoteRequest.configuration?.hidden_fields?.includes('Customer') === false;
    const showTargetPrice = quoteRequest.configuration?.hidden_fields?.includes('TargetPrice') === false;

    const { openOfferDrawer } = useOfferDrawer();
    const [enableDeleteMode, setEnableDeleteMode] = React.useState(false);

    const offerIds = lineItems?.flatMap((lineItem) => {
        if (!isPresent(lineItem.received_offer) || lineItem.received_offer?.offer_id.kind !== 'OffTheShelf') {
            return [];
        }
        return [lineItem.received_offer.offer_id.id];
    });
    const { data: offers } = useOTSOffers({ offerIds, rfqContext: { type: 'OutsideRfQ' } });

    const data: Array<RowData> | undefined = React.useMemo(() => {
        return lineItems?.map((lineItem) => ({
            ...lineItem,
            offer: offers?.find((offer) => offer.id === lineItem.received_offer?.offer_id.id),
        }));
    }, [lineItems, offers]);

    const columns = React.useMemo(() => {
        return [
            ipnColumn,
            descriptionColumn,
            requestedPartColumn,
            showCustomerName ? customerNameColumn : undefined,
            recipientsColumn,
            requiredQuantityColumn,
            potentialQuantityColumn,
            showTargetPrice ? targetPriceColumn : undefined,
            offeredPartColumn,
            unitPriceColumn,
            packagingColumn,
            moqColumn,
            mpqColumn,
            ncnrColumn,
            stockColumn,
            notesColumn,
            statusColumn,
            warningColumn,
        ].filter(isPresent);
    }, [showTargetPrice, showCustomerName]);

    const { table } = useTanStackTable({
        data,
        columns,
        enableColumnOrdering: true,
        enableColumnHiding: true,
        onRowClick: (row) => {
            if (!isPresent(row.original.offer)) {
                return;
            }
            openOfferDrawer({
                offer: row.original.offer,
                rfqContext: { type: 'OutsideRfQ' },
            });
        },
        enableSelection: {
            enabled: enableDeleteMode,
            getRowId: (row) => String(row.id),
        },
        sharedContext: {
            enableDeleteMode,
            setEnableDeleteMode,
            offerLength: offers?.length ?? 0,
        },
    });

    return <TanStackTable table={table} ActionButton={ActionButton} />;
}

function ActionButton({ table, sharedContext }: { table: Table<RowData>; sharedContext: SharedContext }) {
    const { enableDeleteMode, setEnableDeleteMode, offerLength } = sharedContext;
    const { mutateAsync: deleteSelectedLineItems, isPending: isLoading } = useHttpMutation(
        'DELETE /quote-request/line-item/delete-offer',
        {
            snackbarMessage: 'Quote request line item offers deleted',
            onSuccess: () => {
                table.resetRowSelection();
                setEnableDeleteMode(false);
            },
        },
    );

    if (enableDeleteMode) {
        const disabled = !(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected());
        return (
            <Flexbox gap={4}>
                <SecondaryButton
                    size="medium"
                    onClick={() => {
                        table.resetRowSelection();
                        setEnableDeleteMode(false);
                    }}
                >
                    Cancel
                </SecondaryButton>
                <Tooltip title={disabled ? 'Select rows to delete' : ''}>
                    <span>
                        <DestructivePrimaryButton
                            size="medium"
                            disabled={!(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected())}
                            startIcon={<Delete />}
                            isLoading={isLoading}
                            onClick={() => {
                                const ids = table.getSelectedRowModel().rows.map((row) => row.original.id);
                                deleteSelectedLineItems({ requestBody: { ids } });
                            }}
                        >
                            Delete
                        </DestructivePrimaryButton>
                    </span>
                </Tooltip>
            </Flexbox>
        );
    }

    return (
        <DestructiveSecondaryButton
            disabled={offerLength === 0}
            size="medium"
            startIcon={<Delete />}
            onClick={() => setEnableDeleteMode(true)}
        >
            Delete offers
        </DestructiveSecondaryButton>
    );
}
