import { t, Trans } from '@lingui/macro';
import {
    colorSystem,
    columnWidth,
    Flexbox,
    FormulaIcon,
    NumbersIcon,
    SecondaryButton,
    useNavigate,
} from '@luminovo/design-system';
import { CalculationType, DriverIdDTO, SystemDriverIdDTO, UserDriverIdDTO } from '@luminovo/http-client';
import {
    CombinedDriverType,
    DriverTypeTag,
    getSystemDriverName,
    getSystemDriverNotes,
    selectDriverType,
} from '@luminovo/manufacturing-core';
import { LinearScale } from '@mui/icons-material';
import { Box, MenuItem, Typography } from '@mui/material';
import React, { MouseEventHandler } from 'react';
import { MaxWidthText } from '../../../components/MaxWidthText';
import NestedTableMenu from '../../../components/NestedTableMenu';
import SaveButton from '../../../components/SaveButton';
import { ErrorText, LoadingText, Skeleton } from '../../../components/Spinners';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { setAnchorElementAsNullAndStopPropagation } from '../../../utils/anchor';
import { assertUnreachable } from '../../../utils/typingUtils';
interface ActionMenuProps {
    isActive: boolean;
    onClickActivate: MouseEventHandler<HTMLElement>;
    onClickEdit: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
    onClickDelete: MouseEventHandler<HTMLElement>;
    isLoadingDelete?: boolean;
    isLoadingStatusChange?: boolean;
}

export const ActionMenuComponent: React.FunctionComponent<ActionMenuProps> = ({
    isActive,
    onClickActivate,
    onClickEdit,
    onClickDelete,
    onClickDuplicate,
    isLoadingDelete = false,
    isLoadingStatusChange = false,
}): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElementAsNullAndStopPropagation(event, setAnchorEl);
    }, []);

    const loadingMenuItem = (
        <MenuItem>
            <LoadingText />
        </MenuItem>
    );

    return (
        <NestedTableMenu open={open} anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose}>
            {isLoadingStatusChange ? (
                loadingMenuItem
            ) : (
                <MenuItem
                    onClick={(e) => {
                        onClickActivate(e);
                        handleClose(e);
                    }}
                >
                    <Typography variant="h4" style={{ color: colorSystem.neutral[7] }}>
                        {!isActive && <Trans>Activate</Trans>}
                        {isActive && <Trans>Deactivate</Trans>}
                    </Typography>
                </MenuItem>
            )}
            <MenuItem onClick={onClickEdit}>
                <Typography variant="h4" style={{ color: colorSystem.neutral[7] }}>
                    <Trans>Edit</Trans>
                </Typography>
            </MenuItem>
            {onClickDuplicate && (
                <MenuItem onClick={onClickDuplicate}>
                    <Typography variant="h4" style={{ color: colorSystem.neutral[7] }}>
                        <Trans>Duplicate</Trans>
                    </Typography>
                </MenuItem>
            )}
            {isLoadingDelete ? (
                loadingMenuItem
            ) : (
                <MenuItem
                    onClick={(e) => {
                        onClickDelete(e);
                        handleClose(e);
                    }}
                >
                    <Typography variant="h4" style={{ color: colorSystem.red[6] }}>
                        <Trans>Delete</Trans>
                    </Typography>
                </MenuItem>
            )}
        </NestedTableMenu>
    );
};

export const DriverCellInformation = ({
    driverType,
    driverName,
    driverNotes,
    textMaxWidth,
}: {
    driverType: CombinedDriverType;
    driverName: string;
    driverNotes: string | undefined;
    textMaxWidth?: number;
}): JSX.Element => {
    return (
        <Flexbox flexWrap={'nowrap'} gap={'8px'} alignItems={'center'}>
            <DriverTypeTag driverType={driverType} />
            {textMaxWidth ? (
                <MaxWidthText
                    key={driverName}
                    maxWidthLargeScreen={textMaxWidth}
                    maxWidthSmallScreen={textMaxWidth}
                    text={driverName}
                />
            ) : (
                <Typography variant="body1">{driverName}</Typography>
            )}
        </Flexbox>
    );
};

const UserDriverCell = ({ userDriverId, textMaxWidth }: { userDriverId: UserDriverIdDTO; textMaxWidth?: number }) => {
    const { data, isLoading } = useHttpQuery('GET /user-drivers/:driverId', {
        pathParams: { driverId: userDriverId.value },
    });
    const driver = data?.data;
    if (isLoading) {
        return <Skeleton />;
    }
    if (driver === undefined) {
        return <ErrorText />;
    }
    return (
        <DriverCellInformation
            driverName={driver.name}
            driverType={selectDriverType({
                type: userDriverId.type,
                data: driver,
            })}
            driverNotes={driver.notes ?? undefined}
            textMaxWidth={textMaxWidth}
        />
    );
};

const SystemDriverCell = ({
    systemDriverId,
    textMaxWidth,
}: {
    systemDriverId: SystemDriverIdDTO;
    textMaxWidth?: number;
}) => {
    return (
        <DriverCellInformation
            driverName={getSystemDriverName(systemDriverId.value)}
            driverType={'System+Automatic'}
            driverNotes={getSystemDriverNotes(systemDriverId.value)}
            textMaxWidth={textMaxWidth}
        />
    );
};

export const DriverCell = ({
    driverId,
    textMaxWidth = columnWidth.medium,
}: {
    driverId: DriverIdDTO;
    textMaxWidth?: number;
}): JSX.Element => {
    const driverType = driverId.type;
    switch (driverType) {
        case 'User':
            return <UserDriverCell userDriverId={driverId} textMaxWidth={textMaxWidth} />;
        case 'System':
            return <SystemDriverCell systemDriverId={driverId} textMaxWidth={textMaxWidth} />;
        default:
            assertUnreachable(driverType);
    }
};

export const CalculationTypeCell = ({ calculationType }: { calculationType: CalculationType }): JSX.Element => {
    switch (calculationType) {
        case 'Fixed':
            return (
                <Flexbox flexWrap={'nowrap'} gap={'4px'} alignItems={'center'}>
                    <NumbersIcon />
                    <Typography>
                        <Trans>Fixed</Trans>
                    </Typography>
                </Flexbox>
            );
        case 'Linear':
            return (
                <Flexbox flexWrap={'nowrap'} gap={'4px'} alignItems={'center'}>
                    <LinearScale
                        style={{
                            color: colorSystem.neutral[6],
                            transform: 'rotate(-45deg)',
                            width: '20px',
                            height: '20px',
                        }}
                    />
                    <Typography>
                        <Trans>Linear</Trans>
                    </Typography>
                </Flexbox>
            );
        case 'Formula':
            return (
                <Flexbox flexWrap={'nowrap'} gap={'4px'} alignItems={'center'}>
                    <FormulaIcon heightAndWidth="12" fill={colorSystem.neutral[6]} />
                    <Typography>
                        <Trans>Formula</Trans>
                    </Typography>
                </Flexbox>
            );
        default:
            assertUnreachable(calculationType);
    }
};

// This will need to be changed if and when we add filtering to be similar to the DefaultNoResultsComponent
export const ManufacturingNoResults = (noResultsText: string) => {
    return (
        <Box padding={12} textAlign={'center'}>
            <Typography>{noResultsText}</Typography>
        </Box>
    );
};

export const ManufacturingDatabaseBackAndSave = ({
    isSubmitButtonDisabled,
    isSubmitting,
}: {
    isSubmitButtonDisabled: boolean;
    isSubmitting: boolean;
}): JSX.Element => {
    const navigate = useNavigate();
    return (
        <Flexbox gap={8}>
            <SecondaryButton onClick={() => navigate(-1)}>
                <Trans>Back</Trans>
            </SecondaryButton>
            <SaveButton
                isSubmitting={isSubmitting}
                submitDisabled={isSubmitButtonDisabled}
                submitButtonText={t`Save`}
            />
        </Flexbox>
    );
};

export const ManufacturingDatabaseTableWrapper = ({ children }: { children: React.ReactNode }): JSX.Element => {
    return (
        <Box
            paddingLeft={2}
            paddingRight={2}
            sx={{
                height: '100%',
                overflowY: 'auto',
            }}
        >
            {children}
        </Box>
    );
};
