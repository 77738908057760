import { currency } from './domain/currency';
import { designators } from './domain/designators';
import { dnp } from './domain/dnp';
import { itemClass } from './domain/itemClass';
import { language } from './domain/language';
import { leadTime } from './domain/leadTime';
import { manufacturerName } from './domain/manufacturerName';
import { monetaryValue } from './domain/monetaryValue';
import { ncnr } from './domain/ncnr';
import { notes } from './domain/notes';
import { packaging } from './domain/packaging';
import { priceType } from './domain/priceType';
import { quantity } from './domain/quantity';
import { stock } from './domain/stock';
import { boolean } from './parsers/boolean';
import { number } from './parsers/number';
import { email } from './parsers/regex';

export type { Parser, ParseResult } from './parsers/Parser';

export const parse = {
    packaging,
    currency,
    monetaryValue,
    language,
    notes,
    quantity,
    stock,
    number,
    email,
    designators,
    ncnr,
    dnp,
    boolean,
    manufacturerName,
    leadTime,
    priceType,
    itemClass,
};
