import { t, Trans } from '@lingui/macro';
import { MenuButton, MenuItem, MenuItemVariant, Tooltip, useNavigate } from '@luminovo/design-system';
import { DesignItemOriginTypes, UserType } from '@luminovo/http-client';
import { Add, DeleteOutline, Download, Edit, MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import HistoryIcon from '@mui/icons-material/History';
import { newDesignItemId } from '../modules/Bom/components/ImportBomButton';
import { ViewContext } from '../modules/Bom/components/ModuleTableData';
import { useAssemblyDesignItems } from '../modules/Bom/hooks/utils/designItemAggregation';
import useAssemblyChangeHistory from '../modules/ChangeHistory/assemblyChangeHistory';
import { useHasPermission } from '../permissions/useHasPermission';
import { useAssembly, useBomFileResources } from '../resources/assembly/assemblyHandler';
import { useRfQ } from '../resources/rfq/rfqHandler';
import { analytics } from '../utils/analytics';
import { id } from '../utils/ids';
import { useIsRfqEditable } from '../utils/rfqUtils';
import { route } from '../utils/routes';
import { useUserType } from './contexts/CurrentUserDetailsContext';
import { useDeleteAssemblyButton } from './DeleteAssemblyButton';
import { useDeleteBomDesignItems } from './DeleteBomItemsButton';

const useDoesAssemblyContainBomDesignItems = (assemblyId: string) => {
    const { designItems } = useAssemblyDesignItems(assemblyId);

    return designItems.some((designItem) => {
        return designItem.origin.type !== DesignItemOriginTypes.PCB;
    });
};

type AssemblyMenuButtonType = {
    id: string;
    label: string | JSX.Element;
    variant?: MenuItemVariant;
    onClick: () => void;
    enabled: boolean;
    hidden?: boolean;
    icon: JSX.Element;
};

export function AssemblyMenuButton({
    assemblyId,
    viewContext,
    currentParentAssemblyId,
}: {
    assemblyId: string;
    currentParentAssemblyId?: string | null;
    viewContext: ViewContext;
}): JSX.Element {
    return viewContext.rfqId ? (
        <AssemblyWithRfQ
            viewContext={viewContext}
            rfqId={viewContext.rfqId}
            assemblyId={assemblyId}
            currentParentAssemblyId={currentParentAssemblyId}
        />
    ) : (
        <AssemblyWithoutRfQ assemblyId={assemblyId} />
    );
}

export const useImportedAssemblyNotEditableTooltipText = () => {
    return (
        <Trans>
            You cannot manually edit imported assemblies. If you want to make changes, please re-import the assemblies
            again with the changes.
        </Trans>
    );
};

export const AssemblyWithoutRfQ = ({ assemblyId }: { assemblyId: string }) => {
    const { openDrawer: openAssemblyChangeHistory } = useAssemblyChangeHistory({ assembly: assemblyId });
    const tooltip = useImportedAssemblyNotEditableTooltipText();
    const menuButtonOptions: AssemblyMenuButtonType[] = [
        {
            id: id('design/button_add_placed_component'),
            label: <Trans>Add BOM item</Trans>,
            onClick: () => {},
            enabled: false,
            icon: <Add />,
        },
        {
            id: id('design/button_add_assembly'),
            label: t`Add assembly`,
            onClick: () => {},
            enabled: false,
            icon: <Add />,
        },
        {
            id: id('design/button_edit_assembly'),
            label: <Trans>Edit assembly</Trans>,
            onClick: () => {},
            enabled: false,
            icon: <Edit />,
        },
        {
            id: id('design/button_assembly_change_history'),
            label: <Trans>See history of changes</Trans>,
            onClick: () => {
                openAssemblyChangeHistory();
                analytics.track('view_assembly_change_history');
            },
            enabled: true,
            icon: <HistoryIcon />,
        },
        {
            id: id('design/button_dowload_original_bom'),
            label: <Trans>Download original BOM</Trans>,
            onClick: () => {},
            enabled: false,
            icon: <Download />,
        },
        {
            id: id('design/button_delete_assembly'),
            label: <Trans>Delete assembly</Trans>,
            variant: 'destructive',
            onClick: () => {},
            enabled: false,
            icon: <DeleteOutline />,
        },
        {
            id: id('design/button_delete_bom'),
            label: <Trans>Delete BOM</Trans>,
            variant: 'destructive',
            onClick: () => {},
            enabled: false,
            icon: <DeleteOutline />,
        },
    ];

    return (
        <MenuButton
            id={id('design/button_assembly')}
            appearance="secondary"
            icon={<MoreHorizIcon />}
            label={t`Assembly`}
            size="medium"
        >
            {menuButtonOptions.map((option, i) => (
                <Tooltip key={i} title={option.enabled ? '' : tooltip} placement="left">
                    <span>
                        <MenuItem
                            id={option.id}
                            onClick={() => {
                                option.onClick();
                            }}
                            disabled={!option.enabled}
                            label={option.label}
                            variant={option.variant ?? 'primary'}
                            startIcon={option.icon}
                        />
                    </span>
                </Tooltip>
            ))}
        </MenuButton>
    );
};

const AssemblyWithRfQ = ({
    rfqId,
    assemblyId,
    viewContext,
    currentParentAssemblyId,
}: {
    rfqId: string;
    assemblyId: string;
    currentParentAssemblyId: string | null | undefined;
    viewContext: ViewContext;
}) => {
    const { data: rfq } = useRfQ(rfqId);
    const status = rfq?.status;
    const isArchived = rfq?.is_archived;
    const { isRfqEditable } = useIsRfqEditable(status, isArchived, rfq?.workflow_type);
    const navigate = useNavigate();
    const { onClick: handleDeleteAssembly, dialogs: deleteAssemblyDialogs } = useDeleteAssemblyButton({
        rfqId,
        assemblyId,
        currentParentAssemblyId,
        viewContext,
    });

    const { openDialog: handleDeleteBOMDesignItems } = useDeleteBomDesignItems({
        rfqId,
        assemblyId,
        viewContext,
        currentParentAssemblyId,
    });

    const { openDrawer: openAssemblyChangeHistory } = useAssemblyChangeHistory({ assembly: assemblyId });

    const { data: assembly } = useAssembly(assemblyId);
    const isPcbOnlyAssembly = assembly?.type.type === 'PcbOnly';

    const {
        data: resources = [],
        isLoading: isLoadingBomFileResources,
        isError: isBomFileResourcesError,
    } = useBomFileResources(assemblyId);
    // There should only ever be one BOM file to download.
    // It could also happen that none is available if the upload failed at the time for some reason.
    const hasExactlyOneResource = resources.length === 1;

    const hasCreateAssemblyPermission = useHasPermission(['create:assembly']);
    const hasEditAssemblyPermission = useHasPermission(['edit:assembly']);
    const hasDeleteAssemblyPermission = useHasPermission(['delete:assembly']);

    const userType = useUserType();
    const isTopLevelAssembly = assembly ? rfqId in assembly.parents : true;
    const canDeleteAssembly =
        hasDeleteAssemblyPermission &&
        (userType === UserType.Internal || (userType === UserType.Customer && !isTopLevelAssembly));

    const isDeleteBomItemsEnabled = useDoesAssemblyContainBomDesignItems(assemblyId);

    const menuButtonOptions: AssemblyMenuButtonType[] = [
        {
            id: id('design/button_add_placed_component'),
            label: <Trans>Add BOM item</Trans>,
            onClick: () => {
                /* eslint-disable camelcase */
                analytics.track('create_bom_item', {
                    rfq_id: rfqId,
                    assembly_uuid: assemblyId,
                });
                const newBomItemRoute =
                    viewContext.type === 'WithinRfQ'
                        ? route(
                              '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                              { assemblyId: assemblyId, rfqId },
                              {
                                  isReadonly: null,
                                  designItemId: newDesignItemId,
                                  filters: null,
                                  bomTab: null,
                                  dashboardFilters: null,
                                  search: null,
                                  currentParentAssemblyId,
                                  onlyShowItemsWithManufacturingWarnings: null,
                              },
                          )
                        : route(
                              '/assemblies/:assemblyId/details',
                              { assemblyId },
                              {
                                  designItemId: newDesignItemId,
                                  filters: undefined,
                                  dashboardFilters: undefined,
                                  search: undefined,
                              },
                          );
                /* eslint-enable camelcase */
                navigate(newBomItemRoute);
            },
            enabled: isRfqEditable,
            hidden: isPcbOnlyAssembly,
            icon: <Add />,
        },
        {
            id: id('design/button_add_assembly'),
            label: assembly?.id === rfqId ? t`Add assembly` : t`Add subassembly`,
            onClick: () => {
                const addAssemblyRoute =
                    viewContext.type === 'WithinRfQ'
                        ? route(
                              '/rfqs/:rfqId/bom/assembly/add-assembly',
                              { rfqId },
                              { assemblyId, tab: null, monitoring: null },
                          )
                        : route('/assemblies/add-assembly', {}, { assemblyId, rfqId });
                navigate(addAssemblyRoute);
            },
            enabled: hasCreateAssemblyPermission && isRfqEditable,
            hidden: isPcbOnlyAssembly,
            icon: <Add />,
        },
        {
            id: id('design/button_edit_assembly'),
            label: <Trans>Edit assembly</Trans>,
            onClick: () => {
                const editAssemblyRoute =
                    viewContext.type === 'WithinRfQ'
                        ? route(
                              '/rfqs/:rfqId/bom/assembly/:assemblyId/edit',
                              {
                                  rfqId,
                                  assemblyId,
                              },
                              {
                                  currentParentAssemblyId,
                                  tab: null,
                                  monitoring: null,
                              },
                          )
                        : route('/assemblies/:assemblyId/edit', { assemblyId }, { rfqId });
                // eslint-disable-next-line camelcase
                analytics.track('edit_assembly_info', { assembly_uuid: assemblyId });
                navigate(editAssemblyRoute);
            },
            enabled: hasEditAssemblyPermission && isRfqEditable,
            hidden: false,
            icon: <Edit />,
        },
        {
            id: id('design/button_assembly_change_history'),
            label: <Trans>See history of changes</Trans>,
            onClick: () => {
                openAssemblyChangeHistory();
                analytics.track('view_assembly_change_history');
            },
            enabled: true,
            hidden: isPcbOnlyAssembly,
            icon: <HistoryIcon />,
        },
        {
            id: id('design/button_dowload_original_bom'),
            label: <Trans>Download original BOM</Trans>,
            onClick: () => window.open(resources[0]),
            enabled: !isLoadingBomFileResources && !isBomFileResourcesError && hasExactlyOneResource,
            hidden: isPcbOnlyAssembly,
            icon: <Download />,
        },
        {
            id: id('design/button_delete_assembly'),
            label: <Trans>Delete assembly</Trans>,
            variant: 'destructive',
            onClick: () => handleDeleteAssembly(),
            enabled: canDeleteAssembly && isRfqEditable,
            hidden: !canDeleteAssembly,
            icon: <DeleteOutline />,
        },
        {
            id: id('design/button_delete_bom'),
            label: <Trans>Delete BOM</Trans>,
            variant: 'destructive',
            onClick: () => handleDeleteBOMDesignItems(),
            enabled: isDeleteBomItemsEnabled && isRfqEditable,
            hidden: isPcbOnlyAssembly,
            icon: <DeleteOutline />,
        },
    ];

    const visibleMenuButtonOptions: AssemblyMenuButtonType[] = menuButtonOptions.filter((menu) => !menu.hidden);

    return (
        <>
            <MenuButton
                id={id('design/button_assembly')}
                appearance="secondary"
                icon={<MoreHorizIcon />}
                label={t`Assembly`}
                size="medium"
            >
                {visibleMenuButtonOptions.map((option, i) => (
                    <MenuItem
                        id={option.id}
                        key={i}
                        onClick={() => {
                            option.onClick();
                        }}
                        disabled={!option.enabled}
                        label={option.label}
                        variant={option.variant ?? 'primary'}
                        startIcon={option.icon}
                    />
                ))}
            </MenuButton>
            {deleteAssemblyDialogs}
        </>
    );
};
