import { t } from '@lingui/macro';
import { Card, Flexbox, IconNotFound, PrimaryButton, Text, useNavigate } from '@luminovo/design-system';
import { RegisteredHttpEndpoint } from '@luminovo/http-client';
import { route } from '../../utils/routes';
import { typed } from '../../utils/typingUtils';
export function ResourceNotFound({ endpoint }: { endpoint?: string }): JSX.Element {
    const navigate = useNavigate();
    switch (endpoint) {
        case typed<RegisteredHttpEndpoint>('GET /rfqs/:rfqId/snapshot/:snapshotId'):
            return (
                <NotFoundCard
                    title={t`Version not found`}
                    description={t`The version you're looking for was deleted or never existed.`}
                />
            );
        case typed<RegisteredHttpEndpoint>('GET /rfqs/:rfqId'):
            return (
                <NotFoundCard
                    title={t`RfQ not found`}
                    description={t`Sorry for the inconvenience. Looks like the RfQ you're looking for was deleted or never existed.`}
                />
            );
        case typed<RegisteredHttpEndpoint>('POST /assemblies/bulk'):
            return (
                <NotFoundCard
                    title={t`Assembly not found`}
                    description={t`Sorry for the inconvenience. Looks like the assembly you're looking for was deleted or never existed.`}
                    cta={t`Go back`}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
            );
        case typed<RegisteredHttpEndpoint>('GET /solution-configurations/:solutionConfigurationId'):
            return (
                <NotFoundCard
                    title={t`Solution not found`}
                    description={t`This can happen if two design items have been aggregated together after adding or removing a design item.`}
                    cta={t`Go back`}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
            );
    }
    return <NotFoundCard />;
}

export function NotFoundCard({
    title = t`Page not found`,
    description = t`We are sorry for the inconvenience, the page you are trying to access has been deleted or never even existed.`,
    onClick,
    cta = t`Go back to home`,
}: {
    title?: string;
    description?: string;
    cta?: string;
    onClick?: () => void;
}): JSX.Element {
    const navigate = useNavigate();

    return (
        <Card maxWidth="300px">
            <Flexbox width="100%" flexDirection="column" alignItems="center">
                <IconNotFound />
                <Text variant="h1">{title}</Text>
            </Flexbox>

            <Text>{description}</Text>
            <PrimaryButton
                color="inherit"
                onClick={
                    onClick
                        ? onClick
                        : () => {
                              navigate(route('/rfqs'));
                              window.location.reload();
                          }
                }
            >
                {cta}
            </PrimaryButton>
        </Card>
    );
}
