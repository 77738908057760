import { Trans, t } from '@lingui/macro';
import {
    CenteredLayout,
    Column,
    DataTable,
    Flexbox,
    NonIdealState,
    RightBoxDrawer,
    SecondaryIconButton,
    Text,
    Tooltip,
    colorSystem,
    useDataTableState,
} from '@luminovo/design-system';
import {
    BackendPartOfferSummary,
    OtsFullPart,
    PartAlternative,
    PreferenceStatusEnum,
    RfqContext,
} from '@luminovo/http-client';
import {
    ComplianceOverviewChips,
    PackageChip,
    PreferredManufacturerTag,
    SimilarityTag,
    SimilarityTooltip,
} from '@luminovo/sourcing-core';
import { DescriptionRounded, Info } from '@mui/icons-material';
import { CircularProgress, TableCell, Typography, styled } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { useCallback } from 'react';
import { LifecycleChipWithTooltip } from '../../../../../components/PartLifecycleView';
import { StockView } from '../../../../../components/StockView';
import { useIsCustomer } from '../../../../../components/contexts/CurrentUserDetailsContext';
import { CloseDrawerButton, useDrawerContext } from '../../../../../components/contexts/ModalContext';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { PartDetailsPanel } from '../PartDetailsPanel';

type PartAlternativeData = PartAlternative & { sourcingSummary?: BackendPartOfferSummary };

function TooltipExplanatoryText({ text }: { text: string | JSX.Element }) {
    return (
        <Typography variant="body1" style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
            {text}
        </Typography>
    );
}

export function StockTooltip() {
    /**
     * OEM users are not aware of preferred/approved suppliers, so showing them
     * this information is not really useful/relevant to them
     */
    return !useIsCustomer() ? (
        <TooltipExplanatoryText
            text={t`Highest quantity in stock across all offers from your preferred and approved suppliers.`}
        />
    ) : null;
}

const columnMpn: Column<PartAlternativeData> = {
    id: `columnMpn`,
    label: <Trans>MPN</Trans>,
    render: ({ data: part }) => {
        return <TableCellNoWrap>{part.off_the_shelf_part.mpn}</TableCellNoWrap>;
    },
};

const columnManufacturer: Column<PartAlternativeData> = {
    id: `columnManufacturer`,
    label: <Trans>Manufacturer</Trans>,
    render: ({ data: part }) => {
        return (
            <TableCell>
                <Flexbox alignItems="center" gap="4px" style={{ whiteSpace: 'nowrap' }}>
                    {part.off_the_shelf_part.manufacturer.name}
                    {part.off_the_shelf_part.preference_status === PreferenceStatusEnum.Preferred && (
                        <PreferredManufacturerTag />
                    )}
                </Flexbox>
            </TableCell>
        );
    },
};

const columnDescription: Column<PartAlternativeData> = {
    id: `columnDescription`,
    label: <Trans>Description</Trans>,
    render: ({ data: part }) => {
        const description = part.off_the_shelf_part.description ?? '';
        return (
            <TableCell>
                <Flexbox>
                    <Tooltip title={description.length > 60 ? description : ''}>
                        <Text
                            variant="body-small"
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '350px',
                            }}
                        >
                            {description}
                        </Text>
                    </Tooltip>
                </Flexbox>
            </TableCell>
        );
    },
};
const columnDatasheet: Column<PartAlternativeData> = {
    id: `columnDatasheet`,
    label: <Trans>Datasheet</Trans>,
    render: ({ data: part }) => {
        const url = part.off_the_shelf_part.datasheet_url;
        return (
            <TableCell>
                <Tooltip title={!url ? t`Datasheet not available` : ''}>
                    <span>
                        <SecondaryIconButton
                            size="small"
                            disabled={!url}
                            onClick={() => window.open(url ?? undefined, '_blank', 'noopener noreferrer')}
                        >
                            <DescriptionRounded style={{ fontSize: 'small' }} />
                        </SecondaryIconButton>
                    </span>
                </Tooltip>
            </TableCell>
        );
    },
};

const columnPackage: Column<PartAlternativeData> = {
    id: `columnPackage`,
    label: <Trans>Package</Trans>,
    render: ({ data: part }) => {
        const packageData = part.off_the_shelf_part.package;
        if (!packageData) {
            return <TableCell />;
        }
        return (
            <TableCell>
                <PackageChip formfit={packageData} />
            </TableCell>
        );
    },
};

const columnStock: Column<PartAlternativeData> = {
    id: `columnStock`,
    label: '',
    renderHead: () => {
        return (
            <TableCell>
                <Flexbox whiteSpace="nowrap">
                    <Text variant="h5" color={colorSystem.neutral[7]}>
                        <Trans>Stock</Trans>
                    </Text>
                    <Tooltip
                        variant="white"
                        title={
                            <Text variant="body-small">
                                <StockTooltip />
                            </Text>
                        }
                    >
                        <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
                    </Tooltip>
                </Flexbox>
            </TableCell>
        );
    },
    render: ({ data: part }) => {
        return (
            <TableCell>
                <StockView isLoading={!Boolean(part.sourcingSummary)} quantity={part.sourcingSummary?.supplier_stock} />
            </TableCell>
        );
    },
};

const columnLifecycle: Column<PartAlternativeData> = {
    id: `columnLifecycle`,
    label: <Trans>Lifecycle</Trans>,
    render: ({ data: part }) => {
        return (
            <TableCell>
                <LifecycleChipWithTooltip status={part.off_the_shelf_part.lifecycle_status} />
            </TableCell>
        );
    },
};

const columnCompliance: Column<PartAlternativeData> = {
    id: `columnCompliance`,
    label: <Trans>Compliance</Trans>,
    render: ({ data: part }) => {
        const reach = part.off_the_shelf_part.reach_compliant;
        const rohs = part.off_the_shelf_part.rohs_compliant;
        const aecq = part.off_the_shelf_part.aecq_compliant;
        return (
            <TableCell>
                <ComplianceOverviewChips reach={reach} rohs={rohs} aecq={aecq} />
            </TableCell>
        );
    },
};

const columnSimilarity: Column<PartAlternativeData> = {
    id: `columnSimilarity`,
    label: '',
    renderHead: () => (
        <TableCell>
            <Flexbox whiteSpace="nowrap">
                <Text variant="h5" color={colorSystem.neutral[7]}>
                    <Trans>Similarity</Trans>
                </Text>
                <SimilarityTooltip>
                    <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
                </SimilarityTooltip>
            </Flexbox>
        </TableCell>
    ),
    render: ({ data: part }) => {
        return (
            <TableCell>
                <SimilarityTag similarity={part.similarity} />
            </TableCell>
        );
    },
};

export const PartAlternativesTable = ({ items, isLoading }: { items: PartAlternative[]; isLoading: boolean }) => {
    const { openDrawer: openOtsPartDrawer } = useOtsPartDetailsDrawer();
    const parts = items.map((item) => item.off_the_shelf_part);
    const { data: partOffers = [] } = useHttpQuery(
        'POST /parts/library/sourcing',
        {
            requestBody: { part_ids: parts.map((part) => part.id), rfq_context: 'OutsideRfQ' },
        },
        { placeholderData: keepPreviousData, select: (res) => res.items },
    );

    const dataTableState = useDataTableState<PartAlternativeData>({
        persistenceId: 'partAlternativesTable',
        items: items.map((item) => {
            return {
                ...item,
                sourcingSummary: partOffers.find((offer) => offer.part_id === item.off_the_shelf_part.id),
            };
        }),
        columns: [
            columnMpn,
            columnManufacturer,
            columnDescription,
            columnDatasheet,
            columnPackage,
            columnStock,
            columnLifecycle,
            columnCompliance,
            columnSimilarity,
        ],
    });

    const handleItemClick = useCallback(
        ({ off_the_shelf_part: part }: PartAlternativeData) => {
            return openOtsPartDrawer({ part, rfqContext: { type: 'OutsideRfQ' } });
        },
        [openOtsPartDrawer],
    );

    return (
        <DataTable
            size="medium"
            tableState={dataTableState}
            onItemClick={handleItemClick}
            overrides={{
                NoResultsComponent: () => {
                    if (isLoading) {
                        return (
                            <CenteredLayout height={'207px'}>
                                <CircularProgress />
                            </CenteredLayout>
                        );
                    }
                    return (
                        <CenteredLayout>
                            <NonIdealState
                                title={t`No part alternatives`}
                                description={t`We couldn't find any part alternatives.`}
                            />
                        </CenteredLayout>
                    );
                },
            }}
        />
    );
};

const TableCellNoWrap = styled(TableCell)({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
});

function useOtsPartDetailsDrawer() {
    const { closeDrawer, isOpen, setDrawer } = useDrawerContext();

    return {
        closeDrawer,
        isOpen,
        openDrawer: ({ part, rfqContext }: { part: OtsFullPart; rfqContext: RfqContext }) => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <Flexbox flexDirection={'column'} padding={'12px 24px 12px 16px'} width={'420px'}>
                        <PartDetailsPanel
                            partId={part.id}
                            rfqContext={rfqContext}
                            BackButton={() => <CloseDrawerButton disableMargin={true} />}
                            showOpenPartPageButton={true}
                        />
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
}
