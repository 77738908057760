import { t } from '@lingui/macro';
import { Flexbox, ReleaseTag, useNavigate } from '@luminovo/design-system';
import { Typography, useTheme } from '@mui/material';
import { FormContainer, ValidationErrors } from '../../../../components/formLayouts/FormContainer';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useHasCustomers } from '../../../../utils/featureFlags';
import { route } from '../../../../utils/routes';
import { assertUnreachable } from '../../../../utils/typingUtils';
import { RfqCreationFormState, RfqCreationStep, useRfqCreationContext } from '../../RfqCreationContext';
import { createRequestBodyForEms } from '../../util/utils';
import { AssemblyForm } from './AssemblyForm';
import { CustomerForm } from './CustomerForm';
import { DemandScenarioForm } from './DemandScenarioForm';
import { RfqForm } from './RfqForm';
const getHeader = (step: RfqCreationStep, hasCustomers: boolean): JSX.Element | string => {
    switch (step) {
        case 'customer':
            return hasCustomers ? t`Add customer` : t`Add company`;
        case 'assembly':
            return t`Add assembly`;
        case 'demand':
            return t`Demand`;
        case 'rfq':
            return t`Add RfQ`;
        default:
            assertUnreachable(step);
    }
};

export const RfqCreationForm = (): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { hasCustomers } = useHasCustomers();

    // Form
    const { formState, activeStep } = useRfqCreationContext();

    const header = getHeader(activeStep, hasCustomers);

    // Make sure to adjust `getErrorMessages` in <RfqForm /> if you add
    // another validation error here.
    const validationErrors: ValidationErrors<RfqCreationFormState> = {
        'rfq.customer_does_not_exist': {
            fieldPath: 'root.serverError',
        },
        'rfq.new_customer_name_unavailable': {
            fieldPath: 'root.serverError',
        },
        'rfq.duplicate_order_sizes': {
            fieldPath: 'root.serverError',
        },
        'rfq.cannot_update_archived': {
            fieldPath: 'root.serverError',
        },
        'rfq.not_found': {
            fieldPath: 'root.serverError',
        },
        'rfq.request_in_draft_only_allowed_if_customer_portal_is_enabled': {
            fieldPath: 'root.serverError',
        },
        'rfq.invalid_name': {
            fieldPath: 'rfq.name',
        },
        'rfq.invalid_volume_estimate_bounds': {
            fieldPath: 'rfq.volumeEstimate.lower',
        },
        'rfq.cannot_create_customer_as_customer_user': {
            fieldPath: 'root.serverError',
        },
        'rfq.cannot_create_rfq_for_different_customer': {
            fieldPath: 'root.serverError',
        },
        'rfq.invalid_assembly_name': {
            fieldPath: 'assembly.assemblies',
        },
        'rfq.invalid_customer_name': {
            fieldPath: 'root.serverError',
        },
        'rfq.invalid_order_size': {
            fieldPath: 'root.serverError',
        },
        'rfq.invalid_order_date': {
            fieldPath: 'root.serverError',
        },
        'rfq.invalid_rfq_number': {
            fieldPath: 'rfq.emsInternalNumber',
        },
        'rfq.customer_user_has_no_customer': {
            fieldPath: 'root.serverError',
        },
        'rfq.unauthorized': {
            fieldPath: 'root.serverError',
        },
        'rfq.workflow_changes_only_allowed_by_ems': {
            fieldPath: 'root.serverError',
        },
    };

    const { mutateAsync, isPending: isLoading } = useHttpMutation('POST /rfqs', {
        snackbarMessage: t`Request created`,
        onSuccess: async (res) => {
            navigate(route('/rfqs/:rfqId/bom', { rfqId: res.data.id }));
        },
    });

    const onSubmit = async (form: RfqCreationFormState) => {
        const requestBody = createRequestBodyForEms(form);
        await mutateAsync({ requestBody });
    };

    return (
        <>
            <Flexbox gap={12} style={{ marginBottom: theme.spacing(4) }}>
                <Typography variant="h1">{header}</Typography>
                {activeStep === 'demand' && <ReleaseTag color="violet" label={'New'} />}
            </Flexbox>

            <FormContainer
                defaultValues={formState}
                mode="onChange"
                onSubmit={onSubmit}
                validationErrors={validationErrors}
            >
                <Flexbox gap={theme.spacing(3)} flexDirection="column">
                    {activeStep === 'customer' && <CustomerForm />}

                    {activeStep === 'assembly' && <AssemblyForm />}

                    {activeStep === 'demand' && <DemandScenarioForm />}

                    {activeStep === 'rfq' && <RfqForm isSubmitting={isLoading} />}
                </Flexbox>
            </FormContainer>
        </>
    );
};
