/* eslint-disable camelcase */

import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { DemandDTORuntype } from '../negotiation';
import {
    DemandCreationRequestRuntype,
    DemandPostDTORuntype,
    DemandsExportRequestBodyRuntype,
    DemandsExportResponseRuntype,
    MultiStatusRuntype,
    SourceListImportArrayRuntype,
    SourceListImportResponseRuntype,
} from './demandBackendTypes';
import { DemandSummaryDemandsRequestRuntype, DemandSummaryResponseRuntype } from './demandSummaryBackendTypes';

export const demandEndpoints = {
    'POST /demand/create-sourcing-negotiation': endpoint({
        description: 'Creates a sourcing negotiation demand',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: DemandPostDTORuntype,
        responseBody: DemandDTORuntype,
    }),
    'POST /demand/import': endpoint({
        description: 'Posts demands',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: DemandCreationRequestRuntype,
        responseBody: r.Array(MultiStatusRuntype),
    }),
    'POST /demand/export': endpoint({
        description: 'Fetches demands',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: DemandsExportRequestBodyRuntype,
        responseBody: DemandsExportResponseRuntype,
    }),
    'POST /demand/export-summary-demands': endpoint({
        description: 'Fetches demands along with a demand summary',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: DemandSummaryDemandsRequestRuntype,
        responseBody: DemandSummaryResponseRuntype,
    }),
    'POST /demand/export-csv': endpoint({
        description: 'Fetches as a CSV',
        pathParams: r.Undefined,
        queryParams: r.Record({
            target_date: r.String,
        }),
        requestBody: DemandsExportRequestBodyRuntype,
        //csv string
        responseBody: r.String,
    }),
    'POST /source-list/import': endpoint({
        description: 'Imports a source list',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: SourceListImportArrayRuntype,
        responseBody: SourceListImportResponseRuntype,
    }),
};
