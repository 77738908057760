import { CenteredLayout, FullWidthLayout, MaxWidthLayout } from '@luminovo/design-system';
import { Box, BoxProps, CircularProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { ErrorFallback } from './errorHandlers/ErrorBoundary';
import NavbarRouter from './routers/NavbarRouter';

export const scrollContainerId = 'scrollContainer';

type Props = {
    header?: JSX.Element;
    layout?: keyof typeof containers;
    navbar?: JSX.Element;

    /**
     * By default, the PageLayout will insert a 60px padding at the bottom of the page to reduce the likelyhood
     * of the hubspot icon being drawn on top of regular page content.
     *
     * This behaviour is not always ideal, so you can use this property to toggle it.
     */
    removeHubspotPaddingFix?: boolean;
} & BoxProps;

const containers = {
    'max-width': MaxWidthLayout,
    'full-width': FullWidthLayout,
    centered: CenteredLayout,
    default: Box,
    fragment: React.Fragment,
};

/**
 * Use this as the top component of your page.
 *
 * This layout will render the navbar at the right place.
 *
 * You can customize how the children are laid out by passing the layout property
 * as follows
 *
 * ```
 * <PageLayout layout='centered' />
 * <PageLayout layout='max-width' />
 * <PageLayout layout='full-width' />
 * ```
 *
 * For cases where you need a more complex layout, you can compose the PageLayout
 *
 * with the Layout primites as follows:
 *
 * ```
 * <PageLayout>
 *   <FullWidthLayout>Section 1</FullWidthLayout>
 *   <FullWidthLayout>Section 2</FullWidthLayout>
 * </PageLayout>
 * ```
 *
 */
export function PageLayout({
    header,
    navbar = <NavbarRouter />,
    layout = 'default',
    children,
    removeHubspotPaddingFix,
    ...rest
}: Props) {
    const Container = containers[layout];

    return (
        <Box boxSizing={'border-box'} position={'relative'} display="flex" flexDirection="column" height={'100%'}>
            {navbar}
            {header}
            <Box
                id={scrollContainerId}
                display="flex"
                flexDirection={'column'}
                width="100vw"
                overflow="auto"
                flexGrow={1}
                boxSizing="border-box"
                padding={0}
                paddingBottom={removeHubspotPaddingFix ? undefined : '60px'}
                {...rest}
            >
                <Sentry.ErrorBoundary fallback={ErrorFallback}>
                    <React.Suspense
                        fallback={
                            <CenteredLayout>
                                <CircularProgress />
                            </CenteredLayout>
                        }
                    >
                        {layout === 'fragment' ? children : <Container minWidth="fit-content">{children}</Container>}
                    </React.Suspense>
                </Sentry.ErrorBoundary>
            </Box>
        </Box>
    );
}
