/* eslint-disable camelcase */
import * as r from 'runtypes';
import { runtypeFromEnum } from '../utils/typingUtils';
import { Currency } from './currencyBackendTypes';

export const RowRuntype = r.Dictionary(r.String.nullable(), r.String);
export type RowRecord = Record<string, string | null>;

export type ObjectResponse<ResponseType> = {
    data: ResponseType;
};

export type ObjectResponseItems<ResponseType> = {
    items: ResponseType[];
};

export type PaginatedResponse<ResponseType> = {
    data: ResponseType[];
    total_pages: number;
};

export interface ModuleIdentifier extends r.Static<typeof ModuleIdentifierRuntype> {}
export const ModuleIdentifierRuntype = r.Record({
    designator: r.String,
    id: r.String,
});

export interface ParentAssembly extends r.Static<typeof ParentAssemblyRuntype> {}
export const ParentAssemblyRuntype = r.Record({
    id: r.String,
    designator: r.String,
    parents: r.Array(r.String),
});

export interface ParentsDTO extends r.Static<typeof ParentsDTORuntype> {}
export const ParentsDTORuntype = r.Record({
    multi_parent_items: r.Array(r.Array(ParentAssemblyRuntype)),
});

export enum QuantityUnit {
    Kg = 'Kg',
    Meters = 'Meters',
    Liters = 'Liters',
    Pieces = 'Pieces',
    Boards = 'Boards',
}
export type QuantityUnitType = r.Static<typeof QuantityUnitRuntype>;
export const QuantityUnitRuntype = r.Union(
    r.Literal('Kg'),
    r.Literal('Meters'),
    r.Literal('Liters'),
    r.Literal('Pieces'),
    r.Literal('Boards'),
);

export type QuantityUnitDTO = r.Static<typeof QuantityUnitDTORuntype>;
export const QuantityUnitDTORuntype = r.Record({
    unit: QuantityUnitRuntype,
    quantity: r.Number,
});

export interface ExcelLineType extends r.Static<typeof ExcelLinesRuntype> {}
export const ExcelLinesRuntype = r.Array(
    r.Record({
        line_number: r.Number,
        raw_original_line: RowRuntype,
    }),
);

export interface DesignItemExcelOrigin extends r.Static<typeof DesignItemExcelOriginRuntype> {}
export const DesignItemExcelOriginRuntype = r.Record({
    bom_file_id: r.String,
    design_item_edited_since_import: r.Boolean,
    excel_lines: ExcelLinesRuntype,
});

export interface DesignItemExcelOriginV2 extends r.Static<typeof DesignItemExcelOriginV2Runtype> {}
export const DesignItemExcelOriginV2Runtype = r.Record({
    design_item_edited_since_import: r.Boolean,
    excel_lines_id: r.String,
});

export enum DesignItemOriginTypesNew {
    Manual = 'Manual',
    ExcelFile = 'ExcelFile',
    ExcelFileV2 = 'ExcelFileV2',
    PCB = 'PCB',
    AssemblyImport = 'AssemblyImport',
}
export const DesignItemOriginTypesNewRuntype = runtypeFromEnum(DesignItemOriginTypesNew);

export enum DesignItemOriginTypes {
    Manual = 'Manual',
    ExcelFile = 'ExcelFile',
    PCB = 'PCB',
    AssemblyImport = 'AssemblyImport',
}
export const DesignItemOriginTypesRuntype = runtypeFromEnum(DesignItemOriginTypes);

export type DesignItemOriginDTONew = r.Static<typeof DesignItemOriginDTONewRuntype>;
export const DesignItemOriginDTONewRuntype = r.Union(
    r.Record({
        type: r.Literal(DesignItemOriginTypesNew.Manual),
    }),
    r.Record({
        type: r.Literal(DesignItemOriginTypesNew.AssemblyImport),
    }),
    r.Record({
        type: r.Literal(DesignItemOriginTypesNew.ExcelFile),
        data: DesignItemExcelOriginRuntype,
    }),
    r.Record({
        type: r.Literal(DesignItemOriginTypesNew.ExcelFileV2),
        data: DesignItemExcelOriginV2Runtype,
    }),
    r.Record({
        type: r.Literal(DesignItemOriginTypesNew.PCB),
        data: r.String.nullable(),
    }),
);

export type DesignItemOriginDTO = r.Static<typeof DesignItemOriginDTORuntype>;
export const DesignItemOriginDTORuntype = r.Union(
    r.Record({
        type: r.Literal(DesignItemOriginTypes.Manual),
    }),
    r.Record({
        type: r.Literal(DesignItemOriginTypes.ExcelFile),
        data: DesignItemExcelOriginRuntype,
    }),
    r.Record({
        type: r.Literal(DesignItemOriginTypes.PCB),
        data: r.String.nullable(),
    }),
    r.Record({
        type: r.Literal(DesignItemOriginTypes.AssemblyImport),
    }),
);

export enum ApprovalStatus {
    Rejected = 'Rejected',
    Pending = 'Pending',
    Approved = 'Approved',
}

export enum DoNotPlace {
    DNP = 'DNP',
    Warning = 'Warning',
}

export enum ManufacturingScenarioStatus {
    Warning = 'Warning',
}

export const ApprovalStatusRuntype = runtypeFromEnum(ApprovalStatus);
export const ManufacturingScenarioStatusRuntype = runtypeFromEnum(ManufacturingScenarioStatus);
export const DoNotPlaceRuntype = runtypeFromEnum(DoNotPlace);
export type BomItemStatus = ApprovalStatus | DoNotPlace | ManufacturingScenarioStatus;
export enum OfferOriginEnum {
    Manual = 'Manual',
    QuoteRequest = 'QuoteRequest',
    Octopart = 'Octopart',
    TrustedParts = 'TrustedParts',
    Farnell = 'Farnell',
    Newark = 'Newark',
    Element14 = 'Element14',
    Buerklin = 'Buerklin',
    Mouser = 'Mouser',
    MyArrow = 'MyArrow',
    Arrow = 'Arrow',
    Schukat = 'Schukat',
    Tme = 'Tme',
    Weltron = 'Weltron',
    Gudeco = 'Gudeco',
    Future = 'Future',
    Rutronik = 'Rutronik',
    AvnetAbacus = 'AvnetAbacus',
    AvnetApac = 'AvnetApac',
    AvnetSilica = 'AvnetSilica',
    AvnetEbv = 'AvnetEbv',
    AvnetUsa = 'AvnetUsa',

    Digikey = 'Digikey',

    Orbweaver = 'Orbweaver',

    WuerthEisos = 'WuerthEisos',
    Import = 'Import',
    Consigned = 'Consigned',

    Wuerth = 'Wuerth',
    BetaLayout = 'BetaLayout',
    IBRRingler = 'IBRRingler',

    SafePcb = 'SafePcb',
    APCT = 'APCT',
    Gatema = 'Gatema',
    Alba = 'Alba',

    Stackrate = 'Stackrate',
    Lcsc = 'Lcsc',
    ChipCart = 'ChipCart',
    Eve = 'Eve',
    Ti = 'Ti',
    Tti = 'Tti',
    Samtec = 'Samtec',
    Blume = 'Blume',
    AlElektronik = 'AlElektronik',
    OnlineComponents = 'Onlinecomponents',
    Corestaff = 'Corestaff',
    Master = 'Master',
    Sourcengine = 'Sourcengine',
    Rochester = 'Rochester',
    WinSource = 'WinSource',
    QuestComponents = 'QuestComponents',
    RsComponents = 'RsComponents',
}

export interface EmissionData extends r.Static<typeof EmissionDataRuntype> {}

export const EmissionDataRuntype = r.Record({
    product_phase_gwp_in_kg_co2e_min: r.String,
    product_phase_gwp_in_kg_co2e_max: r.String,
});

export enum ComplianceStatus {
    Compliant = 'Compliant',
    CompliantWithExemption = 'CompliantWithExemption',
    NonCompliant = 'NonCompliant',
    NotRequired = 'NotRequired',
    Unknown = 'Unknown',
}
export type ComplianceStatusString = keyof typeof ComplianceStatus;

export const ComplianceStatusRuntype = runtypeFromEnum(ComplianceStatus);

export enum RohsVersion {
    Rohs_2002_95_EC = 'Rohs_2002_95_EC',
    Rohs2_2011_65_EU = 'Rohs2_2011_65_EU',
    Rohs2_2015_863_EU = 'Rohs2_2015_863_EU',
}
export const RohsVersionRuntype = runtypeFromEnum(RohsVersion);

export enum Qualification {
    DLA = 'DLA',
    RadHard = 'RadHard',
    Space = 'Space',
    UL = 'UL',
}
export const QualificationRuntype = runtypeFromEnum(Qualification);

export const CurrencyRuntype = runtypeFromEnum(Currency);

export type Option<ValueType> = ValueType | null;

export interface MonetaryValueBackend extends r.Static<typeof MonetaryValueBackendRuntype> {}

export const MonetaryValueBackendRuntype = r.Record({
    amount: r.String,
    currency: CurrencyRuntype,
});

export interface ExchangedMonetaryValueBackend extends r.Static<typeof ExchangedMonetaryValueBackendRuntype> {}

export const ExchangedMonetaryValueBackendRuntype = r.Record({
    converted_mv: MonetaryValueBackendRuntype,
    original_mv: MonetaryValueBackendRuntype,
});

export const ExchangedMonetaryValueBackendConvertedMVOnlyRuntype =
    ExchangedMonetaryValueBackendRuntype.omit('original_mv');

export const ExchangedMonetaryValueBackendOriginalMVOnlyRuntype =
    ExchangedMonetaryValueBackendRuntype.omit('converted_mv');

export type ExchangedMonetaryValueBackendConvertedMVOnlyDTO = r.Static<
    typeof ExchangedMonetaryValueBackendConvertedMVOnlyRuntype
>;

export const BucketRuntype = r.Record({ key: r.String, doc_count: r.Number });

export const NumericBucketRuntype = r.Record({ key: r.Number, doc_count: r.Number });

export interface ElasticTotalHitsDTO extends r.Static<typeof ElasticTotalHitsDTORuntype> {}
export const ElasticTotalHitsDTORuntype = r.Record({
    value: r.Number,
    relation: r.Union(r.Literal('eq'), r.Literal('gt'), r.Literal('gte')),
});
