import { Trans, t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Message, Text, useNavigate } from '@luminovo/design-system';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { route } from '../../utils/routes';
export const QuotationWarnings = ({ rfqId }: { rfqId: string }): JSX.Element => {
    const navigate = useNavigate();

    const { data: warnings } = useHttpQuery('GET /rfqs/:rfqId/quotation/warnings', {
        pathParams: { rfqId },
    });

    if (!isPresent(warnings)) {
        return <></>;
    }

    switch (warnings.type) {
        case 'Ok':
            return <></>;
        case 'QuotationDocumentMissing':
            return (
                <Message
                    variant="red"
                    attention="high"
                    size="large"
                    title={t`No quotation document`}
                    message={t`You did not upload a quotation document. Customers won't be able to download it in the customer portal`}
                    action={{
                        label: t`Upload quotation`,
                        onClick: () => navigate(route('/rfqs/:rfqId/quotation', { rfqId })),
                    }}
                />
            );
        case 'CalculationMissing':
            const assemblyDesignators = warnings.data.assembly_designators;

            return (
                <Message
                    variant="red"
                    attention="high"
                    size="large"
                    title={t`Calculation missing`}
                    message={
                        <Text>
                            <Trans>
                                There is no calculation for the following assemblies. Customer won't be able to checkout
                                in the customer portal because we cannot calculate the total price.
                            </Trans>
                            <ul>
                                {assemblyDesignators.map((designator, i) => (
                                    <li key={`${designator}-${i}`}>{designator}</li>
                                ))}
                            </ul>
                        </Text>
                    }
                    action={{
                        label: t`Create calculation`,
                        onClick: () => navigate(route('/rfqs/:rfqId/calculation', { rfqId })),
                    }}
                />
            );
    }
};
