import { t } from '@lingui/macro';
import { compareByString, formatDecimal } from '@luminovo/commons';
import {
    CenteredLayout,
    createColumnHelper,
    Flexbox,
    Message,
    NonIdealState,
    PrimaryButton,
    TanStackTable,
    useTanStackTable,
} from '@luminovo/design-system';
import { SupplierAndStockLocationDTO, SupplierLineCardManufacturerDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import React from 'react';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useDialogEditApprovedVendorList } from './SupplierLineCardDialog/EditSupplierApprovedVendorListDialog';

const LineCardRulesMessage: React.FunctionComponent = () => {
    return (
        <Message
            size="large"
            attention="low"
            variant="blue"
            title={t`What is the approved vendor list?`}
            message={t`Approved vendor list helps you implement your strategy about which manufacturers you want to negotiate with which suppliers. This is different from the line card, which simply indicates which manufacturers are offered by a supplier.`}
        />
    );
};

type SupplierLineCardTableData = SupplierLineCardManufacturerDTO;
type SupplierLineCardTableSharedContext = {
    supplierAndStockLocation: SupplierAndStockLocationDTO;
};

const columnHelper = createColumnHelper<SupplierLineCardTableData, SupplierLineCardTableSharedContext>();

const columns = [
    columnHelper.text('name', {
        id: 'manufacturer',
        label: () => t`Manufacturer`,
        size: 140,
        cell: (item) => item.getValue(),
    }),
    columnHelper.number('part_count', {
        id: 'partCount',
        label: () => t`# Parts`,
        size: 100,
        description: () => t`Number of parts for this manufacturer`,
        cell: (item) => formatDecimal(item.getValue()),
    }),
];

function EmptyPlaceholder() {
    return (
        <CenteredLayout>
            <NonIdealState
                title={t`No approved vendor list`}
                description={t`Create entries in the approved vendor list with the edit button above.`}
            />
        </CenteredLayout>
    );
}

const ActionButton: React.FunctionComponent<{
    sharedContext: SupplierLineCardTableSharedContext;
}> = ({ sharedContext }) => {
    const { openDialog } = useDialogEditApprovedVendorList();

    return (
        <Flexbox gap={8}>
            <PrimaryButton size="medium" onClick={() => openDialog(sharedContext.supplierAndStockLocation.supplier.id)}>
                {t`Edit approved vendor list`}
            </PrimaryButton>
        </Flexbox>
    );
};

export function ApprovedVendorListTab({
    supplierAndStockLocation,
}: {
    supplierAndStockLocation: SupplierAndStockLocationDTO;
}) {
    const { data } = useHttpQuery(
        'GET /suppliers/:id/line-card-rules',
        {
            pathParams: { id: supplierAndStockLocation.supplier.id },
        },
        {
            select: (res) => res.manufacturers.sort((a, b) => compareByString(a.name, b.name)) ?? [],
        },
    );

    const { table } = useTanStackTable({
        columns,
        data,
        sharedContext: {
            supplierAndStockLocation,
        },
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enablePersistentColumnVisibility: true,
        enablePersistentScrollPosition: false,
    });

    return (
        <Flexbox flexDirection={'column'} gap={18} height={'100%'}>
            <LineCardRulesMessage />
            <Box height={'100%'}>
                <TanStackTable
                    table={table}
                    size="medium"
                    EmptyPlaceholder={EmptyPlaceholder}
                    ActionButton={ActionButton}
                />
            </Box>
        </Flexbox>
    );
}
