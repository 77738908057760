import { Trans, t } from '@lingui/macro';
import { Permission, isAuthorized } from '@luminovo/auth';
import { formatLongDateTime, formatRelativeTime } from '@luminovo/commons';
import {
    Column,
    DataTable,
    Dialog,
    DialogContent,
    Flexbox,
    HeaderCheckbox,
    PrimaryButton,
    RowCheckbox,
    SecondaryButton,
    TertiaryButton,
    Text,
    Tooltip,
    colorSystem,
    serialiseSearchBlocks,
    useDataTableState,
} from '@luminovo/design-system';
import {
    ApprovalStatus,
    EmsPartNumberPartMatchRequest,
    FullPart,
    GenericFullPart,
    LinkedPartsFilter,
    OtsComponentFull,
    OtsFullPart,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { SyncedErpDataDetails } from '@luminovo/sourcing-core';
import { Launch } from '@mui/icons-material';
import { Box, DialogTitle, styled } from '@mui/material';
import { useCallback } from 'react';
import { TechParamsMultiChip } from '../../../../components/TechParamsMultiChip';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { RenderDescription, RenderManufacturer, RenderMpn } from '../../../../components/partColumns';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { FullPartWithApprovalStatus } from '../../../../resources/part/partFrontendTypes';
import { route } from '../../../../utils/routes';
import { SearchField } from '../../../PartLibrary/PartSearchPage/PartSearchInput';

export type PartToAddAsPartOption = OtsFullPart | GenericFullPart | OtsComponentFull;

export interface OtsCompLinkingDialogProps {
    otsComponent: OtsComponentFull;
    partsToLink: FullPart[];
    onConfirm: () => void;
    onCancel: () => void;
}

export type OtsCompLinkingDialog = {
    openDialog: ({ otsComponent, partsToLink, onConfirm }: OtsCompLinkingDialogProps) => void;
};

type ComponentLinkingDialogType =
    | {
          shouldOpenComponentLinkingDialog: false;
      }
    | {
          shouldOpenComponentLinkingDialog: true;
          otsComponent: OtsComponentFull;
          partsToLink: FullPart[];
          partsToAddAsPartOption: PartToAddAsPartOption[];
      };

export const otsComponentLinkingDialog = ({
    partOptions,
    partsToAddAsPartOption,
    permissions,
}: {
    partOptions: FullPartWithApprovalStatus[];
    partsToAddAsPartOption: FullPart[];
    permissions: Permission[];
}): ComponentLinkingDialogType => {
    if (!isAuthorized(permissions, ['edit:ipn'])) {
        return { shouldOpenComponentLinkingDialog: false };
    }
    if (partsToAddAsPartOption.length === 0) {
        return { shouldOpenComponentLinkingDialog: false };
    }
    if (partsToAddAsPartOption.some((part) => isCustomFullPart(part))) {
        return { shouldOpenComponentLinkingDialog: false };
    }

    const approvedOrPendingPartOptions: FullPart[] = partOptions.reduce((result: FullPart[], part) => {
        if (part.approvalStatus === ApprovalStatus.Approved || part.approvalStatus === ApprovalStatus.Pending) {
            result.push(part.part);
        }
        return result;
    }, []);

    // Case when we're adding a component without matches as part option
    const componentsToAdd = partsToAddAsPartOption.filter(isOtsComponentFull);
    const componentToAddWithoutMatches = componentsToAdd.filter((component) => component.matches.length === 0);
    if (componentToAddWithoutMatches.length === 1) {
        const component = componentToAddWithoutMatches[0];
        if (component.state === 'Removed') {
            return { shouldOpenComponentLinkingDialog: false };
        }
        const hasEligiblePartsToLink =
            approvedOrPendingPartOptions.filter((part) => isOtsFullPart(part) || isGenericFullPart(part)).length > 0;

        if (hasEligiblePartsToLink) {
            return {
                shouldOpenComponentLinkingDialog: true,
                otsComponent: component,
                partsToLink: approvedOrPendingPartOptions,
                partsToAddAsPartOption: partsToAddAsPartOption.filter(
                    (part) => isOtsFullPart(part) || isGenericFullPart(part) || isOtsComponentFull(part),
                ),
            };
        }
    }

    // Case when we're adding an OTS or generic part and there's a single ots component without matches as part option
    const otsOrGenericPartsToAdd = partsToAddAsPartOption.filter(
        (part) => isOtsFullPart(part) || isGenericFullPart(part),
    );
    if (otsOrGenericPartsToAdd.length > 0) {
        const otsCompAddedAsPartOptions: OtsComponentFull[] = approvedOrPendingPartOptions.filter(isOtsComponentFull);
        const componentPartOptWithoutMatches = otsCompAddedAsPartOptions.filter(
            (part) => part.matches.length === 0 && part.state === 'Active',
        );
        const hasSingleComponentPartOptWoutMatches = componentPartOptWithoutMatches.length === 1;
        if (hasSingleComponentPartOptWoutMatches) {
            return {
                shouldOpenComponentLinkingDialog: true,
                otsComponent: componentPartOptWithoutMatches[0],
                partsToLink: otsOrGenericPartsToAdd,
                partsToAddAsPartOption: partsToAddAsPartOption.filter(
                    (part) => isOtsFullPart(part) || isGenericFullPart(part) || isOtsComponentFull(part),
                ),
            };
        }
    }
    return { shouldOpenComponentLinkingDialog: false };
};

export const useOtsComponentLinkingDialog = (): OtsCompLinkingDialog => {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: ({ otsComponent, partsToLink, onConfirm, onCancel }: OtsCompLinkingDialogProps) =>
            setDialog(
                <Dialog open={true} maxWidth="xl" fullWidth={true} onClose={closeDialog}>
                    <DialogTitle>
                        <LinkPartsToComponentDialogHeader otsComponent={otsComponent} />
                    </DialogTitle>
                    <DialogContent>
                        <Flexbox flexDirection="column" gap="24px">
                            <LinkPartsToComponentDialogContent
                                otsComponent={otsComponent}
                                closeDialog={closeDialog}
                                partsToLink={partsToLink}
                                onConfirm={onConfirm}
                                onCancel={onCancel}
                            />
                        </Flexbox>
                    </DialogContent>
                </Dialog>,
            ),
    };
};

const idExtractor = (item: FullPart) => item.id;

const LinkPartsToComponentDialogContent = ({
    otsComponent,
    closeDialog,
    partsToLink,
    onConfirm,
    onCancel,
}: {
    otsComponent: OtsComponentFull;
    closeDialog: () => void;
    partsToLink: FullPart[];
    onConfirm: () => void;
    onCancel: () => void;
}) => {
    const otsPartOptions: OtsFullPart[] = partsToLink.filter(isOtsFullPart);
    const genericPartOptions: GenericFullPart[] = partsToLink.filter(isGenericFullPart);
    const tableState = useDataTableState({
        columns: columnsWithSelection,
        items: [...genericPartOptions, ...otsPartOptions],
        persistenceId: 'link-parts-table',
        selectionOptions: { idExtractor, defaultSelectedIds: partsToLink.map(idExtractor) },
    });

    const { mutateAsync: linkAndAddPart, isPending: isLoading } = useHttpMutation('PATCH /ipns/matches', {
        snackbarMessage: t`Selected parts were successfully linked`,
        onSuccess: () => {
            onConfirm();
            closeDialog();
        },
    });

    const handleLinkPart = () => {
        const otsPartIds = tableState.state.selectedIds.filter((i) => otsPartOptions.find((p) => p.id === i));
        const genericPartIds = tableState.state.selectedIds.filter((i) => genericPartOptions.find((p) => p.id === i));
        const body: EmsPartNumberPartMatchRequest = {
            ipn: otsComponent.id ?? '',
            /* eslint-disable camelcase */
            part_matches: {
                off_the_shelf: otsPartIds,
                generic: genericPartIds,
            },
            /* eslint-enable camelcase */
        };
        linkAndAddPart({ requestBody: body });
    };

    return (
        <>
            <Text>
                <Trans>This IPN has no linked parts, would you like to link the selected parts to this IPN?</Trans>
            </Text>
            <Box display="grid" gridTemplateColumns={'468px auto'} gap="24px">
                <Container>
                    <Text variant="h4">
                        <Trans>Specifications</Trans>
                    </Text>
                    {otsComponent.component_specification && (
                        <TechParamsMultiChip componentSpecification={otsComponent.component_specification} />
                    )}
                    <Flexbox justifyContent="space-between">
                        <Text variant="h5">
                            <Trans>Synced ERP data</Trans>
                        </Text>
                        <Tooltip title={formatLongDateTime(otsComponent.last_imported_at)}>
                            <Text variant="body-small" color={colorSystem.neutral[6]}>
                                Last imported {formatRelativeTime(otsComponent.last_imported_at)}
                            </Text>
                        </Tooltip>
                    </Flexbox>
                    {otsComponent.part_specifications.map((data, i) => {
                        return <SyncedErpDataDetails partSpecifications={data} key={i} />;
                    })}
                </Container>
                <Container style={{ border: `2px solid ${colorSystem.primary[4]}` }}>
                    <Text variant="h4">
                        <Trans>Linked parts</Trans>
                    </Text>
                    <LinkPartsTable items={[]} />
                    <Text variant="h4">
                        <Trans>New parts to be linked</Trans>
                    </Text>
                    <DataTable tableState={tableState} size="medium" />
                </Container>
            </Box>
            <Flexbox gap="8px" justifyContent="flex-end">
                <SecondaryButton
                    size="medium"
                    data-testid={'cancel-link-selected-parts'}
                    onClick={() => {
                        onCancel();
                        closeDialog();
                    }}
                    disabled={isLoading}
                >
                    <Trans>No</Trans>
                </SecondaryButton>
                <PrimaryButton
                    size="medium"
                    isLoading={isLoading}
                    onClick={handleLinkPart}
                    disabled={tableState.state.selectedIds.length === 0}
                >
                    <Trans>Link selected parts</Trans>
                </PrimaryButton>
            </Flexbox>
        </>
    );
};

const LinkPartsToComponentDialogHeader = ({ otsComponent }: { otsComponent: OtsComponentFull }) => {
    const handleOnClick = useCallback(
        () =>
            window.open(
                route(
                    '/parts/components/ipn',
                    {},
                    {
                        ipnId: otsComponent.id,
                        q: serialiseSearchBlocks<OtsComponentFull>({
                            searchBlocks: [
                                {
                                    field: SearchField.linkedParts,
                                    op: 'is',
                                    parameter: LinkedPartsFilter.NoLinkedParts,
                                },
                            ],
                        }),
                    },
                ),
                '_blank',
                'noopener noreferrer',
            ),
        [otsComponent.id],
    );

    return (
        <Flexbox alignItems="center" justifyContent="space-between">
            <Text variant="h2">
                <Trans>Link parts to IPN {otsComponent.id}</Trans>
            </Text>
            <Tooltip title={t`Open IPNs without linked parts`} placement="top" arrow>
                <TertiaryButton size="small" startIcon={<Launch />} onClick={handleOnClick}>
                    <Trans>IPNs with no linked parts</Trans>
                </TertiaryButton>
            </Tooltip>
        </Flexbox>
    );
};

const Container = styled(Flexbox)({
    flexDirection: 'column',
    gap: '24px',
    border: `1px solid ${colorSystem.neutral[3]}`,
    borderRadius: '8px',
    padding: '20px',
    maxHeight: '600px',
    overflow: 'auto',
});

const columns: Column<GenericFullPart | OtsFullPart>[] = [
    { id: 'mpn', label: <Trans>MPN</Trans>, render: ({ data }) => <RenderMpn part={data} /> },
    {
        id: 'manufacturer',
        label: <Trans>Manufacturer</Trans>,
        render: ({ data }) => <RenderManufacturer part={data} />,
    },
    {
        id: 'description',
        label: <Trans>Description</Trans>,
        render: ({ data }) => <RenderDescription part={data} />,
    },
];

const columnsWithSelection: Column<GenericFullPart | OtsFullPart>[] = [
    {
        id: 'selection',
        label: '',
        renderHead: (props) => {
            return <HeaderCheckbox {...props} disabled={false} />;
        },
        render: (data) => {
            return <RowCheckbox data={data} />;
        },
    },
    ...columns,
];

const LinkPartsTable = ({ items }: { items: FullPart[] }) => {
    const otsAndGenericParts: (GenericFullPart | OtsFullPart)[] = items.filter(isGenericFullPart || isOtsFullPart);
    const tableState = useDataTableState({
        columns,
        items: otsAndGenericParts,
        persistenceId: 'link-parts-table',
        paginationOptions: { showPagination: false },
    });
    return (
        <DataTable
            size="medium"
            tableState={tableState}
            overrides={{
                NoResultsComponent: () => (
                    <Flexbox justifyContent="center" padding="16px">
                        <Text>
                            <Trans>No linked parts</Trans>
                        </Text>
                    </Flexbox>
                ),
            }}
        />
    );
};
