import { useHttpQuery } from '@/resources/http/useHttpQuery';
import { Trans } from '@lingui/macro';
import { assertPresent } from '@luminovo/commons';
import { Button } from '@mui/material';

export const DownloadQuotationButton = ({
    rfqId,
    color,
    background,
    hideIfNoQuotation,
}: {
    rfqId: string;
    color: string;
    background: string;
    hideIfNoQuotation?: boolean;
}): JSX.Element => {
    const { data: quotationUrl, isLoading: isLoadingQuotationUrl } = useHttpQuery(
        'GET /rfqs/:rfqId/quotation',
        { pathParams: { rfqId } },
        { select: (res) => res.data?.url },
    );

    if (hideIfNoQuotation && !quotationUrl) {
        return <></>;
    }

    const href = isLoadingQuotationUrl ? '' : assertPresent(quotationUrl);

    return (
        <Button href={href} style={{ background, color, border: 'none' }} download>
            <Trans>Download quotation</Trans>
        </Button>
    );
};
