import { MonthAndYearDTO, MonthlyDemandDTO, QuantityUnitDTO } from '@luminovo/http-client';

export function generateMonthlyDemands(existingDemands: MonthlyDemandDTO[]): {
    month_and_year: MonthAndYearDTO;
    gross_quantity: QuantityUnitDTO;
    net_quantity: QuantityUnitDTO;
}[] {
    if (existingDemands.length === 0) {
        return [];
    }
    // if two demands have the same month and year, we should error
    const duplicateDemands = existingDemands.filter(
        (demand, index, self) =>
            self.findIndex(
                (t) =>
                    t.month_and_year.year === demand.month_and_year.year &&
                    t.month_and_year.month === demand.month_and_year.month,
            ) !== index,
    );
    if (duplicateDemands.length > 0) {
        throw new Error('Duplicate demands found');
    }

    const quantityUnits = [...new Set(existingDemands.map((d) => d.gross_quantity.unit))];
    if (quantityUnits.length > 1) {
        throw new Error('All demands must have the same quantity unit');
    }
    const quantityUnit = quantityUnits[0];

    const current = new Date();
    const months: Array<MonthAndYearDTO> = [];

    // Determine the last month and year from existing demands
    const lastDemand = existingDemands.reduce(
        (latest, demand) => {
            const latestMonthAndYear = new Date(latest.month_and_year.year, latest.month_and_year.month - 1);
            const demandMonthAnYear = new Date(demand.month_and_year.year, demand.month_and_year.month - 1);
            return demandMonthAnYear > latestMonthAndYear ? demand : latest;
        },
        {
            month_and_year: {
                year: new Date().getFullYear(),
                month: 1,
            },
            gross_quantity: {
                unit: quantityUnit,
                quantity: 0,
            },
            net_quantity: {
                unit: quantityUnit,
                quantity: 0,
            },
        },
    );
    // Start from current month and go until the last demand date
    let year = current.getFullYear();
    let month = current.getMonth() + 1; // JavaScript months are 0-based
    while (
        year < lastDemand.month_and_year.year ||
        (year === lastDemand.month_and_year.year && month <= lastDemand.month_and_year.month)
    ) {
        months.push({ year, month });
        month++;
        if (month > 12) {
            month = 1;
            year++;
        }
    }

    return months.map((yearMonth) => {
        const existingDemand = existingDemands.find(
            (d) => d.month_and_year.year === yearMonth.year && d.month_and_year.month === yearMonth.month,
        );
        if (existingDemand) {
            return existingDemand;
        }
        const defaultQuantityUnit: QuantityUnitDTO = { unit: quantityUnit, quantity: 0 };
        return {
            /*eslint-disable camelcase*/
            month_and_year: yearMonth,
            gross_quantity: defaultQuantityUnit,
            net_quantity: defaultQuantityUnit,
            /*eslint-enable camelcase*/
        };
    });
}
