import { Trans, t } from '@lingui/macro';
import { SecondaryButton, colorSystem, useNavigate } from '@luminovo/design-system';
import { RfqStatus } from '@luminovo/http-client';
import { isRfqArchivable } from '@luminovo/manufacturing-core';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useMutationArchiveRfq } from '../../../resources/rfq/rfqHandler';
import { route } from '../../../utils/routes';
import { ChangeRfQStatusDialog, ChangeRfQStatusFormValues } from './ChangeRfQStatusDialog';
import { useDuplicateRfQDialog } from './DashboardDuplicateRfqForm';
import { useDeleteRfQDialog } from './DeleteRfQDialog';
import { useOrderShippedConfirmationDialog } from './OrderShippedConfirmationDialog';
interface RfqActionsButtonProps {
    rfqStatus: RfqStatus;
    rfqId: string;
    rfqName: string;
    isRfqArchived: boolean;
}

const useMutationUpdateRfqStatus = (rfqId: string) => {
    const { mutateAsync } = useHttpMutation('PATCH /rfqs/:rfqId', {
        snackbarMessage: t`RfQ status updated`,
    });

    const onSubmit = (data: ChangeRfQStatusFormValues) => {
        return mutateAsync({
            pathParams: { rfqId },
            requestBody: {
                type: 'Ems',
                data: {
                    status: data.rfqStatus,
                    /* eslint-disable-next-line camelcase */
                    shipping_tracking_link:
                        data.rfqStatus === RfqStatus.OrderShipped ? data.shippingTrackingLink || null : undefined,
                    internal_number:
                        data.rfqStatus === RfqStatus.OrderConfirmed ? data.internalNumber || null : undefined,
                },
            },
        });
    };

    const { openDialog } = useOrderShippedConfirmationDialog(onSubmit);

    return async (data: ChangeRfQStatusFormValues) => {
        if (data.rfqStatus !== RfqStatus.OrderShipped) {
            return await onSubmit(data);
        }

        openDialog(data.shippingTrackingLink);
    };
};

export const RfqActionsButton: React.FunctionComponent<RfqActionsButtonProps> = ({
    rfqStatus,
    rfqId,
    rfqName,
    isRfqArchived,
}: RfqActionsButtonProps): JSX.Element => {
    const [changeRfQStatusDialogIsOpen, setChangeRfQStatusDialogIsOpen] = React.useState(false);

    const { openDialog } = useDuplicateRfQDialog({ rfqId, rfqName });
    const { mutateAsync: archiveRfqAsync } = useMutationArchiveRfq(rfqId, !isRfqArchived);

    const mutateAsync = useMutationUpdateRfqStatus(rfqId);

    function openChangeRfQStatusDialog(): void {
        setChangeRfQStatusDialogIsOpen(true);
    }

    const { openDialog: openDeleteRfqDialog } = useDeleteRfQDialog();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleChangeRfQStatus = (): void => {
        openChangeRfQStatusDialog();
        setAnchorEl(null);
    };
    const handleEditRfq = (): void => {
        navigate(route('/rfqs/:rfqId/edit', { rfqId }));
        setAnchorEl(null);
    };

    const handleArchive = (): void => {
        archiveRfqAsync(!isRfqArchived);
    };

    return (
        <>
            <SecondaryButton onClick={handleOpen} size="medium" aria-haspopup="true" startIcon={<MoreHorizIcon />}>
                <Trans>RfQ</Trans>
            </SecondaryButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEditRfq}>
                    <Trans>Edit</Trans>
                </MenuItem>

                <MenuItem disabled={isRfqArchived} onClick={handleChangeRfQStatus}>
                    <Trans>Change status</Trans>
                </MenuItem>

                <MenuItem onClick={handleArchive} disabled={!isRfqArchivable(rfqStatus)}>
                    {isRfqArchived && <Trans>Unarchive</Trans>}
                    {!isRfqArchived && <Trans>Archive</Trans>}
                </MenuItem>
                <MenuItem onClick={openDialog}>
                    <Trans>Duplicate</Trans>
                </MenuItem>
                <MenuItem onClick={() => openDeleteRfqDialog(rfqId)}>
                    <span style={{ color: colorSystem.red[6] }}>
                        <Trans>Delete</Trans>
                    </span>
                </MenuItem>
            </Menu>
            <ChangeRfQStatusDialog
                rfqId={rfqId}
                isDialogOpen={changeRfQStatusDialogIsOpen}
                setIsDialogOpen={setChangeRfQStatusDialogIsOpen}
                onSubmit={mutateAsync}
            />
        </>
    );
};
