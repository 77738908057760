import { t } from '@lingui/macro';
import { colorSystem } from '@luminovo/design-system';
import { BannerCard } from './BannerCard';
import { DownloadQuotationButton } from './DownloadQuotationButton';
import { GREEN_GRADIENT } from './gradients';

export const BannerCardOrderPlaced = ({ rfqId }: { rfqId: string }): JSX.Element => {
    return (
        <BannerCard
            background={GREEN_GRADIENT}
            icon="📨"
            title={`${t`Order placed`}.`}
            description={t`Your order has been successfully placed, we are processing your request.`}
            callToAction={
                <DownloadQuotationButton
                    rfqId={rfqId}
                    color={colorSystem.neutral.white}
                    background="rgba(255, 255, 255, .3)"
                    hideIfNoQuotation
                />
            }
        />
    );
};
