import { t } from '@lingui/macro';
import {
    assertPresent,
    formatDecimal,
    formatToLongDate,
    isEqual,
    isPresent,
    throwErrorUnlessProduction,
} from '@luminovo/commons';
import { Bookmark, BookmarkBorderOutlined } from '@mui/icons-material';
import { Column, ColumnFilter, Table } from '@tanstack/react-table';
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { colorSystem } from '../../../theme';
import { CheckableChip, Chip } from '../../Chip';
import { Flexbox } from '../../Flexbox';
import { SearchInput } from '../../SearchField';
import { Text } from '../../Text';
import { Tooltip } from '../../Tooltip';
import { SecondaryButton, TertiaryButton } from '../../buttons';
import { XlsIcon } from '../../icons';
import { isFilterValue } from '../Filters/createFilterValueUpdater';
import { getFilterConfig, SupportedFilterFn, TanStackTableProps } from '../type';
import { arrayToggleItem, parseOptionsValuesOrFunc } from '../utils';
import { ColumFilterMenu, FilterMenu, FilterMenuState } from './FilterMenu';
import { SortMenu } from './SortMenu';
import { handleExportAsExcel, VisibilityMenu } from './VisibilityMenu';

const TANSTACK_MENU_BAR_HEIGHT = 44;
const TANSTACK_FILTER_TAG_HEIGHT = 32;

function extractVisibility<TData, TSharedContext>(
    enableMenuBar: TanStackTableProps<TData, TSharedContext>['enableMenuBar'],
    table: Table<TData>,
) {
    const sharedContext = table.options.meta?.sharedContext as TSharedContext;

    // Parse the enableMenuBar option
    const visibility = parseOptionsValuesOrFunc(enableMenuBar, {
        table,
        sharedContext,
    });

    const defaultVisibility = {
        globalSearch: true,
        quickFilters: true,
        resultCount: true,
        controlButtons: true,
        exportExcelButton: false,
        actionButton: true,
    };

    if (typeof visibility === 'boolean') {
        return {
            globalSearch: visibility,
            quickFilters: visibility,
            resultCount: visibility,
            controlButtons: visibility,
            exportExcelButton: visibility,
            actionButton: visibility,
        };
    }

    if (typeof visibility === 'object' && visibility !== null) {
        return {
            globalSearch: visibility.globalSearch ?? defaultVisibility.globalSearch,
            quickFilters: visibility.quickFilters ?? defaultVisibility.quickFilters,
            resultCount: visibility.resultCount ?? defaultVisibility.resultCount,
            controlButtons: visibility.controlButtons ?? defaultVisibility.controlButtons,
            exportExcelButton: visibility.exportExcelButton ?? defaultVisibility.exportExcelButton,
            actionButton: visibility.actionButton ?? defaultVisibility.actionButton,
        };
    }

    // Return default settings if visibility is not valid
    return defaultVisibility;
}

export function calculateBoxHeight<TData, TSharedContext>(
    table: Table<TData>,
    enableMenuBar: TanStackTableProps<TData, TSharedContext>['enableMenuBar'],
): string {
    const visibility = extractVisibility(enableMenuBar, table);
    const isMenuBarVisible = Object.values(visibility).some((v) => v);
    const hasFilters = table.getState().columnFilters.length > 0;

    return `max(200px, calc(100% - ${isMenuBarVisible ? TANSTACK_MENU_BAR_HEIGHT : 0}px - ${hasFilters ? TANSTACK_FILTER_TAG_HEIGHT : 0}px))`;
}

export function TanStackMenuBar<TData, TSharedContext>({
    table,
    enableMenuBar,
    MenuBarTitle,
    ActionButton,
}: TanStackTableProps<TData, TSharedContext>): JSX.Element {
    const visibility = extractVisibility(enableMenuBar, table);
    const isMenuBarVisible = Object.values(visibility).some((v) => v);
    const hasFilters = table.getState().columnFilters.length > 0;

    return (
        <Flexbox flexDirection={'column'} gap={'12px'} paddingBottom={isMenuBarVisible || hasFilters ? '12px' : 0}>
            {isMenuBarVisible && (
                <Toolbar
                    table={table}
                    visibility={visibility}
                    MenuBarTitle={MenuBarTitle}
                    ActionButton={ActionButton}
                />
            )}
            <FilterChipsContainer table={table} />
        </Flexbox>
    );
}

function ResultCount<TData>({ table }: { table: Table<TData> }) {
    if (table.getIsAllRowsSelected() || table.getIsSomeRowsSelected()) {
        return (
            <Text variant={'body'} color={colorSystem.neutral[6]}>
                {t`${formatDecimal(table.getSelectedRowModel().rows.length)} selected`}
            </Text>
        );
    } else {
        return (
            <Text variant={'body'} color={colorSystem.neutral[6]}>
                {t`Showing ${formatDecimal(table.options.meta?.totalCount ?? table.getRowModel().rows.length)} results`}
            </Text>
        );
    }
}

function Toolbar<TData, TSharedContext>({
    table,
    visibility,
    MenuBarTitle,
    ActionButton,
}: {
    table: TanStackTableProps<TData, TSharedContext>['table'];
    visibility: ReturnType<typeof extractVisibility>;
    MenuBarTitle: TanStackTableProps<TData, TSharedContext>['MenuBarTitle'];
    ActionButton: TanStackTableProps<TData, TSharedContext>['ActionButton'];
}) {
    const sharedContext = table.options.meta?.sharedContext as TSharedContext;
    const rows = table.getCoreRowModel().rows;

    const quickFilters = table.getAllLeafColumns().flatMap((column) => {
        const quickFilters = column.columnDef.meta?.quickFilters ?? [];

        return quickFilters.map(({ label, value, showCount, replaceExistingFilters, overrides }) => {
            const filterFn = column.getFilterFn()!;
            const filteredRows = rows.filter((row) =>
                filterFn(row, column.id, value, (filterMeta) => {
                    row.columnFiltersMeta[column.id] = filterMeta;
                }),
            );
            return {
                label,
                replaceExistingFilters,
                count: showCount !== undefined ? filteredRows.length : undefined,
                columnFilter: { id: column.id, value },
                overrides,
            };
        });
    });

    return (
        <Flexbox alignItems={'center'} gap={'8px'}>
            {MenuBarTitle && <MenuBarTitle table={table} sharedContext={sharedContext} />}
            {visibility.globalSearch && (
                <SearchInput
                    debounceWait={50}
                    value={table.getState().globalFilter}
                    onChange={(value) => table.setGlobalFilter(value)}
                />
            )}
            <Flexbox flexGrow={1} overflow={'auto'}>
                {visibility.quickFilters && (
                    <AutoSizer disableHeight>
                        {({ width }: { width: number }) => (
                            <Flexbox gap={'8px'} padding={'2px'} style={{ minWidth: 0, maxWidth: width }}>
                                {quickFilters.map((quickFilter, idx) => {
                                    const formattedCount =
                                        quickFilter.count !== undefined ? formatDecimal(quickFilter.count) : undefined;

                                    const label = [formattedCount, quickFilter.label()]
                                        .filter((x) => x !== undefined)
                                        .join(' ');
                                    return (
                                        <CheckableChip
                                            key={idx}
                                            done={false}
                                            label={label}
                                            overrides={quickFilter.overrides}
                                            selected={table
                                                .getState()
                                                .columnFilters.some((f) => isEqual(f, quickFilter.columnFilter))}
                                            onClick={() => {
                                                if (quickFilter.replaceExistingFilters) {
                                                    table.setColumnFilters((old) => {
                                                        const isAlreadySet = old.some((filter) =>
                                                            isEqual(filter, quickFilter.columnFilter),
                                                        );

                                                        if (isAlreadySet) {
                                                            return old.filter(
                                                                (filter) => !isEqual(filter, quickFilter.columnFilter),
                                                            );
                                                        } else {
                                                            return [quickFilter.columnFilter];
                                                        }
                                                    });
                                                } else {
                                                    table.setColumnFilters((old) =>
                                                        arrayToggleItem(old, quickFilter.columnFilter),
                                                    );
                                                }
                                            }}
                                            showEllipsis={true}
                                        />
                                    );
                                })}
                            </Flexbox>
                        )}
                    </AutoSizer>
                )}
            </Flexbox>
            <Flexbox gap={'8px'} alignItems={'center'}>
                {visibility.resultCount && !table.options.meta?.isLoading && <ResultCount table={table} />}
                {visibility.controlButtons && (
                    <>
                        <FilterMenu table={table} />
                        <SortMenu table={table} />
                        <VisibilityMenu table={table} />
                    </>
                )}
                {visibility.exportExcelButton && (
                    <SecondaryButton
                        startIcon={<XlsIcon />}
                        size={'medium'}
                        onClick={() => handleExportAsExcel({ table })}
                    >
                        {t`Export view`}
                    </SecondaryButton>
                )}
                {visibility.actionButton && ActionButton && (
                    <ActionButton table={table} sharedContext={sharedContext} />
                )}
            </Flexbox>
        </Flexbox>
    );
}

function FilterChipsContainer<TData>({ table }: TanStackTableProps<TData, unknown>): JSX.Element {
    const columnFilters = table.getState().columnFilters;
    const sorting = table.getState().sorting;
    const { sessionFiltersAndSorting, enableSaveAsDefault } = assertPresent(table.options.meta);

    const handleClick = () => {
        sessionFiltersAndSorting?.setPersistentFilters(table.getState().columnFilters);
        sessionFiltersAndSorting?.setPersistentSorting(table.getState().sorting);
    };

    const handleReset = () => {
        table.setColumnFilters([]);
        table.setSorting([]);
        sessionFiltersAndSorting?.setPersistentFilters([]);
        sessionFiltersAndSorting?.setPersistentSorting([]);
    };
    const hasEqualFiltersAndSorting =
        isEqual(sessionFiltersAndSorting?.persistentFilters, table.getState().columnFilters) &&
        isEqual(sessionFiltersAndSorting?.persistentSorting, table.getState().sorting);

    if (columnFilters.length === 0 && sorting.length === 0) {
        return <></>;
    }

    return (
        <Flexbox gap={'32px'} justifyContent={'space-between'} paddingRight={'8px'}>
            <FilterChips table={table} />
            {enableSaveAsDefault && hasEqualFiltersAndSorting && (
                <Tooltip title={t`Clear saved filter and sort settings for this session.`} variant="white">
                    <TertiaryButton
                        size={'small'}
                        onClick={handleReset}
                        startIcon={<Bookmark />}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {t`Remove default`}
                    </TertiaryButton>
                </Tooltip>
            )}
            {enableSaveAsDefault && !hasEqualFiltersAndSorting && (
                <Tooltip title={t`Save filter and sort settings for this browsing session.`} variant="white">
                    <TertiaryButton
                        size={'small'}
                        onClick={handleClick}
                        startIcon={<BookmarkBorderOutlined />}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {t`Save as default`}
                    </TertiaryButton>
                </Tooltip>
            )}
        </Flexbox>
    );
}

export function FilterChips<TData>({ table }: TanStackTableProps<TData, unknown>): JSX.Element {
    const columnFilters = table.getState().columnFilters;
    const sorting = table.getState().sorting;
    const [menuState, setMenuState] = React.useState<FilterMenuState>({ type: 'None', anchorEl: undefined });

    // Add an effect to update menuState when columnFilters change, this happens when a column filter is removed
    React.useEffect(() => {
        if (menuState.type === 'ColumnFilter' && !columnFilters.some((filter) => filter.id === menuState.column.id)) {
            setMenuState({ type: 'None', anchorEl: undefined });
        }
    }, [columnFilters, menuState]);

    const handleOpenColumnFilter = (e: React.MouseEvent<HTMLDivElement>, column: Column<TData, unknown>) => {
        setMenuState({ type: 'ColumnFilter', anchorEl: e.currentTarget, column });
    };

    const handleClose = () => {
        setMenuState({ type: 'None', anchorEl: undefined });
    };

    if (columnFilters.length === 0 && sorting.length === 0) {
        return <></>;
    }

    return (
        <Flexbox gap={4} flexWrap={'wrap'}>
            {columnFilters.map((columnFilter) => (
                <Chip
                    key={columnFilter.id}
                    color={'primary'}
                    style={{
                        maxWidth: '300px',
                        borderRadius: '4px',
                        border: `1px solid ${colorSystem.primary[2]}`,
                        backgroundColor: colorSystem.primary[1],
                    }}
                    onClick={(e) => handleOpenColumnFilter(e, assertPresent(table.getColumn(columnFilter.id)))}
                    onDelete={() => table.getColumn(columnFilter.id)?.setFilterValue(undefined)}
                    label={renderFilterChipLabel({ columnFilter, table })}
                />
            ))}
            <ColumFilterMenu menuState={menuState} onClose={handleClose} table={table} />
        </Flexbox>
    );
}

function renderFilterChipLabel<TData>({
    columnFilter,
    table,
}: {
    columnFilter: ColumnFilter;
    table: Table<TData>;
}): JSX.Element {
    const column = table.getColumn(columnFilter.id);
    if (!isPresent(column)) {
        return <></>;
    }
    const filterValue = columnFilter.value;
    if (!isFilterValue(filterValue)) {
        return <></>;
    }

    if (filterValue.filterFn === SupportedFilterFn.includesString) {
        return (
            <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.primary[8] }}>
                <b>{column.columnDef.meta?.label()}</b>: {filterValue.value}
            </Text>
        );
    }

    if (
        filterValue.filterFn === SupportedFilterFn.inNumberRange ||
        filterValue.filterFn === SupportedFilterFn.inMonetaryValueRange
    ) {
        const [minValue, maxValue] = filterValue.value;

        if (isPresent(minValue) && isPresent(maxValue)) {
            return (
                <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.primary[8] }}>
                    <b>{column.columnDef.meta?.label()}</b>: {formatDecimal(minValue)} - {formatDecimal(maxValue)}
                </Text>
            );
        }

        if (isPresent(minValue)) {
            return (
                <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.primary[8] }}>
                    <b>{column.columnDef.meta?.label()}</b>: ≥ {formatDecimal(minValue)}
                </Text>
            );
        }

        if (isPresent(maxValue)) {
            return (
                <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.primary[8] }}>
                    <b>{column.columnDef.meta?.label()}</b>: ≤ {formatDecimal(maxValue)}
                </Text>
            );
        }
    }

    if (filterValue.filterFn === SupportedFilterFn.inDateRange) {
        const [minValue, maxValue] = filterValue.value;

        if (isPresent(minValue) && isPresent(maxValue)) {
            return (
                <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.primary[8] }}>
                    <b>{column.columnDef.meta?.label()}</b>: {formatToLongDate(minValue)} - {formatToLongDate(maxValue)}
                </Text>
            );
        }

        if (isPresent(minValue)) {
            return (
                <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.primary[8] }}>
                    <b>{column.columnDef.meta?.label()}</b>: ≥ {formatToLongDate(minValue)}
                </Text>
            );
        }
        if (isPresent(maxValue)) {
            return (
                <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.primary[8] }}>
                    <b>{column.columnDef.meta?.label()}</b>: ≤ {formatToLongDate(maxValue)}
                </Text>
            );
        }
    }

    const enumFilterConfig = getFilterConfig(column, 'enum');
    if (isPresent(enumFilterConfig) && Array.isArray(filterValue.value)) {
        const value = filterValue.value;
        if (filterValue.filterFn === SupportedFilterFn.equalsAny) {
            return (
                <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.primary[8] }}>
                    <b>{column.columnDef.meta?.label()}</b>:{' '}
                    {value.map(enumFilterConfig.getOptionLabel as any).join(', ')}
                </Text>
            );
        }
        if (filterValue.filterFn === SupportedFilterFn.equalsNone) {
            return (
                <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.primary[8] }}>
                    <b>{column.columnDef.meta?.label()}</b>:{' Not '}
                    {value.map(enumFilterConfig.getOptionLabel as any).join(', ')}
                </Text>
            );
        }
    }

    const arrayFilterConfig = getFilterConfig(column, 'array');
    if (isPresent(arrayFilterConfig) && Array.isArray(filterValue.value)) {
        const value = filterValue.value;
        if (filterValue.filterFn === SupportedFilterFn.arrIncludesSome) {
            return (
                <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.primary[8] }}>
                    <b>{column.columnDef.meta?.label()}</b>:{' '}
                    {value.length > 0 ? value.map(arrayFilterConfig.getOptionLabel as any).join(', ') : t`-`}
                </Text>
            );
        }
        if (filterValue.filterFn === SupportedFilterFn.arrIncludesAll) {
            return (
                <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.primary[8] }}>
                    <b>{column.columnDef.meta?.label()}</b>:{' '}
                    {value.length > 0 ? value.map(arrayFilterConfig.getOptionLabel as any).join(', ') : t`-`}
                </Text>
            );
        }
        if (filterValue.filterFn === SupportedFilterFn.arrExcludesSome) {
            return (
                <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.primary[8] }}>
                    <b>{column.columnDef.meta?.label()}</b>:{' Not '}
                    {value.length > 0 ? value.map(arrayFilterConfig.getOptionLabel as any).join(', ') : t`-`}
                </Text>
            );
        }
    }

    throwErrorUnlessProduction(new Error(`Unsupported filter type: ${filterValue.filterFn}`));
    return <></>;
}
