import { Trans, t } from '@lingui/macro';
import { Flexbox, TertiaryButton } from '@luminovo/design-system';
import { ConfigurationsPatchDTO } from '@luminovo/http-client';
import { Edit } from '@mui/icons-material';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { route } from '../../../utils/routes';
import {
    DriverInformation,
    EditConfigurationDialog,
    EditConfigurationFormProps,
} from '../ActivitiesTable/editActivityConfigurations';

export const EditExpenseConfiguration = ({
    selectedManufacturingScenarioId,
    expenseConfigurationId,
    assemblyIdOfSelectedManufacturingScenario,
    driverInformation,
}: {
    selectedManufacturingScenarioId: string;
    expenseConfigurationId: string;
    assemblyIdOfSelectedManufacturingScenario: string;
    driverInformation: DriverInformation;
}): JSX.Element => {
    const { rfqId } = useParams<{ rfqId: string }>();
    const [isDialogShown, setIsDialogShown] = React.useState(false);
    const assemblyRoute: string = route('/rfqs/:rfqId/bom/assembly/:assemblyId', {
        rfqId,
        assemblyId: assemblyIdOfSelectedManufacturingScenario,
    });

    const onCloseCallback = async () => {
        setIsDialogShown(false);
    };

    const { mutateAsync } = useHttpMutation('PATCH /expense-configurations/:expenseConfigurationId', {
        snackbarMessage: t`Successfully updated driver count`,
        disableOnError: true,
    });

    const onSubmit = async (data: EditConfigurationFormProps) => {
        const parsedData: ConfigurationsPatchDTO = {
            /* eslint-disable camelcase */
            manual_driver_count: Number(data.count),
            driver_id: driverInformation.driverId,
            /* eslint-disable camelcase */
        };
        await mutateAsync({
            pathParams: { expenseConfigurationId },
            requestBody: parsedData,
            /* eslint-disable-next-line camelcase */
            queryParams: { apply_to_all_activities_and_expenses: data.isAppliedToAllActivitiesAndExpenses },
        });
    };

    return (
        <>
            <TertiaryButton onClick={() => setIsDialogShown(true)} size="medium" disableTouchRipple>
                <Flexbox gap={'4px'} alignItems={'center'}>
                    <Edit fontSize="small" />
                    <Trans>Edit</Trans>
                </Flexbox>
            </TertiaryButton>
            <EditConfigurationDialog
                onSubmit={onSubmit}
                isDialogShown={isDialogShown}
                onCloseCallback={onCloseCallback}
                assemblyRoute={assemblyRoute}
                driverInformation={driverInformation}
            />
        </>
    );
};
