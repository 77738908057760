import styled from '@emotion/styled';
import { Trans } from '@lingui/macro';
import { Box, Popover, Skeleton, TableCell, TableCellProps } from '@mui/material';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { colorSystem } from '../../theme';
import { PrimaryButton } from '../buttons';
export interface EditableCellProps<T> {
    readonly?: boolean;
    value: T;
    onChange?: (value: T) => void;
    align?: 'left' | 'center' | 'right';
    /**
     * @default 'text'
     */
    Field?: React.ComponentType<{ value: T; onChange: (value: T) => void; onBlur?: () => void }>;
    status?: {
        status: 'error' | 'warning' | 'success';
        message?: string;
    };
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

export function EditableCell<T>({
    readonly,
    value,
    onChange = () => {},
    align = 'left',
    Field = () => null,
    status = { status: 'success' },
    children,
    style,
}: EditableCellProps<T>): JSX.Element {
    const ref = React.useRef<HTMLDivElement>(null);
    const [target, setTarget] = React.useState<HTMLDivElement | null>(null);

    const handleSave = (value: T) => {
        onChange(value);
        setTarget(null);
    };

    const showInput = target && document.body.contains(target);

    return (
        <>
            {showInput && (
                <ErrorBoundary fallback={<></>}>
                    <Popover
                        anchorEl={() => ref.current!}
                        elevation={1}
                        open={showInput}
                        onClose={() => setTarget(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        sx={{
                            boxSizing: 'border-box',
                            marginTop: 0,
                        }}
                    >
                        <React.Suspense
                            fallback={<Skeleton width={'200px'} height={'50px'} sx={{ margin: '0 8px' }} />}
                        >
                            <FormContainer initialValue={value} onSave={handleSave} Field={Field} />
                        </React.Suspense>
                    </Popover>
                </ErrorBoundary>
            )}
            <StyledEditableCell
                ref={ref}
                data-readonly={readonly}
                data-show-input={showInput}
                data-status={status.status}
                data-message={status.message}
                onClick={(event) => {
                    if (readonly) {
                        return;
                    }
                    setTarget(event.currentTarget);
                }}
                style={{ padding: '4px 8px', textAlign: align, ...style }}
            >
                {children}
            </StyledEditableCell>
        </>
    );
}

function FormContainer<T>({
    Field,
    onSave,
    initialValue,
}: {
    Field: React.ComponentType<{ value: T; onChange: (value: T) => void; onBlur?: () => void }>;
    onSave: (value: T) => void;
    initialValue: T;
}) {
    const [value, setValue] = React.useState<T>(initialValue);
    return (
        <Box
            sx={{
                padding: 1,
                gap: 1,
                boxSizing: 'border-box',
                minWidth: '300px',
                display: 'grid',
                gridTemplateColumns: '1fr auto',
            }}
            tabIndex={0}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSave(value);
                }
            }}
        >
            <Field value={value} onChange={setValue} />
            <PrimaryButton onClick={() => onSave(value)}>
                <Trans>Save</Trans>
            </PrimaryButton>
        </Box>
    );
}

const defaultBackground = colorSystem.neutral.white;

export const StyledEditableCell: React.ComponentType<
    TableCellProps & { 'data-error'?: boolean; 'data-readonly'?: boolean }
> = styled(TableCell)({
    padding: '8px',
    background: defaultBackground,
    cursor: 'pointer',
    position: 'relative',
    borderRadius: 0,
    boxShadow: `inset 0 0 0 1px ${defaultBackground}`,
    '&:hover, &[data-show-input="true"]': {
        boxShadow: `inset 0 0 0 1px ${colorSystem.blue[5]} !important`,
        paddingRight: '20px',
        zIndex: 1000,
        background: colorSystem.blue[1],
        '&::after': {
            content: '"edit"',
            fontFamily: 'Material Icons',
            fontSize: '16px',
            position: 'absolute',
            right: '8px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: colorSystem.neutral[6],
            borderRadius: '100%',
            padding: '4px',
        },
        '&[data-message]': {
            '&::before': {
                maxWidth: '200px',
                position: 'absolute',
                top: '54px',
                boxSizing: 'border-box',
                background: colorSystem.neutral[8],
                color: colorSystem.neutral.white,
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '12px',
                whiteSpace: 'pre-wrap',
                zIndex: 1001,
                content: 'attr(data-message)',
            },
        },
    },
    '&[data-status="error"]': {
        background: colorSystem.red[1],
        '&::after': {
            content: '"error"',
            fontFamily: 'Material Icons',
            fontSize: '12px',
            position: 'absolute',
            right: '8px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: colorSystem.red[5],
        },
    },
    '&[data-status="warning"]': {
        background: colorSystem.yellow[1],
        '&::after': {
            content: '"warning"',
            fontFamily: 'Material Icons',
            fontSize: '14px',
            position: 'absolute',
            right: '8px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: colorSystem.yellow[5],
        },
    },
    '&[data-readonly="true"]': {
        cursor: 'default',
        pointerEvents: 'none',
    },

    paddingRight: '32px',
});
