import { Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { colorSystem, Flexbox, Text, useNavigate } from '@luminovo/design-system';
import { DescendantsDTO } from '@luminovo/http-client';
import { Divider, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SimpleTreeView, TreeItem, treeItemClasses } from '@mui/x-tree-view';
import { memo, useCallback, useMemo } from 'react';
import { useDescendants } from '../../../../resources/assembly/assemblyHandler';
import { route } from '../../../../utils/routes';
import { ViewContext } from '../../../Bom/components/ModuleTableData';
export const AssemblyStructure = memo(
    ({ assemblyId, viewContext }: { assemblyId: string; viewContext: ViewContext }) => {
        const { data: descendants } = useDescendants(viewContext.type === 'WithinRfQ' ? viewContext.rfqId : assemblyId);

        if (!descendants) return null;
        if (viewContext.type === 'WithinRfQ' && descendants.data.assemblies.length < 2) return null;
        if (viewContext.type === 'AssemblyOverview' && descendants.data.assemblies.length < 1) return null;

        return (
            <Flexbox id="bom-items-header" borderBottom={`1px solid ${colorSystem.neutral[2]}`} flexDirection="column">
                <Flexbox padding="16px">
                    <Text variant="h5">
                        <Trans>Assembly structure</Trans>
                    </Text>
                </Flexbox>
                <Divider style={{ marginBottom: 'unset' }} />
                <Flexbox padding="8px" flexDirection="column">
                    {!descendants ? (
                        <Flexbox flexDirection="column" gap="8px">
                            <Skeleton width="70%" />
                            <Skeleton width="calc(70% - 20px)" style={{ marginLeft: '20px' }} />
                            <Skeleton width="calc(70% - 20px)" style={{ marginLeft: '20px' }} />
                        </Flexbox>
                    ) : (
                        <AssemblyTree
                            assemblyId={assemblyId}
                            descendants={descendants.data}
                            viewContext={viewContext}
                        />
                    )}
                </Flexbox>
            </Flexbox>
        );
    },
);

const getDirectChildren = ({ descendants, parentId }: { descendants: DescendantsDTO; parentId: string }) => {
    return descendants.assemblies.filter((assembly) => parentId in assembly.parents);
};

const renderTreeItems = ({
    descendants,
    currentAssemblyId,
    prevNodeItemId,
    parentId,
    handleClick,
}: {
    descendants: DescendantsDTO;
    currentAssemblyId: string;
    prevNodeItemId: string;
    parentId: string;
    handleClick: ({ parentId, assemblyId }: { parentId: string; assemblyId: string }) => void;
}) => {
    const children = getDirectChildren({ descendants, parentId });

    return children.map((child) => {
        const currentNodeItemId = `${prevNodeItemId}-${child.id}`;
        return (
            <CustomTreeItem
                key={currentNodeItemId}
                itemId={currentNodeItemId}
                label={
                    currentAssemblyId === child.id ? (
                        <HighlightedText
                            style={{
                                marginLeft: child.subassemblies.items.length === 0 ? '-20px' : 0,
                            }}
                        >
                            {child.designator}
                        </HighlightedText>
                    ) : (
                        <Text
                            variant="body-small"
                            style={{
                                marginLeft: child.subassemblies.items.length === 0 ? '-20px' : 0,
                            }}
                        >
                            {child.designator}
                        </Text>
                    )
                }
                onClick={(e) => {
                    // Only handle click if not clicking the expand/collapse icon
                    if (!(e.target as HTMLElement).closest(`.${treeItemClasses.iconContainer}`)) {
                        handleClick({ parentId, assemblyId: child.id });
                    }
                }}
            >
                {renderTreeItems({
                    descendants,
                    currentAssemblyId,
                    prevNodeItemId: currentNodeItemId,
                    parentId: child.id,
                    handleClick,
                })}
            </CustomTreeItem>
        );
    });
};

const AssemblyTree = ({
    assemblyId,
    descendants,
    viewContext,
}: {
    assemblyId: string;
    descendants: DescendantsDTO;
    viewContext: ViewContext;
}) => {
    const navigate = useNavigate();

    const handleClick = useCallback(
        ({ parentId, assemblyId }: { parentId: string; assemblyId: string }) => {
            const contextType = viewContext.type;
            if (contextType === 'WithinRfQ') {
                return navigate(
                    route(
                        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                        { assemblyId, rfqId: viewContext.rfqId },
                        {
                            isReadonly: null,
                            designItemId: null,
                            currentParentAssemblyId: parentId,
                            filters: null,
                            bomTab: null,
                            dashboardFilters: null,
                            search: null,
                            onlyShowItemsWithManufacturingWarnings: null,
                        },
                    ),
                    { replace: true },
                );
            }
            if (contextType === 'AssemblyOverview') {
                return navigate(route('/assemblies/:assemblyId/details', { assemblyId }), { replace: true });
            }
            assertUnreachable(contextType);
        },
        [navigate, viewContext.rfqId, viewContext.type],
    );

    const buildPaths = useCallback(
        (parentId: string, path: string): string[] => {
            const children = getDirectChildren({ descendants, parentId });
            if (children.length === 0) {
                return [path];
            }
            return children.flatMap((child) => {
                const currentPath = `${path}-${child.id}`;
                return [currentPath, ...buildPaths(child.id, currentPath)];
            });
        },
        [descendants],
    );

    const allTreeItemPaths = useMemo(
        () => buildPaths(descendants.parent.id, descendants.parent.id),
        [descendants, buildPaths],
    );

    return (
        <Flexbox flexDirection="column" overflow="auto" maxHeight="200px">
            <SimpleTreeView expansionTrigger="iconContainer" defaultExpandedItems={[...allTreeItemPaths, assemblyId]}>
                {viewContext.type === 'WithinRfQ' &&
                    renderTreeItems({
                        descendants,
                        currentAssemblyId: assemblyId,
                        prevNodeItemId: descendants.parent.id,
                        parentId: descendants.parent.id,
                        handleClick,
                    })}
                {viewContext.type === 'AssemblyOverview' && (
                    <CustomTreeItem
                        itemId={descendants.parent.id}
                        label={
                            <Text variant="body-small-semibold" color={colorSystem.primary[7]}>
                                {descendants.parent.designator}
                            </Text>
                        }
                    >
                        {renderTreeItems({
                            descendants,
                            currentAssemblyId: assemblyId,
                            prevNodeItemId: descendants.parent.id,
                            parentId: descendants.parent.id,
                            handleClick,
                        })}
                    </CustomTreeItem>
                )}
            </SimpleTreeView>
        </Flexbox>
    );
};

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.2, 0),
        width: 'fit-content',
        '&:hover': {
            background: colorSystem.neutral[1],
        },
        '&.Mui-selected': {
            background: 'unset',
            '&:hover': {
                background: colorSystem.neutral[1],
            },
            '&.Mui-focused': {
                background: colorSystem.neutral.white,
            },
        },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
        color: colorSystem.primary[5],
    },
    [`& .${treeItemClasses.label}`]: {
        whiteSpace: 'nowrap',
    },
    [`& .${treeItemClasses.groupTransition}`]: {
        marginLeft: 16,
        paddingLeft: 8,
        borderLeft: `1px solid ${colorSystem.primary[5]}`,
    },
}));

const HighlightedText = ({ ...rest }) => {
    return <Text color={colorSystem.primary[7]} variant="body-small-semibold" {...rest} />;
};
