import { t, Trans } from '@lingui/macro';
import {
    colorSystem,
    FieldCheckboxControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    SecondaryButton,
    TertiaryButton,
} from '@luminovo/design-system';
import { ConfigurationsPatchDTO, DriverIdDTO } from '@luminovo/http-client';
import { Edit } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
// eslint-disable-next-line no-restricted-imports
import { Link, useParams } from 'react-router-dom';
import { FormContainer, ValidationErrors } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { route } from '../../../utils/routes';
import { DriverNotes } from '../shared/ManufacturingSharedComponents';
import { DriverCountLabel } from './DriverCountLabel';

export interface EditConfigurationFormProps {
    count: number;
    isAppliedToAllActivitiesAndExpenses: boolean;
}

const validationErrors: ValidationErrors<EditConfigurationFormProps> = {
    'manufacturing_assembly_details.panel_factor_less_than_one': {
        fieldPath: 'count',
    },
};

const DriverCountFormField = () => {
    const { control } = useFormContext<EditConfigurationFormProps>();

    return <FieldTextControlled name={'count'} control={control} />;
};

const DriverCountCheckboxField = () => {
    const { control } = useFormContext<EditConfigurationFormProps>();

    return (
        <FormItem label={<Trans>Apply changes to identical drivers</Trans>} flexDirection={'row'}>
            <FieldCheckboxControlled name={'isAppliedToAllActivitiesAndExpenses'} control={control} />
        </FormItem>
    );
};

function FormComponents({
    onSubmit,
    driverName,
    driverNotes,
    automaticDriverCount,
    existingCount,
    onCloseCallback,
    assemblyRoute,
}: {
    onSubmit: (form: EditConfigurationFormProps) => Promise<void>;
    driverName: string | undefined;
    existingCount: number | undefined;
    driverNotes: string | undefined;
    automaticDriverCount: number | undefined;
    onCloseCallback: () => void;
    assemblyRoute: string;
}) {
    const defaultValues = { count: existingCount, isAppliedToAllActivitiesAndExpenses: false };
    const handleSubmit = (form: EditConfigurationFormProps) => onSubmit(form);

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={handleSubmit} validationErrors={validationErrors}>
            <FormItem label={t`Driver name`}>
                <Grid item xs={12}>
                    <Box ml={-0.5} mb={1}>
                        <Typography variant={'body1'}> {driverName} </Typography>
                    </Box>
                    {driverNotes !== undefined && driverNotes.length > 0 && (
                        <DriverNotes notes={driverNotes} boxProps={{ marginBottom: '12px' }} maxLength={200} />
                    )}
                </Grid>
            </FormItem>
            <FormItem label={<DriverCountLabel automaticDriverCount={automaticDriverCount} />}>
                <Grid item xs={12}>
                    <Box ml={-0.5}>
                        <Grid container spacing={1} alignItems={'center'}>
                            <Grid item xs={8}>
                                <DriverCountFormField />
                            </Grid>
                            <Grid item xs={4}>
                                <Link
                                    to={assemblyRoute}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Grid container spacing={1} alignItems={'center'}>
                                        <Grid item>
                                            <LaunchIcon
                                                fontSize={'small'}
                                                style={{ color: colorSystem.neutral[7], marginTop: '4px' }}
                                            />
                                        </Grid>
                                        <Grid item style={{ marginLeft: '-4px' }}>
                                            <Typography variant={'h5'} style={{ color: colorSystem.neutral[7] }}>
                                                {t`Open BOM in new tab`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={1}>
                        <DriverCountCheckboxField />
                    </Box>
                </Grid>
            </FormItem>
            <Box mt={2} mb={2}>
                <Grid container justifyContent={'space-between'}>
                    <SecondaryButton onClick={onCloseCallback}>
                        <Trans>Cancel</Trans>
                    </SecondaryButton>

                    <SubmitButton />
                </Grid>
            </Box>
        </FormContainer>
    );
}

export interface DriverInformation {
    driverCount: number | undefined;
    automaticDriverCount: number | undefined;
    driverNotes: string | undefined;
    driverName: string | undefined;
    driverId: DriverIdDTO;
}

interface ActivityConfigurationInformation {
    activityConfigurationId: string;
}

interface EditDriverCountDialog {
    isDialogShown: boolean;
    onCloseCallback: () => void;
    assemblyRoute: string;
    driverInformation: DriverInformation;
    onSubmit: (form: EditConfigurationFormProps) => Promise<void>;
}

export const EditConfigurationDialog = ({
    isDialogShown,
    onCloseCallback,
    assemblyRoute,
    driverInformation,
    onSubmit,
}: EditDriverCountDialog): JSX.Element => {
    const { driverName, driverCount, automaticDriverCount, driverNotes } = driverInformation;
    return (
        <Dialog open={isDialogShown} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle>
                <Grid container spacing={2} justifyContent={'center'}>
                    <Grid item>
                        <Typography variant="h1" color={'textSecondary'}>
                            <Trans>Edit driver count</Trans>
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <FormComponents
                    onSubmit={async (form) => {
                        await onSubmit(form);
                        onCloseCallback();
                    }}
                    driverName={driverName}
                    existingCount={driverCount}
                    driverNotes={driverNotes}
                    automaticDriverCount={automaticDriverCount}
                    onCloseCallback={onCloseCallback}
                    assemblyRoute={assemblyRoute}
                />
            </DialogContent>
        </Dialog>
    );
};

export const EditActivityConfiguration = ({
    driverInformation,
    activityConfigurationInformation,
    selectedManufacturingScenarioId,
    assemblyIdOfSelectedManufacturingScenario,
}: {
    driverInformation: DriverInformation;
    activityConfigurationInformation: ActivityConfigurationInformation;
    selectedManufacturingScenarioId: string;
    assemblyIdOfSelectedManufacturingScenario: string;
}): JSX.Element => {
    const [isDialogShown, setIsDialogShown] = React.useState(false);
    const { rfqId } = useParams<{ rfqId: string }>();

    const onCloseCallback = async () => {
        setIsDialogShown(false);
    };

    const { mutateAsync } = useHttpMutation('PATCH /activity-configurations/:activityConfigurationId', {
        snackbarMessage: t`Successfully updated driver count`,
    });

    const onSubmit = async (data: EditConfigurationFormProps) => {
        const parsedData: ConfigurationsPatchDTO = {
            /* eslint-disable camelcase */
            manual_driver_count: Number(data.count),
            driver_id: driverInformation.driverId,
            /* eslint-enable camelcase */
        };
        await mutateAsync({
            pathParams: { activityConfigurationId: activityConfigurationInformation.activityConfigurationId },
            requestBody: parsedData,
            /* eslint-disable-next-line camelcase */
            queryParams: { apply_to_all_activities_and_expenses: data.isAppliedToAllActivitiesAndExpenses },
        });
    };

    const assemblyRoute: string = route('/rfqs/:rfqId/bom/assembly/:assemblyId', {
        rfqId,
        assemblyId: assemblyIdOfSelectedManufacturingScenario,
    });
    return (
        <>
            <TertiaryButton onClick={() => setIsDialogShown(true)} size="medium" disableTouchRipple>
                <Flexbox gap={'4px'} alignItems={'center'}>
                    <Edit fontSize="small" />
                    <Trans>Edit</Trans>
                </Flexbox>
            </TertiaryButton>
            <EditConfigurationDialog
                onSubmit={onSubmit}
                isDialogShown={isDialogShown}
                onCloseCallback={onCloseCallback}
                assemblyRoute={assemblyRoute}
                driverInformation={driverInformation}
            />
        </>
    );
};
