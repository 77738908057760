import { Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { MonitoringIcon, SecondaryButton, useNavigate } from '@luminovo/design-system';
import { Skeleton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useAssembly } from '../../../../resources/assembly/assemblyHandler';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { ViewContext } from '../ModuleTableData';
import useMonitoringDrawer from './useMonitoringDrawer';
import useMonitoringViewOnlyDrawer from './useMonitoringViewOnlyDrawer';

export const MonitoringButton = ({
    assemblyId,
    viewContext,
    isMonitoringOpen,
}: {
    assemblyId: string;
    isMonitoringOpen?: string | null;
    viewContext: ViewContext;
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { data: assemblyMonitoring, isLoading } = useHttpQuery(
        'GET /assemblies/:assemblyId/monitoring',
        { pathParams: { assemblyId } },
        {},
    );

    const { data: assemblyData, isLoading: isLoadingAssembly } = useAssembly(assemblyId);
    const isEditable = assemblyMonitoring?.top_level_assembly === assemblyId;

    const { openDrawer } = useMonitoringDrawer();
    const { openDrawer: openViewOnlyDrawer } = useMonitoringViewOnlyDrawer(viewContext);

    if (isLoading || isLoadingAssembly) {
        return (
            <SecondaryButton size="medium" disabled>
                <Skeleton width={100} height={24} />
            </SecondaryButton>
        );
    }

    if (!assemblyMonitoring || !assemblyData) {
        return null;
    }

    const handleClick = () => {
        if (isEditable) {
            return openDrawer({ assemblyMonitoring, assemblyId });
        }
        return openViewOnlyDrawer({ assemblyMonitoring });
    };

    if (isMonitoringOpen === 'true') {
        const search = new URLSearchParams(location.search);
        search.set('monitoring', 'false');
        navigate({ pathname: location.pathname, search: search.toString() }, { replace: true });
        handleClick();
    }

    return (
        <SecondaryButton
            size="medium"
            startIcon={<MonitoringIcon color={assemblyMonitoring?.is_active ? 'green' : 'neutral'} />}
            onClick={handleClick}
            id={id('design/button_assembly_monitoring')}
        >
            <Trans>Monitoring</Trans> &nbsp;
        </SecondaryButton>
    );
};
