import { Trans } from '@lingui/macro';
import { ArrowBack } from '@mui/icons-material';
import { useBackNavigation } from '../../hooks/useBackNavigation';
import { SecondaryButton } from './SecondaryButton';
import { TertiaryIconButton } from './TertiaryIconButton';

export function BackButton({
    href,
    variant = 'icon',
    size = 'medium',
}: {
    href: string;
    variant?: 'icon' | 'button';
    size?: 'small' | 'medium';
}): JSX.Element {
    const { navigateBack } = useBackNavigation();

    if (variant === 'icon') {
        return (
            <TertiaryIconButton size={size} onClick={() => navigateBack({ defaultPath: href })}>
                <ArrowBack fontSize="inherit" />
            </TertiaryIconButton>
        );
    } else {
        return (
            <SecondaryButton
                size={size}
                onClick={() => navigateBack({ defaultPath: href })}
                startIcon={<ArrowBack />}
                style={{ width: 'fit-content' }}
            >
                <Trans>Back</Trans>
            </SecondaryButton>
        );
    }
}
