import { route } from '@/utils/routes';
import { t } from '@lingui/macro';
import { isProductionEnvironment, sortBy } from '@luminovo/commons';
import type { SidebarItem } from '@luminovo/design-system';
import * as icons from '@mui/icons-material';

const sidebarResources = ({ defaultOpen }: { defaultOpen: boolean }): SidebarItem[] => [
    {
        type: 'category',
        title: t`Resources`,
        defaultOpen,
        children: sortBy(
            [
                { type: 'link', title: t`RfQs`, href: route('/rfqs'), Icon: icons.RequestPage },
                {
                    type: 'link',
                    title: t`Assemblies`,
                    href: route('/assemblies'),
                    Icon: icons.Build,
                },
                { type: 'link', title: t`Customers`, href: route('/customers'), Icon: icons.People },
                {
                    type: 'link',
                    title: t`Manufacturers`,
                    href: route('/manufacturer'),
                    Icon: icons.Factory,
                },
                {
                    type: 'link',
                    title: t`Part search`,
                    href: route('/parts/off-the-shelf-part-search'),
                    alternatives: [t`MPN search`, t`MPNs`],
                    Icon: icons.ManageSearch,
                },
                {
                    type: 'link',
                    title: t`Part alerts`,
                    alternatives: [],
                    href: route('/parts/alerts'),
                    Icon: icons.AddAlertRounded,
                },
                {
                    type: 'link',
                    title: t`Components`,
                    alternatives: [t`IPNs`, t`Internal part numbers`],
                    href: route('/parts/components'),
                    Icon: icons.Dvr,
                },
                {
                    type: 'link',
                    href: route('/parts/demands'),
                    alternatives: [],
                    Icon: icons.DataUsageRounded,
                    title: t`Demands`,
                },
                {
                    type: 'link',
                    title: t`Purchase orders`,
                    href: route('/purchase-orders'),
                    Icon: icons.ShoppingCart,
                    alternatives: [t`POs`],
                },
                { type: 'link', title: t`Suppliers`, href: route('/supplier'), Icon: icons.Store },
                {
                    type: 'link',
                    title: t`Users`,
                    href: route('/settings/organization/users'),
                    Icon: icons.People,
                },
                {
                    type: 'link',
                    title: t`Sites`,
                    href: route('/settings/organization/sites'),
                    Icon: icons.LocationOn,
                    alternatives: [t`Locations`],
                },
                {
                    type: 'link',
                    title: t`Negotiations`,
                    href: route('/negotiations'),
                    Icon: icons.CurrencyExchange,
                },
            ],
            (item) => item.title,
        ),
    },
];

const sidebarSettings = (): SidebarItem[] => [
    {
        type: 'category',
        title: t`Settings`,
        defaultOpen: false,
        children: sortBy([
            { type: 'link', title: t`Users`, href: route('/settings/organization/users') },
            {
                type: 'link',
                title: t`Currency settings`,
                href: route('/settings/organization/currency'),
                match: 'exact',
                alternatives: [t`Currencies`, t`Exchange rate`, t`Currency preferences`],
            },
            { type: 'link', title: t`Mock data`, href: route('/settings/organization/mock-data') },
            {
                type: 'link',
                title: t`Branding`,
                href: route('/settings/organization/branding'),
                match: 'exact',
                alternatives: [t`Branding`],
            },
            {
                type: 'link',
                title: t`Assembly settings`,
                href: route('/settings/organization/assembly-settings'),
                match: 'exact',
                alternatives: [t`Assembly preferences`],
            },
            {
                type: 'link',
                title: t`Manufacturer settings`,
                href: route('/settings/organization/manufacturer-preferences'),
                match: 'exact',
                alternatives: [t`Manufacturer preferences`],
            },
            {
                type: 'link',
                title: t`PCB panel preferences`,
                href: route('/settings/organization/pcb-panel-preferences'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Carbon footprints`,
                href: route('/settings/organization/carbon-footprints'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Solution preferences`,
                href: route('/settings/organization'),
                alternatives: [t`Sourcing preferences`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Offer age limit`,
                href: route('/settings/organization') + '#offer-age-limit',
                alternatives: [t`Offer age limit`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Inventory preference`,
                href: route('/settings/organization') + '#inventory-preference',
                alternatives: [t`Inventory preferences`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Packaging preferences`,
                href: route('/settings/organization') + '#packaging-preference',
                alternatives: [t`Packaging preferences`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Supplier API integration`,
                href: route('/settings/organization/supplier-api-integrations'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Scrap calculation`,
                href: route('/settings/organization/scrap-calculation'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Total cost of ownership`,
                href: route('/settings/organization/total-cost-of-ownership-rules'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Customer portal`,
                href: route('/settings/organization/customer-portal'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Additional services`,
                href: route('/settings/organization/customer-portal/additional-services'),
                alternatives: [t`Customer portal additional services`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Payment credentials`,
                href: route('/settings/organization/customer-portal/payment-credentials'),
                alternatives: [t`Customer portal payment credentials`, t`Stripe settings`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Supplier portal`,
                href: route('/settings/organization/supplier-portal'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`API and webhooks`,
                href: route('/settings/organization/api-settings'),
                alternatives: [t`API settings`, t`API Docs`],
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Automatic RfQ number`,
                href: route('/settings/organization/automatic-rfq-number'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Email templates`,
                href: route('/settings/organization/email-templates'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Export templates`,
                href: route('/settings/organization/export-templates'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Source list`,
                href: route('/settings/organization/source-list'),
                match: 'exact',
            },
            {
                type: 'link',
                title: t`Account security`,
                alternatives: [t`Two-factor authentication`],
                href: route('/settings/user/account-security'),
                match: 'exact',
            },
        ]),
    },
];

const sidebarWorkflows = ({ defaultOpen }: { defaultOpen: boolean }): SidebarItem[] => [
    {
        type: 'category',
        title: t`Workflows`,
        defaultOpen,
        children: sortBy(
            [
                { type: 'link', title: t`New RfQ`, href: route('/rfq_creation'), Icon: icons.Add },
                {
                    type: 'link',
                    title: t`New negotiation`,
                    href: route('/negotiations/create'),
                    Icon: icons.Add,
                    alternatives: [t`Create negotiation`, t`Start negotiation`],
                },
                {
                    type: 'link',
                    title: t`Import demands`,
                    href: route('/parts/demand/demand-importer'),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add demands`],
                },
                {
                    type: 'link',
                    title: t`Supplier portal`,
                    href: route('/supplier-portal'),
                    Icon: icons.Store,
                    alwaysHidden: isProductionEnvironment(),
                },
                {
                    type: 'link',
                    title: t`Import components`,
                    href: route('/parts/components/component-importer'),
                    Icon: icons.ImportExport,
                    alternatives: [t`Import IPNs`, t`Add components`],
                },
                {
                    type: 'link',
                    title: t`Import suppliers`,
                    href: route('/supplier/importer'),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add suppliers`],
                },
                {
                    type: 'link',
                    title: t`Import customers`,
                    href: route('/customers/importer'),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add customers`],
                },
                {
                    type: 'link',
                    title: t`Import supplier contacts`,
                    href: route('/supplier/supplier-contacts-importer/bulk'),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add supplier contacts`],
                },
                {
                    type: 'link',
                    title: t`Import offers`,
                    href: route('/offers/mpn-offer-importer'),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add offers`],
                },
                {
                    type: 'link',
                    title: t`Import source list`,
                    href: route('/parts/source-list-importer'),
                    Icon: icons.ImportExport,
                    alternatives: [t`Add source list`],
                },
            ],
            (item) => item.title,
        ),
    },
];

const sidebarExtras = (): SidebarItem[] => [];

export const defaultSidebarContents = (): SidebarItem[] => [
    {
        type: 'link',
        href: '/rfqs',
        title: t`Home`,
        Icon: icons.Home,
        match: 'exact',
    },
    {
        type: 'separator',
        title: '',
    },
    ...sidebarResources({ defaultOpen: true }),
    {
        type: 'separator',
        title: '',
    },
    ...sidebarWorkflows({ defaultOpen: false }),
    {
        type: 'separator',
        title: '',
    },
    ...sidebarSettings(),
    ...sidebarExtras(),
];

export const rfqSidebarContents = ({ rfqId }: { rfqId: string }): SidebarItem[] => [
    {
        type: 'link',
        href: '/',
        title: t`Home`,
        Icon: icons.Home,
        match: 'exact',
    },
    {
        type: 'category',
        title: t`Current RfQ`,
        defaultOpen: true,
        children: [
            {
                type: 'link',
                title: t`Overview`,
                href: route('/rfqs/:rfqId/dashboard', { rfqId }),
                Icon: icons.Dashboard,
                alternatives: [t`Dashboard`],
            },
            {
                type: 'link',
                title: t`Design`,
                alternatives: [t`BOM`],
                href: route('/rfqs/:rfqId/bom', { rfqId }),
                Icon: icons.GifBox,
            },
            {
                type: 'link',
                title: t`Sourcing`,
                href: route('/rfqs/:rfqId/sourcing', { rfqId }),
                Icon: icons.BarChart,
            },
            {
                type: 'link',
                title: t`Manufacturing`,
                href: route('/rfqs/:rfqId/manufacturing', { rfqId }, { forwardIfSingleAssembly: 'true' }),
                Icon: icons.Build,
            },
            {
                type: 'link',
                title: t`Calculation`,
                alternatives: [t`Costing templates`],
                href: route('/rfqs/:rfqId/calculation', { rfqId }),
                Icon: icons.Calculate,
            },
            {
                type: 'link',
                title: t`Quotation`,
                href: route('/rfqs/:rfqId/quotation', { rfqId }),
                Icon: icons.PictureAsPdf,
            },
        ],
    },
    {
        type: 'separator',
        title: '',
    },
    ...sidebarResources({ defaultOpen: false }),
    {
        type: 'separator',
        title: '',
    },
    ...sidebarWorkflows({ defaultOpen: false }),
    {
        type: 'separator',
        title: '',
    },
    ...sidebarSettings(),
    ...sidebarExtras(),
];
