import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Column } from '@tanstack/react-table';
import { colorSystem } from '../../../theme';
import { SearchInput } from '../../SearchField';
import { getFilterConfig } from '../type';
import { createFilterValueUpdater, isFilterValue } from './createFilterValueUpdater';

export function FilterIncludesString<TData>({
    column,
    onClose,
}: {
    column: Column<TData, unknown>;
    onClose: () => void;
}): JSX.Element | null {
    const filterConfig = getFilterConfig(column, 'text');

    if (!isPresent(filterConfig)) {
        return null;
    }

    const filterValue = column.getFilterValue();
    const value =
        isFilterValue<string>(filterValue) && typeof filterValue.value === 'string' ? filterValue.value : undefined;

    return (
        <SearchInput
            placeholder={t`Type to filter`}
            value={value}
            debounceWait={50}
            onChange={(value) =>
                column.setFilterValue(
                    createFilterValueUpdater(Boolean(value) ? value : undefined, filterConfig.defaultFilterFn),
                )
            }
            onKeyEnter={onClose}
            onClear={() => column.setFilterValue(createFilterValueUpdater(undefined, filterConfig.defaultFilterFn))}
            style={{ backgroundColor: colorSystem.neutral.white }}
        />
    );
}
