import { formatDecimal, formatPercentage } from '@luminovo/commons';
import { QuantityUnitDTO } from '@luminovo/http-client';

/**
 * Calculates the difference between the target price and the value price
 * and formats it as a percentage or a factor.
 *
 * Example:
 * - 10% -> "10%"
 * - 2x -> "2x"
 */
export function formatMoqDifference(requiredQuantity: QuantityUnitDTO, moq?: number) {
    if (!moq) {
        return '';
    }
    if (moq <= requiredQuantity.quantity) {
        return '';
    }

    const diff = moq / requiredQuantity.quantity - 1;
    if (diff < 1) {
        return formatPercentage(diff);
    }
    return `${formatDecimal(diff, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}x`;
}
