import { t, Trans } from '@lingui/macro';
import { assertUnreachable, formatDecimal, isPresent, uniq } from '@luminovo/commons';
import {
    CenteredLayout,
    colorSystem,
    compareByNumber,
    Flexbox,
    InteractiveCard,
    NonIdealState,
    RequestViaMailIcon,
    StatusChip,
    Tab,
    Tabs,
    Text,
    useNavigate,
} from '@luminovo/design-system';
import { QuoteTrackingDTO } from '@luminovo/http-client';
import { formatSupplierAndStockLocationDTO } from '@luminovo/sourcing-core';
import { CircularProgress, List } from '@mui/material';
import * as React from 'react';
import { useQuoteTrackings } from '../../../../resources/quoteTracking/quoteTrackingHandler';
import { route } from '../../../../utils/routes';
import { useRequestConfigurationDialog } from '../QuoteExporter/RequestConfigurationDialog';
type FilterStatus = 'all' | 'received' | 'pending' | 'overdue' | 'discarded';

export const QuoteTrackingStateChip: React.FunctionComponent<{
    quoteTracking: Pick<QuoteTrackingDTO, 'state'>;
}> = ({ quoteTracking }) => {
    if (quoteTracking.state === 'Received') {
        return <StatusChip color="green" label={t`Received`} />;
    }
    if (quoteTracking.state === 'Pending') {
        return <StatusChip color="yellow" label={t({ id: 'quotetracking.status.pending', message: 'Pending' })} />;
    }
    if (quoteTracking.state === 'Overdue') {
        return <StatusChip color="red" label={t`Overdue`} />;
    }

    if (quoteTracking.state === 'Discarded') {
        return <StatusChip color="neutral" label={t`Discarded`} />;
    }

    if (quoteTracking.state === 'FailedToSend') {
        return <StatusChip color="red" label={t`Failed to send`} />;
    }

    assertUnreachable(quoteTracking.state);
};

const QuoteTrackingEmptyState: React.FunctionComponent<{
    rfqId: string;
}> = ({ rfqId }) => {
    const { openDialog } = useRequestConfigurationDialog({ rfqId });
    return (
        <CenteredLayout>
            <NonIdealState
                title={t`No quotes`}
                description={<Trans>You have not sent any quote requests for this RfQ.</Trans>}
                action={{
                    onClick: () => openDialog(),
                    startIcon: <RequestViaMailIcon />,
                    children: t({ id: 'request.quote.cta', message: 'Request quote' }),
                }}
            />
        </CenteredLayout>
    );
};

const FilteredQuoteTrackingEmptyState: React.FunctionComponent = () => {
    return (
        <CenteredLayout>
            <NonIdealState Icon={RequestViaMailIcon} title={t`Status has no matching quotes`} description={''} />
        </CenteredLayout>
    );
};

const QuoteTrackingListItem: React.FunctionComponent<{
    quoteTracking: QuoteTrackingDTO;
    selected: boolean;
}> = ({ quoteTracking, selected }) => {
    const navigate = useNavigate();

    const numberOfRequestedParts = uniq([
        ...quoteTracking.request_standard_parts.map((p) => p.kind.id),
        ...quoteTracking.request_custom_parts.map((p) => p.kind.id),
    ]).length;

    return (
        <InteractiveCard
            selected={selected}
            onClick={() =>
                navigate(
                    route(
                        '/rfqs/:rfqId/sourcing/quote-tracking',
                        {
                            rfqId: quoteTracking.rfq,
                        },
                        {
                            quoteTrackingId: quoteTracking.id,
                        },
                    ),
                )
            }
            style={{
                margin: '12px 2px',
            }}
        >
            <Flexbox gap={'4px'} flexWrap={'nowrap'} alignItems={'center'}>
                <Text variant="h5" showEllipsis={true}>
                    {formatSupplierAndStockLocationDTO(quoteTracking.supplier_and_stock_location)}
                </Text>
                <Text variant="body-small" color={colorSystem.neutral[6]} style={{ whiteSpace: 'nowrap' }}>
                    {t` • ${quoteTracking.number} • ${formatDecimal(numberOfRequestedParts)} parts`}
                </Text>
                <Flexbox flex={1} />
                <QuoteTrackingStateChip quoteTracking={quoteTracking} />
            </Flexbox>
        </InteractiveCard>
    );
};

const sortByLastActionFirst = compareByNumber(
    (quote: QuoteTrackingDTO) => new Date(quote.received_date ?? quote.creation_date).getTime() * -1,
);

export const QuoteTrackingList: React.FunctionComponent<{
    rfqId: string;
    selectedQuoteTrackingId?: string;
    style?: React.CSSProperties;
}> = ({ rfqId, selectedQuoteTrackingId, style }) => {
    const [filterStatus, setfilterStatus] = React.useState<FilterStatus>('all');
    const { data: quoteTrackingDTOs } = useQuoteTrackings(rfqId);

    if (!isPresent(quoteTrackingDTOs)) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const hasNoQuoteTracking = quoteTrackingDTOs.length === 0;
    const filteredQuoteTrackingDTOs = Array.from(quoteTrackingDTOs)
        .sort(sortByLastActionFirst)
        .filter((quoteTracking) => {
            switch (filterStatus) {
                case 'all':
                    return quoteTracking.state !== 'Discarded';
                case 'received':
                    return quoteTracking.state === 'Received';
                case 'pending':
                    return quoteTracking.state === 'Pending' || quoteTracking.state === 'FailedToSend';
                case 'overdue':
                    return quoteTracking.state === 'Overdue';
                case 'discarded':
                    return quoteTracking.state === 'Discarded';
                default:
                    return assertUnreachable(filterStatus);
            }
        });

    if (hasNoQuoteTracking) {
        return <QuoteTrackingEmptyState rfqId={rfqId} />;
    }

    return (
        <Flexbox data-testid="quote-tracking-table" flexDirection="column" width={'100%'} style={style}>
            <Flexbox alignItems={'center'} justifyContent={'space-between'} paddingBottom={'8px'}>
                <Tabs
                    value={filterStatus}
                    onChange={(_, newFilterStatus) => {
                        setfilterStatus(newFilterStatus);
                    }}
                    size={'small'}
                    style={{ flexGrow: 1 }}
                >
                    <Tab value={'all'} label={t`All`} />
                    <Tab value={'received'} label={t`Received`} />
                    <Tab value={'pending'} label={t({ id: 'quotetracking.status.pending', message: 'Pending' })} />
                    <Tab value={'overdue'} label={t`Overdue`} />
                    <Tab value={'discarded'} label={t`Discarded`} />
                </Tabs>
            </Flexbox>
            <List
                style={{
                    padding: '1px',
                    overflowY: 'auto',
                }}
            >
                {filteredQuoteTrackingDTOs.length === 0 && <FilteredQuoteTrackingEmptyState />}
                {filteredQuoteTrackingDTOs.map((quoteTracking) => (
                    <QuoteTrackingListItem
                        key={quoteTracking.id}
                        quoteTracking={quoteTracking}
                        selected={quoteTracking.id === selectedQuoteTrackingId}
                    />
                ))}
            </List>
        </Flexbox>
    );
};
