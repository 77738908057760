import { t } from '@lingui/macro';
import { Flexbox, Message } from '@luminovo/design-system';

import { AssemblyResponseDTO, BomItemApprovalStatus } from '@luminovo/http-client';
import React, { useMemo } from 'react';
import { groupAndCountFilters, ModuleTableData, ViewContext } from '../../../Bom/components/ModuleTableData';
import { FilterId } from '../../../Bom/components/ModuleTableData/filters';
import { WarningSubCategoryBomItemIssues } from '../../countWarningsAcrossBomItems';
import { BomItemSummary } from './BomItemSummary';
import { MainContentContainer } from './MainContentContainer';
import { MessageForPreviousBomImportChanges } from './MessageForPreviousBomImportChanges';
import { SubassembliesContainer } from './SubassembliesContainer';

export const BomSummary = ({
    moduleTableData,
    selectedStatus,
    setSelectedStatus,
    setWarningSubCategoryIssues,
    clearAppliedFilters,
    warningSubCategoryIssues,
    setSearchedText,
    appliedFilters,
    setAppliedFilters,
    viewContext,
    currentAssembly,
}: {
    moduleTableData: ModuleTableData[];
    selectedStatus: BomItemApprovalStatus | undefined;
    setSelectedStatus: React.Dispatch<React.SetStateAction<BomItemApprovalStatus | undefined>>;
    setWarningSubCategoryIssues: React.Dispatch<React.SetStateAction<Set<WarningSubCategoryBomItemIssues>>>;
    clearAppliedFilters: () => void;
    warningSubCategoryIssues: Set<WarningSubCategoryBomItemIssues>;
    setSearchedText: React.Dispatch<React.SetStateAction<string>>;
    appliedFilters: Set<FilterId>;
    setAppliedFilters: (filters: Set<FilterId>) => void;
    viewContext: ViewContext;
    currentAssembly?: AssemblyResponseDTO;
}): JSX.Element => {
    const bomItems = useMemo(() => {
        return moduleTableData.filter((moduleData) => moduleData.moduleType === 'bomItem');
    }, [moduleTableData]);

    const filtersCount = React.useMemo(() => {
        return groupAndCountFilters(bomItems);
    }, [bomItems]);

    const subassemblies = useMemo(() => {
        return moduleTableData.filter((moduleData) => moduleData.moduleType === 'assembly');
    }, [moduleTableData]);

    return (
        <MainContentContainer minWidth="1000px">
            <Flexbox
                style={{
                    marginTop: subassemblies.length > 0 ? '4%' : '14%',
                    marginBottom: '4%',
                    width: '836px',
                }}
                flexDirection="column"
                gap={12}
            >
                {bomItems.length > 0 ? (
                    <BomItemSummary
                        bomItems={bomItems}
                        filtersCount={filtersCount}
                        selectedStatus={selectedStatus}
                        setSelectedStatus={setSelectedStatus}
                        setWarningSubCategoryIssues={setWarningSubCategoryIssues}
                        clearAppliedFilters={clearAppliedFilters}
                        warningSubCategoryIssues={warningSubCategoryIssues}
                        setSearchedText={setSearchedText}
                        appliedFilters={appliedFilters}
                        setAppliedFilters={setAppliedFilters}
                        viewContext={viewContext}
                        currentAssembly={currentAssembly}
                    />
                ) : (
                    <Message
                        attention="high"
                        size="small"
                        variant="blue"
                        message={t`There are no BOM items in this level`}
                    />
                )}
                <SubassembliesContainer viewContext={viewContext} assemblyId={currentAssembly?.id} />
                {filtersCount.has(FilterId.HasChangesFromPreviousImport) && (
                    <MessageForPreviousBomImportChanges
                        moduleTableData={moduleTableData}
                        appliedFilters={appliedFilters}
                        setAppliedFilters={setAppliedFilters}
                    />
                )}
            </Flexbox>
        </MainContentContainer>
    );
};
