import { getToken } from '@luminovo/auth';
import { useNavigate } from '@luminovo/design-system';
import { TaskAcceptedResponse } from '@luminovo/http-client';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import {
    uploadFailedBOMImportToContainer,
    uploadFileToBOMImporter,
} from '../../resources/bomImporter/bomImporterHandler';
import { analytics } from '../../utils/analytics';
import { route } from '../../utils/routes';
import { ViewContext } from '../Bom/components/ModuleTableData';

export type UseBomImporterReturn = {
    bomImportState: { taskEndpoint: string | null; isUploading: boolean; bomFile: File | null };
    error: {
        message: string;
        isBOMErrorDialogOpen: boolean;
        setBOMErrorDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
        openBOMErrorDialogWithMessage: ({ message, assemblyId }: { message: string; assemblyId: string }) => void;
    };
    handleBomImport: ({
        files,
        rfqId,
        assemblyId,
        viewContext,
    }: {
        files: File[];
        rfqId: string;
        assemblyId: string;
        viewContext: ViewContext;
    }) => Promise<void>;
    bomImportTaskCleanUp: () => void;
};

const BomImporterContext = React.createContext<UseBomImporterReturn | undefined>(undefined);

export function BomImporterProvider({ children }: { children: React.ReactNode }) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isUploading, setIsUploading] = React.useState<boolean>(false);

    const [bomErrorDialogIsOpen, setBomErrorDialogIsOpen] = React.useState<boolean>(false);
    const [bomErrorMessage, setBomErrorMessage] = React.useState<string>('');
    const [bomFile, setBomImporterFile] = React.useState<null | File>(null);
    const [taskEndpoint, setTaskEndpoint] = React.useState<string | null>(null);

    const openBOMErrorDialogWithMessage = ({ message, assemblyId }: { message: string; assemblyId: string }) => {
        // eslint-disable-next-line camelcase
        analytics.track('could_not_upload_bom', { assembly_uuid: assemblyId, error_type: message });
        setBomErrorMessage(message);
        setBomErrorDialogIsOpen(true);
    };

    const handleBomImport = React.useCallback(
        async ({
            files,
            rfqId,
            assemblyId,
            viewContext,
        }: {
            files: File[];
            rfqId: string;
            assemblyId: string;
            viewContext: ViewContext;
        }) => {
            // eslint-disable-next-line camelcase
            analytics.track('initiate_bom_upload', { rfq_id: rfqId, assembly_uuid: assemblyId });
            if (files.length === 1) {
                setIsUploading(true);
                const taskAcceptedResponse: TaskAcceptedResponse | string = await uploadFileToBOMImporter(
                    files[0],
                    getToken(),
                );
                if (typeof taskAcceptedResponse === 'string') {
                    await uploadFailedBOMImportToContainer(files[0], enqueueSnackbar);
                    /* eslint-disable camelcase */
                    analytics.track('could_not_upload_bom', {
                        assembly_uuid: assemblyId,
                        error_type: 'failed to upload file to bom importer',
                    });
                    /* eslint-enable camelcase */
                    openBOMErrorDialogWithMessage({ message: taskAcceptedResponse, assemblyId });
                } else {
                    setBomImporterFile(files[0]);
                    setTaskEndpoint(taskAcceptedResponse.task_endpoint);
                    const page1Route =
                        viewContext.type === 'WithinRfQ'
                            ? route(
                                  '/rfqs/:rfqId/bom/assembly/:assemblyId/bom-importer/page-1',
                                  { rfqId, assemblyId },
                                  {},
                              )
                            : route('/assemblies/:assemblyId/dashboard/bom-importer/page-1', { assemblyId }, { rfqId });
                    navigate(page1Route);
                }
            }
        },
        [enqueueSnackbar, navigate],
    );

    const bomImportTaskCleanUp = React.useCallback(() => {
        setTaskEndpoint(null);
        setIsUploading(false);
    }, []);

    const error = React.useMemo(
        () => ({
            message: bomErrorMessage,
            isBOMErrorDialogOpen: bomErrorDialogIsOpen,
            setBOMErrorDialogOpen: setBomErrorDialogIsOpen,
            openBOMErrorDialogWithMessage,
        }),
        [bomErrorMessage, bomErrorDialogIsOpen],
    );

    const bomImportState = React.useMemo(
        () => ({
            taskEndpoint,
            isUploading,
            bomFile,
        }),
        [taskEndpoint, isUploading, bomFile],
    );

    const contextValue = React.useMemo(
        () => ({ error, bomImportState, handleBomImport, bomImportTaskCleanUp }),
        [error, bomImportState, handleBomImport, bomImportTaskCleanUp],
    );

    return <BomImporterContext.Provider value={contextValue}>{children}</BomImporterContext.Provider>;
}

export function useBomImporter(): UseBomImporterReturn | undefined {
    return React.useContext(BomImporterContext);
}
