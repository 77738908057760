import { DataGridColumn, DataGridRow } from '../types';
import { useDataGridContext } from './DataGridContextProvider';
import { QuickFilter } from './QuickFilter';

export function DataGridQuickFilters<TRow extends DataGridRow>(): JSX.Element {
    const { api } = useDataGridContext<TRow>();

    const quickFilters = (api?.getColumns() ?? [])
        .flatMap((col) => {
            const colDef = col.getUserProvidedColDef() as DataGridColumn<TRow>;

            return (colDef.quickFilters ?? []).map((quickFilter) => ({
                column: col,
                quickFilter,
            }));
        })
        .map(({ column, quickFilter }, i) => {
            return <QuickFilter key={i} column={column} {...quickFilter} />;
        });

    return <>{quickFilters}</>;
}
