import { colorSystem, INTER } from '@luminovo/design-system';
import { themeQuartz } from 'ag-grid-community';

const borderColor = colorSystem.neutral[2];

export const themeEditableGrid = themeQuartz.withParams({
    inputFocusBorder: `1px solid ${colorSystem.primary[6]}`,
    foregroundColor: colorSystem.neutral[8],

    // menu settings
    menuBackgroundColor: colorSystem.neutral.white,
    menuBorder: `1px solid ${colorSystem.neutral[3]}`,
    menuTextColor: colorSystem.neutral[8],

    focusShadow: {
        color: colorSystem.primary[2],
        spread: 3,
    },
    accentColor: colorSystem.primary[5],
    textColor: colorSystem.neutral[7],
    backgroundColor: colorSystem.neutral.white,
    borderColor: borderColor,

    // wrapper settings
    wrapperBorder: {
        width: 0,
        color: borderColor,
    },
    wrapperBorderRadius: 4,

    borderRadius: 4,
    cellEditingBorder: {
        width: 0,
    },

    // font settings
    fontFamily: { ref: INTER },
    fontSize: 12,

    rowVerticalPaddingScale: 1,
    spacing: 5.6,

    // header settings
    headerFontFamily: { ref: INTER },
    headerTextColor: colorSystem.neutral[9],
    headerFontSize: 12,
    headerFontWeight: 600,

    rowBorder: `1px solid ${borderColor}`,
    columnBorder: `1px solid ${borderColor}`,
    pinnedColumnBorder: `1px solid ${borderColor}`,

    // resize handle
    headerColumnResizeHandleWidth: 2,
    headerColumnResizeHandleColor: colorSystem.neutral[5],
    headerColumnResizeHandleHeight: 16,

    headerColumnBorder: `1px solid ${borderColor}`,

    // cell selection settings
    // color and style of border around selection
    rangeSelectionBorderColor: colorSystem.primary[5],
    // color used to indicate that data has been copied form the cell range
    rangeSelectionHighlightColor: `${colorSystem.green[2]}`,
});

export const themeTanstackTable = themeEditableGrid.withParams({
    columnBorder: 'none',
    headerColumnBorder: 'none',
    rangeSelectionBorderColor: 'transparent',
    wrapperBorder: `1px solid ${borderColor}`,
    wrapperBorderRadius: 8,
    headerFontFamily: { ref: INTER },
    headerTextColor: colorSystem.neutral[9],
    headerFontSize: 12,
    headerFontWeight: 600,
});
