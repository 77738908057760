import { t } from '@lingui/macro';
import { Flexbox, NonIdealState } from '@luminovo/design-system';

export const NoDemandsMessage = (): React.ReactElement => {
    return (
        <Flexbox flexDirection="column" alignItems="center">
            <NonIdealState
                title={t`No demands`}
                description={t`Please check your filter settings or import new demands.`}
            />
        </Flexbox>
    );
};
