import { Badge as MuiBadge, BadgeProps as MuiBadgeProps, styled } from '@mui/material';
import React from 'react';
import { colorSystem } from '../../theme';

export type BadgeProps = Omit<MuiBadgeProps, 'size' | 'color'> & {
    size?: 'large' | 'small';
    color?: keyof typeof colorSystem;
};

function formatBadgeContent(badgeContent: React.ReactNode) {
    if (typeof badgeContent === 'number') {
        return badgeContent > 99 ? '99+' : badgeContent;
    }
    return badgeContent;
}

export const Badge = React.forwardRef(function Badge(
    { badgeContent, size = 'small', color = 'primary', ...props }: BadgeProps,
    ref: React.ForwardedRef<HTMLSpanElement>,
): JSX.Element {
    const content = formatBadgeContent(badgeContent);
    switch (size) {
        case 'small':
            return (
                <SmallBadge ref={ref} badgeContent={content} badgecolor={color} {...props}>
                    {props.children}
                </SmallBadge>
            );
        case 'large':
            return (
                <LargeBadge ref={ref} badgeContent={content} badgecolor={color} {...props}>
                    {props.children}
                </LargeBadge>
            );
        default:
            return (
                <MuiBadge ref={ref} badgeContent={content} {...props}>
                    {props.children}
                </MuiBadge>
            );
    }
});

const DefaultBadge = styled(MuiBadge)({
    '& .MuiBadge-badge': {
        color: colorSystem.neutral.white,
    },
});

const SmallBadge = styled(DefaultBadge, {
    shouldForwardProp: (propName) => {
        return propName !== 'badgecolor';
    },
})(({ badgecolor = 'primary' }: { badgecolor?: keyof typeof colorSystem }) => ({
    '& .MuiBadge-badge': {
        background: colorSystem[badgecolor][6],
        height: '16px !important',
        minWidth: '16px !important',
    },
}));

const LargeBadge = styled(DefaultBadge, {
    shouldForwardProp: (propName) => {
        return propName !== 'badgecolor';
    },
})(({ badgecolor = 'primary' }: { badgecolor?: keyof typeof colorSystem }) => ({
    '& .MuiBadge-badge': {
        background: colorSystem[badgecolor][6],
        height: '18px !important',
        minWidth: '18px !important',
    },
}));
