import {
    colorSystem,
    FieldSelectControlled,
    FieldSelectControlledProps,
    Flexbox,
    FormItem,
    Tag,
} from '@luminovo/design-system';
import { FieldValues, Path, PathValue } from 'react-hook-form';
import { capitalizeFirstLetter } from '../../../utils/stringFunctions';
import { transEnum } from '../../localization/TransEnum';
import { SolderMaskColors, solderMaskColorTranslations } from './i18n';

const soldermaskColorMap: Record<SolderMaskColors, keyof typeof colorSystem> = {
    green: 'green',
    // eslint-disable-next-line spellcheck/spell-checker
    'green-matt': 'green',
    'green-glossy': 'green',
    white: 'neutral',
    blue: 'blue',
    red: 'red',
    black: 'neutral',
    yellow: 'yellow',
    purple: 'violet',
};

function colorToHex(color: SolderMaskColors | undefined): keyof typeof colorSystem {
    return color === undefined ? 'neutral' : soldermaskColorMap[color];
}

interface ColorDropDownFormItemProps<TFieldValues extends FieldValues>
    extends Pick<FieldSelectControlledProps<TFieldValues>, 'control' | 'name'> {
    options: PathValue<TFieldValues, Path<TFieldValues>>[];
    disabled: boolean;
    style?: React.CSSProperties;
    label: string;
    description: string;
    color: SolderMaskColors | undefined;
    required?: boolean;
    actions?: JSX.Element;
}

export const ColorDropDownFormItem = <TFieldValues extends FieldValues>({
    disabled,
    style,
    name,
    label,
    description,
    color,
    control,
    options,
    required = false,
    actions,
}: ColorDropDownFormItemProps<TFieldValues>) => {
    const soldermaskColor = colorToHex(color);

    if (disabled) {
        return (
            <FormItem
                style={style}
                label={`${label}${required ? '*' : ''}`}
                actions={actions}
                LabelProps={{ variant: 'h5' }}
                description={description}
            >
                {color && (
                    <Flexbox style={{ paddingLeft: '5px' }} alignItems="center" gap={8}>
                        <Tag
                            attention="low"
                            color={soldermaskColor}
                            label={capitalizeFirstLetter(transEnum(color, solderMaskColorTranslations))}
                            style={
                                color === 'white'
                                    ? {
                                          borderColor: colorSystem.neutral[3],
                                          backgroundColor: colorSystem.neutral.white,
                                      }
                                    : undefined
                            }
                        />
                    </Flexbox>
                )}
            </FormItem>
        );
    }

    return (
        <FormItem
            actions={actions}
            style={style}
            label={`${label}${required ? '*' : ''}`}
            description={description}
            LabelProps={{ variant: 'h5' }}
        >
            <FieldSelectControlled
                control={control}
                name={name}
                FieldProps={{
                    options,
                    disableClearable: true,
                    size: 'small',
                    getOptionLabel: (x) => (x ? capitalizeFirstLetter(transEnum(x, solderMaskColorTranslations)) : ''),
                    renderOption: (color) => (
                        <Flexbox alignItems="center" gap={8}>
                            <Tag
                                attention="low"
                                color={colorToHex(color)}
                                label={capitalizeFirstLetter(transEnum(color, solderMaskColorTranslations))}
                                style={
                                    color === 'white'
                                        ? {
                                              borderColor: colorSystem.neutral[3],
                                              backgroundColor: colorSystem.neutral.white,
                                          }
                                        : undefined
                                }
                            />
                        </Flexbox>
                    ),
                    isOptionEqualToValue: (a, b) => a === b,
                }}
            />
        </FormItem>
    );
};
