/* eslint-disable spellcheck/spell-checker */
import { getToken } from '@luminovo/auth';
import { PCBV2, PdfAnalyzeResponse, http } from '@luminovo/http-client';
import { extractPcbSpec } from '@luminovo/pdf-extractor';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useHttpMutation } from '../../../../../../resources/mutation/useHttpMutation';
import { usePcb } from '../../../../../../resources/pcb/pcbHandlers';
import { convertExtractedValuesToRequestBody } from './converters';
import { useExtractedPcbSpecification } from './useExtractedPcbSpecification';

async function extractPcbSpecFromPdf({ pdfPath }: { pdfPath: string }) {
    const res = await fetch(pdfPath);

    if (!res.ok) {
        return {};
    }

    const blob = await res.blob();

    const formData = new FormData();
    formData.append('file', blob);
    const analyzeResult: PdfAnalyzeResponse = await http(
        'POST /analyze/pdf',
        {
            queryParams: { type: 'General' },
            requestBody: formData,
        },
        getToken(),
    );

    return extractPcbSpec({ azureExtractionResult: { analyzeResult } });
}

export const useExtractAndSavePcbSpecificationFromPdf = ({ pcbId }: { pcbId: string }) => {
    const { data: pcb } = usePcb(pcbId);
    const { data: extractedValues, isPending: isFetchingExtractedValues } = useExtractedPcbSpecification({ pcbId });

    const { mutateAsync: savePcbSpecification, isPending: isSaving } = useHttpMutation(
        'POST /pcb-pdf-specification-extraction',
        {
            snackbarMessage: null,
        },
    );

    const { mutateAsync: extractPcbSpecification, isPending: isExtracting } = useMutation({
        mutationFn: async (pcb: PCBV2) => {
            const pdfs = pcb.files?.filter((file) => file.path && file.name.toLowerCase().endsWith('.pdf')) ?? [];
            const extractionPromises = pdfs.map((pdf) =>
                extractPcbSpecFromPdf({ pdfPath: pdf.path ?? '' }).then((extracted) => ({ file: pdf, extracted })),
            );
            return Promise.allSettled(extractionPromises);
        },
        onSuccess: async (results) => {
            const fulfilledResults = results.filter(
                (result): result is PromiseFulfilledResult<{ file: any; extracted: any }> =>
                    result.status === 'fulfilled',
            );

            if (fulfilledResults.length === 0) {
                return;
            }

            // Find the result with most extracted attributes
            const bestResult = fulfilledResults.reduce((best, current) => {
                const bestKeys = Object.keys(best.value.extracted ?? {});
                const currentKeys = Object.keys(current.value.extracted ?? {});
                return currentKeys.length > bestKeys.length ? current : best;
            });

            await savePcbSpecification({
                requestBody: {
                    // eslint-disable-next-line camelcase
                    merged_results: convertExtractedValuesToRequestBody(bestResult.value),
                    file: bestResult.value.file,
                    pcb: pcbId,
                },
            });
        },
    });

    useEffect(() => {
        if (pcb && !extractedValues && !isExtracting && !isSaving && !isFetchingExtractedValues) {
            extractPcbSpecification(pcb);
        }
    }, [pcb, extractedValues, isExtracting, isSaving, isFetchingExtractedValues, extractPcbSpecification]);
};
