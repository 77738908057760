import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, Link, Text } from '@luminovo/design-system';
import { useExternalLinkDialog } from '../../../../../components/ExternalLinkDialog';
import { BannerCard } from './BannerCard';
import { DownloadQuotationButton } from './DownloadQuotationButton';
import { GREEN_GRADIENT } from './gradients';

export const BannerCardOrderShipped = ({
    orgName,
    shippingTrackingLink,
    rfqId,
}: {
    orgName: string | undefined;
    shippingTrackingLink: string | undefined;
    rfqId: string;
}): JSX.Element => {
    const emsName = orgName || 'EMS';
    const { openDialog } = useExternalLinkDialog();

    return (
        <BannerCard
            background={GREEN_GRADIENT}
            title={t`Your order has been shipped.`}
            description={
                <Flexbox flexDirection={'column'} gap={4}>
                    <Text
                        style={{
                            color: colorSystem.neutral.white,
                        }}
                    >
                        <Trans>{emsName} has shipped your order.</Trans>
                    </Text>
                    {shippingTrackingLink && (
                        <Flexbox flexDirection={'row'}>
                            <Text
                                style={{
                                    color: colorSystem.neutral.white,
                                }}
                            >
                                <Trans>
                                    Here is your{' '}
                                    <Link onClick={() => openDialog({ url: shippingTrackingLink })} attention="high">
                                        <span
                                            style={{
                                                color: colorSystem.neutral.white,
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            tracking link.
                                        </span>
                                    </Link>
                                </Trans>
                            </Text>
                        </Flexbox>
                    )}
                </Flexbox>
            }
            icon="📦"
            callToAction={
                <DownloadQuotationButton
                    rfqId={rfqId}
                    color={colorSystem.neutral.white}
                    background="rgba(255, 255, 255, .3)"
                    hideIfNoQuotation
                />
            }
        />
    );
};
