import { NetworkError } from '@luminovo/http-client';
import { Query, QueryCache, QueryClient, QueryClientProvider, QueryKey } from '@tanstack/react-query';
import React from 'react';
import { useGlobalErrorHandler } from '../../resources/http/debugErrorHandler';

interface Meta {
    /**
     * If set to false, an error will be silently ignored and not trigger any site effect for the User.
     *
     * Note that we will still log the error to Sentry and the console.
     */
    globalErrorHandler?: boolean;
}

// eslint-disable-next-line spellcheck/spell-checker
declare module '@tanstack/react-query' {
    interface Register {
        queryMeta: Meta;
        mutationMeta: Meta;
    }
}

export const AppQueryClientProvider: React.FunctionComponent = ({ children }) => {
    const { onError } = useGlobalErrorHandler();

    const errorRef = React.useRef<
        ((error: unknown, query: Query<unknown, unknown, unknown, QueryKey>) => void) | undefined
    >(undefined);

    // TODO - fix useRef
    // eslint-disable-next-line react-compiler/react-compiler
    errorRef.current = (error, query) => {
        const silent = query.meta?.globalErrorHandler === false;
        onError({ error, silent, silentUnknownError: true });
    };

    const [client] = React.useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        staleTime: 500, // Prevents a repeated network request if successful call made this number of milliseconds ago
                        refetchOnWindowFocus: false,
                        retry: (failureCount, error) => {
                            if (error instanceof NetworkError) {
                                return failureCount < 6;
                            }
                            return failureCount < 3;
                        },
                    },
                },
                queryCache: new QueryCache({
                    onError: errorRef.current,
                }),
            }),
    );

    return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};
