import * as r from 'runtypes';

// Please keep this in sync with https://gitlab.com/luminovo/product/epibator/-/blob/master/rust-workspace/domain-core/src/sources_api.rs#L22
export const SourcesApiRuntype = r.Union(
    r.Literal('Alba'),
    r.Literal('APCT'),
    r.Literal('Arrow'),
    r.Literal('AvnetAbacus'),
    r.Literal('AvnetApac'),
    r.Literal('AvnetEbv'),
    r.Literal('AvnetSilica'),
    r.Literal('AvnetUsa'),
    r.Literal('AlElektronik'),
    r.Literal('BetaLayout'),
    r.Literal('Blume'),
    r.Literal('Buerklin'),
    r.Literal('Digikey'),
    r.Literal('Element14'),
    r.Literal('Eve'),
    r.Literal('Farnell'),
    r.Literal('Future'),
    r.Literal('Gatema'),
    r.Literal('Gudeco'),
    r.Literal('Heilind'),
    r.Literal('IBRRingler'),
    r.Literal('Lcsc'),
    r.Literal('Master'),
    r.Literal('Mouser'),
    r.Literal('MyArrow'),
    r.Literal('Newark'),
    r.Literal('Nexar'),
    r.Literal('Octopart'),
    r.Literal('Onlinecomponents'),
    r.Literal('Corestaff'),
    r.Literal('WinSource'),
    r.Literal('Orbweaver'),
    r.Literal('RsComponents'),
    r.Literal('Rochester'),
    r.Literal('ChipCart'),
    r.Literal('Rutronik'),
    r.Literal('SafePcb'),
    r.Literal('Samtec'),
    r.Literal('Schukat'),
    r.Literal('Sos'),
    r.Literal('Sourcengine'),
    r.Literal('Stackrate'),
    r.Literal('Sluicebox'),
    r.Literal('Ti'),
    r.Literal('Tme'),
    r.Literal('TrustedParts'),
    r.Literal('Tti'),
    r.Literal('Venkel'),
    r.Literal('QuestComponents'),
    r.Literal('Weltron'),
    r.Literal('Wuerth'),
    r.Literal('WuerthEisos'),
);

export const ApiRelevanceRuntype = r.Record({
    api: SourcesApiRuntype,
    relevant: r.Boolean,
});

export type ApiRelevance = r.Static<typeof ApiRelevanceRuntype>;

export const sourcesApiValues = SourcesApiRuntype.alternatives.map((l) => l.value);

export type ApiRelevanceDTO = r.Static<typeof ApiRelevanceRuntype>;
export type SourcesApiDTO = r.Static<typeof SourcesApiRuntype>;
