import { isPresent } from '@luminovo/commons';
import { PCBStreamMessage, PCBV2 } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { httpQueryKey } from '../../../resources/http/httpQueryKey';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpWebSocket } from '../../../resources/http/useHttpWebSocket';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { useExtractAndSavePcbSpecificationFromPdf } from './PcbAnalysis/utils/usePcbPdfAnalysis/useExtractAndSavePcbSpecificationFromPdf';
import { PcbSidebar } from './PcbSidebar';

export function PcbSidebarLayout({
    assemblyId,
    pcb,
    rfqId,
    children,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    pcb: PCBV2;
    children: React.ReactNode;
    viewContext: ViewContext;
}) {
    return (
        <Box display="grid" gridTemplateColumns={`auto 1fr`} style={{ height: 'calc(100vh - 100px)' }}>
            <PcbWebsocketUpdater pcbId={pcb.id} />
            <PcbPdfExtractor pcbId={pcb.id} />
            <PcbSidebar pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} viewContext={viewContext} />
            <Box
                style={{
                    overflow: 'auto',
                    position: 'relative',
                    height: '100%',
                }}
            >
                {children}
            </Box>
        </Box>
    );
}

function PcbWebsocketUpdater({ pcbId }: { pcbId: string }) {
    const queryClient = useQueryClient();

    const { data: websocketToken } = useHttpQuery(
        'GET /ems/pcb/v2/pcbs/:pcbId/token',
        {
            pathParams: { pcbId: pcbId },
        },
        {
            select: (res) => res.authToken,
            staleTime: Infinity,
        },
    );

    const handleOnMessage = React.useCallback(
        (message: PCBStreamMessage) => {
            switch (message.t) {
                case 'analysis':
                    queryClient.invalidateQueries(
                        {
                            queryKey: httpQueryKey('GET /ems/pcb/v2/pcbs/:pcbId'),
                        },
                        { cancelRefetch: false },
                    );
                    return;
                case 'lifecycle':
                    queryClient.invalidateQueries(
                        {
                            queryKey: httpQueryKey('GET /ems/pcb/v2/pcbs/:pcbId'),
                        },
                        { cancelRefetch: false },
                    );
                    if (message.m.lifecycle.find((el) => el.name === 'main')?.status.name === 'success') {
                        queryClient.invalidateQueries(
                            {
                                queryKey: httpQueryKey('GET /assemblies/:assemblyId/pcb/:pcbId/emissions'),
                            },
                            { cancelRefetch: false },
                        );
                    }
                    return;
                case 'file':
                    queryClient.invalidateQueries(
                        {
                            queryKey: httpQueryKey('GET /ems/pcb/v2/pcbs/:pcbId'),
                        },
                        { cancelRefetch: false },
                    );
                    return;
                default:
                    return;
            }
        },
        [queryClient],
    );

    const handleOnClose = React.useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: httpQueryKey('GET /ems/pcb/v2/pcbs/:pcbId/token', { pathParams: { pcbId: pcbId } }),
        });
    }, [queryClient, pcbId]);

    useHttpWebSocket(
        'GET /ems/pcb/v2/pcbs/:pcbId/updates',
        { pathParams: { pcbId: pcbId }, queryParams: { k: websocketToken ?? '' } },
        {
            enabled: isPresent(websocketToken),
            onMessage: handleOnMessage,
            onClose: handleOnClose,
        },
    );

    return <></>;
}

function PcbPdfExtractorInner({ pcbId }: { pcbId: string }) {
    useExtractAndSavePcbSpecificationFromPdf({ pcbId });
    return <></>;
}

function PcbPdfExtractor({ pcbId }: { pcbId: string }) {
    return (
        <React.Suspense fallback={<></>}>
            <PcbPdfExtractorInner pcbId={pcbId} />
        </React.Suspense>
    );
}
