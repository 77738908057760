import { BaseButtonProps, SecondaryButton, useNavigate } from '@luminovo/design-system';
export function UploadButton({
    id,
    onClick,
    link,
    text,
    overrides = { Button: SecondaryButton },
    isEditable,
}: {
    id?: string;
    onClick?: () => void;
    link: string;
    text: string;
    overrides?: { Button: React.ComponentType<BaseButtonProps> };
    isEditable: boolean;
}) {
    const { Button } = overrides;
    const navigate = useNavigate();

    function handleClick() {
        if (onClick) {
            onClick();
        } else {
            navigate(link);
        }
    }
    return (
        <Button id={id} disabled={!isEditable} onClick={handleClick} size="small">
            {text}
        </Button>
    );
}
