import { Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { ButtonGroup, ButtonGroupItem, useNavigate } from '@luminovo/design-system';
import { usePageParams } from '../../../../../resources/hooks';
import { route } from '../../../../../utils/routes';
import { assertUnreachable } from '../../../../../utils/typingUtils';

export type ManufacturingRfqEntity = 'activity' | 'expense';

interface AppendEntityButtonGroupProps {
    entity: ManufacturingRfqEntity;
    totalExpenses: number;
    totalActivities: number;
}

export const AppendEntityButtonGroup = ({
    entity,
    totalActivities,
    totalExpenses,
}: AppendEntityButtonGroupProps): JSX.Element => {
    const navigate = useNavigate();

    const { rfqId, manufacturingAssemblyDetailsId, manufacturingScenarioId } = usePageParams<
        | '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/activity'
        | '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/expense'
    >();

    const handleEntityChange = (newValue: ManufacturingRfqEntity) => {
        switch (newValue) {
            case 'activity':
                navigate(
                    route(
                        '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/activity',
                        {
                            rfqId,
                            manufacturingAssemblyDetailsId,
                            manufacturingScenarioId,
                        },
                    ),
                );
                break;
            case 'expense':
                navigate(
                    route(
                        '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/expense',
                        {
                            rfqId,
                            manufacturingAssemblyDetailsId,
                            manufacturingScenarioId,
                        },
                    ),
                );
                break;
            default:
                assertUnreachable(newValue);
        }
    };

    return (
        <ButtonGroup size="large">
            <ButtonGroupItem
                value={'activity'}
                selected={entity === 'activity'}
                count={totalActivities}
                onClick={() => handleEntityChange('activity')}
                id={id('manufacturing_scenario/button_view_activities')}
            >
                <Trans>Activities</Trans>
            </ButtonGroupItem>
            <ButtonGroupItem
                value={'expense'}
                selected={entity === 'expense'}
                count={totalExpenses}
                onClick={() => handleEntityChange('expense')}
                id={id('manufacturing_scenario/button_view_expenses')}
            >
                <Trans>Expenses</Trans>
            </ButtonGroupItem>
        </ButtonGroup>
    );
};
