import { ComplianceStatus, isOtsFullPart } from '@luminovo/http-client';
import { ComplianceStatusChip } from '@luminovo/sourcing-core';
import { OtsPartComplianceStatusOriginsTooltip } from '../../../../../../components/PartComplianceView/OtsPartComplianceStatusOriginsTooltip';
import { ApprovedPartOptionItem } from '../bomOverviewFrontendTypes';
import { EmptyValue } from '../columns/EmptyValue';
import { MultiplePartOriginsTooltipWithChip } from './MultiplePartOriginsTooltipWithChip';

export const BomItemComplianceStatusOriginTooltipWithChip: React.FunctionComponent<{
    name: 'RoHS' | 'REACH' | 'AECQ';
    bomItemComplianceStatus: ComplianceStatus | undefined;
    approvedPartOptions: ApprovedPartOptionItem[];
}> = ({ name, bomItemComplianceStatus, approvedPartOptions }): JSX.Element => {
    if (!bomItemComplianceStatus) {
        return <EmptyValue />;
    }

    const isSingleOtsPart = approvedPartOptions.length === 1 && isOtsFullPart(approvedPartOptions[0].fullPart);

    if (isSingleOtsPart) {
        const part = approvedPartOptions[0].fullPart;
        return (
            <OtsPartComplianceStatusOriginsTooltip
                partId={part.id}
                name={name}
                complianceStatus={bomItemComplianceStatus}
            >
                <ComplianceStatusChip status={bomItemComplianceStatus} />
            </OtsPartComplianceStatusOriginsTooltip>
        );
    }

    return (
        <MultiplePartOriginsTooltipWithChip name={name}>
            <ComplianceStatusChip status={bomItemComplianceStatus} />
        </MultiplePartOriginsTooltipWithChip>
    );
};
