import { Trans, t } from '@lingui/macro';
import { isPresent, typeSafeObjectKeys, uniq } from '@luminovo/commons';
import { Flexbox, FormItem, FormSection, SecondaryButton, Text, useNavigate } from '@luminovo/design-system';
import { DriverDetailsType, PackageDTO, PackageMountingEnum } from '@luminovo/http-client';
import { Divider } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { ErrorText, SpinnerWithBackdrop } from '../../../components/Spinners';
import { FormContainer, ValidationErrors } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useCustomPartTypes, usePartPackages } from '../../../resources/part/partHandler';
import { CIRCULAR_PROGRESS_BUTTON_SIZE } from '../../../themes';
import { RecursivePartial } from '../../../utils/typingUtils';
import { AddDriverFormInputs } from './AddDriverFormTypes';
import { CalculationSection, GeneralSection } from './driverFormComponents';
import { useDriverFormFunction } from './driverFormFunctions';
/**
 * Helps abstract the things needed for a driver form
 * @param driverId driver id of the current driver, only pass this for the edit driver form
 */
export const useDriverForm = (driverId?: string) => {
    const { data: driversData, isLoading: isLoadingDrivers } = useHttpQuery('GET /user-drivers', {});
    const { data: allPackages = [], isLoading: isLoadingPackages } = usePartPackages('user-selectable');

    return {
        isLoading: isLoadingDrivers || isLoadingPackages,
        drivers: driversData,
        packages: allPackages,
    };
};

export const DriverForm = ({
    onSubmitType,
    defaultValues,
    formTitle,
    packages,
}: {
    onSubmitType: 'PATCH' | 'POST';
    defaultValues: RecursivePartial<AddDriverFormInputs>;
    formTitle: string;
    packages: PackageDTO[];
}) => {
    const { isLoading: isLoadingPartTypesData, isError: isErrorPartTypesData } = useCustomPartTypes(true);
    const { onSubmitPost, onSubmitPatch } = useDriverFormFunction();

    const validationErrors: ValidationErrors<AddDriverFormInputs> = {
        'driver.name_already_in_use': { fieldPath: 'name' },
        'driver.name_must_not_be_empty': { fieldPath: 'name' },
        'driver.name_must_be_alphanumeric': { fieldPath: 'name' },
        'driver.first_char_must_be_letter': { fieldPath: 'name' },
        'driver.name_is_reserved_keyword': { fieldPath: 'name' },
        'driver.name_is_system_driver': { fieldPath: 'name' },
        'driver.invalid_package_name': { fieldPath: 'root.serverError' },
    };

    if (isLoadingPartTypesData) {
        return <SpinnerWithBackdrop size={CIRCULAR_PROGRESS_BUTTON_SIZE} noBackdrop />;
    }
    if (isErrorPartTypesData) {
        <ErrorText />;
    }
    return (
        <Flexbox flexDirection="column">
            <Text variant="h1">{formTitle}</Text>
            <FormContainer
                defaultValues={defaultValues}
                validationErrors={validationErrors}
                onSubmit={onSubmitType === 'PATCH' ? onSubmitPatch : onSubmitPost}
            >
                <DriverFormInner onSubmitType={onSubmitType} packages={packages} />
            </FormContainer>
        </Flexbox>
    );
};

const DriverFormInner = ({
    onSubmitType,
    packages,
}: {
    onSubmitType: 'PATCH' | 'POST';
    packages: PackageDTO[];
}): JSX.Element => {
    const { control } = useFormContext<AddDriverFormInputs>();
    const driverType = useWatch({ control, name: 'driverDetails.type' });
    const navigate = useNavigate();

    let packageOptions: Record<PackageMountingEnum, string[]> = {
        [PackageMountingEnum.SMT]: [],
        [PackageMountingEnum.THT]: [],
        [PackageMountingEnum.PressFit]: [],
        [PackageMountingEnum.Other]: [],
    };
    packageOptions = packages
        .filter((packageDto) => isPresent(packageDto.name))
        .reduce((acc, packageDto) => {
            if (isPresent(packageDto.mounting) && isPresent(packageDto.name)) {
                acc[packageDto.mounting].push(packageDto.name);
            }

            return acc;
        }, packageOptions);

    for (let key of typeSafeObjectKeys(packageOptions)) {
        packageOptions[key] = uniq(packageOptions[key]);
    }

    return (
        <>
            <Flexbox width="50%">
                <GeneralSection onSubmitType={onSubmitType} />
            </Flexbox>
            {driverType === DriverDetailsType.Automatic && (
                <>
                    <Divider />
                    <FormSection title={t`Calculation`}>
                        <FormItem label="">
                            <CalculationSection packages={packageOptions} key={driverType} />
                        </FormItem>
                    </FormSection>
                </>
            )}
            <FormSection title="">
                <Flexbox justifyContent="end" gap={8}>
                    <SecondaryButton onClick={() => navigate(-1)}>
                        <Trans>Back</Trans>
                    </SecondaryButton>
                    <SubmitButton />
                </Flexbox>
            </FormSection>
        </>
    );
};
