import { PCBV2CadFile, ViewBoxCoordinateType } from '@luminovo/http-client';
import { keepPreviousData } from '@tanstack/react-query';
import React from 'react';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { CadViewerAction } from '../../../CadViewerTab/utils/cadViewerTypes';
import { isPcbFilePreviewAvailable } from '../../../utils/isPcbFilePreviewAvailable';
import { validateAndReturnSvgElement } from '../StackUpPreviewer/utils/parseSvgString';

const CadComponentNotMemoized = ({
    viewBox,
    dispatch,
    svgElement,
    fileId,
    svgProps,
}: {
    viewBox: ViewBoxCoordinateType;
    dispatch: React.Dispatch<CadViewerAction>;
    svgElement: SVGSVGElement;
    fileId: string;
    svgProps: React.SVGProps<SVGSVGElement>;
}) => {
    const ref = React.useRef<SVGSVGElement>(null);
    const svgElementRef = React.useRef<SVGSVGElement>(svgElement);

    const { height, width, x, y } = viewBox;
    // TODO - fix useRef
    // eslint-disable-next-line react-compiler/react-compiler
    svgElementRef.current.setAttribute('viewBox', `${x} ${y} ${width} ${height}`);

    React.useEffect(() => {
        if (ref.current) {
            ref.current.appendChild(svgElementRef.current);
            const groupBox = svgElementRef.current.querySelector('g')?.getBBox();

            if (groupBox) {
                dispatch({
                    type: 'UPDATE_CAD_MEASUREMENTS',
                    payload: {
                        id: fileId,
                        viewBox: {
                            x: groupBox.x,
                            y: groupBox.y,
                            width: groupBox.width,
                            height: groupBox.height,
                        },
                    },
                });
            }
        }

        return () => {
            dispatch({
                type: 'RESET_CAD_MEASUREMENTS',
                payload: {
                    id: fileId,
                },
            });
        };
    }, [dispatch, fileId]);

    return <svg ref={ref} preserveAspectRatio="xMinYMin meet" {...svgProps} />;
};

const CadComponent = React.memo(CadComponentNotMemoized);

const CadPreviewerFileNotMemoized = ({
    file,
    assemblyId,
    pcbId,
    dispatch,
    viewBox,
}: {
    file: PCBV2CadFile;
    assemblyId: string;
    pcbId: string;
    dispatch: React.Dispatch<CadViewerAction>;
    viewBox: ViewBoxCoordinateType;
}) => {
    const { data } = useHttpQuery(
        'GET /ems/renderer/assemblies/:assemblyId/versions/:pcbId/files/:fileName',
        {
            pathParams: { assemblyId, pcbId, fileName: file.name },
            queryParams: { format: 'svg' },
        },
        {
            select: (data) => {
                if (typeof data !== 'string') {
                    return undefined;
                }

                const svgElement = validateAndReturnSvgElement(data);

                svgElement.setAttribute('width', `100%`);
                svgElement.setAttribute('height', `100%`);
                svgElement.setAttribute('preserveAspectRatio', 'xMinYMin meet');
                svgElement.setAttribute('opacity', '0.5');
                svgElement.setAttribute('overflow', 'visible');
                svgElement.setAttribute('fill', 'inherit');
                svgElement.setAttribute('stroke', 'inherit');

                return { svgElement };
            },
            enabled: isPcbFilePreviewAvailable(file) && file.isVisible,
            // TODO: This is a workaround until we have websockets - we pull every second.
            retryDelay: 1000,
            retry: true,
            placeholderData: keepPreviousData,
        },
    );

    // Decided to check the visibility here instead of in the parent component to avoid rerendering
    // that was occurring when the visibility was changed
    if (data === undefined || file.isVisible === false) {
        return null;
    }

    return (
        <CadComponent
            svgElement={data.svgElement}
            dispatch={dispatch}
            fileId={file.id}
            viewBox={viewBox}
            svgProps={{
                id: file.name,
                fill: file.fileColor,
                stroke: file.fileColor,
                height: viewBox.height,
                width: viewBox.width,
            }}
        />
    );
};

export const CadPreviewerFile = React.memo(CadPreviewerFileNotMemoized);
