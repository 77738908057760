import { isPresent, isProductionEnvironment, throwErrorUnlessProduction } from '@luminovo/commons';
import { Runtype, Static } from 'runtypes';
import { isFilterValue } from '../Filters/createFilterValueUpdater';
import { TanStackTableState, UseTanStackTablePaginationProps } from '../type';

/**
 * Generates the backend filter request for the current table state.
 */
export function generateFilterRequest<TData, TSharedContext, TRuntype extends Runtype>(
    tableState: TanStackTableState<TData>,
    columns: UseTanStackTablePaginationProps<TData, TSharedContext>['columns'],
    runtype: TRuntype,
): Array<Static<typeof runtype>> {
    const result = tableState.columnFilters.map((filter) => {
        const columnDef = columns.find((def) => def.id === filter.id);
        const filterValueTransform = columnDef?.meta?.filterValueTransform ?? ((value: unknown) => value);

        if (!isPresent(columnDef)) {
            throw new Error(`Unknown filter id: ${filter.id}`);
        }

        if (!isPresent(columnDef.id)) {
            throw new Error(`Column id must be defined`);
        }

        if (!isPresent(columnDef.meta) || !isPresent(columnDef.meta?.filterConfig)) {
            throw new Error(`Filter function is not defined for column ${columnDef.id}`);
        }

        if (!isFilterValue(filter.value)) {
            throw new Error(`Invalid filter value for column ${columnDef.id}`);
        }

        return {
            field: columnDef.id,
            operator: columnDef.meta.filterConfig.defaultFilterFn,
            parameter: filterValueTransform(filter.value.value),
        };
    });

    if (!isProductionEnvironment()) {
        for (const item of result) {
            try {
                runtype.check(item);
            } catch (runtypeError) {
                throwErrorUnlessProduction(runtypeError);
            }
        }
    }

    return result;
}

/**
 * Generates the backend sort request for the current table state.
 */
export function generateSortRequest<TData, TSharedContext, TRuntype extends Runtype>(
    tableState: TanStackTableState<TData>,
    columns: UseTanStackTablePaginationProps<TData, TSharedContext>['columns'],
    runtype: TRuntype,
): Array<Static<typeof runtype>> {
    const result = tableState.sorting.map((sort) => {
        const columnDef = columns.find((def) => def.id === sort.id);

        if (!isPresent(columnDef)) {
            throw new Error(`Unknown filter id: ${sort.id}`);
        }

        if (!isPresent(columnDef.id)) {
            throw new Error(`Column id must be defined`);
        }

        return {
            field: columnDef.id,
            direction: sort.desc ? 'desc' : 'asc',
        };
    });

    try {
        if (!isProductionEnvironment()) {
            result.forEach((item) => runtype.check(item));
        }
    } catch (runtypeError) {
        if (!isProductionEnvironment()) {
            throwErrorUnlessProduction(runtypeError);
        }
    }

    return result;
}

/**
 * Retrieves the count of a specific option from an array of aggregations.
 * This is used to show the number of rows which would be filtered by selecting an filter option.
 */
// eslint-disable-next-line max-params
export function getOptionCount<
    A extends { field: string; options: Array<{ value: unknown; count: number }> },
    F extends A['field'],
    T,
    U = T,
>(
    option: T,
    aggregations: A[] | undefined,
    field: F,
    valueSelector: (option: T) => U = (o) => o as unknown as U,
): number | undefined {
    return aggregations?.find((a) => a.field === field)?.options.find((i) => i.value === valueSelector(option))?.count;
}
