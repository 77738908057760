import { t } from '@lingui/macro';
import { assertUnreachable, formatInteger, formatToMonth, isPresent } from '@luminovo/commons';
import { TanStackTable, Tooltip, createColumnHelper, useTanStackTable } from '@luminovo/design-system';
import { ColumnDefWithSharedContext } from '@luminovo/design-system/src/components/TanStackTable/type';
import { DemandSummaryDTO } from '@luminovo/http-client';
import { generateMonthlyDemands, generateYearlyDemands } from '@luminovo/manufacturing-core';
import { TableCell } from '@mui/material';
import { NoDemandsMessage } from '../../components/NoDemandsMessage';
import { DemandTimeframeType, DemandType, ParticipantRole } from './types';

type Row = string[];
type Table = Array<Row>;

interface TableColumn {
    type: 'string';
    id: string;
    label?: string;
    columnIndex: number;
    tooltip?: string;
}

export function transformDemandSummaryToTable({
    demandSummary,
    demandType,
    participantRole,
    timeframeType,
}: {
    demandSummary: DemandSummaryDTO | undefined;
    demandType: DemandType;
    participantRole: ParticipantRole;
    timeframeType: DemandTimeframeType;
}): {
    tableColumns: TableColumn[];
    table: Table;
} {
    if (!isPresent(demandSummary)) {
        return { tableColumns: [], table: [] };
    }

    let participantDemands = [];
    let label = '';
    switch (participantRole) {
        case 'supplier':
            participantDemands = demandSummary.supplier_demands;
            label = t`Supplier (site)`;
            break;
        case 'recipient':
            participantDemands = demandSummary.ship_to_site_demands;
            label = t`Ship to (site)`;
            break;
        default:
            assertUnreachable(participantRole);
    }

    if (participantDemands.length === 0) {
        return { tableColumns: [], table: [] };
    }

    const timeframes: TableColumn[] = (() => {
        switch (timeframeType) {
            case 'monthly':
                const monthlyDemands = generateMonthlyDemands(participantDemands[0].monthly_demands);
                return monthlyDemands.map((demand, idx) => {
                    const dateStart = new Date(
                        `${demand.month_and_year.year}-${demand.month_and_year.month.toString().padStart(2, '0')}-01`,
                    );
                    return {
                        id: formatToMonth(dateStart),
                        columnIndex: idx + 1,
                        type: 'string' as const,
                    };
                });
            case 'yearly':
                const yearlyDemands = generateYearlyDemands(participantDemands[0].yearly_demands);
                return yearlyDemands.map((demand, idx) => {
                    return {
                        id: demand.year.toString(),
                        columnIndex: idx + 1,
                        type: 'string' as const,
                    };
                });
            default:
                assertUnreachable(timeframeType);
        }
    })();

    const tableColumns: TableColumn[] = [
        {
            id: 'Participant',
            columnIndex: 0,
            type: 'string',
            label,
        },
        ...timeframes,
    ];

    const demandTypeField: 'gross_quantity' | 'net_quantity' = (() => {
        switch (demandType) {
            case 'gross':
                return 'gross_quantity';
            case 'net':
                return 'net_quantity';
            default:
                assertUnreachable(demandType);
        }
    })();
    const table: Table = participantDemands.map((participantDemand) => {
        const demands =
            timeframeType === 'yearly'
                ? generateYearlyDemands(participantDemand['yearly_demands'])
                : timeframeType === 'monthly'
                  ? generateMonthlyDemands(participantDemand['monthly_demands'])
                  : assertUnreachable(timeframeType);

        return [
            participantDemand.participant_name ?? '',
            ...demands.map((item) => formatInteger(item[demandTypeField].quantity)),
        ];
    });

    return { tableColumns: tableColumns, table };
}

const columnHelper = createColumnHelper<Row>();

export function DemandSummaryTableDynamic({
    demandSummary,
    demandType,
    participantRole,
    timeframeType,
}: {
    demandSummary: DemandSummaryDTO;
    demandType: DemandType;
    participantRole: ParticipantRole;
    timeframeType: DemandTimeframeType;
}): JSX.Element {
    const { table, tableColumns } = transformDemandSummaryToTable({
        demandSummary,
        demandType,
        participantRole,
        timeframeType,
    });

    let columns: ColumnDefWithSharedContext<Row, string, unknown>[] = [];
    if (table.length > 0) {
        columns = Object.keys(table[0]).map((_key, colIndex) => {
            const selected = tableColumns.find((option) => option.columnIndex === colIndex) ?? null;
            const label = selected ? (selected.label ?? selected.id) : `Column #${colIndex + 1}`;
            const tooltip = selected?.tooltip;
            const header = (
                <TableCell style={{ verticalAlign: 'middle', paddingLeft: 0 }} variant="head">
                    {label}
                </TableCell>
            );
            return columnHelper.text(
                (row) => {
                    return row[colIndex] ?? '';
                },
                {
                    id: String(colIndex),
                    enableColumnFilter: false,
                    enableSorting: false,
                    enableGlobalFilter: true,
                    enableMultiSort: false,
                    label: () => label,
                    header: function Header() {
                        if (isPresent(tooltip)) {
                            return (
                                <Tooltip title={tooltip} placement="top">
                                    {header}
                                </Tooltip>
                            );
                        }
                        return header;
                    },
                    size: 100,
                },
            );
        });
    }

    const tanstackTable = useTanStackTable({
        columns,
        data: table,
        enablePersistentGlobalFilter: true,
        enableColumnOrdering: false,
        enablePersistentColumnFilters: false,
    });

    return (
        <TanStackTable
            size="medium"
            enableMenuBar={false}
            table={tanstackTable.table}
            EmptyPlaceholder={() => <NoDemandsMessage />}
        />
    );
}
