import { t } from '@lingui/macro';
import { colorSystem, Flexbox, MenuButton, MenuItem, Text } from '@luminovo/design-system';
import { CustomOptionOfferDTO, OfferOriginEnum, RfqContext } from '@luminovo/http-client';
import { formatSupplierAndStockLocationDTO, OfferChips, Solution } from '@luminovo/sourcing-core';
import { ContentCopyRounded, DeleteOutlineRounded, EditRounded, MoreHoriz } from '@mui/icons-material';
import React, { useState } from 'react';
import { CloseDrawerButton, useDrawerContext } from '../../../../../components/contexts/ModalContext';
import ConfirmationDialogBox from '../../../../../components/dialogBox/ConfirmationDialogBox';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import { route } from '../../../../../utils/routes';
import { OfferOriginTag } from './OfferOriginTag';
import { LinkedCustomPartInformation } from './PartInformation';

const ManualOfferActionsButton: React.FunctionComponent<{
    offer: CustomOptionOfferDTO;
    rfqContext: RfqContext;
}> = ({ offer, rfqContext }): JSX.Element => {
    const { closeDrawer } = useDrawerContext();
    const { mutateAsync } = useHttpMutation('DELETE /offers/custom-part/:id', {
        snackbarMessage: t`Offer successfully deleted`,
    });

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const isManualOffer = offer.origin.origin === OfferOriginEnum.Manual;

    if (rfqContext.type !== 'WithinRfQ') {
        return <></>;
    }

    return (
        <>
            <MenuButton
                label={<MoreHoriz fontSize="inherit" />}
                appearance="secondary"
                size="medium"
                style={{ minWidth: 16, padding: 8 }}
            >
                {isManualOffer && (
                    <MenuItem
                        startIcon={<EditRounded />}
                        label={t`Edit`}
                        href={route('/rfqs/:rfqId/sourcing/offer/custom-part/:offerId/edit', {
                            rfqId: rfqContext.rfq_id,
                            offerId: offer.id,
                        })}
                    />
                )}

                <MenuItem
                    startIcon={<ContentCopyRounded />}
                    label={t`Duplicate`}
                    href={route('/rfqs/:rfqId/sourcing/offer/custom-part/:offerId/duplicate', {
                        rfqId: rfqContext.rfq_id,
                        offerId: offer.id,
                    })}
                />

                {isManualOffer && (
                    <MenuItem
                        startIcon={<DeleteOutlineRounded />}
                        label={t`Delete`}
                        onClick={() => setIsDialogOpen(true)}
                        variant={'destructive'}
                    />
                )}
            </MenuButton>
            <ConfirmationDialogBox
                title={t`Delete offer`}
                text={t`Are you sure you want to delete this offer?`}
                isDialogOpen={isDialogOpen}
                onConfirm={async () => {
                    await mutateAsync({
                        pathParams: { id: offer.id },
                    });
                    setIsDialogOpen(false);
                    closeDrawer();
                }}
                onReject={() => setIsDialogOpen(false)}
            />
        </>
    );
};

export const CustomPartDrawerHeader: React.FunctionComponent<{
    offer: CustomOptionOfferDTO;
    solution?: Solution;
    rfqContext: RfqContext;
    fallbackSolutionTags?: Solution['solutionTags'];
    sourcingScenarioId: string;
    isPcbCustomPartOffer?: boolean;
}> = ({ offer, solution, rfqContext, fallbackSolutionTags, sourcingScenarioId, isPcbCustomPartOffer }): JSX.Element => {
    return (
        <Flexbox
            flexDirection={'column'}
            gap={20}
            padding={'16px'}
            bgcolor={colorSystem.neutral[0]}
            borderBottom={`1px solid ${colorSystem.neutral[2]}`}
        >
            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                <CloseDrawerButton disableMargin={true} />
                <Flexbox alignItems={'center'} gap={8}>
                    <OfferOriginTag offer={offer} origin={offer.origin} />
                    <ManualOfferActionsButton offer={offer} rfqContext={rfqContext} />
                </Flexbox>
            </Flexbox>
            <Flexbox flexDirection={'column'} gap={12}>
                <Text variant="h2" color={colorSystem.neutral[8]}>
                    {formatSupplierAndStockLocationDTO(offer.linked_location)}
                </Text>
                <OfferChips solution={solution} fallbackSolutionTags={fallbackSolutionTags} />
            </Flexbox>
            <LinkedCustomPartInformation
                offer={offer}
                sourcingScenarioId={sourcingScenarioId}
                rfqContext={rfqContext}
                isPcbCustomPartOffer={isPcbCustomPartOffer}
            />
        </Flexbox>
    );
};
