import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { WinSourceCredentialsInputDTORuntype, WinSourceResponseBodyDTORuntype } from './winsourceBackendTypes';

export const winsourceEndpoints = {
    'GET /3rdparty/winsource/credentials': endpoint({
        description: 'Returns credentials for winsource integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: WinSourceResponseBodyDTORuntype,
    }),
    'POST /3rdparty/winsource/credentials': endpoint({
        description: 'Uploads credentials for winsource API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: WinSourceCredentialsInputDTORuntype,
        responseBody: WinSourceResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/winsource/credentials'],
    }),
    'PATCH /3rdparty/winsource/credentials': endpoint({
        description: 'Updates credentials for winsource API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: WinSourceCredentialsInputDTORuntype,
        responseBody: WinSourceResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/winsource/credentials'],
    }),
    'DELETE /3rdparty/winsource/credentials': endpoint({
        description: 'Deletes existing credentials for winsource API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/winsource/credentials'],
    }),
};
