/* eslint-disable camelcase */
import { MpnIndex } from '@/modules/Negotiations/model/MpnIndex';
import { StandardPartQuoteImportResult } from '@/modules/Negotiations/NegotiationsQuoteImporter/types';
import { isPresent } from '@luminovo/commons';
import {
    CustomOptionOfferDTO,
    PartLite,
    PartLiteTypes,
    QuoteRequestLineItemDTO,
    StandardPartOfferDTO,
    StandardPartTypes,
} from '@luminovo/http-client';
import { LeadTimeUnit } from '@luminovo/sourcing-core';
import { RowItem } from './types';

export function convertPartLiteToStandardPartDTO(
    part: PartLite | null | undefined,
    ipn?: string,
): { data: string; type: StandardPartTypes } | undefined {
    if (!isPresent(part)) {
        return undefined;
    }
    switch (part.kind) {
        case PartLiteTypes.OffTheShelf:
            return { data: part.id, type: StandardPartTypes.OffTheShelf };
        case PartLiteTypes.Generic:
            return { data: part.id, type: StandardPartTypes.Generic };
        case PartLiteTypes.Ipn:
            return { data: part.id, type: StandardPartTypes.Ipn };
        case PartLiteTypes.RawSpecification:
        case PartLiteTypes.Unknown:
            if (!ipn) {
                return undefined;
            }
            return { data: ipn, type: StandardPartTypes.Ipn };
        case PartLiteTypes.Custom:
        case PartLiteTypes.CustomComponent:
            // Not supported on the OTS supplier portal
            return undefined;
    }
}

/**
 * A quote request is a custom part quote request if all of its line items are custom parts.
 */
export function isCustomPartQuoteRequest(quoteRequestLineItems: QuoteRequestLineItemDTO[]) {
    return quoteRequestLineItems.every((item) => item.requested_part?.kind === PartLiteTypes.Custom);
}

export function isImportMatch(quoteRequestLineItemId: number, lumiQuoteId?: string) {
    if (!lumiQuoteId) {
        return false;
    }
    const ids = lumiQuoteId.split('|').map((x) => x.trim());
    return ids.some((idStr) => idStr === String(quoteRequestLineItemId));
}

/**
 * Scroll to the first element with the given selector.
 * If a ref is provided, scroll to the closest ancestor with the given selector.
 */
export function scrollToEditableCell(selector: 'error' | 'warning', ref?: HTMLElement | null) {
    // Find the first cell with error/warning or closest ancestor if ref provided
    const element = ref
        ? ref.closest(`tr`)?.querySelector(`[data-status="${selector}"]`)
        : document.querySelector(`[data-status="${selector}"]`);

    if (!element) {
        return;
    }

    // Scroll element into view with smooth animation
    element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
    });
}

/**
 * Converts a quote request line item to a row item.
 */
export function convertQuoteRequestLineItemsToRowItems({
    quoteRequestLineItems,
    getPartOrThrow,
    getOffer,
    getCustomPartOffer,
    getImporterRow,
    mpnIndex,
}: {
    quoteRequestLineItems: QuoteRequestLineItemDTO[];
    getPartOrThrow: (id: string) => PartLite;
    getOffer: (id?: string) => StandardPartOfferDTO | undefined;
    getCustomPartOffer: (id?: string) => CustomOptionOfferDTO | undefined;
    getImporterRow: (lineItemId: number) => StandardPartQuoteImportResult['quoteLineRow'][number] | undefined;
    mpnIndex: MpnIndex;
}): RowItem[] {
    return quoteRequestLineItems
        .map((lineItem): RowItem | undefined => {
            const offerId = lineItem.received_offer?.offer_id;

            const offerFieldSuppliers: OfferFieldsSuppplier[] = [
                getOfferFieldsFromImporterRow(getImporterRow(lineItem.id), mpnIndex),
                getOfferFieldsFromCustomPartOffer(getCustomPartOffer(offerId?.id), getPartOrThrow),
                getOfferFieldsFromStandardPartOffer(getOffer(offerId?.id), getPartOrThrow),
                getOfferFieldsWhenNoOffer(lineItem),
            ].filter(isPresent);

            if (!isPresent(lineItem.requested_part)) {
                return undefined;
            }

            const firstOfferFieldSupplier = offerFieldSuppliers[0];

            return {
                lineItemId: lineItem.id,
                id: `${lineItem.id}::${Math.random().toString(36)}`,
                component_origin: lineItem.component_origin ?? undefined,
                requested_part: lineItem.requested_part,
                status: lineItem.status,
                description: lineItem.description ?? undefined,
                requiredQuantity: lineItem.required_quantity,
                potentialQuantity: lineItem.potential_quantity,
                recipients: lineItem.recipients ?? undefined,
                customerName: lineItem.customers ?? undefined,
                targetPrice: lineItem.target_price ?? undefined,
                ...firstOfferFieldSupplier(),
            };
        })
        .filter(isPresent);
}

type OfferFields = Omit<
    RowItem,
    | 'lineItemId'
    | 'id'
    | 'component_origin'
    | 'requested_part'
    | 'description'
    | 'requiredQuantity'
    | 'potentialQuantity'
    | 'recipients'
    | 'customerName'
    | 'targetPrice'
    | 'status'
>;
type OfferFieldsSuppplier = () => OfferFields;

const getOfferFieldsFromStandardPartOffer = (
    offer: StandardPartOfferDTO | undefined,
    getPartOrThrow: (id: string) => PartLite,
): OfferFieldsSuppplier | undefined => {
    if (!offer) {
        return undefined;
    }
    const priceBreak = offer.available_prices.price_breaks[0];
    if (!priceBreak) {
        return undefined;
    }

    return (): OfferFields => {
        return {
            cancellationWindow: offer.cancellation_window_in_days ?? undefined,
            cancellationWindowUnit: LeadTimeUnit.Days,
            stdFactoryLeadTime: offer.available_prices.factory_lead_time_days ?? undefined,
            stdFactoryLeadTimeUnit: LeadTimeUnit.Days,
            bid: true,
            moq: priceBreak.moq,
            mpq: priceBreak.mpq,
            notes: offer.notes ?? '',
            stock: offer.available_prices.stock ?? undefined,
            itemClass: offer.item_class ?? undefined,
            packaging: offer.packaging ?? undefined,
            ncnr: offer.ncnr ?? false,
            validFrom: offer.valid_from ?? undefined,
            validUntil: offer.valid_until ?? undefined,
            selected: true,
            offerCreatedAt: offer.creation_date,
            offerId: offer.id,
            offeredPart: { part: getPartOrThrow(offer.linked_part.id) },
            unitPrice: priceBreak.unit_price,
            pricePer: priceBreak.mpq,
            oneTimeCosts: offer.one_time_costs,
            supplierPartNumber: offer.supplier_part_number ?? undefined,
        };
    };
};

const getOfferFieldsFromCustomPartOffer = (
    offer: CustomOptionOfferDTO | undefined,
    getPartOrThrow: (id: string) => PartLite,
): OfferFieldsSuppplier | undefined => {
    if (!offer) {
        return undefined;
    }
    const priceBreak = offer.price_points[0];
    if (!priceBreak) {
        return undefined;
    }
    return () => {
        return {
            cancellationWindow: undefined,
            cancellationWindowUnit: LeadTimeUnit.Weeks,
            stdFactoryLeadTime: undefined,
            stdFactoryLeadTimeUnit: LeadTimeUnit.Weeks,
            bid: true,
            moq: priceBreak.quantity,
            mpq: priceBreak.quantity,
            notes: offer.notes ?? '',
            stock: undefined,
            itemClass: undefined,
            packaging: undefined,
            ncnr: false,
            validFrom: undefined,
            validUntil: undefined,
            selected: true,
            offerCreatedAt: offer.creation_date,
            offerId: offer.id,
            offeredPart: { part: getPartOrThrow(offer.linked_part.id) },
            unitPrice: priceBreak.unit_price,
            pricePer: priceBreak.quantity,
            oneTimeCosts: offer.one_time_costs,
            supplierPartNumber: undefined,
        };
    };
};

const getOfferFieldsWhenNoOffer = (lineItem: QuoteRequestLineItemDTO): OfferFieldsSuppplier => {
    return () => {
        const requestedPart = lineItem.requested_part;

        const defaultOfferedPart =
            requestedPart?.kind === PartLiteTypes.Custom ||
            requestedPart?.kind === PartLiteTypes.Ipn ||
            requestedPart?.kind === PartLiteTypes.RawSpecification ||
            requestedPart?.kind === PartLiteTypes.OffTheShelf
                ? requestedPart
                : undefined;

        return {
            selected: true,
            bid: true,
            cancellationWindow: undefined,
            cancellationWindowUnit: LeadTimeUnit.Weeks,
            stdFactoryLeadTime: undefined,
            stdFactoryLeadTimeUnit: LeadTimeUnit.Weeks,
            moq: undefined,
            mpq: undefined,
            notes: '',
            stock: undefined,
            itemClass: undefined,
            packaging: undefined,
            ncnr: false,
            validFrom: undefined,
            validUntil: undefined,
            offerCreatedAt: undefined,
            offeredPart: defaultOfferedPart ? { part: defaultOfferedPart } : undefined,
            offerId: undefined,
            oneTimeCosts: [],
            pricePer: 1,
            supplierPartNumber: undefined,
            unitPrice: undefined,
        };
    };
};

const getOfferFieldsFromImporterRow = (
    importedRow: StandardPartQuoteImportResult['quoteLineRow'][number] | undefined,
    mpnIndex: MpnIndex,
): OfferFieldsSuppplier | undefined => {
    const row = importedRow?.row;
    if (!row) {
        return undefined;
    }
    return () => {
        const amount = row.unitPrice;
        const currency = row.currency;
        const unitPrice = amount && currency ? { amount: String(amount), currency } : undefined;
        return {
            selected: true,
            bid: row.bid ?? false,
            cancellationWindow: row.cancellationWindow ?? undefined,
            cancellationWindowUnit: convertLeadTimeUnit(row.cancellationTimeUnit) ?? LeadTimeUnit.Weeks,
            stdFactoryLeadTime: row.stdFactoryLeadTime ?? undefined,
            stdFactoryLeadTimeUnit: convertLeadTimeUnit(row.stdFactoryLeadTimeUnit) ?? LeadTimeUnit.Weeks,
            moq: row.minOrderQuantity ?? undefined,
            mpq: row.minPackagingQuantity ?? undefined,
            notes: row.additionalNotes ?? '',
            stock: row.stock ?? undefined,
            itemClass: row.itemClass ?? undefined,
            packaging: row.packaging ?? undefined,
            ncnr: row.ncnr ?? false,
            validFrom: row.validFrom ?? undefined,
            validUntil: row.validUntil ?? undefined,
            offerCreatedAt: undefined,
            offeredPart: mpnIndex.find({ mpn: row.offeredMpn, manufacturer: row.offeredManufacturer }),
            offerId: undefined,
            oneTimeCosts: [],
            pricePer: row.unitPriceQuantity ?? 1,
            supplierPartNumber: row.supplierPartNumber ?? undefined,
            unitPrice,
        };
    };
};

function convertLeadTimeUnit(unit: 'days' | 'weeks' | undefined): LeadTimeUnit | undefined {
    switch (unit) {
        case 'days':
            return LeadTimeUnit.Days;
        case 'weeks':
            return LeadTimeUnit.Weeks;
        case undefined:
            return undefined;
    }
}
