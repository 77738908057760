import { getToken } from '@luminovo/auth';
import {
    AzureExtractionResult,
    CustomOptionOfferDTO,
    http,
    OtsFullPart,
    RfqContext,
    StandardPartOfferDTO,
} from '@luminovo/http-client';
import { processPdf, RegionNetwork } from '@luminovo/sourcing-core';
import { useMutation, UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useBulkQuery, useBulkSingleQuery } from '../batching/useBulkQuery';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { useHttpQuery } from '../http/useHttpQuery';
import { convertRfqContext } from '../part/partHandler';

export function useOTSOffer({ offerId, rfqContext }: { offerId: string | undefined; rfqContext: RfqContext }) {
    return useBulkSingleQuery('POST /offers/off-the-shelf/bulk', offerId, {
        idExtractor: (item: StandardPartOfferDTO) => item.id,
        httpOptions: (ids) => {
            return { requestBody: { ids, ...convertRfqContext(rfqContext) } };
        },
        select: (res) => res.items,
    });
}

export function useOTSOffers({ offerIds, rfqContext }: { offerIds: string[] | undefined; rfqContext: RfqContext }) {
    return useBulkQuery('POST /offers/off-the-shelf/bulk', offerIds, {
        idExtractor: (item: StandardPartOfferDTO) => item.id,
        httpOptions: (ids) => {
            return { requestBody: { ids, ...convertRfqContext(rfqContext) } };
        },
        select: (res) => res.items,
    });
}

export const useCustomPartOffer = (offerId: string) => {
    return useBulkSingleQuery('POST /offers/custom-part/bulk', offerId, {
        idExtractor: (item: CustomOptionOfferDTO) => item.id,
        httpOptions: (ids) => {
            return { requestBody: { ids } };
        },
        select: (res) => res.items,
    });
};

export function useCustomPartOffers(offerIds: string[]): UseQueryResult<CustomOptionOfferDTO[]> {
    return useHttpQuery(
        'POST /offers/custom-part/bulk',
        {
            requestBody: {
                ids: offerIds,
            },
        },
        {
            enabled: offerIds.length > 0,
            select: (res) => res.items,
        },
    );
}

export function useMutationAnalyzePdf(): UseMutationResult<
    {
        pdfExtractedData: AzureExtractionResult;
        file: File;
        regionNetwork: RegionNetwork;
        uploadedUrl?: string;
    },
    unknown,
    { file: File; expectedParts: OtsFullPart[] }
> {
    const token = getToken();
    const onError = useDebugErrorHandler();
    return useMutation({
        onError,
        mutationFn: async ({ file, expectedParts }) => {
            const form = new FormData();
            form.append('file', file);

            const pdfImportPromise = http(
                'POST /analyze/pdf',
                {
                    queryParams: { type: 'Offer' },
                    requestBody: form,
                },
                token,
            );

            const uploadedUrl = await pdfImportPromise.then((res) => res.attachment);

            const azureExtractionResult: AzureExtractionResult = {
                analyzeResult: await pdfImportPromise,
            };

            const regionNetwork = processPdf(azureExtractionResult, { expectedParts });

            return {
                pdfExtractedData: azureExtractionResult,
                file,
                regionNetwork,
                uploadedUrl,
            };
        },
    });
}
