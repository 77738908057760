import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout, PrimaryButton, RiskOverviewIcon, Toolbar } from '@luminovo/design-system';
import { AssemblyResponseDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import React from 'react';

import { useNavigate } from '@luminovo/design-system';
import { ProgressAwareTabs } from '../../../components/ProgressAwareTabs';
import { ImportedAssemblyProgressTab } from '../../../components/ProgressAwareTabs/ProgressAwareTabs';
import { useAssembly } from '../../../resources/assembly/assemblyHandler';
import { route, UrlParams } from '../../../utils/routes';
import { isAssemblyTableData, ModuleTableData, ViewContext } from '../../Bom/components/ModuleTableData';
import { BomDetails } from '../../DesignItem/BomEditPage';
import { useIsAssemblyEditable } from '../utils';

export default function AssemblyDetailsPage({ pathParams, queryParams }: UrlParams<'/assemblies/:assemblyId/details'>) {
    const assemblyId = pathParams.assemblyId;

    const { data: currentAssembly } = useAssembly(assemblyId);

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'AssemblyOverview',
            rfqId: currentAssembly?.rfq ?? undefined,
        };
    }, [currentAssembly?.rfq]);

    const detailsQueryParams = React.useMemo(() => {
        return {
            ...queryParams,
            currentParentAssemblyId: undefined,
            onlyShowItemsWithManufacturingWarnings: undefined,
            isReadonly: undefined,
            bomTab: undefined,
        };
    }, [queryParams]);

    const isEditable = useIsAssemblyEditable(currentAssembly);

    if (!isPresent(currentAssembly)) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return (
        <BomDetails
            assemblyId={assemblyId}
            isEditable={isEditable}
            viewContext={viewContext}
            queryParams={detailsQueryParams}
            renderToolBar={(selectedBomItem) => (
                <AssemblyDetailsToolbar
                    viewContext={viewContext}
                    assembly={currentAssembly}
                    selectedModule={selectedBomItem}
                />
            )}
        />
    );
}

const assemblyRootRouteBreadcrumb = {
    href: route('/assemblies'),
    title: <Trans>Assemblies</Trans>,
};

function AssemblyDetailsToolbar({
    assembly,
    selectedModule,
    viewContext,
}: {
    selectedModule: ModuleTableData | undefined;
    assembly: AssemblyResponseDTO;
    viewContext: ViewContext;
}) {
    const navigate = useNavigate();
    const breadcrumbs = React.useMemo(() => {
        const currentAssembly = {
            title: assembly.designator,
            href: route('/assemblies/:assemblyId/dashboard', { assemblyId: assembly.id }),
        };

        const currentBomItem = selectedModule
            ? { title: generateDesignatorAsTitle(selectedModule) }
            : { title: t`BOM` };
        return [assemblyRootRouteBreadcrumb, currentAssembly, currentBomItem];
    }, [assembly.designator, assembly.id, selectedModule]);

    const center = React.useMemo(() => {
        if (assembly.rfq) {
            return (
                <ProgressAwareTabs
                    assembly={assembly}
                    rfqId={assembly.rfq}
                    viewContext={viewContext}
                    currentParentAssemblyId={null}
                />
            );
        }
        return <ImportedAssemblyProgressTab assemblyId={assembly.id} />;
    }, [assembly, viewContext]);
    return (
        <Toolbar breadcrumbs={breadcrumbs} center={center}>
            <PrimaryButton
                size={'medium'}
                onClick={() => {
                    navigate(route('/assemblies/:assemblyId/dashboard', { assemblyId: assembly.id }));
                }}
                startIcon={<RiskOverviewIcon />}
                style={{ whiteSpace: 'nowrap' }}
            >
                <Trans>Risk overview</Trans>
            </PrimaryButton>
        </Toolbar>
    );
}

function generateDesignatorAsTitle(selectedBomItem: ModuleTableData | undefined) {
    if (!selectedBomItem) return '';
    if (isAssemblyTableData(selectedBomItem)) {
        return selectedBomItem.designator;
    }
    return selectedBomItem.designator.sort().join(', ');
}
