import { Trans } from '@lingui/macro';
import { Flexbox, Switch, Text, colorSystem, usePersistedState } from '@luminovo/design-system';
import { UseFormReturn } from 'react-hook-form';
import { TransEnum } from '../../../components/localization/TransEnum';
import { BomItem } from '../../../resources/designItem/bomItemFrontendTypes';
import { designItemOriginTypesTranslations } from '../../../resources/designItem/i18n';
import { BomItemFormState } from '../../DesignItem/components/BomItemFormState';
import { BomFileDetails } from './BomFileDetails';

export const OriginalExcel = ({
    bomItem,
    formReturn,
}: {
    bomItem: BomItem;
    formReturn: UseFormReturn<BomItemFormState>;
}) => {
    const [isColumnMap, setColumnMap] = usePersistedState<'true' | 'false'>('isColumnMap', 'false', localStorage);

    function handleChange() {
        if (isColumnMap === 'true') setColumnMap('false');
        if (isColumnMap === 'false') setColumnMap('true');
    }
    return (
        <Flexbox
            flexDirection="column"
            style={{ background: colorSystem.neutral.white }}
            padding="20px"
            borderRadius="8px"
            gap="8px"
        >
            <Flexbox justifyContent="space-between">
                <Text variant="h4" color={colorSystem.neutral[8]}>
                    <TransEnum text={bomItem.origin.type} translations={designItemOriginTypesTranslations} />
                </Text>
                <Flexbox alignItems={'center'} gap={'8px'}>
                    <Switch checked={isColumnMap === 'true'} onChange={handleChange} />
                    <Text>
                        <Trans>Show column mapping</Trans>
                    </Text>
                </Flexbox>
            </Flexbox>

            <BomFileDetails bomItem={bomItem} formReturn={formReturn} isColumnMap={isColumnMap === 'true'} />
        </Flexbox>
    );
};
