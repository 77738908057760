import { Trans } from '@lingui/macro';
import { formatDecimal, isPresent, throwErrorUnlessProduction } from '@luminovo/commons';
import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { DemandSummaryDTO, ParticipantDemandSummaryDTO, StandardPartOfferDTO } from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { Skeleton } from '@mui/material';
import { NoDemandsMessage } from '../../../../../components/NoDemandsMessage';
import { SadBox } from '../../../../../components/errorHandlers/SadBox';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';

export function getTotalStock(offers: StandardPartOfferDTO[]): number {
    return offers.reduce(
        // Workaround: In some case the total_stock is null, so we use the available stock instead
        (acc, offer) => acc + (offer.available_prices?.total_stock ?? offer.available_prices?.stock ?? 0),
        0,
    );
}

export function getAvailableStock(offers: StandardPartOfferDTO[]): number {
    return offers.reduce((acc, offer) => acc + (offer.available_prices?.stock ?? 0), 0);
}

function SingleSiteBreakdown({
    shipToSiteDemand,
    ipnId,
}: {
    shipToSiteDemand: ParticipantDemandSummaryDTO;
    ipnId: string;
}): JSX.Element {
    const { data: offers, isLoading } = useHttpQuery(
        'GET /offers/off-the-shelf/by-ipn',
        { queryParams: { ipn: ipnId, rfq_context: 'OutsideRfQ', source: 'Inventory' } },
        {
            select: (res) =>
                /* eslint-disable-next-line array-callback-return */
                res.items.filter(
                    (offer) =>
                        'site_number' in offer.linked_location &&
                        offer.linked_location.name === shipToSiteDemand.participant_name,
                ),
        },
    );

    const { data: site, isLoading: isSiteLoading } = useHttpQuery(
        'GET /organization-settings/sites',
        {},
        {
            //need to find the site by name because the site number is not always available and site name is unique
            select: (res) => res.data.sites.find((site) => site.name === shipToSiteDemand.participant_name),
        },
    );

    if (isLoading || isSiteLoading) {
        return <Skeleton height="120px" />;
    }

    const shipToSiteName = site?.name;

    if (!isPresent(shipToSiteName)) {
        throwErrorUnlessProduction(`Cannot find recipient: ${shipToSiteDemand.participant_number}`);
        return <SadBox />;
    }

    const totalInventory = isPresent(offers) && offers.length > 0 ? getTotalStock(offers) : undefined;
    const availableInventory = isPresent(offers) && offers.length > 0 ? getAvailableStock(offers) : undefined;

    const onOrderQuantity =
        isPresent(offers) && offers.length > 0
            ? offers.reduce(
                  (acc, offer) => acc + (offer.available_prices?.on_order.reduce((acc, v) => acc + v.quantity, 0) ?? 0),
                  0,
              )
            : undefined;

    return (
        <Flexbox
            gap={8}
            padding="16px"
            style={{ outline: `1px solid ${colorSystem.neutral[2]}` }}
            borderRadius="8px"
            flexDirection="column"
        >
            <Text variant="h4" color={colorSystem.neutral[8]}>
                {shipToSiteName}
            </Text>
            <Flexbox gap={8}>
                <Flexbox flexDirection="column" gap={4} width="100%">
                    <Text variant="body-small" color={colorSystem.neutral[8]}>
                        <Trans>Total inventory</Trans>
                    </Text>
                    <Text variant={'h4'}>{isPresent(totalInventory) ? formatDecimal(totalInventory) : '-'}</Text>
                </Flexbox>
                <Flexbox flexDirection="column" gap={4} width="100%">
                    <Text variant="body-small" color={colorSystem.neutral[8]}>
                        <Trans>Available inventory</Trans>
                    </Text>
                    <Text variant={'h4'}>
                        {isPresent(availableInventory) ? formatDecimal(availableInventory) : '-'}
                    </Text>
                </Flexbox>
                <Flexbox flexDirection="column" gap={4} width="100%">
                    <Text variant="body-small" color={colorSystem.neutral[8]}>
                        <Trans>On order quantity</Trans>
                    </Text>
                    <Text variant={'h4'}>{isPresent(onOrderQuantity) ? formatDecimal(onOrderQuantity) : '-'}</Text>
                </Flexbox>
                <Flexbox flexDirection="column" gap={4} width="100%">
                    <Text variant="body-small" color={colorSystem.neutral[8]}>
                        <Trans>Gross demand</Trans>
                    </Text>
                    <Text variant={'h4'}>{formatQuantity(shipToSiteDemand.total_gross_demand)}</Text>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    );
}

export function SiteBreakdown({
    summary,
    ipnId,
}: {
    summary: DemandSummaryDTO | undefined;
    ipnId: string;
}): JSX.Element {
    if (!isPresent(summary)) {
        return <Skeleton height="120px" />;
    }

    if (summary.ship_to_site_demands.length === 0) {
        return <NoDemandsMessage />;
    }

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '16px',
            }}
        >
            {summary.ship_to_site_demands.map((shipToSiteDemand, idx) => (
                <SingleSiteBreakdown shipToSiteDemand={shipToSiteDemand} ipnId={ipnId} key={idx} />
            ))}
        </div>
    );
}
