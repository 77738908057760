import { t } from '@lingui/macro';
import { useNavigate } from '@luminovo/design-system';
import { DriverDetailsType } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { PageLayout } from '../../../components/PageLayout';
import { capitalizeFirstLetter } from '../../../utils/stringFunctions';
import { DriverForm, useDriverForm } from './DriverForm';
import { useNewLexoRank } from './utils';
export const DriverFormPage = (): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { isLoading, packages } = useDriverForm();

    const result = useNewLexoRank();
    const newDriverLexoRank = result?.lexoRank;

    if (!result || isLoading || !packages) {
        return (
            <PageLayout layout="centered">
                <CircularProgress />
            </PageLayout>
        );
    }

    if (newDriverLexoRank === undefined) {
        enqueueSnackbar({ message: t`Can't add a new driver`, variant: 'error' });
        navigate(-1);
    }

    return (
        <PageLayout layout="full-width">
            <DriverForm
                defaultValues={{
                    isPerPanel: false,
                    lexoRank: newDriverLexoRank,
                    driverDetails: { type: DriverDetailsType.Manual },
                }}
                onSubmitType={'POST'}
                formTitle={capitalizeFirstLetter(t`Add driver`)}
                packages={packages}
            />
        </PageLayout>
    );
};
