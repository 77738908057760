import { colorSystem, Flexbox, TertiaryIconButton, Text, Tooltip } from '@luminovo/design-system';
import { ExpandMore, InfoRounded } from '@mui/icons-material';
import React from 'react';

type CollapsibleSectionProps = {
    id?: string;
    label: React.ReactNode;
    labelIcon?: React.ReactNode;
    description?: React.ReactNode;
    isOpenByDefault?: boolean;
    shouldCollapseButtonBeShown?: boolean;
    ContentStyle?: React.CSSProperties;
    CollapseStyle?: React.CSSProperties;
    action?: React.ReactNode;
    tooltipProps?: Omit<React.ComponentProps<typeof Tooltip>, 'children' | 'title'>;
};

type PcbCollapseProps = Omit<CollapsibleSectionProps, 'isOpenByDefault' | 'shouldCollapseButtonBeShown'> & {
    isOpen: boolean;
    onClick?: () => void;
};

export const PcbCollapse: React.FunctionComponent<PcbCollapseProps> = ({
    label,
    id,
    action,
    children,
    description,
    labelIcon,
    onClick,
    isOpen,
    tooltipProps,
    ContentStyle,
    CollapseStyle,
}) => {
    return (
        <Flexbox
            id={id}
            flexDirection={'column'}
            alignItems="start"
            style={{
                width: '100%',
                ...CollapseStyle,
            }}
        >
            <Flexbox
                style={{
                    padding: '16px 16px 8px',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                }}
                onClick={onClick}
            >
                <Flexbox
                    style={{
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        gap: '8px',
                    }}
                >
                    <Flexbox alignItems={'center'} gap={8}>
                        {labelIcon}
                        <Text variant="h4" style={{ color: colorSystem.neutral[8] }}>
                            {label}
                        </Text>
                        {description && (
                            <Tooltip title={description} placement="bottom-start" variant="white" {...tooltipProps}>
                                <InfoRounded
                                    style={{
                                        cursor: 'pointer',
                                        color: colorSystem.neutral[5],
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Flexbox>
                    {action}
                </Flexbox>

                {onClick && (
                    <TertiaryIconButton size="medium" style={{ width: 24, height: 24 }}>
                        <ExpandMore
                            style={{
                                transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                                transition: 'transform 0.1s linear 0s',
                            }}
                            fontSize="inherit"
                        />
                    </TertiaryIconButton>
                )}
            </Flexbox>
            {isOpen && (
                <Flexbox
                    id="content"
                    gap={24}
                    flexDirection={'column'}
                    alignItems="flex-start"
                    style={{
                        width: '100%',
                        padding: '8px 16px 16px',
                        ...ContentStyle,
                    }}
                >
                    {children}
                </Flexbox>
            )}
        </Flexbox>
    );
};

export const CollapsibleSection: React.FunctionComponent<CollapsibleSectionProps> = ({
    isOpenByDefault = false,
    shouldCollapseButtonBeShown = false,
    ...rest
}) => {
    const [isOpen, setIsOpen] = React.useState(isOpenByDefault || !shouldCollapseButtonBeShown);

    React.useEffect(() => {
        // if isOpenByDefault changes, it should only cause the section to open
        if (isOpenByDefault || !shouldCollapseButtonBeShown) {
            setIsOpen(true);
        }
    }, [isOpenByDefault, shouldCollapseButtonBeShown]);

    return (
        <PcbCollapse
            isOpen={isOpen}
            onClick={shouldCollapseButtonBeShown ? () => setIsOpen((x) => !x) : undefined}
            {...rest}
        />
    );
};
