import { Trans, t } from '@lingui/macro';
import { compareByString, isEqual, isPresent } from '@luminovo/commons';
import {
    CenteredLayout,
    Dialog,
    DialogContent,
    DialogTitle,
    Flexbox,
    Text,
    useNavigate,
} from '@luminovo/design-system';
import { AssemblyFormDTO, SourcingScenarioDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { useAssembly } from '../../../resources/assembly/assemblyHandler';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { useSourcingScenariosOfRfq } from '../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { route } from '../../../utils/routes';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { AssemblyForm } from './AssemblyForm';
import { AssemblySourcingScenariosTable, useIsAssemblyQuantityOfAssemblyValid } from './AssemblySourcingScenariosTable';

const useAssemblyEditValidationDialog = () => {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: ({
            assemblyId,
            nonZeroSourcingScenarios,
        }: {
            assemblyId: string;
            nonZeroSourcingScenarios: SourcingScenarioDTO[];
        }) =>
            setDialog(
                <Dialog open={true} maxWidth={'md'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle title={t`Moving not possible`} handleClose={() => closeDialog()} />

                    <DialogContent style={{ paddingBottom: '24px' }}>
                        <Flexbox flexDirection={'column'} gap={8}>
                            <Text>
                                <Trans>The top-level assembly is in use in the following sourcing scenarios.</Trans>
                            </Text>
                            <AssemblySourcingScenariosTable
                                assemblyId={assemblyId}
                                sourcingScenarios={nonZeroSourcingScenarios}
                            />
                            <Text>
                                <Trans>Before moving it you need to set all order sizes to zero.</Trans>
                            </Text>
                        </Flexbox>
                    </DialogContent>
                </Dialog>,
            ),
    };
};

export function EditAssemblyForm({
    currentParentAssemblyId,
    rfqId,
    assemblyId,
    viewContext,
}: {
    currentParentAssemblyId: string | null | undefined;
    rfqId: string;
    assemblyId: string;
    viewContext: ViewContext;
}): JSX.Element {
    const navigate = useNavigate();
    const { data: rfq } = useRfQ(rfqId);
    const { data: assembly } = useAssembly(assemblyId);
    const { data: sourcingScenarios } = useSourcingScenariosOfRfq(rfqId);

    const onSuccessRoute = (parents: { [key: string]: number }) => {
        if (viewContext.type === 'WithinRfQ') {
            const parentId =
                currentParentAssemblyId && currentParentAssemblyId in parents
                    ? currentParentAssemblyId
                    : Object.keys(parents)[0];
            return route(
                '/rfqs/:rfqId/bom/assembly/:assemblyId',
                { rfqId, assemblyId },
                { currentParentAssemblyId: parentId, tab: null, monitoring: null },
            );
        }

        return route(
            '/assemblies/:assemblyId/dashboard',
            { assemblyId },
            { rfqId, tab: undefined, isMonitoringOpen: undefined },
        );
    };

    const { mutateAsync } = useHttpMutation('PATCH /assemblies/:assemblyId', {
        snackbarMessage: t`Assembly successfully updated`,
        onSuccess: (data) => navigate(onSuccessRoute(data.data.parents)),
    });

    const { openDialog } = useAssemblyEditValidationDialog();
    const { hasNonZeroAssemblyQuantity, nonZeroSourcingScenarios } = useIsAssemblyQuantityOfAssemblyValid({
        rfqId,
        assemblyId,
    });

    if (!isPresent(assembly) || !isPresent(sourcingScenarios) || !isPresent(rfq)) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const onSubmitCallback = async (requestBody: AssemblyFormDTO) => {
        const hasChangedParent = !isEqual(
            requestBody.parents.sort(compareByString),
            Object.keys(assembly.parents).sort(compareByString),
        );
        if (hasChangedParent && hasNonZeroAssemblyQuantity && isPresent(nonZeroSourcingScenarios)) {
            openDialog({ assemblyId, nonZeroSourcingScenarios });
        } else {
            await mutateAsync({ pathParams: { assemblyId }, requestBody });
        }
    };

    /* eslint-disable camelcase */
    const defaultValues: AssemblyFormDTO = {
        rfq: rfqId,
        type: assembly.type,
        notes: assembly.notes ?? '',
        designator: assembly.designator,
        customer: rfq.customer,
        industry: assembly.industry,
        parents: Object.keys(assembly.parents),
        sub_assembly_quantities: assembly.subassemblies.items,
        ipn_value: assembly.ipn?.value ?? '',
        ipn_revision: assembly.ipn?.revision ?? '',
    };
    /* eslint-enable camelcase */

    return (
        <AssemblyForm
            onSubmit={onSubmitCallback}
            defaultValues={defaultValues}
            disableAssemblyType={true}
            assemblyId={assemblyId}
        />
    );
}
