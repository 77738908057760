import { Trans } from '@lingui/macro';
import { SecondaryButton, useNavigate } from '@luminovo/design-system';
import { Upload } from '@mui/icons-material';
import { route } from '../../../utils/routes';

export function ButtonNegotiationQuoteImporter({
    negotiationId,
    quoteRequestId,
}: {
    negotiationId: number;
    quoteRequestId: string;
}) {
    const navigate = useNavigate();

    return (
        <SecondaryButton
            onClick={() =>
                navigate(
                    route('/negotiations/:negotiationId/quote-importer', { negotiationId }, { newImporter: 'true' }),
                )
            }
            startIcon={<Upload />}
            size="medium"
        >
            <Trans>Upload quote</Trans>
        </SecondaryButton>
    );
}
