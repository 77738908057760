import { Trans, t } from '@lingui/macro';
import {
    Currency,
    assertUnreachable,
    formatDecimal,
    isPresent,
    throwErrorUnlessProduction,
    transEnum,
} from '@luminovo/commons';
import {
    CenteredLayout,
    DEFAULT_SELECT_COLUMN_ID,
    FieldCheckboxControlled,
    FieldDateControlled,
    FieldNumericControlled,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    SupportedFilterFn,
    Tag,
    Text,
    Tooltip,
    colorSystem,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import { Packaging, QuantityUnit } from '@luminovo/http-client';
import { LabelPart, formatPart, packagingTranslations, quantityUnitTranslations } from '@luminovo/sourcing-core';
import {
    CheckCircleOutlineRounded,
    DoNotDisturbOutlined,
    ErrorOutlineRounded,
    Notes,
    SyncProblemOutlined,
    WarningAmberRounded,
} from '@mui/icons-material';
import { InputAdornment, Skeleton } from '@mui/material';
import React from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { inputCurrenciesPublicTranslations } from '../../../../resources/currencyInputTypes';
import { useSourcingScenario } from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import {
    CustomPartQuoteHeader,
    CustomPartQuoteHeaderToPropsMap,
    QuoteImporterFormState,
    QuoteLineTableData,
    SharedContext,
    StandardPartQuoteHeader,
    extractQuoteLineStatus,
    standardPartQuoteHeaderToPropsMap,
} from './types';

function generateWarningAdornment(
    line: QuoteLineTableData,
    quoteHeader: StandardPartQuoteHeader | CustomPartQuoteHeader,
) {
    const columnWarnings = line.warnings.find((warning) => {
        return warning.column === quoteHeader;
    });

    const hasStandardPartLineValue =
        line.type === 'StandardPartLine' &&
        isPresent(line.row[standardPartQuoteHeaderToPropsMap[quoteHeader as StandardPartQuoteHeader]]);
    const hasCustomPartLineValue =
        line.type === 'CustomPartLine' &&
        isPresent(line.row[CustomPartQuoteHeaderToPropsMap[quoteHeader as CustomPartQuoteHeader]]);

    if (hasStandardPartLineValue || hasCustomPartLineValue || !isPresent(columnWarnings)) {
        return null;
    }

    return (
        <InputAdornment position="start">
            <Tooltip
                variant="white"
                disableMaxWidth={true}
                title={
                    <Flexbox gap={4} alignItems={'center'}>
                        <WarningAmberRounded style={{ color: colorSystem.yellow[6] }} fontSize="inherit" />
                        <Text variant="body-small">
                            {t`Invalid entry `} <Text variant="body-small-semibold">{`${columnWarnings.value}`}</Text>{' '}
                            {t` detected in column `} <Text variant="body-small-semibold">{columnWarnings.column}</Text>{' '}
                            {t` at row `}{' '}
                            <Text variant="body-small-semibold">{formatDecimal(line.excelRowNumber)}</Text>{' '}
                            {t` of the spreadsheet.`}
                        </Text>
                    </Flexbox>
                }
            >
                <WarningAmberRounded style={{ color: colorSystem.yellow[6] }} fontSize="inherit" />
            </Tooltip>
        </InputAdornment>
    );
}
const columnHelper = createColumnHelper<QuoteLineTableData, SharedContext>();

const columnErrorStatus = columnHelper.array((row) => row.status, {
    id: 'status',
    size: 70,
    label: () => t`Status`,
    enableHiding: false,
    options: ['good', 'errors', 'warnings', 'missingUnit', 'missingLeadTimeUnit'],
    getOptionLabel: (option) => {
        switch (option) {
            case 'good':
                return t`Ready for import`;
            case 'errors':
                return t`Errors`;
            case 'warnings':
                return t`Warnings`;
            case 'missingUnit':
                return t`Missing unit`;
            case 'missingLeadTimeUnit':
                return t`Missing lead time unit`;
            default:
                return t`No errors`;
        }
    },
    quickFilters: [
        {
            label: () => t`Errors`,
            value: {
                filterFn: SupportedFilterFn.arrIncludesSome,
                value: ['errors', 'missingUnit', 'missingLeadTimeUnit'],
            },
            showCount: true,
        },
        {
            label: () => t`Warnings`,
            value: {
                filterFn: SupportedFilterFn.arrIncludesSome,
                value: ['warnings'],
            },
            showCount: true,
        },
    ],
    align: 'center',
    cell: (item) => {
        if (item.getValue().includes('missingUnit')) {
            return (
                <CenteredLayout>
                    <Tooltip
                        variant="white"
                        disableMaxWidth={true}
                        title={t`This row cannot be imported due to missing unit value.`}
                    >
                        <DoNotDisturbOutlined style={{ color: colorSystem.red[6] }} fontSize="small" />
                    </Tooltip>
                </CenteredLayout>
            );
        }

        if (item.getValue().includes('missingLeadTimeUnit')) {
            return (
                <Tooltip
                    variant="white"
                    disableMaxWidth={true}
                    title={t`This row cannot be imported due to missing lead time unit value.`}
                >
                    <DoNotDisturbOutlined style={{ color: colorSystem.red[6] }} fontSize="small" />
                </Tooltip>
            );
        }

        if (item.getValue().includes('errors')) {
            return (
                <Tooltip variant="white" disableMaxWidth={true} title={t`This row cannot be imported due to errors.`}>
                    <ErrorOutlineRounded style={{ color: colorSystem.red[6] }} fontSize="small" />
                </Tooltip>
            );
        }

        if (item.getValue().includes('warnings')) {
            return (
                <Tooltip
                    variant="white"
                    disableMaxWidth={true}
                    title={t`This row contains potential issues. Please verify the row before importing.`}
                >
                    <WarningAmberRounded style={{ color: colorSystem.yellow[6] }} fontSize="small" />
                </Tooltip>
            );
        }

        if (item.getValue().includes('good')) {
            return (
                <Tooltip variant="white" disableMaxWidth={true} title={t`This row is ready for import.`}>
                    <CheckCircleOutlineRounded style={{ color: colorSystem.green[6] }} fontSize="small" />
                </Tooltip>
            );
        }

        return <></>;
    },
});

function getAlternativePartPlaceholder(data: QuoteLineTableData) {
    if (data.type !== 'StandardPartLine') {
        return t`Unknown`;
    }

    if (isPresent(data.row.alternativeMpn)) {
        return `${data.row.alternativeMpn}, ${data.row.alternativeManufacturer ?? t`Unknown`}`;
    }
    if (isPresent(data.row.mpn)) {
        return `${data.row.mpn}, ${data.row.manufacturer ?? t`Unknown`}`;
    }

    return `${t`Unknown`}, ${t`Unknown`}`;
}

const columnPart = columnHelper.text((row) => formatPart(row.linkedPart.part), {
    id: 'part',
    label: () => t`Part`,
    size: 280,
    renderType: 'generic',
    cell: ({ row, sharedContext }) => {
        switch (row.original.linkedPart.type) {
            case 'OffTheShelf':
                return <LabelPart part={row.original.linkedPart.part} />;
            case 'CustomPart':
                return <LabelPart part={row.original.linkedPart.part} />;
            case 'CustomComponent':
                return <LabelPart part={row.original.linkedPart.part} />;
            case 'Ipn':
                return <LabelPart part={row.original.linkedPart.part} />;
            case 'AlternativePart':
                return (
                    <FieldSelectControlled
                        key={row.original.index}
                        control={sharedContext.control}
                        name={`lines.${row.original.index}.linkedPart.part`}
                        displayErrorAsTooltip={true}
                        required={true}
                        FieldProps={{
                            size: 'small',
                            style: { width: '280px', backgroundColor: colorSystem.neutral.white },
                            options: row.original.linkedPart.matches,
                            placeholder: getAlternativePartPlaceholder(row.original),
                            getOptionLabel: (option) => formatPart(option),
                            isOptionEqualToValue: (option, value) => option.id === value.id,
                            inputProps: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Tooltip
                                            title={t`Multiple parts match the provided alternative MPN. Please select the correct part from the dropdown menu.`}
                                        >
                                            <SyncProblemOutlined
                                                style={{ color: colorSystem.neutral[6] }}
                                                fontSize="inherit"
                                            />
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                );
            case 'NonMatching':
                return (
                    <Tooltip
                        title={t`We could not find a matching part. Please check the mpn and manufacturer name in row ${row.original.excelRowNumber} of the spreadsheet.`}
                    >
                        <span>
                            <Tag color={'red'} label={t`Part details are missing`} attention="low" />
                        </span>
                    </Tooltip>
                );

            default:
                assertUnreachable(row.original.linkedPart);
        }
    },
});

const columnAdditionalNotes = columnHelper.text('row.additionalNotes', {
    id: 'additionalNotes',
    label: () => t`Notes`,
    size: 80,
    cell: (item) => {
        const notes = item.getValue();
        if (!Boolean(notes) || !isPresent(notes)) {
            return <></>;
        }

        return (
            <Flexbox justifyContent={'center'}>
                <Tooltip
                    variant={'white'}
                    title={
                        <Flexbox flexDirection={'column'} gap={4} padding={'4px'}>
                            <Text variant="h5" color={colorSystem.neutral[8]}>
                                <Trans>Notes</Trans>
                            </Text>
                            <Text variant="body-small" color={colorSystem.neutral[8]}>
                                {notes}
                            </Text>
                        </Flexbox>
                    }
                >
                    <Notes
                        style={{
                            color: colorSystem.neutral[6],
                            border: `1px solid ${colorSystem.neutral[2]}`,
                            borderRadius: '24px',
                            padding: '2px 8px',
                            fontSize: '16px',
                        }}
                    />
                </Tooltip>
            </Flexbox>
        );
    },
});

const columnUnitPrice = columnHelper.number('row.unitPrice', {
    label: () => t`Unit price`,
    id: 'unitPrice',
    size: 120,
    align: 'left',
    cell: ({ row, sharedContext }) => (
        <FieldNumericControlled
            key={row.original.index}
            control={sharedContext.control}
            name={`lines.${row.original.index}.row.unitPrice`}
            displayErrorAsTooltip={true}
            required={true}
            exclusiveMin={0}
            FieldProps={{
                size: 'small',
                InputProps: {
                    endAdornment: <InputAdornment position="end">{row.original.row.currency}</InputAdornment>,
                    startAdornment: generateWarningAdornment(row.original, StandardPartQuoteHeader.UnitPrice),
                },
                style: { width: '120px' },
            }}
        />
    ),
});

const columnStock = columnHelper.number('row.stock', {
    label: () => t`Stock`,
    id: 'stock',
    size: 120,
    align: 'left',
    cell: ({ row, sharedContext }) => (
        <FieldNumericControlled
            key={row.original.index}
            control={sharedContext.control}
            name={`lines.${row.original.index}.row.stock`}
            displayErrorAsTooltip={true}
            min={0}
            FieldProps={{
                size: 'small',
                style: { width: '120px' },
                InputProps: {
                    startAdornment: generateWarningAdornment(row.original, StandardPartQuoteHeader.Stock),
                },
            }}
        />
    ),
});

const columnLeadTime = columnHelper.number('row.leadTime', {
    label: () => t`Lead time`,
    id: 'leadTime',
    size: 120,
    align: 'left',
    cell: ({ row, sharedContext }) => {
        if (row.original.type === 'CustomPartLine') {
            return <></>;
        }
        return (
            <FieldNumericControlled
                key={row.original.index}
                control={sharedContext.control}
                name={`lines.${row.original.index}.row.leadTime`}
                displayErrorAsTooltip={true}
                exclusiveMin={0}
                FieldProps={{
                    size: 'small',
                    InputProps: {
                        endAdornment: <InputAdornment position="end">{row.original.row.leadTimeUnit}</InputAdornment>,
                        startAdornment: generateWarningAdornment(row.original, StandardPartQuoteHeader.LeadTime),
                    },
                    style: { width: '100px' },
                }}
            />
        );
    },
});

const columnUnitPriceQuantity = columnHelper.number('row.unitPriceQuantity', {
    label: () => t`Unit price quantity`,
    id: 'unitPriceQuantity',
    size: 120,
    align: 'left',
    cell: ({ row, sharedContext }) => {
        if (row.original.type === 'CustomPartLine') {
            return <></>;
        }

        return (
            <FieldNumericControlled
                key={row.original.index}
                control={sharedContext.control}
                name={`lines.${row.original.index}.row.unitPriceQuantity`}
                displayErrorAsTooltip={true}
                required={true}
                exclusiveMin={0}
                FieldProps={{
                    size: 'small',
                    InputProps: {
                        endAdornment: <InputAdornment position="end">{row.original.row.unit}</InputAdornment>,
                        startAdornment: generateWarningAdornment(
                            row.original,
                            StandardPartQuoteHeader.UnitPriceQuantity,
                        ),
                    },
                    style: { width: '100px' },
                }}
            />
        );
    },
});

const columnSupplierPartNumber = columnHelper.text('row.supplierPartNumber', {
    label: () => t`SKU`,
    id: 'supplierPartNumber',
    size: 120,
    cell: ({ row, sharedContext }) => (
        <FieldTextControlled
            key={row.original.index}
            control={sharedContext.control}
            name={`lines.${row.original.index}.row.supplierPartNumber`}
            displayErrorAsTooltip={true}
            FieldProps={{
                size: 'small',
                style: { width: '120px' },
                InputProps: {
                    startAdornment: generateWarningAdornment(row.original, StandardPartQuoteHeader.SupplierPartNumber),
                },
            }}
        />
    ),
});
const columnPackaging = columnHelper.enum('row.packaging', {
    label: () => t`Packaging`,
    id: 'packaging',
    size: 140,
    options: Object.values(Packaging),
    getOptionLabel: (option) => transEnum(option, packagingTranslations),
    cell: ({ row, sharedContext }) => (
        <FieldSelectControlled
            key={row.original.index}
            control={sharedContext.control}
            name={`lines.${row.original.index}.row.packaging`}
            displayErrorAsTooltip={true}
            FieldProps={{
                size: 'small',
                style: { width: '140px' },
                options: Object.values(Packaging),
                getOptionLabel: (option) => transEnum(option, packagingTranslations),
                inputProps: {
                    startAdornment: generateWarningAdornment(row.original, StandardPartQuoteHeader.Packaging),
                },
            }}
        />
    ),
});
const columnMinOrderQuantity = columnHelper.number('row.minOrderQuantity', {
    label: () => t`MOQ`,
    id: 'minOrderQuantity',
    size: 120,
    align: 'left',
    cell: ({ row, sharedContext }) => (
        <FieldNumericControlled
            key={row.original.index}
            control={sharedContext.control}
            name={`lines.${row.original.index}.row.minOrderQuantity`}
            displayErrorAsTooltip={true}
            exclusiveMin={0}
            FieldProps={{
                size: 'small',
                style: { width: '100px' },
                placeholder: '1',
                InputProps: {
                    startAdornment: generateWarningAdornment(row.original, StandardPartQuoteHeader.MinOrderQuantity),
                },
            }}
        />
    ),
});
const columnMinPackagingQuantity = columnHelper.number('row.minPackagingQuantity', {
    label: () => t`MPQ`,
    id: 'minPackagingQuantity',
    size: 120,
    align: 'left',
    cell: ({ row, sharedContext }) => (
        <FieldNumericControlled
            key={row.original.index}
            control={sharedContext.control}
            name={`lines.${row.original.index}.row.minPackagingQuantity`}
            displayErrorAsTooltip={true}
            exclusiveMin={0}
            FieldProps={{
                size: 'small',
                style: { width: '100px' },
                placeholder: '1',
                InputProps: {
                    startAdornment: generateWarningAdornment(
                        row.original,
                        StandardPartQuoteHeader.MinPackagingQuantity,
                    ),
                },
            }}
        />
    ),
});

const columnCurrency = columnHelper.enum('row.currency', {
    label: () => t`Currency`,
    id: 'currency',
    size: 120,
    getOptionLabel: (option) => (isPresent(option) ? transEnum(option, inputCurrenciesPublicTranslations) : t`Unknown`),
    cell: ({ row, sharedContext }) => (
        <FieldSelectControlled
            key={row.original.index}
            control={sharedContext.control}
            name={`lines.${row.original.index}.row.currency`}
            displayErrorAsTooltip={true}
            required={true}
            FieldProps={{
                size: 'small',
                style: { width: '120px' },
                options: Object.values(Currency),
                getOptionLabel: (option) => transEnum(option, inputCurrenciesPublicTranslations),
                disableClearable: true,
                inputProps: {
                    startAdornment: generateWarningAdornment(row.original, StandardPartQuoteHeader.Currency),
                },
            }}
        />
    ),
});

const columnValidUntil = columnHelper.date('row.validUntil', {
    label: () => t`Valid until`,
    id: 'validUntil',
    size: 120,
    cell: ({ row, sharedContext }) => (
        <FieldDateControlled
            key={row.original.index}
            control={sharedContext.control}
            name={`lines.${row.original.index}.row.validUntil`}
            displayErrorAsTooltip={true}
            FieldProps={{
                size: 'small',
                style: { width: '120px' },
                InputProps: {
                    startAdornment: generateWarningAdornment(row.original, StandardPartQuoteHeader.ValidUntil),
                },
            }}
        />
    ),
});

const columnNcnr = columnHelper.enum('row.ncnr', {
    label: () => t`NCNR`,
    id: 'ncnr',
    size: 120,
    align: 'center',
    getOptionLabel: (option) => (option ? t`Yes` : t`No`),
    cell: ({ row, sharedContext }) => (
        <FieldCheckboxControlled
            key={row.original.index}
            control={sharedContext.control}
            name={`lines.${row.original.index}.row.ncnr`}
            displayErrorAsTooltip={true}
            FieldProps={{ size: 'small' }}
        />
    ),
});

const SourcingDetails = ({ sourcingScenarioId }: { sourcingScenarioId: string | undefined }) => {
    const { data, isLoading } = useSourcingScenario(sourcingScenarioId);

    if (!isPresent(data) || isLoading) {
        return <Skeleton />;
    }

    return <>{data.name}</>;
};

// TODO: Inline the sourcing scenario into the table data
const columnSourcingScenario = columnHelper.text(() => null, {
    label: () => t`Sourcing scenario`,
    id: 'scenario',
    size: 120,
    align: 'left',
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ row }) => {
        if (row.original.type !== 'CustomPartLine') {
            return <></>;
        }

        return <SourcingDetails sourcingScenarioId={row.original.row.sourcingScenarioId} />;
    },
});

const columnRequiredQuantity = columnHelper.number('row.requiredQuantity', {
    label: () => t`Quantity`,
    id: 'quantity',
    size: 120,
    align: 'left',
    cell: ({ row, sharedContext }) => (
        <FieldNumericControlled
            key={row.original.index}
            control={sharedContext.control}
            name={`lines.${row.original.index}.row.requiredQuantity`}
            displayErrorAsTooltip={true}
            exclusiveMin={0}
            FieldProps={{
                size: 'small',
                InputProps: {
                    endAdornment: (
                        <InputAdornment position="end">
                            {transEnum(QuantityUnit.Pieces, quantityUnitTranslations)}
                        </InputAdornment>
                    ),
                    startAdornment: generateWarningAdornment(row.original, CustomPartQuoteHeader.RequiredQuantity),
                },
                style: { width: '120px' },
            }}
            required={true}
        />
    ),
});

const columnOneTimeCosts = columnHelper.number('row.oneTimeCosts', {
    label: () => t`One-time costs`,
    size: 120,
    id: 'oneTimeCosts',
    align: 'left',
    cell: ({ row, sharedContext }) => (
        <FieldNumericControlled
            key={row.original.index}
            control={sharedContext.control}
            name={`lines.${row.original.index}.row.oneTimeCosts`}
            displayErrorAsTooltip={true}
            exclusiveMin={0}
            FieldProps={{
                size: 'small',
                InputProps: {
                    endAdornment: <InputAdornment position="end">{row.original.row.currency}</InputAdornment>,
                    startAdornment: generateWarningAdornment(row.original, CustomPartQuoteHeader.OneTimeCost),
                },
                style: { width: '120px' },
            }}
        />
    ),
});

const columnLeadTimeInDays = columnHelper.number('row.leadTimeInDays', {
    label: () => t`Lead time`,
    id: 'leadTime',
    size: 120,
    align: 'left',
    cell: ({ row, sharedContext }) => (
        <FieldNumericControlled
            key={row.original.index}
            control={sharedContext.control}
            name={`lines.${row.original.index}.row.leadTimeInDays`}
            displayErrorAsTooltip={true}
            exclusiveMin={0}
            FieldProps={{
                size: 'small',
                InputProps: {
                    endAdornment: (
                        <InputAdornment position="end">
                            <Trans>Days</Trans>
                        </InputAdornment>
                    ),
                    startAdornment: generateWarningAdornment(row.original, CustomPartQuoteHeader.LeadTimeInDays),
                },
                style: { width: '100px' },
            }}
        />
    ),
});

const standardPardColumns = [
    columnErrorStatus,
    columnPart,
    columnAdditionalNotes,
    columnUnitPrice,
    columnStock,
    columnLeadTime,
    columnUnitPriceQuantity,
    columnSupplierPartNumber,
    columnPackaging,
    columnMinOrderQuantity,
    columnMinPackagingQuantity,
    columnCurrency,
    columnValidUntil,
    columnNcnr,
];

const customPartColumns = [
    columnErrorStatus,
    columnPart,
    columnSourcingScenario,
    columnAdditionalNotes,
    columnRequiredQuantity,
    columnUnitPrice,
    columnOneTimeCosts,
    columnLeadTimeInDays,
    columnSupplierPartNumber,
    columnCurrency,
    columnValidUntil,
];

export function useQuoteLineTableState() {
    const { control } = useFormContext<QuoteImporterFormState>();
    const { errors } = useFormState<QuoteImporterFormState>();
    const lines = useWatch({ control, name: 'lines' });

    const isStandardPartImport = lines.every((line) => line.type === 'StandardPartLine');
    const isCustomPartImport = lines.every((line) => line.type === 'CustomPartLine');

    const columns = React.useMemo(() => {
        if (isStandardPartImport) {
            return standardPardColumns;
        }
        if (isCustomPartImport) {
            return customPartColumns;
        }
        throwErrorUnlessProduction(new Error('Invalid quote line type'));
        return standardPardColumns; // Fallback to standard part columns
    }, [isStandardPartImport, isCustomPartImport]);

    const data: Array<QuoteLineTableData> = React.useMemo(() => {
        return lines.map((line) => {
            return {
                ...line,
                status: extractQuoteLineStatus(line, errors),
            };
        });
    }, [lines, errors]);

    return useTanStackTable({
        data,
        columns,
        enableSelection: {
            enabled: true,
            getRowId: (row) => String(row.index),
        },
        enableRowSelection: (row) => !row.original.status.includes('errors'),
        enableColumnOrdering: true,
        enableColumnHiding: true,
        initialState: {
            columnPinning: {
                left: [DEFAULT_SELECT_COLUMN_ID],
                right: ['status'],
            },
        },
        sharedContext: { control },
    });
}
