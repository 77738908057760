import { useNavigate } from '@luminovo/design-system';
import { AssemblyResponseDTO } from '@luminovo/http-client';
import { route } from '../../../utils/routes';
export const useChangeToPcbPageIfPcbOnlyAssemblyType = ({
    rfqId,
    assembly,
}: {
    rfqId?: string;
    assembly: AssemblyResponseDTO | undefined;
}) => {
    const navigate = useNavigate();
    if (assembly === undefined || !rfqId) {
        return;
    }

    if (assembly.type.type === 'PcbOnly') {
        navigate(
            route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb', {
                rfqId,
                assemblyId: assembly.id,
            }),
            { replace: true },
        );
    }
};
