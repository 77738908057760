import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from './useNavigate';

interface BackNavigationOptions {
    defaultPath: string;
}

interface UseBackNavigationResult {
    navigateBack: ({ defaultPath }: BackNavigationOptions) => Promise<void>;
    canGoBack: () => boolean;
    currentPath: string;
}

export function useBackNavigation(): UseBackNavigationResult {
    const navigate = useNavigate();
    const location = useLocation();

    const canGoBack = useCallback(() => {
        return window.history.length > 1;
    }, []);

    const navigateBack = useCallback(
        async ({ defaultPath }: BackNavigationOptions) => {
            if (canGoBack()) {
                await navigate(-1);
            } else {
                await navigate(`${defaultPath}`);
            }
        },
        [navigate, canGoBack],
    );

    return {
        navigateBack,
        canGoBack,
        currentPath: location.pathname,
    };
}
