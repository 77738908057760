import { t, Trans } from '@lingui/macro';
import { compareByString, isPresent, TransEnum } from '@luminovo/commons';
import { colorSystem, Flexbox, Tag, Text, Tooltip, useNavigate } from '@luminovo/design-system';
import { AssemblyDTO } from '@luminovo/http-client';
import { assemblyIndustryTranslations } from '@luminovo/manufacturing-core';
import { InfoRounded } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import React from 'react';
import {
    getSpecificAssemblyPath,
    useDescendants,
    useParentsIdentifiers,
} from '../../../../resources/assembly/assemblyHandler';
import { RiskAvatar } from '../BomOverallRisk';
export const TopLevelAssemblySidebar = ({
    rfqId,
    assemblyId,
    currentParentAssemblyId,
}: {
    rfqId: string;
    assemblyId: string;
    currentParentAssemblyId?: string | null;
}) => {
    const { data: parentsData } = useParentsIdentifiers(assemblyId);
    const { data: descendants } = useDescendants(assemblyId);
    const assembly = descendants?.data.parent;

    const subAssemblies = React.useMemo(() => {
        return descendants?.data.assemblies.filter((assembly) => assemblyId in assembly.parents) ?? [];
    }, [descendants?.data.assemblies, assemblyId]);

    const otherParents = React.useMemo(() => {
        const assemblyParents = Object.entries(assembly?.parents ?? {});
        if (parentsData && assemblyParents.length > 1) {
            const parents = parentsData.multi_parent_items
                .map((data) => {
                    // The parent is always the second last element in the array
                    return data[data.length - 2];
                })
                .filter(isPresent);

            return assemblyParents
                .map(([parentId, quantity]) => {
                    const parent = parents.find((parent) => parent.id === parentId);
                    if (parent && currentParentAssemblyId !== parent.id) return { ...parent, quantity };
                    return undefined;
                })
                .filter(isPresent)
                .sort((a, b) => compareByString(a.designator, b.designator));
        }
        return [];
    }, [assembly?.parents, parentsData, currentParentAssemblyId]);

    const sortedSubassemblies = React.useMemo(() => {
        return subAssemblies.sort((d1, d2) => compareByString(d1.designator, d2.designator));
    }, [subAssemblies]);

    if (!assembly || !parentsData) {
        return null;
    }

    return (
        <Box
            style={{
                background: colorSystem.neutral.white,
                height: '100%',
                boxSizing: 'border-box',
                padding: 20,
                gap: 20,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Container>
                <Text variant="h4">
                    <Trans>Assembly</Trans>
                </Text>
                <Flexbox gap={4}>
                    <Tag
                        label={<TransEnum text={assembly.industry} translations={assemblyIndustryTranslations} />}
                        color="neutral"
                        attention="low"
                    />
                </Flexbox>
                <AssemblyItem
                    tooltip={t`A group of design items that are assembled together to form a larger component or system, such as a printed circuit board assembly (PCBA) or a box build.`}
                    label={t`Subassemblies`}
                    count={assembly.subassemblies.items.length}
                />
            </Container>
            <Container>
                <Text variant="h4">
                    <Trans>Top level assemblies</Trans>
                </Text>
                {sortedSubassemblies.length === 0 && (
                    <Text>
                        <Trans>No subassemblies</Trans>
                    </Text>
                )}
                {sortedSubassemblies.map((subassembly) => {
                    return (
                        <SubAssembly rfqId={rfqId} key={subassembly.id} assembly={subassembly} parentId={assemblyId} />
                    );
                })}
            </Container>
            {otherParents.length > 0 && (
                <Container>
                    <Text variant="h4">
                        <Trans>Used in</Trans>
                    </Text>
                    {otherParents.map((parent) => {
                        return (
                            <ParentAssembly
                                key={parent.id}
                                rfqId={rfqId}
                                parentId={parent.id}
                                quantity={parent.quantity}
                                designator={parent.designator}
                            />
                        );
                    })}
                </Container>
            )}
        </Box>
    );
};

const ParentAssembly = ({
    rfqId,
    parentId,
    quantity,
    designator,
}: {
    rfqId: string;
    parentId: string;
    quantity: number;
    designator: string;
}) => {
    const navigate = useNavigate();

    const handleSubassemblyClick = React.useCallback(() => {
        navigate(getSpecificAssemblyPath({ rfqId, assemblyId: parentId, currentParentAssemblyId: undefined }));
    }, [navigate, parentId, rfqId]);

    return (
        <Flexbox key={parentId} justifyContent="space-between">
            <Flexbox gap={4} alignItems={'center'}>
                <LinkText
                    variant="h4"
                    color={colorSystem.neutral[6]}
                    id={parentId}
                    onClick={() => handleSubassemblyClick()}
                >
                    {designator}
                </LinkText>
            </Flexbox>
            <Text>{quantity}</Text>
        </Flexbox>
    );
};

const SubAssembly = ({ assembly, parentId, rfqId }: { assembly: AssemblyDTO; parentId: string; rfqId: string }) => {
    const navigate = useNavigate();

    let quantity = assembly.parents[parentId] ?? 1;

    const handleSubassemblyClick = React.useCallback(
        (data: AssemblyDTO) => {
            navigate(getSpecificAssemblyPath({ rfqId, assemblyId: data.id, currentParentAssemblyId: parentId }));
        },
        [navigate, parentId, rfqId],
    );

    return (
        <Flexbox justifyContent="space-between">
            <Flexbox gap={4} key={assembly.id} alignItems={'center'}>
                <RiskAvatar overallRisk={assembly.overall_risk} />
                <LinkText
                    variant="h4"
                    color={colorSystem.neutral[6]}
                    id={assembly.id}
                    onClick={() => handleSubassemblyClick(assembly)}
                >
                    {assembly.designator}
                </LinkText>
            </Flexbox>
            <Text>{quantity}</Text>
        </Flexbox>
    );
};

const AssemblyItem = ({ label, count, tooltip }: { label: string; count: number; tooltip: string }) => {
    return (
        <Flexbox justifyContent="space-between">
            <Flexbox gap={4} alignItems="center">
                <Tooltip title={tooltip} placement={'top'}>
                    <InfoRounded fontSize="inherit" style={{ color: colorSystem.neutral[5] }} />
                </Tooltip>
                <Text>{label}</Text>
            </Flexbox>
            <Text>{count}</Text>
        </Flexbox>
    );
};

const Container = styled('div')({
    display: 'flex',
    height: 'auto',
    background: colorSystem.neutral.white,
    flexDirection: 'column',
    border: `1px solid ${colorSystem.neutral[2]}`,
    padding: 12,
    borderRadius: 8,
    gap: 12,
    overflow: 'clip',
});

const LinkText = styled(Text)({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 130,
    '&:hover': {
        cursor: 'pointer',
    },
});
