import { colorSystem } from '@luminovo/design-system';
import { AxisTop } from '@visx/axis';
import { ScaleLinear } from 'd3-scale';
import { palette } from '../../../color/palette';

export function BarChartAxisTop({
    xScale,
    formatValue,
    data,
}: {
    xScale: ScaleLinear<number, number, never>;
    formatValue: (x: number) => string;
    data: Array<unknown>;
}): JSX.Element {
    return (
        <AxisTop
            scale={xScale}
            stroke={palette.gridLines}
            tickStroke={palette.gridLines}
            numTicks={clamp(2, 6, data.length)}
            tickFormat={(x) => formatValue(x.valueOf())}
            tickLabelProps={() => ({
                fill: colorSystem.neutral[8],
                fontSize: 14,
                fontFamily: "'INTER', sans-serif",
                textAnchor: 'middle',
                dy: -4,
            })}
        />
    );
}

function clamp(min: number, max: number, value: number): number {
    return Math.min(Math.max(value, min), max);
}
