/* eslint-disable camelcase */
import { plural } from '@lingui/macro';
import { compareByStringKey, isPresent, transEnum } from '@luminovo/commons';
import {
    colorSystem,
    createColumnHelper,
    DestructiveSecondaryIconButton,
    Flexbox,
    SupportedFilterFn,
    Tag,
    Text,
    Tooltip,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    PartLite,
    PartLiteTypes,
    QuoteRequestDTO,
    QuoteRequestLineItemAssignmentReason,
    QuoteRequestLineItemStatus,
} from '@luminovo/http-client';
import { formatSupplierDTO } from '@luminovo/sourcing-core';
import { Delete } from '@mui/icons-material';
import React from 'react';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import { NegotiationIpnLabel } from '../../../../Negotiations/components/NegotiationIpnLabel';
import { PartLiteLabel } from '../../../../Negotiations/components/PartLiteLabel';
import { QuoteRequestLineItemAssignmentReasonTag } from '../../../../Negotiations/components/QuoteRequestLineItemAssignmentReasonTag';
import { QuoteRequestLineItemStatusChip } from '../../../../Negotiations/components/QuoteRequestLineItemStatusChip';
import { useNegotiationQuoteRequests } from '../../../../Negotiations/hooks/negotiationHandlers';
import {
    quoteRequestLineItemAssignmentReasonTranslations,
    quoteRequestLineItemStatusTranslations,
} from '../../../../Negotiations/i18n';
import { formatPartLite } from '../../../../Negotiations/model/formatPartLite';

type RowItem = {
    type: 'row';
    partOptionId: number;
    part: PartLite;
    componentOrigin: string | null;
    description: string | null;
    subRows: SubRowItem[];
};

type SubRowItem = {
    type: 'subRow';
    id: number;
    parentRow: Pick<RowItem, 'part' | 'componentOrigin' | 'description'>;
    quoteRequest: QuoteRequestDTO;
    assignmentReason: QuoteRequestLineItemAssignmentReason;
    status: QuoteRequestLineItemStatus;
};

const columnHelper = createColumnHelper<RowItem | SubRowItem>();

const columns = [
    columnHelper.text((row) => (row.type === 'row' ? row.componentOrigin : row.parentRow.componentOrigin), {
        id: 'componentOrigin',
        label: () => 'IPN',
        size: 120,
        cell: ({ row }) => {
            switch (row.original.type) {
                case 'row':
                    return <NegotiationIpnLabel ipn={row.original.componentOrigin} />;
                case 'subRow':
                    return <></>;
            }
        },
    }),
    columnHelper.text((row) => (row.type === 'row' ? row.description : row.parentRow.description), {
        id: 'description',
        label: () => 'Description',
        size: 120,
    }),
    columnHelper.text((row) => (row.type === 'row' ? formatPartLite(row.part) : formatPartLite(row.parentRow.part)), {
        id: 'part',
        label: () => 'Part',
        size: 250,
        cell: ({ row }) => {
            switch (row.original.type) {
                case 'row':
                    return <PartLiteLabel variant="body-small" part={row.original.part} />;
                case 'subRow':
                    return <></>;
            }
        },
    }),
    columnHelper.enum(
        (row) =>
            row.type === 'row'
                ? row.subRows.length === 0
                    ? 'No suppliers'
                    : ''
                : formatSupplierDTO(row.quoteRequest.supplier),
        {
            id: 'suppliers',
            label: () => 'Suppliers',
            size: 220,
            getOptionLabel: (opt) => opt,
            cell: ({ row }) => {
                switch (row.original.type) {
                    case 'row':
                        if (row.original.subRows.length === 0) {
                            return <Text variant="inherit" color={colorSystem.yellow[8]}>{`No suppliers`}</Text>;
                        }

                        return plural(row.original.subRows.length, {
                            one: `${row.original.subRows.length} supplier`,
                            other: `${row.original.subRows.length} suppliers`,
                        });
                    case 'subRow':
                        if (row.original.status === QuoteRequestLineItemStatus.NotSent) {
                            return (
                                <Text variant="inherit">{formatSupplierDTO(row.original.quoteRequest.supplier)}</Text>
                            );
                        } else {
                            return (
                                <Text variant="inherit" color={colorSystem.neutral[6]}>
                                    {formatSupplierDTO(row.original.quoteRequest.supplier)}
                                </Text>
                            );
                        }
                }
            },
            quickFilters: [
                {
                    label: () => 'No suppliers',
                    value: { filterFn: SupportedFilterFn.equalsAny, value: ['No suppliers'] },
                    showCount: true,
                },
            ],
        },
    ),
    columnHelper.array((row) => (row.type === 'row' ? [] : [row.status]), {
        id: 'status',
        label: () => 'Status',
        size: 120,
        align: 'right',
        getOptionLabel: (status) => transEnum(status, quoteRequestLineItemStatusTranslations),
        cell: ({ row }) => {
            switch (row.original.type) {
                case 'row':
                    return <></>;
                case 'subRow':
                    return (
                        <Flexbox gap={4} justifyContent={'flex-end'}>
                            <Tag attention="low" color={'neutral'} label={`#${row.original.quoteRequest.number}`} />
                            <QuoteRequestLineItemStatusChip quoteRequestLineItemStatus={row.original.status} />
                        </Flexbox>
                    );
            }
        },
    }),
    columnHelper.array((row) => (row.type === 'row' ? [] : [row.assignmentReason]), {
        id: 'assignmentReason',
        label: () => 'Assignment reason',
        size: 150,
        getOptionLabel: (status) => transEnum(status, quoteRequestLineItemAssignmentReasonTranslations),
        cell: ({ row }) => {
            switch (row.original.type) {
                case 'row':
                    return <></>;
                case 'subRow':
                    return <QuoteRequestLineItemAssignmentReasonTag assignmentReason={row.original.assignmentReason} />;
            }
        },
    }),

    columnHelper.action({
        id: 'actions',
        size: 80,
        cell: function Cell({ row }) {
            const { original } = row;
            const { mutateAsync, isPending: isLoading } = useHttpMutation('POST /quote-request/line-item/delete', {
                snackbarMessage: 'Deleted quote request line items',
            });

            if (original.type !== 'subRow') {
                return null;
            }

            return (
                <Tooltip title={`Remove quote request line item`}>
                    <DestructiveSecondaryIconButton
                        disabled={original.status !== QuoteRequestLineItemStatus.NotSent || isLoading}
                        onClick={() => mutateAsync({ requestBody: { ids: [original.id] } })}
                        size="small"
                    >
                        <Delete fontSize="inherit" />
                    </DestructiveSecondaryIconButton>
                </Tooltip>
            );
        },
    }),
];

function useOffTheShelfPartOptionsTableData(
    negotiationId: number,
    negotiationLineItemIds: number[],
): Array<RowItem | SubRowItem> | undefined {
    const { data: quoteRequests } = useNegotiationQuoteRequests(negotiationId);
    const { data } = useHttpQuery('POST /negotiation/line-items/part-options-with-quote-request-line-items', {
        requestBody: {
            negotiation_line_item_ids: negotiationLineItemIds,
        },
    });

    return React.useMemo(() => {
        if (!isPresent(data) || !isPresent(quoteRequests)) {
            return undefined;
        }

        const filteredData = data.filter(
            (item) => item.part.kind !== PartLiteTypes.Custom && item.part.kind !== PartLiteTypes.CustomComponent,
        );

        return filteredData.map((item) => ({
            type: 'row',
            partOptionId: item.part_option_id,
            part: item.part,
            componentOrigin: item.component_origin,
            description: item.description?.split(';')[0] ?? null,
            subRows: item.quote_request_line_items
                .sort(compareByStringKey((lineItem) => lineItem.quote_request_id, { type: 'descending' }))
                .flatMap((lineItem) => {
                    const quoteRequest = quoteRequests?.find((qr) => qr.id === lineItem.quote_request_id);

                    if (!isPresent(quoteRequest)) {
                        return [];
                    }

                    return [
                        {
                            type: 'subRow',
                            id: lineItem.id,
                            quoteRequest,
                            parentRow: {
                                part: item.part,
                                componentOrigin: item.component_origin,
                                description: item.description?.split(';')[0] ?? null,
                            },
                            assignmentReason: lineItem.assignment_reason,
                            status: lineItem.status,
                        },
                    ];
                }),
        }));
    }, [data, quoteRequests]);
}

export function useOffTheShelfPartOptionsTable(negotiationId: number, negotiationLineItemIds: number[]) {
    const data = useOffTheShelfPartOptionsTableData(negotiationId, negotiationLineItemIds);

    const { table } = useTanStackTable({
        data,
        columns,
        filterFromLeafRows: true,
        enableSelection: {
            enabled: true,
            getRowId: (row) => (row.type === 'row' ? `${row.partOptionId}-${row.part.kind}-${row.part.id}` : ''),
        },
        getSubRows: (row) => (row.type === 'row' ? row.subRows : []),
    });

    return { table, isLoading: !isPresent(data) };
}
