import * as r from 'runtypes';
import { StepEnumRuntype } from './organizationSettingsBackendTypes';

const ColumnSettingsRuntype = r
    .Record({
        hidden: r.Boolean.nullable().optional(),
    })
    .optional();

export const AllColumnSettingsRuntype = r.Record({
    bid: ColumnSettingsRuntype,
    ipn: ColumnSettingsRuntype,
    cancellationWindow: ColumnSettingsRuntype,
    cancellationWindowUnit: ColumnSettingsRuntype,
    itemClass: ColumnSettingsRuntype,
    leadTimeDays: ColumnSettingsRuntype,
    moq: ColumnSettingsRuntype,
    mpq: ColumnSettingsRuntype,
    ncnr: ColumnSettingsRuntype,
    notes: ColumnSettingsRuntype,
    oneTimeCosts: ColumnSettingsRuntype,
    packaging: ColumnSettingsRuntype,
    potentialQuantity: ColumnSettingsRuntype,
    pricePer: ColumnSettingsRuntype,
    quantity: ColumnSettingsRuntype,
    selected: ColumnSettingsRuntype,
    stdFactoryLeadTime: ColumnSettingsRuntype,
    stdFactoryLeadTimeUnit: ColumnSettingsRuntype,
    stock: ColumnSettingsRuntype,
    supplierPartNumber: ColumnSettingsRuntype,
    totalPrice: ColumnSettingsRuntype,
    unitPrice: ColumnSettingsRuntype,
    validFrom: ColumnSettingsRuntype,
    validUntil: ColumnSettingsRuntype,
});

export interface SupplierPortalSettingsDTO extends r.Static<typeof SupplierPortalSettingsRuntype> {}
export const SupplierPortalSettingsRuntype = r.Record({
    default_days_before_offer_invalid: r.Number.nullable(),
    show_supplier_portal_link: r.Boolean,
    upload_additional_supplier_files: StepEnumRuntype,
    hours_until_frozen: r.Number.nullable().optional(),
    should_include_pcb_specification: r.Boolean.optional(),
    should_include_shipping_panel_specification: r.Boolean.optional(),
    quote_columns: AllColumnSettingsRuntype.optional(),
    custom_part_quote_columns: AllColumnSettingsRuntype.optional(),
});

export interface PatchSupplierPortalSettingsDTO extends r.Static<typeof PatchSupplierPortalSettingsRuntype> {}
export const PatchSupplierPortalSettingsRuntype = r.Record({
    default_days_before_offer_invalid: r.Number.nullable().optional(),
    show_supplier_portal_link: r.Boolean.optional(),
    upload_additional_supplier_files: StepEnumRuntype.optional(),
    hours_until_frozen: r.Number.nullable().optional(),
    should_include_pcb_specification: r.Boolean.optional(),
    should_include_shipping_panel_specification: r.Boolean.optional(),
    quote_columns: AllColumnSettingsRuntype.optional(),
    custom_part_quote_columns: AllColumnSettingsRuntype.optional(),
});
