import { t, Trans } from '@lingui/macro';
import { groupBy } from '@luminovo/commons';
import {
    ButtonGroup,
    ButtonGroupItem,
    colorSystem,
    Comparable,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Flexbox,
    SecondaryButton,
    Text,
    useNavigate,
} from '@luminovo/design-system';
import { RfqListItemDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import React from 'react';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { route } from '../../../utils/routes';
import { RfqOverviewMiniTable } from './RfqOverviewTable';
export type ViewMode = 'table' | 'rfqsByCreatedDate' | 'rfqsByStatus';

export interface ChartSpec<TKeys, TDatum> {
    id: ViewMode;
    title: JSX.Element;
    keys: TKeys[];
    groupBy: Array<{
        extractor: (data: RfqListItemDTO) => string;
        label: React.ReactNode;
        tooltip?: string;
    }>;
    map: (data: RfqListItemDTO) => TDatum;
    aggregate: (a: TDatum, b: TDatum) => TDatum;
    orderBy: Comparable<TDatum>;
    render: (props: { data: TDatum[]; keys: TKeys[]; onSelectDatum: (datum: TDatum) => void }) => JSX.Element | null;
}

function ChartContainer(props: React.PropsWithChildren<{}>) {
    return (
        <Box
            display="grid"
            style={{
                minWidth: 1000,
                placeItems: 'center',
                background: colorSystem.neutral[0],
                borderRadius: 8,
                padding: 20,
                border: `1px solid ${colorSystem.neutral[2]}`,
            }}
        >
            {props.children}
        </Box>
    );
}

export function ChartRfq<TKeys, TDatum extends { ids: string[] }>({
    chartSpec,
    data,
}: {
    chartSpec: ChartSpec<TKeys, TDatum>;
    data: RfqListItemDTO[];
}) {
    const navigate = useNavigate();
    const { setDialog, closeDialog } = useDialogContext();

    const Chart = chartSpec.render;

    const handleSelectItem = ({ ids }: { ids: string[] }) => {
        if (ids.length === 1) {
            return navigate(route('/rfqs/:rfqId/dashboard', { rfqId: ids[0] }));
        }
        if (ids.length > 1) {
            setDialog(
                <Dialog open={true} onClose={closeDialog} maxWidth="md">
                    <DialogTitle title={t`Select RfQs`} />
                    <DialogContent style={{ height: '40vh' }}>
                        <RfqOverviewMiniTable data={data.filter((rfq) => ids.includes(rfq.id))} />
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton onClick={closeDialog}>
                            <Trans>Cancel</Trans>
                        </SecondaryButton>
                    </DialogActions>
                </Dialog>,
            );
        }
    };

    const [selectedGroupingFunction, setSelectedGroupingFunction] = React.useState(chartSpec.groupBy[0]);

    const chartData = Object.values(groupBy(data, selectedGroupingFunction.extractor))
        .map((group) => {
            return group.map((item) => chartSpec.map(item)).reduce(chartSpec.aggregate);
        })
        .sort(chartSpec.orderBy);

    return (
        <ChartContainer>
            <Flexbox width="100%" alignItems="center" gap={8}>
                <Text variant="h4">{chartSpec.title}</Text>
                <ButtonGroup size="small">
                    {chartSpec.groupBy.map((g, i) => (
                        <ButtonGroupItem
                            key={i}
                            onClick={() => setSelectedGroupingFunction(g)}
                            selected={selectedGroupingFunction === g}
                        >
                            {g.label}
                        </ButtonGroupItem>
                    ))}
                </ButtonGroup>
            </Flexbox>
            <Chart keys={chartSpec.keys} data={chartData} onSelectDatum={handleSelectItem} />
        </ChartContainer>
    );
}
