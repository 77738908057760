import { Column } from 'ag-grid-community';
import { DataGridColumn, DataGridRow } from '../types';

export function getColumnValue<TRow extends DataGridRow, TValue>(row: TRow, col: Column): TValue | null | undefined {
    const columnDef = col.getColDef() as DataGridColumn<TRow, TValue>;
    // @ts-expect-error
    return columnDef.valueGetter({ data: row });
}

export function formatColumnValue<TRow extends DataGridRow, TValue>(row: TRow | undefined, col: Column): string {
    if (row === undefined) {
        return '-';
    }
    const value = getColumnValue<TRow, TValue>(row, col);
    return formatValue(value, col);
}

export function formatValue<TRow extends DataGridRow, TValue>(value: TValue, col: Column): string {
    const columnDef = col.getColDef() as DataGridColumn<TRow, TValue>;
    const formatter = columnDef.valueFormatter;
    if (!formatter) {
        return value === null || value === undefined ? '-' : String(value);
    }
    // @ts-expect-error
    return formatter({ value, column: col });
}
