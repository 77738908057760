import { convertDataForPcbAnalytics } from '@/resources/pcb/analytics/analytic';
import { analytics } from '@/utils/analytics';
import { Trans, t } from '@lingui/macro';
import { assertUnreachable, id, transEnum } from '@luminovo/commons';
import {
    Flexbox,
    Link,
    SecondaryButton,
    TertiaryIconButton,
    Text,
    Tooltip,
    colorSystem,
    usePersistedState,
} from '@luminovo/design-system';
import {
    CustomPartOfferResponse,
    CustomPartOfferResultType,
    PCBV2,
    PcbWorkflowStep,
    UserType,
} from '@luminovo/http-client';
import {
    Add,
    LocalShipping as LocalShippingIcon,
    OpenInNewRounded as OpenInNewRoundedIcon,
    Radar,
    UnfoldLessRounded,
    UnfoldMoreRounded,
} from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import {
    useCurrentUserDetailsContext,
    useIsCustomer,
    useUserType,
} from '../../../../components/contexts/CurrentUserDetailsContext';
import { pcbStepTranslations } from '../../../../resources/pcb/i18n';
import { route } from '../../../../utils/routes';
import { ViewContext } from '../../../Bom/components/ModuleTableData';
import { formatRouteBasedOnViewContext } from '../../utils/formatRoutebasedOnViewContext';
import { PCBRouteSuffix } from '../PcbSidebar/utils/pcbSidebarLinks';
import { OfferStateLoadingBox } from './components/OfferStateLoadingBox';
import { OfferErrorBox } from './components/OffersError';
import { PcbDetailedOffers } from './components/PcbDetailedOffers';
import { PcbSimpleOffers } from './components/PcbSimpleOffers';
import { checkIfDetailedOffersIsPending, usePcbOffersState } from './utils/usePcbOfferState';

function stepToPCBRouteSufix(step: PcbWorkflowStep): PCBRouteSuffix {
    switch (step) {
        case PcbWorkflowStep.FileManager:
            return 'files';
        case PcbWorkflowStep.DrillManager:
            return 'drills';
        case PcbWorkflowStep.Stackup:
            return 'stackup';
        case PcbWorkflowStep.Specification:
            return 'specification';
        default:
            assertUnreachable(step);
    }
}

const PcbOffers = ({
    offers,
    rfqId,
    pcbId,
    assemblyId,
    isRfqEditable,
    viewContext,
}: {
    offers: CustomPartOfferResponse;
    rfqId: string;
    assemblyId: string;
    pcbId: string;
    isRfqEditable: boolean;
    viewContext: ViewContext;
}) => {
    const isCustomer = useUserType() === UserType.Customer;
    const organizationName = useCurrentUserDetailsContext().organization.name;

    switch (offers.type) {
        case CustomPartOfferResultType.NoSourcingScenarios:
            if (isCustomer) {
                return (
                    <OfferErrorBox title={t`No order scenarios created`}>
                        <Text
                            variant="body-small"
                            style={{
                                textAlign: 'center',
                                width: '85%',
                            }}
                        >
                            <Trans>
                                We are unable to provide pricing information for this PCB at the moment, as no order
                                scenarios have been created
                            </Trans>
                        </Text>
                        {isRfqEditable && (
                            <SecondaryButton
                                size="medium"
                                startIcon={<Add />}
                                href={route('/rfqs/:rfqId/edit', { rfqId })}
                                style={{ marginBlockStart: '8px' }}
                            >
                                <Trans>Add order scenario</Trans>
                            </SecondaryButton>
                        )}
                    </OfferErrorBox>
                );
            } else {
                return (
                    <OfferErrorBox title={t`No sourcing scenarios created`}>
                        <Text
                            variant="body-small"
                            style={{
                                textAlign: 'center',
                                width: '85%',
                            }}
                        >
                            <Trans>
                                We are unable to provide pricing information for this PCB at the moment, as no sourcing
                                scenarios have been created
                            </Trans>
                        </Text>

                        <SecondaryButton
                            size="medium"
                            startIcon={<Add />}
                            href={route('/rfqs/:rfqId/sourcing/scenarios/new', { rfqId })}
                            style={{ marginBlockStart: '8px' }}
                        >
                            <Trans>Add sourcing scenario</Trans>
                        </SecondaryButton>
                    </OfferErrorBox>
                );
            }

        case CustomPartOfferResultType.NoSuppliersConfigured:
            if (isCustomer) {
                return (
                    <OfferErrorBox title={t`No suppliers configured`} Icon={LocalShippingIcon}>
                        <Text
                            variant="body-small"
                            style={{
                                textAlign: 'center',
                                width: '85%',
                            }}
                        >
                            <Trans>
                                Pricing information for this PCB is currently unavailable. This is due to the absence of
                                approved PCB suppliers as no PCB suppliers have been configured by {organizationName}.
                            </Trans>
                        </Text>
                    </OfferErrorBox>
                );
            }
            return (
                <OfferErrorBox title={t`No suppliers configured`} Icon={LocalShippingIcon}>
                    <Text
                        variant="body-small"
                        style={{
                            textAlign: 'center',
                            width: '85%',
                        }}
                    >
                        <Trans>
                            There are no approved PCB suppliers in any of the current RfQ sourcing scenarios. Please,
                            adjust your solution preferences.
                        </Trans>
                    </Text>
                    <SecondaryButton
                        size="small"
                        href={route('/rfqs/:rfqId/sourcing', { rfqId })}
                        style={{ marginBlockStart: '8px' }}
                    >
                        <Trans>Go to sourcing</Trans>
                    </SecondaryButton>
                </OfferErrorBox>
            );

        case CustomPartOfferResultType.NoDefinedQuantity:
            return (
                <OfferErrorBox title={t`No demand`} Icon={LocalShippingIcon}>
                    <Text
                        variant="body-small"
                        style={{
                            textAlign: 'center',
                            width: '85%',
                        }}
                    >
                        <Trans>
                            Pricing information for this PCB is currently unavailable. No demand is configured for this
                            PCB in any sourcing scenario.
                        </Trans>
                    </Text>
                </OfferErrorBox>
            );

        case CustomPartOfferResultType.AnalysisInProgress:
            return <OfferStateLoadingBox />;

        case CustomPartOfferResultType.NotApproved:
            return (
                <OfferErrorBox title={t`PCB is not saved`} Icon={LocalShippingIcon}>
                    <Text
                        variant="body-small"
                        style={{
                            textAlign: 'center',
                            width: '85%',
                        }}
                    >
                        <Trans>
                            Sourcing of PCB offers and capability check is only possible after saving all individual PCB
                            steps.
                        </Trans>
                    </Text>

                    <Flexbox flexDirection="column" gap={4} padding="4px" alignItems={'center'}>
                        {offers.missing_steps.map((step) => (
                            <Link
                                to={route(formatRouteBasedOnViewContext(stepToPCBRouteSufix(step), viewContext), {
                                    assemblyId,
                                    rfqId,
                                })}
                                key={step}
                            >
                                <Flexbox gap={4} alignItems={'center'}>
                                    <OpenInNewRoundedIcon fontSize="inherit" />
                                    <Text variant="body-small" color="inherit">
                                        {transEnum(step, pcbStepTranslations)}
                                    </Text>
                                </Flexbox>
                            </Link>
                        ))}
                    </Flexbox>
                </OfferErrorBox>
            );

        case CustomPartOfferResultType.Detailed:
            return <PcbDetailedOffers offers={offers} rfqId={rfqId} assemblyId={assemblyId} pcbId={pcbId} />;

        case CustomPartOfferResultType.Simple:
            return <PcbSimpleOffers offers={offers} rfqId={rfqId} assemblyId={assemblyId} pcbId={pcbId} />;
    }
};

export const PcbPriceRadar = ({
    pcb,
    rfqId,
    assemblyId,
    isRfqEditable,
    viewContext,
}: {
    pcb: PCBV2;
    rfqId: string;
    assemblyId: string;
    isRfqEditable: boolean;
    viewContext: ViewContext;
}) => {
    const [isRadarExpanded, setIsRadarExpanded] = usePersistedState<boolean>('isRadarExpanded', true);
    const { data: offers, isLoading: isLoadingOfferState } = usePcbOffersState({ pcb, assemblyId });
    const isCustomer = useIsCustomer();

    if (!offers) return null;

    const isDetailedOffersType = offers.status.type === 'Detailed';
    const isOneOfDetailedOffersPending = checkIfDetailedOffersIsPending(offers.status);
    const numberOfPendingOffers =
        offers.status.type === 'Detailed'
            ? Math.max(offers.status.data.filter((offer) => offer.status.type === 'Pending').length, 1)
            : 0;

    const isLoading = isLoadingOfferState || isOneOfDetailedOffersPending;
    const shouldRadarIconLightUp =
        isDetailedOffersType ||
        isLoadingOfferState ||
        (offers.status.type === 'Simple' && offers.status.data.type === 'OffersAvailable');

    const handleRadarExpand = () => {
        const payload = convertDataForPcbAnalytics({
            pcbId: pcb.id,
            rfqId,
            assemblyId,
        });
        analytics.track('expand_pcb_price_radar', payload);
        setIsRadarExpanded(!isRadarExpanded);
    };

    return (
        <Box
            style={{
                background: colorSystem.neutral[1],
                borderRadius: '8px',
                padding: '16px',
                width: '350px',
                transition: 'all 0.2s ease-in',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
            }}
            id={id('design/box_pcb_price_radar')}
        >
            <Flexbox justifyContent={'space-between'} flexDirection={'column'} gap={'8px'}>
                <Flexbox alignItems={'center'} gap={4}>
                    {isLoading ? (
                        <Tooltip title={t`Offer is pending`} placement="top">
                            <CircularProgress size={20} style={{ marginInlineEnd: '2px' }} />
                        </Tooltip>
                    ) : (
                        <Radar
                            style={{
                                color: shouldRadarIconLightUp ? colorSystem.primary[6] : colorSystem.neutral[6],
                                marginInlineStart: '-2px',
                            }}
                        />
                    )}
                    <Text variant="h3">{isCustomer ? t`PCB Radar` : t`PCB Price Radar`}</Text>
                    <TertiaryIconButton size="small" onClick={handleRadarExpand} style={{ marginInlineStart: 'auto' }}>
                        {isRadarExpanded ? <UnfoldLessRounded /> : <UnfoldMoreRounded />}
                    </TertiaryIconButton>
                </Flexbox>

                {isOneOfDetailedOffersPending && (
                    <Text variant="caption" style={{ color: colorSystem.neutral[6] }}>
                        <Trans>
                            {numberOfPendingOffers} suppliers may not be visible as we validate their API. Please wait
                            until the loading process is complete to view the entire list
                        </Trans>
                    </Text>
                )}
            </Flexbox>

            {isRadarExpanded && (
                <>
                    {isLoadingOfferState ? (
                        <OfferStateLoadingBox />
                    ) : (
                        <PcbOffers
                            offers={offers.status}
                            rfqId={rfqId}
                            assemblyId={assemblyId}
                            pcbId={pcb.id}
                            isRfqEditable={isRfqEditable}
                            viewContext={viewContext}
                        />
                    )}
                </>
            )}
        </Box>
    );
};
