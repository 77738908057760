import { assertUnreachable } from '@luminovo/commons';
import { ArrowBack } from '@mui/icons-material';
import { List, ListItem, ListItemText, styled } from '@mui/material';
import React from 'react';
import { useNavigate } from '../../hooks/useNavigate';
import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { TertiaryIconButton } from '../buttons';
import { NavListItem } from './NavListItem';
export type NavigationSidebarLinkHeader = { type: 'header'; label: React.ReactNode };
export type NavigationSidebarLinkItem = {
    type: 'link';
    href: string;
    label: string | JSX.Element;
    disabled?: boolean;
    hidden?: boolean;
};
type NavigationSidebarItem = NavigationSidebarLinkHeader | NavigationSidebarLinkItem;

export interface NavigationSidebarProps {
    title: string;
    contents: Array<NavigationSidebarItem>;
}

export function NavigationSidebar({ title, contents }: NavigationSidebarProps): JSX.Element {
    const navigate = useNavigate();

    const visibleContents = contents.filter((link) => {
        if (link.type === 'header') return true;
        return !link.hidden;
    });
    return (
        <StyledList>
            <ListItem>
                <Flexbox alignItems={'center'} gap={8}>
                    <TertiaryIconButton
                        size="small"
                        onClick={() => navigate(-1)}
                        style={{ padding: 0, marginRight: 4 }}
                    >
                        <ArrowBack fontSize="inherit" />
                    </TertiaryIconButton>

                    <ListItemText
                        style={{
                            paddingLeft: '0px',
                            color: colorSystem.neutral[8],
                            cursor: 'normal',
                        }}
                        primaryTypographyProps={{ variant: 'h2', noWrap: true }}
                        primary={title}
                    />
                </Flexbox>
            </ListItem>
            {visibleContents.map((link, i) => {
                return <NavigationSidebarLink key={i} link={link} />;
            })}
            {/*for some reason adding a margin/padding to the StyledList did not work and the last <NavigationSidebarLink /> is always hidden
              I suspect this has something to do with the Navigation component, but not sure how. The div below ensures all links will be visible
             */}
            <div style={{ height: '60px' }} />
        </StyledList>
    );
}

function NavigationSidebarLink({ link }: { link: NavigationSidebarItem }) {
    if (link.type === 'header') {
        return (
            <ListItem style={{ background: colorSystem.neutral[0] }}>
                <ListItemText primary={link.label} primaryTypographyProps={{ style: headerStyles }} />
            </ListItem>
        );
    }
    if (link.type === 'link') {
        return <NavListItem href={link.href} label={link.label} disabled={link.disabled} />;
    }
    assertUnreachable(link);
}

const StyledList = styled(List)({
    background: colorSystem.neutral[0],
    height: '100%',
    boxSizing: 'border-box',
});

const headerStyles: React.CSSProperties = {
    fontWeight: 600,
    color: colorSystem.neutral[8],
};
