import * as r from 'runtypes';
import { QuantityUnitDTORuntype } from '../backendTypes';
import { DemandResponseRuntype } from './demandBackendTypes';

const MonthAndYearRuntype = r.Record({
    month: r.Number,
    year: r.Number,
});
export type MonthAndYearDTO = r.Static<typeof MonthAndYearRuntype>;

const MonthlyDemandRuntype = r.Record({
    month_and_year: MonthAndYearRuntype,
    gross_quantity: QuantityUnitDTORuntype,
    net_quantity: QuantityUnitDTORuntype,
});
export type MonthlyDemandDTO = r.Static<typeof MonthlyDemandRuntype>;

const YearlyDemandRuntype = r.Record({
    year: r.Number,
    gross_quantity: QuantityUnitDTORuntype,
    net_quantity: QuantityUnitDTORuntype,
});
export type YearlyDemandDTO = r.Static<typeof YearlyDemandRuntype>;

const ParticipantDemandSummaryRuntype = r.Record({
    participant_number: r.String.nullable().optional(),
    participant_name: r.String,
    total_gross_demand: QuantityUnitDTORuntype,
    total_net_demand: QuantityUnitDTORuntype,
    monthly_demands: r.Array(MonthlyDemandRuntype),
    yearly_demands: r.Array(YearlyDemandRuntype),
});
export type ParticipantDemandSummaryDTO = r.Static<typeof ParticipantDemandSummaryRuntype>;

const DemandSummaryRuntype = r.Record({
    total_gross_demand: QuantityUnitDTORuntype,
    total_net_demand: QuantityUnitDTORuntype,
    supplier_demands: r.Array(ParticipantDemandSummaryRuntype),
    ship_to_site_demands: r.Array(ParticipantDemandSummaryRuntype),
    start_date: r.String,
    end_date: r.String,
});
export type DemandSummaryDTO = r.Static<typeof DemandSummaryRuntype>;

export const DemandSummaryResponseRuntype = r.Record({
    summary: DemandSummaryRuntype,
    demands: r.Array(DemandResponseRuntype),
});
export type DemandsSummaryResponseDTO = r.Static<typeof DemandSummaryResponseRuntype>;

const DemandSummaryForRuntype = r.Union(
    r.Record({
        type: r.Literal('Assembly'),
        assembly_id: r.String,
    }),
    r.Record({
        type: r.Literal('Component'),
        component_ipn_id: r.String,
    }),
);
export type DemandSummaryForDTO = r.Static<typeof DemandSummaryForRuntype>;

export const DemandSummaryDemandsRequestRuntype = r.Record({
    demand_ids: r.Array(r.String).optional(),
    summary_for: DemandSummaryForRuntype,
});
export type DemandSummaryDemandsRequestDTO = r.Static<typeof DemandSummaryDemandsRequestRuntype>;
