import { Trans, t } from '@lingui/macro';
import { CenteredLayout, NonIdealState } from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';

export function PcbLoadingError() {
    return (
        <CenteredLayout>
            <NonIdealState
                title={t`PCB failed to load`}
                description={t`Try refreshing the page. If this problem persists, please contact our support team.`}
                action={{
                    onClick: () => window.location.reload(),
                    children: <Trans>Refresh page</Trans>,
                }}
            />
        </CenteredLayout>
    );
}

export function PcbLoadingProgress() {
    return (
        <CenteredLayout>
            <CircularProgress />
        </CenteredLayout>
    );
}
