import { t } from '@lingui/macro';
import { useNavigate } from '@luminovo/design-system';
import { ViewContext } from '../modules/Bom/components/ModuleTableData';
import { useHttpMutation } from '../resources/mutation/useHttpMutation';
import { route } from '../utils/routes';
import { useDialogContext } from './contexts/ModalContext';
import ConfirmationDialogBox from './dialogBox/ConfirmationDialogBox';

export function useDeleteBomDesignItems({
    rfqId,
    assemblyId,
    currentParentAssemblyId,
    viewContext,
}: {
    rfqId: string;
    assemblyId: string;
    viewContext: ViewContext;
    currentParentAssemblyId: string | null | undefined;
}) {
    const { setDialog, closeDialog } = useDialogContext();
    const navigate = useNavigate();

    const { mutateAsync } = useHttpMutation('DELETE /assemblies/:id/bom-design-items', {
        snackbarMessage: t`BOM items deleted`,
        onSuccess: () => {
            const redirectRoute =
                viewContext.type === 'WithinRfQ'
                    ? route(
                          '/rfqs/:rfqId/bom/assembly/:assemblyId',
                          { rfqId, assemblyId },
                          { currentParentAssemblyId, tab: null, monitoring: null },
                      )
                    : route(
                          '/assemblies/:assemblyId/dashboard',
                          { assemblyId },
                          { rfqId, tab: undefined, isMonitoringOpen: undefined },
                      );
            navigate(redirectRoute, { replace: true });
        },
    });

    return {
        openDialog: () => {
            setDialog(
                <ConfirmationDialogBox
                    text={t`Do you want to delete all BOM Items of this assembly? Sub-assemblies or the PCB will not be affected by this action.`}
                    closeIconIsShown={true}
                    onConfirm={() => mutateAsync({ pathParams: { id: assemblyId } })}
                    title={t`Delete all BOM items`}
                    isDialogOpen={true}
                    onReject={closeDialog}
                />,
            );
        },
    };
}
