import { isPresent } from '@luminovo/commons';
import { SecondaryIconButton } from '@luminovo/design-system';
import { Close } from '@mui/icons-material';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
const ModalContext = React.createContext<
    | {
          dialog: JSX.Element | null;
          setDialog: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
          drawer: JSX.Element | null;
          setDrawer: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
      }
    | undefined
>(undefined);

export function useDialogContext() {
    const modalContext = React.useContext(ModalContext);
    if (!modalContext) {
        throw new Error("ModalContext is not present, make sure you've setup the ModalContext.Provider correctly.");
    }

    const { setDialog, dialog } = modalContext;
    return { setDialog, closeDialog: () => setDialog(null), isOpen: isPresent(dialog) };
}

export function useDrawerContext() {
    const modalContext = React.useContext(ModalContext);
    if (!modalContext) {
        throw new Error("ModalContext is not present, make sure you've setup the ModalContext.Provider correctly.");
    }

    const { setDrawer, drawer } = modalContext;
    return { setDrawer, closeDrawer: () => setDrawer(null), isOpen: isPresent(drawer) };
}

export const CloseDrawerButton: React.FunctionComponent<{ disableMargin?: boolean }> = ({
    disableMargin,
}): JSX.Element => {
    const { closeDrawer } = useDrawerContext();

    return (
        <SecondaryIconButton
            size="small"
            onClick={() => closeDrawer()}
            style={{ margin: disableMargin ? undefined : '12px 24px 12px 16px' }}
        >
            <Close fontSize="inherit" />
        </SecondaryIconButton>
    );
};

export function ModalContextProvider({ children }: React.PropsWithChildren<{}>) {
    const [dialog, setDialog] = React.useState<JSX.Element | null>(null);
    const [drawer, setDrawer] = React.useState<JSX.Element | null>(null);

    const { pathname } = useLocation();

    React.useEffect(() => {
        setDialog(null);
        setDrawer(null);
    }, [pathname]);

    return (
        <ModalContext.Provider value={{ dialog, setDialog, drawer, setDrawer }}>
            <>
                {dialog}
                {drawer}
                {children}
            </>
        </ModalContext.Provider>
    );
}
