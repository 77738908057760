import { transEnum } from '@luminovo/commons';
import { PreferenceStatusEnum } from '@luminovo/http-client';
import {
    PreferredTag,
    PreferredTagEnum,
    preferredTagTooltipTranslations,
    preferredTagTranslations,
} from '@luminovo/sourcing-core';

const buildPreferences = ({
    manufacturerPreferenceStatus,
    isPreferred,
}: {
    manufacturerPreferenceStatus?: PreferenceStatusEnum | null;
    isPreferred?: boolean;
}) => {
    const arr = [];
    if (manufacturerPreferenceStatus === PreferenceStatusEnum.Preferred) {
        arr.push(PreferredTagEnum.Manufacturer);
    }
    if (isPreferred) {
        arr.push(PreferredTagEnum.Ipn);
    }
    return arr;
};

export const PreferredTags = ({
    manufacturerPreferenceStatus,
    isPreferred,
}: {
    manufacturerPreferenceStatus?: PreferenceStatusEnum | null;
    isPreferred?: boolean;
}) => {
    const preferences = buildPreferences({
        manufacturerPreferenceStatus,
        isPreferred,
    });
    if (preferences.length > 1) {
        const tooltip = preferences
            .map((preference) => transEnum(preference, preferredTagTooltipTranslations))
            .join(', ');
        return <PreferredTag label={String(preferences.length)} tooltip={tooltip} />;
    }
    if (preferences.length === 1) {
        return (
            <PreferredTag
                label={transEnum(preferences[0], preferredTagTranslations)}
                tooltip={transEnum(preferences[0], preferredTagTooltipTranslations)}
            />
        );
    }
    return null;
};
