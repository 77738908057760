import { Trans, t } from '@lingui/macro';
import { useHasPermission } from '@luminovo/auth';
import { Currency, formatDays, isPresent, transEnum } from '@luminovo/commons';
import {
    BackButton,
    CenteredLayout,
    DestructivePrimaryButton,
    Dialog,
    DialogContent,
    DialogTitle,
    Flexbox,
    Message,
    SecondaryButton,
    StatusChip,
    Tab,
    Tabs,
    Tag,
    Text,
    Tooltip,
    colorSystem,
    useNavigate,
} from '@luminovo/design-system';
import { SupplierAndStockLocationDTO, SupplierPartType, SupplierTag } from '@luminovo/http-client';
import {
    formatRegionAsName,
    formatSupplierAndStockLocationDTO,
    hasSupplierTag,
    supplierOriginTranslations,
    supplierPartTypeTranslations,
    supplierTypeTranslations,
} from '@luminovo/sourcing-core';
import { DeleteRounded, Edit } from '@mui/icons-material';
import { Box, CircularProgress, DialogActions } from '@mui/material';
import React from 'react';
import { PageLayout } from '../../components/PageLayout';
import { useDialogContext } from '../../components/contexts/ModalContext';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { useGlobalCurrency } from '../../resources/organizationSettings/currencySettingsHandler';
import {
    useGlobalApprovedSupplierAndStockLocations,
    useGlobalPreferredSupplierAndStockLocations,
    useSupplierAndStockLocation,
} from '../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { UrlParams, route } from '../../utils/routes';
import { ApprovedVendorListTab } from './components/ApprovedVendorListTab';
import { SupplierContactsTable } from './components/SupplierContactsTable';
import { useDialogEditSupplierAndStockLocation } from './components/SupplierDialogs/EditSupplierDialog';
import { SupplierLineCardTab } from './components/SupplierLineCardTab';
import { SupplierTermsTable } from './components/SupplierTermsTable';
function ButtonEditSupplier({ supplierAndStockLocation }: { supplierAndStockLocation: SupplierAndStockLocationDTO }) {
    const { openDialog } = useDialogEditSupplierAndStockLocation({ supplierAndStockLocation });
    const hasCreateSupplierPermission = useHasPermission(['create:supplier']);

    if (!hasCreateSupplierPermission) {
        return null;
    }
    return (
        <Tooltip title={t`Edit supplier`}>
            <SecondaryButton size="small" onClick={() => openDialog()} startIcon={<Edit />}>
                <Trans>Edit</Trans>
            </SecondaryButton>
        </Tooltip>
    );
}

const DetailsLabel: React.FunctionComponent<{
    label: string;
}> = ({ label, children }) => {
    return (
        <Flexbox flexDirection={'column'} gap={'4px'}>
            <Text variant="h4" color={colorSystem.neutral[6]} style={{ whiteSpace: 'nowrap' }}>
                {label}
            </Text>
            {children}
        </Flexbox>
    );
};

const SupplierInformation: React.FunctionComponent<{
    supplierAndStockLocation: SupplierAndStockLocationDTO;
}> = ({ supplierAndStockLocation }) => {
    const { data: preferredSuppliersAndStockLocationsDTOs = [] } = useGlobalPreferredSupplierAndStockLocations();
    const { data: approvedSuppliersAndStockLocationsDTO = [] } = useGlobalApprovedSupplierAndStockLocations();
    const { preferredCurrency } = useGlobalCurrency();

    const isPreferred = preferredSuppliersAndStockLocationsDTOs.some((pS) => pS.id === supplierAndStockLocation.id);
    const isApproved = approvedSuppliersAndStockLocationsDTO.some((aS) => aS.id === supplierAndStockLocation.id);

    return (
        <Flexbox flexDirection={'column'} gap={24}>
            <Flexbox flexDirection={'column'} gap={4}>
                <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                    <Text variant={'h2'} color={colorSystem.neutral[8]}>
                        {formatSupplierAndStockLocationDTO(supplierAndStockLocation)}
                    </Text>
                    {hasSupplierTag(supplierAndStockLocation, SupplierTag.QuotePartner) && (
                        <Tag color={'teal'} attention={'low'} label={t`Quote partner`} />
                    )}
                </Flexbox>
                <Text variant="body" color={colorSystem.neutral[6]}>
                    {supplierAndStockLocation.supplier_number}
                </Text>
                <Flexbox gap={8} alignItems={'center'} flexWrap={'wrap'}></Flexbox>
                {supplierAndStockLocation.supplier.alternative_names.length > 0 && (
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                            <Trans>Alternative names</Trans>
                        </Text>
                        <Text variant="body-small" color={colorSystem.neutral[7]}>
                            {supplierAndStockLocation.supplier.alternative_names.join(', ')}
                        </Text>
                    </Flexbox>
                )}
            </Flexbox>

            <Flexbox flexDirection={'column'} gap={12}>
                <Text variant="h4">
                    <Trans>General information</Trans>
                </Text>

                <DetailsLabel label={t`Supplier type`}>
                    <Text variant="body" color={colorSystem.neutral[7]}>
                        {transEnum(supplierAndStockLocation.supplier.supplier_type, supplierTypeTranslations)}
                    </Text>
                </DetailsLabel>
                <DetailsLabel label={t`Part type`}>
                    <Tag
                        color={'neutral'}
                        attention={'low'}
                        label={transEnum(
                            supplierAndStockLocation.supplier.supplier_part_type,
                            supplierPartTypeTranslations,
                        )}
                    />
                </DetailsLabel>
                <DetailsLabel label={t`Origin`}>
                    <Tag
                        color={'neutral'}
                        attention={'low'}
                        label={transEnum(supplierAndStockLocation.supplier.origin, supplierOriginTranslations)}
                    />
                </DetailsLabel>
                <DetailsLabel label={t`Default currency`}>
                    <Tag
                        color="neutral"
                        attention="low"
                        label={Currency[supplierAndStockLocation.supplier.default_currency ?? preferredCurrency]}
                    />
                </DetailsLabel>
                {[SupplierPartType.Custom, SupplierPartType.Pcb].includes(
                    supplierAndStockLocation.supplier.supplier_part_type,
                ) && (
                    <DetailsLabel label={t`Shipping time`}>
                        {supplierAndStockLocation.shipping_time_in_days
                            ? formatDays(supplierAndStockLocation.shipping_time_in_days)
                            : '-'}
                    </DetailsLabel>
                )}
            </Flexbox>

            <Flexbox flexDirection={'column'} gap={12}>
                <Text variant="h4">
                    <Trans>Stock locations</Trans>
                </Text>
                <Flexbox justifyContent={'space-between'} gap={24}>
                    <Text variant="body" color={colorSystem.neutral[7]} showEllipsis>
                        {formatRegionAsName(supplierAndStockLocation.stock_location)}
                    </Text>
                    {isPreferred && <StatusChip color={'primary'} label={t`Preferred`} />}
                    {isApproved && <StatusChip color={'green'} label={t`Approved`} />}
                    {!isPreferred && !isApproved && <StatusChip color={'neutral'} label={t`Not approved`} />}
                </Flexbox>
            </Flexbox>
        </Flexbox>
    );
};

function ButtonDeleteSupplier({ supplierAndStockLocation }: { supplierAndStockLocation: SupplierAndStockLocationDTO }) {
    const { openDialog } = useDeleteSupplierDialog();
    const hasPermission = useHasPermission(['delete:supplier']);

    if (!hasPermission || !hasSupplierTag(supplierAndStockLocation, SupplierTag.ManuallyAdded)) {
        return <></>;
    }

    return (
        <SecondaryButton
            size={'small'}
            startIcon={<DeleteRounded />}
            onClick={() => openDialog(supplierAndStockLocation)}
        >
            {t`Delete`}
        </SecondaryButton>
    );
}

function useDeleteSupplierDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: (supplierAndStockLocation: SupplierAndStockLocationDTO) => {
            setDialog(
                <Dialog
                    open={true}
                    maxWidth={'xs'}
                    fullWidth={true}
                    transitionDuration={0}
                    onClose={() => closeDialog()}
                >
                    <DialogTitle title={t`Delete supplier`} handleClose={() => closeDialog()} />
                    <DialogContent>
                        <Flexbox gap={20} flexDirection={'column'}>
                            <Text>
                                <Trans>
                                    By deleting this manually created supplier, you will remove it from your supplier
                                    database and loose any information about the line card and contacts.
                                </Trans>
                            </Text>
                            <Message
                                variant={'yellow'}
                                attention={'low'}
                                size={'small'}
                                title={''}
                                message={t`Please note that this action is irreversible`}
                            />
                            <Text>
                                <Trans>Do you want to proceed?</Trans>
                            </Text>
                        </Flexbox>
                    </DialogContent>
                    <DialogActions>
                        <Flexbox justifyContent={'flex-end'} gap={10}>
                            <SecondaryButton onClick={() => closeDialog()}>{t`Cancel`}</SecondaryButton>
                            <InnerDeleteButton supplierAndStockLocation={supplierAndStockLocation} />
                        </Flexbox>
                    </DialogActions>
                </Dialog>,
            );
        },
    };
}

const InnerDeleteButton: React.FunctionComponent<{
    supplierAndStockLocation: SupplierAndStockLocationDTO;
}> = ({ supplierAndStockLocation }) => {
    const navigate = useNavigate();
    const { mutateAsync, isPending: isLoading } = useHttpMutation('DELETE /suppliers/:id', {
        snackbarMessage: t`Supplier deleted`,
        onSuccess: () => navigate('/supplier'),
    });

    return (
        <DestructivePrimaryButton
            isLoading={isLoading}
            onClick={() => mutateAsync({ pathParams: { id: supplierAndStockLocation.supplier.id } })}
        >{t`Delete supplier`}</DestructivePrimaryButton>
    );
};

type TabValue = 'contacts' | 'terms' | 'lineCard' | 'approvedVendorList' | 'demand';

export function SupplierDetailsPage(props: UrlParams<'/supplier/:supplierAndStockLocationId'>) {
    const [tabValue, setTabValue] = React.useState<TabValue>((props.queryParams.tab as TabValue) ?? 'contacts');
    const { data: supplierAndStockLocation } = useSupplierAndStockLocation(props.pathParams.supplierAndStockLocationId);

    if (!isPresent(supplierAndStockLocation)) {
        return (
            <PageLayout style={{ background: colorSystem.neutral[1] }} layout="full-width">
                <CenteredLayout height={'50vh'}>
                    <CircularProgress />
                </CenteredLayout>
            </PageLayout>
        );
    }
    return (
        <PageLayout layout={'fragment'} removeHubspotPaddingFix bgcolor={colorSystem.neutral[1]}>
            <Box display="grid" gridTemplateColumns="auto 1fr" height={'100%'}>
                <Flexbox
                    flexDirection={'column'}
                    gap={24}
                    bgcolor={colorSystem.neutral[0]}
                    paddingX={'24px'}
                    paddingY={'24px'}
                    width={'400px'}
                    overflow={'auto'}
                >
                    <Flexbox gap={'8px'} alignItems={'center'}>
                        <BackButton href={route('/supplier')} variant="button" size={'small'} />
                        <Flexbox flex={1} />
                        <ButtonDeleteSupplier supplierAndStockLocation={supplierAndStockLocation} />
                        <ButtonEditSupplier supplierAndStockLocation={supplierAndStockLocation} />
                    </Flexbox>

                    <SupplierInformation supplierAndStockLocation={supplierAndStockLocation} />
                </Flexbox>

                <Flexbox
                    flexDirection={'column'}
                    gap={'20px'}
                    padding="20px"
                    borderRadius="8px"
                    margin={'24px'}
                    style={{ background: colorSystem.neutral.white }}
                    flexGrow={1}
                >
                    <Tabs value={tabValue} size="large">
                        <Tab
                            value={'contacts'}
                            label={<Trans>Contacts</Trans>}
                            onClick={() => setTabValue('contacts')}
                        />
                        <Tab value={'terms'} label={<Trans>Terms</Trans>} onClick={() => setTabValue('terms')} />
                        {hasSupplierTag(supplierAndStockLocation, SupplierTag.OffTheShelfPartSupplier) && (
                            <Tab
                                value={'lineCard'}
                                label={<Trans>Line card</Trans>}
                                onClick={() => setTabValue('lineCard')}
                            />
                        )}
                        {hasSupplierTag(supplierAndStockLocation, SupplierTag.OffTheShelfPartSupplier) && (
                            <Tab
                                value={'approvedVendorList'}
                                label={<Trans>Approved vendor list</Trans>}
                                onClick={() => setTabValue('approvedVendorList')}
                            />
                        )}
                    </Tabs>
                    {tabValue === 'contacts' && (
                        <Box height={'100%'}>
                            <SupplierContactsTable supplierId={supplierAndStockLocation.supplier.id} />
                        </Box>
                    )}
                    {tabValue === 'lineCard' && (
                        <SupplierLineCardTab supplierAndStockLocation={supplierAndStockLocation} />
                    )}

                    {tabValue === 'approvedVendorList' && (
                        <ApprovedVendorListTab supplierAndStockLocation={supplierAndStockLocation} />
                    )}
                    {tabValue === 'terms' && (
                        <Box height={'100%'}>
                            <SupplierTermsTable supplierId={supplierAndStockLocation.supplier.id} />
                        </Box>
                    )}
                </Flexbox>
            </Box>
        </PageLayout>
    );
}
