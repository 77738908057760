import { t } from '@lingui/macro';
import { ColumnBuilder, DataGrid, DataGridContextProvider, themeTanstackTable } from '@luminovo/data-grid';
import { FieldSelect, Text, colorSystem } from '@luminovo/design-system';
import { CheckCircle } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Row, Table } from '../../../../types';
import { useUniversalImporter } from '../../context';

const columns = new ColumnBuilder<Row>();

export function TableMapColumns({ table }: { table: Table }): JSX.Element {
    const { state } = useUniversalImporter();
    const { config } = state;

    const columnDefs = Object.keys(table[0]).map((key, colIndex) => {
        const selected = config.fields.find((option) => option.columnIndices.includes(colIndex)) ?? null;
        const label = selected ? (selected.label ?? selected.id) : `Column #${colIndex + 1}`;

        return columns.text({
            headerName: label,
            valueGetter: ({ data }) => data?.[colIndex] ?? '',
            headerComponent: () => <Header colIndex={colIndex} />,
            minWidth: 250,
        });
    });

    return (
        <DataGridContextProvider initialData={table}>
            <DataGrid
                editable={false}
                columnDefs={columnDefs}
                theme={themeTanstackTable.withParams({
                    headerVerticalPaddingScale: 1.5,
                })}
            />
        </DataGridContextProvider>
    );
}

function Header({ colIndex }: { colIndex: number }) {
    const { dispatch, state } = useUniversalImporter();
    const { config } = state;
    const className = (colIndex: number) => `field-select-${colIndex}`;
    const selected = config.fields.find((option) => option.columnIndices.includes(colIndex)) ?? null;
    return (
        <FieldSelect
            className={className(colIndex)}
            size="small"
            openOnFocus
            fullWidth
            getOptionLabel={(field) => field.label ?? field.id}
            placeholder={t`Click to map column`}
            options={Array.from(config.fields)}
            renderOption={(option) => {
                const isOptionLinkedToAnotherColumn =
                    config.fields
                        .filter((field) => field.id === option.id)
                        .filter((field) => field.columnIndices.length > 0).length > 0;
                return (
                    <Box width={'100%'} display={'grid'} gridTemplateColumns={'1fr auto'}>
                        <Text variant="body-small">{option?.label}</Text>
                        <CheckCircle
                            style={{
                                color: isOptionLinkedToAnotherColumn ? colorSystem.green[6] : colorSystem.neutral[5],
                                height: 16,
                                // align self to the right
                                justifySelf: 'end',
                            }}
                        />
                    </Box>
                );
            }}
            value={selected}
            onChange={(value) => {
                if (value) {
                    dispatch({ type: 'linkColumn', column: colIndex, field: value.id });
                    // hacky way to focus the next field select
                    // we need to wait for the popup to close.
                    // Even though its hacky, we don't really break anything in case the timer is too short.
                    setTimeout(() => {
                        const element = document.querySelector(
                            `.${className(colIndex + 1)} .MuiAutocomplete-popupIndicator`,
                        );
                        if (element && element instanceof HTMLElement) {
                            element.click();
                        }
                    }, 200);
                } else if (value === null) {
                    dispatch({ type: 'unlinkColumn', column: colIndex });
                }
            }}
        />
    );
}
