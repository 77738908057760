/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { id, uniqBy } from '@luminovo/commons';
import { Flexbox, Message, Tab, Tabs } from '@luminovo/design-system';
import {
    AssemblyIndustry,
    CustomComponentFull,
    CustomFullPart,
    CustomOptionDTO,
    CustomPartType,
    DesignItemResponseDTO,
    FullPart,
    PartSpecificationTypes,
    RfqContext,
    isCustomComponentFull,
    isCustomFullPart,
} from '@luminovo/http-client';
import React, { useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { hasPartsWithCpns } from '../../../../components/partColumns/hasPartsWithCpns';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { ViewContext } from '../../../Bom/components/ModuleTableData';
import { BomItemFormState } from '../BomItemFormState';
import { DesignItemParametricSearchErpData } from '../ErpDataSearch/DesignItemParametricSearchErpData';
import { AddCustomPartTab } from './AddCustomPart/AddCustomPartTab';
import { CustomPartOptionsTable } from './CustomPartOptionsTable';
import { MainContainer } from './MainContainer';
import { SeparateContainer } from './SeparateContainer';

export function CustomSpecificationForm({
    isEditable: isRfqEditable,
    submitForm,
    individualDesignItems,
    viewContext,
    bomItem,
    assemblyId,
    assemblyIndustry,
}: {
    isEditable: boolean;
    submitForm: () => void;
    individualDesignItems: DesignItemResponseDTO[];
    viewContext: ViewContext;
    assemblyId: string;
    bomItem: BomItem;
    assemblyIndustry?: AssemblyIndustry;
}) {
    const [selectedTab, setSelectedTab] = useState<'AddCustomPart' | 'search'>('AddCustomPart');

    const rfqId = viewContext.rfqId;
    const rfqContext: RfqContext = rfqId ? { type: 'WithinRfQ', rfq_id: rfqId } : { type: 'OutsideRfQ' };

    const { control, setValue, formState } = useFormContext<BomItemFormState>();
    const isSubmitting = formState.isSubmitting;
    const { field } = useController({
        control,
        name: 'customPart.data',
        defaultValue: [],
    });

    const customPartSpecs: CustomOptionDTO[] = field.value;

    const fullParts: FullPart[] = bomItem.parts.map((data) => data.part);
    const customParts = fullParts.filter(isCustomFullPart);
    const customComponents = fullParts.filter(isCustomComponentFull);

    const hasPartOptionsWithCpns = hasPartsWithCpns({
        parts: [...customParts, ...customComponents],
    });

    const { customPartsMap, partType, customComponentsMap } = useMemo((): {
        customPartsMap: Map<string, CustomFullPart>;
        partType: CustomPartType | undefined;
        customComponentsMap: Map<string, CustomComponentFull>;
    } => {
        const customPartsMap = new Map(customParts.map((x): [string, CustomFullPart] => [x.id, x]));
        const partType = customParts[0]?.type;
        const customComponentsMap = new Map(customComponents.map((x): [string, CustomComponentFull] => [x.id, x]));

        return { customPartsMap, partType, customComponentsMap };
    }, [customParts, customComponents]);

    const existingPartIds = customPartSpecs.map((p) => p.part.data);

    const setCustomPartSpecs = React.useCallback(
        (fn: (existingParts: CustomOptionDTO[]) => CustomOptionDTO[]) => {
            setValue(
                'customPart.data',
                uniqBy(fn(customPartSpecs), (p) => p.part),
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
            submitForm();
        },
        [customPartSpecs, submitForm, setValue],
    );

    const handleRemovePartOption = React.useCallback(
        (partId: string) => {
            setCustomPartSpecs((parts) => {
                return parts.filter((p) => p.part.data !== partId);
            });
        },
        [setCustomPartSpecs],
    );

    const handleUpdatePartOption = React.useCallback(
        (newPart: CustomOptionDTO) => {
            setCustomPartSpecs((parts) =>
                parts.map((p) => {
                    if (p.part.data === newPart.part.data) {
                        return newPart;
                    }
                    return p;
                }),
            );
        },
        [setCustomPartSpecs],
    );

    const handleAppendPartOption = React.useCallback(
        (newPart: CustomOptionDTO) => {
            setCustomPartSpecs((parts) => parts.concat(newPart));
        },
        [setCustomPartSpecs],
    );

    const form = isRfqEditable && (
        <SeparateContainer>
            <Flexbox flexDirection="column" gap="12px">
                <Tabs
                    value={selectedTab}
                    onChange={(_, newValue) => {
                        setSelectedTab(newValue);
                    }}
                    size="large"
                >
                    <Tab value="AddCustomPart" label={t`Add custom part`} />
                    <Tab value={'search'} label={t`Search`} id={id('design/button_search')} />
                </Tabs>
                {selectedTab === 'AddCustomPart' && (
                    <AddCustomPartTab
                        individualDesignItems={individualDesignItems}
                        handleAppendPartOption={handleAppendPartOption}
                        selectedPartType={partType}
                    />
                )}
                {selectedTab === 'search' && rfqId && (
                    <DesignItemParametricSearchErpData
                        rfqContext={rfqContext}
                        onRemovePart={handleRemovePartOption}
                        existingPartIds={existingPartIds}
                        rfqId={rfqId}
                        bomItem={bomItem}
                        assemblyId={assemblyId}
                        assemblyIndustry={assemblyIndustry}
                        displayOtsFilters={false}
                        partSpecificationContext={{
                            partSpecificationType: PartSpecificationTypes.Custom,
                            handleAddPart: handleAppendPartOption,
                        }}
                    />
                )}
            </Flexbox>
        </SeparateContainer>
    );

    if (customPartSpecs.length === 0) {
        return (
            <>
                <span style={{ marginBottom: isRfqEditable ? 0 : '-24px' }} id={id('design/message_no_part_options')}>
                    <MainContainer>
                        <Message
                            variant={'red'}
                            title={t`No part has been added.`}
                            message={t`Add a custom part using the form below.`}
                            size="small"
                            attention="low"
                        />
                    </MainContainer>
                </span>
                {form}
            </>
        );
    }

    if (!isRfqEditable) {
        return (
            <CustomPartOptionsTable
                customPartSpecs={customPartSpecs}
                customPartsMap={customPartsMap}
                customComponentsMap={customComponentsMap}
                isRfqEditable={isRfqEditable}
                isSubmitting={isSubmitting}
                handleUpdatePartOption={handleUpdatePartOption}
                handleRemovePartOption={handleRemovePartOption}
                rfqContext={rfqContext}
                hasPartOptionsWithCpns={hasPartOptionsWithCpns}
            />
        );
    }
    return (
        <>
            <MainContainer>
                <CustomPartOptionsTable
                    customPartSpecs={customPartSpecs}
                    customPartsMap={customPartsMap}
                    customComponentsMap={customComponentsMap}
                    isRfqEditable={isRfqEditable}
                    isSubmitting={isSubmitting}
                    handleUpdatePartOption={handleUpdatePartOption}
                    handleRemovePartOption={handleRemovePartOption}
                    rfqContext={rfqContext}
                    hasPartOptionsWithCpns={hasPartOptionsWithCpns}
                />
            </MainContainer>
            {form}
        </>
    );
}
