import { UserType } from '@luminovo/http-client';
import { useCurrentUserDetailsContext } from '../../../../../components/contexts/CurrentUserDetailsContext';
import { useQuoteRequest } from '../../../hooks/negotiationHandlers';
import { useHoursUntilQuoteFrozen } from '../../../hooks/useHoursUntilQuoteFrozen';

/**
 *
 * ```
 *      |...................|...............|
 *      |                   |               |
 *      |      ACTIVE       |    FROZEN     |
 *      |                   |               |
 *      |                   |               |
 * received_date    hours_until_frozen     now
 * ```
 */
export function useIsQuoteRequestFrozen(quoteRequestId?: string): boolean {
    const response = useRemainingHoursUntilQuoteFrozen(quoteRequestId);
    return response?.isFrozen ?? false;
}

export function useRemainingHoursUntilQuoteFrozen(quoteRequestId?: string):
    | {
          isFrozen: true;
      }
    | {
          isFrozen: false;
          remainingHours?: number;
      }
    | undefined {
    const { userType } = useCurrentUserDetailsContext();
    const { data: quoteRequest } = useQuoteRequest(quoteRequestId);
    const freezingWindow = useHoursUntilQuoteFrozen();

    if (!quoteRequest) {
        return undefined;
    }

    // Freezing only applies to supplier users.
    if (userType !== UserType.Supplier) {
        return { isFrozen: false };
    }

    // if it's not received yet, it should also not be frozen as no offers have been attached yet.
    if (!quoteRequest.received_date) {
        return { isFrozen: false };
    }

    // If the quote request is discarded, don't allow any changes
    if (quoteRequest.discarded) {
        return { isFrozen: true };
    }

    // for received, and not discarded quote requests, allow making changes for the remaining hours
    const remainingHours =
        freezingWindow - calculateHoursBetweenDates(new Date(quoteRequest.received_date), new Date());
    return { isFrozen: remainingHours <= 0, remainingHours };
}

function calculateHoursBetweenDates(from: Date, to: Date): number {
    return Math.floor((to.getTime() - from.getTime()) / (1000 * 60 * 60));
}
