/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { assertPresent, assertUnreachable, isEqual } from '@luminovo/commons';
import {
    ButtonGroup,
    ButtonGroupItem,
    colorSystem,
    Flexbox,
    Message,
    SecondaryButton,
    Tab,
    Tabs,
    Tag,
    Text,
    useNavigate,
    usePersistedState,
} from '@luminovo/design-system';
import {
    BomItemIssue,
    customPartOrUndefined,
    CustomPartSpecification,
    otsPartOrUndefined,
    OtsPartSpecification,
} from '@luminovo/http-client';
import { ErrorRounded } from '@mui/icons-material';
import MemoryIcon from '@mui/icons-material/Memory';
import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Controller, FormProvider, useForm, useFormContext, UseFormReturn } from 'react-hook-form';
import { Prompt, useLocation } from 'react-router-dom';
import * as r from 'runtypes';
import ConfirmationDialogBox from '../../../components/dialogBox/ConfirmationDialogBox';
import { BomItem } from '../../../resources/designItem/bomItemFrontendTypes';
import { useDebugErrorHandler } from '../../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../../resources/http/httpQueryKey';
import { PartSpecificationTypes } from '../../../resources/part/PartSpecificationTypes';
import { id } from '../../../utils/ids';
import { QueryParams, route } from '../../../utils/routes';
import { sleep } from '../../../utils/sleep';
import { alphaNumericalStringSort } from '../../../utils/stringFunctions';
import { BomItemTableData, ViewContext } from '../../Bom/components/ModuleTableData';
import { editDesignItems } from '../designItemFormFunctions';
import { useOnWindowBlur } from '../hooks/useOnWindowBlur';
import useSourcedByLabel from '../hooks/useSourcedByLabel';
import { isEmptyBomItem } from '../isEmptyBomItem';
import { BomItemDetailsAndOriginalExcel } from './BomItemDetailsAndOriginalExcel';
import { BomItemFormState } from './BomItemFormState';
import { ConsignmentButton } from './ConsignmentButton';
import { ManufacturerFreeField } from './ManufacturerFreeField';
import { ManufacturingData } from './ManufacturingData/ManufacturingData';
import { OtsPartSpecificationForm } from './OtsPartSpecificationForm';
import { CustomSpecificationForm } from './SpecificationTypeForms/CustomSpecificationForm';
import { updateDesignItemsQueryKey } from './updateDesignItemsQueryKey';

interface BomItemFormProps {
    queryParams: QueryParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>;
    viewContext: ViewContext;
    assemblyId: string;
    bomItem: BomItemTableData;
    siblingDesignators: string[];
    isEditable: boolean;
    customerId?: string;
    currentParentAssemblyId: string | null | undefined;
}

const defaultCustomPart: CustomPartSpecification = {
    type: PartSpecificationTypes.Custom,
    data: [],
};

const defaultOtsPart: OtsPartSpecification = {
    type: PartSpecificationTypes.OffTheShelf,
    data: {
        is_manufacturer_free: false,
        part_options: [],
    },
};

function hasAnyPartOption(values: BomItemFormState) {
    if (values.specificationType === PartSpecificationTypes.OffTheShelf) {
        return values.otsPart.data?.part_options?.length > 0;
    }
    if (values.specificationType === PartSpecificationTypes.Custom) {
        return values.customPart.data?.length > 0;
    }
    assertUnreachable(values.specificationType);
}

function useUpdateBomItem({
    bomItem,
    viewContext,
    assemblyId,
    currentParentAssemblyId,
}: {
    bomItem: BomItem;
    viewContext: ViewContext;
    assemblyId: string;
    currentParentAssemblyId: string | null | undefined;
}) {
    const token = getToken();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const onError = useDebugErrorHandler();

    return useMutation({
        mutationKey: updateDesignItemsQueryKey(bomItem),
        mutationFn: async (values: BomItemFormState) => {
            const designItems = await editDesignItems({
                values,
                initialDesignItems: bomItem.individualDesignItems,
                token,
            });

            await Promise.allSettled([
                queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /parts/availability') }),
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('GET /design-items/:designItemId/part-alternatives'),
                }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /assemblies/:assemblyId/descendants') }),
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('POST /design-items/bulk', { requestBody: { ids: designItems } }),
                }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /assemblies/bulk') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /design-items/history') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /sourcing-scenarios/bulk') }),
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('POST /sourcing-scenarios/sourcing-full/bulk'),
                }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /bom-items/:aggregationKey/suggestions') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /solutions') }),
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('GET /solution-configurations/:solutionConfigurationId'),
                }),
                // sleep to allow the user to get the psychological safety that progress has been made and a change has been saved
                // otherwise the spinner can spin for such a short time that it is barely visible.
                sleep(1000),
            ]);
            return designItems;
        },
        onSuccess: async (ids: string[]) => {
            /**
             * If the IDs change after the mutation succeeds, then redirect the user to the BOM item with
             * the new IDs.
             *
             * This is specially important in case a bomItem has been created from scratch.
             */
            const isIdChangeDetected = !isEqual(Array.from(bomItem.id).sort(), Array.from(ids).sort());

            if (!isIdChangeDetected) return;
            if (viewContext.type === 'WithinRfQ') {
                return navigate(
                    route(
                        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                        { rfqId: viewContext.rfqId, assemblyId },
                        {
                            isReadonly: null,
                            designItemId: ids.join(','),
                            filters: null,
                            bomTab: null,
                            dashboardFilters: null,
                            search: null,
                            currentParentAssemblyId,
                            onlyShowItemsWithManufacturingWarnings: null,
                        },
                    ),
                );
            }

            return navigate(
                route(
                    '/assemblies/:assemblyId/details',
                    { assemblyId },
                    {
                        designItemId: ids.join(','),
                        filters: undefined,
                        dashboardFilters: undefined,
                        search: undefined,
                    },
                ),
            );
        },
        onError,
    });
}

const TabTypeRuntype = r.Union(r.Literal('selectedParts'), r.Literal('manufacturingData'));

type TabType = r.Static<typeof TabTypeRuntype>;
const ALLOWED_TABS: TabType[] = ['selectedParts', 'manufacturingData'];

function parseInitialTab({ search, maybeActiveTab }: { search: string; maybeActiveTab: TabType }): TabType {
    const queryParams = new URLSearchParams(search);
    const bomTab = queryParams.get('bomTab') ?? 'selectedParts';
    const paramValue = TabTypeRuntype.check(bomTab);

    if (paramValue && ALLOWED_TABS.includes(paramValue)) {
        return paramValue;
    }
    if (!paramValue) {
        return ALLOWED_TABS.includes(maybeActiveTab) ? maybeActiveTab : assertPresent(ALLOWED_TABS[0]);
    }

    return 'selectedParts';
}

function useActiveTab({
    search,
    hasPartOptions,
    specificationType,
}: {
    search: string;
    hasPartOptions: boolean;
    specificationType: PartSpecificationTypes;
}): [TabType, (newState: TabType) => void] {
    const [maybeActiveTab, setActiveTab] = usePersistedState<TabType>(
        'luminovo.bom-component-form.tab-container-version-2',
        'selectedParts',
        sessionStorage,
    );

    if (!hasPartOptions || specificationType === PartSpecificationTypes.Custom) {
        return ['selectedParts', setActiveTab];
    }

    const activeTab = parseInitialTab({ search, maybeActiveTab });

    return [activeTab, setActiveTab];
}

const BomItemFormView = ({
    queryParams,
    assemblyId,
    viewContext,
    bomItem,
    formReturn,
    submitForm,
    isEditable,
    siblingDesignators,
    customerId,
}: {
    queryParams: QueryParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>;
    assemblyId: string;
    viewContext: ViewContext;
    bomItem: BomItemTableData;
    formReturn: UseFormReturn<BomItemFormState>;
    submitForm: () => void;
    isEditable: boolean;
    siblingDesignators: string[];
    customerId?: string;
}) => {
    const { formState, trigger } = formReturn;
    const { errors, isDirty } = formState;
    const formSubmissionFailed = formReturn.formState.isSubmitted && !formReturn.formState.isSubmitSuccessful;
    const isValid = Object.keys(errors).length === 0 && !formSubmissionFailed;

    React.useEffect(() => {
        trigger();
    }, [trigger]);

    return (
        <>
            <Prompt
                when={!isValid && isDirty && isEmptyBomItem(bomItem)}
                message={() => t`Your form contains unsaved changes. Are you sure you want to continue?`}
            />
            <BomItemDetailsAndOriginalExcel
                queryParams={queryParams}
                assemblyId={assemblyId}
                viewContext={viewContext}
                bomItem={bomItem}
                formReturn={formReturn}
                submitForm={submitForm}
                isEditable={isEditable}
                siblingDesignators={siblingDesignators}
                customerId={customerId}
            />

            <Flexbox
                flexDirection="column"
                padding="20px"
                borderRadius="8px"
                gap={16}
                position="relative"
                style={{ background: colorSystem.neutral.white }}
            >
                <SelectedPartsManufacturingDataTabs
                    isEditable={isEditable}
                    bomItem={bomItem}
                    assemblyId={assemblyId}
                    viewContext={viewContext}
                    submitForm={submitForm}
                />
            </Flexbox>
        </>
    );
};

const SelectedPartsManufacturingDataTabs = React.memo(function PartSelectionManufacturingDataTabs({
    isEditable,
    bomItem,
    assemblyId,
    viewContext,
    submitForm,
}: {
    isEditable: boolean;
    bomItem: BomItemTableData;
    assemblyId: string;
    viewContext: ViewContext;
    submitForm: () => void;
}) {
    const location = useLocation();
    const navigate = useNavigate();
    const { watch } = useFormContext<BomItemFormState>();
    const values = watch();
    const hasPartOptions = hasAnyPartOption(values);

    const [activeTab, setActiveTab] = useActiveTab({
        search: location.search,
        hasPartOptions,
        specificationType: values.specificationType,
    });

    const handleTabChange = React.useCallback(
        (_event, value) => {
            setActiveTab(value);
            const queryParams = new URLSearchParams(location.search);
            queryParams.set('bomTab', value);
            navigate({ pathname: location.pathname, search: queryParams.toString() }, { replace: true });
        },
        [setActiveTab, location.pathname, location.search, navigate],
    );

    const rfqId = viewContext.rfqId;

    return (
        <>
            <Tabs value={activeTab} size="large" onChange={handleTabChange}>
                <Tab
                    value={'selectedParts'}
                    label={<Trans>Selected parts</Trans>}
                    id={id('design/button_part_selection')}
                />
                <Tab
                    value={'manufacturingData'}
                    icon={
                        bomItem.issues.includes(BomItemIssue.MissingPartData) ? (
                            <ErrorRounded style={{ color: colorSystem.yellow[6], fontSize: '16px' }} />
                        ) : undefined
                    }
                    label={<Trans>Manufacturing data</Trans>}
                    id={id('design/button_manufacturing_data')}
                    disabled={values.specificationType === PartSpecificationTypes.Custom || !rfqId}
                />
            </Tabs>
            {values.specificationType === PartSpecificationTypes.OffTheShelf && (
                <Flexbox
                    id={id('design/box_manufacturer_free')}
                    alignItems="center"
                    gap="8px"
                    position="absolute"
                    top="24px"
                    right="20px"
                >
                    <ManufacturerFreeField
                        isEditable={isEditable}
                        specification={bomItem.specification}
                        submitForm={submitForm}
                    />
                </Flexbox>
            )}

            {activeTab === 'selectedParts' && (
                <Flexbox flexDirection={'column'}>
                    <PartSpecificationForm
                        assemblyId={assemblyId}
                        viewContext={viewContext}
                        bomItem={bomItem}
                        isEditable={isEditable}
                        submitForm={submitForm}
                    />
                </Flexbox>
            )}
            {activeTab === 'manufacturingData' && rfqId && (
                <>
                    <ManufacturingData
                        bomItem={bomItem}
                        isRfqEditable={isEditable}
                        rfqId={rfqId}
                        submitForm={submitForm}
                    />
                </>
            )}
        </>
    );
});

const PartSpecificationForm = React.memo(function PartSpecificationForm({
    assemblyId,
    viewContext,
    isEditable,
    bomItem,
    submitForm,
}: {
    assemblyId: string;
    viewContext: ViewContext;
    isEditable: boolean;
    bomItem: BomItem;
    submitForm: () => void;
}) {
    const { watch, setValue } = useFormContext<BomItemFormState>();
    const values = watch();
    const { specificationType } = values;
    const [activeButton, setActiveButton] = React.useState<PartSpecificationTypes>(specificationType);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    const changeSpecificationType = () => {
        const newType =
            specificationType === PartSpecificationTypes.Custom
                ? PartSpecificationTypes.OffTheShelf
                : PartSpecificationTypes.Custom;

        setActiveButton(newType);
        setValue('specificationType', newType, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
    };

    const handleClick = (newValue: PartSpecificationTypes) => {
        if (newValue === specificationType) {
            return;
        }
        if (specificationType !== newValue && hasAnyPartOption(values)) {
            return setIsDialogOpen(true);
        }
        changeSpecificationType();
    };

    const hasPartOptions = hasAnyPartOption(values);

    return (
        <>
            <Flexbox justifyContent="space-between" alignItems="center" marginBottom="16px">
                <ButtonGroup size="large">
                    <ButtonGroupItem
                        selected={activeButton === PartSpecificationTypes.OffTheShelf}
                        id={id('design/button_off_the_shelf')}
                        onClick={() => handleClick(PartSpecificationTypes.OffTheShelf)}
                        disabled={!isEditable}
                    >
                        <Trans>Off-the-shelf</Trans>
                    </ButtonGroupItem>
                    <ButtonGroupItem
                        selected={activeButton === PartSpecificationTypes.Custom}
                        id={id('design/button_custom')}
                        onClick={() => handleClick(PartSpecificationTypes.Custom)}
                        disabled={!isEditable}
                    >
                        <Trans>Custom</Trans>
                    </ButtonGroupItem>
                </ButtonGroup>
            </Flexbox>
            {!hasPartOptions && (
                <NoPartOptionMessage isEditable={isEditable} specificationType={values.specificationType} />
            )}
            {activeButton === PartSpecificationTypes.OffTheShelf && (
                <OtsPartSpecificationForm
                    assemblyId={assemblyId}
                    viewContext={viewContext}
                    bomItem={bomItem}
                    isEditable={isEditable}
                    submitForm={submitForm}
                />
            )}
            {activeButton === PartSpecificationTypes.Custom && (
                <CustomSpecificationForm
                    assemblyId={assemblyId}
                    viewContext={viewContext}
                    bomItem={bomItem}
                    isEditable={isEditable}
                    submitForm={submitForm}
                    individualDesignItems={bomItem.individualDesignItems}
                />
            )}
            {isDialogOpen && (
                <ConfirmationDialogBox
                    title={t`Make Changes`}
                    text={t`Are you sure you want to make these changes? Current part options will be overridden.`}
                    isDialogOpen={isDialogOpen}
                    onConfirm={() => {
                        changeSpecificationType();
                        setIsDialogOpen(false);
                    }}
                    onReject={() => setIsDialogOpen(false)}
                />
            )}
        </>
    );
});

export const BomItemForm = React.memo(
    function BomItemForm({
        queryParams,
        viewContext,
        assemblyId,
        bomItem,
        siblingDesignators,
        isEditable,
        customerId,
        currentParentAssemblyId,
    }: BomItemFormProps) {
        const initialValues = React.useMemo((): BomItemFormState => {
            return {
                id: bomItem.id,
                designator: bomItem.designator.sort(alphaNumericalStringSort),
                doNotPlace: bomItem.doNotPlace,
                notes: bomItem.notes,
                quantity: bomItem.quantity,
                parentId: bomItem.parentId,
                specificationType: bomItem.specification?.type ?? PartSpecificationTypes.OffTheShelf,
                customPart: customPartOrUndefined(bomItem.specification) ?? defaultCustomPart,
                otsPart: otsPartOrUndefined(bomItem.specification) ?? defaultOtsPart,
                isConsigned: bomItem.isConsigned,
                ignorePackageNameMismatch: bomItem.ignorePackageNameMismatch,
                addPartEvents: [],
            };
        }, [bomItem]);

        const formReturn = useForm<BomItemFormState>({
            defaultValues: initialValues,
            reValidateMode: 'onBlur',
            mode: 'onBlur',
        });

        const { mutateAsync } = useUpdateBomItem({
            bomItem: bomItem,
            viewContext,
            assemblyId,
            currentParentAssemblyId,
        });

        const { handleSubmit, formState, reset } = formReturn;
        const { dirtyFields, isValid } = formState;

        const onSubmit = React.useMemo(() => {
            return handleSubmit(async (data: BomItemFormState) => {
                // don't actually submit the form if it is not dirty or not valid
                if (
                    Object.keys(dirtyFields).length === 0 ||
                    !isValid ||
                    data.designator.length === 0 ||
                    !data.quantity ||
                    !data.quantity.quantity ||
                    !data.quantity.unit
                ) {
                    return;
                }
                return mutateAsync(data).then(() => reset(data));
            });
        }, [dirtyFields, handleSubmit, isValid, mutateAsync, reset]);

        // submit the form whenever the user blurs
        useOnWindowBlur(onSubmit);

        return (
            <FormProvider {...formReturn}>
                <form
                    style={{
                        padding: '24px',
                        gap: 24,
                        display: 'flex',
                        flexDirection: 'column',
                        boxSizing: 'border-box',
                        width: 'clamp(1000px, 100%, 1464px)',
                    }}
                    onSubmit={onSubmit}
                >
                    <BomItemFormView
                        queryParams={queryParams}
                        viewContext={viewContext}
                        assemblyId={assemblyId}
                        bomItem={bomItem}
                        formReturn={formReturn}
                        submitForm={onSubmit}
                        isEditable={isEditable}
                        siblingDesignators={siblingDesignators}
                        customerId={customerId}
                    />
                </form>
            </FormProvider>
        );
    },
    (a, b) => {
        return isEqual(a, b);
    },
);

export const PCBDesignItemForm = ({
    assemblyId,
    rfqId,
    isEditable,
    bomItem,
    customerId,
    currentParentAssemblyId,
}: {
    assemblyId: string;
    rfqId: string;
    isEditable: boolean;
    bomItem: BomItem;
    customerId?: string;
    currentParentAssemblyId: string | null | undefined;
}): JSX.Element => {
    const initialValues = React.useMemo((): BomItemFormState => {
        return {
            id: bomItem.id,
            designator: bomItem.designator.sort(alphaNumericalStringSort),
            doNotPlace: bomItem.doNotPlace,
            notes: bomItem.notes,
            quantity: bomItem.quantity,
            parentId: bomItem.parentId,
            specificationType: bomItem.specification?.type ?? PartSpecificationTypes.OffTheShelf,
            customPart: customPartOrUndefined(bomItem.specification) ?? defaultCustomPart,
            otsPart: otsPartOrUndefined(bomItem.specification) ?? defaultOtsPart,
            isConsigned: bomItem.isConsigned,
            ignorePackageNameMismatch: bomItem.ignorePackageNameMismatch,
            addPartEvents: [],
        };
    }, [bomItem]);

    const formReturn = useForm<BomItemFormState>({
        defaultValues: initialValues,
        reValidateMode: 'onBlur',
        mode: 'onBlur',
    });

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId: rfqId,
        };
    }, [rfqId]);

    const { mutateAsync } = useUpdateBomItem({
        bomItem: bomItem,
        viewContext,
        assemblyId,
        currentParentAssemblyId,
    });

    const { handleSubmit, reset, control, setValue, getValues } = formReturn;
    const onSubmit = React.useMemo(() => {
        return handleSubmit(async (data: BomItemFormState) => {
            return mutateAsync(data).then(() => reset(data));
        });
    }, [handleSubmit, mutateAsync, reset]);

    const sourcedByLabel = useSourcedByLabel(getValues('isConsigned'));

    return (
        <Flexbox
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap="12px"
            padding="48px"
            style={{
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                background: colorSystem.neutral.white,
                borderRadius: 8,
                maxWidth: '1464px',
            }}
        >
            <MemoryIcon style={{ color: colorSystem.neutral[5], fontSize: '150px' }} />
            <Typography variant="h1" style={{ color: colorSystem.neutral[7] }}>
                <Trans>PCB Design Item</Trans>
            </Typography>
            <Typography variant="body1" style={{ color: colorSystem.neutral[7] }}>
                <Trans>This design item was automatically generated for you from the uploaded PCB.</Trans>
            </Typography>
            <Flexbox alignItems="center" gap="4px">
                <Text variant="h4" color={colorSystem.neutral[7]}>
                    <Trans>Sourced by</Trans>
                </Text>
                {isEditable ? (
                    <Controller
                        name={'isConsigned'}
                        control={control}
                        render={({ field }): JSX.Element => {
                            return (
                                <ConsignmentButton
                                    isConsigned={field.value}
                                    setValue={setValue}
                                    submitForm={onSubmit}
                                    customerId={customerId}
                                />
                            );
                        }}
                    />
                ) : (
                    <Tag color="neutral" attention="low" label={sourcedByLabel} />
                )}
            </Flexbox>

            <SecondaryButton
                style={{ marginTop: '16px' }}
                size="medium"
                href={route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb', { assemblyId: assemblyId, rfqId: rfqId })}
            >
                <Trans>Go to PCB</Trans>
            </SecondaryButton>
        </Flexbox>
    );
};

function NoPartOptionMessage({
    isEditable,
    specificationType,
}: {
    isEditable: boolean;
    specificationType: PartSpecificationTypes;
}) {
    if (specificationType === PartSpecificationTypes.Custom) {
        // "no part" message for custom parts is in the CustomSpecificationForm.tsx
        return null;
    }
    return (
        <span id={id('design/message_no_part_options')}>
            <Message
                variant={'red'}
                title={t`No parts have been added.`}
                size="small"
                attention="low"
                message={isEditable ? t`Add new parts using the suggestions or search below.` : ''}
            />
        </span>
    );
}
