/* eslint-disable react-compiler/react-compiler */
import { isPresent } from '@luminovo/commons';
import { FilterListRounded } from '@mui/icons-material';
import { styled } from '@mui/material';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import React from 'react';
import { colorSystem } from '../../theme';
import { SortIcon } from '../icons';
import { ColumnMenu } from './ColumnMenu';
import { isQueryOnColumn } from './reducer';
import { Action, Column, PersistentTableState, RenderHeadProps } from './types';

/**
 * A column has a menu if it has comparators, filters or is searchable
 */
function hasColumnMenu<TRowData, TRowContext>({
    filters = [],
    comparators = [],
    searchable,
}: Column<TRowData, TRowContext>) {
    return comparators.length > 0 || filters.length > 0 || !!searchable;
}

function useColumnMenu<TRowData, TContext>() {
    const [selectedColumn, setColumn] = React.useState<Column<TRowData, TContext> | undefined>();
    const [isOpen, setIsOpen] = React.useState(false);
    const onClose = React.useCallback(() => {
        setColumn(undefined);
        setIsOpen(false);
    }, []);

    const onToggleMenu = (column: Column<TRowData, TContext>) => {
        if (!hasColumnMenu(column)) {
            return undefined;
        }
        return () => {
            setIsOpen(true);
            setColumn(column);
        };
    };

    return { isOpen, onClose, selectedColumn, onToggleMenu };
}

function countSelectedFilters<TRowData, TContext>(
    { filters = [] }: Column<TRowData, TContext>,
    state: PersistentTableState,
    columnId: string,
) {
    const countToggledFilters = filters.filter((filter) => state.selectedFilterIds.includes(filter.id)).length;
    const countSearch = isQueryOnColumn(state, columnId) ? 1 : 0;
    return countToggledFilters + countSearch;
}

export function TableHeadCell<TRowData, TContext>({
    column,
    state,
    dispatch,
    renderHeadProps,
}: {
    column: Column<TRowData, TContext>;
    state: PersistentTableState;
    dispatch: React.Dispatch<Action>;
    renderHeadProps: RenderHeadProps<TContext>;
}): JSX.Element {
    const { HeaderTableCell = DefaultHeaderTableCell } = column.overrides ?? {};
    const { isOpen, onClose, onToggleMenu, selectedColumn } = useColumnMenu<TRowData, TContext>();
    const ref = React.useRef<HTMLSpanElement>(null);
    const { comparators = [], filters = [] } = column;

    const comparator = comparators.find((comparator) => state.selectedComparatorIds.includes(comparator.id));
    const hasComparators = comparators.length > 0;

    const hasFilters = filters.length > 0;
    const numSelectedFilters = countSelectedFilters(column, state, column.id);

    const isSearchable = !!column.searchable;
    const isClickable = (hasComparators || hasFilters || isSearchable) && !Boolean(column.disableClickable);
    const isActive = isPresent(comparator) || numSelectedFilters > 0;

    const HeadComponent = column.renderHead;

    const sharedContext = renderHeadProps.sharedContext;

    return (
        <>
            <ColumnMenu
                selectedColumn={selectedColumn}
                anchorEl={ref.current} // TODO - fix useRef
                isOpen={isOpen}
                onClose={onClose}
                dispatch={dispatch}
                state={state}
                sharedContext={sharedContext}
            />

            {HeadComponent ? (
                <HeadComponent {...renderHeadProps} />
            ) : (
                <HeaderTableCell
                    key={column.id}
                    sortDirection={comparator?.order || false}
                    onClick={isClickable ? onToggleMenu(column) : undefined}
                    width={column.width}
                >
                    <div style={{ display: 'inline', color: colorSystem.neutral[7] }}>{column.label}</div>
                    {isClickable && (
                        <span ref={ref}>
                            <ActionIcon isActive={isActive} hasFilters={hasFilters} isSearchable={isSearchable} />
                        </span>
                    )}
                </HeaderTableCell>
            )}
        </>
    );
}

export const DefaultHeaderTableCell: React.FunctionComponent<TableCellProps> = ({
    children,
    ...props
}): JSX.Element => {
    if (props.onClick) {
        return <ClickableTableCell {...props}>{children}</ClickableTableCell>;
    } else {
        return <StyledTableCell {...props}>{children}</StyledTableCell>;
    }
};

export const DefaultStickyHeaderTableCell = styled(DefaultHeaderTableCell)({
    position: 'sticky',
    right: 0,
    borderLeft: `1px solid ${colorSystem.neutral[2]}`,
    background: 'white',
});

const ActionIcon: React.FunctionComponent<{
    isActive: boolean;
    hasFilters: boolean;
    isSearchable: boolean;
}> = ({ isActive, hasFilters, isSearchable }): JSX.Element => {
    if (hasFilters || isSearchable) {
        return isActive ? <ActiveFilterIcon /> : <StyledFilterIcon />;
    } else {
        return isActive ? <ActiveSortIcon /> : <StyledSortIcon />;
    }
};

const StyledSortIcon = styled(SortIcon)(() => ({
    verticalAlign: 'middle',
    backgroundColor: colorSystem.neutral[1],
    borderRadius: '50%',
    padding: '2px',
    marginLeft: '4px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colorSystem.neutral[2],
    },
}));

const ActiveSortIcon = styled(SortIcon)(() => ({
    verticalAlign: 'middle',
    backgroundColor: colorSystem.primary[2],
    color: colorSystem.primary[6],
    borderRadius: '50%',
    padding: '2px',
    marginLeft: '4px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colorSystem.primary[3],
        color: colorSystem.primary[7],
    },
}));

const StyledFilterIcon = styled(FilterListRounded)(() => ({
    fontSize: 16,
    verticalAlign: 'middle',
    backgroundColor: colorSystem.neutral[1],
    borderRadius: '50%',
    padding: '2px',
    marginLeft: '4px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colorSystem.neutral[2],
    },
}));

const ActiveFilterIcon = styled(FilterListRounded)(() => ({
    fontSize: 16,
    verticalAlign: 'middle',
    backgroundColor: colorSystem.primary[2],
    color: colorSystem.primary[6],
    borderRadius: '50%',
    padding: '2px',
    marginLeft: '4px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colorSystem.primary[3],
        color: colorSystem.primary[7],
    },
}));

const StyledTableCell = styled(TableCell)(() => ({
    color: colorSystem.neutral[7],
    verticalAlign: 'middle',
    align: 'right',
    whiteSpace: 'nowrap',
    overflow: 'visible',
}));

const ClickableTableCell = styled(StyledTableCell)(() => ({
    '&:hover': {
        cursor: 'pointer',
        color: colorSystem.primary[6],
    },
}));
