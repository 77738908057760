import { t } from '@lingui/macro';
import {
    extractAmountFromMonetaryValue,
    formatDecimal,
    formatMonetaryValue,
    isPresent,
    maxBy,
    minBy,
} from '@luminovo/commons';
import { InputAdornment } from '@mui/material';
import { Column } from '@tanstack/react-table';
import { colorSystem } from '../../../theme';
import { Flexbox } from '../../Flexbox';
import { SearchInput } from '../../SearchField';
import { fieldConverterNumber } from '../../forms/FieldNumeric/FieldNumeric';
import { createNumericValidator } from '../../forms/FieldNumeric/FieldNumericController';
import { FilterValue, NumberRange, getFilterConfig } from '../type';
import { createFilterValueUpdater, isFilterValue } from './createFilterValueUpdater';

function parseNumberValue(value: string | undefined): number | null {
    if (!isPresent(value) || value === '' || (typeof value === 'string' && value.trim() === '')) {
        return null;
    }

    const parsedValue = Number(value);
    return Number.isFinite(parsedValue) ? parsedValue : null;
}

function generatePlaceholder<TData>(column: Column<TData, unknown>, minOrMax: 'min' | 'max'): string {
    const numberFilterConfig = getFilterConfig(column, 'number');
    if (isPresent(numberFilterConfig)) {
        const facetedMinMaxValues = column.getFacetedMinMaxValues();
        if (isPresent(facetedMinMaxValues)) {
            switch (minOrMax) {
                case 'min':
                    return formatDecimal(facetedMinMaxValues[0], { ifAbsent: '-' });
                case 'max':
                    return formatDecimal(facetedMinMaxValues[1], { ifAbsent: '-' });
            }
        }
    }

    const monetaryValueFilterConfig = getFilterConfig(column, 'monetaryValue');
    if (isPresent(monetaryValueFilterConfig)) {
        const facedValues = Array.from(column.getFacetedUniqueValues().keys());
        switch (minOrMax) {
            case 'min':
                return formatMonetaryValue(
                    minBy(facedValues, (x) => extractAmountFromMonetaryValue(x)),
                    monetaryValueFilterConfig.formatAs,
                    { ifAbsent: '-' },
                );
            case 'max':
                return formatMonetaryValue(
                    maxBy(facedValues, (x) => extractAmountFromMonetaryValue(x)),
                    monetaryValueFilterConfig.formatAs,
                    { ifAbsent: '-' },
                );
        }
    }

    return '-';
}

export function FilterInRange<TData>({
    column,
    onClose,
}: {
    column: Column<TData, unknown>;
    onClose: () => void;
}): JSX.Element | null {
    const filterConfig = getFilterConfig(column, 'number') || getFilterConfig(column, 'monetaryValue');

    if (!isPresent(filterConfig)) {
        return null;
    }

    const handleMinChange = (value: string | undefined) => {
        const numberValue = parseNumberValue(value);
        column.setFilterValue(
            createFilterValueUpdater((old: FilterValue<NumberRange> | undefined) => {
                const max = old?.value[1] ?? null;
                if (!isPresent(numberValue) && !isPresent(max)) {
                    return undefined;
                }
                return [numberValue, max];
            }, filterConfig.defaultFilterFn),
        );
    };

    const handleMaxChange = (value: string | undefined) => {
        const numberValue = parseNumberValue(value);
        column.setFilterValue(
            createFilterValueUpdater((old: FilterValue<NumberRange> | undefined) => {
                const min = old?.value[0] ?? null;
                if (!isPresent(numberValue) && !isPresent(min)) {
                    return undefined;
                }
                return [min, numberValue];
            }, filterConfig.defaultFilterFn),
        );
    };

    const filterValue = column.getFilterValue();
    const valueMin =
        isFilterValue<NumberRange>(filterValue) && typeof filterValue.value[0] === 'number'
            ? String(filterValue.value[0])
            : undefined;
    const valueMax =
        isFilterValue<NumberRange>(filterValue) && typeof filterValue.value[1] === 'number'
            ? String(filterValue.value[1])
            : undefined;

    return (
        <Flexbox gap={4}>
            <SearchInput
                placeholder={generatePlaceholder(column, 'min')}
                value={valueMin}
                onChange={handleMinChange}
                debounceWait={50}
                onKeyEnter={onClose}
                onClear={() => handleMinChange(undefined)}
                style={{ backgroundColor: colorSystem.neutral.white, width: '148px' }}
                formatter={{
                    converter: fieldConverterNumber,
                    validation: createNumericValidator({ required: false }),
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{t`Min`}</InputAdornment>,
                }}
            />
            <SearchInput
                placeholder={generatePlaceholder(column, 'max')}
                value={valueMax}
                onChange={handleMaxChange}
                debounceWait={50}
                onKeyEnter={onClose}
                onClear={() => handleMaxChange(undefined)}
                style={{ backgroundColor: colorSystem.neutral.white, width: '148px' }}
                formatter={{
                    converter: fieldConverterNumber,
                    validation: createNumericValidator({ required: false }),
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{t`Max`}</InputAdornment>,
                }}
            />
        </Flexbox>
    );
}

export const FilterInNumberRange = FilterInRange;
export const FilterInMonetaryValueRange = FilterInRange;
