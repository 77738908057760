import { t } from '@lingui/macro';
import { Close } from '@mui/icons-material';
import { ClickAwayListener, InputAdornment, styled } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from '../../hooks/useNavigate';
import { INTER, colorSystem } from '../../theme';
import { Tag } from '../Tag';
import { TertiaryIconButton } from '../buttons';
import { SearchIcon } from '../icons';
import { MenuFields } from './components/MenuFields';
import { MenuOperatorArgument } from './components/MenuOperatorArgument';
import { MenuOperators } from './components/MenuOperators';
import { ParametricInputTooltip } from './components/ParametricInputTooltip';
import { SearchBlockView as DefaultSearchBlockView } from './components/SearchBlockView';
import { reducer } from './reducer';
import { findAllowedParameters } from './selectors/findAllowedParameters';
import { Actions, AutocompleteOptions, ParametricSearchState } from './types';
import { parseInitialSelectedBlocks } from './util/parseInitialSelectedBlocks';
import { serialiseSearchBlocks } from './util/serialiseSearchBlocks';
import { useHotKeyRequestFocus } from './util/useHotKeyRequestFocus';

export interface ParametricSearchInputProps<T, TAutocompleteState = T[]> {
    placeholder?: string;
    autoFocus?: boolean;
    state: ParametricSearchState<T, TAutocompleteState>;
    dispatch: React.Dispatch<Actions<T, TAutocompleteState>>;
    overrides?: {
        Container?: typeof DefaultContainer;
        Input?: typeof DefaultInput;
        SearchBlockView?: typeof DefaultSearchBlockView;
        IconButton?: typeof TertiaryIconButton;
    };
    autocompleteState?: TAutocompleteState;
}

export interface UseParametricSearchInputStateReturn<T, TAutocompleteState> {
    state: ParametricSearchState<T, TAutocompleteState>;
    dispatch: React.Dispatch<Actions<T, TAutocompleteState>>;
}

/**
 * @deprecated We don't recommend using the parametric search anymore.
 */
export function useParametricSearchInputState<T, TAutocompleteState>(
    config: AutocompleteOptions<T, TAutocompleteState>,
): UseParametricSearchInputStateReturn<T, TAutocompleteState> {
    const ref = React.useRef<HTMLInputElement>(null);
    const location = useLocation();
    const navigate = useNavigate();

    const [state, dispatch] = React.useReducer<
        React.Reducer<ParametricSearchState<T, TAutocompleteState>, Actions<T, TAutocompleteState>>
    >(reducer, {
        inputRef: ref,
        config,
        query: '',
        selectedBlocks: parseInitialSelectedBlocks(config, location.search),
    });

    const serialisedSearchBlocks = serialiseSearchBlocks({
        searchBlocks: state.selectedBlocks,
    });

    React.useEffect(() => {
        if (new URLSearchParams(location.search).has('override')) {
            dispatch({ type: 'setSelectedBlocks', searchBlocks: parseInitialSelectedBlocks(config, location.search) });
        }
    }, [location.search, config]);

    const queryParameter = config.history?.queryParameter;
    React.useEffect(() => {
        if (!queryParameter) {
            return;
        }

        const search = new URLSearchParams(location.search);
        search.set(queryParameter, serialisedSearchBlocks);
        search.delete('override');
        navigate({ pathname: location.pathname, search: search.toString() }, { replace: true });
    }, [queryParameter, location.pathname, location.search, navigate, serialisedSearchBlocks]);

    return { state, dispatch };
}

export function ParametricSearchInput<T, TAutocompleteState>({
    placeholder = '',
    autoFocus,
    state,
    dispatch,
    overrides = {},
    autocompleteState,
}: ParametricSearchInputProps<T, TAutocompleteState>): JSX.Element {
    const {
        Input = DefaultInput,
        Container = DefaultContainer,
        SearchBlockView = DefaultSearchBlockView,
        IconButton = TertiaryIconButton,
    } = overrides;

    useHotKeyRequestFocus(state.inputRef);

    const [showMenu, setShowMenu] = React.useState(false);

    function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Escape') {
            dispatch({ type: 'removePartialFilter' });

            if (state.query.length === 0) {
                state.inputRef.current?.blur();
            }
        } else if (event.key === 'Enter') {
            dispatch({ type: 'submitQuery' });
            setShowMenu(false);
        }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newQuery = event.target.value;

        dispatch({ type: 'onQueryChange', query: newQuery });
        setShowMenu(true);
    }

    const { selectedBlocks } = state;
    const blocks = selectedBlocks.map((filter, i) => {
        const parameter = state.config.parameters.find((parameter) => parameter.field === filter.field);
        return (
            <SearchBlockView
                key={i}
                block={filter}
                parameter={parameter}
                dispatch={dispatch}
                autocompleteState={autocompleteState}
            />
        );
    });

    const tooltip =
        showMenu || Boolean(state.partialBlock)
            ? findMenu({
                  state,
                  dispatch,
                  autocompleteState,
                  setShowMenu,
              })
            : '';

    const showClearIcon = state.partialBlock || state.selectedBlocks.length > 0;

    const getPlaceholder = () => {
        const { partialBlock, selectedBlocks } = state;
        if (partialBlock && partialBlock.selectedOp) {
            const { selectedOp } = partialBlock;
            switch (selectedOp.op) {
                case '<':
                case '=':
                case '>':
                case '≤':
                case '≥':
                case 'like':
                case 'starts-with':
                    return '';
                case 'is':
                case 'is not':
                    return t`Type to filter by "${partialBlock.parameter.label ?? partialBlock.parameter.field}"`;
            }
        }
        if (!partialBlock && selectedBlocks.length === 0) {
            return placeholder;
        }
        return undefined;
    };

    return (
        <ClickAwayListener
            onClickAway={() => {
                setShowMenu(false);
                dispatch({ type: 'removePartialFilter' });
            }}
        >
            <Container>
                <SearchIcon fontSize="inherit" fill={colorSystem.neutral[7]} />
                {blocks}
                {state.partialBlock && <></>}
                <ParametricInputTooltip arrow={false} open={Boolean(tooltip)} placement="bottom-start" title={tooltip}>
                    <Input
                        placeholder={getPlaceholder()}
                        value={state.query}
                        autoFocus={autoFocus}
                        onChange={handleChange}
                        onKeyUp={handleKeyUp}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === 'Tab') {
                                e.preventDefault();
                            } else if (e.key === 'Backspace') {
                                dispatch({ type: 'backspace' });
                            }
                        }}
                        ref={state.inputRef}
                        onFocus={() => {
                            setShowMenu(true);
                        }}
                        onClick={() => {
                            setShowMenu(true);
                        }}
                        aria-label={placeholder}
                        // Fixes flaky chromatic test
                        aria-describedby={undefined}
                    />
                </ParametricInputTooltip>
                {!showClearIcon && (
                    <InputAdornment position="end">
                        <Tag attention={'low'} color={'neutral'} label={t`Ctrl+K`} />
                    </InputAdornment>
                )}
                {showClearIcon && (
                    <IconButton
                        size="medium"
                        disabled={!showClearIcon}
                        onClick={() => {
                            dispatch({ type: 'clearSearch' });
                        }}
                        style={{
                            padding: 4,
                        }}
                    >
                        <Close style={{ width: 20, height: 20 }} />
                    </IconButton>
                )}
            </Container>
        </ClickAwayListener>
    );
}

function findMenu<T, TAutocompleteState>({
    state,
    dispatch,
    autocompleteState,
    setShowMenu,
}: {
    state: ParametricSearchState<T, TAutocompleteState>;
    dispatch: React.Dispatch<Actions<T, TAutocompleteState>>;
    autocompleteState?: TAutocompleteState;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}): '' | JSX.Element {
    if (state.partialBlock?.selectedOp) {
        return (
            <MenuOperatorArgument
                autocompleteState={autocompleteState}
                state={state}
                onSelect={(parameter, label) => {
                    dispatch({ type: 'selectParameter', parameter, label });
                    setShowMenu(false);
                    dispatch({ type: 'removePartialFilter' });
                }}
                onCancel={() => {
                    dispatch({ type: 'removePartialFilter' });
                }}
                onSearch={() => dispatch({ type: 'submitQuery' })}
            />
        );
    }

    if (state.partialBlock?.parameter) {
        return (
            <MenuOperators
                inputRef={state.inputRef}
                query={state.query}
                partialFilter={state.partialBlock}
                onSelect={(operator) => {
                    dispatch({ type: 'selectOperator', operator });
                }}
                onSearch={() => dispatch({ type: 'submitQuery' })}
            />
        );
    }

    if (!state.partialBlock) {
        const allowedParameters = findAllowedParameters(state, autocompleteState);

        return (
            <MenuFields
                showIcons={!state.config.noIcons}
                inputRef={state.inputRef}
                parameters={allowedParameters}
                query={state.query}
                onSearch={() => dispatch({ type: 'submitQuery' })}
                onSelect={(selected) => {
                    dispatch({ type: 'selectField', selected, autocompleteState });
                }}
                showSearch={state.config.showSearch ?? true}
            />
        );
    }

    return '';
}

const DefaultContainer = styled('span')({
    display: 'inline-flex',
    padding: '4px 8px',
    alignItems: 'center',
    gap: 4,
    border: `1px solid ${colorSystem.neutral[3]}`,
    borderRadius: 4,
    width: '100%',
    minWidth: '280px',
    boxSizing: 'border-box',
    minHeight: 40,
    flexWrap: 'wrap',
    background: colorSystem.neutral.white,
    '&:has(input:focus)': {
        border: `1px solid ${colorSystem.primary[5]}`,
        boxShadow: `0px 0px 0px 2px ${colorSystem.primary[3]}`,
    },
    '&:focus': {
        border: `1px solid ${colorSystem.primary[5]}`,
        boxShadow: `0px 0px 0px 2px ${colorSystem.primary[3]}`,
    },
    '&:has(input:hover)': {
        border: `1px solid ${colorSystem.primary[5]}`,
    },
});

const DefaultInput = styled('input')({
    width: '10px',
    border: 'none',
    outline: 'none',
    flexGrow: 1,
    padding: 0,
    margin: 0,
    background: 'transparent',
    fontFamily: INTER,
    fontSize: 14,
    lineHeight: '24px',
    '&::placeholder': {
        opacity: 1,
        color: colorSystem.neutral[6],
    },
});

export const ParametricSearchInputDefaults = {
    DefaultContainer,
    DefaultInput,
    DefaultSearchBlockView,
};
