import {
    closestCenter,
    CollisionDetection,
    DragEndEvent,
    DragOverEvent,
    DragStartEvent,
    getFirstCollision,
    pointerWithin,
    rectIntersection,
    UniqueIdentifier,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { typeSafeObjectKeys } from '@luminovo/commons';
import { PCBFileTypes } from '@luminovo/http-client';
import { useCallback, useState } from 'react';
import { encodePcbFilePath, usePdfViewerDrawer } from '../PcbPdfViewer/usePdfViewerDrawer';
import {
    ContainersWithFiles,
    convertToUiStateToPcbFileType,
    getFileCategory,
    getFileStateType,
    PCBFileWithId,
    sortFilesInContainers,
    StateFileType,
    UpdateContainersWithFiles,
} from './utils/layerAssignmentsUtils';

export const useLayerAssignment = ({
    items,
    handleOnChange,
    clonedItems,
    setClonedItems,
}: {
    clonedItems: ContainersWithFiles | null;
    setClonedItems: (items: ContainersWithFiles | null) => void;
    items: ContainersWithFiles;
    handleOnChange: UpdateContainersWithFiles;
    isEditable: boolean;
}) => {
    const [activeFile, setActiveFile] = useState<PCBFileWithId | null>(null);
    const [previewFile, setPreviewFile] = useState<PCBFileWithId | null>(null);
    const [dropDownFile, setDropDownFile] = useState<PCBFileWithId | null>(null);
    const { openDrawer: openPdfDrawer } = usePdfViewerDrawer();

    const findContainer = useCallback(
        (id: UniqueIdentifier | StateFileType): StateFileType | undefined => {
            const itemToInspect = clonedItems ?? items;
            // id can be either container id or id of item inside a container
            if (id in itemToInspect) {
                // This typecast is safe since `if` from above ensures id is a StateFileType
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                return id as StateFileType;
            }

            // if `id` is not a container then find the id through list of files to find the container
            return typeSafeObjectKeys<ContainersWithFiles>(itemToInspect).find((key: StateFileType) =>
                itemToInspect[key].files.some((item: PCBFileWithId) => item.name === id),
            );
        },
        [items, clonedItems],
    );

    const collisionDetectionStrategy: CollisionDetection = useCallback(
        (args) => {
            const itemsToInspect = clonedItems ?? items;
            // Start by finding any intersecting droppable
            const pointerIntersections = pointerWithin(args);

            const intersections =
                pointerIntersections.length > 0
                    ? // If there are droppables intersecting with the pointer, return those
                      pointerIntersections
                    : rectIntersection(args);

            let overId = getFirstCollision(intersections, 'id');

            if (overId != null) {
                if (overId in itemsToInspect) {
                    // This typecast is safe since `if` from above ensures id is a StateFileType
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    const containerItems = itemsToInspect[overId as StateFileType];

                    // If a container is matched and it contains items
                    if (containerItems.files.length > 0) {
                        // Return the closest droppable within that container
                        overId = closestCenter({
                            ...args,
                            droppableContainers: args.droppableContainers.filter(
                                (container) =>
                                    container.id !== overId &&
                                    containerItems.files.some((item) => item.name === container.id),
                            ),
                        })[0]?.id;
                    }
                }
                return [{ id: overId }];
            }
            return [];
        },
        [items, clonedItems],
    );

    // When dragging starts we only want the currently active file
    function handleDragStart({ active }: DragStartEvent) {
        const activeContainer = findContainer(active.id);
        if (activeContainer === undefined) return;

        setDropDownFile(null);

        const deepCopy: ContainersWithFiles = JSON.parse(JSON.stringify(items));

        setClonedItems(deepCopy);

        const activeFile = deepCopy[activeContainer].files.find((item) => item.id === active.id);
        activeFile && setActiveFile(activeFile);
    }

    function handleDragCancel() {
        if (clonedItems) {
            // Reset items to their original state in case items have been
            // Dragged across containers
            handleOnChange(clonedItems);
        }

        setActiveFile(null);
        setClonedItems(null);
    }

    function handleDragOver({ over, active }: DragOverEvent) {
        if (over === null || !clonedItems) {
            return;
        }

        const sourceContainer = findContainer(active.id);
        const targetContainer = findContainer(over.id);

        if (!sourceContainer || !targetContainer) {
            return;
        }

        const containerFiles = clonedItems[targetContainer]?.files ?? [];
        // Find index of "over" if it’s a file
        let overIndex = containerFiles.findIndex((f) => f.name === over.id);

        // If the "over.id" is actually the container key itself (meaning we’re over the container, not a file),
        // you might want to place at the end
        if (over.id in clonedItems) {
            overIndex = containerFiles.length;
        }
        if (overIndex < 0) {
            overIndex = containerFiles.length;
        }

        const sourceFiles = [...clonedItems[sourceContainer].files];
        const targetFiles = sourceContainer === targetContainer ? sourceFiles : [...clonedItems[targetContainer].files];

        let targetIndex: number;
        if (over.id in clonedItems) {
            // If hovered over an empty container, place at the end (+1 is optional)
            targetIndex = targetFiles.length + 1;
        } else {
            const isBelowOverItem =
                active.rect?.current?.translated &&
                active.rect.current.translated.top > over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;
            targetIndex = overIndex >= 0 ? overIndex + modifier : targetFiles.length + 1;
        }

        const currentContainerFiles = clonedItems[sourceContainer].files;
        const currentIndex = currentContainerFiles.findIndex((f) => f.name === active.id);

        if (sourceContainer === targetContainer && currentIndex === targetIndex) {
            return;
        }

        // This should take out the file from the source container and add it to the target container
        const [movingFile] = sourceFiles.splice(currentIndex, 1);

        // TODO: update this to use the index of the file being hovered over
        targetFiles.splice(targetIndex, 0, movingFile);

        const newPreviewItems: ContainersWithFiles = {
            ...clonedItems,
            [sourceContainer]: {
                ...clonedItems[sourceContainer],
                files: sourceContainer === targetContainer ? targetFiles : sourceFiles,
            },
            [targetContainer]: {
                ...clonedItems[targetContainer],
                files: targetFiles,
            },
        };

        setClonedItems(newPreviewItems);
    }

    function handleDragEnd({ over, active }: DragEndEvent) {
        if (over === null || !clonedItems) return;

        const activeContainer = findContainer(active.id);
        const overContainer = findContainer(over.id); // Container we are dropping file into

        // Validate that the over and active containers exists
        if (overContainer === undefined || activeContainer === undefined) return;

        const originalContainer = activeFile ? getFileStateType(activeFile) : undefined;

        const activeIndex = clonedItems[activeContainer].files.findIndex((item) => item.name === active.id);
        const overIndex = clonedItems[overContainer].files.findIndex((item) => item.name === over.id);
        // if drop container is copper or mechanical show the drop down.
        if (
            activeFile &&
            overContainer !== originalContainer &&
            [StateFileType.MechanicalFiles, StateFileType.Copper].includes(overContainer)
        ) {
            setDropDownFile(clonedItems[activeContainer].files[activeIndex]);
        }

        setActiveFile(null);
        setClonedItems(null);

        // Rearrange the items within the container
        const updatedItems = {
            ...clonedItems,
            [overContainer]: {
                files: arrayMove<PCBFileWithId>(clonedItems[overContainer].files, activeIndex, overIndex),
                dirty: true,
            },
        };

        if (originalContainer) {
            updatedItems[originalContainer].dirty = true;

            // If the original container is copper, we need to update the copper file types
            // because the user might have removed the copper top or bottom
            if (originalContainer === StateFileType.Copper) {
                updatedItems[StateFileType.Copper] = {
                    dirty: true,
                    files: updateOrderOfFilesInCopperContainer(updatedItems),
                };
            }
        }

        // When operating on the copper container, we need to update all the fileTypes
        // because the file order matters
        if (overContainer === StateFileType.Copper) {
            updatedItems[StateFileType.Copper] = {
                dirty: true,
                files: updateOrderOfFilesInCopperContainer(updatedItems),
            };
        } else {
            const newFileType = convertToUiStateToPcbFileType(
                updatedItems[overContainer].files,
                overContainer,
                overIndex,
            );
            updatedItems[overContainer].files[overIndex].fileType.category = getFileCategory(newFileType);
            updatedItems[overContainer].files[overIndex].fileType.fileType = newFileType;
            updatedItems[overContainer].dirty = true;
        }

        handleOnChange(updatedItems);
    }

    function handleOnDropDownChange(fileType: PCBFileTypes, currentFile: PCBFileWithId) {
        const container = findContainer(currentFile.id);
        setDropDownFile(null);

        if (container) {
            handleOnChange((items) => {
                const activeIndex = items[container].files.findIndex((item) => item.id === currentFile.id);

                const updatedState = JSON.parse(JSON.stringify(items)) as ContainersWithFiles;

                updatedState[container].files[activeIndex].fileType.category = getFileCategory(fileType);
                updatedState[container].files[activeIndex].fileType.fileType = fileType;
                updatedState[container].dirty = true;

                return sortFilesInContainers(updatedState);
            });
        }
    }

    function handleFileCardClick(file: PCBFileWithId) {
        // Check if file is pdf file
        const isPdfFile = file.name.toLowerCase().endsWith('.pdf');
        if (isPdfFile && file.path) {
            openPdfDrawer({
                selectedPdf: {
                    fileName: encodePcbFilePath(file.path),
                    regions: [],
                },
            });
        }

        return previewFile?.id === file.id ? setPreviewFile(null) : setPreviewFile(file);
    }

    return {
        handleDragCancel,
        handleDragStart,
        handleDragOver,
        handleDragEnd,
        handleOnDropDownChange,
        handleSetPreviewClick: handleFileCardClick,
        collisionDetectionStrategy,
        activeFile,
        previewFile,
        dropDownFile,
    };
};

const updateOrderOfFilesInCopperContainer = (
    updatedItems: ContainersWithFiles,
    overContainer: StateFileType = StateFileType.Copper,
) => {
    return updatedItems[overContainer].files.map((item, index) => {
        // Get the new file type based on the position of the file in the container
        // 1st file: copper top, last file:  copper bottom, mid file:
        let newFileType = convertToUiStateToPcbFileType(updatedItems[overContainer].files, overContainer, index);

        // If the user has already specified it as a plane, we should keep it as a plane
        // and not override it (unless it needs to be copper bottom or top now)
        if (item.fileType.fileType === PCBFileTypes.PLANE_MID && newFileType === PCBFileTypes.COPPER_MID) {
            newFileType = PCBFileTypes.PLANE_MID;
        }

        return {
            ...item,
            fileType: {
                ...item.fileType,
                index,
                fileType: newFileType,
                category: getFileCategory(newFileType),
            },
        };
    });
};
