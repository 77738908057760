import { typeSafeObjectKeys } from '../typingUtils';
import { LumiQuoteRoute, RouteDefinition, routes } from './routes';

interface RouteInfo extends RouteDefinition {
    routeId: LumiQuoteRoute;
}

export interface PageInfo extends RouteInfo {
    page: string;
}

export function allRoutes(): RouteInfo[] {
    return typeSafeObjectKeys(routes).map((routeId) => {
        return {
            routeId: routeId,
            ...routes[routeId],
        };
    });
}
