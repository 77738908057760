import { t } from '@lingui/macro';
import { SearchIcon, TextField } from '@luminovo/design-system';
import { InputAdornment } from '@mui/material';
import { useDataGridContext } from '../components/DataGridContextProvider';

export function DataGridSearch(): JSX.Element {
    const { setQuickFilter, quickFilter } = useDataGridContext();
    return (
        <TextField
            sx={{
                minWidth: 300,
            }}
            value={quickFilter}
            placeholder={t`Type to search`}
            onChange={(event) => {
                setQuickFilter(event.target.value);
            }}
            size="small"
            slotProps={{
                input: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                },
            }}
        />
    );
}
