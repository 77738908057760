import * as React from 'react';
import { usePermissions } from '../hooks/usePermissions';
import { isAuthorized, RoleTest } from '../permissions';

interface Props {
    /**
     * A list of all the required permissions. The children will only be
     * rendered if all the required permissions are met.
     */
    requiredPermissions: RoleTest;
    /**
     * Use this as fallback content in case not all the required permissions
     * are met.
     */
    fallback?: JSX.Element;
}

/**
 * This component will only render its children when the `requiredPermissions` are met.
 */
export const Protected = React.forwardRef<unknown, React.PropsWithChildren<Props>>(
    ({ requiredPermissions, fallback, children }, ref) => {
        const { permissions } = usePermissions();

        if (!isAuthorized(permissions, requiredPermissions)) {
            return fallback ?? null;
        }

        const child = React.Children.only(children);

        if (React.isValidElement<{ ref: unknown }>(child)) {
            // TODO - fix useRef
            // eslint-disable-next-line react-compiler/react-compiler
            return React.cloneElement(child, { ref });
        }

        return <>{child}</>;
    },
);
