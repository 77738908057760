import { Trans } from '@lingui/macro';
import { getLocale } from '@luminovo/commons';
import { colorSystem } from '@luminovo/design-system';
import { Box } from '@mui/material';
import { onHoverFilter } from './onHoverFilter';

export const PartImage = ({
    imageUrl,
    showBackground = true,
    isHovered = false,
}: {
    imageUrl: string | null | undefined;
    showBackground?: boolean;
    isHovered?: boolean;
}) => {
    if (!imageUrl) {
        return <NoPartImage isHovered={isHovered} />;
    }
    return (
        <Box
            style={{
                background: showBackground ? colorSystem.neutral[1] : 'unset',
                border: showBackground ? `1px solid ${colorSystem.neutral[2]}` : 'unset',
                width: '135px',
                height: '135px',
                borderRadius: showBackground ? '4px' : 'unset',
                overflow: 'hidden',
            }}
        >
            <img
                alt=""
                src={imageUrl}
                style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                    mixBlendMode: 'multiply',
                    filter: onHoverFilter({ isHovered }),
                    borderRadius: showBackground ? '4px' : 'unset',
                }}
            />
        </Box>
    );
};

const NoPartImage = ({ isHovered }: { isHovered: boolean }) => {
    const locale = getLocale();
    const textPositionX = locale === 'de' ? '30%' : '28%';

    return (
        <svg width="135" height="135" viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g style={{ mixBlendMode: 'multiply' }}>
                <rect width="135" height="107" transform="translate(0 14)" fill="white" />
            </g>
            <path
                d="M44.332 81.6667H89.6654V52.3333H44.332V81.6667ZM44.332 85.6667C43.2654 85.6667 42.332 85.2667 41.532 84.4667C40.732 83.6667 40.332 82.7333 40.332 81.6667V52.3333C40.332 51.2667 40.732 50.3333 41.532 49.5333C42.332 48.7333 43.2654 48.3333 44.332 48.3333H48.9987V45C48.9987 44.4333 49.1915 43.9583 49.577 43.575C49.9626 43.1917 50.4404 43 51.0104 43C51.5804 43 52.0543 43.1917 52.432 43.575C52.8098 43.9583 52.9987 44.4333 52.9987 45V48.3333H64.9987V45C64.9987 44.4333 65.1915 43.9583 65.577 43.575C65.9626 43.1917 66.4404 43 67.0104 43C67.5804 43 68.0543 43.1917 68.432 43.575C68.8098 43.9583 68.9987 44.4333 68.9987 45V48.3333H80.9987V45C80.9987 44.4333 81.1915 43.9583 81.577 43.575C81.9626 43.1917 82.4404 43 83.0104 43C83.5804 43 84.0543 43.1917 84.432 43.575C84.8098 43.9583 84.9987 44.4333 84.9987 45V48.3333H89.6654C90.732 48.3333 91.6654 48.7333 92.4654 49.5333C93.2654 50.3333 93.6654 51.2667 93.6654 52.3333V81.6667C93.6654 82.7333 93.2654 83.6667 92.4654 84.4667C91.6654 85.2667 90.732 85.6667 89.6654 85.6667H84.9987V89C84.9987 89.5667 84.8059 90.0417 84.4204 90.425C84.0348 90.8083 83.557 91 82.987 91C82.417 91 81.9431 90.8083 81.5654 90.425C81.1876 90.0417 80.9987 89.5667 80.9987 89V85.6667H68.9987V89C68.9987 89.5667 68.8059 90.0417 68.4204 90.425C68.0348 90.8083 67.557 91 66.987 91C66.417 91 65.9431 90.8083 65.5654 90.425C65.1876 90.0417 64.9987 89.5667 64.9987 89V85.6667H52.9987V89C52.9987 89.5667 52.8059 90.0417 52.4204 90.425C52.0348 90.8083 51.557 91 50.987 91C50.417 91 49.9431 90.8083 49.5654 90.425C49.1876 90.0417 48.9987 89.5667 48.9987 89V85.6667H44.332Z"
                fill="#E9ECF4"
            />
            <text
                fill={colorSystem.neutral[6]}
                style={{ whiteSpace: 'pre' }}
                fontFamily="INTER"
                fontSize="12"
                fontWeight="600"
                letterSpacing="0px"
                filter={onHoverFilter({ isHovered })}
            >
                <tspan y="71" x={textPositionX}>
                    <Trans>No image</Trans>
                </tspan>
            </text>
            <g style={{ mixBlendMode: 'multiply' }}>
                <rect x="0.5" y="0.5" width="134" height="134" rx="3.5" fill="#F5F6FA" />
                <rect x="0.5" y="0.5" width="134" height="134" rx="3.5" stroke="#E9ECF4" />
            </g>
        </svg>
    );
};
