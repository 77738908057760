import type { LocalFile, ParseResult } from 'papaparse';
import { Encoding, Row, Table } from '../types';

export type InputFile = LocalFile;

export async function readFile(
    input: InputFile,
    fileType: 'csv' | 'excel' = 'csv',
    encoding: Encoding = 'UTF-8',
): Promise<{ data: Table }> {
    const papa = await import('papaparse');
    const { default: readXlsxFile } = await import('read-excel-file');

    if (fileType === 'csv') {
        return new Promise<ParseResult<Row>>((resolve, reject) =>
            papa.parse<Row>(input, {
                header: false,
                worker: true,
                dynamicTyping: false,
                skipEmptyLines: true,

                complete(results) {
                    resolve(results);
                },
                encoding: encoding,
                error: (error) => {
                    reject(error);
                },
            }),
        );
    }
    // assume it's an excel file
    else if (input instanceof File) {
        const rows = await readXlsxFile(input);
        return {
            data: rows.map((row) => row.map((cell) => cell?.toString() ?? '')),
        };
    }
    throw new Error('Unsupported file type');
}
