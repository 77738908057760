import React from 'react';
import { extractDefaultSelectedIds, extractPersistentSelection } from './extractDefaultSelectedIds';
import { loadTableState } from './loadTableState';
import { reducer } from './reducer';
import { saveTableState } from './saveTableState';
import {
    Action,
    Column,
    DefaultTableOptions,
    PaginationOptions,
    PersistentTableState,
    SearchOptions,
    SelectionOptions,
    TableState,
} from './types';
import { useGlobalSearch } from './useGlobalSearch';
import { useSortedData } from './useSortedData';

type OptionalSharedContextIfUndefined<TContext> = TContext extends undefined
    ? { sharedContext?: never }
    : { sharedContext: TContext };

export type UseDataTableStateProps<TRowData, TContext = undefined> = OptionalSharedContextIfUndefined<TContext> & {
    /**
     * A unique identifier for this table.
     *
     * Since the DataTable stores state in `sessionStorage`, the persistenceId is used to determine which state
     * to load from the storage.
     *
     * **Important**: Set a unique key for this table so that filter and sorting configurations
     * are persistent only for the same persistenceId, but not across tables on the same page.
     */
    persistenceId: string;
    query?: string;
    /**
     * Determines how the state of the DataTable is stored. Defaults to
     * `sessionStorage`.
     *
     * 🚨 Important 🚨
     * If you decide to switch to `localStorage`, keep in mind that the DataTable will store a copy of
     * it's data for every `persistenceId`. This means that if you plan on having a dynamic `persistenceId`
     * you create the risk of filling up the user's `localStorage` which on some browsers is only a
     * few megabytes.
     */
    storage?: Storage;
    items: TRowData[];
    columns: Column<TRowData, TContext>[];
    selectionOptions?: SelectionOptions<TRowData> | undefined;
    /**
     * A global search query that will filter any rows that match the query.
     *
     * In order for searching to work, you must also provide an `idExtractor` prop.
     *
     * If no query is passed, no rows will be filtered.
     */
    searchOptions?: SearchOptions<TRowData, TContext>;
    paginationOptions?: PaginationOptions;
};

/**
 * @deprecated Use the `TanStackTable` component instead.
 */
export function useDataTableState<TRowData, TContext = undefined>({
    persistenceId: tableId,
    storage = sessionStorage,
    paginationOptions = {
        showPagination: true,
        rowsPerPageOptions: [5, 15, 25],
        defaultRowsPerPage: 25,
        persistPagination: false,
        scrollResetAfterChange: true,
        memorizeEmptyQueryPage: false,
    },
    query = '',
    searchOptions,
    selectionOptions,
    items,
    columns,
    sharedContext,
}: UseDataTableStateProps<TRowData, TContext>): TableState<TRowData, TContext> {
    const { memorizeEmptyQueryPage } = paginationOptions;

    const tableStateOptions: DefaultTableOptions = React.useMemo(
        () => {
            return {
                providedRowsPerPage: paginationOptions.defaultRowsPerPage,
                persistPagination: paginationOptions.persistPagination,
                defaultSelectedIds: extractDefaultSelectedIds(items, selectionOptions),
            };
        },
        // Notice that we don't include the `default` table options as we don't want these to
        // be controlled.
        // eslint-disable-next-line react-compiler/react-compiler
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [paginationOptions.persistPagination],
    );

    const initialState: PersistentTableState = React.useMemo(
        () =>
            loadTableState({
                tableId,
                storage,
                defaultTableOptions: tableStateOptions,
            }),
        [tableId, tableStateOptions, storage],
    );
    initialState.selectedIds = extractPersistentSelection(items, initialState.selectedIds, selectionOptions);

    const reduceAndSave = React.useCallback(
        (state: PersistentTableState, action: Action) => {
            const newState = reducer(state, action);
            saveTableState({ tableId, storage, tableState: newState });
            return newState;
        },
        [tableId, storage],
    );

    const [state, dispatch] = React.useReducer(reduceAndSave, initialState);
    const { onIndexSearchText, search } = useGlobalSearch<TRowData, TContext>(searchOptions);

    React.useEffect(() => {
        dispatch({ type: 'reset-state', state: initialState });
    }, [dispatch, initialState]);

    React.useEffect(() => {
        if (query === '' && memorizeEmptyQueryPage) {
            dispatch({ type: 'restore-memorized-page' });
        } else {
            dispatch({ type: 'set-page', page: 0, memorize: true });
        }
    }, [dispatch, query, memorizeEmptyQueryPage]);

    // We can make this assertion because we force the user to provide a sharedContext
    // when the type parameter C is explicitly set.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const typedContext = sharedContext as TContext;
    const sortedItems = useSortedData({ items, sharedContext: typedContext, columns, state });
    const filteredItems = search(sortedItems, query);

    return {
        searchOptions,
        state,
        onIndexSearchText,
        dispatch,
        query,
        items,
        filteredItems,
        columns,
        selectionOptions,
        paginationOptions,
        sharedContext: typedContext,
    };
}
