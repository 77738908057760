import { colorSystem } from '@luminovo/design-system';
import { ToggleButton, ToggleButtonGroup, styled } from '@mui/material';

export const PcbFormToggleButton = styled(ToggleButton)({
    '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: colorSystem.neutral.white,
        color: colorSystem.neutral[7],
        boxShadow: `0px 2px 4px ${colorSystem.neutral[2]}`,
    },
    '&.MuiToggleButtonGroup-groupedHorizontal.MuiToggleButtonGroup-grouped': {
        borderRadius: 4,
        margin: 0,
        border: 0,
    },

    '&.MuiToggleButton-sizeSmall': {
        height: '24px',
    },
});

export const PcbFormToggleButtonGroup = styled(ToggleButtonGroup)({
    maxHeight: '32px',
    backgroundColor: colorSystem.neutral[2],
    padding: 4,
    gap: 4,
});
