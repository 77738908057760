import { BaseParser, Parser } from '../parsers/Parser';

/**
 * A parser that parses component designators, which are comma-separated identifiers
 * like "U1, U2, U3" or ranges like "C1-3" which expands to "C1,C2,C3".
 */
export function designators(): Parser<string[]> {
    return BaseParser.of((input: string) => {
        if (!input || input.trim().length === 0) {
            return {
                ok: false,
                expected: 'Expected a comma-separated list of designators (e.g. "U1, U2, U3") or ranges (e.g. "C1-3")',
            };
        }

        const expandRange = (designator: string): string[] => {
            const rangeMatch = designator.match(/^([A-Za-z]+)(\d+)-(\d+)$/);
            if (!rangeMatch) return [designator];

            const [, prefix, startStr, endStr] = rangeMatch;
            const start = parseInt(startStr);
            const end = parseInt(endStr);

            if (start > end) return [designator];

            return Array.from({ length: end - start + 1 }, (_, i) => `${prefix}${start + i}`);
        };

        const designators = input
            .split(',')
            .map((d) => d.trim())
            .filter((d) => d.length > 0)
            .flatMap(expandRange);

        if (designators.length === 0) {
            return {
                ok: false,
                expected: 'Expected at least one designator',
            };
        }

        return {
            ok: true,
            value: designators,
            rest: '',
        };
    });
}
