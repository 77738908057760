import { UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Trans, t } from '@lingui/macro';
import {
    MonetaryValue,
    assertPresent,
    formatMonetaryValue,
    formatToIso8601Date,
    isEqual,
    isPresent,
    throwErrorUnlessProduction,
} from '@luminovo/commons';
import {
    DragIndicatorRounded,
    MoreHorizRounded,
    RestartAltRounded,
    VisibilityOffOutlined,
    VisibilityOutlined,
} from '@mui/icons-material';
import { Divider, ListItem, Menu } from '@mui/material';
import { Column, Table } from '@tanstack/react-table';
import React from 'react';
import { colorSystem } from '../../../theme';
import { Flexbox } from '../../Flexbox';
import { MenuItem } from '../../MenuItem';
import { Text } from '../../Text';
import { Tooltip } from '../../Tooltip';
import { SecondaryIconButton, TertiaryButton } from '../../buttons';
import { XlsIcon } from '../../icons';
import { getFilterConfig } from '../type';
import { resetTable } from '../utils';
import { MultiContainerDndContext } from './MultiContainerDndContext';

function ColumnManager<TData>({ table }: { table: Table<TData> }) {
    const tableState = table.getState();
    const columns = table.getAllLeafColumns().filter((col) => col.getCanHide());
    const hasAllColumnVisible = columns.every((col) => col.getIsVisible());
    const hasOnlyOneColumnVisible = columns.filter((col) => col.getIsVisible()).length === 1;
    const { enableColumnHiding } = table.options.meta || {};

    const getItems = React.useCallback(() => {
        const columnOrder = tableState.columnOrder.length > 0 ? tableState.columnOrder : columns.map(({ id }) => id);

        const visible = columnOrder.filter((columnId) => columns.find((col) => col.id === columnId)?.getIsVisible());
        const hidden = columnOrder.filter((columnId) => !columns.find((col) => col.id === columnId)?.getIsVisible());

        return { visible, hidden };
    }, [tableState.columnOrder, columns]);

    const updateItems = React.useCallback(
        (
            fn: (
                prev: Record<'visible' | 'hidden', UniqueIdentifier[]>,
            ) => Record<'visible' | 'hidden', UniqueIdentifier[]>,
        ) => {
            const newItems = fn(getItems());

            table.setColumnOrder([...newItems.visible, ...newItems.hidden] as string[]);
            newItems.visible.forEach((columnId) => {
                const col = columns.find((col) => col.id === columnId);
                if (col && !col.getIsVisible()) col.toggleVisibility(true);
            });
            newItems.hidden.forEach((columnId) => {
                const col = columns.find((col) => col.id === columnId);
                if (col && col.getIsVisible()) col.toggleVisibility(false);
            });

            return newItems;
        },
        [columns, getItems, table],
    );

    const handleHideAllColumns = () => {
        updateItems((prev) => ({ visible: [prev.visible[0]], hidden: [...prev.visible.slice(1), ...prev.hidden] }));
    };

    const handleShowAllColumns = () => {
        updateItems((prev) => ({ visible: [...prev.visible, ...prev.hidden], hidden: [] }));
    };

    const handleToggleColumnVisibility = (columnId: string) => {
        updateItems((prev) => {
            if (prev.visible.includes(columnId)) {
                return {
                    visible: prev.visible.filter((id) => id !== columnId),
                    hidden: [columnId, ...prev.hidden],
                };
            }
            if (prev.hidden.includes(columnId)) {
                return {
                    visible: [...prev.visible, columnId],
                    hidden: prev.hidden.filter((id) => id !== columnId),
                };
            }
            return prev;
        });
    };

    if (columns.length === 0) {
        return null;
    }

    const items = getItems();

    return (
        <ListItem style={{ backgroundColor: colorSystem.neutral.white }}>
            <Flexbox flexDirection={'column'} gap={4}>
                <MultiContainerDndContext items={items} updateItems={updateItems}>
                    <Flexbox flexDirection={'column'} gap={8}>
                        <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                            <Text variant={'h5'}>
                                <Trans>Shown in table</Trans>
                            </Text>
                            <Flexbox flex={1} paddingX={'12px'} />
                            <TertiaryButton
                                size={'small'}
                                disabled={hasOnlyOneColumnVisible || !enableColumnHiding}
                                onClick={handleHideAllColumns}
                            >
                                <Trans>Hide all</Trans>
                            </TertiaryButton>
                        </Flexbox>
                        <SortableContext items={items.visible}>
                            {items.visible.map((columnId) => (
                                <ColumnManagerItem
                                    key={columnId}
                                    columnId={columnId}
                                    table={table}
                                    onClick={() => handleToggleColumnVisibility(columnId)}
                                    disabled={hasOnlyOneColumnVisible || !enableColumnHiding}
                                />
                            ))}
                        </SortableContext>
                        {!hasAllColumnVisible && (
                            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                                <Text variant={'h5'}>
                                    <Trans>Hidden in table</Trans>
                                </Text>
                                <Flexbox flex={1} paddingX={'12px'} />
                                <TertiaryButton
                                    size={'small'}
                                    disabled={hasAllColumnVisible || !enableColumnHiding}
                                    onClick={handleShowAllColumns}
                                >
                                    <Trans>Show all</Trans>
                                </TertiaryButton>
                            </Flexbox>
                        )}

                        <SortableContext items={items.hidden}>
                            {items.hidden.map((columnId) => (
                                <ColumnManagerItem
                                    key={columnId}
                                    columnId={columnId}
                                    table={table}
                                    onClick={() => handleToggleColumnVisibility(columnId)}
                                    disabled={!enableColumnHiding}
                                />
                            ))}
                        </SortableContext>
                    </Flexbox>
                </MultiContainerDndContext>
            </Flexbox>
        </ListItem>
    );
}

function ColumnManagerItem({
    columnId,
    table,
    onClick,
    disabled,
}: {
    columnId: UniqueIdentifier;
    table: Table<any>;
    onClick: () => void;
    disabled?: boolean;
}) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: columnId });
    const column = assertPresent(table.getColumn(columnId as string));

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    if (!column.getCanHide()) {
        return null;
    }

    return (
        <div ref={setNodeRef} style={style}>
            <Flexbox gap={8} justifyContent={'space-between'}>
                <Flexbox gap={4} alignItems={'center'} width={'100%'} minWidth={'250px'}>
                    {table.options.meta?.enableColumnOrdering && (
                        <TertiaryButton
                            size="small"
                            ref={setNodeRef}
                            style={{ cursor: 'grab', color: colorSystem.neutral[5], width: '20px' }}
                            {...listeners}
                            {...attributes}
                            disabled={disabled}
                        >
                            <DragIndicatorRounded color={'inherit'} fontSize="inherit" />
                        </TertiaryButton>
                    )}

                    <Tooltip title={column.getIsVisible() ? t`Hide column` : t`Show column`}>
                        <TertiaryButton size={'small'} onClick={onClick} style={{ width: '100%' }} disabled={disabled}>
                            <Flexbox alignItems={'center'} gap={24} width={'100%'} justifyContent={'space-between'}>
                                <Text variant={'body-small'} showEllipsis={true} style={{ maxWidth: '200px' }}>
                                    {column.columnDef.meta?.label()}
                                </Text>
                                {column.getIsVisible() ? (
                                    <VisibilityOutlined fontSize={'inherit'} />
                                ) : (
                                    <VisibilityOffOutlined fontSize={'inherit'} />
                                )}
                            </Flexbox>
                        </TertiaryButton>
                    </Tooltip>
                </Flexbox>
            </Flexbox>
        </div>
    );
}

export async function handleExportAsExcel<TData>({ table }: { table: Table<TData> }): Promise<void> {
    const { default: writeXlsxFile } = await import('write-excel-file');

    const cols = table
        .getAllLeafColumns()
        .filter((col) => col.getIsVisible())
        .filter((col) => col.columnDef.meta?.dataType !== 'generic');
    const rows = table.getRowModel().rows;

    const headerRow = cols.map((col) => ({ value: assertPresent(col.columnDef.meta).label(), fontWeights: 'bold' }));

    const dataRows = rows.map((row) =>
        cols.map((col) => {
            const meta = assertPresent(col.columnDef.meta);
            const value = row.getValue(col.id);

            if (!isPresent(value) || value === '') {
                return null;
            }

            switch (meta.dataType) {
                case 'text':
                    return {
                        type: String,
                        value: String(value),
                    };
                case 'number':
                    if (typeof value !== 'number' || isNaN(value) || !isFinite(value)) {
                        return null;
                    }

                    return {
                        type: Number,
                        value: Number(value),
                    };
                case 'enum':
                    return {
                        type: String,
                        value: getFilterConfig(col, 'enum')?.getOptionLabel(value) ?? JSON.stringify(value),
                    };
                case 'array':
                    return {
                        type: String,
                        value: (value as unknown[])
                            .map(
                                (v) =>
                                    getFilterConfig(col as Column<TData, unknown[]>, 'array')?.getOptionLabel(v) ??
                                    JSON.stringify(v),
                            )
                            .join(', '),
                    };
                case 'date':
                    return {
                        type: String,
                        value: formatToIso8601Date(value as string),
                    };
                case 'monetaryValue':
                    const formatAs = getFilterConfig(col, 'monetaryValue')?.formatAs;
                    return {
                        type: String,
                        value: formatMonetaryValue(value as MonetaryValue, formatAs),
                    };

                case 'generic':
                default:
                    throwErrorUnlessProduction(new Error('Error: Unsupported data type in exportAsExcel'), {
                        extra: { column: col.id, value: value },
                    });
                    return null;
            }
        }),
    );

    await writeXlsxFile([headerRow, ...dataRows], {
        columns: cols.map((col) => ({
            width: isPresent(col.columnDef.size) ? col.columnDef.size / 8 : undefined,
        })),
        fileName: `export-${formatToIso8601Date(new Date())}.xlsx`,
    });
}

function ExportAsExcelMenuItem<TData>({ table, handleClose }: { table: Table<TData>; handleClose: () => void }) {
    const { enableExcelExport } = table.options.meta || {};

    if (!enableExcelExport) {
        return null;
    }

    return (
        <MenuItem
            label={t`Export as Excel`}
            onClick={async () => {
                await handleExportAsExcel({ table });
                handleClose();
            }}
            startIcon={<XlsIcon />}
        />
    );
}

function ResetTableMenuItem<TData>({ table, handleClose }: { table: Table<TData>; handleClose: () => void }) {
    const { columnFilters, columnOrder, columnPinning, columnVisibility, globalFilter, rowSelection, sorting } =
        table.getState();

    const hasSameColumnFilters = isEqual(columnFilters, table.options.meta?.defautlInitalState?.columnFilters);
    const hasSameColumnOrder = isEqual(columnOrder, table.options.meta?.defautlInitalState?.columnOrder);
    const hasSameColumnPinning = isEqual(columnPinning, table.options.meta?.defautlInitalState?.columnPinning);
    const hasSameColumnVisibility = isEqual(columnVisibility, table.options.meta?.defautlInitalState?.columnVisibility);
    const hasSameGlobalFilter = isEqual(globalFilter, table.options.meta?.defautlInitalState?.globalFilter);
    const hasSameRowSelection = isEqual(rowSelection, table.options.meta?.defautlInitalState?.rowSelection);
    const hasSameSorting = isEqual(sorting, table.options.meta?.defautlInitalState?.sorting);

    const disabledReset =
        hasSameColumnFilters &&
        hasSameColumnOrder &&
        hasSameColumnPinning &&
        hasSameColumnVisibility &&
        hasSameGlobalFilter &&
        hasSameRowSelection &&
        hasSameSorting;

    return (
        <MenuItem
            label={t`Reset table to default`}
            variant={'destructive'}
            startIcon={<RestartAltRounded />}
            onClick={() => {
                resetTable(table);
                handleClose();
            }}
            disabled={disabledReset}
        />
    );
}

export function VisibilityMenu<TData>({ table }: { table: Table<TData> }): JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(undefined);
    const hasColumnManagerEnabled = table.options.meta?.enableColumnHiding || table.options.meta?.enableColumnOrdering;

    const handleOnClick = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (isPresent(anchorEl)) {
                return;
            }
            setAnchorEl(e.currentTarget);
        },
        [anchorEl],
    );

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return (
        <>
            <SecondaryIconButton size={'medium'} onClick={handleOnClick}>
                <MoreHorizRounded fontSize={'inherit'} />
            </SecondaryIconButton>

            <Menu anchorEl={anchorEl} open={isPresent(anchorEl)} onClose={handleClose} variant="menu">
                {hasColumnManagerEnabled && [<ColumnManager key="1" table={table} />, <Divider key="2" />]}
                <ExportAsExcelMenuItem table={table} handleClose={handleClose} />
                <ResetTableMenuItem table={table} handleClose={handleClose} />
            </Menu>
        </>
    );
}
