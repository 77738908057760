import { Trans } from '@lingui/macro';
import { colorSystem, SecondaryButton } from '@luminovo/design-system';
import { FilterList } from '@mui/icons-material';

type FilterDemandsButtonProps = {
    isFiltered: boolean;
    onClick: () => void;
};

export const FilterDemandsButton = ({ isFiltered, onClick }: FilterDemandsButtonProps): JSX.Element => {
    return (
        <SecondaryButton
            onClick={onClick}
            startIcon={<FilterList fontSize="small" />}
            size="small"
            style={
                isFiltered
                    ? {
                          backgroundColor: colorSystem.primary[2],
                          borderColor: colorSystem.primary[3],
                          color: colorSystem.primary[7],
                      }
                    : undefined
            }
        >
            <Trans>Filter demand</Trans>
        </SecondaryButton>
    );
};
