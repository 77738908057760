import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, TertiaryIconButton, Text } from '@luminovo/design-system';
import { PCBFileTypes, PCBV2, PCBV2File } from '@luminovo/http-client';
import { DeleteOutlineRounded, DescriptionRounded as FileIcon, GetApp } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { useIsCustomer } from '../../../../components/contexts/CurrentUserDetailsContext';
import { useDownloadPCBFiles } from '../../../../resources/export/exportHandler';
import { CollapsibleSection } from '../../components/CollapsibleSection';
import { PcbFileName } from '../../components/PcbFileName';
import { DeletePcbFileModal } from './DeleteStackUpFileModal';
import { StackUpMessageBox } from './StackUpMessageBox';

const CustomStackupFileContext = React.createContext<
    | {
          previewFile: PCBV2File | null;
          setPreviewFile: React.Dispatch<React.SetStateAction<PCBV2File | null>>;
      }
    | undefined
>(undefined);

export const useCustomStackupFileContext = () => {
    const customStackupContext = React.useContext(CustomStackupFileContext);
    if (!customStackupContext) {
        throw new Error('useCustomStackupFileContext must be used within a CustomStackUpFileContextProvider');
    }

    return customStackupContext;
};
export function CustomStackUpFileContextProvider(props: React.PropsWithChildren<{}>) {
    const [previewFile, setPreviewFile] = React.useState<PCBV2File | null>(null);

    return (
        <CustomStackupFileContext.Provider value={{ previewFile, setPreviewFile }}>
            {props.children}
        </CustomStackupFileContext.Provider>
    );
}

const CustomStackUpFile = ({ file, pcbId, disabled }: { file: PCBV2File; pcbId: string; disabled: boolean }) => {
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState<boolean>(false);
    const { downloadPCBFiles: downloadStackUp } = useDownloadPCBFiles({
        pcbId: pcbId,
        filePath: `singular/${file.name}`,
    });
    const { setPreviewFile, previewFile } = useCustomStackupFileContext();

    const handleStackUpClick = () => {
        setPreviewFile((oldFile) => (oldFile?.name === file.name ? null : file));
    };

    return (
        <>
            <Flexbox
                key={file.name}
                style={{
                    gap: '8px',
                    border: `1px solid ${
                        previewFile?.name === file.name ? colorSystem.primary[5] : colorSystem.neutral[2]
                    }`,
                    padding: '8px',
                    borderRadius: '8px',
                    alignItems: 'center',
                    width: '100%',
                    boxSizing: 'border-box',
                    cursor: 'pointer',
                }}
                onClick={handleStackUpClick}
            >
                <FileIcon style={{ color: colorSystem.neutral[7] }} />
                <PcbFileName
                    text={file.name}
                    TextProps={{
                        variant: 'body-small-semibold',
                        style: { color: colorSystem.neutral[8] },
                    }}
                />

                <Flexbox style={{ marginInlineStart: 'auto' }}>
                    <TertiaryIconButton
                        size="small"
                        onClick={(event) => {
                            event.stopPropagation();
                            downloadStackUp();
                        }}
                        style={{ width: 24, height: 24, marginLeft: 'auto' }}
                    >
                        <GetApp color="inherit" fontSize="inherit" />
                    </TertiaryIconButton>
                    <TertiaryIconButton
                        disabled={disabled}
                        size="small"
                        style={{ width: 24, height: 24, marginLeft: 'auto' }}
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsConfirmationDialogOpen(true);
                        }}
                    >
                        <DeleteOutlineRounded color="inherit" fontSize="inherit" />
                    </TertiaryIconButton>
                </Flexbox>
            </Flexbox>

            <DeletePcbFileModal
                title={t`Delete custom stackup?`}
                text={t`Are you sure you want to delete the uploaded file with the Stackup?`}
                fileNames={[file.name]}
                pcbId={pcbId}
                isConfirmationDialogOpen={isConfirmationDialogOpen}
                setIsConfirmationDialogOpen={setIsConfirmationDialogOpen}
                onDeleteConfirmation={() => {
                    if (previewFile?.name === file.name) {
                        setPreviewFile(null);
                    }
                }}
            />
        </>
    );
};

export const CustomStackUpForm = ({ pcb, isEditing }: { pcb: PCBV2; isEditing: boolean }) => {
    const isCustomer = useIsCustomer();
    const stackUpFiles = pcb.files?.filter((file) => file.fileType.fileType === PCBFileTypes.STACK_UP) ?? [];
    const { setPreviewFile } = useCustomStackupFileContext();

    const firstStackUpFile = stackUpFiles[0];
    React.useEffect(() => {
        if (firstStackUpFile && firstStackUpFile.name.toLowerCase().endsWith('.pdf')) {
            setPreviewFile(firstStackUpFile);
        }
    }, [firstStackUpFile, setPreviewFile]);

    return (
        <Flexbox
            style={{
                gap: '16px',
                flexDirection: 'column',
            }}
        >
            {isCustomer && (
                <StackUpMessageBox
                    // eslint-disable-next-line spellcheck/spell-checker
                    text={t` Real-time prices rely on default stackups from our linked manufacturers. Custom stackup requires manual price requests, often resulting in increased PCB costs.`}
                    title={t`Custom stackup`}
                    id="custom-stack-up"
                />
            )}

            <Box
                style={{
                    borderRadius: '8px',
                    backgroundColor: colorSystem.neutral.white,
                }}
            >
                <CollapsibleSection
                    label={t`Stackup files`}
                    isOpenByDefault
                    ContentStyle={{
                        gap: '8px',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    {stackUpFiles.length > 0 ? (
                        <>
                            {stackUpFiles.map((file) => (
                                <CustomStackUpFile disabled={!isEditing} pcbId={pcb.id} file={file} key={file.name} />
                            ))}
                        </>
                    ) : (
                        <Text variant="body-small" style={{ color: colorSystem.neutral[6] }}>
                            <Trans>
                                The file will be displayed here as soon as you upload or select it from the existing
                                files in the window to the right
                            </Trans>
                        </Text>
                    )}
                </CollapsibleSection>
            </Box>
        </Flexbox>
    );
};
